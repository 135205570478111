import { Icon24SmileAddOutline } from '@vkontakte/icons';
import React, { memo, ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PROMO_TARIFFS } from 'reactApp/appHelpers/configHelpers';
import { familyLoadRequest, inviteToFamilyOrCreate } from 'reactApp/modules/family/family.actions';
import { FamilyAnalyticsCategory, FamilyAnalyticsPageActions, sendFamilyAnalytics } from 'reactApp/modules/family/family.analytics';
import { isUserWithFamily, userCanCreateFamily } from 'reactApp/modules/family/family.selectors';
import { loadProductsAndOpenFastCheckout } from 'reactApp/modules/payment/payment.module';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { Button } from 'reactApp/ui/Button/Button';

import styles from './Footer.css';

export const Footer = memo((): ReactElement => {
    const dispatch = useDispatch();

    const withFamily = useSelector(isUserWithFamily);
    const canCreateFamily = useSelector(userCanCreateFamily);
    const isFull = useSelector(UserQuotaSelectors.isFull);
    const overquota = useSelector(UserQuotaSelectors.getOverQuota);

    const showInvite = withFamily || canCreateFamily;

    const invite = useCallback(() => {
        sendFamilyAnalytics({
            eventCategory: FamilyAnalyticsCategory.pageOwner,
            action: FamilyAnalyticsPageActions.clickTabInvite,
        });

        dispatch(inviteToFamilyOrCreate());
    }, [dispatch]);

    const updateFamilyData = useCallback(() => {
        dispatch(familyLoadRequest({ withQuota: true }));
    }, []);

    const buyTariff = useCallback(() => {
        dispatch(
            loadProductsAndOpenFastCheckout({
                productId: isFull ? '' : PROMO_TARIFFS?.familyPromo,
                quota: overquota.original,
                onSuccess: updateFamilyData,
            })
        );
        sendFamilyAnalytics({
            eventCategory: FamilyAnalyticsCategory.pageOwner,
            action: showInvite ? FamilyAnalyticsPageActions.clickTabInvite : FamilyAnalyticsPageActions.clickButtonTry,
        });
    }, [dispatch, isFull, overquota.original, showInvite, updateFamilyData]);

    useEffect(() => {
        sendFamilyAnalytics({
            eventCategory: FamilyAnalyticsCategory.pageOwner,
            action: showInvite ? FamilyAnalyticsPageActions.showTabInvite : FamilyAnalyticsPageActions.showButtonTry,
        });
    }, []);

    return (
        <div className={styles.root}>
            <Button className={styles.button} theme="vk" fluid primary={!showInvite} onClick={showInvite ? invite : buyTariff}>
                {showInvite && <Icon24SmileAddOutline className={styles.icon} />}
                {showInvite ? 'Пригласить' : 'Оформить подписку'}
            </Button>
        </div>
    );
});

Footer.displayName = 'Footer';
