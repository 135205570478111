define(function(require, exports, module) {
	var params = require('Cloud/config'); // JSSDK

	var Params = {
		setParam: function() {
			throw new Error('You cant change server params');
		},

		getParam: function(name, defaultValue) {
			var value = params.get(name);
			return (value === undefined) ? defaultValue : value;
		},

		allParams: function() {
			return params;
		}
	};

	module.exports = Params;
});
 
