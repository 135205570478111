import api from 'Cloud/Application/api';
import { loadSharedAutoDeleteRequest, loadSharedAutoDeleteSuccess } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.module';
import { cancel, put, takeEvery } from 'redux-saga/effects';

function* loadSharedAutoDelete() {
    try {
        const res = yield Promise.resolve(api.folder.shared.links({ autodelete: true }));
        yield put(
            loadSharedAutoDeleteSuccess({
                list: [...res.list],
            })
        );
    } catch (error) {
        cancel();
    }
}

export function* watchSharedAutoDelete() {
    yield takeEvery(loadSharedAutoDeleteRequest.toString(), loadSharedAutoDelete);
}
