import React, { memo, useRef } from 'react';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';

import { FileInfoItem } from './FileInfoItem/FileInfoItem';
import styles from './FileInfoModal.css';
import { useItems } from './hooks/useItems';

type Props = {
    id: string;
    storage: EStorageType;
    onClose: () => void;
    isArchiveInfo?: boolean;
};

export const FileInfoModal = memo<Props>(({ onClose, id, storage, isArchiveInfo = false }) => {
    const { header, items } = useItems({ id, storage, isArchiveInfo });
    const scrollRef = useRef(null);

    return (
        <MobileDialog id="mobile-file-info-modal" onClose={onClose} mod="base" topmost closeOnDimmerClick scrollRef={scrollRef}>
            <div className={styles.root}>
                <p className={styles.header}>{header}</p>
                <div className={styles.content} ref={scrollRef}>
                    {items.map(({ Icon, section, active, value, rightIcon, onClick }) => (
                        <div className={styles.item} key={id + section}>
                            <FileInfoItem
                                section={section}
                                Icon={Icon}
                                active={active}
                                value={value}
                                onClick={onClick}
                                rightIcon={rightIcon}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </MobileDialog>
    );
});

FileInfoModal.displayName = 'FileInfoModal';
