import classNames from 'clsx';
import throttle from 'lodash.throttle';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { noop } from 'reactApp/utils/helpers';
import useResizeObserver from 'use-resize-observer/polyfilled';

import styles from './PaginatorLine.css';

const AUTO_PLAY_TIME_MS = 6000;

interface Props {
    count: number;
    currentIdx: number;
    onClick?({ itemIndex: number });
    autoTransition?: boolean;
    nextBlockId?: number | null;
    id?: string;
}

export const PaginatorLine = memo(function PaginatorLine({
    onClick = noop,
    count,
    currentIdx,
    autoTransition,
    nextBlockId,
    id,
}: Props): JSX.Element | null {
    const [size, setSize] = useState({ width: 0 });
    const [animate, setAnimate] = useState(false);
    const [currentId, setCurrentId] = useState<string | undefined>('');

    const handleOnResize = useMemo(() => throttle(setSize, 200), [setSize]);

    const stripes = useMemo(() => new Array<number>(count).fill(0), [count]);

    const { ref } = useResizeObserver<HTMLDivElement>({ onResize: handleOnResize });

    const handleOnClick = useCallback(
        (event) => {
            const width = size.width;

            if (!width || !count) {
                return;
            }

            const currentTargetRect = event.target?.offsetParent?.getBoundingClientRect() ?? 0;
            const itemIndex = Math.floor(((event.clientX - currentTargetRect.left) / width) * count);

            onClick({ itemIndex });
        },
        [size, count]
    );

    useEffect(() => {
        if (!count) {
            return;
        }

        let timerId;
        if (autoTransition && currentIdx <= count - 1) {
            timerId = setTimeout(() => {
                clearTimeout(timerId);
                if (autoTransition) {
                    onClick({ itemIndex: currentIdx === count - 1 ? nextBlockId : currentIdx + 1 });
                    setAnimate(true);
                } else {
                    setAnimate(false);
                }
            }, AUTO_PLAY_TIME_MS);
        }
        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, [autoTransition, currentIdx, id, onClick, setAnimate, count, nextBlockId]);

    useEffect(() => {
        if (!autoTransition || !count) {
            return;
        }
        if (currentId !== id) {
            setCurrentId(id);
            if (animate) {
                // Сбрасываем флаг при смене currentIdx
                setAnimate(false);
            }
            return;
        }
        if (!animate) {
            // Через setTimeout, чтобы анимацию включать после первого рендера
            setTimeout(() => setAnimate(true), 0);
        }
    }, [animate, autoTransition, setAnimate, id, currentId, setCurrentId, count]);

    return (
        <div className={styles.root} ref={ref} onClick={handleOnClick}>
            {stripes.map((_, idx) => {
                const isFilled = idx < currentIdx && count > 0;
                const animateCurrent = autoTransition && animate && size.width > 0 ? idx === currentIdx : false;
                let style = {};
                if (animateCurrent) {
                    style = {
                        transition: `background-position ${AUTO_PLAY_TIME_MS / 1000}s linear`,
                    };
                }

                return (
                    <div
                        style={style}
                        key={idx}
                        className={classNames({
                            [styles.stripe]: true,
                            [styles.stripe_filled]: isFilled,
                            [styles.stripe_animate]: animateCurrent,
                        })}
                    />
                );
            })}
        </div>
    );
});

PaginatorLine.displayName = 'PaginatorLine';

PaginatorLine.whyDidYouRender = true;
