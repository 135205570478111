import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { resetFace } from 'reactApp/modules/faces/faces.module';
import { filesFilterAll } from 'reactApp/modules/filesFilter/filesFilter.module';
import { downloadMobileItem } from 'reactApp/modules/modifying/modifying.actions';
import { RequiredSignUpTouchSource } from 'reactApp/modules/requiredAuthorization/constants';
import { requiredAuthorizationHelpers } from 'reactApp/modules/requiredAuthorization/helpers';
import { getSort } from 'reactApp/modules/sort/sort.selectors';
import { ESortOder } from 'reactApp/modules/sort/sort.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { RootState } from 'reactApp/store';
import { renderAuthModalDialog } from 'reactApp/ui/Mobile/AuthModal/AuthModal.helpers';
import { OptionsModalSource } from 'reactApp/ui/Mobile/OptionsModal/OptionsModal.constants';

export const useCallbacks = ({
    onClose,
    id,
    sendAnalytics,
    storage,
    isAppViewer,
    source,
}: {
    onClose: () => void;
    id: string;
    sendAnalytics?: (action: any) => void;
    storage: EStorageType;
    isAppViewer: boolean;
    source?: OptionsModalSource;
}) => {
    const dispatch = useDispatch();

    const isAnonymous = useSelector(UserSelectors.isAnonymous);

    const sort = useSelector((state: RootState) => getSort(state, { id }, storage));

    const resetFilter = useCallback(() => {
        dispatch(filesFilterAll());
        dispatch(resetFace());
        onClose();
    }, [dispatch, onClose]);

    const onDownload = useCallback(() => {
        if (isAppViewer) {
            window.CloudApp?.downloadFile?.();
        } else {
            dispatch(downloadMobileItem({ id, storage }));

            let requiredSignUpTouchSource: RequiredSignUpTouchSource | null = null;
            switch (source) {
                case OptionsModalSource.TOOLBAR:
                case OptionsModalSource.PUBLIC_FILE:
                    requiredSignUpTouchSource = RequiredSignUpTouchSource.PUBLIC_TOOLBAR;
                    break;
                case OptionsModalSource.DATALIST_ITEM:
                    requiredSignUpTouchSource = RequiredSignUpTouchSource.ITEM_MORE_BTN;
                    break;
                case OptionsModalSource.MOBILE_VIEWER:
                    requiredSignUpTouchSource = RequiredSignUpTouchSource.VIEWER_MORE_BTN;
                    break;
            }

            if (requiredSignUpTouchSource) {
                requiredAuthorizationHelpers.setRequiredSignUpTouchSource(requiredSignUpTouchSource, id);
            }
        }
        sendAnalytics?.({ action: 'download-content' });
    }, [isAppViewer, sendAnalytics, dispatch, id, storage, source]);

    const onUpload = useCallback(() => {
        if (isAnonymous) {
            renderAuthModalDialog();
        }
    }, [isAnonymous]);

    const changeSortOrder = useCallback(() => {
        toolbarActions.sort(`${sort.type}-${sort.order === ESortOder.asc ? ESortOder.desc : ESortOder.asc}`);
        onClose();
    }, [onClose, sort.order, sort.type]);

    return {
        changeSortOrder,
        onUpload,
        onDownload,
        resetFilter,
    };
};
