import { Icon16Chevron } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { memo } from 'react';
import { iconSize } from 'reactApp/ui/UploadList/Problems/CloseIcon';

import styles from './Problems.css';

interface IArrowsProps {
    fileIndex: number;
    fileLength: number;
    prevActive: boolean;
    nextActive: boolean;
    switchWarning: (params: { back: boolean; highlightFile: boolean }) => void;
}

export const Arrows = memo(({ fileIndex, fileLength, prevActive, nextActive, switchWarning }: IArrowsProps) => {
    const nextWarning = () => {
        switchWarning({ back: false, highlightFile: true });
    };

    const prevWarning = () => {
        switchWarning({ back: true, highlightFile: true });
    };

    return (
        <div className={styles.arrows}>
            <div
                className={classNames({
                    [styles.arrow]: true,
                    [styles.prevIcon]: true,
                    [styles.active]: prevActive,
                })}
                onClick={prevWarning}
            >
                <Icon16Chevron {...iconSize} />
            </div>
            <div className={styles.counter}>
                {fileIndex + 1} / {fileLength}
            </div>
            <div className={classNames({ [styles.arrow]: true, [styles.active]: nextActive })} onClick={nextWarning}>
                <Icon16Chevron {...iconSize} />
            </div>
        </div>
    );
});

Arrows.displayName = 'Arrows';
