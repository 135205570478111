export const TreeFetch = {
    NAME: '@tree/fetch',
    REQUEST: '@tree/fetch/request',
    SUCCESS: '@tree/fetch/success',
    FAILURE: '@tree/fetch/failure',
};

export const TreeAddFolder = {
    NAME: '@tree/addFolder',
    REQUEST: '@tree/addFolder/request',
    SUCCESS: '@tree/addFolder/success',
    ADD: '@tree/addFolder/add',
};
