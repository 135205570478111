import config from 'Cloud/config';
import { getExtension, getItemNameWithoutExt } from 'reactApp/modules/file/utils';
import { renameRequest } from 'reactApp/modules/modifying/sagas/rename.saga';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';

const MAX_NAME_LENGTH = config.get('ITEM_NAME_MAX_LENGTH');

export const renderRenameDialog = (item, onSuccess) => {
    const ext = getExtension(item.name);
    const nameWithoutExt = getItemNameWithoutExt(item.name, ext);
    const selectionRange = item.isFolder ? item.name.length : nameWithoutExt.length;

    const handleOnSuccess = (newName) => {
        onSuccess?.();
        return renameRequest(item, newName.trim().normalize());
    };

    openPopupHelper({
        popupName: popupNames.RENAME_DIALOG,
        data: {
            dataQAId: 'rename-confirmation-dialog',
            renderHeader: () => 'Переименовать',
            value: item.name,
            maxLength: MAX_NAME_LENGTH,
            waitText: 'ожидайте, идет переименование',
            successButtonText: 'Переименовать',
            onSuccess: handleOnSuccess,
            selectionRange,
        },
    });
};

export const renderAskNameDialog = (
    item: { name: string; isFolder: boolean },
    onSuccess: (newName: string) => void,
    onClose?: () => void
) => {
    const ext = getExtension(item.name);
    const nameWithoutExt = getItemNameWithoutExt(item.name, ext);
    const selectionRange = item.isFolder ? item.name.length : nameWithoutExt.length;

    openPopupHelper({
        popupName: popupNames.ASK_NAME_DIALOG,
        data: {
            dataQAId: 'ask-name-confirmation-dialog',
            renderHeader: () => (item.isFolder ? 'Название папки' : 'Название файла'),
            value: item.name,
            maxLength: MAX_NAME_LENGTH,
            waitText: '',
            successButtonText: 'Сохранить',
            onSuccess: (newName) => {
                onSuccess(newName.trim().normalize());
                return Promise.resolve();
            },
            onClose,
            selectionRange,
        },
    });
};
