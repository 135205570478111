import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { differenceInYears } from 'date-fns';
import cloudIcon from 'img/icons/cloud-colored.svg?url';
import { isEmpty, path } from 'ramda';
import { TRANSIT_REGEX } from 'reactApp/modules/products/products.helpers';
import { isBeforeDate } from 'reactApp/utils/formatDate';
import { generateSubscriptionId } from 'reactApp/utils/generateSubscriptionId';
import { isYearPeriod } from 'reactApp/utils/Period';

import { PARTNER_LIST } from './subscriptions.data';
import { EStatus, ESubscriptionsTypes, ISubscription, SUBSCRIPTION_TYPE_LIST } from './subscriptions.types';

const STATUS_MAP = {
    A: 'ACTIVE',
    R: 'RENEWING',
    F: 'FINISHED',
} as const;

export const getStatus = (status?: string, autorenewal?: boolean): EStatus => {
    if (status === 'A' && typeof autorenewal === 'boolean' && !autorenewal) {
        return EStatus.SUSPENDED;
    }

    return STATUS_MAP[status || EStatus.ACTIVE];
};

export const getStatusByExpires = (expires?: number) => {
    if (!expires) {
        return 'A';
    }

    return isBeforeDate(expires) ? 'F' : 'A';
};

type SubscriptionRecord = Record<string, ISubscription>;
// TODO: переписать на корректный тип, если ничего не сломает
type SubscriptionList = any[];

type NormalizeSubscriptionsListFn = (subscription: Record<string, Record<string, undefined> | SubscriptionList>) => SubscriptionRecord;

const calculateDate = (date?: number) => date && date * 1000;

export const normalizeSubscriptionsList: NormalizeSubscriptionsListFn = (subscriptions) => {
    const today = Date.now();

    return SUBSCRIPTION_TYPE_LIST.reduce((subscriptionsAcc, type) => {
        if (!subscriptions?.[type] || (!!subscriptions[type] && isEmpty(subscriptions[type]))) {
            return subscriptionsAcc;
        }

        const subscriptionsByType = (subscriptions[type] as SubscriptionList).reduce((subscriptionByTypeAcc, subscription) => {
            const partner = subscription.partner || subscription.source;
            const start = calculateDate(subscription.start || subscription.start_at);
            const expires = calculateDate(subscription.expires || subscription.expire_at);
            const quota = subscription?.services?.quota;
            const space = bytesToNDigits(quota, 3);
            const id = generateSubscriptionId({
                period: subscription.period,
                subId: subscription.sub_id,
                start,
                quota,
                type,
                expires,
            }).toString();

            const renewAt = typeof subscription.renew_at === 'undefined' ? null : calculateDate(subscription.renew_at);
            const currentStatus = subscription?.status || getStatusByExpires(expires);
            const { disko, mailru, pro } = path(['services'], subscription) || {};

            if (start && today < start && type !== ESubscriptionsTypes.future) {
                return subscriptionByTypeAcc;
            }

            return {
                ...subscriptionByTypeAcc,
                [id]: {
                    id,
                    type,
                    start,
                    icon: subscription?.icon || cloudIcon,
                    accountLink: subscription?.account_link,
                    status: getStatus(currentStatus, subscription.autorenewal),
                    expires,
                    renewAt,
                    productId: subscription.product_id,
                    isTransit: TRANSIT_REGEX.test(subscription.product_id),
                    autorenewal: subscription.autorenewal,
                    renewable: subscription.renewable,
                    isTrial: subscription.is_trial_period,
                    // "предоплаченный" триал, не отнимает квоту при отвязке карты / отключеннии автопродления
                    isPrepaid: subscription.is_prepaid,
                    isProfessional: Boolean(mailru ? disko && pro : pro),
                    period: subscription.period,
                    partner: PARTNER_LIST.includes(partner) ? partner : undefined,
                    space,
                    flags: {
                        upload: !!path(['services', 'flags', 'UFLAG_PAID_UPLOAD'], subscription),
                        paidFeatures: !!path(['services', 'flags', 'UFLAG_PAID_ACCOUNT'], subscription),
                        pro: !!path(['services', 'flags', 'UFLAG_PRO'], subscription),
                    },
                    isMail: Boolean(mailru),
                    santa: path(['services', 'santa'], subscription),
                    isDisko: Boolean(disko),
                    diskoKey: subscription.disko_key || '',
                },
            };
        }, {} as SubscriptionRecord);

        return { ...subscriptionsAcc, ...subscriptionsByType };
    }, {} as SubscriptionRecord);
};

export const isYearSubscription = (subscription: ISubscription): boolean => {
    if (!subscription) {
        return false;
    }

    if (subscription.period) {
        return isYearPeriod(subscription.period);
    }

    return differenceInYears(subscription.expires, subscription.start) >= 1;
};
