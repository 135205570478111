import { EAllDocumentsType } from 'reactApp/modules/allDocuments/allDocuments.types';
import { ETreeRootIds } from 'reactApp/ui/TreeComponent/TreeComponent.constants';

export const AllDocumentsMeta = {
    [EAllDocumentsType.document]: {
        name: 'Документы',
        id: ETreeRootIds.alldocumentsDocument,
    },
    [EAllDocumentsType.spreadsheet]: {
        name: 'Таблицы',
        id: ETreeRootIds.alldocumentsSpreadsheet,
    },
    [EAllDocumentsType.presentation]: {
        name: 'Презентации',
        id: ETreeRootIds.alldocumentsPresentation,
    },
    [EAllDocumentsType.pdf]: {
        name: 'PDF',
        id: ETreeRootIds.alldocumentsPdf,
    },
    trashbin: {
        name: 'Корзина',
        id: ETreeRootIds.alldocumentsTrashbin,
    },
};

export const ALL_DOCUMENTS_DOMAIN_ROUTES = {
    document: '/document',
    spreadsheet: '/spreadsheet',
    presentation: '/presentation',
    pdf: '/pdf',
    trashbin: '/trashbin',
    root: '/',
};

export const AllDocumentsDomainMeta = {
    [EAllDocumentsType.document]: {
        name: 'Документы',
        id: ALL_DOCUMENTS_DOMAIN_ROUTES.document,
    },
    [EAllDocumentsType.spreadsheet]: {
        name: 'Таблицы',
        id: ALL_DOCUMENTS_DOMAIN_ROUTES.spreadsheet,
    },
    [EAllDocumentsType.presentation]: {
        name: 'Презентации',
        id: ALL_DOCUMENTS_DOMAIN_ROUTES.presentation,
    },
    [EAllDocumentsType.pdf]: {
        name: 'PDF',
        id: ALL_DOCUMENTS_DOMAIN_ROUTES.pdf,
    },
    trashbin: {
        name: 'Корзина',
        id: ALL_DOCUMENTS_DOMAIN_ROUTES.trashbin,
    },
};

export const ALL_DOCUMENTS_AVAILABLE_EXTENSIONS = {
    document: ['docx', 'odt', 'ott', 'doc', 'txt', 'rtf'],
    spreadsheet: ['xlsx', 'xltx', 'csv', 'ots', 'ods', 'xls', 'xlsm'],
    pdf: ['pdf'],
};

export const ALL_DOCUMENTS_PATH = '/alldocuments';
