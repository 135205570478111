import classNames from 'clsx';
import React, { memo, ReactElement, useCallback } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { DataListControl, EDatalistControlTheme } from 'reactApp/ui/DataListControl/DataListControl';
import { Hint } from 'reactApp/ui/Hint/Hint';

import styles from '../DataListGalleryItem.css';

export const Publish = memo(({ isActive, onClick }: { isActive: boolean; onClick: (e: React.MouseEvent) => void }): ReactElement => {
    const handleOnPublish = useCallback(
        (e) => {
            onClick(e);
        },
        [onClick]
    );

    return (
        <Hint text="Поделиться ссылкой">
            <div className={classNames(styles.control, styles.weblink)}>
                <DataListControl
                    type="publish"
                    active={isActive}
                    onClick={handleOnPublish}
                    size={28}
                    theme={EDatalistControlTheme.dark}
                    viewType={EViewMode.gallery}
                />
            </div>
        </Hint>
    );
});
Publish.displayName = 'Publish';
