import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { ReactComponent as OverwriteIcon } from '@vkontakte/icons/src/svg/20/arrow_uturn_left_outline_20.svg';
import { ReactComponent as CheckIcon } from '@vkontakte/icons/src/svg/20/check_circle_outline_20.svg';
import { ReactComponent as CopyIcon } from '@vkontakte/icons/src/svg/20/copy_outline_20.svg';
import classNames from 'clsx';
import { format } from 'date-fns';
import React from 'react';
import { RecoveryDialog } from 'reactApp/ui/FileHistory/FileHistoryDialogs/RecoveryDialog';
import { FileIcon } from 'reactApp/ui/FileIcon/FileIcon';
import { extractLocalPart } from 'reactApp/ui/FilesFoldersLogViewer/FileFolderChangesLog/FileFolderChangesList/format.utilities';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { NameComponent } from 'reactApp/ui/Name/Name';

import styles from './Item.css';
import { useItem } from './useItem';

type ItemProps = {
    id: string;
};

export const Item: React.FC<ItemProps> = ({ id }) => {
    const { showDialog, closeDialog, item, avatarUrl, handleCopy, handleRewrite, recovery } = useItem(id);
    const { actor, date, ext, name, size } = item;
    return (
        <div className={styles.item}>
            {actor && (
                <div className={styles.avatar}>
                    <img src={avatarUrl} />
                </div>
            )}
            {!actor && <FileIcon.New type={ext} size="xs" />}

            {actor && (
                <div className={styles.actorWrapper}>
                    <NameComponent className={styles.subhead} name={actor.name || extractLocalPart(actor.email)} />
                    <NameComponent className={styles.footnote} name={actor.email} truncate showTooltip />
                    <NameComponent className={styles.regular} name={name} truncate showTooltip />
                    <div className={styles.footnote}>{bytesToNDigits(size, 3).value}</div>
                </div>
            )}
            {!item.actor && (
                <div className={styles.fileNameWrapper}>
                    <NameComponent className={styles.subhead} name={name} truncate showTooltip />
                    <div className={styles.footnote}>{bytesToNDigits(size, 3).value}</div>
                </div>
            )}

            <div className={styles.timeWrapper}>
                <span className={classNames(styles.time, styles.footnoteLarge)}>{format(date, 'HH:mm')}</span>
            </div>

            <div className={styles.buttonsWrapper}>
                {item.isCurrent && (
                    <div className={styles.checkButton}>
                        <Hint text="Текущая версия файла">
                            <CheckIcon />
                        </Hint>
                    </div>
                )}

                {!item.isCurrent && (
                    <>
                        <Hint text="Создать копию">
                            <div className={styles.button} onClick={handleCopy}>
                                <CopyIcon />
                            </div>
                        </Hint>
                        <Hint text="Перезапись">
                            <div className={styles.button} onClick={handleRewrite}>
                                <OverwriteIcon />
                            </div>
                        </Hint>
                    </>
                )}
            </div>
            {showDialog && <RecoveryDialog type={showDialog} onClose={closeDialog} fileName={item.name} onSuccess={recovery} />}
        </div>
    );
};
