import app from 'Cloud/Application/app';
import { useSelector } from 'react-redux';
import { isDocument, isFolder, isImage, isMediaFolderAndGalleryEnabled } from 'reactApp/modules/file/utils';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getCurrentItem, getStorageCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { RootState } from 'reactApp/store';

import { ERegisterCloudTooltipType, registerCloudTooltipData } from './RegisterCloudTooltip.data';

export const useTooltipData = () => {
    const storage = useSelector(getCurrentStorage);
    const { isStock } = getStorage(storage);
    const currentFolder = useSelector((state: RootState) => getStorageCurrentFolder(state, storage));
    const isMediaFolder = useSelector((state: RootState) => isMediaFolderAndGalleryEnabled(state, currentFolder, storage));
    let currentItem = useSelector((state: RootState) => getCurrentItem(state));

    // TODO: CLOUDWEB-14460 - у стоков нет норм селектора, прихоидтся использовать app.current().
    if (isStock) {
        currentItem = app.current();
    }

    if (isFolder(currentItem) && isMediaFolder) {
        return registerCloudTooltipData[ERegisterCloudTooltipType.gallery];
    }

    if (isFolder(currentItem) && !isMediaFolder) {
        return registerCloudTooltipData[ERegisterCloudTooltipType.folder];
    }

    if (isDocument(currentItem)) {
        return registerCloudTooltipData[ERegisterCloudTooltipType.docs];
    }

    if (isImage(currentItem)) {
        return registerCloudTooltipData[ERegisterCloudTooltipType.image];
    }

    return registerCloudTooltipData[ERegisterCloudTooltipType.file];
};
