import classNames from 'clsx';
import React, { memo, ReactElement } from 'react';
import { noopVoid } from 'reactApp/utils/helpers';

import styles from './SidebarSubscriptionItem.css';

export enum ELabelTheme {
    pro = 'pro',
    discount = 'discount',
}

interface IProps {
    icon: ReactElement;
    title: ReactElement;
    description: ReactElement;
    label?: string;
    labelTheme?: ELabelTheme;
    onClick?: () => void;
    isDisable?: boolean;
}

export const SidebarSubscriptionItem = memo<IProps>(
    ({ icon, title, description, label, labelTheme = ELabelTheme.discount, onClick = noopVoid, isDisable }): ReactElement => (
        <div
            className={classNames(styles.root, {
                [styles.root_disable]: isDisable,
            })}
            onClick={onClick}
        >
            <div className={styles.icon}>{icon}</div>
            <div className={styles.content}>
                {label && (
                    <div
                        className={classNames({
                            [styles.label]: true,
                            [styles[`label_${labelTheme}`]]: !!labelTheme,
                        })}
                    >
                        {label}
                    </div>
                )}
                <div className={styles.title}>{title}</div>
                <div className={styles.description}>{description}</div>
            </div>
        </div>
    )
);

SidebarSubscriptionItem.displayName = 'SidebarSubscriptionItem';
