import { isMediaFolder, isMediaFolderAndGalleryEnabled } from 'reactApp/modules/file/utils';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { loadSettingsRequest, setQueryParams, setViewMode } from 'reactApp/modules/settings/settings.module';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { getStorageCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { store } from 'reactApp/store';

class SettingsController {
    setViewMode = (viewMode) => {
        const state = store.getState();
        const storage = getCurrentStorage(state);
        const folder = getStorageCurrentFolder(state, storage as EStorageType);
        const isMediaFolder = isMediaFolderAndGalleryEnabled(state, folder, storage);

        return store.dispatch(setViewMode({ storage, viewMode, isMediaFolder }));
    };

    loadSettingsRequest = (): void => {
        store.dispatch(loadSettingsRequest());
    };

    setQueryParams = (params): void => {
        store.dispatch(setQueryParams(params));
    };

    getViewMode = (): EViewMode => {
        const state = store.getState();
        const storage = getCurrentStorage(state);

        return SettingsSelectors.getViewByStorage(state, storage, isMediaFolder(getStorageCurrentFolder(state, storage)));
    };

    getCurrentAttachFolderId = (): string => {
        const queryParams = SettingsSelectors.getQueryParams(store.getState());
        return queryParams?.folderId || '-1';
    };
}

const settingsController = new SettingsController();

export { settingsController, SettingsController };
