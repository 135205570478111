import React, { memo, ReactElement, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { changeStorageHistory, getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getMobileBreadcrumbs, isRootCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { RootState } from 'reactApp/store';
import { Breadcrumbs } from 'reactApp/ui/Mobile/Breadcrumbs/Breadcrumbs';

export const BreadcrumbsContainer = memo(function BreadcrumbsContainer(): ReactElement {
    const storage = useSelector(getCurrentStorage);
    const { isPublicOrStock } = getStorage(storage);
    const folder = useSelector((state: RootState) => getMobileBreadcrumbs(state, storage));
    const isRoot = useSelector((state: RootState) => isRootCurrentFolder(state, storage));

    const name = (folder && 'name' in folder && folder.name) || '';
    const parent = folder && 'parent' in folder ? folder?.parent : undefined;

    const onBack = useCallback(
        () =>
            changeStorageHistory({
                id: parent,
                storage,
            }),
        [parent, storage]
    );

    return <Breadcrumbs showBackButton={!isRoot} showProtectedHint={isPublicOrStock} onClickBackButton={onBack} name={name} />;
});
