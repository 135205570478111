import { xray } from 'lib/xray';
import { PDFDocument } from 'pdf-lib';
import { getFrameSource } from 'reactApp/ui/ReactViewer/ReactViewer.helpers';

export const mergePdfs = async (pdfsToMerges: ArrayBuffer[]) => {
    const mergedPdf = await PDFDocument.create();

    for (let i = 0; i < pdfsToMerges.length; i++) {
        const pdf = await PDFDocument.load(new Uint8Array(pdfsToMerges[i]));
        const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
        copiedPages.forEach((page) => mergedPdf.addPage(page));
    }

    return await mergedPdf.save();
};

export const printPdfParts = async (content: ArrayBuffer[]) => {
    const isLoading = content?.length === 0;
    const channelId = Date.now().toString();
    const iframeSrc = getFrameSource(channelId);

    if (!iframeSrc) {
        console.error('iframeSrc missing');
        return;
    }

    if (isLoading) {
        return;
    }

    xray.send('print_process-started', { channelId });

    const iframe = document.createElement('iframe');

    const url = new URL(iframeSrc);

    iframe.src = url.href;
    iframe.style.display = 'none';

    const removeFrame = () => {
        iframe.remove();
        window.removeEventListener('focus', removeFrame);
    };

    window.addEventListener('focus', removeFrame);

    iframe.onload = function () {
        xray.send('print_process_iframe-loaded', { channelId });

        setTimeout(function () {
            if (iframe.contentWindow) {
                iframe.focus(); // Required for IE
                iframe.contentWindow.postMessage({ type: 'print', content, channelId }, url.origin);
            }
        }, 0);
    };

    iframe.onerror = () => {
        xray.send('print_process_iframe-error', { channelId, error: 'Iframe loading failed' });
    };

    document.body.appendChild(iframe);
};
