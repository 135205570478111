import { Method } from 'axios';
import { APICallV4 } from 'reactApp/api/APICall';

interface ICreateApiCall {
    id: string;
    title: string;
}

export class CreateAlbumAPICall extends APICallV4<ICreateApiCall> {
    _method = 'album/create';
    _type: Method = 'post';
}
