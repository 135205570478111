import { RefObject } from 'react';
import { EDropdownPosition } from 'reactApp/ui/Toolbar/Toolbar.types';

const PADDING = 30;

interface IFindPosition {
    ref: RefObject<HTMLElement>;
    parentRef?: RefObject<HTMLElement>;
    position?: EDropdownPosition;
}

export const findDropdownPosition =
    ({ ref, parentRef, position }: IFindPosition) =>
    (dropdownWidth: number, dropdownHeight = 0): { posX: number; posY: number } => {
        if (ref.current) {
            const elRect = ref.current?.getBoundingClientRect();
            const parentRect = parentRef?.current && parentRef.current?.getBoundingClientRect();
            const topRect = parentRect ?? elRect;

            const canPositionBottom = topRect.bottom + dropdownHeight + PADDING <= window.innerHeight;
            const canPositionLeft = elRect.left + dropdownWidth + PADDING <= window.innerWidth;
            const canPositionRight = window.innerWidth - elRect.right > dropdownWidth;

            const posY = canPositionBottom ? topRect.top + topRect.height : topRect.top - dropdownHeight;

            if ((position === EDropdownPosition.right && canPositionRight) || !canPositionLeft) {
                // выравниваем по правой стороне стороне кнопки
                return {
                    posY: posY + window.scrollY,
                    posX: elRect.right - dropdownWidth + window.scrollX,
                };
            }

            // выравниваем по левой стороне кнопки
            return {
                posY: posY + window.scrollY,
                posX: elRect.left + window.scrollX,
            };
        }

        return { posX: 0, posY: 0 };
    };
