export const REWIND_TIME = 10; // в секундах

export const enum ESides {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    CENTER = 'CENTER',
}

export const enum EWaitDelay {
    TO_SECOND_CLICK = 300,
    BEFORE_FORGET = 1000,
}
