import { WopiParams } from 'Cloud/Application/Editor/types';

const createInput = (name: string, value: string) => {
    const input = document.createElement('input');

    input.name = name;
    input.type = 'hidden';
    input.value = value;

    return input;
};

type Props = {
    container?: HTMLElement;
    action?: HTMLFormElement['action'];
    inputs?: WopiParams['accessParams'];
    target: string;
    postContentType: WopiParams['postContentType'];
};

type CreateFormFn = (props: Props) => HTMLFormElement;
/**
 * Создает форму для возможности открывать редактор на просмотр/редактирование
 */
export const createForm: CreateFormFn = ({ container: _container, action, inputs, target, postContentType }) => {
    const form = document.createElement('form');

    form.action = action || '';
    form.method = 'post';
    form.target = target || '';
    form.enctype = postContentType || '';
    Object.entries(inputs || {}).forEach(([name, value]) => {
        form.appendChild(createInput(name, value));
    });

    const container = _container || document.body;
    container.appendChild(form);

    return form;
};
