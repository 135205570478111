import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

/**
 * https://docs.google.com/spreadsheets/d/1oxESW-0Ne7WtP7gKejV6CJYSv-Q9ssFDC8MY1_chDT0/edit#gid=867542801
 */
export const sendPromocodesAnalytics = ({ action, label = '', version = 'old', product = 'cloud' }) => {
    sendDwh({
        eventCategory: ECategoryGa.promocodes,
        action,
        label,
        dwhData: {
            source: IS_PHONE_BROWSER ? 'mobile' : 'desktop',
            version,
            product,
        },
    });
};
