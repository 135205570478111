import { BizCategoryType } from 'reactApp/modules/bizCategories/bizCategories.types';
import { sortItems } from 'reactApp/modules/sort/sort.helpers';
import { isMountedFolder } from 'reactApp/modules/storage/folder.helpers';
import { CloudItem } from 'reactApp/modules/storage/storage.types';
import { Sort, SubKind } from 'reactApp/types/Tree';

const EXCLUDED_FROM_OTHER: SubKind[] = ['pdf', 'spreadsheet', 'archive', 'presentation', 'video', 'picture', 'audio', 'text'];

const filterBySubKind = (item: CloudItem, subKind: string) => !item.isFolder && 'subKind' in item && item.subKind === subKind;

export const getBizFilteredItems = (items: CloudItem[], filterCategory?: BizCategoryType): CloudItem[] => {
    if (!filterCategory || filterCategory === BizCategoryType.all) {
        return items;
    }

    switch (filterCategory) {
        case BizCategoryType.folder:
            return items.filter((item) => item.isFolder);
        case BizCategoryType.other:
            return items.filter((item) => !item.isFolder && 'subKind' in item && !EXCLUDED_FROM_OTHER.includes(item.subKind));
        case BizCategoryType.pdf:
            return items.filter(
                (item) => !item.isFolder && (item.kind === 'pdf,document' || ('subKind' in item && item.subKind === 'pdf'))
            );
        case BizCategoryType.image:
            return items.filter((item) => filterBySubKind(item, 'picture'));
        case BizCategoryType.document:
            return items.filter((item) => filterBySubKind(item, 'text'));
        default:
            return items.filter((item) => filterBySubKind(item, filterCategory));
    }
};

export const getBizSortedAndFilteredItems = (items: CloudItem[], sort: Sort, filterCategory?: BizCategoryType): CloudItem[] => {
    const filteredItems = getBizFilteredItems(items, filterCategory);

    return sortItems(filteredItems, sort) as CloudItem[];
};

export const getBizSortedItemsGroupedByMount = (sortedItems: CloudItem[]) => {
    return sortedItems
        .reduce<[CloudItem[], CloudItem[]]>(
            (acc, item) => {
                const index = isMountedFolder(item) ? 1 : 0;
                acc[index].push(item);
                return acc;
            },
            [[], []]
        )
        .flat();
};
