import { isFeature } from 'Cloud/Application/FeaturesEs6';
import store from 'lib/store';

import { searchTooltipData, SearchTooltipTypes } from './SearchTooltip.data';

export function getTooltipFlagName(email: string | null, tooltipType: SearchTooltipTypes | null): string {
    return `${email || ''}|search-tooltip-${tooltipType}`;
}

export function getTooltipAppearanceCount(tooltipFlagName) {
    const count = store.get(tooltipFlagName);

    if (!count) {
        return 0;
    }
    return count;
}

export function increaseTooltipAppearanceCount(tooltipFlagName) {
    const count = getTooltipAppearanceCount(tooltipFlagName);

    store.set(tooltipFlagName, count + 1);
}

export function setSessionTooltipVisibilityStatus(tooltipFlagName, status: 'visible' | 'closed') {
    sessionStorage.setItem(`${tooltipFlagName}-${status}`, 'true');
}

export function getSessionTooltipVisibilityStatus(tooltipFlagName, status) {
    return sessionStorage.getItem(`${tooltipFlagName}-${status}`);
}

export function getSearchTooltipType(): SearchTooltipTypes | null {
    if (isFeature(searchTooltipData[SearchTooltipTypes.file_extension_search].flag)) {
        return SearchTooltipTypes.file_extension_search;
    }

    if (isFeature(searchTooltipData[SearchTooltipTypes.file_content_search].flag)) {
        return SearchTooltipTypes.file_content_search;
    }

    return null;
}

export function getSearchFilterTooltipType(): SearchTooltipTypes | null {
    if (isFeature(searchTooltipData[SearchTooltipTypes.extension_setup_filter].flag)) {
        return SearchTooltipTypes.extension_setup_filter;
    }

    if (isFeature(searchTooltipData[SearchTooltipTypes.extension_filter].flag)) {
        return SearchTooltipTypes.extension_filter;
    }

    return null;
}
