import { captureException } from '@sentry/browser';
import { Rubles } from 'Cloud/Application/Rubles';
import config from 'Cloud/config';
import classNames from 'clsx';
import React, { memo, ReactElement } from 'react';
import { isAbAttachesCentered, isAttachUpdateLogicV2 } from 'reactApp/appHelpers/featuresHelpers';
import { ADS_TIMER_MS, ADS_VIEWER_ATTACHES_WORM_FAST_TIMER_MS, ADS_VIEWER_ATTACHES_WORM_TIMER_MS } from 'reactApp/constants';
import { useShowAdsBannerTimer } from 'reactApp/hooks/useShowAdsBannerTimer';
import { useShowAttachBannerTimer } from 'reactApp/hooks/useShowAttachBannerTimer';

import styles from './ReactViewer.css';

const DOM_ID_VIEWER_NEW_WORM = config.get('DOM_ID_VIEWER_NEW_WORM');

export const AttachesBanner = memo((): ReactElement | null => {
    const updateAds = () => {
        try {
            Rubles?.updateViewerAttachesWorm();
        } catch (error) {
            captureException(error);
        }
    };

    const renderItem = () => (
        <div
            className={classNames(styles.attachesBanner, {
                [styles.attachesBanner_centered]: isAbAttachesCentered,
            })}
            id={DOM_ID_VIEWER_NEW_WORM}
        />
    );

    // isAttachUpdateLogicV2 - константа => Порядок вызова хуков не меняется и правила работы с хуками не будут нарушены
    // TODO: оставить 1 хук после окончания АБ теста

    if (isAttachUpdateLogicV2) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useShowAttachBannerTimer({
            adsUpdateTimerMs: ADS_VIEWER_ATTACHES_WORM_TIMER_MS,
            adsFastUpdateTimerMs: ADS_VIEWER_ATTACHES_WORM_FAST_TIMER_MS,
            updateAds,
            renderItem,
        });
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useShowAdsBannerTimer({
        adsUpdateTimerMs: ADS_TIMER_MS,
        updateAds,
        renderItem,
    });
});

AttachesBanner.displayName = 'AttachesBanner';

AttachesBanner.whyDidYouRender = true;
