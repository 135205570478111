import { pathOr } from 'ramda';

export const getParams = (currentState) => {
    const queryParams = pathOr({}, ['params'], currentState);
    const { query = '', type = '', folderId = '' } = queryParams;

    if (!query && !type && !folderId) {
        return null;
    }

    return { query, type, folderId };
};
