import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { isTouchSearchHistoryEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { searchHistoryClear, searchHistoryHide, searchHistoryRemoveItem, searchLoadHistory } from 'reactApp/modules/search/search.module';
import { getSearchHistory, getSearchLoadingState } from 'reactApp/modules/search/search.selectors';
import { EActionSearchHistory } from 'reactApp/modules/search/search.types';
import { ISearchHistory } from 'reactApp/sections/MobileSearchPage/MobileSearch.data';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

export const useMobileSearchHistory = ({ inputValue }): ISearchHistory => {
    const wasSearched = useRef(false);

    const historyItems = useSelector(getSearchHistory);
    const { isLoading } = useSelector(getSearchLoadingState);
    const isPhone = Boolean(IS_PHONE_BROWSER);

    const dispatch = useDispatch();

    const removeHistoryItem = useCallback(
        (id) => {
            sendDwh({
                eventCategory: ECategoryGa.search_history,
                action: EActionSearchHistory.delete,
                dwhData: {
                    search_phrase: id,
                    count_history: historyItems.length,
                    history_pos: historyItems.findIndex((item) => item.text === id),
                    place: isPhone ? 'touch' : 'web',
                },
            });

            dispatch(searchHistoryRemoveItem(id));
        },
        [dispatch, historyItems, isPhone]
    );

    const clearHistory = useCallback(() => {
        sendDwh({
            eventCategory: ECategoryGa.search_history,
            action: EActionSearchHistory.clear,
            dwhData: {
                place: isPhone ? 'touch' : 'web',
                count_history: historyItems.length,
            },
        });
        dispatch(searchHistoryClear());
    }, [dispatch, historyItems, isPhone]);

    useEffect(() => {
        if (isTouchSearchHistoryEnabled && !inputValue) {
            dispatch(searchLoadHistory());
        }
    }, [dispatch]);

    useEffect(() => {
        if (isTouchSearchHistoryEnabled && isLoading) {
            dispatch(searchHistoryHide());
            wasSearched.current = true;
        }
    }, [dispatch, isLoading]);

    useEffect(() => {
        if (isTouchSearchHistoryEnabled && inputValue === '' && wasSearched.current) {
            dispatch(searchLoadHistory());
            wasSearched.current = false;
        }
    }, [dispatch, inputValue]);

    return {
        removeHistoryItem,
        clearHistory,
        historyItems,
    };
};
