import { Icon20Cancel, Icon20HistoryBackwardOutline } from '@vkontakte/icons';
import { Button, Cell, Group, Header, Headline } from '@vkontakte/vkui';
import React from 'react';
import { IHistoryItem } from 'reactApp/modules/search/search.types';

import styles from './SearchSuggests.css';

export interface IProps {
    onClick: (value: string) => void;
    onRemoveItem: (id: string) => void;
    onClear: () => void;
    items: IHistoryItem[];
}

export const SearchSuggests: React.FC<IProps> = ({ onClick, onRemoveItem, onClear, items }) => (
    <Group
        className={styles.container}
        header={
            <Header
                mode="primary"
                aside={
                    <Button className={styles.resetButton} hasHover={false} mode="tertiary" onClick={onClear}>
                        <Headline>Очистить</Headline>
                    </Button>
                }
            >
                <Headline weight="2">История поиска</Headline>
            </Header>
        }
    >
        {items.map(({ text }) => (
            <Cell
                hasActive={false}
                key={text}
                before={<Icon20HistoryBackwardOutline fill="var(--vkui--color_accent_gray)" />}
                after={
                    <div
                        className={styles.clearButton}
                        onClick={(event) => {
                            event.stopPropagation();
                            onRemoveItem(text);
                        }}
                    >
                        <Icon20Cancel />
                    </div>
                }
                onClick={() => onClick(text)}
            >
                {text}
            </Cell>
        ))}
    </Group>
);

SearchSuggests.displayName = 'SearchSuggests';
