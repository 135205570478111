import React from 'react';
import { connect, useSelector } from 'react-redux';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getOwner } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.selectors';
import { getPublicOwner } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import {
    VIOLATED_FILENAME_ERROR_EVENT,
    VIOLATED_FILENAME_ERROR_EVENT_DETAILS,
} from 'reactApp/modules/uploading/sagas/handleErrors/handleViolatedFileNameError';
import { isFileCancel } from 'reactApp/modules/uploadList/uploadList.getters';
import { EFileError, ICountStatistic } from 'reactApp/modules/uploadList/uploadList.model';
import { getCountStatistic, getWarningFile } from 'reactApp/modules/uploadList/uploadList.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { OverQuotaComponent } from 'reactApp/ui/UploadList/PopupWarnings/OverQuota';
import { OverWriteComponent } from 'reactApp/ui/UploadList/PopupWarnings/OverWrite';
import { ViolatedFilenameDialog } from 'reactApp/ui/ViolatedFilenameDialog/ViolatedFilenameDialog';

const mapStateToProps = (state) => ({
    countStatistic: getCountStatistic(state),
});

interface IProps {
    countStatistic: ICountStatistic;
}

const PopupWarningsContainerComponent = (props: IProps) => {
    const warningFile = useSelector(getWarningFile);
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    let problem: JSX.Element | null = null;

    const currentStorage = useSelector(getCurrentStorage);
    const { isPublic } = getStorage(currentStorage);

    const publicOwner = useSelector(getPublicOwner);
    const acOwner = useSelector(getOwner);
    const owner = isPublic ? publicOwner : acOwner;

    const currentUserEmail = useSelector(UserSelectors.getEmail);

    const isStrangerOwner = !!currentUserEmail && !!owner?.email && currentUserEmail !== owner?.email;

    if (!warningFile || isFileCancel(warningFile)) {
        return null;
    }

    switch (warningFile.error) {
        case EFileError.OVER_QUOTA_CLOUD: {
            problem = <OverQuotaComponent file={warningFile} isStrangerOwner={isStrangerOwner} />;
            break;
        }
        case EFileError.FILE_EXIST: {
            problem = <OverWriteComponent file={warningFile} isPhone={isPhone} />;
            break;
        }
        case EFileError.VIOLATED_FILENAME: {
            const skipHandler = () => {
                const event = new CustomEvent(VIOLATED_FILENAME_ERROR_EVENT, { detail: VIOLATED_FILENAME_ERROR_EVENT_DETAILS.SKIP });
                window.dispatchEvent(event);
            };
            const stopHandler = () => {
                const event = new CustomEvent(VIOLATED_FILENAME_ERROR_EVENT, { detail: VIOLATED_FILENAME_ERROR_EVENT_DETAILS.STOP });
                window.dispatchEvent(event);
            };
            const folderName = warningFile.localPath.split(`/${warningFile.name}`)[0];

            problem = (
                <ViolatedFilenameDialog
                    uploadQueueLenght={props.countStatistic.leftCount}
                    fileName={warningFile.name}
                    folderName={folderName}
                    onClose={skipHandler}
                    skip={skipHandler}
                    stop={stopHandler}
                />
            );
        }
    }

    return <>{problem}</>;
};

export const PopupWarningsContainer = connect(mapStateToProps)(PopupWarningsContainerComponent);
