import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDownloadEbookClick } from 'reactApp/modules/ebook/ebook.selectors';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { EpubPromoType } from 'reactApp/modules/promo/sagas/epub.saga';
import { Button } from 'reactApp/ui/Button/Button';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipAppearance, ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { sendXray } from 'reactApp/utils/ga';

import styles from './Stub.css';

const sendStubRadar = (id: string, action: string, label = '') => sendXray(['viewstub', 'ebook', id.toLowerCase(), action, label]);

interface Props {
    fileHash?: string;
    ext?: string;

    onActionButtonClick?(): void;
}

export const EbookStub = memo(({ fileHash, onActionButtonClick, ext }: Props) => {
    const [showEpubPromoId, setShowEpubPromoId] = useState(false);
    const targetRef = useRef<HTMLDivElement | null>(null);

    const epubPromo = useSelector(PromoSelectors.pullPromo(EPromoType.epubPromo));
    const isDownloadEbookClick = useSelector(getDownloadEbookClick);

    const lastReadData = useMemo(() => {
        if (!fileHash) {
            return null;
        }
        const lastReadStore = storeHelper.getValue('ebooklast');
        return lastReadStore?.[fileHash];
    }, [fileHash]);

    const showEpubTipId = useMemo<EpubPromoType | null>(() => {
        if (!epubPromo?.tipType?.length) {
            return null;
        }
        if (epubPromo.tipType.includes(EpubPromoType.first)) {
            return EpubPromoType.first;
        }

        if (epubPromo.tipType.includes(EpubPromoType.continueRead) && lastReadData) {
            return EpubPromoType.continueRead;
        }

        if (epubPromo.tipType.includes(EpubPromoType.download) && isDownloadEbookClick) {
            return EpubPromoType.download;
        }

        return null;
    }, [epubPromo?.tipType, lastReadData, isDownloadEbookClick]);

    const handleCloseTip = useCallback((e) => e.stopPropagation(), []);

    const handleClosePromo = useCallback(() => {
        if (showEpubTipId && epubPromo) {
            sendStubRadar(`tip${showEpubTipId}`, 'close');
            epubPromo?.onClose();
        }
    }, [epubPromo, showEpubTipId]);

    const onBttnClick = useCallback(
        (e) => {
            e.stopPropagation();
            onActionButtonClick?.();
            handleClosePromo();
            setShowEpubPromoId(false);
            sendStubRadar(`bttn_read${lastReadData ? 'cont' : ''}`, 'click', ext);
        },
        [onActionButtonClick, handleClosePromo]
    );

    useEffect(() => {
        if (showEpubTipId) {
            setShowEpubPromoId(true);
            epubPromo?.onShow();
            epubPromo?.onMarkShown(showEpubTipId);

            sendStubRadar(`tip${showEpubTipId}`, 'show');
        }
    }, [showEpubTipId]);

    useEffect(() => {
        sendStubRadar(`bttn_read${lastReadData ? 'cont' : ''}`, 'show', ext);
    }, [ext]);

    useEffect(() => {
        return () => {
            handleClosePromo();
        };
    }, []);

    return (
        <div className={styles.buttonWrapper} ref={targetRef} onClick={handleCloseTip}>
            <Button theme="octavius" data-name="stub-action" className={styles.read_button} onClick={onBttnClick} primary>
                <span>{lastReadData ? 'Продолжить чтение' : 'Читать книгу'}</span>
            </Button>
            {Boolean(showEpubPromoId) && (
                <FloatingTooltip
                    target={targetRef}
                    text={
                        showEpubTipId === EpubPromoType.continueRead
                            ? 'Облако откроет, где остановились'
                            : 'Читайте книги когда удобно. Облако\nзапомнит, где вы остановились'
                    }
                    placement={ETooltipPlacement.bottom}
                    appearance={ETooltipAppearance.dark}
                    closeOnOutsideClick={false}
                    onClose={handleClosePromo}
                    qaId={`tooltip-epub-viewer-${showEpubTipId}`}
                />
            )}
        </div>
    );
});

EbookStub.displayName = 'EbookStub';
