import { EDITORS_CONFIG, VIEWERS_CONFIG } from 'reactApp/appHelpers/configHelpers';
import { handleReadyCoEditPublicAlert } from 'reactApp/modules/editor/sagas/coEditPublicAlert';
import { put, takeLatest } from 'redux-saga/effects';
import { sortEditors } from 'server/helpers/editors/sortEditors';
import { ServerEditor } from 'server/helpers/editors/types';

import { composeAvailableEditors, composeExtensions, composeSharedExtensions } from './editor.helpers';
import { initEditor, readyEditor as readyEditorAction } from './editor.module';

export function* setEditor() {
    const editors: ServerEditor[] = (EDITORS_CONFIG.length ? EDITORS_CONFIG : VIEWERS_CONFIG).sort(sortEditors);
    const extensions = composeExtensions(editors);
    const availableEditors = composeAvailableEditors(editors, extensions);
    const hasSharedEditor = editors.some((editor) => 'x-in-shared-folder' in editor);
    const sharedExtensions = composeSharedExtensions(editors, extensions);

    yield put(initEditor({ availableEditors, hasSharedEditor, sharedExtensions, extensions }));
    yield takeLatest(readyEditorAction, handleReadyCoEditPublicAlert);
}
