import { path, sortBy, values } from 'ramda';
import { getGiftsRegExp } from 'reactApp/modules/features/features.selectors';
import { createSelector } from 'reselect';

const getGifts = (state) => state.gifts.list;

export const sortBySpace = createSelector(getGifts, (gifts) => sortBy(path(['space', 'original']), values(gifts)));
export const getAvaliableGifts = createSelector(sortBySpace, (gifts) => gifts.filter((gift) => gift.available));
export const getGiftById = createSelector(
    getGifts,
    (state, id) => id,
    (list, id) => list[id]
);

export const getGiftsList = createSelector(getAvaliableGifts, getGiftsRegExp, (list, regExp) =>
    list.filter((gift) => new RegExp(regExp).test(gift.id))
);
