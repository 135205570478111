import classNames from 'clsx';
import React, { memo } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import styles from 'reactApp/ui/PollPopup/PollPopup.css';

export const FooterButton = memo(({ onClick, enableSubmit, isPhone }: { onClick(): void; enableSubmit: boolean; isPhone: boolean }) => (
    <div className={classNames(styles.buttonsWrapper, { [styles.buttonsWrapper_mobile]: isPhone })}>
        <Button
            className={styles.button}
            data-name="confirm"
            onClick={onClick}
            theme={isPhone ? 'vk' : 'octavius'}
            primary
            disabled={!enableSubmit}
        >
            Отправить
        </Button>
    </div>
));

FooterButton.displayName = 'FooterButton';
