import { clear, entries } from 'idb-keyval';
import { logger } from 'lib/logger';
import { groupBy } from 'ramda';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { isFolder } from 'reactApp/modules/file/utils';
import { loadFolderSuccess } from 'reactApp/modules/home/home.actions';
import { getHomeItemById } from 'reactApp/modules/home/home.selectors';
import { loggerSaga } from 'reactApp/modules/logger/logger.saga';
import { getCurrentRouteId, getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { setUploadFromAction } from 'reactApp/modules/upload/upload.module';
import { sendGaUploaderNew } from 'reactApp/modules/uploading/helpers/uploading.helpers';
import { processInputChange } from 'reactApp/modules/uploading/sagas/processInputChange';
import {
    clearContinueUploadFilesAction,
    continueUploadAction,
    hideContinueUploadAction,
    showContinueUploadAction,
} from 'reactApp/modules/uploadList/continueUpload/continueUpload.module';
import { folderCloudPath } from 'reactApp/modules/uploadList/uploadList.helpers';
import { sendXray } from 'reactApp/utils/ga';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';

export function* checkContinueUpload() {
    try {
        const storage = yield select(getCurrentStorage);
        const { isPrivatePage, isHome } = getStorage(storage);

        if (!isPrivatePage) {
            return;
        }

        let fileHandleEntries;
        try {
            fileHandleEntries = yield call(entries);
        } catch (error) {
            logger.error(logger);
            // eslint-disable-next-line sonarjs/no-duplicate-string
            sendXray(['uploader', 'idb-keyval', 'entries', 'error']);
        }

        if (!fileHandleEntries || !fileHandleEntries.length) {
            return;
        }

        const currentRoute = yield select(getCurrentRouteId);
        const id = isHome ? currentRoute : ROOT_FOLDER_ID;
        let homeItem = yield select(getHomeItemById, id);

        if (!homeItem) {
            yield take(loadFolderSuccess);

            homeItem = yield select(getHomeItemById, id);
        }

        if (!isFolder(homeItem)) {
            return;
        }

        yield put(showContinueUploadAction());

        sendGaUploaderNew('file', 'continue_check');
    } catch (error: any) {
        logger.error(logger);
        yield call(loggerSaga, error);
    }
}

function* continueUpload() {
    try {
        yield put(hideContinueUploadAction());

        let fileHandleEntries;
        try {
            fileHandleEntries = yield call(entries);
        } catch (error) {
            logger.error(logger);
            sendXray(['uploader', 'idb-keyval', 'entries', 'error']);
        }

        if (!fileHandleEntries) {
            return;
        }

        sendGaUploaderNew('file', 'continue_accept');

        yield put(setUploadFromAction('continue_upload'));

        const list = groupBy<[string, File]>((item) => folderCloudPath(item[0]), fileHandleEntries);
        for (const originalCloudPath of Object.keys(list)) {
            const group = list[originalCloudPath];
            yield call(
                processInputChange,
                group.map((pair) => pair[1]),
                {
                    continueUpload: true,
                    originalCloudPath,
                }
            );
        }

        sendGaUploaderNew('file', 'continue_accept_file');
    } catch (error: any) {
        logger.error(logger);
        yield call(loggerSaga, error);
    }
}

function* clearContinueUploadFiles() {
    try {
        yield put(hideContinueUploadAction());

        sendGaUploaderNew('file', 'continue_cancel');

        try {
            yield call(clear);
        } catch (error) {
            logger.error(logger);
            sendXray(['uploader', 'idb-keyval', 'clear', 'error']);
        }
    } catch (error: any) {
        logger.error(logger);
        yield call(loggerSaga, error);
    }
}

export function* watchContinueUploadRoot() {
    yield takeLatest(continueUploadAction.toString(), continueUpload);
    yield takeLatest(clearContinueUploadFilesAction.toString(), clearContinueUploadFiles);
}
