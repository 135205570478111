export const POLL_DLG_ID = 'poll-dlg';
export const OTHER_ID = 'Другое';

export const MAX_COMMENT_LENGTH = 200;

export const getReasons = (isPhone = false) => [
    'Достаточно бесплатной версии',
    'Есть подписка на другое облако',
    'Технические проблемы',
    isPhone ? 'Есть подписка на другой тариф' : 'Высокая цена',
    isPhone ? 'Высокая цена' : 'Есть подписка на другой тариф',
    OTHER_ID,
];
