import { LoadingState } from 'reactApp/types/commonStates';

export interface FamilyUser {
    user: string;
    link: string;
    used: number;
    img: string;
    name: string;
}

export interface FamilyItem {
    count: number;
    family: string;
    invite_link: {
        link: string;
        available: number;
        expire_at?: number;
    };
    name: string;
    owner: string;
    quota: number;
    used: number;
    user_used: number;
    base_quota?: number;
    users: FamilyUser[];
}

export interface FamilyPlan {
    user_img: string;
    user_name: string;
    family_quota: number;
}

export interface FamilyState extends LoadingState {
    list: Record<string, FamilyItem>;
    familyPlan?: FamilyPlan;
    loadingLinkState: LoadingState;
}

export interface FamilyInviteLink {
    available: number;
    created_at: number;
    expire_at: number;
    owner: string;
    link: string;
    quota: number;
}

export interface InitFamilyProps {
    withQuota?: boolean;
}

export interface RemoveFamilyProps {
    deleteAll?: boolean;
    user?: string;
}

export interface FamilyLinkUpdatePayload {
    link: FamilyItem['invite_link'];
    family: string;
}

export const getStateWithErrorLoading = (state) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    hasError: true,
});

export interface InitFamilyOnboardingProps {
    redirect?: boolean;
}
