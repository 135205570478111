import classNames from 'clsx';
import React, { memo, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { getFilesWithFaceIdxs, getSelectedFaceId } from 'reactApp/modules/faces/faces.selectors';
import { getFolderVerifiedByKaspersky } from 'reactApp/modules/file/utils';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getCurrentCount, isCurrentLoaded } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { RootState } from 'reactApp/store';
import styles from 'reactApp/ui/FilesCounter/FilesCounter.css';
import { ProtectedHint } from 'reactApp/ui/ProtectedHint/ProtectedHint';
import { filesPlural, foldersPlural } from 'reactApp/utils/pluralHelpers';

interface IProps {
    className?: string;
    files?: number;
    folders?: number;
    all?: number;
    isLoaded?: boolean;
}

export const FilesCounter = memo(({ className, files, folders, all, isLoaded }: IProps): ReactElement | null => {
    const count = useSelector(getCurrentCount);
    const storage = useSelector(getCurrentStorage);
    const isLoadedFromSelector = useSelector(isCurrentLoaded);
    let counterText: string | null = null;

    const selectedFaceId = useSelector(getSelectedFaceId);
    // @ts-ignore
    const filesWithFaces = useSelector((state) => getFilesWithFaceIdxs(state, selectedFaceId));
    if (selectedFaceId && filesWithFaces?.length) {
        counterText = `${filesWithFaces.length} фото с этим человеком`;
    }

    const isLoadedVal = isLoaded || isLoadedFromSelector;
    const allCount = all ?? count.all;
    const foldersCount = folders ?? count.folders;
    const filesCount = files ?? count.files;

    const folderVerifiedByKaspersky = useSelector((state: RootState) => getFolderVerifiedByKaspersky(state, storage));
    const showProtectedHint = storage === EStorageType.public && folderVerifiedByKaspersky;

    if (!allCount || !isLoadedVal) {
        return null;
    }

    const getText = (): string => {
        let countText = '';

        if (foldersCount) {
            countText += `${foldersCount} ${foldersPlural(foldersCount)}`;
        }

        if (filesCount && foldersCount) {
            countText += ', ';
        }

        if (filesCount) {
            countText += `${filesCount} ${filesPlural(filesCount)}`;
        }

        return countText;
    };

    return (
        <div className={classNames(className, styles.root)}>
            {counterText ?? getText()}
            {showProtectedHint && <ProtectedHint text="Файлы проверены антивирусом Касперского" hover />}
        </div>
    );
});

FilesCounter.displayName = 'FilesCounter';
