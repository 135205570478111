import React, { memo, MouseEvent } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { DataListControl, EDatalistControlTheme } from 'reactApp/ui/DataListControl/DataListControl';
import { Hint } from 'reactApp/ui/Hint/Hint';

import styles from '../DataListGalleryItem.css';

export const Checkbox = memo(({ isActive, onClick }: { isActive: boolean; onClick: (e: MouseEvent) => void }) => {
    return (
        <Hint text={isActive ? 'Снять выделение' : 'Выделить'}>
            <div className={styles.check}>
                <DataListControl
                    type="check"
                    active={isActive}
                    onClick={onClick}
                    size={28}
                    theme={EDatalistControlTheme.dark}
                    viewType={EViewMode.gallery}
                />
            </div>
        </Hint>
    );
});

Checkbox.displayName = 'Checkbox';
