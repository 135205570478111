define(function(require, exports, module) {
    'use strict';

    const { isFeature, enableFeature, disableFeature, getFeature, getFeatureParam, features } = require('./FeaturesEs6').default;

    function moduleExports() {
        // Для совместимости реализуем старый интерфейс,
        // когда модуль используется в Application.js
        // и должен экспортировать свои методы
        // в публийчный неймспейс "app".
        return {
            isFeature,
            enableFeature,
            disableFeature,
            getFeature,
            getFeatureParam,
            features,
        };
    }

    Object.assign(moduleExports, moduleExports(), features);

    module.exports = moduleExports;
});
