// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
    Icon20CalendarOutline,
    Icon20HomeArrowDownOutline,
    Icon20MailOutline,
    Icon20MailStackOutline,
    Icon20ReplyOutline,
} from '@vkontakte/icons';
import React, { ReactElement } from 'react';
import { IS_B2B_BIZ_USER, IS_DOCUMENTS_DOMAIN, IS_MY_TEAM, IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';
import { attachFoldersRequest } from 'reactApp/modules/attaches/attaches.actions';
import { AttachesSelectors } from 'reactApp/modules/attaches/attaches.selectors';
import {
    getFeatureAlbumsPage,
    getFeatureAttachesEnabled,
    getFeatureDocuments,
    getFeaturePublicAutoDeletePromo,
} from 'reactApp/modules/features/features.selectors';
import { getDiscountGiftList } from 'reactApp/modules/giftReceived/giftReceived.selectors';
import { loadHomeFolderRequest } from 'reactApp/modules/home/home.actions';
import { getFolderListById } from 'reactApp/modules/home/home.selectors';
import { sortDiscountPromoBySpace } from 'reactApp/modules/promoTariffs/promoTariffs.selectors';
import { getCurrentRouteId, getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { isDomainFolder, isMountedFolder, isPublicFolder, isSharedFolder } from 'reactApp/modules/storage/folder.helpers';
import { isIntegrationStorage } from 'reactApp/modules/storage/storage.helpers';
import { isReadOnlyMountedOrShared } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { RootState } from 'reactApp/store';
import { ESelectFolderDialogMod } from 'reactApp/ui/SelectFolderDialog/SelectFolderDialog.types';
import { ETreeNodeMode, ETreeRootIds } from 'reactApp/ui/TreeComponent/TreeComponent.constants';
import { treeRootAllDocumentsConfig, treeRootAllDocumentsDomainConfig } from 'reactApp/ui/TreeComponent/TreeComponent.data';
import { emptyArray } from 'reactApp/utils/helpers';
import { createSelector } from 'reselect';

import { TreeNodeData } from './TreeNode.types';

export const getCurrentTreeState = (state): { id: string; storage: EStorageType } => {
    const currentStorage = getCurrentStorage(state) as EStorageType;
    const currentRouteId = getCurrentRouteId(state);
    let currentId = '';

    if (currentStorage) {
        if (
            currentStorage === EStorageType.favorites ||
            currentStorage === EStorageType.trashbin ||
            currentStorage === EStorageType.sharedIncoming ||
            currentStorage === EStorageType.sharedLinks ||
            currentStorage === EStorageType.recommend ||
            currentStorage === EStorageType.start ||
            currentStorage === EStorageType.gallery ||
            currentStorage === EStorageType.sharedAutodelete ||
            currentStorage === EStorageType.feed ||
            currentStorage === EStorageType.documents ||
            currentStorage === EStorageType.albums
        ) {
            currentId = `/${currentStorage}`;
        } else if (currentStorage === EStorageType.attaches) {
            currentId = SettingsSelectors.getQueryParams(state)?.folderId || `/${EStorageType.attaches}`;
        } else {
            currentId = currentRouteId;
        }

        currentId = currentStorage + currentId;
    }

    return { id: currentId, storage: currentStorage };
};

const hideChildren = (item, dialogMod) =>
    dialogMod === ESelectFolderDialogMod.createShare && (isMountedFolder(item) || isSharedFolder(item) || isDomainFolder(item));

const getHomeFoldersTree = createSelector(
    (state: RootState): RootState => state,
    (state, dialogMod?: ESelectFolderDialogMod) => dialogMod,
    (state, dialogMod?: ESelectFolderDialogMod, skipRoFolders?: boolean) => skipRoFolders || false,
    (state, dialogMod?: ESelectFolderDialogMod, skipRoFolders?: boolean): TreeNodeData[] => {
        const mapFolderItemToTreeNodeData = (list) =>
            list
                ? list
                      .filter((item) => item?.isFolder)
                      .map((item) => {
                          const isRo = isReadOnlyMountedOrShared(state, item);

                          if (isRo && skipRoFolders) {
                              return;
                          }

                          return {
                              id: item.id,
                              href: item.href,
                              parent: item.parent,
                              isFolder: item.isFolder,
                              title: item.name,
                              storage: EStorageType.home,
                              folderCounter: !!item.count?.folders && !hideChildren(item, dialogMod) ? item.count?.folders : 0,
                              children: mapFolderItemToTreeNodeData(getFolderListById(state, item.id)),
                              isMountedOrShared: item.kind === 'mounted' || isDomainFolder(item) || item.kind === 'shared',
                              kind: item.kind,
                              isPublic: isPublicFolder(item),
                          };
                      })
                      .filter(Boolean)
                : emptyArray;

        return mapFolderItemToTreeNodeData(getFolderListById(state, '/'));
    }
);

const getAttachesFolderIcon = ({ type }: { type: string }): ReactElement | null => {
    switch (type) {
        case 'inbox':
            return <Icon20MailOutline />;
        case 'sent':
            return <Icon20ReplyOutline />;
        case 'newsletters':
            return <Icon20MailStackOutline />;
        case 'tomyself':
            return <Icon20HomeArrowDownOutline />;
        case 'user':
            return <Icon20CalendarOutline />;
        default:
            return null;
    }
};

const getAttachesFoldersTree = createSelector(
    (state: RootState): RootState => state,
    (state): TreeNodeData[] => {
        const mapAttachItemToTreeNodeData = (list) =>
            list
                ? list.map((item) => {
                      return {
                          id: item.id,
                          href: item.href,
                          title: item.text,
                          storage: EStorageType.attaches,
                          folderCounter: item.items ? item.items.length : 0,
                          children: mapAttachItemToTreeNodeData(item.items),
                          icon: getAttachesFolderIcon(item),
                      };
                  })
                : emptyArray;

        const folders = Array.from(Object.values(AttachesSelectors.getAttachesFoldersTree(state, '')));

        return mapAttachItemToTreeNodeData(folders);
    }
);

export const getFoldersTree = createSelector(
    (state: RootState): RootState => state,
    (state, items: TreeNodeData[]): TreeNodeData[] => items,
    (state, items: TreeNodeData[], dialogMod?: ESelectFolderDialogMod) => dialogMod,
    (state, items: TreeNodeData[], dialogMod?: ESelectFolderDialogMod, skipRoFolders?: boolean) => skipRoFolders || false,
    // eslint-disable-next-line complexity
    (state, items: TreeNodeData[], dialogMod?: ESelectFolderDialogMod, skipRoFolders?: boolean): TreeNodeData[] => {
        const isBizUser = UserSelectors.isBizUser(state);
        const storage = getCurrentStorage(state);

        items = [...items];

        let idx = items.findIndex((item) => item?.id === ETreeRootIds.home);
        if (idx >= 0) {
            items[idx] = {
                ...items[idx],
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                children: getHomeFoldersTree(state, dialogMod, skipRoFolders),
            };
            if (isBizUser) {
                items[idx].title = 'Личные папки';
                items[idx].children = items[idx].children?.filter((item) => !isDomainFolder(item) && item.kind !== 'mounted');
            }
        }

        // у storage === integration отображаем только хомяк
        if (isIntegrationStorage(storage)) {
            return [items[idx]];
        }

        idx = items.findIndex((item) => item?.id === ETreeRootIds.domain);
        if (idx >= 0) {
            if (isBizUser) {
                let children: TreeNodeData[] = [];
                const incomingFolders = getHomeFoldersTree(state).filter((item) => item.kind === 'mounted');

                children = getHomeFoldersTree(state).filter(isDomainFolder).concat(incomingFolders);

                if (children && children.length > 0) {
                    items[idx] = {
                        ...items[idx],
                        children,
                    };
                }
            } else {
                items.splice(idx, 1);
            }
        }

        idx = items.findIndex((item) => item?.id === ETreeRootIds.attaches);

        if (idx >= 0 && IS_B2B_BIZ_USER) {
            if (getFeatureAttachesEnabled(state)) {
                items[idx] = {
                    ...items[idx],
                    children: getAttachesFoldersTree(state),
                };
            } else {
                items = items.filter((item) => item?.id !== ETreeRootIds.attaches);
            }
        }

        idx = items.findIndex((item) => item?.id === ETreeRootIds.recommend);
        if (idx >= 0 && IS_ONPREMISE) {
            items = items.filter((item) => item?.id !== ETreeRootIds.recommend);
        }

        idx = items.findIndex((item) => item?.id === ETreeRootIds.start);

        if (idx >= 0 && !!items[idx].children && IS_MY_TEAM) {
            items[idx].children = items[idx]?.children?.filter((item) => item?.id !== ETreeRootIds.gallery);
        }

        if (idx >= 0 && !!items[idx].children && !getFeaturePublicAutoDeletePromo(state)) {
            items[idx].children = items[idx]?.children?.filter((item) => item?.id !== ETreeRootIds.sharedAutodelete);
        }

        if (idx >= 0 && !!items[idx].children && !getFeatureDocuments(state)) {
            items[idx].children = items[idx]?.children?.filter((item) => item?.id !== ETreeRootIds.documents);
        }

        if (idx >= 0 && !!items[idx].children && !getFeatureAlbumsPage(state)) {
            items[idx].children = items[idx]?.children?.filter((item) => item?.id !== ETreeRootIds.albums);
        }

        idx = items.findIndex((item) => item?.id === ETreeRootIds.promocodes);

        if (idx >= 0 && !sortDiscountPromoBySpace(state).length && !getDiscountGiftList(state).length) {
            items = items.filter((item) => item?.id !== ETreeRootIds.promocodes);
        }

        if (IS_MY_TEAM) {
            items = items.filter((item) => item.id !== ETreeRootIds.recommend);
        }

        return items;
    }
);

export const loadFolder = async ({ id, storage, dispatch }): Promise<void> => {
    if (storage === EStorageType.attaches) {
        dispatch(attachFoldersRequest());
        return;
    }

    if (id === ETreeRootIds.domain || id === ETreeRootIds.promocodes) {
        return;
    }

    if (storage === EStorageType.home) {
        dispatch(loadHomeFolderRequest({ id, isFolder: true }));
    }
};

/**
 * Сторы доступные для открытия бокового меню по прямому пути
 */
const AVAILABLE_TO_FORCE_OPEN_STORAGE_FROM_START_AND_INCOMING = [
    EStorageType.sharedLinks,
    EStorageType.sharedIncoming,
    EStorageType.feed,
    EStorageType.favorites,
    EStorageType.gallery,
    EStorageType.albums,
    EStorageType.sharedAutodelete,
    EStorageType.documents,
];

export const checkForceOpenFoldersOnThePath = (
    id: string,
    selectedStorage: EStorageType,
    selectedId: string,
    children?: TreeNodeData[],
    mod?: ETreeNodeMode
) => {
    const { pathname, search } = window?.location || {};
    const _selectedId = selectedId.match(/(\d+)/)?.[0];

    /**
     * Раскрываем аттачи (Из почты) в случае присутствия search в URI.
     * @example /attaches/?folderId=0
     */
    if (selectedStorage === EStorageType.attaches && search && selectedId.includes(EStorageType.attaches) && _selectedId) {
        return children?.some((c) => c.id === _selectedId) || children?.some((c) => c.children?.some((cc) => cc.id === _selectedId));
    }
    /**
     * Раскрываем аттачи (Из почты) только в случае отсутсвия search в URI.
     * @example /attaches
     */
    if (selectedStorage === EStorageType.attaches && !search && id === ETreeRootIds.attaches) {
        return true;
    }

    /**
     * Раскрываем папку в хомяке внутри диалога выбора файлов для альбома.
     */
    if (mod === ETreeNodeMode.albumsDlg && selectedStorage === EStorageType.home) {
        return selectedId.startsWith(`${selectedStorage}${id}`);
    }

    if (
        /**
         * Раскрываем Хомяк (Все файлы) только в случае содержании в pathname минимум одной 1 папку после /home.
         * @example /home/folder
         */
        selectedStorage === EStorageType.home &&
        pathname?.split('/').filter(Boolean).length > 1
    ) {
        return decodeURIComponent(`${pathname}${search}`).includes(id);
    }

    /**
     * Раскрываем стартовую страницу (Быстрый доступ или /start) только для определенных хранилищ.
     * Частный случай это путь /incoming он нужен для корректной работы shared/incoming и shared/links
     */
    return (
        (id === ETreeRootIds.start || id === ETreeRootIds.incoming) &&
        AVAILABLE_TO_FORCE_OPEN_STORAGE_FROM_START_AND_INCOMING.includes(selectedStorage)
    );
};

/**
 * Функция создания конфига для страницы документов.
 *
 * @param {string[]} hiddenSections Секции, которые нужно скрыть.
 *
 * @return {TreeNodeData[]} Итоговый конфиг.
 */
export const getAllDocumentsTreeConfig = (hiddenSections: string[]): TreeNodeData[] => {
    const defaultConfig = IS_DOCUMENTS_DOMAIN ? [...treeRootAllDocumentsDomainConfig] : [...treeRootAllDocumentsConfig];

    return hiddenSections.length
        ? defaultConfig.filter(({ id }) => !hiddenSections.includes(id.replace('/alldocuments/', '').replace(/^\//, '')))
        : defaultConfig;
};
