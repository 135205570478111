import { PayloadAction } from '@reduxjs/toolkit';
import { delMany } from 'idb-keyval';
import { logger } from 'lib/logger';
import { splitEvery } from 'ramda';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { removeFileSuccess } from 'reactApp/modules/modifying/modifying.actions';
import { handlePublish } from 'reactApp/modules/modifying/modifying.saga';
import { getItemById } from 'reactApp/modules/storage/storage.selectors';
import { cancelUploading } from 'reactApp/modules/uploading/uploading.module';
import { askUserForConflictFiles, askUserForPaymentFiles } from 'reactApp/modules/uploadList/oldUploadListBridge';
import { checkProgressStatus } from 'reactApp/modules/uploadList/sagas/progress.saga';
import {
    getFileLoaded,
    getFileSize,
    hasAutoFix,
    hasProblemFile,
    isCancellable,
    isFileAutoFixError,
    isFileCompleteWithError,
    isFolder,
} from 'reactApp/modules/uploadList/uploadList.getters';
import { animationDelay, fileInFolder, folderCloudPath, folderName } from 'reactApp/modules/uploadList/uploadList.helpers';
import {
    EFileError,
    EFileStatus,
    EProgressStatus,
    IInputFile,
    INormalizedInputFile,
    IUpdateInputFile,
} from 'reactApp/modules/uploadList/uploadList.model';
import {
    setGroupedFiles,
    setInputFilesAction,
    setPercentLoaded,
    setProgressStatusAction,
    updateUploadFilesAction,
} from 'reactApp/modules/uploadList/uploadList.module';
import {
    getCommonSizeForCurrentPacket,
    getFolder,
    getGroupedFiles,
    getHasInput,
    getInputFile,
    getInputFiles,
    getInputFilesCurrentPacket,
    getPercentLoaded,
    getTotalCount,
    isProgressingStatus,
} from 'reactApp/modules/uploadList/uploadList.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { sendXray } from 'reactApp/utils/ga';
import { ActionsBatcher } from 'reactApp/utils/reduxHelpers';
import { actionChannel, call, delay, put, select, take } from 'redux-saga/effects';

const actionsBatcher = new ActionsBatcher((items) => {
    if (!items?.length) {
        return;
    }

    delMany(items).catch(() => {
        logger.error(logger);
        sendXray(['uploader', 'idb-keyval', 'delindex', 'error']);
    });
}, 250);

function delFromIndexDbHelper(path) {
    if (!path) {
        return;
    }
    actionsBatcher.push(path);
}

export function* updateUploadFiles(action: PayloadAction<IUpdateInputFile | IUpdateInputFile[]>) {
    const payloads = Array.isArray(action.payload) ? action.payload : [action.payload];

    try {
        for (const payload of payloads) {
            if (payload.status === EFileStatus.DELETING || !payload.status) {
                continue;
            }

            // берем файл из стора, потому что в payload может быть что-то одно: cloudPath или descriptorId
            let currentItem = (yield select(getInputFile, {
                cloudPath: payload.cloudPath,
                descriptorId: payload.descriptorId,
            })) as IInputFile;

            if (!currentItem) {
                currentItem = (yield select(getFolder, {
                    localPath: payload?.cloudPath,
                    descriptorId: payload?.descriptorId,
                })) as IInputFile;
            }

            yield handleStatus(currentItem, payload);

            yield handleHighlight(currentItem, payload);

            yield handleUploadedBytes(currentItem, payload?.loaded);

            yield checkProgressStatus();
        }
    } catch (error) {
        logger.error(error);
    }
}

export function* deferredUpdateUploadFile(action: PayloadAction<IInputFile | IInputFile[]>) {
    const payloads = Array.isArray(action.payload) ? action.payload : [action.payload];
    const hasInput = yield select(getHasInput);

    if (hasInput) {
        yield delay(1500); // TODO размер приходит раньше, чем setInputFilesAction, поэтому ждем

        yield put(updateUploadFilesAction(payloads));

        return;
    }

    for (const payload of payloads) {
        const requestChan = yield actionChannel(setInputFilesAction.toString());

        while (true) {
            yield take(requestChan);

            yield put(updateUploadFilesAction(payload));
            requestChan.close();
        }
    }
}

export function* sortFilesByError() {
    const groupedFiles: INormalizedInputFile = yield select(getGroupedFiles);
    const groupedFilesEntries: [string, IInputFile][] = Object.entries(groupedFiles);

    groupedFilesEntries.sort(([, itemA], [, itemB]) => {
        if (!itemA.error && itemB.error) {
            return 1;
        }

        if (itemA.error && !itemB.error) {
            return -1;
        }

        return 0;
    });

    // нормализуем массив
    const groupedFilesNormalized = groupedFilesEntries.reduce((acc, [key, file]) => ({ ...acc, [key]: file }), {});
    yield put(setGroupedFiles(groupedFilesNormalized));
}

function* removeStubFromDataList<T extends Pick<IInputFile, 'cloudPath'>>(currentItem: T, deleteParent = false) {
    let item = yield select(getItemById, currentItem.cloudPath);

    // если descriptorId есть, значит файл не существовал и надо удалить из даталиста
    if (item?.descriptorId) {
        let removeId = currentItem.cloudPath;
        if (deleteParent) {
            while (item.id !== ROOT_FOLDER_ID) {
                item = yield select(getItemById, item.parent);
                if (item?.descriptorId) {
                    removeId = item.id;
                } else {
                    break;
                }
            }
        }

        yield put(removeFileSuccess({ ids: [removeId] }));
    }
}

function* handleStatus(currentItem: IInputFile, payload: IUpdateInputFile) {
    if (!currentItem) {
        return;
    }

    const { cloudPath, descriptorId, localPath } = currentItem;
    const isFolderItem = isFolder(currentItem);
    const inFolder = fileInFolder(currentItem.localPath);

    switch (payload.status) {
        case EFileStatus.DONE: {
            const item = yield select(getItemById, cloudPath);

            delFromIndexDbHelper(cloudPath);

            // При загрузке в личных документов, item добавляется когда выполнится запрос doc/link
            // Поэтому тут игнорим, а в личных документах вызовем еще раз после запроса
            if (!item) {
                return;
            }

            if (item.weblink && !payload.publish) {
                yield call(handlePublish, {
                    cloudPath,
                    name: item.name,
                    type: item.type,
                    publish: item.weblink && !payload.publish,
                });
            }

            if (hasAutoFix(currentItem)) {
                const editedFile: IUpdateInputFile = {
                    cloudPath: currentItem.cloudPath,
                    descriptorId: currentItem.descriptorId,
                    hideError: false,
                    status: EFileStatus.INFO,
                };

                yield put(updateUploadFilesAction(editedFile));
            }

            break;
        }
        case EFileStatus.CANCEL: {
            if (!descriptorId) {
                return;
            }

            if (isFolderItem) {
                const files = (yield select(getInputFiles)) as IInputFile[];
                const filesInFolder = files.filter((file) => localPath === folderName(file.localPath));

                const items: IUpdateInputFile[] = [];
                const descriptors: string[] = [];
                for (const file of filesInFolder) {
                    if (file.descriptorId && isCancellable(file)) {
                        yield call(removeStubFromDataList, file);
                        delFromIndexDbHelper(file.cloudPath);
                        descriptors.push(file.descriptorId);

                        const editedFile: IUpdateInputFile = {
                            cloudPath: file.cloudPath,
                            descriptorId: file.descriptorId,
                        };
                        editedFile.status = EFileStatus.CANCEL;
                        editedFile.error = EFileError.CANCELLED_FILE;

                        items.push(editedFile);
                    }
                }

                if (descriptors.length > 0) {
                    yield put(cancelUploading({ fileId: descriptors }));
                }
                if (items.length > 0) {
                    const chunks = splitEvery<IUpdateInputFile>(100, items);
                    for (const chunk of chunks) {
                        yield put(updateUploadFilesAction(chunk));
                    }
                }

                yield call(delFromIndexDbHelper, cloudPath);

                yield call(removeStubFromDataList, currentItem);
            } else {
                yield put(cancelUploading({ fileId: descriptorId }));

                delFromIndexDbHelper(cloudPath);

                if (currentItem.error !== EFileError.FILE_EXIST) {
                    yield call(removeStubFromDataList, currentItem);
                }
            }

            if (inFolder) {
                const folder = folderCloudPath(localPath);

                const folderInfo = yield select(getItemById, folder);

                if (folderInfo && folderInfo.descriptorId && !folderInfo.childs.length) {
                    yield call(delFromIndexDbHelper, folderInfo.id);

                    yield put(removeFileSuccess({ ids: [folderInfo.id] }));
                }
            }
            break;
        }
        case EFileStatus.PAUSED:
        case EFileStatus.WARNING: {
            yield put(setProgressStatusAction({ status: EProgressStatus.PAUSE }));

            if (payload.error !== EFileError.CONNECTION_ERROR && payload.error !== EFileError.GATEWAY_ERROR) {
                yield call(delFromIndexDbHelper, cloudPath);
            }

            if (payload.error === EFileError.OVER_QUOTA_CLOUD) {
                const isB2BUser = yield select(UserSelectors.isB2BUser);

                if (!isB2BUser) {
                    return;
                }

                if (askUserForPaymentFiles) {
                    yield call(askUserForPaymentFiles, 'skip_b2b');
                }

                yield put(
                    updateUploadFilesAction({
                        ...currentItem,
                        status: EFileStatus.ERROR,
                        error: EFileError.OVER_QUOTA_CLOUD_B2B,
                        hideError: false,
                    })
                );
            }
            break;
        }
        case EFileStatus.INFO: {
            if (isFileAutoFixError(payload as IInputFile)) {
                yield call(askUserForConflictFiles, { option: 'rename', saveForAll: false });
            }
            break;
        }
        case EFileStatus.ERROR: {
            const folderPath = folderCloudPath(currentItem.cloudPath);
            const isFolderExist = !(yield select(getItemById, folderPath))?.descriptorId;
            const findFilesInFolder = (file: IInputFile) => folderCloudPath(file.cloudPath) === folderPath;

            const files = (yield select(getInputFiles)) as IInputFile[];
            const filesInFolder = files.filter(findFilesInFolder);
            const filesInFolderWithError = files.filter((f) => f.status === EFileStatus.ERROR && findFilesInFolder(f));
            // 1. Проверяем существование папки в облаке
            // 2. Сравниваем количество файлов загруженных в папку c количеством файлов, завершившиеся с
            //    ошибкой при попытке загрузки в папку
            if (filesInFolder.length === filesInFolderWithError.length && !isFolderExist) {
                yield call(removeStubFromDataList, { cloudPath: folderPath });
            }

            yield call(removeStubFromDataList, currentItem);
            if (payload.error !== EFileError.CONNECTION_ERROR && payload.error !== EFileError.GATEWAY_ERROR) {
                yield call(delFromIndexDbHelper, cloudPath);
            }
            break;
        }
        case EFileStatus.MOVED: {
            yield call(removeStubFromDataList, currentItem, true);
            break;
        }
    }
}

function* handleHighlight(currentFile: IInputFile, payload: IUpdateInputFile) {
    if (!payload.highlight) {
        return;
    }

    yield delay(animationDelay);
    yield put(updateUploadFilesAction({ descriptorId: currentFile.descriptorId, cloudPath: currentFile.cloudPath, highlight: false }));
}

function* handleUploadedBytes(currentFile: IInputFile, loadedBytes?: number) {
    if (!loadedBytes || hasProblemFile(currentFile)) {
        return;
    }

    const isProgress = (yield select(isProgressingStatus)) as boolean;
    const percentLoadedPrev = (yield select(getPercentLoaded)) as number;

    if (!isProgress && currentFile.status !== EFileStatus.DONE) {
        yield put(setProgressStatusAction({ status: EProgressStatus.PROGRESS }));
    }

    const files = (yield select(getInputFilesCurrentPacket)) as IInputFile[];
    const commonFilesSize = (yield select(getTotalCount)).totalSize || ((yield select(getCommonSizeForCurrentPacket)) as number);

    // считаем сколько байт загружено
    let sizeLoaded = 0;

    files.forEach((file) => {
        let size = getFileLoaded(file);

        if (isFileCompleteWithError(file)) {
            size = getFileSize(file);
        }

        sizeLoaded += size;
    });

    const leftDownloadSize = commonFilesSize - sizeLoaded;
    const percentPast = leftDownloadSize / (commonFilesSize / 100);
    const percentLoaded = Math.floor(100 - percentPast);

    if (percentLoadedPrev !== percentLoaded) {
        yield put(setPercentLoaded(percentLoaded));
    }
}
