import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { Icon20CloudOutline, Icon20DeleteClockAltOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import differenceInDays from 'date-fns/differenceInDays';
import { isNil } from 'ramda';
import React, { memo, useRef } from 'react';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';
import { useTooltip } from 'reactApp/ui/Tooltip/Tooltip.hooks';
import { formatAutoDeleteExpires } from 'reactApp/utils/formatDate';

import { DeleteTooltip } from '../DeleteTooltip/DeleteTooltip';
import styles from './Size.css';

interface SizeProps {
    className?: string;
    size?: number;
    isAutoDelete?: boolean;
    savedInCloud?: boolean;
    expires?: number;
    onSaveToCloud?: () => void;
    isInline?: boolean;
}

const TOOLTIP_OFFSET = {
    y: 30,
    x: 30,
};

const DELETE_TOOLTIP_ID = 'DELETE_TOOLTIP_ID';

export const Size = memo<SizeProps>(function Size({
    className,
    size,
    isAutoDelete,
    savedInCloud,
    expires,
    onSaveToCloud,
    isInline = false,
}) {
    const deleteIconRef = useRef<HTMLDivElement>(null);

    const remainingDays = typeof expires === 'number' ? differenceInDays(new Date(), new Date(expires)) : null;
    const redDaysCondition = remainingDays && remainingDays < 30;

    const { open } = useTooltip({
        id: DELETE_TOOLTIP_ID,
        defaultProps: {
            theme: 'octavius',
            target: deleteIconRef,
            offset: TOOLTIP_OFFSET,
            minWidth: false,
            placement: tooltipPlacements.LEFT,
            noArrow: true,
            children: ({ onClose }) => (
                <DeleteTooltip
                    onClose={onClose}
                    title={
                        !redDaysCondition && expires
                            ? `Файл будет удалён ${formatAutoDeleteExpires(expires, true)}`
                            : 'Файл скоро будет удалён'
                    }
                    onSaveToCloud={onSaveToCloud}
                />
            ),
        },
    });

    return (
        <div ref={deleteIconRef} className={classNames(styles.root, className)}>
            <div
                className={classNames(styles.size, {
                    [styles.delete]: redDaysCondition,
                    [styles.inline]: isInline,
                })}
            >
                {!isNil(size) && bytesToNDigits(size, 3).value}
            </div>
            {isAutoDelete && (
                <div onMouseEnter={open}>
                    <Icon20DeleteClockAltOutline
                        className={classNames(styles.icon, {
                            [styles.delete]: redDaysCondition,
                        })}
                    />
                </div>
            )}
            {savedInCloud && (
                <Hint text="Файл сохранён в Облако">
                    <Icon20CloudOutline className={styles['size-icon']} />
                </Hint>
            )}
        </div>
    );
});

Size.displayName = 'Size';
