import React, { useCallback } from 'react';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';

import styles from './PreCopyWeblinkDialog.css';

export interface IProps {
    onClose?: () => void;
    onSuccess: () => void;
    fileName?: string;
}

const buttons = [EButtonType.accept, EButtonType.cancel];

const renderHeader = () => 'Скопировать ссылку с доступом «для всех пользователей»';

export const PreCopyWeblinkDialog: React.FC<IProps> = ({ onClose, onSuccess, fileName }) => {
    const renderContent = useCallback(() => {
        return `При отправке созданной вами ссылки доступ к файлу «${fileName}» получит любой пользователь сети Интернет.`;
    }, [fileName]);

    const handleSuccess = () => {
        onSuccess();
        onClose?.();
    };

    return (
        <BaseConfirmDialog
            dataQAId="pre-copy-weblink-dialog"
            renderHeader={renderHeader}
            successButtonText="Скопировать"
            cancelButtonText="Отмена"
            buttons={buttons}
            onClose={onClose}
            onSuccess={handleSuccess}
            renderContent={renderContent}
            closeOnDimmerClick
            size="middle"
            className={styles.modal}
        />
    );
};

PreCopyWeblinkDialog.displayName = 'PreCopyWeblinkDialog';
