import { toNDigits } from '@mail/cross-sizes-utils';

import { UploadFail } from './UploadFail';

export class UserFileSizeLimitFail extends UploadFail {
    constructor(stack: Error, source: string, limit) {
        super(stack, source);

        this.limit = limit;
        limit = toNDigits({ bytes: limit, maxDigitsCount: 3 }).value;
        this.message = this.message.replace('{LIMIT}', limit);
    }

    limit = 0;
    radarName = 'fail_{SOURCE}_user-file-size-limit';
    message = [
        'Текущий тарифный план не позволяет загружать файлы больше {LIMIT}.',
        '<a href="https://help.mail.ru/cloud_web/size/increase" rel="noopener" target="_blank">Увеличить лимит</a>',
    ].join(' ');
}
