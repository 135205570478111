// TODO: CLOUDWEB-14876 - заменить на новый компонент empty-state'а после аб.
import { Spacing, Text } from '@vkontakte/vkui';
import emptyImg from 'img/empty-state/select_from_cloud.png';
import React, { memo } from 'react';

import styles from './SelectFromCloudEmpty.css';

export const SelectFromCloudEmpty = memo(() => {
    return (
        <div className={styles.root}>
            <img className={styles.image} src={emptyImg} alt="В папке нет фотографий" />
            <Spacing size={24} />
            <Text className={styles.title}>В папке нет фотографий</Text>
            <Spacing size={12} />
            <Text className={styles.text}>Загрузите фото или видео в папку, чтобы добавить их в альбом</Text>
        </div>
    );
});

SelectFromCloudEmpty.displayName = 'SelectFromCloudEmpty';
