import { Spacing, Text, Title } from '@vkontakte/vkui';
import { ReactComponent as DocxIcon } from 'img/filetype/newest/light/document_text_outline_20.svg';
import { ReactComponent as TableIcon } from 'img/filetype/newest/light/file_table_outline_20.svg';
import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { createAlbumRequest } from 'reactApp/modules/albums/albums.actions';
import { AddIcon, AlbumIcon, Icon20FolderOutline } from 'reactApp/ui/VKUIIcons';

import styles from './WelcomeNewPromo.css';

const items = [
    {
        id: 'folder',
        title: 'Папку',
        icon: <Icon20FolderOutline />,
        onClick: () => toolbarActions.create('folder', 'welcome'),
    },
    {
        id: 'album',
        title: 'Альбом',
        icon: <AlbumIcon />,
    },
    {
        id: 'docx',
        title: 'Документ',
        icon: <DocxIcon />,
        onClick: () => toolbarActions.create('docx', 'welcome'),
    },
    {
        id: 'xlsx',
        title: 'Таблицу',
        icon: <TableIcon />,
        onClick: () => toolbarActions.create('xlsx', 'welcome'),
    },
];

export const CreateBlock = memo(() => {
    const dispatch = useDispatch();
    const handleCreateAlbum = useCallback(() => {
        dispatch(createAlbumRequest());
    }, []);

    return (
        <div className={styles.block} data-qa-id="create">
            <div className={styles.icon}>
                <AddIcon />
            </div>
            <Spacing size={24} style={{ pointerEvents: 'none' }} />
            <Title className={styles.block_title} level="3">
                Создать
            </Title>
            <Spacing size={8} style={{ pointerEvents: 'none' }} />
            <Text className={styles.block_text}>Создавайте папки, документы, таблицы и делитесь с друзьями</Text>
            <Spacing size={24} style={{ pointerEvents: 'none' }} />
            <div className={styles.items}>
                {items.map((item) => (
                    <div
                        key={item.id}
                        className={styles.item}
                        onClick={item.id === 'album' ? handleCreateAlbum : item.onClick}
                        data-qa-id={`create-${item.id}`}
                    >
                        {item.icon}
                        <Spacing size={8} />
                        <Text>{item.title}</Text>
                    </div>
                ))}
            </div>
        </div>
    );
});

CreateBlock.displayName = 'CreateBlock';
