import settings from 'Cloud/settings';
import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { requestFacesListSuccess } from 'reactApp/modules/faces/faces.module';
import { hasFolderFileWithFace } from 'reactApp/modules/faces/faces.selectors';
import { getFeatureFaces } from 'reactApp/modules/features/features.selectors';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getPublicRootWeblink } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { store } from 'reactApp/store';
import { delay, put, race, take } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

export const FILTER_TIP_ID = 'filterTip';

export function* initFaceFilterPromo() {
    const storage = yield* select(getCurrentStorage);
    const { isPublic } = getStorage(storage);

    if (settings?.request?.action || IS_PHONE_BROWSER || !isPublic) {
        return;
    }

    yield race({
        done: take(requestFacesListSuccess.type),
        timeout: delay(2000),
    });

    const isFacesFeature = yield* select(getFeatureFaces);
    const { weblink } = yield* select(getPublicRootWeblink);

    const hasFaces = yield select(hasFolderFileWithFace, weblink);

    if (!isFacesFeature || !hasFaces || storeHelper.getValue(FILTER_TIP_ID)) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.faceFilter,
            promoId: FILTER_TIP_ID,
            onShow: () => {
                store.dispatch(promoShown(EPromoType.faceFilter));
                storeHelper.markAsShown(FILTER_TIP_ID);
            },
            onClose: () => {
                store.dispatch(removePromo(EPromoType.faceFilter));
            },
        })
    );
}
