import { getTariffListData } from 'reactApp/modules/features/features.selectors';
import { loggerSaga } from 'reactApp/modules/logger/logger.saga';
import { checkProductListEquals, normalizeData } from 'reactApp/modules/products/products.helpers';
import {
    callProductsListApi,
    initProducts,
    initProductsForAnonymous,
    loadProductsError,
    loadProductsStart,
    loadProductsSuccess,
    updateProducts,
} from 'reactApp/modules/products/products.module';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import ping from 'reactApp/utils/ping';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

export function* handleInitProducts(action) {
    const { onSuccess, onError } = action.payload || {};
    try {
        const { isLoaded, isLoading } = yield select(ProductsSelectors.getLifeCycleState);
        if (isLoaded || isLoading) {
            onSuccess?.();
            return;
        }

        yield put(loadProductsStart({ setLoading: true }));

        const { data } = yield call(callProductsListApi);

        const products = normalizeData(data);
        yield put(loadProductsSuccess(products));

        onSuccess?.();
    } catch (error) {
        onError?.();
        yield loggerSaga({ error, action: loadProductsError() });
    }
}

export function* handleUpdateProducts(action) {
    const { onSuccess, onError, productId } = action.payload || {};
    try {
        if (productId) {
            const product = yield select(ProductsSelectors.getProductById, productId);

            if (product) {
                // Такой продукт уже был ранее открыт и уже есть в списке
                onSuccess?.();
                return;
            }
        }

        const initialState = yield select(ProductsSelectors.getTariffs);

        const { data } = yield call(ping, { request: callProductsListApi, check: checkProductListEquals(initialState) });

        const products = normalizeData(data);

        yield put(loadProductsSuccess(products));

        onSuccess?.();
    } catch (error) {
        onError?.();
        yield loggerSaga({ error, action: loadProductsError() });
    }
}

export function* loadProductsForAnonymous() {
    const isAnonymous = yield select(UserSelectors.isAnonymous);

    if (!isAnonymous) {
        return;
    }

    const data = yield select(getTariffListData);

    if (!data) {
        return;
    }

    const products = normalizeData(data);

    yield put(loadProductsSuccess(products));
}

export function* watchProductsRoot() {
    yield takeEvery(initProducts.toString(), handleInitProducts);
    yield takeLatest(updateProducts.toString(), handleUpdateProducts);
    yield takeEvery(initProductsForAnonymous.toString(), loadProductsForAnonymous);
}
