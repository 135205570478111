import { toNDigits } from '@mail/cross-sizes-utils';
import classNames from 'clsx';
import { ReactComponent as WarningIcon } from 'img/icons/uploader/warning.svg';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { getErrorInfo, getFileName, showInfoIcon } from 'reactApp/modules/uploadList/uploadList.getters';
import { EFileError, IInputFile } from 'reactApp/modules/uploadList/uploadList.model';
import { getUserFileSizeLimit } from 'reactApp/modules/uploadList/uploadList.selectors';
import FilePicture from 'reactApp/ui/FilePicture/FilePicture';

import styles from './Problems.css';

interface IProps {
    file: IInputFile;
}

export const InfoErrorComponent = memo(({ file }: IProps) => {
    const userFileSizeLimit = toNDigits({
        bytes: useSelector(getUserFileSizeLimit),
        maxDigitsCount: 3,
    }).value;
    const error = file.error || EFileError.UNKNOWN;
    const errorInfo = getErrorInfo(error, {
        userFileSizeLimit,
        fileName: getFileName(file),
        folderName: file.localPath.split(`/${file.name}`)[0],
    });
    const isInfoIcon = showInfoIcon(file);

    return (
        <div className={classNames(styles.problem, { [styles[`problem_${errorInfo.mode}`]]: !!errorInfo.mode })}>
            <div className={styles.icon}>
                {isInfoIcon ? (
                    errorInfo.icon ?? (
                        <FilePicture
                            icon={file.extension}
                            file={file}
                            thumbUrl={file.thumb}
                            borderRadius={true}
                            removeLoader={() => true}
                            loadThumb={() => true}
                            isNew
                        />
                    )
                ) : (
                    <WarningIcon />
                )}
            </div>
            <div className={classNames({ [styles.wrapperText]: true })}>
                <div>{errorInfo.text}</div>
                {!!errorInfo.description && <div>{errorInfo.description}</div>}
                {!!errorInfo.link?.url && (
                    <div className={styles.infoLink}>
                        <a href={errorInfo.link.url} rel="noopener noreferrer" target="_blank">
                            {errorInfo.link.text}
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
});

InfoErrorComponent.displayName = 'InfoErrorComponent';
