import config from 'Cloud/config';
import store from 'lib/store';
import { find, path, propEq } from 'ramda';

let FEATURES = config.get('FEATURES');

function isABEnabled(featureName, ctx) {
    if (ctx?.features) {
        FEATURES = ctx.features;
    }

    if (!FEATURES) {
        return false;
    }

    const feature = FEATURES?.percents && find(propEq('name', featureName), FEATURES?.percents);

    if (!feature) {
        return false;
    }

    let userPercent = store.get(`${featureName}|percent`);

    if (!userPercent) {
        userPercent = Math.floor(Math.random() * 100) + 1;
        store.set(`${featureName}|percent`, userPercent);
    }

    if (Array.isArray(feature.percent)) {
        let index = -1;
        feature.percent.every(function (segment, idx) {
            segment = segment.split('..');

            const from = segment[0];
            const to = segment[1];

            if ((from === 0 || userPercent >= from) && (to === 100 || userPercent <= to)) {
                index = idx;
            }
            return index === -1;
        });

        if (index >= 0) {
            return path(['params', index.toString()], feature);
        }
        return false;
    }

    return userPercent <= feature.percent;
}

function isFeature(featureName, ctx) {
    if (ctx?.features) {
        FEATURES = ctx.features;
    }

    return FEATURES && (FEATURES?.enabled?.includes(featureName) || isABEnabled(featureName, ctx));
}

isFeature.ssr = (featureName, orConditionCb = (_ctx) => false, andConditionCb = (_ctx) => true) => {
    return {
        client: (isFeature(featureName) && andConditionCb()) || orConditionCb(),
        server: (ctx) => (isFeature(featureName, ctx) && andConditionCb(ctx)) || orConditionCb(ctx),
    };
};

function getFeatureObject(featureName) {
    return {
        isEnabled() {
            return isFeature(featureName);
        },
    };
}

function enableFeature(featureName, data = true) {
    const WEEK_MAX_AGE = 3600 * 24 * 7;

    let value = data;
    try {
        value = JSON.stringify(data);
    } catch (_) {}

    document.cookie = `cloudFeature_${featureName}=${encodeURIComponent(value)}; max-age=${WEEK_MAX_AGE}; samesite=strict; path=/`;
    location.reload();
}

function disableFeature(featureName) {
    const WEEK_MAX_AGE = 3600 * 24 * 7;
    document.cookie = `cloudFeature_${featureName}=false; max-age=${WEEK_MAX_AGE}; samesite=strict; path=/`;
    location.reload();
}

function resetFeature(featureName) {
    document.cookie = `cloudFeature_${featureName}=; max-age=-1; path=/`;
    location.reload();
}

function getFeature(featureName) {
    const res = isFeature(featureName) && FEATURES ? FEATURES?.configs?.[featureName] : undefined;

    if (!res) {
        return isABEnabled(featureName);
    }

    return res;
}

function getFeatureParam(featureName, key, defaultValue) {
    const featureParams = getFeature(featureName);

    if (new Object(featureParams) === featureParams && key in featureParams) {
        return featureParams[key];
    }

    return defaultValue;
}

const featuresWithSpecialConditions = {
    newUpload: getFeatureObject('newUpload'),
    search: getFeatureObject('search'),
    fileHistory: getFeatureObject('fileHistory'),
    abTestDiscountText: getFeatureObject('discount-text'),
    publicUpload: getFeatureObject('public-upload'),
};

export { isFeature, enableFeature, disableFeature, resetFeature, getFeature, getFeatureParam, featuresWithSpecialConditions as features };

export default {
    isFeature,
    enableFeature,
    disableFeature,
    getFeature,
    getFeatureParam,
    resetFeature,
    features: featuresWithSpecialConditions,
};
