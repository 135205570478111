import { useParams } from 'react-router-dom';

export const useAlbumId = () => {
    const params = useParams();
    const albumParams = params['*']?.split('/');

    return {
        albumId: albumParams?.[0] || '',
        initialItemId: albumParams?.[1],
    };
};
