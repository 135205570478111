import { captureException } from '@sentry/browser';
import { format } from 'date-fns';
import { logger } from 'lib/logger';
import { getFileHistoryApiCall } from 'reactApp/api/FileHistoryAPICall';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { getNewFileName } from 'reactApp/modules/fileHistory/fileHistory.helpers';
import {
    fileHistoryLoadFailure,
    fileHistoryLoadRequest,
    fileHistoryLoadSuccess,
    fileHistoryRecoveryRequest,
} from 'reactApp/modules/fileHistory/fileHistory.module';
import { FileHistorySelectors } from 'reactApp/modules/fileHistory/fileHistory.selectors';
import { checkFolderForUpdate } from 'reactApp/modules/modifying/modifying.actions';
import { addFileHelper } from 'reactApp/modules/modifying/modifying.helpers';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { getItemById } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { put, select, takeEvery } from 'redux-saga/effects';

function* loadFileHistory(action: ReturnType<typeof fileHistoryLoadRequest>) {
    const { id } = action.payload;
    try {
        const data = yield getFileHistoryApiCall({
            home: id,
        });

        const item = yield select(getItemById, id);

        yield put(fileHistoryLoadSuccess({ data, item }));
    } catch (error) {
        logger.error(error);
        captureException(error);
        yield put(fileHistoryLoadFailure());
    }
}

function* recoveryFile(action: ReturnType<typeof fileHistoryRecoveryRequest>) {
    const { version, isRewrite } = action.payload;

    const conflictMode = isRewrite ? 'rewrite' : 'rename';
    const actionName = isRewrite ? 'rewrite' : 'copy';

    const entry = yield select(FileHistorySelectors.getEntry, version);
    const itemId = yield select(FileHistorySelectors.getCurrentItem);
    const item = yield select(getItemById, itemId);

    const time = !isRewrite ? format(entry.date, 'HH\uA789mm\uA789ss\u00A0dd.MM.yyyy') : '';

    const newItem = {
        id: item.id,
        home: item.home || item.id,
        name: getNewFileName(item.name, entry.name, time),
        kind: 'file',
        hash: entry.hash,
        size: entry.size,
        mtime: Math.floor(Date.now() / 1000),
        virus_scan: 'not_yet',
    };

    try {
        const newFile = yield addFileHelper({
            item: newItem,
            parent: item.parent,
            storage: EStorageType.home,
            conflictMode,
            skipXhr: false,
            reason: actionName,
        });

        yield put(
            showSnackbarAction({
                id: 'new-recovery-file',
                closable: true,
                text: `Новый файл: ${item.parent === ROOT_FOLDER_ID ? newFile.name : newFile.id}`,
                type: SnackbarTypes.success,
            })
        );

        yield put(checkFolderForUpdate());

        if (isRewrite) {
            yield closePopupHelper(popupNames.FILE_HISTORY);
        }
    } catch (error) {
        logger.error(error);
        captureException(error);

        yield put(
            showSnackbarAction({
                id: 'new-recovery-file-fail',
                closable: true,
                text: 'Ошибка восстановления',
                type: SnackbarTypes.failure,
            })
        );
    }
}

export function* watchFileHistory() {
    yield takeEvery(fileHistoryLoadRequest.toString(), loadFileHistory);
    yield takeEvery(fileHistoryRecoveryRequest.toString(), recoveryFile);
}
