import { Spacing, Title } from '@vkontakte/vkui';
import coverImage from 'img/subscriptions/cancel-autorenew.jpg';
import React, { memo, ReactElement, useEffect, useMemo } from 'react';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { ISubscription } from 'reactApp/modules/subscriptions/subscriptions.types';
import {
    ESubscriptionModalAction,
    useSendSubscriptionModalAnalytics,
} from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';

import styles from './CancelAutoRenewConfirmDialog.css';

interface IProps {
    onClose: () => void;
    onAction: () => void;
    onSuccess: () => void;
    isTrial: boolean;
    subscription: ISubscription;
}

export const CancelAutoRenewConfirmDialog = memo<IProps>(({ onClose, onAction, onSuccess, isTrial, subscription }): ReactElement => {
    const sendAnalytics = useSendSubscriptionModalAnalytics(subscription);

    useEffect(() => {
        sendAnalytics(ESubscriptionModalAction.showCancelAutopayment);
    }, []);

    const content = useMemo(
        (): ReactElement => (
            <div className={styles.content}>
                <Spacing size={16} />
                <Title level="3">После {isTrial ? 'отмены' : 'окончания'} подписки:</Title>
                <Spacing size={12} />
                <ul className={styles.list}>
                    <li>Объём единого хранилища уменьшится на {subscription.space.value}</li>
                    <li>Не получится загружать тяжёлые файлы</li>
                    <li>Вернётся реклама</li>
                </ul>
                <Spacing size={36} />
            </div>
        ),
        [isTrial, subscription.space.value]
    );

    return (
        <WhatsNewDialog
            onClose={onClose}
            onClick={onSuccess}
            onSecondary={onAction}
            primaryButtonText={isTrial ? 'Сохранить подписку' : 'Сохранить продление'}
            secondaryButtonText="Отключить"
            title="Вы уверены?"
            content={content}
            imageUrl={coverImage}
            imageBgColor="var(--vkui--color_background)"
            buttonTheme="vk"
            dialogSize="tiny"
        />
    );
});

CancelAutoRenewConfirmDialog.displayName = 'CancelAutoRenewConfirmDialog';
