import { addKeeperInterceptors } from '@mail-core/dashboard/keeper/binding/axios';
import axios, { AxiosInstance } from 'axios';
import { SUCCESS_HTTP_CODES } from 'reactApp/api/apiConstants';

import { getHandlersFactory } from './interceptors/interceptors';

export const getAxiosInstance = (apiVersion = 3): AxiosInstance => {
    const axiosInstance = axios.create({
        baseURL: `/api/v${apiVersion}/`,
        validateStatus: (status) => SUCCESS_HTTP_CODES.includes(status),
    });

    addKeeperInterceptors(axiosInstance);

    const interceptors = getHandlersFactory(apiVersion);

    axiosInstance.interceptors.request.use(interceptors.requestSuccess, interceptors.requestError);

    axiosInstance.interceptors.response.use(interceptors.responseSuccess, interceptors.responseError);

    return axiosInstance;
};
