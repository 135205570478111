import { RootState } from 'reactApp/store';
import { UserQuotaGroupId, UserQuotaGroupType } from 'reactApp/types/QuotaCleaner';
import { createSelector } from 'reselect';

import { baseItemsLimit, imageItemsLimit } from './helpers/constants';
import { getViewModes } from './helpers/getViewModes';
import { QuotaCleanerGroup, QuotaCleanerYearConfig, QuotaCleanerYearGroupConfig, State } from './userQuotaCleaner.types';

const getQuotaCleanerState = (state: RootState) => state.userQuotaCleaner as State;

export const getGroups = createSelector(getQuotaCleanerState, (state: State) => state.groups);
export const getNotEmptyGroups = createSelector(getGroups, (groups) => groups.filter((group) => group.childs.length));
export const getLimitedGroups = createSelector(getNotEmptyGroups, (groups) =>
    groups.map((group) => {
        const { groupId, childs, list } = group;
        const isImageContent = groupId === UserQuotaGroupId.CameraUploads || groupId === UserQuotaGroupId.Video;
        const limit = isImageContent ? imageItemsLimit : baseItemsLimit;

        const newChilds = childs.slice(0, limit);
        const newList = newChilds.reduce((acc, child) => {
            acc[child] = list[child];
            return acc;
        }, {});

        return {
            ...group,
            childs: newChilds,
            list: newList,
        };
    })
);

export const getFilteredLimitedGroups = createSelector(getLimitedGroups, (groups): QuotaCleanerGroup[] =>
    groups.sort((a, b) => b.size - a.size)
);

export const getGroupsParams = createSelector(getQuotaCleanerState, (state: State) => state.groupsParams);

export const getGroupsIsLoading = createSelector(getQuotaCleanerState, (state) => state.isGroupsLoading);
export const getGroupsIsLoadingError = createSelector(getQuotaCleanerState, (state) => state.isGroupsLoadingError);
export const getGroupsIsLoaded = createSelector(getQuotaCleanerState, (state) => state.isGroupsLoaded);

export const getCurrentGroup = createSelector(getQuotaCleanerState, (state: State) => state.currentGroup);
export const getCurrentGroupId = createSelector(getCurrentGroup, (group) => group?.groupId);

export const getCurrentGroupTotalCount = createSelector(getCurrentGroup, (currentGroup) => currentGroup?.totalCount || 0);

export const getGroupViewModes = createSelector(getCurrentGroup, (currentGroup) =>
    getViewModes(currentGroup?.groupType, currentGroup?.groupId)
);

export const getGroupById = createSelector(
    getGroups,
    (state, id) => id,
    (groups, id) => groups.find((group) => group.groupId === id)
);

export const getItemByGroup = createSelector(
    (state, itemId: string, groupId?: UserQuotaGroupId) => getGroupById(state, groupId),
    (state, itemId: string) => itemId,
    (group, itemId) => group?.list?.[itemId]
);

export const getItem = createSelector(
    (state, itemId: string) => itemId,
    getGroups,
    (itemId: string, groups: QuotaCleanerGroup[]) => {
        const group = groups.find((group: QuotaCleanerGroup) => group.childs.includes(itemId));
        return group?.list[itemId];
    }
);

export const getItemById = createSelector(
    (state) => state,
    (state, id: string) => id,
    getCurrentGroup,
    (state, id, currentGroup) => currentGroup?.list[id] || getItem(state, id)
);

export const getNeedGroupsReload = createSelector(getQuotaCleanerState, (state: State) => state.needGroupsReload);

export const getIsLetterGroup = createSelector(getCurrentGroup, (currentGroup) => currentGroup?.groupType === UserQuotaGroupType.Letter);

// все загруженные группы пустые, если загрузки еще нет - пустых групп тоже нет
export const isAllGroupsLoadedEmpty = createSelector(
    getGroupsIsLoaded,
    getNotEmptyGroups,
    (isLoaded, notEmptyGroups) => isLoaded && !notEmptyGroups.length
);

export const isMeesagesRemoveNeeded = createSelector(getQuotaCleanerState, (state: State) => state.isMessagesRemoveNeeded);
export const getYearGroupsConfig = createSelector(getQuotaCleanerState, (state: State) => state.yearGroupsConfig);
export const getYearGroupConfig = createSelector(
    getYearGroupsConfig,
    (state, groupId: UserQuotaGroupId | undefined) => groupId,
    (yearGroupsConfig: QuotaCleanerYearConfig, groupId) => {
        if (!groupId) {
            return;
        }

        const yearGroupConfig: QuotaCleanerYearGroupConfig | undefined = yearGroupsConfig[groupId];
        if (!yearGroupConfig) {
            return;
        }

        const copyYearConfig: QuotaCleanerYearGroupConfig = JSON.parse(JSON.stringify(yearGroupConfig));
        const { config } = copyYearConfig;
        Object.keys(config).forEach((year) => {
            const { totalCount, size } = config[year];
            if (totalCount <= 0 || size <= 0) {
                delete config[year];
            }
        });

        return copyYearConfig;
    }
);

export const getYearCurrentGroupConfig = createSelector(
    (state) => state,
    getCurrentGroup,
    (state, group) => (group ? getYearGroupConfig(state, group.groupId) : null)
);

export const isYearCurrentGroupFilter = createSelector(getYearCurrentGroupConfig, (yearConfig) => {
    if (!yearConfig) {
        return false;
    }

    const validYearsCount = Object.values(yearConfig.config).reduce(
        (acc, { totalCount, size }) => (totalCount > 0 && size > 0 ? acc + 1 : acc),
        0
    );
    // фильр валиден, когда есть из чего выбирать
    return validYearsCount > 1;
});

export const getIsToggledYearFilterOpen = createSelector(getQuotaCleanerState, (state: State) => state.isToggledYearFilterOpen);
export const getIsItemsDeleteProcess = createSelector(getQuotaCleanerState, (state: State) => state.isItemsDeleteProcess);
export const getIsItemsDeleteError = createSelector(getQuotaCleanerState, (state: State) => state.isItemsDeleteError);
