import React, { memo } from 'react';
import { LoaderIcon } from 'reactApp/ui/UploadList/Item/LoaderIcon';
import { noop } from 'reactApp/utils/helpers';

export const Progress = memo(({ progress = 0, className, onClick }: { progress?: number; className: string; onClick?: () => void }) => (
    <div className={className} onClick={onClick ? onClick : noop}>
        <LoaderIcon progress={progress} loading={true} />
    </div>
));
Progress.displayName = 'Progress';
