import config from 'Cloud/config';
import { advUpdated, EAdvType } from 'reactApp/modules/adv/adv.module';
import { delay, takeLatest } from 'redux-saga/effects';

const DOM_ID_PHONE_PUBLIC_HEADER = config.get('DOM_ID_PHONE_PUBLIC_HEADER');
const WAIT_FOR_ADV_DRAW = 500;

export function* handleAdvUpdated(action: ReturnType<typeof advUpdated>) {
    if (action.payload !== EAdvType.phoneTopBanner) {
        return;
    }

    const el = document.getElementById(DOM_ID_PHONE_PUBLIC_HEADER);

    if (!el) {
        return;
    }

    yield delay(WAIT_FOR_ADV_DRAW);

    const elAdv = el.querySelectorAll('div')?.[0];

    if (elAdv?.clientHeight) {
        el.style.height = `${elAdv?.clientHeight + 8}px`;
    }
}

export function* watchAdv() {
    yield takeLatest(advUpdated.toString(), handleAdvUpdated);
}
