import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectHistoryPath } from 'reactApp/modules/router/router.selectors';

export const ChangeHistory = () => {
    const historyPath = useSelector(selectHistoryPath);
    const [prevPath, setPrevPath] = useState(historyPath);
    const navigate = useNavigate();
    const location = useLocation();
    const isNewPath = historyPath !== location.pathname + location.search && prevPath !== historyPath;

    useEffect(() => {
        setPrevPath(historyPath);

        if (!historyPath || !isNewPath) {
            return;
        }

        navigate(historyPath);
    }, [historyPath, isNewPath, setPrevPath]);

    return null;
};
