import classNames from 'clsx';
import React, { memo, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { ADD_TOOLBAR_ID } from 'reactApp/constants/magicIdentificators';
import { getDomainFoldersFilterActive } from 'reactApp/modules/home/home.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { ToolbarItem } from 'reactApp/ui/Toolbar/ToolbarItem/ToolbarItem';
import { useAddActions } from 'reactApp/ui/Toolbar/useAddActions';

import styles from './Toolbar.css';

export const AddToolbar = memo((): ReactElement | null => {
    const storage = useSelector(getCurrentStorage) as EStorageType;
    const domainFoldersFilterActive = useSelector(getDomainFoldersFilterActive);
    const actions = useAddActions({ storage });

    const isInlineIntegration = storage === EStorageType.inlineIntegration;

    if (!actions.length || domainFoldersFilterActive) {
        return null;
    }

    return (
        <div
            className={classNames(styles.root, styles.root_add, styles.rootFixed, { [styles.rootInlineIntegration]: isInlineIntegration })}
            id={ADD_TOOLBAR_ID}
            data-qa-id="addToolbar"
        >
            {actions.map(({ id, text, icon, list, dropdownTheme, onClick }) => (
                <ToolbarItem key={id} id={id} text={text} icon={icon} list={list} dropdownTheme={dropdownTheme} onClick={onClick} />
            ))}
        </div>
    );
});

AddToolbar.displayName = 'AddToolbar';
