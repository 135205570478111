import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { store } from 'reactApp/store';
import { CLOUD_NEW_FEATURES_TOOLTIP_ID_DESKTOP } from 'reactApp/ui/CloudNewFeatures/CloudNewFeatures.helpers';
import { put, select } from 'redux-saga/effects';

export function* initCloudNewFeaturesDesktopPromo() {
    const storage = yield select(getCurrentStorage);
    const { isHome } = getStorage(storage);

    if (!isHome || storeHelper.getValue(CLOUD_NEW_FEATURES_TOOLTIP_ID_DESKTOP)) {
        return;
    }
    yield put(
        addPromoToShowQueue({
            type: EPromoType.newFeaturesDesktop,
            promoId: CLOUD_NEW_FEATURES_TOOLTIP_ID_DESKTOP,
            onShow: () => {
                store.dispatch(promoShown(EPromoType.newFeaturesDesktop));
                storeHelper.markAsShown(CLOUD_NEW_FEATURES_TOOLTIP_ID_DESKTOP);
            },
            onClose: () => {
                store.dispatch(removePromo(EPromoType.newFeaturesDesktop));
            },
        })
    );
}
