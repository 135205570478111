import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import debounce from 'lodash.debounce';
import { store } from 'reactApp/store';

import { IActionType, MediaState, MediaTypes } from './media.types';

let isInitiated = false;

const INITIAL_STATE: MediaState = {
    isMobile: false,
    isPhone: false,
    isPhoneSmall: false,
    isTablet: false,
    isDesktop: false,
    isDesktopMedium: false,
    isDesktopSmall: false,
    windowWidth: 0,
};

const setMedia = createAction<IActionType>('media/set');

export const mediaReducer = createReducer(INITIAL_STATE, {
    [setMedia.type]: (state, action: PayloadAction<IActionType>) => {
        return {
            ...INITIAL_STATE,
            isMobile:
                action.payload.type === MediaTypes.PHONE ||
                action.payload.type === MediaTypes.TABLET ||
                action.payload.type === MediaTypes.PHONE_SMALL,
            isPhone: action.payload.type === MediaTypes.PHONE || action.payload.type === MediaTypes.PHONE_SMALL,
            isPhoneSmall: action.payload.type === MediaTypes.PHONE_SMALL,
            isTablet: action.payload.type === MediaTypes.TABLET,
            isDesktop: action.payload.type === MediaTypes.DESKTOP_MEDIUM || action.payload.type === MediaTypes.DESKTOP_SMALL,
            isDesktopSmall: action.payload.type === MediaTypes.DESKTOP_SMALL,
            isDesktopMedium: action.payload.type === MediaTypes.DESKTOP_MEDIUM,
            windowWidth: action.payload.windowWidth,
        };
    },
});

const mediumDesktop = '(min-width: 1360px)';
const smallDesktop = '(min-width: 1024px)';
const tablet = '(min-width: 768px)';
const smallPhone = '(max-width: 375px)';

export const isMatch = (media: string): boolean => window.matchMedia(media).matches;

const match = debounce((): void => {
    const windowWidth = window.innerWidth;
    if (isMatch(mediumDesktop)) {
        store.dispatch(setMedia({ type: MediaTypes.DESKTOP_MEDIUM, windowWidth }));
    } else if (isMatch(smallDesktop)) {
        store.dispatch(setMedia({ type: MediaTypes.DESKTOP_SMALL, windowWidth }));
    } else if (isMatch(tablet)) {
        store.dispatch(setMedia({ type: MediaTypes.TABLET, windowWidth }));
    } else if (isMatch(smallPhone)) {
        store.dispatch(setMedia({ type: MediaTypes.PHONE_SMALL, windowWidth }));
    } else {
        store.dispatch(setMedia({ type: MediaTypes.PHONE, windowWidth }));
    }
}, 200);

export const initMedia = (): void => {
    if (!isInitiated) {
        isInitiated = true;
        match();
        window.addEventListener('resize', match);
    }
};
