import React from 'react';
import { Portal } from 'react-portal';

import styles from './RotateScreen.css';
export const RotateScreen: React.FC = () => {
    return (
        <Portal>
            <div className={styles.container}>Сервис поддерживает только вертикальное отображение</div>
        </Portal>
    );
};
