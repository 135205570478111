import { IS_B2B_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { isPreviewable } from 'reactApp/modules/file/utils';
import { GalleryCategoryType, GalleryItem, GalleryState } from 'reactApp/modules/gallery/gallery.types';
import { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

const getGalleryState = (state: RootState): GalleryState => state.gallery;

export const getGalleryCategory = (state: RootState): GalleryCategoryType => getGalleryState(state).currentCategory;
export const getGalleryList = (state: RootState) => getGalleryState(state).list;
export const getGalleryCategories = createSelector(getGalleryState, (gallery) => {
    // B2BCLOUD-230 временно скрываем раздел "С телефона", т.к. не отображаются фотографии, загруженные с телефона в разделе Галерея
    if (IS_B2B_BIZ_USER) {
        return gallery.categories.filter((item) => item.id !== GalleryCategoryType.mobile);
    }

    return gallery.categories;
});

export const getGalleryCursorByCategory = createSelector(
    getGalleryCategories,
    (state: RootState, category: GalleryCategoryType) => category,
    (categories, category) => categories.find((item) => item.id === category)?.cursor
);

export const getGalleryCategoryById = createSelector(
    getGalleryCategories,
    (state, category) => category,
    (categories, category) => categories.find((item) => item.id === category)
);

export const getCurrentGalleryCategory = createSelector(
    getGalleryCategory,
    (state: RootState) => state,
    (currentCategory, state) => getGalleryCategoryById(state, currentCategory)
);

export const getGalleryOfCategory = createSelector(
    getGalleryCategories,
    (state, category) => category,
    (categories, category) => categories.find((item) => item.id === category)
);

export const getGalleryItemById = createSelector(
    getGalleryList,
    (state, id) => id,
    (list, id): GalleryItem | undefined => list[id]
);

export const isGalleryCategoryLoaded = createSelector(
    getGalleryCategories,
    (state: RootState, category: GalleryCategoryType) => category,
    (categories, category) => !!categories.find((item) => item.id === category)?.isLoaded
);

export const isGalleryCategoryLoading = createSelector(
    getGalleryCategories,
    (state: RootState, category: GalleryCategoryType) => category,
    (categories, category) => !!categories.find((item) => item.id === category)?.isLoading
);

export const hasMoreToLoadGallery = createSelector(
    getGalleryCategories,
    (state: RootState, category: GalleryCategoryType) => category,
    (categories, category) => categories.find((item) => item.id === category)?.hasMoreToLoad
);

const USED_THUMB_SIZE = '1000x1000';

export const getPreviewableMediaForGallery = createSelector(
    (state) => state,
    getGalleryList,
    getCurrentGalleryCategory,
    (state, number): number => number,
    (state, list, category, number: number | null): string[] => {
        const withImages = (Object.values(list) as GalleryItem[])
            .filter(
                (item) =>
                    category?.childs.includes(item?.id) &&
                    item?.thumbnails &&
                    !!Object.keys(item?.thumbnails).length &&
                    item?.thumbnails[USED_THUMB_SIZE] &&
                    isPreviewable(item)
            )
            .map((item): string => item?.id as string);
        if (number === null) {
            return withImages;
        }
        return withImages.slice(0, number);
    }
);

export const getGalleryFolder = (state: RootState): string | undefined => getGalleryState(state).currentFolder;

export const getGalleryListCount = createSelector(getCurrentGalleryCategory, (category) => category?.childs.length || 0);
