import { IS_CLOUD_BANNED, IS_CLOUD_CREATED, IS_CLOUD_LOCKED, IS_OWN_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

export const canCloneWeblink = (isAuthorized: boolean, storage: EStorageType | null = EStorageType.home, isCheckCloudOnPublic = false) => {
    const { isPublic, isStock, isAttaches } = getStorage(storage || EStorageType.home);
    const isCloudAvailabe = (IS_CLOUD_CREATED || !isAuthorized) && !IS_CLOUD_BANNED && !IS_CLOUD_LOCKED;
    const enableClone = isCheckCloudOnPublic
        ? (isPublic || isStock || isAttaches) && isCloudAvailabe
        : isPublic || isStock || (isAttaches && isCloudAvailabe);

    return enableClone && (isStock || !IS_OWN_PUBLIC);
};
