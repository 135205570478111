import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getFolderAuthors } from 'reactApp/modules/storage/storage.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';

export const useAuthorEmails = (isOwner: boolean) => {
    const userEmail = useSelector(UserSelectors.getEmail);
    const authorsEmails = useSelector(getFolderAuthors);

    const isFolderOwnerMissing = userEmail && isOwner && !authorsEmails.includes(userEmail);

    return useMemo(
        () => (isFolderOwnerMissing ? [userEmail, ...authorsEmails] : authorsEmails),
        [authorsEmails, isFolderOwnerMissing, userEmail]
    );
};
