import { isFeature } from 'Cloud/Application/FeaturesEs6';
import dickOCoverImage from 'img/aside-promo-modal/disko-cover.jpg';
import { DOWNLOAD_DISK_O_PROMO_CONFIG, IS_AUTO_TEST_HIDE } from 'reactApp/appHelpers/configHelpers';
import { isDefaultRootContent } from 'reactApp/modules/home/home.selectors';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { store as reduxStore } from 'reactApp/store';
import { openAsidePromoModal } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.helpers';
import { EDiskOPromoAnalytics, sendDiskOPromoAnalytics } from 'reactApp/utils/diskOPromoGa';
import { put, select } from 'redux-saga/effects';

export const DISK_O_PROMO_ID = 'diskOAsidePromoModal';
const diskOAsidePromoEnabled = isFeature('disko-aside-promo');

export function* initDiskOAsidePromoModal() {
    const isDefaultContent = yield select(isDefaultRootContent);
    const isCorpUser = yield select(UserSelectors.isCorpUser);
    const isPromoShown = storeHelper.getValue(DISK_O_PROMO_ID);
    const storage = yield select(getCurrentStorage);
    const { isPrivatePage } = getStorage(storage);

    if (
        !diskOAsidePromoEnabled ||
        IS_AUTO_TEST_HIDE ||
        isDefaultContent ||
        isCorpUser ||
        !DOWNLOAD_DISK_O_PROMO_CONFIG?.asideModalLink ||
        isPromoShown ||
        !isPrivatePage
    ) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.diskOAsidePromo,
            promoId: DISK_O_PROMO_ID,
            onShow: () => sendDiskOPromoAnalytics(EDiskOPromoAnalytics['show-left']),
            onClick: () => {
                sendDiskOPromoAnalytics(EDiskOPromoAnalytics['click-left']);
                reduxStore.dispatch(promoShown(EPromoType.diskOAsidePromo));
                storeHelper.markAsShown(DISK_O_PROMO_ID);
                reduxStore.dispatch(removePromo(EPromoType.diskOAsidePromo));
            },
            onClose: () => {
                sendDiskOPromoAnalytics(EDiskOPromoAnalytics['cancel-left']);
                reduxStore.dispatch(promoShown(EPromoType.diskOAsidePromo));
                storeHelper.markAsShown(DISK_O_PROMO_ID);
                reduxStore.dispatch(removePromo(EPromoType.diskOAsidePromo));
            },
        })
    );
}

export function* showDiskOAsidePromoModal() {
    const href = DOWNLOAD_DISK_O_PROMO_CONFIG?.asideModalLink;
    const promo = PromoSelectors.getPromo(EPromoType.diskOAsidePromo)(yield select());
    const storage = yield select(getCurrentStorage);
    const { isPrivatePage } = getStorage(storage);

    if (!promo) {
        return;
    }

    if (!href || !isPrivatePage) {
        yield closePopupHelper(popupNames.ASIDE_PROMO_MODAL);
        return;
    }

    const { onShow, onClick, onClose } = promo;

    yield openAsidePromoModal({
        title: 'Облако Mail.ru на ПК',
        text: 'Переносите файлы с зарубежных облаков в пару кликов и работайте с ними прямо на компьютере',
        buttonText: 'Установить',
        imageUrl: dickOCoverImage,
        href,
        onShow,
        onClick,
        onClose,
    });
}
