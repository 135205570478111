import React, { forwardRef } from 'react';
import { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { isBaseSubscription, renderSubscriptionInfoModal } from 'reactApp/ui/Mobile/SubscriptionInfoModal/SubscriptionInfoModal.helpers';

import { useSendAnalyticsAfterOpen } from '../hooks/useSendAnalyticsAfterOpen';
import { Subscription, SubscriptionList } from '../SubscriptionsModal.types';
import { BaseSubscriptionItemComponent } from './Components/BaseSubscriptionItem/BaseSubscriptionItem';
import { ModernSubscriptionItemComponent } from './Components/ModernSubscriptionItem/ModernSubscriptionListItemComponent';
import styles from './SubscriptionListComponent.css';

type Props = IPropsWithPopup<{ subscriptionList: SubscriptionList }>;

export const SubscriptionListComponent = forwardRef<HTMLDivElement, Props>(({ subscriptionList, onClose }, ref) => {
    useSendAnalyticsAfterOpen(subscriptionList);

    const handleClick = (subscription: Subscription) => {
        onClose();
        renderSubscriptionInfoModal({ subscription });
    };

    return (
        <div className={styles.root} ref={ref}>
            {subscriptionList.map((subscription) =>
                isBaseSubscription(subscription) ? (
                    <BaseSubscriptionItemComponent
                        key={subscription.id}
                        subscription={subscription}
                        onClick={() => handleClick(subscription)}
                    />
                ) : (
                    <ModernSubscriptionItemComponent
                        key={subscription.id}
                        subscription={subscription}
                        onClick={() => handleClick(subscription)}
                    />
                )
            )}
        </div>
    );
});

SubscriptionListComponent.displayName = 'SubscriptionListComponent';
