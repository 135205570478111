import React, { HTMLAttributes } from 'react';
import { ESearchOptionType } from 'reactApp/ui/WebSearch/WebSearch.data';

import { BaseMessage } from '../BaseMessage/BaseMessage';
import styles from './EmptySearch.css';

const mapTypeName = {
    image: 'Изображения',
    audio: 'Музыка',
    video: 'Видео',
    document: 'Документы',
    pdf: 'Файлы PDF',
    spreadsheet: 'Таблицы',
    presentation: 'Презентации',
    archive: 'Архивы',
    folder: 'Папки',
};

export const getSearchData = ({
    searchType = ESearchOptionType.all,
    searchText,
}: {
    searchType?: ESearchOptionType;
    searchText?: string;
}) => {
    const type = searchType && mapTypeName[searchType];

    if (type && searchText) {
        return {
            title: `По запросу «${searchText}» ${type.toLowerCase()} не\u00A0${type === ESearchOptionType.audio ? 'найдена' : 'найдены'}`,
            text: 'Проверьте правильность написания или выберите другой тип файлов',
        };
    }

    if (searchText) {
        return {
            title: `По запросу ${searchText ? `«${searchText}» ` : ''}ничего не\u00A0найдено`,
            text: 'Проверьте правильность написания или используйте другой запрос',
        };
    }

    return {
        title: 'Ничего не нашлось',
        text: 'Выберите другой тип файла или напишите, что вы ищете',
    };
};

interface IProps extends HTMLAttributes<HTMLDivElement> {
    searchText?: string;
    searchType?: ESearchOptionType;
    isMobile?: boolean;
}

export const EmptySearch: React.FC<IProps> = ({ searchText, searchType, ...rest }) => {
    const { title, text } = getSearchData({ searchType, searchText });

    return <BaseMessage img={<div className={styles.image} />} title={title} text={text} {...rest} />;
};

EmptySearch.displayName = 'EmptySearch';
