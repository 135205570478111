// tempexp_16480-full-file
import { APICallV3Post } from 'reactApp/api/APICall';

export interface SubscriptionRenewAddTrialParams {
    prolong_time?: number;
    new_expires?: number;
}

export interface SubscriptionRenewAddTrialQueryParams {
    sub_id: string | number;
    interval?: string;
    action?: 'set';
}

export class SubscriptionRenewAddTrialAPICall extends APICallV3Post<SubscriptionRenewAddTrialParams> {
    _method = 'billing/subscription/free';
}
