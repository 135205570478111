import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';

interface WindowSize {
    windowWidth: number;
    windowHeight: number;
}
const getWindowSize = () => ({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
export const useWindowSize = (): WindowSize => {
    const [size, setSize] = useState<WindowSize>(getWindowSize);

    const debounceCalculateSize = useCallback(
        debounce(() => {
            setSize(getWindowSize());
        }, 200),
        []
    );

    useEffect(() => {
        window.addEventListener('resize', debounceCalculateSize);
        return () => window.removeEventListener('resize', debounceCalculateSize);
    }, []);

    return size;
};
