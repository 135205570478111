export const ReceivedGiftsFetch = {
    NAME: '@receivedGifts/fetch',
    REQUEST: '@receivedGifts/fetch/request',
    SUCCESS: '@receivedGifts/fetch/success',
    FAILURE: '@receivedGifts/fetch/failure',
};

export const ReceivedGiftsUpdate = {
    NAME: '@receivedGifts/update',
    REQUEST: '@receivedGifts/update/request',
    SUCCESS: '@receivedGifts/update/success',
    FAILURE: '@receivedGifts/update/failure',
};
