export const getHasMoreToLoad = ({
    currentCount,
    totalCount,
    maxOffset,
    limit,
}: {
    currentCount: number;
    totalCount: number;
    maxOffset: number;
    limit: number;
}) => currentCount < Math.min(totalCount, maxOffset + limit);
