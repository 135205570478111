import { extInfo } from 'lib/extInfo';

import { getExtension, getItemNameWithoutExt, isFolder as isItemFolder } from './utils';

export class FileBuilder {
    prepareFile(file, isAttaches = false) {
        const isFolder = isItemFolder(file);
        const ext = isFolder ? null : getExtension(file);
        const extLowerCase = ext ? ext.toLowerCase() : null;
        const { kind, subKind } = extInfo.get(extLowerCase!, isAttaches);

        return {
            ...file,
            id: file.id || (file.name === '/' ? file.name : `${file.rev}:${file.deleted_from}${file.name}`),
            size: file.size,
            nameWithoutExt: getItemNameWithoutExt(file.name, ext!),
            isFolder,
            name: file.name,
            kind,
            subKind,
            ext,
            extLowerCase,
        };
    }
}
