import { contactsSelectors } from 'reactApp/modules/contacts/contacts.selectors';
import { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

import { IFolderAccessControlListState } from './folderAccessControlList.types';

const getAccessControlListState = (state: RootState): IFolderAccessControlListState => state.accessControlList;

export const isAccessControlListLoading = createSelector(
    getAccessControlListState,
    (state: IFolderAccessControlListState) => state.isLoading
);

export const getAccessControlList = createSelector(getAccessControlListState, (state: IFolderAccessControlListState) => state.acList);
export const getError = createSelector(getAccessControlListState, (state: IFolderAccessControlListState) => state.error);
export const getOwner = createSelector(getAccessControlListState, (state: IFolderAccessControlListState) => state.owner);

export const getACL = createSelector(getAccessControlList, contactsSelectors.getContacts, (acList, contacts) => {
    if (!acList?.length || !contacts?.length) {
        return acList;
    }

    const contactsMap = contacts?.reduce((result, { email, name }) => {
        result[email] = name;
        return result;
    }, {});

    return acList.map((item) => {
        const newItem = { ...item };

        if (!newItem.name || item.name === item.email) {
            newItem.name = contactsMap[item.email] || item.name;
        }

        return newItem;
    });
});

export const getUserAccessRights = createSelector(
    getAccessControlList,
    (state, email) => email,
    (acList, userEmail) => (acList.find(({ email }) => email === userEmail) || {}).accessRights
);
