import classNames from 'clsx';
import React, { ForwardedRef, forwardRef, memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getACL, getOwner } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.selectors';
import { PublishItem } from 'reactApp/modules/modifying/modifying.types';
import { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { isDomainFolder, isMountedFolder } from 'reactApp/modules/storage/folder.helpers';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getStorageItemByIdFromStorageOrCurrentOrHome, isMountedOrSharedFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { RootState } from 'reactApp/store';
import { Button } from 'reactApp/ui/Button/Button';

import { useSharingNew } from '../SharingWindow/SharingNew/hooks/sharingNew.hook';
import { CloseSharing } from './CloseSharing/CloseSharing';
import { SharingNewSharing } from './Sharing/SharingNewSharing';
import { getTitle } from './SharingNew.constants';
import styles from './SharingNew.css';
import { EFrom, IProps } from './SharingNew.types';
import { SharingNewWeblink } from './Weblink/SharingNewWeblink';

export const SharingNewContent = memo(
    forwardRef((props: IPropsWithPopup<IProps>, ref: ForwardedRef<HTMLDivElement>) => {
        const { itemStorage, id, from, onClose, gaSuffix, isPhone } = props;
        const [showUsersScreen, setShowUsersScreen] = useState<boolean>(false);
        const acList = useSelector(getACL);
        const storage = useSelector(getCurrentStorage);
        const { isSharedIncoming, isPublic, isStart } = getStorage(storage);
        const item = useSelector((state: RootState) => getStorageItemByIdFromStorageOrCurrentOrHome(state, itemStorage, id)) as PublishItem;
        const isFromWeblink = from === EFrom.WEBLINK || isPublic;
        const isFromSharing = from === EFrom.SHARING;
        const isFolder = item?.isFolder;
        const isMounted = isMountedFolder(item);
        const isSharedOrMouted = useSelector((state: RootState) =>
            isFolder ? isMountedOrSharedFolder(state, item) : ({} as ReturnType<typeof isMountedOrSharedFolder>)
        );
        const isDomainItem: boolean = isDomainFolder(item);
        const isAlbum = from === EFrom.ALBUMS || storage === EStorageType.albums;
        const currentUserEmail = useSelector(UserSelectors.getEmail);
        const owner = useSelector(getOwner);

        const isOwner = currentUserEmail === owner?.email;
        const showSharing =
            !isAlbum &&
            isFolder &&
            !isDomainItem &&
            !isStart &&
            (!(isSharedOrMouted.isMounted || isSharedOrMouted.isShared) || item?.id === isSharedOrMouted.folderId);

        const { isCloudUsersOpened, isWeblinkOpened, setWeblinkOpened, setCloudUsersOpened, onActionClick, onChangeWeblink } =
            useSharingNew({ storage, isFromSharing, item, acList, isFromWeblink, isOwner });

        const showCloseButton = isWeblinkOpened && !showUsersScreen;

        useEffect(() => {
            if (!item && onClose) {
                onClose();
            }
        }, [item, onClose]);

        return (
            <div className={classNames(styles.root, { [styles.root_phone]: isPhone })}>
                <div className={styles.header}>{getTitle({ isPublic, isMounted, name: item?.name })}</div>
                <div className={classNames(styles.content, { [styles.content_pb]: !showCloseButton && !isOwner })} ref={ref}>
                    {!isSharedIncoming && !showUsersScreen && (
                        <SharingNewWeblink
                            item={item}
                            from={from}
                            isSharedOrMouted={isSharedOrMouted}
                            gaSuffix={gaSuffix}
                            noExpiresControl={isPublic}
                            isPhone={isPhone}
                            isWeblinkOpened={isWeblinkOpened}
                            setWeblinkOpened={setWeblinkOpened}
                            onActionClick={onActionClick}
                        />
                    )}
                    {showSharing && (
                        <SharingNewSharing
                            onClose={onClose}
                            item={item}
                            from={from}
                            isPhone={isPhone}
                            isMounted={isMounted}
                            showUsersScreen={showUsersScreen}
                            setShowUsersScreen={setShowUsersScreen}
                            acList={acList}
                            isCloudUsersOpened={isCloudUsersOpened}
                            setCloudUsersOpened={setCloudUsersOpened}
                            owner={owner}
                            isAlbum={isAlbum}
                            currentUserEmail={currentUserEmail}
                            onActionClick={onActionClick}
                        />
                    )}
                    {showUsersScreen && (
                        <Button className={styles.backButton} size="big" middle onClick={() => setShowUsersScreen(false)}>
                            Назад
                        </Button>
                    )}
                    {showCloseButton && <CloseSharing onClick={onChangeWeblink} showSharing={showSharing} isOwner={isOwner} />}
                </div>
            </div>
        );
    })
);

SharingNewContent.displayName = 'SharingNewContent';
