import trialPromoImg from 'img/aside-promo-modal/trial-promo.jpg';
import { xray } from 'lib/xray';
import React, { memo, ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openVkBuyModalWithSidebar } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.helpers';
import { EPaymentModalType } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.types';
import { getFeaturesAttachesTrialPromo } from 'reactApp/modules/features/features.selectors';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { RootState } from 'reactApp/store';
import { AsidePromoModal } from 'reactApp/ui/AsidePromoModal/AsidePromoModal';
import { EAsidePromoModalTheme } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.types';
import { ESplashScreenAction, useSplashScreenAnalytics } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen.analytics';
import { getPromoContent } from 'reactApp/ui/TrialPromoBanner/TrialPromoBanner.helpers';

import styles from './TrialPromoBanner.css';

interface Props {
    productId: string;
}

export const TRIAL_ATTACHES_BANNER_ID = 'attaches-trial-banner';

export const TrialPromoBanner = memo(({ productId }: Props): ReactElement | null => {
    const dispatch = useDispatch();

    const trialPromoFeature = useSelector(getFeaturesAttachesTrialPromo);

    const product = useSelector((state: RootState) => ProductsSelectors.getProductById(state, productId));

    const [showBanner, setShowBanner] = useState<boolean>(true);

    const { title, buttonText, text } = getPromoContent(product);

    const sendSplashScreenAnalytics = useSplashScreenAnalytics({ name: 'attaches-trial', button_name: buttonText });

    const onPromoButtonClick = useCallback(() => {
        openVkBuyModalWithSidebar({ productId, type: EPaymentModalType.attachesTrial });
        sendSplashScreenAnalytics(ESplashScreenAction.click);
        setShowBanner(false);
        dispatch(UserStorageActions.set(TRIAL_ATTACHES_BANNER_ID, '1'));
    }, [productId, sendSplashScreenAnalytics]);

    const onBannerClose = useCallback(() => {
        setShowBanner(false);
        sendSplashScreenAnalytics(ESplashScreenAction.close);
        dispatch(UserStorageActions.set(TRIAL_ATTACHES_BANNER_ID, '1'));
    }, []);

    useEffect(() => {
        if (product?.id) {
            sendSplashScreenAnalytics(ESplashScreenAction.show);
        }
        xray.send('trial_promo_bnr_show', { i: product?.id || '' });
    }, [product?.id]);

    if (!product || !trialPromoFeature) {
        return null;
    }

    return (
        <div className={styles.banner}>
            {showBanner && (
                <AsidePromoModal
                    fixed={false}
                    title={title}
                    text={text}
                    buttonText={buttonText}
                    imageUrl={trialPromoImg}
                    onClose={onBannerClose}
                    onClick={onPromoButtonClick}
                    theme={EAsidePromoModalTheme.dark}
                />
            )}
        </div>
    );
});

TrialPromoBanner.displayName = 'TrialPromoBanner';
