import { WeblinkDownloadable } from 'reactApp/modules/public/public.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { AccessRights, Url } from 'reactApp/types/Tree';

export enum EStoryItemType {
    image = 'image',
    video = 'video',
}

export enum EStoryType {
    historyStory = 'historyStory',
    selectionStory = 'selectionStory',
    marketingStory = 'marketingStory',
    flashbackStory = 'flashbackStory',
}

export enum EStoryGalleryBlockType {
    gallery = 'gallery',
}

export enum EStoryScrollType {
    horizontal = 'horizontal',
    vertical = 'vertical',
}

export enum EStoryHeaderType {
    history = 'history',
    marketingStory = 'marketingStory',
    article = 'article',
    selection = 'selection',
    flashback = 'flashback',
}

export enum IEnumStoryContentType {
    elementWithPath = 'elementWithPath',
    elementWithURL = 'elementWithURL',
    elementWithPathAndTitle = 'elementWithPathAndTitle',
    elementWithPathAndTopTitle = 'elementWithPathAndTopTitle',
    elementWithURLAndAction = 'elementWithURLAndAction',
    elementWithURLAndWebLink = 'elementWithURLAndWebLink',
}

export type IStoryHeader = IStoryHistoryHeader | IStoryMarketingHeader | IStoryArticleHeader | IStorySelectionHeader;

export interface IStory {
    type: EStoryType;
    id: string;
    isLoading: boolean;
    isLoaded: boolean;
    viewed: boolean;
    error?: string;
    cover: IStoryCover | null;
    // Для просмотрщика
    header: IStoryHistoryHeader | IStoryMarketingHeader | IStoryArticleHeader | IStorySelectionHeader | IStoryFlashbackHeader | null;
    // Для галереи по клику Подробнее
    blocks?: IStoryGalleryBlock[] | null;
}

export interface IStoryState {
    isLoading: boolean;
    isLoaded: boolean;
    error?: string;
    cursor?: string;
    files: Record<string, IStoryFileItem>;
    // Выбранная стори для просмотра
    story: IStory | null;
    // Для виджета
    summaries: Record<string, IStorySummary>;
    summaryIdxs: string[];
    unviewedCount: number;
}

export interface IStoryThumb {
    xms0: string;
    xms4: string;
    xms3: string;
    xm0: string;
}

export interface IStoryFileItem extends WeblinkDownloadable {
    name: string;
    path: string;
    size: number;
    kind: EStoryItemType;
    // Был ли файлу проставлен лайк, in api: favorited
    isInFavorites: boolean;
    thumb: IStoryThumb;
    mtime?: number;
    // Video duration
    duration_ms?: number;
    awesomeness?: number;

    weblink?: string; // ???
    weblinkAccessRights?: AccessRights;
    weblinkExpires?: number;
    weblinkDomestic?: boolean;

    // Calculated fields
    id: string;
    ext: string;
    storage: EStorageType.story;
    nameWithoutExt: string;
    parent: string;
    isFolder: boolean;
    url: Url;
    home?: string;
}

export interface IStoryContentElementUrl {
    id: string;
    element_id: string;
    type: IEnumStoryContentType.elementWithURL;
    url: string;
}

export interface IStoryContentElementFile {
    id: string;
    element_id: string;
    type: IEnumStoryContentType.elementWithPath;
    file: string;
}

export interface IStorySummary {
    id: string;
    title: string;
    subtitle: string;
    type: EStoryType;
    cover: IStoryContentElementUrl | IStoryContentElementFile;
    viewed: boolean;
    last_viewed_element_id?: string;
    created_at: number;
}

export interface IStoryListApiResponse {
    cursor?: string;
    list: IStorySummary[];
}

export interface IStoryCover {
    id: string;
    content: IStoryContentItem;
}

export interface IStoryInfoRequest {
    id: string;
    type: EStoryType;
}

export interface IStoryActionButton {
    action_url: string;
    title: string;
    // HexColor
    button_color: string;
}

export interface IStoryWeblinkButton {
    link: string;
    title: string;
}

export interface IStoryContentElementFileWithTitle extends Omit<IStoryContentElementFile, 'type'> {
    type: IEnumStoryContentType.elementWithPathAndTitle;
    title: string;
    subtitle: string;
}

export interface IStoryContentElementFileWithTopTitle extends Omit<IStoryContentElementFile, 'type'> {
    type: IEnumStoryContentType.elementWithPathAndTopTitle;
    top_title: string;
    top_subtitle: string;
}

export interface IStoryContentElementUrlWithAction extends Omit<IStoryContentElementUrl, 'type'> {
    type: IEnumStoryContentType.elementWithURLAndAction;
    title: string;
    description: string;
    image_url: string;
    button?: IStoryActionButton;
}

export interface IStoryContentElementUrlWithWeblink extends Omit<IStoryContentElementUrl, 'type'> {
    type: IEnumStoryContentType.elementWithURLAndWebLink;
    title: string;
    description: string;
    image_url: string;
    button?: IStoryWeblinkButton;
}

export type IStoryContentItem =
    | IStoryContentElementFileWithTopTitle
    | IStoryContentElementUrlWithWeblink
    | IStoryContentElementFileWithTitle
    | IStoryContentElementFile
    | IStoryContentElementUrl;

export interface IStoryHistoryHeader {
    type: EStoryHeaderType.history;
    top_title: string;
    top_subtitle: string;
    content: IStoryContentElementFileWithTitle[];
}

export interface IStorySelectionHeader {
    type: EStoryHeaderType.selection;
    bottom_title: string;
    bottom_subtitle: string;
    content: IStoryContentElementFileWithTopTitle[];
}

export interface IStoryFlashbackHeader {
    type: EStoryHeaderType.flashback;
    bottom_title: string;
    bottom_subtitle: string;
    content: IStoryContentElementFileWithTopTitle[];
}

export interface IStoryMarketingHeader {
    type: EStoryHeaderType.marketingStory;
    top_title: string;
    top_subtitle: string;
    subject_id: string;
    content: IStoryContentElementUrlWithAction[];
}

export interface IStoryArticleHeader {
    type: EStoryHeaderType.article;
    top_title: string;
    top_subtitle: string;
    subject_id: string;
    image_url: string;
    content: IStoryContentElementUrlWithWeblink[];
}

export interface IStoryGalleryBlock {
    header: string;
    type: EStoryGalleryBlockType;
    title?: string;
    subtitle?: string;
    scroll: EStoryScrollType;
    content: (IStoryContentElementUrl | IStoryContentElementFile)[];

    content_type?: string;
}

export interface IStoryInfoApiResponse {
    id: string;
    cover: IStoryContentElementUrl | IStoryContentElementFile;
    // для отображения  в просмотрщике
    header: IStoryHeader;
    // данные для подвала (нажатие на кнопку "Подробнее" в истории)
    blocks?: IStoryGalleryBlock[];
    viewed: boolean;
}

export interface IStoryShowRequest {
    storyId: string;
    type: EStoryType;
    item?: string;
}
