import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useEffect } from 'react';
import { IS_PUBLIC_FOLDER, PUBLIC_SHOVE } from 'reactApp/appHelpers/configHelpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { useRouteChangeProcessing } from 'reactApp/sections/MainPage/hooks/useRouteChangeProcessing';
import { DisableButton } from 'reactApp/ui/AdvBanners/DisableButton/DisableButton';
import { File } from 'reactApp/ui/Public/Components/File/File';
import { Folder } from 'reactApp/ui/Public/Components/Folder/Folder';
import { ShoveFrame } from 'reactApp/ui/Public/Components/Shove/ShoveFrame';

import styles from './ShovePublic.css';
import { getShoveBackground, sendShoveGa } from './ShovePublic.helpers';
import { EShoveType } from './ShovePublic.types';

interface Props {
    storage: EStorageType;
}

export const ShovePublic = memo(function ShovePublic({ storage }: Props): ReactElement {
    const shoveBackgroundStyle = PUBLIC_SHOVE ? getShoveBackground(PUBLIC_SHOVE) : '';
    const shoveStyle = shoveBackgroundStyle ? { background: shoveBackgroundStyle } : {};
    const type = IS_PUBLIC_FOLDER ? EShoveType.FOLDER : EShoveType.FILE;

    useRouteChangeProcessing({
        storage,
        path: location.pathname.replace(`/${storage}/`, ''),
        search: location.search,
    });

    const onLoad = useCallback(() => {
        sendShoveGa(`${storage}_${type}_load`);
    }, []);

    const onTimeOut = useCallback(() => {
        sendShoveGa(`${storage}_${type}_timeout`);
    }, []);

    const onError = useCallback(() => {
        sendShoveGa(`${storage}_${type}_error`);
    }, []);

    useEffect(() => {
        sendShoveGa(`${storage}_${type}_show`);
    }, []);

    return (
        <div className={classNames(styles.root, styles[`root_type_${type}`])} style={{ background: PUBLIC_SHOVE?.color }}>
            <div className={styles.content} style={shoveStyle}>
                {IS_PUBLIC_FOLDER ? (
                    <div className={styles.folderContent}>
                        <Folder isShove />
                    </div>
                ) : (
                    <div className={styles.fileContent}>
                        <File />
                    </div>
                )}
            </div>
            <div className={styles.frame}>
                <ShoveFrame onLoad={onLoad} onTimeOut={onTimeOut} onError={onError} />
            </div>
            <div className={styles.disableButton}>
                <DisableButton id="shove" theme="dark" />
            </div>
        </div>
    );
});
