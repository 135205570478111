import { requestPayment } from 'Cloud/Application/Payment';
import expiredSubsImg from 'img/expiredSubs.png';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getProductById } from 'reactApp/modules/products/products.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { RootState } from 'reactApp/store';
import { ESplashScreenMod, UseContentHook } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen.types';
import { getPeriodName } from 'reactApp/utils/Period';

export const useExpiredSubscriptionScreen: UseContentHook = ({ hookParams = {} }) => {
    const { total } = useSelector(UserSelectors.getCloudSpace);
    const product = useSelector((state: RootState) => getProductById(state, hookParams?.productId));

    const footerText = product?.trialPeriod ? `Первые ${getPeriodName(product.trialPeriod, false, false, true)} бесплатно!` : '';

    const onClick = useCallback(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        requestPayment({ isMobile: true, tariff: product?.id });
    }, [product?.id]);

    return {
        id: 'mobile-canceled-subs',
        title: 'Ваша подписка закончилась',
        description: `Размер Облака уменьшился до ${total?.value}. Чтобы вернуть объём, верните подписку в веб-версии.`,
        buttonText: `Подключить ${product?.space?.value}`,
        onClick,
        img: expiredSubsImg,
        mod: ESplashScreenMod.blue,
        footerText,
    };
};
