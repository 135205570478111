import classNames from 'clsx';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestFacesListLoadMoreRequest, selectFace } from 'reactApp/modules/faces/faces.module';
import {
    getFacesListRequestState,
    getFilteredFacesList,
    getSelectedFaceId,
    hasMoreFacesToLoad,
} from 'reactApp/modules/faces/faces.selectors';
import { IFace } from 'reactApp/modules/faces/faces.types';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { IProps } from 'reactApp/ui/FaceFilter/FaceFilter.types';
import { FaceFilterItem } from 'reactApp/ui/FaceFilter/FaceFilterItem';
import { FaceListTooltip } from 'reactApp/ui/FaceFilter/FaceList/FaceList';
import { usePublicFaceRadars } from 'reactApp/ui/FaceFilter/hooks/publicFaceRadars';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement, ETooltipSize } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { noop } from 'reactApp/utils/helpers';

import { MAX_FIRST_NUMBER_FACES_IN_FILTER } from './FaceFIlter.constants';
import styles from './FaceFilterComponent.css';

export const FaceFilterComponent = memo(function FaceFilterComponent({
    faces,
    selectedFaceId,
    onSelect = noop,
    showTip = false,
    onHideTip = noop,
    hasMoreToLoad,
    isLoading,
    loadMore,
    onShow,
    onPopup,
}: IProps) {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);
    const facesRef = useRef<HTMLDivElement | null>(null);

    const facesToShow = useMemo(() => {
        if (!faces?.length) {
            return [];
        }
        let res: (IFace | { count: number })[] = [...faces].sort((a, b) => {
            if (b.id === selectedFaceId) {
                return 1;
            } else if (a.id === selectedFaceId) {
                return -1;
            }

            return b.numOfPhotos - a.numOfPhotos;
        });

        if (faces.length > MAX_FIRST_NUMBER_FACES_IN_FILTER) {
            res = res.slice(0, MAX_FIRST_NUMBER_FACES_IN_FILTER - 1);
            res.push({ count: faces.length });
        }

        return res;
    }, [selectedFaceId, faces]);

    const handleOnCounter = useCallback(() => {
        if (faces && faces.length > MAX_FIRST_NUMBER_FACES_IN_FILTER) {
            setIsOpen(!isOpen);
        }
    }, [setIsOpen, isOpen, faces?.length]);

    const handleOnSelect = useCallback(
        (id) => {
            onSelect(id);
            setIsOpen(false);
        },
        [setIsOpen, onSelect]
    );

    const handleCloseTooltip = useCallback(() => {
        setIsOpen(false);
    }, []);

    useEffect(() => {
        if (faces && faces.length > 0) {
            onShow?.();
        }
    }, [faces?.length]);

    useEffect(() => {
        if (isOpen) {
            onPopup?.();
        }
    }, [isOpen, onPopup]);

    if (!facesToShow?.length) {
        return null;
    }

    return (
        <>
            <div className={styles.root} ref={ref}>
                {facesToShow.map((face, idx) => {
                    if ('count' in face) {
                        return <FaceFilterItem key={idx} count={face.count} onCounter={handleOnCounter} />;
                    }

                    return (
                        <FaceFilterItem
                            /* key={idx} для анимации, мы рисуем просто слоты, а в них могут быть разные лица */
                            key={idx}
                            id={face.id}
                            url={face.imageUrl}
                            isSelected={face.id === selectedFaceId}
                            onSelect={handleOnSelect}
                        />
                    );
                })}
                {/* Первое время всегда показываем текст, потом: [styles.text_show]: isOpen */}
                <div className={classNames({ [styles.text]: true, [styles.text_show]: true })} onClick={handleOnCounter} ref={facesRef}>
                    Люди на фото
                </div>
            </div>
            {isOpen && (
                <FaceListTooltip
                    faces={faces}
                    selectedFaceId={selectedFaceId}
                    onSelect={handleOnSelect}
                    loadMore={loadMore}
                    hasMoreToLoad={hasMoreToLoad}
                    isLoading={isLoading}
                    onTooltipClose={handleCloseTooltip}
                    targetRef={ref}
                />
            )}
            {showTip && (
                <FloatingTooltip
                    target={facesRef}
                    placement={ETooltipPlacement.bottom}
                    size={ETooltipSize.medium}
                    title="Люди на фото"
                    text="Теперь вы можете фильтровать фото по распознанным лицам в альбоме"
                    onClose={onHideTip}
                    qaId="tooltip-faces"
                />
            )}
        </>
    );
});

export const FaceFilter = memo(function FaceFilter() {
    const dispatch = useDispatch();
    const [showTip, setShowTip] = useState(false);

    const faces = useSelector(getFilteredFacesList);
    const selectedFaceId = useSelector(getSelectedFaceId);
    const requestState = useSelector(getFacesListRequestState);
    const hasMoreToLoad = useSelector(hasMoreFacesToLoad);
    const faceFilterPromo = useSelector(PromoSelectors.getPromo(EPromoType.faceFilter));

    const handleLoadMore = useCallback(() => {
        dispatch(requestFacesListLoadMoreRequest());
    }, []);

    useEffect(() => {
        if (faceFilterPromo && !showTip) {
            setShowTip(true);
            faceFilterPromo.onShow();
        }
    }, [faceFilterPromo, showTip]);

    const handleHideTip = useCallback(() => {
        faceFilterPromo?.onClose();
    }, [faceFilterPromo]);

    const { sendFacesHelper } = usePublicFaceRadars({ countFaces: faces?.length });

    const handleSelectFace = useCallback(
        (id) => {
            sendFacesHelper('face_filter_select');
            dispatch(selectFace({ id }));
        },
        [sendFacesHelper]
    );

    const handleOnShow = useCallback(() => {
        sendFacesHelper('face_filter');
    }, [sendFacesHelper]);

    const handlePopupShow = useCallback(() => sendFacesHelper('toolbar_all_faces'), [sendFacesHelper]);

    return (
        <FaceFilterComponent
            selectedFaceId={selectedFaceId}
            faces={faces}
            onSelect={handleSelectFace}
            onHideTip={handleHideTip}
            showTip={showTip}
            hasMoreToLoad={hasMoreToLoad}
            isLoading={requestState.isLoading}
            loadMore={handleLoadMore}
            onShow={handleOnShow}
            onPopup={handlePopupShow}
        />
    );
});
