import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useEffect, useState } from 'react';
import { ADS_TIMER_MS } from 'reactApp/constants';
import { useShowAdsBannerTimer } from 'reactApp/hooks/useShowAdsBannerTimer';
import { noop } from 'reactApp/utils/helpers';

import styles from './RollContent.css';

interface Props {
    darkMode?: boolean;
    domRollId: string;
    showElementIsInViewport?: boolean;
    renderFooter?: () => null | ReactElement;

    updateContent?(data?: { onError?(); onSuccess?(): void }): void;
}

export const RollContent = memo<Props>(
    ({ darkMode = false, domRollId = 'rollid', showElementIsInViewport, renderFooter = noop, updateContent = noop }) => {
        const [show, setShow] = useState(() => showElementIsInViewport ?? true);

        const handleSuccess = useCallback(() => {
            // Если блок с рекламой не полностью находится в окне просмотра,
            // то после обновления из рб, код рб считает, что блок не виден и скрывает рекламу.
            // Если сделать скролл хотя бы на 1 пиксель, код рб делает рекламу видимой.
            window.scrollBy(0, 1);
            window.scrollBy(0, -1);
        }, []);

        const update = () => {
            try {
                updateContent({
                    onError: () => {
                        setShow(false);
                    },
                    onSuccess: handleSuccess,
                });
            } catch (_) {
                setShow(false);
            }
        };

        useEffect(() => update(), [updateContent, setShow]);

        return useShowAdsBannerTimer({
            adsUpdateTimerMs: ADS_TIMER_MS,
            updateAds: update,
            renderItem: () => {
                if (!show) {
                    return null;
                }
                return (
                    <div className={classNames({ [styles.root]: true, [styles.root_dark]: darkMode })}>
                        <div id={domRollId} className={styles.placeholder} />
                        {Boolean(renderFooter) && renderFooter?.()}
                    </div>
                );
            },
        });
    }
);

RollContent.displayName = 'RollContent';
