import { Spaces, toNDigits } from '@mail/cross-sizes-utils';

const SIZE_RANGES = {
    kb: Spaces.ONE_KB,
    mb: Spaces.ONE_MB,
    '5mb': 5 * Spaces.ONE_MB,
    '10mb': 10 * Spaces.ONE_MB,
    '20mb': 20 * Spaces.ONE_MB,
    '25mb': 25 * Spaces.ONE_MB,
    '30mb': 30 * Spaces.ONE_MB,
    '40mb': 40 * Spaces.ONE_MB,
    '50mb': 50 * Spaces.ONE_MB,
    '75mb': 75 * Spaces.ONE_MB,
    '100mb': 100 * Spaces.ONE_MB,
};

export const getSize = (size: number) =>
    toNDigits({
        bytes: size,
        maxDigitsCount: 3,
    }).value;

export const getSizeInteger = (size) => {
    if (Number.isInteger(size)) {
        return size;
    }

    return size ? size.original : 0;
};

export const getSizeRange = (size: number, ranges: Record<string, number> = SIZE_RANGES): string => {
    const range = Object.keys(ranges).find((range) => ranges[range] > size);
    return range || 'large';
};
