import { JavaScriptError } from 'reactApp/modules/uploading/errors/JavaScriptError';
import { LocalFileNotFoundFail } from 'reactApp/modules/uploading/fails/LocalFileNotFoundFail';
import { LocalFileNotReadableFail } from 'reactApp/modules/uploading/fails/LocalFileNotReadableFail';
import { UnsupportedFolderTransferFail } from 'reactApp/modules/uploading/fails/UnsupportedFolderTransferFail';
import { MAX_READABLE_SIZE } from 'reactApp/modules/uploading/helpers/fs/fs.helpers';
import { EUploadReasonSource } from 'reactApp/modules/uploading/serviceClasses/UploadingReason';

export const readFile = (file: File) => {
    if (file.size > MAX_READABLE_SIZE) {
        return Promise.reject(new Error('too large'));
    }

    const reader = new FileReader();

    return _readFile(reader, file).then(function () {
        return file;
    });
};

/**
 * @param startIdx? - для чтения не всего файла, а блока байт из него
 * @param whole? - читать до первого прогресс евента
 */
export function _readFile(
    reader: FileReader,
    file: File,
    startIdx = 0,
    stopIdx: number | null = null,
    whole = false
): Promise<ArrayBuffer | null> {
    const blob = startIdx >= 0 && stopIdx !== null ? file.slice(startIdx, stopIdx + 1) : file;

    if (whole) {
        try {
            return blob.arrayBuffer();
        } catch (error: any) {
            return Promise.reject(handleError(error, file));
        }
    }

    let promise = new Promise<ArrayBuffer | null>(function (resolve) {
        const handler = (event) => {
            let promise;

            if (event.type === 'error') {
                promise = Promise.reject(handleError(reader.error, file));
            } else {
                if (reader.readyState === reader.LOADING) {
                    reader.abort();
                }

                if (reader.readyState === FileReader.DONE) {
                    promise = Promise.resolve(reader.result);
                }

                // resolve first progress
                promise = Promise.resolve(reader.result);
            }

            reader.removeEventListener('error', handler);
            reader.removeEventListener('load', handler);
            reader.removeEventListener('loadend', handler);
            reader.removeEventListener('progress', handler);

            resolve(promise);
        };

        reader.addEventListener('error', handler);
        reader.addEventListener('load', handler);
        reader.addEventListener('loadend', handler);
        reader.addEventListener('progress', handler);

        reader.readAsArrayBuffer(blob);
    });

    if (!whole) {
        // Если файл не считывается целиком, значит чтение используется
        // для проверки существования файла, поэтому чтобы не блокировать
        // возможность аплоада, игнорируем ошибку "Out of Memory".
        promise = promise.catch(function (error) {
            if (error && error.name === 'NS_ERROR_OUT_OF_MEMORY') {
                return Promise.resolve(null);
            }

            return Promise.reject(error);
        });
    }

    return promise;
}

function handleError(error: DOMException | null, file: File) {
    const source = EUploadReasonSource.SOURCE_WEB_CLIENT;
    let stack;
    let reason;

    if (LocalFileNotFoundFail.isNotFoundError(error)) {
        stack = new Error('LocalFileNotFoundFail');
        reason = new LocalFileNotFoundFail(stack, source);
    } else if (!file.type && UnsupportedFolderTransferFail.isNotReadableError(error)) {
        stack = new Error('UnsupportedFolderTransferFail');
        reason = new UnsupportedFolderTransferFail(stack, source);
    } else if (LocalFileNotReadableFail.isNotReadableError(error)) {
        stack = new Error('LocalFileNotReadableFail');
        reason = new LocalFileNotReadableFail(stack, source);
    } else {
        stack = new Error('JavaScriptError');
        reason = new JavaScriptError(stack, source, error);
    }

    return reason;
}
