interface Overquota {
    clearTime?: number;
    clearStarted?: number;
}

export interface Profile {
    email: string;
    overquota?: Overquota;
    checkUpdateLicenseCompleted: boolean;
}

export interface ApiProfile {
    email: string;
    properties: { 'overquota.clear_time'?: number; 'overquota.clear_started'?: number };
}

export enum ProfileApiResponses {
    USER_NOT_FOUND = 'USER_NOT_FOUND',
    DEVICE_NOT_FOUND = 'DEVICE_NOT_FOUND',
}
