import classNames from 'clsx';
import React from 'react';
import { Loader as SemanticLoader } from 'semantic-ui-react';

import styles from './Loader.css';

interface IProps {
    centered?: boolean;
    isModal?: boolean;
}

export const Loader = ({ centered, isModal }: IProps) => (
    <div
        className={classNames({
            [styles.root]: true,
            [styles.centered]: centered,
            [styles.modal]: Boolean(isModal),
        })}
    >
        <SemanticLoader active />
    </div>
);
