import { createAction, createReducer } from '@reduxjs/toolkit';
import { ApiProfile, Profile } from 'reactApp/modules/profile/profile.types';

const initialState: Profile = {
    email: '',
    overquota: {},
    checkUpdateLicenseCompleted: false,
};

export const loadProfileRequest = createAction('@profile/clearError');
export const loadProfileSuccess = createAction<{ profile: ApiProfile }>('@profile/loadProfileSuccess');
export const loadProfileFailure = createAction('@profile/loadProfileFailure');
export const checkUpdateLicenseRequest = createAction('@profile/user-la/request');
export const agreeUpdateLicenseRequest = createAction<{ source: string; isUpdate?: boolean }>('@profile/agree-la/request');
export const checkUpdateLicenseCompleted = createAction('profile/checkUpdateLicenseCompleted');

export const profileReducer = createReducer(initialState, {
    [loadProfileSuccess.type]: (state, action: ReturnType<typeof loadProfileSuccess>): void => {
        const { profile } = action.payload;

        const { properties, email } = profile;

        state.email = email;
        state.overquota = {
            clearTime: (properties['overquota.clear_time'] || 0) * 1000,
            clearStarted: (properties['overquota.clear_started'] || 0) * 1000,
        };
    },
    [checkUpdateLicenseCompleted.type]: (state): void => {
        state.checkUpdateLicenseCompleted = true;
    },
});
