import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { sendDwh } from 'reactApp/utils/ga';

export const sendSuccessRadar = (subscriptionId) => {
    const queryParams = getQueryParams();
    const clientId = queryParams.clientId;
    const group = queryParams.group;
    const from = queryParams.from;

    if (!clientId || !group || !subscriptionId) {
        return;
    }

    let eventCategory = '';
    let label = '';

    if (from === 'disko') {
        eventCategory = 'disko_tariff';
        label = 'buy';
    } else if (from === 'landingPro') {
        eventCategory = 'landing_pro';
        label = 'buy-tariff';
    }

    if (!eventCategory) {
        return;
    }

    sendDwh({
        eventCategory,
        label,
        dwhData: {
            client_id: clientId,
            group,
            // отправляем айди тарифа, который купили по факту, а не того, который был в гет-параметре productId
            name_tariff: subscriptionId,
        },
    });
};
