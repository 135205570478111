import React, { FC, useRef } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { Checkbox } from 'reactApp/ui/DataListItemCommonComponents/Checkbox/Checkbox';
import { Download } from 'reactApp/ui/DataListItemCommonComponents/Download/Download';
import { Date } from 'reactApp/ui/DataListItemRow/components/Date/Date';
import { ItemName } from 'reactApp/ui/DataListItemRow/components/ItemName/ItemName';
import { ItemTip } from 'reactApp/ui/DataListItemRow/components/ItemTip/ItemTip';
import { Size } from 'reactApp/ui/DataListItemRow/components/Size/Size';
import { TableColumn } from 'reactApp/ui/DataListItemRow/components/TableColumn/TableColumn';
import { QuotaCleanerItemProps } from 'reactApp/ui/DataListItemRow/DataListItemRow.types';
import { FilePic } from 'reactApp/ui/FilePic/FilePic';
import { useCompactView } from 'reactApp/ui/VirtualList/VirtualList.hooks';

import styles from '../../DataListItemRow.new.css';

export const QuotaCleanerItem: FC<QuotaCleanerItemProps> = (props) => {
    const {
        id,
        storage,
        tipData,
        isFolder,
        thumbUrl,
        isVirus,
        kind,
        isPublic,
        name,
        size,
        author,
        date,
        ext,
        isSelected,
        onCheckboxClick,
        onDownload,
        parentItemRef,
    } = props;

    const isCompact = useCompactView();

    const tipTargetRef = useRef(null);

    return (
        <>
            <ItemTip target={parentItemRef} tipData={tipData} isFolder={isFolder ?? false} />
            <TableColumn>
                <Checkbox viewType={EViewMode.list} className={styles.check} onCheckboxClick={onCheckboxClick} isSelected={isSelected} />
                <FilePic
                    ref={tipTargetRef}
                    isFolder={isFolder}
                    thumbUrl={thumbUrl}
                    kind={kind}
                    isVirus={isVirus}
                    ext={ext}
                    author={author}
                    isAuthorView={!!author}
                    isPublic={isPublic}
                />
            </TableColumn>

            {isCompact ? (
                <TableColumn width="100%" overflow="hidden">
                    <div className={styles.nameWrapper}>
                        <ItemName name={name} ext={ext || ''} id={id} />
                        <span className={styles.responsiveDateWrapper}>
                            <Size size={size} isInline />
                            {!!date && <>&nbsp;•&nbsp;</>}
                            <Date date={date} className={styles.date} />
                        </span>
                    </div>
                </TableColumn>
            ) : (
                <>
                    <TableColumn width="100%" overflow="hidden">
                        <ItemName name={name} ext={ext || ''} id={id} />
                    </TableColumn>
                    <TableColumn flexBasis={132} flexShrink={0} justifyContent="flex-end">
                        <Date date={date} />
                    </TableColumn>
                    <TableColumn flexBasis={132} flexShrink={0} justifyContent="center">
                        <Size size={size} />
                    </TableColumn>
                </>
            )}

            <TableColumn flexBasis={100} flexShrink={0} justifyContent="flex-end" gap={20}>
                <Download onClick={onDownload} ext={ext} size={size} id={id} storage={storage} viewType={EViewMode.list} />
            </TableColumn>
        </>
    );
};
