import classNames from 'clsx';
import React, { ReactElement } from 'react';

import styles from './SharingNewSection.css';
import { IProps } from './SharingNewSection.types';

export const SharingNewSection = React.memo(function SharingNewSection({
    isOpened,
    className = '',
    name,
    children,
    isPhone,
}: IProps): ReactElement {
    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles.root_opened]: isOpened,
                [styles.root_phone]: isPhone,
                [className]: Boolean(className),
            })}
            data-qa-section={name}
        >
            <div className={styles.content}>{children}</div>
        </div>
    );
});
