import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { downloadAsJpeg } from 'Cloud/Application/DownloadAsJpeg';
import classNames from 'clsx';
import React, { memo, useMemo } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { DataListControl, EDatalistControlTheme } from 'reactApp/ui/DataListControl/DataListControl';
import { Hint } from 'reactApp/ui/Hint/Hint';

import styles from '../DataListGalleryItem.css';

export const Download = memo(
    ({
        ext,
        onClick,
        onDropdown,
        id,
        storage,
        size,
        name,
    }: {
        ext: string;
        onClick: () => void;
        onDropdown: (isOpen: boolean) => void;
        id: string;
        storage: EStorageType;
        size: number;
        name: string;
    }) => {
        const downloadHint = useMemo(() => `Скачать ${name}.${ext.toLowerCase()}, ${bytesToNDigits(size, 3).value}`, [ext, size]);

        return downloadAsJpeg.isAvailable({ ext, storage }) ? (
            <div className={classNames(styles.control, styles.download)}>
                <DataListControl
                    type="downloadAsJpeg"
                    onClick={onClick}
                    onDropdown={onDropdown}
                    list={downloadAsJpeg.getDropdownItems({
                        itemOrId: id,
                        gaSuffix: 'datalist',
                        ext,
                        textPrefix: '',
                        storage,
                    })}
                    listWithArrow={false}
                    hint={downloadHint}
                    size={28}
                    theme={EDatalistControlTheme.dark}
                    viewType={EViewMode.gallery}
                />
            </div>
        ) : (
            <Hint text={downloadHint}>
                <div className={classNames(styles.control, styles.download)}>
                    <DataListControl
                        type="download"
                        onClick={onClick}
                        size={28}
                        theme={EDatalistControlTheme.dark}
                        viewType={EViewMode.gallery}
                    />
                </div>
            </Hint>
        );
    }
);

Download.displayName = 'Download';
