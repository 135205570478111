import settings from 'Cloud/settings';
import { useSelector } from 'react-redux';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFeatureStartPageRoot } from 'reactApp/modules/features/features.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { StockFolder } from 'reactApp/modules/stock/stock.type';
import { getPageTitleByStorage } from 'reactApp/modules/storage/storage.helpers';
import { getStorageCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getHasError } from 'reactApp/modules/user/user.selectors';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { RootState } from 'reactApp/store';

export const useDataContainer = () => {
    const hasError = useSelector(getHasError);
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    const isRootToReactPage = !!useSelector(getFeatureStartPageRoot);
    const storage = useSelector(getCurrentStorage);
    const viewerItem = useSelector(ViewerSelectors.getViewerItem);
    const currentFolder = useSelector((state: RootState) => getStorageCurrentFolder(state, storage));
    let itemTitle = currentFolder && 'name' in currentFolder ? currentFolder.name : '';
    if (storage === EStorageType.stock && !(currentFolder as unknown as StockFolder)?.parent) {
        itemTitle = settings?.storages?.[EStorageType.stock]?.title;
    } else if (viewerItem?.name) {
        itemTitle = viewerItem.name;
    }

    const title = getPageTitleByStorage(storage, itemTitle);

    return {
        hasError,
        isPhone,
        isRootToReactPage,
        title,
        storage,
    };
};
