import { ExtraEmailConfirmAPICall } from 'reactApp/api/ExtraEmailConfirmAPICall';
import { checkEmailAction } from 'reactApp/modules/socialUser/socialUser.actions';
import { confirmEmailFailure, confirmEmailRequest, confirmEmailSuccess } from 'reactApp/modules/socialUser/socialUser.module';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

const api = (token: string): any => new ExtraEmailConfirmAPICall().makeRequest({ token });

type ConfirmEmailActions =
    | ReturnType<typeof confirmEmailRequest>
    | ReturnType<typeof confirmEmailSuccess>
    | ReturnType<typeof confirmEmailFailure>;

export const confirmEmail =
    (token: string): ((dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => any) =>
    (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<ConfirmEmailActions> =>
        new Promise<ConfirmEmailActions>((resolve, reject): void => {
            dispatch(confirmEmailRequest());

            api(token)
                .then((): void => {
                    return dispatch(checkEmailAction());
                })
                .then((): void => {
                    resolve(dispatch(confirmEmailSuccess()));
                })
                .catch((): void => {
                    reject(dispatch(confirmEmailFailure()));
                });
        });
