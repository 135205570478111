define(function (require, exports, module) {
	'use strict';

	module.exports = function () {
		return {
			'file/history': {
				"success": {
					"status": 200,
					"body": [
						{
							"hash": "0000000000000000000000000000000000000000",
							"time": 1490302800,
							"name": "iiuqjhc0.test",
							"path": "/iz1q71c0/j52j853r/iiuqjhc0.test",
							"size": 0
						},
						{
							"hash": "0000000000000000000000000000000000000000",
							"time": 1490302800,
							"name": "iiuqjhc0.test",
							"path": "/iz1q71c0/j52j853r/iiuqjhc0.test",
							"size": 0
						}
					]
				},
				"noHistory": {
					"status": 200,
					"body": []
				},
				"fail": {
					"status": 400,
					"body": {
						"error": "bad request"
					}
				}
			}
		};
	};
});
