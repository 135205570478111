import { bytesToNDigits } from '@mail/cross-sizes-utils';
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { NameComponent } from 'reactApp/ui/Name/Name';
import { createGaSender } from 'reactApp/utils/ga';

import styles from './UploadBigFileDialog.css';
import { Props } from './UploadBigFileDialog.types';

const sendGa = createGaSender('upload-big-file');

const dataQAId = 'upload-big-file';

export const UploadBizBigFileDialog = React.memo(function UploadBizBigFileDialog({
    onClose,
    file,
    files,
    skipFileLoading,
}: Props): ReactElement {
    const { uploadLimit } = useSelector(UserSelectors.getUserData);
    const limit = bytesToNDigits(uploadLimit, 3);
    const isPhone = EnvironmentSelectors.isPhone();

    const header = useMemo(
        () => (
            <div className={styles.header}>
                {files ? (
                    'Невозможно загрузить файлы'
                ) : (
                    <>
                        Невозможно загрузить файл <NameComponent name={file?.name || ''} truncate />
                    </>
                )}
            </div>
        ),
        [file?.name, files]
    );

    useEffect(() => {
        sendGa('show', 'biz');
    }, []);

    const handleClose = useCallback(() => {
        sendGa('close', 'biz');
        onClose();
        skipFileLoading();
    }, [onClose, skipFileLoading]);

    const content = useMemo(
        () => (
            <div className={styles.content}>
                {files ? 'Файлы слишком тяжёлые' : 'Файл слишком тяжёлый'}. Чтобы загружать файлы больше {limit.value}, свяжитесь с
                администратором вашего Облака для изменения лимитов.
            </div>
        ),
        [files, limit.value]
    );

    if (isPhone) {
        return (
            <MobileDialog
                className={styles.root_phone}
                id={dataQAId}
                open
                dimmer
                onClose={handleClose}
                disableScrolling
                mod="confirm"
                title={header}
                topmost
            >
                {content}
            </MobileDialog>
        );
    }

    return (
        <Dialog open header={header} closeOnDimmerClick={false} id={dataQAId} className={styles.root} onCancel={handleClose}>
            <Content isModal>{content}</Content>
        </Dialog>
    );
});
