import addDays from 'date-fns/addDays';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';
import store from 'lib/store';
import { IS_AUTO_TEST_HIDE, IS_MOBILE_BROWSER } from 'reactApp/appHelpers/configHelpers';

import { user } from './User';

export class Promo {
    constructor(public key: string) {}

    isShown() {
        const value = store.get(`${user.getEmail()}|${this.key}`);
        const isDate = parseInt(value);

        return isDate ? isBefore(new Date(), parseISO(value)) : value;
    }

    lastShowTime() {
        return this.isShown() ? store.get(`${user.getEmail()}|${this.key}-time`) : null;
    }

    markAsShown({ daysCount }: { daysCount?: number } = {}) {
        const value = daysCount ? addDays(new Date(), daysCount) : true;

        store.set(`${user.getEmail()}|${this.key}`, value);
        store.set(`${user.getEmail()}|${this.key}-time`, Date.now());
    }

    clear() {
        store.remove(`${user.getEmail()}|${this.key}`);
        store.remove(`${user.getEmail()}|${this.key}-time`);
    }

    isEnabled() {
        return !(user && user.isNewbie()) && !IS_MOBILE_BROWSER && !IS_AUTO_TEST_HIDE;
    }

    isShownUntillTime({ time }: { time: Date }) {
        const value = store.get(`${user.getEmail()}|${this.key}`);

        return time && value ? time < value : undefined;
    }
}
