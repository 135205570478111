import { toNDigits } from '@mail/cross-sizes-utils';

import { UploadFail } from './UploadFail';

export class PublicFileSizeLimit extends UploadFail {
    constructor(stack: Error, source: string, limit) {
        super(stack, source);

        limit = toNDigits({ bytes: limit, maxDigitsCount: 3 }).value;
        this.message = this.message.replace('{LIMIT}', limit);
    }

    radarName = 'fail_{SOURCE}_public-file-size-limit';
    message = 'Размер загружаемых файлов не должен превышать {LIMIT}';
}
