import { IS_REACT_PROMO_QUOTA_TARIFFS_PAGE } from 'reactApp/appHelpers/configHelpers';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

export const sendAnalytics = (action: string, title: string) => {
    sendDwh({
        eventCategory: IS_REACT_PROMO_QUOTA_TARIFFS_PAGE ? ECategoryGa.quotaTariffs : ECategoryGa.payment,
        action,
        dwhData: { source: 'touch', name_action: title },
    });
};

export const formatTitle = (str: string) => {
    const splitTitle = str.split(/(гб|тб)/i);

    if (splitTitle.length === 1) {
        return { titleWithoutSpace: str };
    }

    const [space, units, titleWithoutSpace] = splitTitle;

    return { space: `${space}${units}`, titleWithoutSpace };
};
