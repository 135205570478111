import { ReactComponent as MyOfficeIcon } from 'img/myOffice.svg';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getMyOfficeProgressConfig } from 'reactApp/modules/features/features.selectors';

import styles from './ProgressBar.css';

interface IProps {
    error: boolean;
    docLoaded: boolean;
    closeFunc: () => void;
}

const radius = 22;
const circumference = radius * 2 * Math.PI;

const messages = {
    downloading: 'Загрузка документа',
    error: 'Не удалось загрузить документ',
    tryAgain: 'Повторить попытку',
};

export const ProgressBar = ({ error, docLoaded, closeFunc }: IProps) => {
    const config = useSelector(getMyOfficeProgressConfig);
    const { progressCoeff, startTime, closeTimeout, link } = config || {};

    const [strokeDasharray, setStrokeDasharray] = useState('');
    const [percentWidth, setPercentWidth] = useState(circumference);
    const [percent, setPercent] = useState(0);
    const [percentTimeout, setPercentTimeout] = useState(startTime); // ms

    const ref = useRef<SVGCircleElement>(null);

    useEffect(() => {
        setStrokeDasharray(`${circumference} ${circumference}`);
        setPercent(1);
    }, []);

    useEffect(() => {
        const progress = circumference - (percent / 100) * circumference;
        if (progress < percentWidth) {
            setPercentWidth(progress);
        }

        if (percent === 100) {
            setTimeout(() => {
                closeFunc();
            }, closeTimeout);
        }

        // т.к. прогресс бар фейковый - зависаем на 90% и ждем события загрузки
        if (percent >= 90) {
            return;
        }

        const timerId = window.setTimeout(() => {
            setPercent(percent + 1);
            setPercentTimeout(percentTimeout * progressCoeff);
        }, percentTimeout);

        return () => window.clearTimeout(timerId);
    }, [percent]);

    if (docLoaded && percent !== 100) {
        setPercent(100);
    }

    return (
        <>
            {!error && (
                <div className={styles.mainContainer}>
                    <div className={styles.container}>
                        <svg className={styles.progressBarCircleContainer}>
                            <circle className={styles.progressBarFakeCircle} r={radius} cx="26" cy="26" />
                            <circle
                                className={styles.progressBarCircle}
                                strokeDashoffset={percentWidth}
                                strokeDasharray={strokeDasharray}
                                r={radius}
                                ref={ref}
                                cx="26"
                                cy="26"
                            />
                        </svg>
                        <p className={styles.mainText}>
                            {messages.downloading}...{percent}%
                        </p>
                    </div>

                    <div className={styles.myofficeIconContainer}>
                        <a href={link} target="_blank" rel="noreferrer">
                            <MyOfficeIcon height={20} width={95} />
                        </a>
                    </div>
                </div>
            )}

            {error && (
                <div className={styles.container}>
                    <p className={styles.mainText}>{messages.error}</p>
                    <button className={styles.tryAgainButton}>{messages.tryAgain}</button>
                </div>
            )}
        </>
    );
};
