import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { isMultiVariantFeature, isMultiVariantFeatureResult, isToggleFeature } from 'reactApp/modules/features/features.helpers';
import { FeatureSelectorProps, FeatureVariants, TemplateVariantString } from 'reactApp/modules/features/features.types';

/**
 * @deprecated Ушли от redux в фичах. Теперь стоит использовать хелпер отсюда - reactApp/appHelpers/featuresHelpers/utils.ts
 */
export const FeatureSelector = <T extends boolean | TemplateVariantString>(props: FeatureSelectorProps<T, ReactElement>): ReactElement => {
    const { selector, control, options, ...variants } = props;
    const result = useSelector(selector);

    const { skipCondition } = options || {};

    /*
     * TS не справляется с деструктуризацией(...variants) и не хочет поверить, что
     * Omit<FeatureSelectorProps<T>, "selector" | "control"> это тоже самое, что FeatureVariants<T>
     * А они одинаковые, т.к.
     *
     * FeatureSelectorProps<T> = FeatureVariants<T> & {
     *      selector: (state: RootState) => T;
     *      control: React.ReactElement;
     * };
     */
    const _variants = variants as unknown as FeatureVariants<T, ReactElement>;

    if (isToggleFeature(_variants, result)) {
        return result && _variants.variant1 && /* Скипаем, если нужно */ !(skipCondition?.variant1?.() || skipCondition?.forAll?.())
            ? _variants.variant1
            : control;
    }

    if (isMultiVariantFeature(_variants, result) && isMultiVariantFeatureResult(result)) {
        const selected = _variants[result];

        if (skipCondition?.[result]?.() || skipCondition?.forAll?.()) {
            return control;
        }

        return selected ?? control;
    }

    return control;
};
