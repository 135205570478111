import classNames from 'clsx';
import React, { memo } from 'react';
import { formatAutoDeleteExpires, formatExpiresDropdown } from 'reactApp/utils/formatDate';

import styles from './Expires.css';

interface ExpiresProps {
    expires?: number;
    isAutoDelete?: boolean;
    isShared?: boolean;
    isInline?: boolean;
}

export const Expires = memo<ExpiresProps>(function Expires({ expires, isAutoDelete, isShared, isInline = false }) {
    return (
        <div
            data-qa-id="expires"
            className={classNames(styles.root, {
                [styles.autodelete]: isAutoDelete,
                [styles.inline]: isInline,
            })}
        >
            {!!expires &&
                (isAutoDelete
                    ? `Удалится ${formatAutoDeleteExpires(expires, true)}`
                    : isShared && `Доступ открыт до ${formatExpiresDropdown(expires, true)}`)}
        </div>
    );
});
