import { Card, CardGrid, Group, Headline, Title } from '@vkontakte/vkui';
import React from 'react';
import { WebFilterCell } from 'reactApp/ui/WebSearch/WebFilterCell';
import { WebResetFiltersButton } from 'reactApp/ui/WebSearch/WebResetFiltersButton';
import {
    ESearchOptionSource,
    ESearchOptionType,
    fileTypeOptionsList,
    IFilters,
    sourceTypeOptionsList,
} from 'reactApp/ui/WebSearch/WebSearch.data';
import { Popup } from 'reactApp/ui/WebSearchToolbar/Popup';

import styles from './Filters.css';

interface IProps {
    targetRef: React.RefObject<HTMLElement>;
    type: ESearchOptionType;
    source: ESearchOptionSource;
    setFilters: (filters: IFilters) => void;
    onClickAway: () => void;
}

export const Filters: React.FC<IProps> = ({ targetRef, type, source, setFilters, onClickAway }) => {
    const defaultType = type === ESearchOptionType.all;
    const defaultSource = source === ESearchOptionSource.all;
    const canReset = !defaultType || !defaultSource;
    const typeIsDocument = type === ESearchOptionType.document;

    const optionsList = fileTypeOptionsList.map((item) => ({
        ...item,
        onClick: () => {
            setFilters({ type: item.id });
        },
    }));

    const sourcesList = sourceTypeOptionsList.map((item) => ({
        ...item,
        onClick: () => {
            setFilters({ source: item.id });
        },
    }));

    const handleReset = () => {
        setFilters({ type: ESearchOptionType.all, source: ESearchOptionSource.all });
    };

    return (
        <Popup targetRef={targetRef} onClickAway={onClickAway} placement="bottom-end">
            <div className={styles.container}>
                <Group
                    className={styles.group}
                    header={
                        <WebFilterCell
                            className={styles.header}
                            hasHover={false}
                            hasActive={false}
                            after={canReset && <WebResetFiltersButton onClick={handleReset}>Сбросить</WebResetFiltersButton>}
                        >
                            <Title weight="2" level="3">
                                Фильтр поиска
                            </Title>
                        </WebFilterCell>
                    }
                >
                    <CardGrid size="m" className={styles.grid}>
                        <Card className={styles.card}>
                            <WebFilterCell hasHover={false} hasActive={false}>
                                <Headline weight="2">Что искать</Headline>
                            </WebFilterCell>
                            {optionsList.map(({ id, icon, text, onClick }) => (
                                <WebFilterCell key={id} before={icon} onClick={onClick} checked={type === id} data-qa-id={id}>
                                    {text}
                                </WebFilterCell>
                            ))}
                        </Card>
                        <Card className={styles.card}>
                            <WebFilterCell hasHover={false} hasActive={false}>
                                <Headline weight="2">Где Искать</Headline>
                            </WebFilterCell>
                            {sourcesList.map(({ id, icon, text, onClick }) => (
                                <WebFilterCell
                                    key={id}
                                    before={icon}
                                    onClick={onClick}
                                    checked={source === id}
                                    disabled={
                                        !defaultType &&
                                        !typeIsDocument &&
                                        (id === ESearchOptionSource.all || id === ESearchOptionSource.content)
                                    }
                                    data-qa-id={id}
                                >
                                    {text}
                                </WebFilterCell>
                            ))}
                        </Card>
                    </CardGrid>
                </Group>
            </div>
        </Popup>
    );
};

Filters.displayName = 'Filters';
