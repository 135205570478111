import React, { memo, RefObject, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { withValidationMessageComponent } from 'reactApp/ui/WithValidationMessage';
import { Form } from 'semantic-ui-react';

const InputWithValidationMessage = withValidationMessageComponent(Form.Input);

interface IProps {
    value: string;
    error: boolean;
    validationErrorMessage?: string;
    onChange: (event: any) => void;
    onKeyDown?: (event: any) => void;
    maxLength?: number;
    fluid?: boolean;
    focus?: boolean;
    disabled?: boolean;
    autoFocus?: boolean;
    className?: string;
    selectionRange?: number;
    placeholder?: string;
    getReference: (ref: RefObject<HTMLInputElement>) => void;
}

export const Input = memo(function Input(props: IProps) {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const { selectionRange, ...rest } = props;
    const isPhone = useSelector(EnvironmentSelectors.isPhone);

    const getReference = (ref) => {
        inputRef.current = ref;
        props.getReference(ref);
    };

    useEffect(() => {
        if (!inputRef.current || !props.value || !selectionRange) {
            return;
        }

        if (!isPhone) {
            inputRef.current.select();
            inputRef.current.setSelectionRange(0, selectionRange);
        }
    }, []);

    return <InputWithValidationMessage {...rest} getReference={getReference} />;
});
