import React, { FC } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { Download } from 'reactApp/ui/DataListItemCommonComponents/Download/Download';
import { DownloadVK } from 'reactApp/ui/DataListItemCommonComponents/Download/DownloadVK';
import { ItemName } from 'reactApp/ui/DataListItemRow/components/ItemName/ItemName';
import { PicVk } from 'reactApp/ui/DataListItemRow/components/Pic/PicVK';
import { Size } from 'reactApp/ui/DataListItemRow/components/Size/Size';
import { TableColumn } from 'reactApp/ui/DataListItemRow/components/TableColumn/TableColumn';
import { ArchiveItemProps } from 'reactApp/ui/DataListItemRow/DataListItemRow.types';
import { FilePic } from 'reactApp/ui/FilePic/FilePic';
import { useCompactView } from 'reactApp/ui/VirtualList/VirtualList.hooks';

import styles from '../../DataListItemRow.new.css';

const ARCHIVE_BP = 1200;

export const ArchiveItem: FC<ArchiveItemProps> = (props) => {
    const { id, storage, isFolder, kind, isPublic, name, nameWithoutExt, size, ext, isEncrypted, onDownload, isVKEmbedded } = props;

    const isCompact = useCompactView(ARCHIVE_BP);

    return (
        <>
            <TableColumn>
                {isVKEmbedded ? (
                    <PicVk isFolder={isFolder} kind={kind} isVirus={false} ext={ext || ''} isLarge={isVKEmbedded || !isFolder} />
                ) : (
                    <FilePic isFolder={isFolder} kind={kind} isVirus={false} ext={ext || ''} isPublic={isPublic} />
                )}
            </TableColumn>

            {isCompact ? (
                <TableColumn width="100%" overflow="hidden">
                    <div className={styles.nameWrapper}>
                        <ItemName name={nameWithoutExt || name} ext={ext || ''} id={id} />
                        <span className={styles.responsiveDateWrapper}>
                            <Size size={size} isInline />
                        </span>
                    </div>
                </TableColumn>
            ) : (
                <>
                    <TableColumn width="100%" overflow="hidden">
                        <ItemName name={nameWithoutExt || name} ext={ext || ''} id={id} />
                    </TableColumn>
                    <TableColumn flexBasis={60} flexShrink={0} justifyContent="center">
                        <Size size={size} />
                    </TableColumn>
                </>
            )}

            <TableColumn flexBasis={50} flexShrink={0} justifyContent="flex-end" gap={20}>
                {!isFolder && !isEncrypted && !isVKEmbedded && (
                    <Download onClick={onDownload} ext={ext} size={size} id={id} storage={storage} viewType={EViewMode.list} />
                )}
                {!isFolder && !isEncrypted && isVKEmbedded && (
                    <DownloadVK
                        onClick={onDownload}
                        ext={ext}
                        size={size}
                        id={id}
                        storage={storage}
                        isHidden={isFolder || isEncrypted}
                        isLarge
                    />
                )}
            </TableColumn>
        </>
    );
};
