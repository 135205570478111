import { ReactComponent as BirthCertificate } from 'img/icons/documents/birth_certificate.svg';
import { ReactComponent as BirthCertificateFilled } from 'img/icons/documents/birth_certificate_filled.svg';
import { ReactComponent as CardIcon } from 'img/icons/documents/card.svg';
import { ReactComponent as CardIconFilled } from 'img/icons/documents/card_filled.svg';
import { ReactComponent as CertificateIcon } from 'img/icons/documents/certificate.svg';
import { ReactComponent as CertificateIconFilled } from 'img/icons/documents/certificate_filled.svg';
import { ReactComponent as DriversLicense } from 'img/icons/documents/drivers_license.svg';
import { ReactComponent as DriversLicenseFilled } from 'img/icons/documents/drivers_license_filled.svg';
import { ReactComponent as InnIcon } from 'img/icons/documents/inn.svg';
import { ReactComponent as InnIconFilled } from 'img/icons/documents/inn_filled.svg';
import { ReactComponent as InternationalPassport } from 'img/icons/documents/international_passport.svg';
import { ReactComponent as InternationalPassportFilled } from 'img/icons/documents/international_passport_filled.svg';
import { ReactComponent as InvoiceIcon } from 'img/icons/documents/invoice.svg';
import { ReactComponent as InvoiceIconFilled } from 'img/icons/documents/invoice_filled.svg';
import { ReactComponent as MedicalPolicy } from 'img/icons/documents/medical_policy.svg';
import { ReactComponent as MedicalPolicyFilled } from 'img/icons/documents/medical_policy_filled.svg';
import { ReactComponent as PassportIcon } from 'img/icons/documents/passport.svg';
import { ReactComponent as PassportIconFilled } from 'img/icons/documents/passport_filled.svg';
import { ReactComponent as PtsIcon } from 'img/icons/documents/pts.svg';
import { ReactComponent as PtsIconFilled } from 'img/icons/documents/pts_filled.svg';
import { ReactComponent as SnilsIcon } from 'img/icons/documents/snils.svg';
import { ReactComponent as SnilsIconFilled } from 'img/icons/documents/snils_filled.svg';
import { ReactComponent as VehicleRegistration } from 'img/icons/documents/vehicle_registration.svg';
import { ReactComponent as VehicleRegistrationFilled } from 'img/icons/documents/vehicle_registration_filled.svg';
import React from 'react';

export enum EDocumentType {
    BANK_CARD = 'bank_card',
    BIRTH_CERTIFICATE = 'birth_certificate',
    CERTIFICATE = 'certificate',
    DRIVING_LICENSE = 'driving_license',
    HEALTH_INSURANCE_POLICY = 'health_insurance_policy',
    INDIVIDUAL_INSURANCE_ACCOUNT_NUMBER = 'individual_insurance_account_number',
    INTERNATIONAL_PASSPORT = 'international_passport',
    INVOICE = 'invoice',
    PASSPORT = 'passport',
    TAXPAYER_IDENTIFICATION_NUMBER = 'taxpayer_identification_number',
    VEHICLE_REGISTRATION_DOCUMENT = 'vehicle_registration_document',
    VEHICLE_TITLE = 'vehicle_title',
}

export const DOCUMENT_ID = {
    [EDocumentType.PASSPORT]: 6,
    [EDocumentType.INTERNATIONAL_PASSPORT]: 3,
    [EDocumentType.TAXPAYER_IDENTIFICATION_NUMBER]: 4,
    [EDocumentType.INDIVIDUAL_INSURANCE_ACCOUNT_NUMBER]: 10,
    [EDocumentType.HEALTH_INSURANCE_POLICY]: 12,
    [EDocumentType.DRIVING_LICENSE]: 2,
    [EDocumentType.CERTIFICATE]: 9,
    [EDocumentType.BIRTH_CERTIFICATE]: 13,
    [EDocumentType.BANK_CARD]: 11,
    [EDocumentType.VEHICLE_TITLE]: 7,
    [EDocumentType.VEHICLE_REGISTRATION_DOCUMENT]: 8,
    [EDocumentType.INVOICE]: 14,
};

export const MAP_DOCUMENT_ID = Object.entries(DOCUMENT_ID).reduce((res, [key, value]) => {
    res[value] = key;
    return res;
}, {});

export const ITEMS_META = {
    [DOCUMENT_ID[EDocumentType.PASSPORT]]: {
        Icon: <PassportIcon />,
        FilledIcon: <PassportIconFilled />,
        uploadTitle: 'Загрузить паспорт',
        name: 'Паспорт',
        title: EDocumentType.PASSPORT,
        gaId: 'passport',
    },
    [DOCUMENT_ID[EDocumentType.INTERNATIONAL_PASSPORT]]: {
        Icon: <InternationalPassport />,
        FilledIcon: <InternationalPassportFilled />,
        uploadTitle: 'Загрузить загранпаспорт',
        name: 'Загранпаспорт',
        title: EDocumentType.INTERNATIONAL_PASSPORT,
        gaId: 'int_passport',
    },
    [DOCUMENT_ID[EDocumentType.TAXPAYER_IDENTIFICATION_NUMBER]]: {
        Icon: <InnIcon />,
        FilledIcon: <InnIconFilled />,
        uploadTitle: 'Загрузить ИНН',
        name: 'ИНН',
        title: EDocumentType.TAXPAYER_IDENTIFICATION_NUMBER,
        gaId: 'tax_number',
    },
    [DOCUMENT_ID[EDocumentType.INDIVIDUAL_INSURANCE_ACCOUNT_NUMBER]]: {
        Icon: <SnilsIcon />,
        FilledIcon: <SnilsIconFilled />,
        uploadTitle: 'Загрузить СНИЛС',
        name: 'СНИЛС',
        title: EDocumentType.INDIVIDUAL_INSURANCE_ACCOUNT_NUMBER,
        gaId: 'insurance_number',
    },
    [DOCUMENT_ID[EDocumentType.HEALTH_INSURANCE_POLICY]]: {
        Icon: <MedicalPolicy />,
        FilledIcon: <MedicalPolicyFilled />,
        uploadTitle: 'Загрузить медицинский полис',
        name: 'Медицинский полис',
        title: EDocumentType.HEALTH_INSURANCE_POLICY,
        gaId: 'health_policy',
    },
    [DOCUMENT_ID[EDocumentType.DRIVING_LICENSE]]: {
        Icon: <DriversLicense />,
        FilledIcon: <DriversLicenseFilled />,
        uploadTitle: 'Загрузить водительские права',
        name: 'Водительские права',
        title: EDocumentType.DRIVING_LICENSE,
        gaId: 'driving_l',
    },
    [DOCUMENT_ID[EDocumentType.CERTIFICATE]]: {
        Icon: <CertificateIcon />,
        FilledIcon: <CertificateIconFilled />,
        uploadTitle: 'Загрузить свидетельство',
        name: 'Свидетельство',
        title: EDocumentType.CERTIFICATE,
        gaId: 'cert',
    },
    [DOCUMENT_ID[EDocumentType.BIRTH_CERTIFICATE]]: {
        Icon: <BirthCertificate />,
        FilledIcon: <BirthCertificateFilled />,
        uploadTitle: 'Загрузить свидетельство о рождении',
        name: 'Свидетельство о рождении',
        title: EDocumentType.BIRTH_CERTIFICATE,
        gaId: 'birth_cert',
    },
    [DOCUMENT_ID[EDocumentType.BANK_CARD]]: {
        Icon: <CardIcon />,
        FilledIcon: <CardIconFilled />,
        uploadTitle: 'Загрузить банковскую карту',
        name: 'Банковская карта',
        title: EDocumentType.BANK_CARD,
        gaId: 'bank_card',
    },
    [DOCUMENT_ID[EDocumentType.VEHICLE_TITLE]]: {
        Icon: <PtsIcon />,
        FilledIcon: <PtsIconFilled />,
        uploadTitle: 'Загрузить ПТС',
        name: 'ПТС',
        title: EDocumentType.VEHICLE_TITLE,
        gaId: 'ptc',
    },
    [DOCUMENT_ID[EDocumentType.VEHICLE_REGISTRATION_DOCUMENT]]: {
        Icon: <VehicleRegistration />,
        FilledIcon: <VehicleRegistrationFilled />,
        uploadTitle: 'Загрузить регистрацию ТС',
        name: 'Регистрация ТС',
        title: EDocumentType.VEHICLE_REGISTRATION_DOCUMENT,
        gaId: 'ts',
    },
    [DOCUMENT_ID[EDocumentType.INVOICE]]: {
        Icon: <InvoiceIcon />,
        FilledIcon: <InvoiceIconFilled />,
        uploadTitle: 'Загрузить чек',
        name: 'Чек',
        title: EDocumentType.INVOICE,
        gaId: 'invoice',
    },
};
