import { AstraMetaDataType } from './astraMeta.types';

export const getAstraMetaProcessing = (options: AstraMetaDataType): string => {
    const { editorID, processorType, isWOPI = false, processorIDsMap, fileExt } = options;

    if (!editorID) {
        return `${processorType}_${processorIDsMap.default}_${fileExt}`;
    }

    return `${processorType}_${processorIDsMap[isWOPI ? `${editorID}wopi` : editorID] ?? processorIDsMap.default}_${fileExt}`;
};
