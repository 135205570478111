import React, { ImgHTMLAttributes } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import { MobileDialog as BaseMobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { noop } from 'reactApp/utils/helpers';

import styles from './MobileDialog.css';

interface IMobileDialogProps {
    title: string;
    buttonText: string;
    onClickButton: () => void;
    onClose?: () => void;
    onShow?: () => void;
    imgProps: ImgHTMLAttributes<HTMLImageElement>;
    closable: boolean;
}

export const MobileDialog: React.FC<IMobileDialogProps> = ({
    title,
    children,
    onClickButton,
    onClose = noop,
    onShow = noop,
    buttonText,
    imgProps,
    closable,
}) => (
    <BaseMobileDialog open onClose={onClose} onShow={onShow} closable={closable} mod="mobileV1" topmost>
        <div className={styles.root}>
            <div className={styles.cover}>
                <img {...imgProps} alt={imgProps.alt} />
            </div>
            <div className={styles.title}>{title}</div>
            <div className={styles.content}>{children}</div>
            <div className={styles.button}>
                <Button onClick={onClickButton} primary fluid size="big" theme="octavius">
                    {buttonText}
                </Button>
            </div>
        </div>
    </BaseMobileDialog>
);
