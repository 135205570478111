import { getExtension } from 'reactApp/modules/file/utils';
import { CloudItem } from 'reactApp/modules/storage/storage.types';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

// https://docs.google.com/spreadsheets/d/1oxESW-0Ne7WtP7gKejV6CJYSv-Q9ssFDC8MY1_chDT0/edit#gid=582124059

export enum EAlbumAction {
    CREATED = 'created',
    UPLOAD = 'upload',
    DELETE_MEDIA = 'detele-media',
    RENAME = 'rename',
    DELETE = 'delete',
}

/** Место, откуда создан альбом */
export enum ETypeCreated {
    FROM_PUBLISH = 'from-publish',
    FROM_CREATE_BUTTON = 'from-create-button',
    FROM_EMPTY_POPUP = 'from-empty-popup',
}

/** Способ добавления элементов в альбом */
export enum EPlaceUpload {
    FROM_CLOUD = 'from-cloud',
    FROM_PUBLISH = 'from-publish',
    UPLOAD = 'upload',
}

interface ICreateAlbum {
    action: EAlbumAction.CREATED;
    type_created: ETypeCreated;
}

interface IUploadAlbum {
    action: EAlbumAction.UPLOAD;
    place_upload: EPlaceUpload;
    type_media: string;
}

interface IDeleteMedia {
    action: EAlbumAction.DELETE_MEDIA;
    type_media: string;
}

type IAlbumAnalytics = ICreateAlbum | IUploadAlbum | IDeleteMedia | { action: EAlbumAction.RENAME | EAlbumAction.DELETE };

type IItemsAnalytics =
    | { items: CloudItem[]; action: EAlbumAction.DELETE_MEDIA }
    | { items: CloudItem[]; action: EAlbumAction.UPLOAD; place_upload: EPlaceUpload };

export const sendAlbumAnalytics = ({ action, ...rest }: IAlbumAnalytics): void => {
    sendDwh({
        eventCategory: ECategoryGa.album,
        action,
        dwhData: rest,
    });
};

export const sendAlbumItemsAnalytics = ({ items, ...rest }: IItemsAnalytics): void => {
    items.forEach((item) => {
        const typeMedia = 'ext' in item && item.ext || getExtension(item.name) || '';

        sendAlbumAnalytics({ type_media: typeMedia, ...rest });
    });
};
