import { APICallV3Get } from 'reactApp/api/APICall';

export type TExpiredSubscription = {
    period?: string;
    services: {
        quota: number;
    };
    expire_at: number;
    source: string;
};

export class GetExpiredIosSubscriptionAPICall extends APICallV3Get<TExpiredSubscription> {
    _method = 'billing/subscription/expired/ios';
}

export class GetExpiredAndroidSubscriptionAPICall extends APICallV3Get<TExpiredSubscription> {
    _method = 'billing/subscription/expired/android';
}
