import { Icon20Check } from '@vkontakte/icons';
import { SimpleCell, SimpleCellProps } from '@vkontakte/vkui';
import React from 'react';

const accentIconColor = { '--vkui--color_icon_accent': '--vkui--color_icon_primary' };

export interface IProps extends SimpleCellProps {
    checked?: boolean;
}

export const WebFilterCell: React.FC<IProps> = ({ style, checked, disabled, ...rest }) => (
    <SimpleCell
        disabled={disabled}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        style={{ ...accentIconColor, ...style, opacity: disabled ? 'var(--vkui--opacity_disable_accessibility)' : 1 }}
        after={checked && <Icon20Check style={{ color: '#2688eb' }} />}
        {...rest}
    />
);

WebFilterCell.displayName = 'WebFilterCell';
