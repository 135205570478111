import classNames from 'clsx';
import { ReactComponent as ShieldIcon } from 'img/icons/shield.svg';
import React, { memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { Hint } from 'reactApp/ui/Hint/Hint';

import { sendProtectedAnalytics } from './ProtectedHint.analytics';
import styles from './ProtectedHint.css';

interface IProps {
    text: string;
    hover?: boolean;
}

export const ProtectedHint = memo<IProps>(({ text, hover = false }) => {
    const storage = useSelector(getCurrentStorage);

    const handleOnShow = useCallback(() => {
        sendProtectedAnalytics({
            action: 'view',
            place: storage,
            platform: 'desktop',
        });
    }, [storage]);

    const handleOnClick = useCallback(() => {
        sendProtectedAnalytics({
            action: 'click',
            place: storage,
            platform: 'desktop',
        });

        open('/promo/security', '_blank');
    }, [storage]);

    useEffect(() => {
        sendProtectedAnalytics({
            action: 'show',
            place: storage,
            platform: 'desktop',
        });
    }, []);

    return (
        <Hint text={text} onShow={handleOnShow}>
            <div
                className={classNames(styles.root, {
                    [styles.rootHovered]: hover,
                })}
                onClick={handleOnClick}
            >
                <ShieldIcon />
            </div>
        </Hint>
    );
});

ProtectedHint.displayName = 'ProtectedHint';
