import classNames from 'clsx';
import debounce from 'lodash.debounce';
import { contains, findIndex, pathOr, values } from 'ramda';
import React, { createRef, PureComponent, ReactNode } from 'react';
import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { WINDOW_PADDING } from 'reactApp/constants/padding';
import { noop } from 'reactApp/utils/helpers';
import { isIosDevice } from 'reactApp/utils/scrollLock';

import { tooltipPlacements } from './Tooltip.constants';
import styles from './Tooltip.css';

const ARROW_SIZE = {
    width: 16,
    height: 8,
};
const ARROW_PADDING = IS_PHONE_BROWSER ? 25 : 40;
const TOOLBAR_STICKY_SIZE = 52;
const PADDING = 8;
const IS_IOS = isIosDevice();

export interface IProps {
    children: ReactNode | null;
    placement: string;
    targetRect?: any;
    targetGetClientRect?();
    onClose: (e?: MouseEvent) => void;
    fluid?: boolean;
    noArrow?: boolean;
    pinned?: boolean;
    qaId?: string;
    header?: string;
    basic?: boolean;
    target: any;
    smartPlacement: boolean;
    fixed?: boolean;
    theme?: '' | 'octavius' | 'withinDialog' | 'inlineIntegration';
    forceAutoPosition?: boolean;
    enableTransparentDimmer?: boolean;
    withinDialog?: boolean;
    offset?: { x?: number; y?: number };
    fitWindow?: boolean;
    fitHorizontalWindow?: boolean;
    arrowColor?: string;
    radius?: '' | 16 | 20;
    closeOnOutsideClick?: boolean;
    animated?: boolean;
    minWidth?: boolean;
    noPageOffset?: boolean;
    overAll?: boolean;
}

export class Tooltip extends PureComponent<IProps> {
    static defaultProps = {
        placement: tooltipPlacements.BOTTOM,
        fallbackPlacement: tooltipPlacements.BOTTOM_RIGHT,
        onClose: noop,
        pinned: false,
        smartPlacement: true,
        fixed: false,
        theme: '',
        enableTransparentDimmer: false,
        withinDialog: false,
        fitWindow: false,
        fitHorizontalWindow: false,
        target: null,
        closeOnOutsideClick: true,
        animated: false,
        minWidth: true,
        noPageOffset: false,
    };

    el = React.createRef<HTMLDivElement>();
    dimmerRef = createRef<HTMLDivElement>();
    footer: null | Element = null;
    placements: string[][] = [];
    elWidth = 0;
    bestPlacement = '';

    repositionCount = 0;
    maxRepositionCount = this.props.noArrow && !this.props.forceAutoPosition ? 4 : 12;
    maxGoodPlacementCount = 0;

    state = {
        currentPlacement: this.props.placement,
        isFinalPlacement: false,
    };

    componentDidMount() {
        this.setPlacements();
        this.setPosition();
        this.footer = document.querySelector('#w-portal-footer');
        const { enableTransparentDimmer, closeOnOutsideClick } = this.props;

        if (closeOnOutsideClick) {
            setTimeout(() => document.addEventListener('click', this.onDocumentClick), 0);
        }

        if (enableTransparentDimmer) {
            setTimeout(() => {
                if (this.dimmerRef && this.dimmerRef.current) {
                    this.dimmerRef.current.addEventListener('click', this.onDimmerClick, true);
                }
            }, 0);
        }

        window.addEventListener?.('resize', this.onWindowReposition);
        window.addEventListener?.('scroll', this.onWindowReposition);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.currentPlacement !== this.state.currentPlacement || prevProps.offset !== this.props.offset) {
            this.setPosition();
        }
    }

    componentWillUnmount() {
        const { enableTransparentDimmer, closeOnOutsideClick } = this.props;

        window.removeEventListener?.('resize', this.onWindowReposition);
        window.removeEventListener?.('scroll', this.onWindowReposition);

        if (enableTransparentDimmer && this.dimmerRef && this.dimmerRef.current) {
            this.dimmerRef.current.removeEventListener('click', this.onDimmerClick);
        }

        if (closeOnOutsideClick) {
            document.removeEventListener('click', this.onDocumentClick);
        }
    }

    setPlacements() {
        this.placements = [
            [tooltipPlacements.TOP_LEFT, tooltipPlacements.TOP, tooltipPlacements.TOP_RIGHT],
            [tooltipPlacements.RIGHT_TOP, tooltipPlacements.RIGHT, tooltipPlacements.RIGHT_BOTTOM],
            [tooltipPlacements.BOTTOM_RIGHT, tooltipPlacements.BOTTOM, tooltipPlacements.BOTTOM_LEFT],
            [tooltipPlacements.LEFT_BOTTOM, tooltipPlacements.LEFT, tooltipPlacements.LEFT_TOP],
        ];
    }

    onDocumentClick = (event: MouseEvent) => {
        const { onClose } = this.props;
        if (this.el && this.el.current && !this.el.current.contains(event.target as Node)) {
            onClose(event);
        }
    };

    checkPosition() {
        const topOffset = window.pageYOffset > WINDOW_PADDING * 3 ? TOOLBAR_STICKY_SIZE : WINDOW_PADDING * 3;
        const elRect = this.el.current?.getBoundingClientRect();
        const topPos = elRect?.top ?? 0;
        const leftPos = elRect?.left ?? 0;
        const isFooterVisible = this.footer && this.footer.getBoundingClientRect().top < window.innerHeight;
        const isTopOk = topPos - (IS_PHONE_BROWSER ? 0 : topOffset) > 0;
        const isBottomOk =
            topPos + (elRect?.height ?? 0) < window.innerHeight - (isFooterVisible ? this.footer?.getBoundingClientRect().height ?? 0 : 0);
        const isLeftOk = leftPos > 0;
        const isRightOk = leftPos + this.elWidth < Math.min(window.innerWidth, document.body.clientWidth);

        return {
            isTopOk,
            isBottomOk,
            isLeftOk,
            isRightOk,
        };
    }

    setNewPlacement({ isLeftOk, isTopOk, isRightOk, isBottomOk }) {
        let newPlacemnt;

        switch (this.state.currentPlacement) {
            case tooltipPlacements.BOTTOM_LEFT:
                if (isBottomOk) {
                    newPlacemnt = tooltipPlacements.BOTTOM_RIGHT;
                } else {
                    newPlacemnt = isRightOk ? tooltipPlacements.TOP_LEFT : tooltipPlacements.TOP_RIGHT;
                }
                break;
            case tooltipPlacements.BOTTOM_RIGHT:
                if (isBottomOk) {
                    newPlacemnt = tooltipPlacements.BOTTOM_LEFT;
                } else {
                    newPlacemnt = isLeftOk ? tooltipPlacements.TOP_RIGHT : tooltipPlacements.TOP_LEFT;
                }
                break;
            case tooltipPlacements.TOP_LEFT:
                if (isTopOk) {
                    newPlacemnt = tooltipPlacements.TOP_RIGHT;
                } else {
                    newPlacemnt = isRightOk ? tooltipPlacements.BOTTOM_LEFT : tooltipPlacements.BOTTOM_RIGHT;
                }
                break;
            case tooltipPlacements.TOP_RIGHT:
                if (isTopOk) {
                    newPlacemnt = tooltipPlacements.TOP_LEFT;
                } else {
                    newPlacemnt = isLeftOk ? tooltipPlacements.BOTTOM_RIGHT : tooltipPlacements.BOTTOM_LEFT;
                }
        }

        this.setState({
            currentPlacement: newPlacemnt,
        });
    }

    setNewPlacementArrow() {
        this.placements = this.placements.filter((_) => _ && _.length);
        // @ts-ignore
        const groupId = findIndex(contains(this.state.currentPlacement), this.placements);
        this.placements = this.placements.reduce<string[][]>((acc, cur) => {
            return [...acc, cur.filter((item) => item !== this.state.currentPlacement)];
        }, []);
        const group = this.placements[groupId];

        if (group && group.length) {
            this.setState({
                currentPlacement: group[0],
            });
        } else {
            this.setState({
                currentPlacement: this.placements[groupId + 1] ? this.placements[groupId + 1][0] : this.placements[groupId - 1][0],
            });
        }
    }

    // eslint-disable-next-line max-lines-per-function
    setPosition = () => {
        const {
            target,
            targetGetClientRect,
            smartPlacement,
            forceAutoPosition,
            noArrow,
            offset,
            fitWindow,
            fitHorizontalWindow,
            noPageOffset,
        } = this.props;
        const { currentPlacement: placement } = this.state;
        const offsetX = pathOr(0, ['x'], offset);
        const offsetY = pathOr(0, ['y'], offset);
        const pageY = (noPageOffset ? 0 : window.pageYOffset) + offsetY;
        const pageX = (noPageOffset ? 0 : window.pageXOffset) + offsetX;
        const el = this.el.current;

        if (!el) {
            return;
        }

        const elRect = el.getBoundingClientRect();
        const targetRect =
            this.props.targetRect ||
            (targetGetClientRect && targetGetClientRect()) ||
            (target && target.current && target.current.getBoundingClientRect()) ||
            (target && target.getBoundingClientRect()) ||
            {};
        let top, left;
        const arrowHeight = noArrow ? 0 : ARROW_SIZE.height;
        const arrowWidth = noArrow ? 0 : ARROW_SIZE.width;
        const shiftY = noArrow && !forceAutoPosition ? 0 : targetRect.height / 2;
        const shiftX = noArrow && !forceAutoPosition ? 0 : targetRect.width / 2;
        const padding = noArrow ? 0 : ARROW_PADDING;

        if (!this.elWidth) {
            this.elWidth = elRect.width;
        }

        // eslint-disable-next-line sonarjs/max-switch-cases
        switch (placement) {
            case tooltipPlacements.TOP_LEFT:
                left = pageX + targetRect.left + shiftX - padding - arrowWidth / 2;
                top = pageY + targetRect.top - elRect.height - arrowHeight;
                break;
            case tooltipPlacements.TOP_RIGHT:
                left = pageX + targetRect.left + targetRect.width - this.elWidth - shiftX + padding + arrowWidth / 2;
                top = pageY + targetRect.top - elRect.height - arrowHeight;
                break;
            case tooltipPlacements.TOP:
                left = pageX + targetRect.left + targetRect.width / 2 - this.elWidth / 2;
                top = pageY + targetRect.top - elRect.height - arrowHeight;
                break;
            case tooltipPlacements.LEFT:
                left = pageX + targetRect.left - this.elWidth - arrowHeight;
                top = pageY + targetRect.top + targetRect.height / 2 - elRect.height / 2;
                break;
            case tooltipPlacements.LEFT_TOP:
                left = pageX + targetRect.left - this.elWidth - arrowHeight;
                top = pageY + targetRect.top + shiftY - padding - arrowWidth / 2;
                break;
            case tooltipPlacements.LEFT_BOTTOM:
                left = pageX + targetRect.left - this.elWidth - arrowHeight;
                top = pageY + targetRect.top + targetRect.height - elRect.height - shiftY + padding + arrowWidth / 2;
                break;
            case tooltipPlacements.BOTTOM:
                left = pageX + targetRect.left + targetRect.width / 2 - this.elWidth / 2;
                top = pageY + targetRect.top + targetRect.height + arrowHeight;
                break;
            case tooltipPlacements.BOTTOM_LEFT:
                left = pageX + targetRect.left + shiftX - padding - arrowWidth / 2;
                top = pageY + targetRect.top + targetRect.height + arrowHeight;
                break;
            case tooltipPlacements.BOTTOM_RIGHT:
                left = pageX + targetRect.left + targetRect.width - this.elWidth - shiftX + padding + arrowWidth / 2;
                top = pageY + targetRect.top + targetRect.height + arrowHeight;
                break;
            case tooltipPlacements.RIGHT:
                left = pageX + targetRect.left + targetRect.width + arrowHeight;
                top = pageY + targetRect.top + targetRect.height / 2 - elRect.height / 2;
                break;
            case tooltipPlacements.RIGHT_TOP:
                left = pageX + targetRect.left + targetRect.width + arrowHeight;
                top = pageY + targetRect.top + shiftY - padding - arrowWidth / 2;
                break;
            case tooltipPlacements.RIGHT_BOTTOM:
                left = pageX + targetRect.left + targetRect.width + arrowHeight;
                top = pageY + targetRect.top + targetRect.height - elRect.height - shiftY + padding + arrowWidth / 2;
                break;
        }

        // На iOS используется scrollLock hack, который выставляет отрицательное значение свойства top у body.
        if (IS_IOS && document.body.classList.contains('body_fixed')) {
            top = top - window.pageYOffset;
        }

        el.style.top = `${top}px`;
        el.style.left = `${left}px`;

        const checkResult = this.checkPosition();

        const goodPlacementCount = !checkResult.isRightOk ? 0 : values(checkResult).reduce((acc, cur) => acc + (cur ? 1 : 0), 0);

        if (goodPlacementCount >= this.maxGoodPlacementCount) {
            this.maxGoodPlacementCount = goodPlacementCount;
            this.bestPlacement = placement;
        }

        if (goodPlacementCount < 4 && smartPlacement) {
            this.repositionCount += 1;
            if (this.repositionCount < this.maxRepositionCount) {
                if (noArrow && !forceAutoPosition) {
                    this.setNewPlacement(checkResult);
                } else {
                    this.setNewPlacementArrow();
                }
                return;
            }

            this.setState({
                currentPlacement: this.bestPlacement,
            });
        }

        if (fitWindow && top + elRect.height + PADDING > window.innerHeight + window.pageYOffset) {
            el.style.bottom = `${PADDING - window.pageYOffset}px`;
        }

        if (fitHorizontalWindow && left + elRect.width + PADDING > window.innerWidth + window.pageXOffset) {
            el.style.right = `${PADDING}px`;
            el.style.left = `initial`;
        }

        this.setState({
            isFinalPlacement: true,
        });
    };

    onDimmerClick = (event) => {
        const { onClose } = this.props;
        event.stopPropagation();
        onClose(event);
    };

    // eslint-disable-next-line unicorn/consistent-function-scoping
    onWindowReposition = debounce(() => {
        const { pinned, fixed, onClose } = this.props;

        if (fixed) {
            return;
        }

        if (!pinned) {
            onClose();
            return;
        }

        this.setPlacements();
        this.repositionCount = 0;
        this.setPosition();
    }, 250);

    getArrowStyle() {
        const { arrowColor } = this.props;
        const { currentPlacement } = this.state;

        if (!arrowColor) {
            return undefined;
        }

        switch (currentPlacement) {
            case tooltipPlacements.RIGHT:
            case tooltipPlacements.RIGHT_TOP:
            case tooltipPlacements.RIGHT_BOTTOM:
                return { borderRightColor: arrowColor };
            case tooltipPlacements.LEFT:
            case tooltipPlacements.LEFT_TOP:
            case tooltipPlacements.LEFT_BOTTOM:
                return { borderLeftColor: arrowColor };
            case tooltipPlacements.BOTTOM:
            case tooltipPlacements.BOTTOM_RIGHT:
            case tooltipPlacements.BOTTOM_LEFT:
                return { borderBottomColor: arrowColor };
            case tooltipPlacements.TOP:
            case tooltipPlacements.TOP_RIGHT:
            case tooltipPlacements.TOP_LEFT:
                return { borderTopColor: arrowColor };
            default:
                return undefined;
        }
    }

    render() {
        const {
            children,
            qaId,
            fluid,
            noArrow,
            basic,
            fixed,
            theme,
            header,
            enableTransparentDimmer,
            withinDialog,
            radius,
            animated,
            minWidth,
            overAll,
        } = this.props;
        const { currentPlacement, isFinalPlacement } = this.state;

        const className = classNames({
            [styles.base]: true,
            [styles.base_minWidth]: minWidth,
            [styles.base_withinDialog]: !!withinDialog,
            [styles[`base_${currentPlacement}`]]: !noArrow,
            [styles.base_fluid]: fluid,
            [styles.base_visible]: isFinalPlacement,
            [styles.base_basic]: basic,
            [styles.base_fixed]: fixed,
            [styles.base_phone]: IS_PHONE_BROWSER,
            [styles[`base_${theme}`]]: !!theme,
            [styles[`radius_${radius}`]]: !!radius,
            [styles[`root_${currentPlacement}`]]: animated,
            [styles.root_final]: isFinalPlacement,
            [styles.base_overAll]: overAll,
        });

        return (
            <>
                {!!enableTransparentDimmer && <div id="clickDimmer" ref={this.dimmerRef} className={styles.clickDimmer} />}
                <div className={className} ref={this.el} data-qa-tooltip={qaId}>
                    {!noArrow && <div className={styles.arrow} style={this.getArrowStyle()} />}
                    <div className={styles.overlay}>
                        {!!header && (
                            <div
                                className={classNames({
                                    [styles[`header_${theme}`]]: !!theme,
                                })}
                            >
                                {header}
                            </div>
                        )}
                        {children}
                    </div>
                </div>
            </>
        );
    }
}
