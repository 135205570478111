import { Uploader } from 'reactApp/modules/uploading/serviceClasses/Uploader';
import { EConflictResolution } from 'reactApp/modules/uploading/uploading.types';
import { sendDwh, sendXray } from 'reactApp/utils/ga';

export const MB = 1024 * 1024;

export const getWaitTimeFromHeader = (headerVal?: any) => {
    return Math.max(headerVal * 1000 || 60000, 10000);
};

export const sendMetricsToDwh = (uploader: Uploader, size: number, receivedBytes = 0) => {
    size = size || uploader.descriptor?.size;

    sendDwh({
        eventCategory: 'uploader',
        action: 'upload',
        label: 'metrics',
        dwhData: {
            diskReadTime: uploader._metrics.diskReadTime,
            hashCalcTime: uploader._metrics.hashCalcTime,
            putTime: uploader._metrics.putTime || 0,
            pollTime: uploader._metrics.pollTime || 0,
            totalTime: getPerfDiffInMs(uploader._metrics.startTime),
            fileSize: size,
            prevUploadedBytes: receivedBytes ?? 0,
            speed: Math.round((size - receivedBytes) / (getPerfDiffInMs(uploader._metrics.startTime) / 1000)),
        },
    });

    if (!size) {
        return;
    }

    const koeff = size / MB;

    sendXray(['uploader', 'upload', 'metric'], {
        diskReadTime: Math.round(uploader._metrics.diskReadTime / koeff),
        hashCalcTime: Math.round(uploader._metrics.hashCalcTime / koeff),
        putTime: Math.round(uploader._metrics.putTime / koeff) || 0,
        pollTime: Math.round(uploader._metrics.pollTime / koeff) || 0,
        totalTime: Math.round(getPerfDiffInMs(uploader._metrics.startTime) / koeff),
    });

    if (uploader.descriptor) {
        uploader.descriptor.uploadingPacketConfig.putTime += uploader._metrics?.putTime ?? 0;
        uploader.descriptor.uploadingPacketConfig.pollTime += uploader._metrics?.pollTime ?? 0;
        uploader.descriptor.uploadingPacketConfig.hashCalcTime += uploader._metrics?.hashCalcTime ?? 0;
        uploader.descriptor.uploadingPacketConfig.diskReadTime += uploader._metrics?.diskReadTime ?? 0;
        uploader.descriptor.uploadingPacketConfig.prevUploadedBytes += receivedBytes ?? 0;
    }
};

export const getPerfDiffInMs = (startTime: number) => Date.now() - startTime;

export const isCancelableXhrState = (readyState: number) => {
    const UNSENT = XMLHttpRequest.UNSENT;
    const DONE = XMLHttpRequest.DONE;
    return UNSENT < readyState && readyState < DONE;
};

const allowedConflictResolutions = [EConflictResolution.skip, EConflictResolution.rewrite, EConflictResolution.rename];

export const isAllowedConflictResolution = (resolution: EConflictResolution) => allowedConflictResolutions.includes(resolution);
