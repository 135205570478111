import axios from 'axios';
import { sendMyofficeGa } from 'Cloud/Application/Editor/MyOffice/helpers/sendMyofficeGa';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { addFileToCloud } from 'reactApp/modules/uploading/helpers/cloudFs/addFile';
import { saveDocument } from 'reactApp/modules/uploading/uploading.module';
import { EConflictResolution } from 'reactApp/modules/uploading/uploading.types';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';
import { call } from 'redux-saga/effects';

const urlBuilder = new UrlBuilder();

export function* handleSaveDocument(action: ReturnType<typeof saveDocument>) {
    const { fileContent, home, onResult, conflictResolution = EConflictResolution.rewrite } = action.payload;

    try {
        const url = urlBuilder.upload(true);
        const hash = yield axios.put(url, fileContent, { withCredentials: true });

        const result = yield call(addFileToCloud, {
            file: {
                cloudPath: home,
                cloudHash: hash.data,
                storage: EStorageType.home,
                size: fileContent.byteLength,
            },
            conflictResolution,
        });

        sendMyofficeGa('save-success');

        onResult({ result, hash: hash.data });
    } catch (error) {
        sendMyofficeGa('save-error');

        onResult({ error });
    }
}
