import classNames from 'clsx';
import React, { memo, ReactElement } from 'react';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { AlldocumentsItem } from 'reactApp/ui/DataListItemRow/components/RowFor/AlldocumentsItem';
import { GalleryItem } from 'reactApp/ui/DataListItemRow/components/RowFor/Gallery';

import { AlbumsItem } from './components/RowFor/Album';
import { ArchiveItem } from './components/RowFor/Archive';
import { AttachesItem } from './components/RowFor/Attach';
import { FavoritesItem } from './components/RowFor/Favorites';
import { FeedItem } from './components/RowFor/Feed';
import { HomeItem } from './components/RowFor/Home';
import { IncomingItem } from './components/RowFor/Incoming';
import { PublicItem } from './components/RowFor/Public';
import { QuotaCleanerItem } from './components/RowFor/QuotaCleaner';
import { SearchItem } from './components/RowFor/Search';
import { SharedItem } from './components/RowFor/Shared';
import { StockItem } from './components/RowFor/Stock';
import { TrashbinItem } from './components/RowFor/Trashbin';
import styles from './DataListItemRow.css';
import newStyles from './DataListItemRow.new.css';
import { DataListItemRowProps } from './DataListItemRow.types';

export const DataListItemRow = memo((props: DataListItemRowProps): ReactElement => {
    const { isVKEmbedded, isSelected, isSelecting, isActive, parentItemRef } = props;

    const renderItem = (props: DataListItemRowProps) => {
        // eslint-disable-next-line sonarjs/max-switch-cases
        switch (props.storage) {
            case EStorageType.archive:
                return <ArchiveItem {...props} />;
            case EStorageType.sharedIncoming:
                return <IncomingItem {...props} />;
            case EStorageType.home: {
                return <HomeItem {...props} />;
            }
            case EStorageType.albums: {
                return <AlbumsItem {...props} />;
            }
            case EStorageType.sharedLinks:
            case EStorageType.sharedAutodelete:
                return <SharedItem {...props} />;
            case EStorageType.public:
                return <PublicItem {...props} />;
            case EStorageType.favorites:
                return <FavoritesItem {...props} />;
            case EStorageType.stock:
                return <StockItem {...props} />;
            case EStorageType.search: {
                return <SearchItem {...props} />;
            }
            case EStorageType.feed: {
                return <FeedItem {...props} />;
            }
            case EStorageType.alldocuments: {
                return <AlldocumentsItem {...props} />;
            }
            case EStorageType.gallery: {
                return <GalleryItem {...props} />;
            }
            case EStorageType.attaches:
                return <AttachesItem {...props} />;
            case EStorageType.trashbin: {
                return <TrashbinItem {...props} />;
            }
            case EStorageType.quotaCleaner: {
                return <QuotaCleanerItem {...props} />;
            }
        }
    };

    return (
        <div
            ref={parentItemRef}
            className={classNames(newStyles.root, {
                [styles.root_vk_embedded]: isVKEmbedded,
                [newStyles.root_selected]: isSelected,
                [newStyles.root_active]: isActive,
                [newStyles.root_selecting]: isSelecting,
                [styles.root_responsive]: ENABLE_FULL_RESPONSIVE,
                [styles.root_xl]:
                    (props.storage === EStorageType.sharedIncoming ||
                        props.storage === EStorageType.gallery ||
                        props.storage === EStorageType.search) &&
                    ENABLE_FULL_RESPONSIVE,
            })}
        >
            {renderItem(props)}
        </div>
    );
});

DataListItemRow.displayName = 'DataListItemRow';
DataListItemRow.whyDidYouRender = true;
