export const errorReducer = (state = {}, action) => {
    const { type, error } = action;
    const matches = /(.*)\/(request|failure)/.exec(type);

    if (!matches) {
        return state;
    }

    const [, requestName, requestState] = matches;
    return {
        ...state,
        // Store errorMessage
        // e.g. stores errorMessage when receiving @gift/fetch/failure
        // else clear errorMessage when receiving @gift/fetch/request
        [requestName]: requestState === 'failure' ? error : '',
    };
};
