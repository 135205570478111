import classNames from 'clsx';
import { ReactComponent as ShareIcon } from 'img/icons/editor/r7ShareIcon.svg';
import React from 'react';
import { BREAKPOINT_SM } from 'reactApp/constants/breakpoints';
import { useMinWidthBreakpoint } from 'reactApp/hooks/responsiveness/useMinWidthBreakpoint';

import styles from './Button.css';

type ButtonProps = {
    text?: string;
    testId?: string;
    onClick: (e) => void;
};

export const Button: React.FC<ButtonProps> = ({ text, testId, onClick }) => {
    const [largeBreakpointHit] = useMinWidthBreakpoint(BREAKPOINT_SM);
    const isMini = !largeBreakpointHit;

    return (
        <button data-test-id={testId} className={classNames(styles.button, { [styles.mini]: isMini })} onClick={onClick}>
            <ShareIcon />
            {!isMini && text}
        </button>
    );
};
