import { isUseO2Token } from 'reactApp/api/O2AuthClient';
import type { CommunicatorPackedMessage, File } from 'reactApp/modules/integration/integration.types';

export enum PostMessagesNames {
    publishSuccess = 'cloud:publish_success',
    close = 'cloud:close',
    expandRequest = 'cloud:expand_request',
    expandResponse = 'cloud:expand_response',
    closeViewer = 'cloud:close_viewer',
}

// todo: использовать модуль Communicator из @vkontakte/cloud-integration-sdk, когда он будет опубликован
function sendMessage(targetOrigin: string, name: string, payload?: Record<string, any>) {
    const packedMessage: CommunicatorPackedMessage = {
        name,
        __cloudMessage: true,
    };
    if (payload) {
        packedMessage.payload = JSON.stringify(payload);
    }

    if (isUseO2Token() && window.parent.opener && targetOrigin) {
        window.parent.opener.postMessage(packedMessage, targetOrigin);
    } else if (isUseO2Token() && window.parent.parent && targetOrigin) {
        window.parent.parent.postMessage(packedMessage, targetOrigin);
    } else if (window.opener && targetOrigin) {
        window.opener.postMessage(packedMessage, targetOrigin);
    } else if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log('%c [integration]', 'font-weight: bold', 'sending message', packedMessage, 'to origin ', targetOrigin);
    }
}

export function sendSuccess(parentOrigin: string, files: File[]) {
    sendMessage(parentOrigin, PostMessagesNames.publishSuccess, { files });
}

export function sendClose(parentOrigin: string) {
    sendMessage(parentOrigin, PostMessagesNames.close);
}

export function sendExpandRequest(parentOrigin: string) {
    sendMessage(parentOrigin, PostMessagesNames.expandRequest);
}
