import { bytesToNDigits } from '@mail/cross-sizes-utils';
import slide1 from 'img/family/onboarding/family-onboarding-1.jpg';
import slide1Dark from 'img/family/onboarding/family-onboarding-1-dark.jpg';
import slide1Touch from 'img/family/onboarding/family-onboarding-1-touch.jpg';
import slide1TouchDark from 'img/family/onboarding/family-onboarding-1-touch-dark.jpg';
import slide2 from 'img/family/onboarding/family-onboarding-2.jpg';
import slide2Dark from 'img/family/onboarding/family-onboarding-2-dark.jpg';
import slide2Touch from 'img/family/onboarding/family-onboarding-2-touch.jpg';
import slide2TouchDark from 'img/family/onboarding/family-onboarding-2-touch-dark.jpg';
import slide3 from 'img/family/onboarding/family-onboarding-3.jpg';
import slide3Dark from 'img/family/onboarding/family-onboarding-3-dark.jpg';
import slide3Touch from 'img/family/onboarding/family-onboarding-3-touch.jpg';
import slide3TouchDark from 'img/family/onboarding/family-onboarding-3-touch-dark.jpg';
import { SliderModalSlide } from 'reactApp/ui/SliderModal/SliderModal.types';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

const familyOnboardingSlides: SliderModalSlide[] = [
    {
        id: 'slide1',
        title: 'Пользуйтесь подпиской Mail Space вместе',
        text: 'Это выгодно: ваши близкие получат преимущества платной подписки, при этом её стоимость для вас не изменится',
        src: slide1,
        srcMobile: slide1Touch,
    },
    {
        id: 'slide2',
        title: 'У каждого своё Облако',
        text: 'Каждый участник видит только свой аккаунт и свои файлы в Облаке. Личное остаётся личным.',
        src: slide2,
        srcMobile: slide2Touch,
    },
    {
        id: 'slide3',
        title: 'У вас 3 свободных места',
        text: 'Ваши близкие получат доступ к вашим гигабайтам и другим возможностям подписки Mail Space бесплатно',
        src: slide3,
        srcMobile: slide3Touch,
    },
];

export const getFamilyOnboardingSlides = (isPaid: boolean, quota: number): SliderModalSlide[] => {
    const isDarkMode = isDarkThemeModeEnabled();

    if (isDarkMode) {
        familyOnboardingSlides[0].src = slide1Dark;
        familyOnboardingSlides[1].src = slide2Dark;
        familyOnboardingSlides[2].src = slide3Dark;
        familyOnboardingSlides[0].srcMobile = slide1TouchDark;
        familyOnboardingSlides[1].srcMobile = slide2TouchDark;
        familyOnboardingSlides[2].srcMobile = slide3TouchDark;
    }

    if (isPaid) {
        const size = bytesToNDigits(quota, 3);

        return [
            familyOnboardingSlides[0],
            familyOnboardingSlides[1],
            {
                ...familyOnboardingSlides[2],
                text: `Ваши близкие получат доступ к ${size.value} и другим возможностям подписки Mail Space бесплатно`,
            },
        ];
    }
    return familyOnboardingSlides;
};
