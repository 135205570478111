import { xray } from 'lib/xray';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

export enum TechAnalyticsType {
    apiStart = 'api-start',
    apiSuccess = 'api-success',
    apiError = 'api-error',
    showPopup = 'show-popup',
    clickPopupButton = 'click-popup',
    showEditButton = 'show-edit',
    clickOnEditButton = 'click-on-edit',
}

export const sendTechAnalytics = (ext: string, storage: EStorageType) => (type: TechAnalyticsType) =>
    xray.send(`ovidius_convert-${type}_${storage}_${ext}`);
