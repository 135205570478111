import classNames from 'clsx';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getLlmDialogState } from 'reactApp/modules/editor/editor.selectors';
import { LLMAnalyticsType, sendTechLLMAnalytics, useGetLLMAnalytics } from 'reactApp/ui/EditorLLM/analytics/EditorLlmAnalytics';
import { EditorLLMDialogFooter } from 'reactApp/ui/EditorLLM/EditorLLMDialog/components/Footer/EditorLLMDialogFooter';
import { EditorLLMDialogHeader } from 'reactApp/ui/EditorLLM/EditorLLMDialog/components/Header/EditorLLMDialogHeader';
import { EditLLMDialogMessages } from 'reactApp/ui/EditorLLM/EditorLLMDialog/components/Messages/EditLLMDialogMessages';
import { copyTextWithoutStyles } from 'reactApp/ui/EditorLLM/EditorLLMDialog/utils/EditorLlmDialog.utils';

import styles from './EditorLlmDialog.css';

export const EditorLlmDialog = () => {
    const { messages, isOpen } = useSelector(getLlmDialogState);
    const ref = useRef<HTMLDivElement>(null);
    const sendAnalytics = useGetLLMAnalytics();

    useEffect(() => {
        if (isOpen) {
            sendAnalytics({ action: LLMAnalyticsType.CHAT_OPEN });
            sendTechLLMAnalytics({ action: LLMAnalyticsType.CHAT_OPEN });
        }
    }, [isOpen, sendAnalytics]);

    useEffect(() => {
        const element = ref?.current;

        element?.addEventListener('copy', copyTextWithoutStyles);

        return () => {
            element?.removeEventListener('copy', copyTextWithoutStyles);
        };
    }, []);

    return (
        <div className={classNames(styles.container, { [styles.container_open]: isOpen })} data-qa-id="LLMDialog">
            <div className={styles.content} ref={ref}>
                <EditorLLMDialogHeader />
                <EditLLMDialogMessages messages={messages} />
                <EditorLLMDialogFooter />
            </div>
        </div>
    );
};
