import { Icon16ErrorCircleOutline } from '@vkontakte/icons';
import classnames from 'clsx';
import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { platform } from 'reactApp/appHelpers/configHelpers';
import { getAllDocumentsCurrentType } from 'reactApp/modules/allDocuments/allDocuments.selectors';
import { getFeatureAllDocumentsUploadText } from 'reactApp/modules/features/features.selectors';
import styles from 'reactApp/ui/UploadList/NotDocumentWarning/NotDocumentWarning.css';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

const mainText = {
    common: 'Загрузили только документы',
};

export const NotDocumentWarning = memo(() => {
    const currentAllDocsType = useSelector(getAllDocumentsCurrentType);
    const additionalText = useSelector(getFeatureAllDocumentsUploadText).warning;

    useEffect(() => {
        sendDwh({
            eventCategory: ECategoryGa.docs,
            action: 'show-uploader_wrong_format',
            dwhData: { platform, place: currentAllDocsType },
        });
    }, []);

    return (
        <div className={styles.root}>
            <div className={classnames({ [styles.iconContainer]: true, [styles.noAdditionalTextIcon]: !additionalText })}>
                <Icon16ErrorCircleOutline fill={'var(--vkui--color_background)'} className={styles.icon} />
            </div>
            <div className={classnames({ [styles.text]: true, [styles.noAdditionalText]: !additionalText })}>
                <div className={styles.mainText}>{mainText.common}</div>
                {additionalText && <div className={styles.additionalText}>{additionalText}</div>}
            </div>
        </div>
    );
});

NotDocumentWarning.displayName = 'NotDocumentWarning';
