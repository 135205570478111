/**
 * Конвертирует ArrayBuffer в JSON объект
 */
export const convertBufferToJSON = (source: ArrayBuffer): { isConverted: boolean; data?: Record<string, any>; error?: unknown } => {
    try {
        const array = new Uint8Array(source) as any;
        const data = JSON.parse(String.fromCharCode.apply(null, array));

        return { isConverted: true, data };
    } catch (error) {
        return { isConverted: false, error };
    }
};
