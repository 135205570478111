import classNames from 'clsx';
import { ReactComponent as AppQRCode } from 'img/errors/app-qrcode.svg';
import { ReactComponent as CuteCat } from 'img/errors/cute-cat.svg';
import React, { FC } from 'react';
import { DisableButtonComponent } from 'reactApp/ui/AdvBanners/DisableButton/DisableButtonComponent';
import { DownloadAppButtonComponent } from 'reactApp/ui/Mobile/DownloadAppButton/DownloadButtonComponent';
import { ON_PREMISE } from 'server/constants/environment';

import { ErrorPageButton } from './components/ErrorPageButton';
import styles from './ErrorPage.css';
import { disableAdvButtonHref, ERROR_STATUS_MAP } from './ErrorPage.helpers';
import { ErrorInfo, EStatus, IProps } from './ErrorPage.types';

export const ErrorPageComponent: FC<Omit<IProps, 'status'> & ErrorInfo & { status: EStatus | string }> = ({
    status,
    title,
    annotation,
    buttonLink,
    buttonText,
    imageSrc,
    imageSrcDark,
    downloadLink,
    onButtonClick,
    onClickHandler,
    advDomIdDesktop,
    children,
    isHidePromo = false,
}) => {
    /**
     * Обработчик, который вызывает onButtonClick (захардкожен - отправляется статистика)
     * и onClickHandler (любой обработчик из ERROR_STATUS_MAP) при его наличии.
     */
    const onButtonClickHandler = () => {
        onButtonClick?.();
        onClickHandler?.();
    };

    return (
        <div className={styles.container} data-qa-id={status}>
            <div className={styles.wrapper}>
                <div className={styles.root}>
                    <img className={classNames(styles.error, styles.errorLight)} src={imageSrc} />
                    <img className={classNames(styles.error, styles.errorDark)} src={imageSrcDark} />
                    <div className={styles.description}>
                        <div className={styles.title}>{title}</div>
                        <div className={styles.annotation}>{annotation}</div>
                        <ErrorPageButton link={buttonLink} text={buttonText} onClick={onButtonClickHandler} />
                    </div>
                </div>

                {!isHidePromo && !ON_PREMISE && (
                    <>
                        <div className={styles.downloadAppContainer}>
                            <div className={styles.cuteCat}>
                                <CuteCat />
                            </div>
                            <div className={styles.qrcodeContainer}>
                                <div className={styles.qrcode}>
                                    <AppQRCode />
                                </div>
                                <div className={styles.qrcodeText}>
                                    Установите приложение, чтобы ваши файлы и&nbsp;фото всегда были под рукой
                                </div>
                            </div>
                        </div>

                        <div className={styles.appButton}>
                            <DownloadAppButtonComponent linkToDownload={downloadLink} text="Установить приложение" showIcon />
                        </div>
                    </>
                )}
            </div>
            {!ON_PREMISE && (
                <div className={styles.advDesktop}>
                    {children || (
                        <div className={classNames(styles.advHeaderStatic, 'js-header-error-page')}>
                            <h3 className={styles.advTitle}>Реклама</h3>
                            <DisableButtonComponent className="js-disable-button" text="Отключить" href={disableAdvButtonHref} />
                        </div>
                    )}
                    <div id={advDomIdDesktop} data-qa-id="Sidebar" />
                </div>
            )}
        </div>
    );
};

export const ErrorPageBlock: FC<IProps> = (props) => {
    const { status } = props;
    const info = ERROR_STATUS_MAP[status];

    return <ErrorPageComponent {...props} {...info} />;
};
