export enum ERegisterCloudTooltipType {
    gallery = 'gallery',
    image = 'image',
    docs = 'docs',
    file = 'file',
    folder = 'folder',
}

type TRegisterCloudTooltipData = {
    title: string;
    text: string;
    theme: 'light' | 'dark';
    image: 'cloud' | 'docs' | 'photos';
};

export const registerCloudTooltipData: Record<ERegisterCloudTooltipType, TRegisterCloudTooltipData> = {
    [ERegisterCloudTooltipType.gallery]: {
        title: 'Любимые фото не потеряются',
        text: 'Сохраняйте все файлы в Облако\n и отправляйте другим людям',
        theme: 'light',
        image: 'photos',
    },
    [ERegisterCloudTooltipType.image]: {
        title: 'Любимые фото не потеряются',
        text: 'Сохраняйте все файлы в Облако\n и отправляйте другим людям',
        theme: 'dark',
        image: 'photos',
    },
    [ERegisterCloudTooltipType.docs]: {
        title: 'Важные документы всегда под рукой',
        text: 'Сохраняйте файлы в Облако\n и редактируйте их онлайн',
        theme: 'dark',
        image: 'docs',
    },
    [ERegisterCloudTooltipType.file]: {
        title: 'Сохраняйте файлы в Облако',
        text: 'Они будут надёжно защищены\n и доступны на любых устройствах',
        theme: 'dark',
        image: 'cloud',
    },
    [ERegisterCloudTooltipType.folder]: {
        title: 'Сохраняйте файлы в Облако',
        text: 'Они будут надёжно защищены\n и доступны на любых устройствах',
        theme: 'light',
        image: 'cloud',
    },
};
