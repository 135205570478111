import './LoopButton.css';

import videojs from 'video.js';

import { LoopButton } from './LoopButton';

interface IConfig {
    placementIndex: number;
}

const defaults = {};

class LoopPlugin {
    player: videojs.Player;
    config: IConfig;
    _loopButton?: videojs.Button;

    constructor(player, options) {
        this.player = player;
        this.config = options;

        this.createLoopButton();
    }

    /**
     * Adds the loop button to the player control bar.
     */
    createLoopButton() {
        const player = this.player;

        this._loopButton = new LoopButton(player, { name: 'LoopButton' });

        const placementIndex = player.controlBar.children().length - 4;
        const concreteButtonInstance = player.controlBar.addChild(
            this._loopButton,
            { componentClass: 'loopButton' },
            this.config.placementIndex || placementIndex
        );

        concreteButtonInstance.addClass('vjs-loop-button');

        concreteButtonInstance.removeClass('vjs-hidden');
    }
}

const onPlayerReady = (player: videojs.Player, options: IConfig) => {
    player.addClass('vjs-loop-plugin');
    player._loopPlugin = new LoopPlugin(player, options);
};

const loopPlugin = function (this: videojs.Player, options: IConfig) {
    this.ready(() => {
        onPlayerReady(this, videojs.mergeOptions(defaults, options));
    });
};

declare module 'video.js' {
    interface VideoJsPlayer {
        _loopPlugin: LoopPlugin;
        loopPlugin: (options?: IConfig) => void;
    }
}

export default loopPlugin;
