define(function (require, exports, module) {
	'use strict';

	module.exports = function () {

		return {
			'domain/folders': {
				"success": {
					"status": 200,
					"body": [
						{
							"mode": "rw",
							"name": "TestDomainFolder1",
							"home": "/TestDomainFolder1",
							"size": 0
						},
						{
							"mode": "rw",
							"name": "TestDomainFolder2",
							"home": "/TestDomainFolder2",
							"size": 100
						},
						{
							"mode": "rw",
							"name": "TestDomainFolder3",
							"home": "/TestDomainFolder3",
							"size": 1000
						}
					]
				},
				"emptyResult": {
					"status": 200,
					"body": []
				},
				"fail503": {
					"status": 503,
					"body": {
						error: "service unavailable"
					}
				},
				"fail400": {
					"status": 400,
					"body": {
						"error": "bad request"
					}
				}
			}
		};
	};
});
