import { logger } from 'lib/logger';
import { PromocodeGetAPICall } from 'reactApp/api/promo/PromocodeGetAPICall';
import { loadPromocodes, loadPromocodesSuccess } from 'reactApp/modules/promocode/promocode.module';
import { put, takeEvery } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

const api = () => new PromocodeGetAPICall().makeRequest();

function* loadPromocodesSaga() {
    try {
        const { data } = yield* call(api);

        const { promocodes } = data;

        yield put(loadPromocodesSuccess(promocodes || []));
    } catch (error) {
        logger.error(error);
    }
}

export function* watchPromocodes() {
    yield takeEvery(loadPromocodes.toString(), loadPromocodesSaga);
}
