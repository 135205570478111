export enum FileType {
    /** Folder
     * АЛАРМ:
     *  - Если добавляешь тип папки, то добавь его в reactApp/ui/FileIcon/new/FileIcon.helpers.ts:isFolder
     */
    FOLDER_AUTODELETE = 'folder-autodelete',
    FOLDER_AUTODELETE_WEEK = 'folder-autodelete-week',
    FOLDER_AUTODELETE_MONTH = 'folder-autodelete-month',
    FOLDER_SHARED = 'folder-shared',
    FOLDER_MOUNTED = 'folder-mounted',
    FOLDER_CAMERA = 'folder-camera',
    FOLDER = 'folder',

    /** Document */
    DOCM = 'docm',
    DOT = 'dot',
    DOTX = 'dotx',
    ODT = 'odt',
    XODT = 'xodt',
    OTT = 'ott',
    PAGES = 'pages',
    DOC = 'doc',
    DOCX = 'docx',
    RTF = 'rtf',
    WPS = 'wps',
    WPD = 'wpd',
    TEXT = 'text',
    XOTT = 'xott',
    WORD_ONLINE = 'word-online',
    DOCUMENT = 'document',

    /** Spreadsheet */
    CSV = 'csv',
    NUMBERS = 'numbers',
    ODS = 'ods',
    XODS = 'xods',
    OTS = 'ots',
    XOTS = 'xots',
    XLS = 'xls',
    XLSX = 'xlsx',
    XLSB = 'xlsb',
    XLSM = 'xlsm',
    XLTX = 'xltx',
    XLT = 'xlt',
    EXCEL = 'excel',
    EXCEL_ONLINE = 'excel-online',
    SPREADSHEET = 'spreadsheet',

    /** Presentation */
    KEYNOTE = 'keynote',
    ODP = 'odp',
    OTP = 'otp',
    POT = 'pot',
    POTM = 'potm',
    POTX = 'potx',
    PPSM = 'ppsm',
    PPTM = 'pptm',
    PPT = 'ppt',
    PPTX = 'pptx',
    PPS = 'pps',
    PPSX = 'ppsx',
    POWERPOINT_ONLINE = 'powerpoint-online',
    PRESENTATION = 'presentation',

    /** Image */
    APNG = 'apng',
    HDP = 'hdp',
    ICO = 'ico',
    J2K = 'j2k',
    JPE = 'jpe',
    JPF = 'jpf',
    JPM = 'jpm',
    JPX = 'jpx',
    JXR = 'jxr',
    WDP = 'wdp',
    WEBP = 'webp',
    JPG = 'jpg',
    JPEG = 'jpeg',
    GIF = 'gif',
    BMP = 'bmp',
    TIF = 'tif',
    PNG = 'png',
    TGA = 'tga',
    IMAGE = 'image',
    RAW = 'raw',
    GRAPHICS = 'graphics',
    PICTURE = 'picture',
    ARW = 'arw',
    CR2 = 'cr2',
    CRW = 'crw',
    DNG = 'dng',
    HEIC = 'heic',
    HEIF = 'heif',
    NEF = 'nef',
    NRW = 'nrw',
    SR2 = 'sr2',
    SRF = 'srf',

    /** Video */
    M2TS = 'm2ts',
    M4V = 'm4v',
    MATROSKA = 'matroska',
    MK3D = 'mk3d',
    MKA = 'mka',
    MKS = 'mks',
    MKV = 'mkv',
    MNG = 'mng',
    MP4V = 'mp4v',
    MTS = 'mts',
    OGV = 'ogv',
    QT = 'qt',
    TS = 'ts',
    TSA = 'tsa',
    TSV = 'tsv',
    VOB = 'vob',
    WMX = 'wmx',
    FLV = 'flv',
    MPG = 'mpg',
    _3GP = '3gp',
    MOV = 'mov',
    ASF = 'asf',
    MP4 = 'mp4',
    MPEG = 'mpeg',
    AVI = 'avi',
    WMV = 'wmv',
    WEBM = 'webm',
    VIDEO = 'video',

    /** Audio */
    AIFC = 'aifc',
    MID = 'mid',
    OGA = 'oga',
    OPUS = 'opus',
    RA = 'ra',
    WAVE = 'wave',
    RAM = 'ram',
    MIDI = 'midi',
    WAV = 'wav',
    OGG = 'ogg',
    AIFF = 'aiff',
    FLAC = 'flac',
    M4A = 'm4a',
    WMA = 'wma',
    MP3 = 'mp3',
    AAC = 'aac',
    AUDIO = 'audio',

    /** Text */
    CONF = 'conf',
    DIFF = 'diff',
    INI = 'ini',
    MIME = 'mime',
    LOG = 'log',
    TXT = 'txt',
    LNG = 'lng',
    NOTE = 'note',
    PLAIN_TEXT = 'plain-text',

    /** Code */
    ASM = 'asm',
    ATOM = 'atom',
    CC = 'cc',
    CPP = 'cpp',
    CSS = 'css',
    CXX = 'cxx',
    DTD = 'dtd',
    F = 'f',
    F77 = 'f77',
    F90 = 'f90',
    FOR = 'for',
    GEMSPEC = 'gemspec',
    H = 'h',
    HH = 'hh',
    HPP = 'hpp',
    JAVA = 'java',
    MASM = 'masm',
    P = 'p',
    PAS = 'pas',
    PL = 'pl',
    PM = 'pm',
    RAKE = 'rake',
    RU = 'ru',
    S = 's',
    SGM = 'sgm',
    SGML = 'sgml',
    T = 'T',
    TEX = 'tex',
    TEXI = 'texi',
    TEXINFO = 'texinfo',
    TXI = 'txi',
    VBS = 'vbs',
    XML = 'xml',
    YAML = 'yaml',
    YML = 'yml',
    C = 'C',
    JS = 'js',
    PHP = 'php',
    PY = 'py',
    RB = 'rb',
    CHM = 'chm',
    MATHML = 'mathml',
    MHT = 'mht',
    MHTML = 'mhtml',
    MML = 'mml',
    HTM = 'htm',
    HTML = 'html',
    XHTML = 'xhtml',
    JSON = 'json',
    MAD = 'mad',
    MARKDOWN = 'markdown',
    SOURCE = 'source',

    /** Archive */
    CAB = 'cab',
    EAR = 'ear',
    TAR = 'tar',
    TBZ = 'tbz',
    TGZ = 'tgz',
    WAR = 'war',
    ARJ = 'arj',
    ZIPZ = 'zipz',
    BZIP2 = 'bzip2',
    BZ2 = 'bz2',
    GZ = 'gz',
    _7z = '7z',
    RAR = 'rar',
    ZIP = 'zip',
    JAR = 'jar',
    HYP = 'hyp',
    ARCHIVE = 'archive',

    /** Graphics */
    PSD = 'psd',
    AI = 'ai',
    SVG = 'svg',
    SVGZ = 'svgz',
    EPS = 'eps',
    SKETCH = 'sketch',
    CDR = 'cdr',
    FIG = 'fig',
    CDW = 'cdw',
    STL = 'stl',
    DXF = 'dxf',
    SKB = 'skb',
    PHOTOSHOP = 'photoshop',

    /** Books */
    CB7 = 'cb7',
    CBR = 'cbr',
    CBA = 'cba',
    CBT = 'cbt',
    CBZ = 'cbz',
    DJV = 'djv',
    DJVU = 'djvu',
    EBOOK = 'ebook',
    FB2 = 'fb2',
    PDF = 'pdf',
    EPUB = 'epub',

    /** Mail */
    MSG = 'msg',
    MBOX = 'mbox',
    CLX = 'clx',
    EML = 'eml',
    P7S = 'p7s',
    EMAIL = 'email',

    /** Calendar */
    ICS = 'ics',
    IFB = 'ifb',
    VCS = 'vcs',
    CALENDAR = 'calendar',

    /** Source */
    BAT = 'bat',
    COM = 'com',
    MSI = 'msi',
    SH = 'sh',
    EXE = 'exe',
    EXEC = 'exec',
    ISO = 'iso',
    DMG = 'dmg',
    ANDROID_PACKAGE = 'android-package',
    APK = 'apk',
    ipa = 'ipa',
    dbf = 'dbf',
    EXECUTABLE = 'executable',

    /** Other */
    TORRENT = 'torrent',
    SIG = 'sig',
    ASX = 'asx',
    M3U = 'm3u',
    M3U8 = 'm3u8',
    PLAYLIST = 'playlist',
    ODC = 'odc',
    OTC = 'otc',
    CHART = 'chart',
    LDIF = 'ldif',
    VCARD = 'vcard',
    VCF = 'vcf',
    CARD = 'card',
    RSS = 'rss',
    WEB_FEED = 'web-feed',

    /** Virus */
    VIRUS = 'virus',
    FOLDER_VIRUS = 'folder-virus',

    UNKNOWN = 'UNKNOWN',
}
