import classNames from 'clsx';
import React, { useCallback } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { EColor, FavoriteIcon } from 'reactApp/ui/FavoriteIcon/FavoriteIcon';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { sendGa } from 'reactApp/utils/ga';

import styles from '../DataListItemThumb.css';

const handleDoubleClick = (e) => {
    e.stopPropagation();
};

export const Favorites = ({ isActive, onClick, isActiveHidden }: { isActive: boolean; onClick: () => void; isActiveHidden?: boolean }) => {
    const handleOnFavorites = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            e.nativeEvent.stopImmediatePropagation();

            sendGa('datalist-control', 'click', 'favorite', EViewMode.thumbs);

            onClick();
        },
        [onClick]
    );

    return (
        <Hint text={isActive ? 'Убрать из избранных' : 'Добавить в избранные'}>
            <div
                className={classNames({
                    [styles.favorites]: true,
                    [styles.favorites_active]: isActive && !isActiveHidden,
                })}
                onClick={handleOnFavorites}
                onDoubleClick={handleDoubleClick}
            >
                <FavoriteIcon active={isActive} color={EColor.RED} />
            </div>
        </Hint>
    );
};
