import { ReactComponent as SuspiciousIcon } from 'img/filetype/flatNew/suspicious.svg';
import React, { memo, ReactElement, useMemo } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { EActionName } from 'reactApp/ui/SuspiciousDialog/SuspiciousDialog.types';

import styles from './SuspiciousDialog.css';

interface IProps {
    onSuccess: () => void;
    onClose: () => void;
    filename: string;
    action?: EActionName;
}

export const SuspiciousDialog = memo<IProps>(function SuspiciousDialog(props): ReactElement {
    const { onClose, onSuccess, filename, action } = props;
    let submitBttnName = 'Скачать';
    if (action === EActionName.clone) {
        submitBttnName = 'Сохранить';
    }

    const footer = useMemo(
        () => (
            <div>
                <Button theme="vk" fluid onClick={onSuccess}>
                    {submitBttnName}
                </Button>
            </div>
        ),
        [onSuccess, submitBttnName]
    );

    return (
        <MobileDialog
            id="mobile-suspicious-modal"
            open
            dimmer
            onClose={onClose}
            disableScrolling
            closable
            closeOnDimmerClick
            mod="confirm"
            topmost
            footer={footer}
        >
            <div className={styles.root}>
                <SuspiciousIcon className={styles.icon} />
                <div className={styles.title}>
                    Вы собираетесь {action === EActionName.clone ? 'cохранить' : 'скачать'} файл {filename}
                </div>
                <div>
                    Файл этого типа может причинить вред вашему устройству. {action === EActionName.clone ? 'Сохраняйте' : 'Скачивайте'}{' '}
                    его, только если вы доверяете отправителю.
                </div>
            </div>
        </MobileDialog>
    );
});
