import { ServerError } from 'reactApp/errors';

import { UploadError } from './UploadError';

export class MalformedError extends UploadError {
    constructor(stack: Error, source: string, responseText: string) {
        super(stack, source);

        this.responseText = ServerError.removeHTML(responseText || '').trim().slice(0, 1000);
    }

    radarName = 'error_{SOURCE}_malformed';
    message = 'Неизвестный формат данных.';
    responseText = '';

    toLogStringPattern = '[{TIME}] {ID} {SOURCE} {CLASS_NAME} { {META} }: {RESPONSE_TEXT}';

    toLogString = () => {
        let result = super.toLogString();

        result = result.replace('{RESPONSE_TEXT}', this.responseText);

        return result;
    };

    getDetails = () => {
        const details = super.getDetails();

        return { ...details, responseText: this.responseText };
    };
}
