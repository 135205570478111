import { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

import { DomainFoldersState } from './domainFolders.types';

const getDomainFoldersState = (state: RootState): DomainFoldersState => state.domainsFolders;

export const getDomainFoldersList = createSelector(getDomainFoldersState, (state) => state.list);

export const getDomainFolderQuotaFree = createSelector(
    getDomainFoldersState,
    (folders, folderId: string) => folderId,
    (folders, folderId) => {
        const lowerFolderId = folderId.toLowerCase();
        if (folders.list[lowerFolderId]) {
            return folders.list[lowerFolderId].quota - folders.list[lowerFolderId].size;
        }

        return 0;
    }
);
