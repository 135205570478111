import React, { memo, ReactElement, useRef } from 'react';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';

import styles from './SharingNew.css';
import { IProps } from './SharingNew.types';
import { SharingNewContent } from './SharingNewContent';

export const SharingNewDesktop = memo(function SharingNewDesktop(props: IPropsWithPopup<IProps>): ReactElement {
    return (
        <Dialog open id="sharing-new" onCancel={props.onClose} className={styles.modal}>
            <Content isModal>
                <SharingNewContent {...props} />
            </Content>
        </Dialog>
    );
});

export const SharingNewPhone = memo(function SharingNewPhone(props: IPropsWithPopup<IProps>): ReactElement {
    const scrollRef = useRef(null);

    return (
        <MobileDialog id="sharing-new" closeOnDimmerClick mod="base" topmost onClose={props.onClose} scrollRef={scrollRef}>
            <SharingNewContent {...props} isPhone ref={scrollRef} />
        </MobileDialog>
    );
});

export const SharingNewBiz = memo(function SharingNew(props: IPropsWithPopup<IProps>): ReactElement {
    const isPhone = EnvironmentSelectors.isPhone();
    const Component = isPhone ? SharingNewPhone : SharingNewDesktop;
    return <Component {...props} />;
});
