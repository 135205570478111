import {
    defaultPayMethod,
    defaultPayMethodTouch,
    isDmrReactUrlEnabled,
    isDmrReactUrlTouchEnabled,
} from 'reactApp/appHelpers/featuresHelpers';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';

export const getABReactPaymentLink = (url: string, isMobile = false) => {
    const isNewUrlEnabled = (isMobile && isDmrReactUrlTouchEnabled) || (!isMobile && isDmrReactUrlEnabled);

    if (!isNewUrlEnabled) {
        return url;
    }

    return url?.replace(/\/\/pw\.money\.mail\.ru\/pw\/[\d-]+\//, '//checkout.vkpay.io/');
};

export const getPayMethod = (isMobile) => {
    const { pay_method: payMethod } = getQueryParams();

    if (payMethod) {
        return payMethod;
    }

    if (defaultPayMethod && !isMobile) {
        return defaultPayMethod;
    }

    if (defaultPayMethodTouch && isMobile) {
        return defaultPayMethodTouch;
    }
};
