import React, { memo, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { sendFilesHistoryGa } from 'reactApp/ui/FileHistory/FileHistory.helpers';
import { EDialogsType, IRecoveryProps } from 'reactApp/ui/FileHistory/FileHistory.types';

import styles from './RecoveryDialog.css';

export const RecoveryDialog = memo(
    ({ onClose, fileName, onSuccess, type }: IRecoveryProps): ReactElement => {
        const renderHeader = useCallback(() => <>Восстановление</>, []);

        const renderContent = useCallback(
            () => (
                <div className={styles.content}>
                    {type === EDialogsType.rewrite ? 'Перезаписать' : 'Создать копию'} «{fileName}»?
                </div>
            ),
            [fileName, type]
        );

        const buttonText = useMemo(() => (type === EDialogsType.rewrite ? 'Перезаписать' : 'Создать копию'), [type]);

        const handleSuccess = useCallback(() => {
            sendFilesHistoryGa('click', `${type}-modal`);
            onSuccess(type === EDialogsType.rewrite);
            onClose();
        }, [onClose, onSuccess, type]);

        const handleClose = useCallback(() => {
            sendFilesHistoryGa('close', `${type}-modal`);
            onClose();
        }, [onClose, type]);

        useEffect(() => {
            sendFilesHistoryGa('show', `${type}-modal`);
        }, []);

        return (
            <BaseConfirmDialog
                dataQAId={`file-history-${type}`}
                renderContent={renderContent}
                renderHeader={renderHeader}
                buttons={[EButtonType.accept, EButtonType.cancel]}
                successButtonText={buttonText}
                onSuccess={handleSuccess}
                onClose={handleClose}
            />
        );
    }
);

RecoveryDialog.displayName = 'RecoveryDialog';
