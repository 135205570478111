import classNames from 'clsx';
import throttle from 'lodash.throttle';
import React, { createRef, PureComponent, ReactElement } from 'react';

import styles from './StickyComponent.css';

interface Props {
    fixedContentClassName: string;
    renderContent(isPinned?: boolean);
    /* offset for sticky object */
    offset?: number;
    pinToTop?: boolean;
    initialPin?: boolean;
    fullHeight?: boolean;
}

interface State {
    fixed: boolean;
    height?: number;
}

export class StickyComponent extends PureComponent<Props, State> {
    public static defaultProps = {
        fixedClassName: '',
        offset: 0,
        pinToTop: false,
        fullHeight: false,
        initialPin: true,
    };

    public state = {
        fixed: Boolean(this.props.initialPin),
        height: 0,
    };

    private placeholderRef = createRef<HTMLDivElement>();
    private contentRef = createRef<HTMLDivElement>();

    private handlerChange = throttle(() => {
        const placeholderEl = this.placeholderRef?.current;
        const contentEl = this.contentRef?.current;

        if (!placeholderEl) {
            return;
        }

        const posInViewport = this.getElementPosInViewport(placeholderEl, contentEl);
        const fixed = !posInViewport.isFixed;
        this.setState({ fixed, height: posInViewport.maxHeight });
    }, 50);

    componentDidMount() {
        window.addEventListener('scroll', this.handlerChange, false);
        window.addEventListener('resize', this.handlerChange, false);

        if (this.props.fullHeight) {
            this.handlerChange();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handlerChange);
        window.removeEventListener('resize', this.handlerChange);
    }

    private getElementPosInViewport(el, contentEl): { isFixed: boolean; maxHeight?: number } {
        if (!el?.getBoundingClientRect) {
            return { isFixed: false };
        }

        const { offset = 0, pinToTop } = this.props;

        const rect = el.getBoundingClientRect();
        const viewHeight = window.innerHeight || document.documentElement.clientHeight;

        if (pinToTop) {
            const rectContent = contentEl?.getBoundingClientRect?.() || {};
            return {
                isFixed: rect.top - offset >= 0,
                maxHeight: viewHeight - Math.floor(rectContent?.top) - offset,
            };
        }

        // Если элемент - sticky к низу и скроллим ниже, чем контейнер элемента
        if (rect.top < 0) {
            return {
                isFixed: true,
            };
        }

        return {
            isFixed:
                rect.top >= 0 &&
                rect.left >= 0 &&
                Math.floor(rect.bottom) + offset <= viewHeight &&
                Math.floor(rect.right) <= (window.innerWidth || document.documentElement.clientWidth),
        };
    }

    public render(): ReactElement | null {
        const { fixedContentClassName, renderContent, pinToTop, fullHeight } = this.props;
        const { fixed, height } = this.state;

        const content = renderContent(fixed);

        if (pinToTop) {
            const style = {
                maxHeight: 'initial',
            };

            if (fullHeight) {
                style.maxHeight = height ? `${height}px` : 'initial';
            }

            return (
                <>
                    <div ref={this.placeholderRef} className={classNames(styles.root, styles.root_hidden)}>
                        <div className={styles.placeholder} />
                    </div>
                    <div
                        ref={this.contentRef}
                        className={classNames({
                            [styles.root]: true,
                            [styles.root_fixed]: fixed,
                            [styles.root_margin]: !fixed,
                            [styles.root_top]: pinToTop,
                            [fixedContentClassName]: !!fixedContentClassName,
                        })}
                        style={style}
                    >
                        {content}
                    </div>
                </>
            );
        }

        return (
            <>
                <div
                    ref={this.placeholderRef}
                    className={classNames({
                        [styles.root]: true,
                        [styles.root_hidden]: fixed,
                    })}
                >
                    {content}
                </div>
                <div
                    className={classNames({
                        [styles.root]: true,
                        [styles.root_fixed]: fixed,
                        [styles.root_hide]: !fixed,
                        [styles.root_top]: pinToTop,
                        [fixedContentClassName]: !!fixedContentClassName,
                    })}
                >
                    {content}
                </div>
            </>
        );
    }
}
