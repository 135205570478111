import { createAction } from '@reduxjs/toolkit';

import { IState, SetIsLoadingAction } from './user.types';

export const setUser = createAction<any>('user/setUser');
export const destroyUser = createAction('user/destroyUser');
export const setNewUser = createAction('user/setNewUser');
export const setUserData = createAction<any>('user/setUserData');
export const setIsLoading = createAction<SetIsLoadingAction>('user/setIsLoading');
export const setError = createAction<boolean>('user/setError');
export const setUserState = createAction<IState>('user/setUserState');
