import React from 'react';
import { ButtonLink } from 'reactApp/ui/ButtonLink/ButtonLink';

// eslint-disable-next-line react/display-name
export default () => {
    return (
        <span>
            В&nbsp;случае возникновения проблем с&nbsp;оплатой вы&nbsp;можете обратиться в&nbsp;
            <ButtonLink
                inline
                href="https://help.mail.ru/cloud_web/size/increase#trouble"
                target="_blank"
                rel="noopener noreferrer"
                primary
                size="big"
            >
                службу поддержки
            </ButtonLink>
            .
        </span>
    );
};
