import { useEffect } from 'react';
import { PostMessagesNames } from 'reactApp/modules/integration/communicator';
import { closeViewer } from 'reactApp/modules/viewer/viewer.module';
import { dispatch } from 'reactApp/react-into-toolkit-block-wrapper/renderComponentInExternalDom';

export const useIntegrationMessageHandler = () => {
    const handleMessage = ({ data, origin }: MessageEvent) => {
        if (origin !== window.location.origin) {
            return;
        }
        if (!data.__cloudIntegrationMessage) {
            return;
        }
        if (data.name === PostMessagesNames.closeViewer) {
            dispatch(closeViewer());
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);
};
