/* eslint-disable max-lines-per-function */
import {
    Icon20ArrowLeftOutline,
    Icon20Cancel,
    Icon20MenuOutline,
    Icon20MoreVertical,
    Icon20Rectangle2HorizontalOutline,
    Icon20Search,
    Icon20SlidersOutline,
    Icon20Square4Outline,
} from '@vkontakte/icons';
import classNames from 'clsx';
import CloudWhiteIcon from 'img/portal-menu/cloud-white.svg?url';
import throttle from 'lodash.throttle';
import React, { memo, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { ACTION_PROMO, HIDE_ADS } from 'reactApp/appHelpers/configHelpers';
import { isDarkTheme } from 'reactApp/appHelpers/featuresHelpers';
import { useFallbackSearchUrl } from 'reactApp/hooks/useFallbackSearchUrl';
import { isDefaultRootContent } from 'reactApp/modules/home/home.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { sendSearchInputClickRadar } from 'reactApp/modules/search/search.analytics';
import { searchReset } from 'reactApp/modules/search/search.module';
import { getSearchRequestParams } from 'reactApp/modules/search/search.selectors';
import { EActionSearch } from 'reactApp/modules/search/search.types';
import { resetSelect, startSelecting, toggleAll } from 'reactApp/modules/selections/selections.actions';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { setViewMode } from 'reactApp/modules/settings/settings.module';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import {
    getIds,
    getIsYearFilter,
    getStorageCurrentFolder,
    getStorageSelectedItems,
    isEmptyFolder,
} from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { setShowUploaderAction } from 'reactApp/modules/uploadList/uploadList.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { TABS_MAP } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscriptionsPage.constants';
import { sendQuotaCleanerGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { RootState, store as reduxStore } from 'reactApp/store';
import { Button } from 'reactApp/ui/Button/Button';
import { renderMobileLeftMenu } from 'reactApp/ui/Mobile/LeftMenu/LeftMenu.helpers';
import { Logo } from 'reactApp/ui/Mobile/Logo/Logo';
import { OptionsModalSource } from 'reactApp/ui/Mobile/OptionsModal/OptionsModal.constants';
import { renderMobileOptionsModal } from 'reactApp/ui/Mobile/OptionsModal/OptionsModal.helpers';
import { renderSubscriptionsModal } from 'reactApp/ui/Mobile/SubscriptionsModal/SubscriptionsModal.helpers';
import { renderMobileUserLoginMenu } from 'reactApp/ui/Mobile/UserLoginMenu/UserLoginMenu.helpers';
import { renderYearFilterModal } from 'reactApp/ui/Mobile/YearFilterModal/YearFilterModal.helpers';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './Toolbar.css';

const TOP_PADDING = HIDE_ADS ? 10 : 98;

interface IProps {
    disableActions?: boolean;
    showSelection?: boolean;
    showViewChange?: boolean;
    showOptions?: boolean;
    showSearch?: boolean;
    hideLogo?: boolean;
    showBack?: boolean;
    showMenu?: boolean;
    showSubscriptions?: boolean;
    showCloseArrow?: boolean;
    onCloseArrow?: () => void;
    showQuotaCleanerSelecting?: boolean;
    optionHeaderText?: string;
    isItemInfoHidden?: boolean;
}

export const Toolbar = memo(
    ({
        disableActions = false,
        showMenu = false,
        showSelection = true,
        showViewChange = false,
        showSearch = true,
        hideLogo = false,
        showBack = false,
        showOptions = true,
        showSubscriptions = false,
        showCloseArrow = false,
        onCloseArrow,
        showQuotaCleanerSelecting = false,
        optionHeaderText,
        isItemInfoHidden,
    }: IProps): ReactElement => {
        const dispatch = useDispatch();

        const storage = useSelector(getCurrentStorage);
        const folder = useSelector((state: RootState) => getStorageCurrentFolder(state, storage));

        const ids = useSelector((state: RootState) => getIds(state, storage));
        const selectedIds = useSelector(SelectionsSelectors.getSelectedIdxs);
        const isAllSelected = ids.length === selectedIds.length;
        const isSelecting = useSelector(SelectionsSelectors.isSelecting);
        const isDefaultContent = useSelector(isDefaultRootContent);
        const isEmpty = useSelector(isEmptyFolder);
        const isEmptyContent = isDefaultContent || isEmpty;
        const viewMode = useSelector((state) => SettingsSelectors.getViewByStorage(state, storage));
        const isList = viewMode === EViewMode.list;
        const { path, query, xPageId, xReqId } = useSelector(getSearchRequestParams);
        const { startSearch, closeSearch } = useFallbackSearchUrl();
        const isYearFilter = useSelector(getIsYearFilter);
        const isQuotaCleaner = storage === EStorageType.quotaCleaner;
        const isAnonymous = useSelector(UserSelectors.isAnonymous);
        const state = reduxStore.getState();
        const allSelectedIdx = getStorageSelectedItems(state, storage);
        const isDeselectAll = ids?.length && ids?.length === allSelectedIdx?.length;

        const location = useLocation();
        const isAction =
            Boolean(ACTION_PROMO) && storage === EStorageType.subscriptions && (!location.hash || location.hash === TABS_MAP.special);

        useEffect(() => {
            if (allSelectedIdx?.length > 0 && allSelectedIdx?.length !== ids?.length) {
                sendQuotaCleanerGa({ action: 'one-file-select' });
            }
        }, [allSelectedIdx]);

        const openOptions = useCallback(() => {
            if (folder) {
                dispatch(setShowUploaderAction(false));
                renderMobileOptionsModal({ id: folder?.id, isItemInfoHidden, optionHeaderText, source: OptionsModalSource.TOOLBAR });
            }
        }, [dispatch, folder]);

        const openYearFilter = useCallback(() => {
            renderYearFilterModal({});
        }, []);

        const [isSticky, setSticky] = useState<boolean>(false);

        const onScroll = useCallback(() => setSticky(window.scrollY > TOP_PADDING), []);

        const throttledScroll = throttle(onScroll, 100);

        const onToggleView = useCallback(() => {
            const type = isList ? EViewMode.thumbs : EViewMode.list;
            dispatch(setViewMode({ viewMode: type, storage }));
        }, [isList, dispatch, storage]);

        const onStartSelecting = useCallback(() => {
            dispatch(setShowUploaderAction(false));
            dispatch(startSelecting());
        }, [dispatch]);

        const onResetSelections = useCallback(() => {
            dispatch(resetSelect());
        }, [dispatch]);

        const onSelectAll = useCallback(() => {
            if (!isAllSelected) {
                sendPaymentGa({
                    eventCategory: ECategoryGa.toolbar,
                    action: 'select_all',
                    source: storage,
                });
            }

            if (isQuotaCleaner) {
                const prefix = isDeselectAll ? 'all-files-select-cancel' : 'all-files-select';
                sendQuotaCleanerGa({ action: prefix, dwh: { count_select_files: ids?.length } });
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dispatch(toggleAll({ allIdxs: ids, storage }));
        }, [dispatch, ids, storage, isDeselectAll]);

        const onSearchClick = useCallback(() => {
            dispatch(searchReset());
            startSearch();
            sendSearchInputClickRadar({ storage, path });
        }, [dispatch, startSearch, storage, path]);

        const onSearchClose = useCallback(() => {
            let dwhData: {
                place: string;
                search_phrase?: string;
                page_id?: string;
                req_id?: string;
            } = { place: 'touch' };
            if (query) {
                dwhData = {
                    ...dwhData,
                    search_phrase: query,
                    page_id: xPageId,
                    req_id: xReqId,
                };
            }
            sendDwh({
                eventCategory: ECategoryGa.search,
                action: EActionSearch.searchClose,
                dwhData,
            });
            closeSearch();
        }, [closeSearch, query, xPageId, xReqId]);

        const onMenuClick = useCallback(() => {
            if (isAnonymous) {
                renderMobileUserLoginMenu();
            } else {
                renderMobileLeftMenu({ animated: true });
            }
        }, [isAnonymous]);

        useEffect(() => {
            onResetSelections();
        }, [folder?.id, onResetSelections]);

        useEffect(() => {
            document.addEventListener('scroll', throttledScroll);

            return () => document.removeEventListener('scroll', throttledScroll);
        }, [throttledScroll]);

        const isShowSearch = useMemo(() => showSearch && storage !== EStorageType.public, [showSearch, storage]);

        const left = useMemo(
            () => (
                <>
                    {showCloseArrow && (
                        <div className={classNames(styles.button, styles.button_square)} onClick={onCloseArrow} data-qa-button={'back'}>
                            <Icon20ArrowLeftOutline />
                        </div>
                    )}

                    {isSelecting && (
                        <div className={styles.button} onClick={onSelectAll} data-qa-button={isAllSelected ? 'cancelSelect' : 'selectAll'}>
                            <div>{isAllSelected ? 'Отменить выбор' : 'Выбрать все'}</div>
                        </div>
                    )}

                    {!isSelecting && (
                        <>
                            {showBack && (
                                <div
                                    className={classNames(styles.button, styles.button_square)}
                                    data-qa-button="closeSearch"
                                    onClick={onSearchClose}
                                >
                                    <Icon20Cancel />
                                </div>
                            )}

                            {showMenu && (
                                <div className={styles.menu} onClick={onMenuClick} data-qa-button={'menu'}>
                                    <Icon20MenuOutline
                                        width={16}
                                        height={16}
                                        fill={isAction ? 'var(--vkui--color_text_contrast)' : 'currentColor'}
                                    />
                                </div>
                            )}

                            {!hideLogo && <Logo logoSrc={isAction || isDarkTheme ? CloudWhiteIcon : undefined} />}

                            {showSubscriptions && (
                                <Button className={styles.button} onClick={renderSubscriptionsModal}>
                                    Мои подписки
                                </Button>
                            )}
                        </>
                    )}
                </>
            ),
            [isSelecting, onSelectAll, isAllSelected, showMenu, showSubscriptions, isAction]
        );

        const right = useMemo(
            () => (
                <>
                    {isYearFilter && (
                        <div
                            className={classNames(styles.button, styles.button_square)}
                            onClick={openYearFilter}
                            data-promo-id={'quota-cleaner-year-filter'}
                            data-qa-button={'quota-cleaner-year-filter'}
                        >
                            <Icon20SlidersOutline />
                        </div>
                    )}

                    {isSelecting && !showQuotaCleanerSelecting && (
                        <div className={styles.button} onClick={onResetSelections} data-qa-button={'cancel'}>
                            <div>Отменить</div>
                        </div>
                    )}
                    {!isSelecting && !showQuotaCleanerSelecting && (
                        <>
                            {showSelection && (
                                <div className={styles.button} onClick={onStartSelecting} data-qa-button={'select'}>
                                    <div>Выбрать</div>
                                </div>
                            )}
                            {showViewChange && (
                                <div
                                    className={classNames(styles.button, styles.button_square)}
                                    data-qa-button={'change-view'}
                                    onClick={onToggleView}
                                >
                                    {isList ? <Icon20Rectangle2HorizontalOutline /> : <Icon20Square4Outline />}
                                </div>
                            )}
                            {isShowSearch && (
                                <div
                                    className={classNames(styles.button, styles.button_square)}
                                    onClick={onSearchClick}
                                    data-qa-button="search"
                                >
                                    <Icon20Search />
                                </div>
                            )}
                        </>
                    )}

                    {showOptions && (!isSelecting || showQuotaCleanerSelecting) && (
                        <div className={classNames(styles.button, styles.button_icon)} onClick={openOptions} data-qa-button={'options'}>
                            <Icon20MoreVertical width={20} height={20} fill="#currentColor" />
                        </div>
                    )}
                </>
            ),
            [isList, isSelecting, onResetSelections, onStartSelecting, onToggleView, openOptions, showOptions]
        );

        return (
            <div
                className={classNames(styles.root, {
                    [styles.root_sticky]: isSticky,
                    [styles.root_action]: isAction,
                })}
            >
                <div className={styles.left}>{left}</div>
                {!isEmptyContent && !disableActions && <div className={styles.right}>{right}</div>}
            </div>
        );
    }
);

Toolbar.displayName = 'Toolbar';
