import { filter, propEq,reduce } from 'ramda';
import { TreeAddFolder, TreeFetch } from 'reactApp/modules/tree/tree.constants';

enum TreeItemKind {
    folder = 'folder',
}

interface TreeItem {
    id: string;
    parent: string;
    name: string;
    weblink: string;
    home: string;
    kind: TreeItemKind;
    list: TreeItem[];
    count: { folders: number; files: number };
}

const normalizeTree = (tree: TreeItem[], parentNode?: string) => {
    if (!tree.length) {
        return;
    }

    const pathId = tree[0].home ? 'home' : 'weblink';

    return reduce(
        (acc, cur) => {
            const id = cur[pathId]; // home || weblink
            let parent = parentNode || cur.parent;

            if (!parent) {
                const split = id.split(/\//);

                // первым идем storage, с id = '/', что уже является корнем
                if (split[0]) {
                    // если длина массива 2, значит это самая верняяя директория, родителем которой является storage
                    if (split.length === 2) {
                        parent = '/';
                    } else {
                        split.pop();
                        parent = split.join('/');
                    }
                }
            }

            const list = filter(propEq('kind', 'folder'), cur.list || []);

            return {
                ...acc,
                [id]: {
                    kind: cur.kind,
                    id: cur[pathId],
                    count: cur.count,
                    parent,
                    name: cur.name,
                    list: list.map(child => child[pathId]),
                },
                ...normalizeTree(list, id),
            };
        },
        {},
        tree
    );
};

export const treeReducer = (state = {}, action) => {
    switch (action.type) {
        case TreeFetch.SUCCESS:
            return {
                ...state,
                ...normalizeTree(action.payload),
            };
        case TreeAddFolder.ADD:
            return {
                ...state,
                ...normalizeTree([action.payload]),
            };
        default:
            return state;
    }
};
