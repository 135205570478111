/**
 * Allows to require public namespace "app" as module:
 * var app = require('Cloud/Application/app');
 */
define(function (require, exports, module) {
    'use strict';

    if (process.env.SSR) {
        return;
    }

    var MicroEvent = require('lib/microevent');
    var v2mock = require('Cloud/API/mock.v2');
    var config = require('Cloud/config');
    var settings = require('Cloud/settings');

    var app = {
        settings: settings,
        extend: function (obj) {
            Object.assign(app, obj);
        },
        ui: [],
    };

    var userEmail = config.get('user.email');

    MicroEvent.mixin(app);

    app.apiMock = v2mock(userEmail);

    //TODO: убрать везде app.params
    app.extend(require('Cloud/Application/Params'));
    //TODO: убрать везде app.features
    app.extend(require('Cloud/Application/Features')());
    //TODO: убрать везде app.errorList
    app.extend(require('Cloud/Application/ErrorList'));

    module.exports = app;
});
