import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';

export interface IState {
    dialogIdxs: string[];
    isDropdownOpen: boolean;
}

const INITIAL_STATE: IState = {
    dialogIdxs: [],
    isDropdownOpen: false,
};

export const showDialog = createAction<string | undefined>('showDialog/show');
export const hideDialog = createAction<string | undefined>('showDialog/hide');
export const dropdownOpen = createAction<boolean>('dropdownOpen');

export const dialogReducer = createReducer(INITIAL_STATE, {
    [showDialog.type]: (state, action: PayloadAction<string>) => {
        const id = action.payload ?? '';
        state.dialogIdxs.push(id);
    },
    [hideDialog.type]: (state, action: PayloadAction<string>) => {
        const id = action.payload ?? '';
        const idIdx = state.dialogIdxs.lastIndexOf(id);
        if (idIdx >= 0) {
            state.dialogIdxs.splice(idIdx, 1);
        } else {
            state.dialogIdxs.pop();
        }
    },
    [dropdownOpen.type]: (state, action: PayloadAction<boolean>) => {
        state.isDropdownOpen = action.payload;
    },
});
