export const GiftFetch = {
    NAME: '@gift/fetch',
    REQUEST: '@gift/fetch/request',
    LOADING: '@gift/fetch/loading',
    SUCCESS: '@gift/fetch/success',
    FAILURE: '@gift/fetch/failure',
};

export const GiftBuy = {
    NAME: '@gift/buy',
    REQUEST: '@gift/buy/request',
    LOADING: '@gift/buy/loading',
    SUCCESS: '@gift/buy/success',
    FAILURE: '@gift/buy/failure',
    RESET: '@gift/buy/reset',
};
