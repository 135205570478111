import React, { memo, ReactElement, useMemo } from 'react';
import { areEqual, ListChildComponentProps } from 'react-window';

import styles from './VirtualList.css';

export const VirtualListGalleryRowMobile = memo(({ index, style, data }: ListChildComponentProps) => {
    const { list, width, margin, height, itemActiveIndex, indexes } = data;

    const startIndex = indexes[index].start;
    const lastIndex = Math.min(indexes[index].end, list.length);
    const handleRef = index === itemActiveIndex ? data.handleRef : null;
    const isDivider = list[startIndex].divider;

    const content = useMemo(() => {
        const output: ReactElement[] = [];

        for (let i = startIndex; i <= lastIndex; i++) {
            if (list[i]?.divider) {
                continue;
            }
            const key = String(typeof list[i] === 'string' ? list[i] : list[i].id);
            const itemStyle = { width: `${width}px`, marginRight: i === lastIndex ? 0 : `${margin}px`, height: `${height}px` };

            output.push(
                <div key={key + i} style={itemStyle}>
                    <div className={styles.colItem}>{data.renderItem(list[i], { index: i })}</div>
                </div>
            );
        }

        return output;
    }, [startIndex, lastIndex, height, list, data, margin, width]);

    if (isDivider) {
        return (
            <div key={index} className={styles.dividerContainer} style={style} data-qa-divider={list[startIndex].text}>
                <div className={styles.divider}>{list[startIndex].text}</div>
            </div>
        );
    }

    return (
        <div style={style} className={styles.gridRow} ref={handleRef}>
            {content}
        </div>
    );
}, areEqual);

VirtualListGalleryRowMobile.displayName = 'VirtualListGalleryRowMobile';
