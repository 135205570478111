import { clearItemFromLocalStorage, getItemFromLocalStorage, setItemFromLocalStorage } from './localstorageHelpers';

const LOCAL_STORAGE_KEY = 'non-editable-file-notify' as const;

export const enum NotifyFrom {
    home = 'home',
    sharedHome = 'sharedHome',
}

export type NotifyMeta = {
    oldId: string;
    newId: string;
    from: NotifyFrom;
};

export const getNotificationAboutCreatingCopyOfNoneditableFile = () => getItemFromLocalStorage(LOCAL_STORAGE_KEY);

export const setNotificationAboutCreatingCopyOfNoneditableFile = (init: NotifyMeta) =>
    setItemFromLocalStorage<typeof init>(LOCAL_STORAGE_KEY, init);

export const clearNotificationAboutCreatingCopyOfNoneditableFile = () => clearItemFromLocalStorage(LOCAL_STORAGE_KEY);
