export class StackLimitedCache<T> {
    private readonly maxCacheCount;
    private itemsCache: Map<string, T> = new Map<string, T>();
    private idxsStack: string[] = [];

    public constructor(maxCacheCount = 10) {
        this.maxCacheCount = maxCacheCount;
    }

    getItem = (id: string): T | undefined => {
        return this.itemsCache.get(id);
    };

    updateItem = (id: string, item: T) => {
        if (!this.getItem(id)) {
            return;
        }
        this.itemsCache.set(id, item);
    };

    addItem = (id: string, item: T) => {
        if (this.getItem(id)) {
            return;
        }
        this.idxsStack.push(id);
        this.itemsCache.set(id, item);
        if (this.idxsStack.length > this.maxCacheCount) {
            const id2Del = this.idxsStack.shift();
            if (id2Del) {
                this.itemsCache.delete(id2Del);
            }
        }
    };

    deleteItem = (id: string) => {
        if (this.getItem(id)) {
            this.itemsCache.delete(id);
        }
    };

    getAll = (): { key: string; item: T }[] =>
        Array.from(this.itemsCache).map(([key, item]) => ({
            key,
            item,
        }));

    clear = () => {
        this.itemsCache.clear();
        this.idxsStack = [];
    };
}
