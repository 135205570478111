import debounce from 'lodash.debounce';
import { ChangeEvent, KeyboardEvent, ReactElement, useCallback, useMemo, useState } from 'react';
import { IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';
import { ListItem } from 'reactApp/ui/DropdownList/DropdownList';
import { TUseCallbacks } from 'reactApp/ui/InputSuggest/InputSuggest.types';

const DROPDOWN_PADDING = 16 * 2;
const DROPDOWN_LEFT_MARGIN = 16;

export const useCallbacks: TUseCallbacks = ({
    suggestions,
    onChange,
    getSuggestionItemId,
    inputRef,
    rootRef,
    renderSuggestionItem,
    onRequestSuggestions,
    disabled,
}) => {
    const [selectedSuggestionId, setSelectedSuggestionId] = useState<number>(-1);
    const [shouldDropdownClose, setDropdownClose] = useState<boolean>(true);

    const onItemClick = useCallback(
        (id: string) => {
            onChange(null, { value: id });
        },
        [onChange]
    );

    const list: ListItem[] = useMemo(
        () =>
            suggestions?.map((item, index) => ({
                id: getSuggestionItemId(item),
                onClick: onItemClick,
                isSelected: index === selectedSuggestionId,
                ...item,
            })) || [],
        [getSuggestionItemId, onItemClick, selectedSuggestionId, suggestions]
    );

    const showDropdown = Boolean(list?.length) && !shouldDropdownClose && !disabled;

    const handleInputKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (!list?.length) {
                return;
            }

            switch (event.key) {
                case 'ArrowUp':
                    if (selectedSuggestionId >= 0) {
                        setSelectedSuggestionId(selectedSuggestionId - 1);
                    }
                    event.preventDefault();
                    break;
                case 'ArrowDown':
                    if (selectedSuggestionId < list.length - 1) {
                        setSelectedSuggestionId(selectedSuggestionId + 1);
                    }
                    event.preventDefault();
                    break;
                case 'Enter':
                    if (selectedSuggestionId < list.length && selectedSuggestionId >= 0) {
                        onChange(null, { value: list[selectedSuggestionId].id });
                        setDropdownClose(true);
                        event.preventDefault();
                    }
                    break;
                default:
                    setSelectedSuggestionId(-1);
                    break;
            }
        },
        [list, onChange, selectedSuggestionId]
    );

    const calcDropdownSize = useCallback(() => {
        if (!inputRef?.current || !rootRef?.current) {
            return 0;
        }

        const { width: inputWidth } = inputRef.current.getBoundingClientRect();
        const { width: rootWidth } = rootRef.current.getBoundingClientRect();

        return IS_MY_TEAM ? rootWidth : inputWidth;
    }, []);

    const renderItem = useCallback(
        (item: ListItem): ReactElement | null => {
            const dropdownWidth = calcDropdownSize();
            const responsiveWidth = IS_MY_TEAM ? dropdownWidth - DROPDOWN_LEFT_MARGIN : dropdownWidth;
            return renderSuggestionItem(item, Math.max(0, responsiveWidth - DROPDOWN_PADDING));
        },
        [renderSuggestionItem]
    );

    const calcDropdownPosition = useCallback(() => {
        if (!inputRef?.current) {
            return { posX: 0, posY: 0 };
        }

        const { top, left, height } = inputRef.current.getBoundingClientRect();

        return {
            posY: top + height + window.scrollY,
            posX: left,
        };
    }, []);

    const requestSuggestions = debounce((value) => onRequestSuggestions(value), 250);

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target || {};

            setDropdownClose(false);
            onChange(event, { value });
            requestSuggestions(value);
        },
        [onChange, requestSuggestions]
    );

    const closeDropdown = () => setDropdownClose(true);

    return { handleInputKeyDown, closeDropdown, handleChange, calcDropdownPosition, calcDropdownSize, renderItem, showDropdown, list };
};
