import { EProductPeriod } from 'reactApp/modules/products/products.types';

const SCREEN_WIDTH_FOR_ONE_CARDS = 600;
const SCREEN_WIDTH_FOR_TWO_CARDS = 800;
const SCREEN_WIDTH_FOR_THREE_CARDS = 1300;

export const carouselBreakpoints = [
    {
        maxWidth: SCREEN_WIDTH_FOR_ONE_CARDS,
        showCount: 1,
    },
    {
        maxWidth: SCREEN_WIDTH_FOR_TWO_CARDS,
        showCount: 2,
    },
    {
        maxWidth: SCREEN_WIDTH_FOR_THREE_CARDS,
        showCount: 3,
    },
];

export const baseYearProduct = {
    price: 0,
    id: 'base_year',
    period: EProductPeriod.year,
    available: true,
    isPromo: false,
    hasTrial: false,
    hasDiscount: false,
    turbo: false,
    isBase: true,
};
