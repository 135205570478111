import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useState } from 'react';
import { getItemNameWithoutExt } from 'reactApp/modules/file/utils';
import { Kind } from 'reactApp/types/Tree';
import { ImageCancelable } from 'reactApp/ui/ImageCancelable/ImageCancelable';
import { Stub } from 'reactApp/ui/ReactViewer/Stub/Stub';
import { Spinner } from 'reactApp/ui/Spinner/Spinner';
import { sendGa } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';

import styles from './MobileViewerItem.css';

interface Props {
    src: string;
    name: string;
    ext: string;
    kind?: Kind;
    isSingle?: boolean;
    isVirus?: boolean;

    onError?(): void;
}

export const MobileViewerImg = memo(({ name, src, ext, kind, isSingle, isVirus = false, onError = noop }: Props): ReactElement | null => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const nameWithoutExt = getItemNameWithoutExt(name, ext);

    const handleImageLoad = useCallback(() => {
        setLoaded(true);
    }, []);

    const handleImageError = useCallback(() => {
        sendGa('mobile-viewer', 'load-image', 'error');
        setError(true);
        setLoaded(true);
        onError();
    }, [onError]);

    return (
        <>
            {!error && (
                <ImageCancelable
                    key={src}
                    className={classNames({
                        [styles.image]: true,
                        [styles.imageHidden]: !loaded,
                    })}
                    src={src}
                    alt={name}
                    onError={handleImageError}
                    onLoad={handleImageLoad}
                />
            )}
            {error && (
                <div className={styles.iconBlock}>
                    <Stub
                        ext={ext}
                        isVirus={isVirus}
                        name={nameWithoutExt}
                        kind={kind || 'file'}
                        mode={isSingle ? 'light' : 'dark'}
                        size={isSingle ? 'medium' : 'big'}
                        showName={isSingle}
                    />
                </div>
            )}
            {!loaded && <Spinner />}
        </>
    );
});

MobileViewerImg.displayName = 'MobileViewerImg';
