import { logger } from 'lib/logger';
import { xray } from 'lib/xray';
import { GetFamilyAPICall } from 'reactApp/api/family/GetFamilyAPICall';
import { familyUpdate, familyUpdateFailure } from 'reactApp/modules/family/family.actions';
import { getCurrentFamily } from 'reactApp/modules/family/family.selectors';
import ping from 'reactApp/utils/ping';
import { put, select } from 'typed-redux-saga';

const getFamilyAPICall = () => new GetFamilyAPICall().makeRequest(null, { url: '?add_avatar=1' });

export function* updateFamilySaga() {
    const initialState = yield* select(getCurrentFamily);

    try {
        const { data } = yield ping({
            request: getFamilyAPICall,
            check: ({ data }) => {
                const newFamily = data?.[0];
                return newFamily && newFamily.quota !== initialState?.quota;
            },
        });

        xray.send('family_update_success');
        yield put(familyUpdate(data?.[0]));
    } catch (error) {
        xray.send('family_update_failure');
        yield put(familyUpdateFailure());
        logger.error(error);
    }
}
