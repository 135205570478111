import { extInfo } from 'lib/extInfo';
import { getExtension } from 'reactApp/modules/file/helpers/getExtention';
import { MalwareStatus } from 'reactApp/modules/start/start.types';

export const ITEM_WITHOUT_HASH_MAX_SIZE = 20; /* CLOUDWEB-6507: thumbnails are available only for items whose size is larger than 20 bytes */

export const isPreviewable = (item) => {
    if (!item) {
        return false;
    }

    if (item.virus_scan === 'fail' || item.malware?.status === MalwareStatus.infected) {
        return false;
    }

    if (item.size <= ITEM_WITHOUT_HASH_MAX_SIZE) {
        return false;
    }

    const extParams = extInfo.get(getExtension(item));
    const maxSize = extParams.maxPreviewableSize || 0;

    return item.size <= maxSize;
};
