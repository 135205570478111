import { FC, RefObject } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { STORAGE_CONFIG } from 'reactApp/modules/storage/storage.config';
import { getStorageSelectedItems } from 'reactApp/modules/storage/storage.selectors';
import { CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { RootState } from 'reactApp/store';
import { getQuotaDeleteItem } from 'reactApp/ui/Mobile/ActionsBar/customActions/getQuotaDeleteItem';

export type ActionbarItemProps = Record<string, any> & { parentRef?: RefObject<HTMLElement> };
export type ActionbarItem = FC<ActionbarItemProps>;
export type TActionbarItem = { Item?: ActionbarItem | null; itemProps?: ActionbarItemProps };
export const useCustomActions = ({ storage }: { storage: EStorageType }) => {
    const customActions: TActionbarItem[] = [];
    const items: CloudItem[] = useSelector((state: RootState) => getStorageSelectedItems(state, storage)) as CloudItem[];
    const currentStorage = useSelector(getCurrentStorage);
    const settings = STORAGE_CONFIG[storage || currentStorage];

    if (!settings) {
        return { customActions };
    }

    if (settings.quotaCleanerDelete) {
        customActions.push(getQuotaDeleteItem(storage, items));
    }

    return { customActions };
};
