import { useIntersectionObserver } from 'reactApp/hooks/useInterSectionObserver';

const mobileThreshold = 0.3;
const desktopThreshold = 0.5;

export const useIntersecObsrWithParams = ({
    onIntersecting,
    isMobile,
    headerHeight,
}: {
    onIntersecting: () => void;
    isMobile: boolean;
    headerHeight?: number;
}) =>
    useIntersectionObserver({
        onIntersecting,
        rootMargin: `${headerHeight || 0}px`,
        threshold: isMobile ? mobileThreshold : desktopThreshold,
    });
