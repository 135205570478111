import { UploadFail } from 'reactApp/modules/uploading/fails/UploadFail';

export class PathTooLongFail extends UploadFail {
    static WEB_BACKEND_ERROR = 'name_too_long';

    radarName = 'fail_{SOURCE}_path-too-long';
    message = [
        'Превышена длина пути.',
        '<a href="https://help.mail.ru/cloud_web/confines" rel="noopener" target="_blank">Подробнее</a>',
    ].join(' ');
}
