import { EAccessRights, IAccessRightsOptions } from 'reactApp/components/SharingNewBiz/SharingNew.types';

export const DELETE_USER: IAccessRightsOptions = {
    key: EAccessRights.DELETE_USER,
    text: 'Закрыть доступ',
};

export const UNMOUNT_USER: IAccessRightsOptions = {
    key: EAccessRights.UNMOUNT_USER,
    text: 'Отключить доступ ',
};

export const ACCESS_RIGHTS_OPTIONS: Record<string, IAccessRightsOptions> = {
    [EAccessRights.READ_WRITE]: {
        key: EAccessRights.READ_WRITE,
        text: 'Редактирование',
    },
    [EAccessRights.READ_ONLY]: {
        key: EAccessRights.READ_ONLY,
        text: 'Просмотр',
    },
};
