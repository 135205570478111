import React, { ComponentType, FC, useCallback } from 'react';
import { FILEVIEW_EMBEDDED_ORIGIN } from 'reactApp/ui/ReactViewer/ReactViewer.constants';
import { IItemHandlerData } from 'reactApp/ui/ReactViewer/ReactViewer.types';

interface IProps {
    onAppReady?: (data?: IItemHandlerData) => void;
    onItemError?: (data?: IItemHandlerData) => void;
    onItemOpen?: (data?: IItemHandlerData) => void;
}

interface IVKPostMessage {
    type: 'app-ready' | 'ready' | 'error';
}

export function createPostMessageSender<T extends IVKPostMessage = IVKPostMessage>(origin: string) {
    return (message: T) => {
        parent.postMessage(message, origin);
    };
}

export function withFileStateVKNotifier<T extends IProps = IProps>(WrappedComponent: ComponentType<T>) {
    const sendPostMessageToVK = createPostMessageSender(FILEVIEW_EMBEDDED_ORIGIN || 'https://vk.com');

    const ComponentWithFileStateLogging: FC<T> = (props) => {
        const { onAppReady, onItemOpen, onItemError } = props;

        const handleAppReady = useCallback(
            (data?: IItemHandlerData) => {
                onAppReady?.(data);

                sendPostMessageToVK({
                    type: 'app-ready',
                });
            },
            [onAppReady]
        );

        const handleItemReady = useCallback(
            (data?: IItemHandlerData) => {
                onItemOpen?.(data);

                sendPostMessageToVK({
                    type: 'ready',
                });
            },
            [onItemOpen]
        );

        const handleItemError = useCallback(
            (data?: IItemHandlerData) => {
                onItemError?.(data);

                sendPostMessageToVK({
                    type: 'error',
                });
            },
            [onItemError]
        );

        return <WrappedComponent {...props} onAppReady={handleAppReady} onItemOpen={handleItemReady} onItemError={handleItemError} />;
    };

    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    ComponentWithFileStateLogging.displayName = `withFileStateVKNotifier(${displayName})`;

    return ComponentWithFileStateLogging;
}
