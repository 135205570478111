import { Icon20ChevronRightOutline } from '@vkontakte/icons';
import { Spacing, Text, Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo } from 'react';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';

import styles from './WelcomeNewPromo.css';

export interface ClearItem {
    title: string;
    size: string;

    onClick(): void;
}

interface Props {
    items: ClearItem[];

    onClearClick(): void;
}

export const ClearBlock = memo(({ items = [], onClearClick }: Props) => {
    return (
        <div className={classNames(styles.block, styles.block_clear)} data-qa-id="clear">
            <Title className={styles.tariff_text} level="2">
                Очистите место в хранилище
            </Title>
            <Spacing size={24} />
            <div className={styles.clearItems}>
                {items.map((item) => (
                    <div key={item.title} className={styles.clearItem} onClick={item.onClick} data-qa-id={item.title}>
                        <Text>{item.title}</Text>
                        <div className={styles.clearWrapper}>
                            <span className={styles.clearSize}>{item.size}</span>
                            <Icon20ChevronRightOutline />
                        </div>
                    </div>
                ))}
            </div>
            <Spacing size={24} />
            <div className={styles.clearBttn}>
                <Button data-name="welcome-clear" theme="octavius" onClick={onClearClick} sizeMode={ButtonSizeMode.small}>
                    <Text className={styles.clearText} weight="3">
                        Освободить место
                    </Text>
                </Button>
            </div>
        </div>
    );
});

ClearBlock.displayName = 'ClearBlock';
