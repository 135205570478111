import { PurchasedGiftsAPICall } from 'reactApp/api/PurchasedGiftsAPICall';
import { PurchasedGiftsFetch } from 'reactApp/modules/purchasedGifts/purchasedGifts.constants';

const request = () => ({
    type: PurchasedGiftsFetch.REQUEST,
});

const success = list => ({
    type: PurchasedGiftsFetch.SUCCESS,
    list,
});

const failure = error => ({
    type: PurchasedGiftsFetch.FAILURE,
    error,
});

export const loadPurchasedGifts = () => dispatch => {
    dispatch(request());

    return new Promise((resolve, reject) => {
        new PurchasedGiftsAPICall()
            .makeRequest()
            .then(({ data }) => {
                resolve(dispatch(success(data)));
            })
            .catch(error => {
                reject(dispatch(failure(error)));
            });
    });
};
