import { Button, ButtonGroup, Group, Header, Headline } from '@vkontakte/vkui';
import { stringify } from 'qs';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { historyPush } from 'reactApp/modules/router/router.module';
import { ApiSearchFolderResponseFile, ApiSearchResponseFolder, SearchSourceTitle } from 'reactApp/modules/search/search.types';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { ESearchOptionSource } from 'reactApp/ui/WebSearch/WebSearch.data';
import { scrollToTop } from 'reactApp/utils/helpers';

import styles from './SearchSection.css';
import { SearchSectionItem } from './SearchSectionItem/SearchSectionItem';

export interface IProps {
    title: SearchSourceTitle;
    items: (ApiSearchResponseFolder | ApiSearchFolderResponseFile)[];
    sectionItems: (ApiSearchResponseFolder | ApiSearchFolderResponseFile)[];
    section: ESearchOptionSource;
}

export const SearchSection: React.FC<IProps> = ({ items, title, section, sectionItems }) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { query, searchType } = useSelector(SettingsSelectors.getQueryParams);

    const showMore = items.length > 5;

    const onSectionClick = (searchSection: ESearchOptionSource) => {
        const newQuery = stringify({ query, searchType, searchSection });
        dispatch(historyPush({ id: `${pathname}?${newQuery}` }));
        scrollToTop();
    };

    return (
        <div className={styles.sectionContainer}>
            <Group
                header={
                    <Header mode="primary">
                        <Headline weight="2">{title}</Headline>
                    </Header>
                }
            >
                {sectionItems.map((item) => (
                    <SearchSectionItem key={item.home} item={item} />
                ))}
            </Group>
            {showMore && (
                <div className={styles.buttonWrapper}>
                    <ButtonGroup stretched mode="vertical">
                        <Button size="l" stretched appearance="neutral" mode="secondary" onClick={() => onSectionClick(section)}>
                            <Headline weight="2">Показать все</Headline>
                        </Button>
                    </ButtonGroup>
                </div>
            )}
        </div>
    );
};

SearchSection.displayName = 'SearchSection';
