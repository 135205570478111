import { UploadingReason } from 'reactApp/modules/uploading/serviceClasses/UploadingReason';

export class UploadError extends UploadingReason {
    radarName = 'error_{SOURCE}_abstract';

    ERROR_WRONG_INSTANCE = 'UploadError called on non-instance of UploadError';
    ERROR_ABSTRACT_CLASS = 'UploadError is abstract class';

    constructor(stack: Error, source: string) {
        super(stack, source);

        if (!(this instanceof UploadError)) {
            // @ts-ignore
            throw new TypeError(this.ERROR_WRONG_INSTANCE);
        }

        if (this.constructor === UploadError) {
            throw new TypeError(this.ERROR_ABSTRACT_CLASS);
        }
    }

}
