import classNames from 'clsx';
import { ReactComponent as IconClose } from 'mrg-icons/src/mailru/actions/close_big.svg';
import React, { memo, ReactElement, ReactNode, useCallback, useState } from 'react';
import { Portal } from 'react-portal';
import { CSSTransition } from 'react-transition-group';
import { IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';
import { noop } from 'reactApp/utils/helpers';

import styles from './LeftDialog.css';

interface Props {
    onClose: () => void;
    onFinishAnimation?: () => void;
    children: ReactNode;
    mode?: string;
    animated?: boolean;
    closeIcon?: ReactElement;
}

const TRANSITION_TIMEOUT = 250;

export const LeftDialog = memo(({ onClose, children, mode, animated = true, onFinishAnimation = noop, closeIcon }: Props): ReactElement => {
    const [visible, setVisible] = useState<boolean>(true);

    const handleClose = useCallback((): void => {
        setVisible(false);
        if (onClose) {
            setTimeout(onClose, TRANSITION_TIMEOUT);
        }
    }, [onClose]);

    return (
        <Portal>
            <CSSTransition
                in={visible}
                timeout={TRANSITION_TIMEOUT}
                appear={animated}
                classNames={{
                    exit: styles.animation_exit,
                    exitActive: styles.animation_exit_active,
                    enter: styles.animation_appear,
                    enterActive: styles.animation_appear_active,
                    appear: styles.animation_appear,
                    appearActive: styles.animation_appear_active,
                }}
                onEntered={onFinishAnimation}
            >
                <div
                    className={classNames(styles.root, {
                        [styles[`root_${mode}`]]: !!mode,
                        [styles.root_maxWidth]: IS_MY_TEAM,
                    })}
                >
                    <div className={styles.close} onClick={handleClose}>
                        {closeIcon || <IconClose width={14} height={14} fill="currentColor" />}
                    </div>
                    {children}
                </div>
            </CSSTransition>
        </Portal>
    );
});

LeftDialog.displayName = 'LeftDialog';
