/* eslint-disable max-lines-per-function */
// eslint-disable-next-line max-lines-per-function
define(function (require) {
    const { extInfo } = require('lib/extInfo');
    const api = require('Cloud/Application/api');
    const throttle = require('lodash.throttle');
    const features = require('Cloud/Application/Features');
    const { downloadAsJpeg } = require('Cloud/Application/DownloadAsJpeg');
    const { _path_join } = require('reactApp/utils/urlHelper');
    const { IS_PUBLIC, IS_STOCK } = require('reactApp/appHelpers/configHelpers');
    const { isUploadResumeAvailable, isNewVideoStreamUrl } = require('reactApp/modules/features/features.helpers');
    const { user } = require('Cloud/Application/User');

    const parseURL = (function () {
        let URLsupport = false;

        try {
            URLsupport = location.origin && new URL('//cloud.mail.ru/a/', location.origin);
            URLsupport = URLsupport.pathname === '/a/' && URLsupport.origin === `${location.protocol}//cloud.mail.ru`;
        } catch (error) {
            //
        }

        let element = null;

        return URLsupport
            ? function (url) {
                  const urlObj = new URL(url, location.origin);

                  return {
                      origin: urlObj.origin,
                      search: urlObj.search,
                      pathname: urlObj.pathname.replace(/\/{2,}/g, '/'),
                  };
              }
            : function (url) {
                  if (!element) {
                      element = document.createElement('a');
                  }

                  element.href = url;

                  return {
                      origin: `${element.protocol}//${element.hostname}`,
                      search: element.search,
                      pathname: `/${element.pathname}`.replace(/\/{2,}/g, '/'),
                  };
              };
    })();

    // eslint-disable-next-line max-lines-per-function
    const apiV4ToV4Dispatchers = (data) => {
        return Object.keys(data).reduce((res, dispatcher) => {
            res[dispatcher] = data[dispatcher][0];
            return res;
        }, {});
    };

    return function (app, _) {
        const rewriteDispatcher = app.getParam('REWRITE_DISPATCHER'); // CLOUDWEB-4395

        const EMAIL_ESCAPED = encodeURIComponent(user.getEmail());

        const RE_WEBLINK = new RegExp('^/(dev-)weblink/');
        const RE_X_EMAIL = new RegExp('([?&])x-email=[^&]+');

        const IS_OLD_IOS = app.browser.isOldIOS();
        const IS_HIDPI = app.browser.isHiDPI();
        const IS_MOBILE_BROWSER = app.browser.isMobile();

        const ITEM_WITHOUT_HASH_MAX_SIZE = app.getParam('ITEM_WITHOUT_HASH_MAX_SIZE'); // байт

        const last = {};
        let dispatcher;
        let __updateLock = false;
        const uploadResumableUrl = isUploadResumeAvailable && features.getFeatureParam('upload-resumable', 'url');

        function makeDispatcherUpdate() {
            return new Promise(function (resolve, reject) {
                if (!__updateLock) {
                    __updateLock = true;

                    RADAR.deferred(
                        'dispather_update',

                        api
                            .dispatcher()
                            .done(function (data) {
                                const newData = apiV4ToV4Dispatchers(data);
                                dispatcher = app.settings.dispatcher = normalizeDispatchers(newData);
                                __updateLock = false;

                                resolve();
                            })

                            .fail(function () {
                                reject();
                            })
                    );
                } else {
                    reject();
                }
            });
        }

        function _createDispatcher(sourceName, pathname, addToPathname = false) {
            const source = dispatcher?.[sourceName];

            if (!source) {
                return {};
            }

            const url = parseURL(source.url);

            return {
                count: Math.round(Math.max((source.count | 0) / 2, 2)),
                url: _path_join(url.origin, addToPathname ? _path_join(url.pathname, pathname) : pathname),
                isProcessed: true,
            };
        }

        function normalizeDispatchers(dispatchers) {
            if (dispatchers) {
                if (dispatcher?.stock_get) {
                    dispatchers.stock_get = _createDispatcher('stock_get', 'stock/get');
                } else {
                    dispatchers.stock_get = _createDispatcher('stock', 'stock/get');
                }

                if (dispatcher?.stock_view) {
                    dispatchers.stock_view = _createDispatcher('stock_view', 'stock/view');
                } else {
                    dispatchers.stock_view = _createDispatcher('stock', 'stock/view');
                }

                if (dispatcher?.stock_video) {
                    dispatchers.stock_video = _createDispatcher('stock_video', 'stock/video/0p');
                } else {
                    dispatchers.stock_video = _createDispatcher('stock', 'stock/video/0p');
                }

                if (dispatcher?.stock_thumb) {
                    dispatchers.stock_thumbs = _createDispatcher('stock_thumb', 'stock/thumb');
                } else {
                    dispatchers.stock_thumbs = _createDispatcher('stock', 'stock/thumb');
                }

                // private video
                if (dispatchers.video) {
                    dispatchers.video = _createDispatcher('video', 'video/0p');
                } else if (dispatchers.view) {
                    dispatchers.video = _createDispatcher('view', 'video/0p');
                }

                // public video
                if (dispatchers.weblink_video) {
                    dispatchers.weblink_video = _createDispatcher('weblink_video', 'videowl/0p');
                } else if (dispatchers.weblink_view) {
                    dispatchers.weblink_video = _createDispatcher('weblink_view', 'videowl/0p');
                }
                if (dispatchers.videowl_view && !dispatchers.videowl_view.isProcessed) {
                    dispatchers.videowl_view = _createDispatcher('videowl_view', '0p', true);
                }
            }

            return dispatchers;
        }

        const updateDispatcher = throttle(makeDispatcherUpdate, 50);

        function url(domain) {
            if (!dispatcher[domain]) {
                throw new Error(`Undefined dispather domain ${domain}`);
            }

            if (dispatcher[domain]) {
                if (rewriteDispatcher[domain]) {
                    // CLOUDWEB-4395
                    let dispatcherPath = dispatcher[domain].url;
                    dispatcherPath = dispatcherPath.slice(Math.max(0, dispatcherPath.search(/[^\/:]\//) + 1));

                    return _path_join(rewriteDispatcher[domain], dispatcherPath);
                }

                last[domain] = dispatcher[domain];

                if (!__updateLock) {
                    last[domain].count--;

                    if (!last[domain].count) {
                        updateDispatcher(domain);
                    }
                }
            }

            if (!last[domain]) {
                throw new Error(`Not save last ${domain}`);
            }

            return last[domain].url;
        }

        function thumbUrl(item, code, isBig, id, isPublic) {
            let path;

            if (IS_STOCK) {
                path = _path_join(url('stock_thumbs'), code, `${item.dwl_token}/${item.name}`);
            } else {
                path = url(isPublic && isBig ? 'weblink_thumbnails' : 'thumbnails');

                // CLOUDWEB-5839 – во всех запросах к тамбнейлам должно присутствовать оригинальное расширение файла.
                // CLOUDWEB-6030 – используем расширение файла в оригинальном регистре (item.ext2), а не приведенное к lowercase (item.ext).

                if (isBig) {
                    // Большие тамбнейлы (xw0 и xw1) запрашиваем по id файла.
                    path = _path_join(path, code, id);
                } else {
                    // Тамбнейлы меньшего размера запрашиваем с использованием hash файла.
                    path = _path_join(path, code, `${item.hash}.${item.ext2 || item.ext}`);
                }
            }

            if (IS_OLD_IOS) {
                path = _rnd(path);
            }

            return path;
        }

        function _encodeId(str) {
            return str
                ? encodeURIComponent(str)
                      .replace(/%2F/g, '/')
                      .replace(/[!'()*]/g, function (c) {
                          return `%${c.charCodeAt(0).toString(16).toUpperCase()}`;
                      })
                : undefined;
        }

        function _email(url) {
            if (url) {
                const p = parseURL(url);

                if (!RE_WEBLINK.test(p.pathname)) {
                    url = p.origin + p.pathname;

                    if (RE_X_EMAIL.test(p.search)) {
                        url += p.search.replace(RE_X_EMAIL, `$1x-email=${EMAIL_ESCAPED}`);
                    } else {
                        url += `${p.search ? `${p.search}&` : '?'}x-email=${EMAIL_ESCAPED}`;
                    }
                }

                if (IS_OLD_IOS) {
                    url = _rnd(url);
                }
            }

            return url;
        }

        function _rnd(url) {
            if (url && !url.match(/(\?|&)rnd=\d+(&|$)/)) {
                url += `${parseURL(url).search ? '&' : '?'}rnd=${Date.now()}`;
            }

            return url;
        }

        dispatcher = app.settings.dispatcher;

        if (!dispatcher || typeof dispatcher !== 'object' || !Object.keys(dispatcher).length) {
            updateDispatcher();
        } else {
            normalizeDispatchers(dispatcher);
        }

        const builder = {
            parseURL,

            get(item, id, isPublic) {
                if (IS_STOCK) {
                    return _path_join(url('stock_get'), item.dwl_token, encodeURIComponent(item.name));
                } else if (isPublic) {
                    return _path_join(url('weblink_get'), id);
                }
                return _email(_path_join(url('get'), id));
            },

            view(item, id, isPublic) {
                if (IS_STOCK) {
                    return _email(_path_join(url('stock_view'), item.dwl_token, encodeURIComponent(item.name)));
                } else if (isPublic) {
                    return _path_join(url('weblink_view'), id);
                }
                return _email(_path_join(url('view'), id));
            },

            download(item, id, isPublic, suffix = '') {
                if (IS_STOCK) {
                    return _email(_path_join(url('stock_thumbs'), 'download', 'xw1', `${item.dwl_token}/${item.name}${suffix}`));
                }

                id = isPublic ? _encodeId(item.weblink) : id;
                const path = url(isPublic ? 'weblink_thumbnails' : 'thumbnails');

                const ext = _encodeId(item.ext2);
                const idHasExt = ext && id.lastIndexOf(ext) === id.length - ext.length;

                const itemId = idHasExt ? id + suffix : id;

                if (idHasExt) {
                    return _email(_path_join(path, 'download', 'xw1', itemId));
                }

                return _email(_path_join(path, 'download', 'xw1', itemId, encodeURIComponent(item.name + suffix)));
            },

            video(item, id, isPublic) {
                if (IS_STOCK) {
                    return `${_path_join(url('stock_video'), item.dwl_token)}.m3u8`;
                }

                id = btoa(id);

                if (isPublic) {
                    const videoUrl = `${_path_join(url(isNewVideoStreamUrl ? 'videowl_view' : 'weblink_video'), id)}.m3u8?double_encode=1`;

                    if (EMAIL_ESCAPED !== 'undefined') {
                        // CLOUDWEB-5871
                        return _email(videoUrl);
                    }

                    return videoUrl;
                }
                return _email(`${_path_join(url('video'), id)}.m3u8?double_encode=1`);
            },

            viewDirect(item, id, isPublic) {
                if (IS_STOCK) {
                    return _email(_path_join(url('stock_view'), item.dwl_token, encodeURIComponent(item.name)));
                }

                if (isPublic) {
                    return _path_join(url('weblink_view'), id);
                }
                // CLOUDWEB-3901 only for private docs
                return _email(_path_join(url('view_direct'), id));
            },

            thumbs(item, id, isPublic) {
                if (app.isVirus && app.isVirus(item)) {
                    return;
                }

                id = isPublic ? _encodeId(item.weblink) : id;

                const extParams = extInfo.get(item.ext);
                const maxSize = extParams.maxThumbnailableSize;

                if (!maxSize || item.size > maxSize) {
                    return;
                }

                const prefix = item.kind === 'video' ? 'v' : '';

                const thumbnails = IS_STOCK
                    ? {
                          '26x26': thumbUrl(item, `${prefix}w1`),
                          '52x52': thumbUrl(item, `${prefix}w2`),
                          '160x120': thumbUrl(item, `${prefix}w0`),
                          '320x240': thumbUrl(item, `${prefix}w3`),
                          '240w': thumbUrl(item, `${prefix}w13`),
                          '324w': thumbUrl(item, `${prefix}w14`),

                          '320x240crop': thumbUrl(item, `${prefix}w6`),

                          '1200x1200': _email(thumbUrl(item, `${prefix}xw0`)),
                          original: _email(thumbUrl(item, `${prefix}xw1`)),
                      }
                    : {
                          '26x26': thumbUrl(item, `${prefix}w1`, false, id, isPublic),
                          '52x52': thumbUrl(item, `${prefix}w2`, false, id, isPublic),
                          '160x120': thumbUrl(item, `${prefix}w0`, false, id, isPublic),
                          '320x240': thumbUrl(item, `${prefix}w3`, false, id, isPublic),
                          '240w': thumbUrl(item, `${prefix}w13`, false, id, isPublic),
                          '324w': thumbUrl(item, `${prefix}w14`, false, id, isPublic),

                          '320x240crop': thumbUrl(item, `${prefix}w6`, false, id, isPublic), // CLOUDWEB-5362

                          '1200x1200': _email(thumbUrl(item, `${prefix}xw0`, true, id, isPublic)),
                          original: _email(thumbUrl(item, `${prefix}xw1`, true, id, isPublic)),
                      };

                if (extParams.showThumbnailInList) {
                    // PIC (Mini thumb)
                    const mobileSize = IS_HIDPI ? '160x120' : '52x52';
                    const size = IS_MOBILE_BROWSER ? mobileSize : '26x26';
                    thumbnails.pic = thumbnails[size];
                }

                return thumbnails;
            },

            upload() {
                if (IS_STOCK) {
                    return '';
                }

                let backendUploadResumableUrl;
                try {
                    backendUploadResumableUrl = url('upload_resumable');
                } catch {}

                if (isUploadResumeAvailable && !app.isEditor()) {
                    return `${backendUploadResumableUrl || uploadResumableUrl}[HASH]`; // + '?cloud_domain=2'
                }

                return _email(`${url(IS_PUBLIC ? 'public_upload' : 'upload')}?cloud_domain=2`);
            },
        };

        Object.assign(_, {
            urls: builder,

            normalizeUrls(item) {
                if (item) {
                    const already = _.v2
                        ? Date._timer && item.__normalizeUrls && Math.abs(Date._timer - item.__normalizeUrls) < 60000
                        : item.__normalizeUrls;
                    if (!already) {
                        if (!item.url) {
                            item.url = {};
                        }

                        // cloudweb-4320:
                        // use 'weblink' for get/view on Public
                        // use 'home' or 'id' for get/view on any other storage's
                        const isPublic = IS_PUBLIC && !!item.weblink; // cloudweb-4710
                        const id = _encodeId(isPublic ? item.weblink : item.home || item.id);

                        item.url.get = builder.get(item, id, isPublic);
                        item.url.view = builder.view(item, id, isPublic);

                        if (item.size && item.size > ITEM_WITHOUT_HASH_MAX_SIZE) {
                            const thumbnails = builder.thumbs(item, id, isPublic);

                            item.thumbnails = thumbnails;
                            item.pic = thumbnails && thumbnails.pic;
                        }

                        if (item.subKind === 'video') {
                            item.url.media = builder.video(item, id, isPublic);
                        } else if (item.subKind === 'audio') {
                            item.url.media = item.url.view;
                        } else if (item.kind === 'document') {
                            // CLOUDWEB-3901
                            item.url.viewDirect = builder.viewDirect(item, id, isPublic);
                        } else if (downloadAsJpeg.isAvailable({ ext: item.ext })) {
                            item.url.download = builder.download(item, id, isPublic, '.jpeg');
                        }

                        if (_.v2 || _.v4) {
                            item.__normalizeUrls = Date.now();
                        } else {
                            item.__normalizeUrls = true;
                        }
                    }
                }

                return item;
            },

            // TODO delete
            updateDispatcher: makeDispatcherUpdate,

            makeDispatcherUpdate,
        });
    };
});
