import classNames from 'clsx';
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { getReasons, OTHER_ID } from 'reactApp/ui/PollPopup/PollPopup.constants';
import styles from 'reactApp/ui/PollPopup/PollPopup.css';
import { SelectableButton } from 'reactApp/ui/PollPopup/SelectableButton';
import { isDarkThemeModeEnabled } from 'reactApp/utils/theme';

export const PollContent = memo(
    ({
        onComment,
        onChoice,
        choiceId,
        isPhone,
        comment,
    }: {
        onComment(comment: string): void;
        onChoice(id: string): void;
        choiceId: string;
        comment: string;
        isPhone: boolean;
    }) => {
        const reasons = useMemo(() => getReasons(isPhone), [isPhone]);

        const rootRef = useRef<HTMLInputElement | null>(null);

        const isOtherSelected = choiceId === OTHER_ID;

        const isDarkMode = isDarkThemeModeEnabled();

        const handleOnComment = useCallback(
            (event: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;
                onComment(value);
            },
            [onComment]
        );

        useEffect(() => {
            if (isOtherSelected && isPhone) {
                rootRef?.current?.scrollIntoView();
            }
        }, [isOtherSelected, isPhone]);

        return (
            <div className={isPhone ? styles.content_phone : ''}>
                <div
                    className={classNames(styles.image, {
                        [styles.image_dark]: isDarkMode,
                    })}
                />
                <div className={styles.title}>
                    Почему вы решили
                    <br />
                    отменить подписку?
                </div>
                <div className={styles.buttons}>
                    {reasons.map((reason) => (
                        <SelectableButton key={reason} title={reason} onClick={onChoice} isSelected={reason === choiceId} />
                    ))}
                    {isOtherSelected && (
                        <input
                            ref={rootRef}
                            onChange={handleOnComment}
                            autoComplete="off"
                            placeholder="Ваш комментарий"
                            value={comment}
                            type="text"
                            id="comment"
                        />
                    )}
                </div>
            </div>
        );
    }
);

PollContent.displayName = 'PollContent';
