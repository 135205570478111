import { CLOUD_NEW_FEATURES_CONFIG } from 'reactApp/appHelpers/configHelpers';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { store as reduxStore } from 'reactApp/store';

export const CLOUD_NEW_FEATURES_TOOLTIP_ID_DESKTOP = 'cloudNewFeaturesTooltipDesktop';
export const CLOUD_NEW_FEATURES_TOOLTIP_ID_MOBILE = 'cloudNewFeaturesTooltipMobile';
export const CLOUD_NEW_FEATURES_VIEWED_IDS = 'cloudNewFeaturesViewedIDs';
export const CLOUD_NEW_FEATURES_LOADED_IDS = 'cloudNewFeaturesLoadedIDs';
export const CLOUD_NEW_FEATURES_LANDING_HREF = 'https://whatsnew.mail.ru/all_cloud_updates';

/** Получаем ID новых фич (из слота рб). */
export const getCloudFeatures = () => CLOUD_NEW_FEATURES_CONFIG.map((item) => item.bannerid);
/** Получаем список просмотренных фич юзера из Local Storage */
export const getViewedCloudFeatures = () => (reduxStore.dispatch(UserStorageActions.get(CLOUD_NEW_FEATURES_VIEWED_IDS)) as string[]) || [];
/** Сохраняем просмотренную фичу в Local Storage */
export const markCloudFeatureAsViewed = (id: string) => {
    const viewedFeatures = getViewedCloudFeatures();
    reduxStore.dispatch(UserStorageActions.set(CLOUD_NEW_FEATURES_VIEWED_IDS, [...viewedFeatures, id]));
};
/** Получаем список ранее загруженных фич из Local Storage  */
export const getLoadedCloudFeatures = () => (reduxStore.dispatch(UserStorageActions.get(CLOUD_NEW_FEATURES_LOADED_IDS)) as string[]) || [];

/** Обновляем список загруженных фич в локалсторадж */
export const updateLoadedCloudFeaturesList = () => {
    const features = getCloudFeatures();
    const loadedFeatures = getLoadedCloudFeatures();
    reduxStore.dispatch(UserStorageActions.set(CLOUD_NEW_FEATURES_LOADED_IDS, [...features, ...loadedFeatures]));
};
