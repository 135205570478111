import React, { memo, ReactElement } from 'react';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { TabMenu } from 'reactApp/ui/Menu/TabMenu';
import { ETabMenuAlign, ETabMenuMod } from 'reactApp/ui/Menu/TabMenu.types';
import { TabMenuItem } from 'reactApp/ui/Menu/TabMenuItem';

interface Tab {
    value: string;
    name: string;
}

interface Props {
    tabs: Tab[];
    current: string;
    onClick: (category: any) => void;
    changeHash?: boolean;
    noVerticalScroll?: boolean;
    isMobile?: boolean;
    allowClickOnActive?: boolean;
}

const areEqual = (prevProps: Props, nextProps: Props): boolean =>
    prevProps.current === nextProps.current && prevProps.tabs?.length === nextProps.tabs?.length;

const PageTabMenuComponent = ({
    tabs,
    current,
    onClick,
    isMobile = false,
    changeHash = false,
    noVerticalScroll = false,
    allowClickOnActive,
}: Props): ReactElement => {
    const noVerticalScrollUpdated = ENABLE_FULL_RESPONSIVE ? true : noVerticalScroll;

    return (
        <TabMenu align={ETabMenuAlign.left} mod={ETabMenuMod.buttons} noVerticalScroll={noVerticalScrollUpdated} isMobile={isMobile}>
            {tabs.map((tab: Tab) => (
                <TabMenuItem
                    changeHash={changeHash}
                    onClick={onClick}
                    key={tab.value}
                    name={tab.name}
                    value={tab.value}
                    active={current === tab.value}
                    allowClickOnActive={allowClickOnActive}
                />
            ))}
        </TabMenu>
    );
};

export const PageTabMenu = memo(PageTabMenuComponent, areEqual);
