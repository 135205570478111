import classNames from 'clsx';
import { addDays } from 'date-fns';
import { ReactComponent as AutoDeleteIcon } from 'img/autodeleteIcon.svg';
import React, { memo, ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { HomeFolder } from 'reactApp/modules/home/home.types';
import { getAutoDeleteFoldersInfo } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.selectors';
import { IAutoDeleteFoldersInfo } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.type';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { useContent } from 'reactApp/ui/AutoDeleteFolderInformer/useContent';
import { Informer } from 'reactApp/ui/Informer/Informer';
import { EInformerColor } from 'reactApp/ui/Informer/Informer.types';
import opener from 'reactApp/utils/opener';

import styles from './AutoDeleteFolderInformer.css';

const autoDeleteInformerId = 'autodelete-home-informer';

interface IProps {
    onClose: () => void;
    item: HomeFolder;
    className?: string;
}

export const AutoDeleteFolderInformer = memo(({ onClose, item, className }: IProps): ReactElement | null => {
    const dispatch = useDispatch();

    const foldersInfo: IAutoDeleteFoldersInfo = useSelector(getAutoDeleteFoldersInfo);
    const informerShown = dispatch(UserStorageActions.get(autoDeleteInformerId)) as unknown as number;
    const isRoot = item.id === ROOT_FOLDER_ID;

    const { description, buttonText, closable } = useContent({ item, isRoot, foldersInfo });

    const handleClose = useCallback(() => {
        onClose();
        if (isRoot) {
            dispatch(UserStorageActions.set(autoDeleteInformerId, addDays(new Date(), 1).valueOf()));
        }
    }, [onClose, dispatch, isRoot]);

    const handleButtonClick = useCallback(() => {
        if (isRoot) {
            opener('/shared/autodelete', true);
        } else {
            toolbarActions.publish();
        }
    }, [isRoot]);

    // если root, но нет автоудаляемых файлов(или баннер уже был показан за сутки) или папка не автоудаляемая, то информер не показываем
    if ((isRoot && (!foldersInfo.folderCount || informerShown > new Date().valueOf())) || (!isRoot && !item.weblinkAutoDelete)) {
        return null;
    }

    return (
        <div className={classNames(styles.root, className)}>
            <Informer
                icon={<AutoDeleteIcon height={32} width={32} />}
                color={EInformerColor.red}
                closable={closable}
                onClose={handleClose}
                buttonText={buttonText}
                centered
                onButtonClick={handleButtonClick}
            >
                {description}
            </Informer>
        </div>
    );
});

AutoDeleteFolderInformer.displayName = 'AutoDeleteFolderInformer';
