import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getIntegrationClient } from 'reactApp/modules/integration/integration.selectors';
import { IIntegrationClient } from 'reactApp/modules/integration/integration.types';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { createSelector } from 'reselect';

import { EViewMode, QueryParams, SettingsState } from './settings.types';

const getSettingsStore = (state: any): SettingsState => state.settings;

const getPrevViewMode = (state: SettingsState): EViewMode => getSettingsStore(state).prevViewMode;

const getClient = (state: any): IIntegrationClient => getIntegrationClient(state);

const getViewByStorage = createSelector(
    getSettingsStore,
    (state, storage): EStorageType => storage,
    (state, storage, isMediaFolder: boolean): boolean => isMediaFolder,
    () => EnvironmentSelectors.isPhone(),
    getClient,
    (store: SettingsState, storage: EStorageType, isMediaFolder: boolean, isPhone: boolean, { isTutoria }) => {
        const { isGallery, isAttaches, isSharedIncoming, isTrashBin, isDocuments, isQuotaCleaner, isIntegration, isInlineIntegration } =
            getStorage(storage as EStorageType);

        if (isInlineIntegration) {
            return EViewMode.thumbs;
        }

        if (isIntegration) {
            return isTutoria ? EViewMode.thumbs : EViewMode.list;
        }

        if ((isGallery || isMediaFolder) && !isPhone) {
            return store.viewModePhoto;
        }

        if (isGallery && isPhone) {
            return store.viewModePhotoMobile;
        }

        if (isAttaches) {
            return store.viewModeAttaches;
        }

        if (isSharedIncoming || isTrashBin) {
            return EViewMode.list;
        }

        if (isDocuments) {
            return EViewMode.thumbs;
        }

        if (isQuotaCleaner) {
            return store.viewModeQuotaCleaner;
        }

        if (ENABLE_FULL_RESPONSIVE && store.viewMode !== EViewMode.list) {
            return EViewMode.list;
        }

        return store.viewMode;
    }
);

const getQueryParams = createSelector(getSettingsStore, (store: SettingsState): QueryParams => store.queryParams);

const isSidebarOpened = createSelector(getSettingsStore, (store: SettingsState): boolean => store.sidebarOpened);

export const SettingsSelectors = {
    getViewByStorage,
    getQueryParams,
    getPrevViewMode,
    isSidebarOpened,
};
