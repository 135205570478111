import { newDocNamesByExt } from 'Cloud/Application/Editor/config';
import config from 'Cloud/config';
import React, { memo, ReactElement } from 'react';
import { BaseInputDialog } from 'reactApp/components/BaseConfirmDialog/BaseInputDialog';
import { INewDocumentModal } from 'reactApp/components/NewDocumentModal/NewDocumentModal.helpers';
import { createDocumentErrors } from 'reactApp/modules/modifying/modifying.constants';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { noop } from 'reactApp/utils/helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

const titleByExt = {
    docx: 'Название документа',
    xlsx: 'Название таблицы',
    pptx: 'Название презентации',
};

const getNameSize = (name: string) => {
    const encoder = new TextEncoder();
    return encoder.encode(name).length;
};

const popupName = popupNames.CREATE_DOCUMENT;

const MAX_NAME_LENGTH = config.get('ITEM_NAME_MAX_LENGTH') - 5;
const MAX_NAME_SIZE = 250;
const INVALID_CHARACTERS = /["*/:<>?\\|]|^\.{1,2}$/;
const isPaidUser = config.get('PAID_USER');

export const NewDocumentModal = memo(({ createDocument, editorArg, item, ext, from }: INewDocumentModal): ReactElement => {
    const fileName = newDocNamesByExt[ext];

    const onSuccessCallback = (fileName: string) => {
        return new Promise<void>((resolve, reject) => {
            if (!fileName.trim()) {
                return reject(createDocumentErrors.getMessage('required'));
            }

            if (INVALID_CHARACTERS.test(fileName)) {
                return reject(createDocumentErrors.getMessage('invalid'));
            }

            if (getNameSize(fileName) > MAX_NAME_SIZE) {
                return reject(createDocumentErrors.getMessage('name_too_long'));
            }

            createDocument(editorArg, item, ext, false, fileName.trim().normalize());

            sendPaymentGa({
                eventCategory: ECategoryGa.editor,
                action: 'name_create',
                paid_user: isPaidUser,
                extension: ext,
                source: from,
                id_media: `${item.id}${item.id === '/' ? '' : '/'}${fileName}`,
            });

            closePopupHelper(popupName);
            Promise.resolve();
        });
    };

    const handleClose = () => {
        sendPaymentGa({
            eventCategory: ECategoryGa.editor,
            action: 'name_cancel',
            paid_user: isPaidUser,
            extension: ext,
            source: from,
        });

        closePopupHelper(popupName);
    };

    return (
        <BaseInputDialog
            dataQAId="new-document-modal"
            renderHeader={() => titleByExt[ext]}
            successButtonText="Создать"
            actionButtonText="Отменить"
            onAction={handleClose}
            onSuccess={onSuccessCallback}
            selectionRange={fileName.length}
            maxLength={MAX_NAME_LENGTH}
            value={fileName}
            onClose={handleClose}
            renderContent={noop}
        />
    );
});

NewDocumentModal.displayName = 'NewDocumentModal';
