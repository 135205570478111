import { ReactComponent as CloneIcon } from 'img/icons/mobile/clone.svg';
import { ReactComponent as CopyIcon } from 'img/icons/mobile/copy.svg';
import { ReactComponent as DownloadIcon } from 'img/icons/mobile/download.svg';
import { ReactComponent as MoveIcon } from 'img/icons/mobile/move.svg';
import { ReactComponent as RemoveIcon } from 'img/icons/mobile/remove.svg';
import { ReactComponent as RestoreIcon } from 'img/icons/mobile/restore.svg';
import { ReactComponent as ShareIcon } from 'img/icons/mobile/share.svg';
import React, { ReactElement } from 'react';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { EActions } from 'reactApp/modules/storage/storage.types';
import styles from 'reactApp/ui/Mobile/ActionsBar/ActionsBar.css';
import { ShareAlbumIcon } from 'reactApp/ui/VKUIIcons';

export interface IBarItem {
    id: EActions;
    text: string;
    icon: ReactElement;
    disabled: boolean;
    onClick: () => void;
}

export const actionItems: Partial<Record<EActions, (disabled?: boolean) => IBarItem>> = {
    [EActions.remove]: (disabled = false) => ({
        id: EActions.remove,
        text: 'Удалить',
        icon: <RemoveIcon className={styles.icon} />,
        disabled,
        onClick: toolbarActions.remove,
    }),
    [EActions.clone]: (disabled = false) => ({
        id: EActions.clone,
        text: 'Сохранить в облако',
        icon: <CloneIcon className={styles.icon} />,
        disabled,
        onClick: () => toolbarActions.clone({ source: 'action-bar' })
    }),
    [EActions.download]: (disabled = false) => ({
        id: EActions.download,
        text: 'Скачать',
        icon: <DownloadIcon className={styles.icon} />,
        disabled,
        onClick: toolbarActions.downloadMobileItem,
    }),
    [EActions.publish]: (disabled = false) => ({
        id: EActions.publish,
        text: 'Поделиться',
        icon: <ShareIcon className={styles.icon} />,
        disabled,
        onClick: () => toolbarActions.publish('toolbar'),
    }),
    [EActions.publishAlbum]: (disabled = false) => ({
        text: 'Поделиться',
        id: EActions.publishAlbum,
        disabled,
        icon: <ShareAlbumIcon width={24} height={24} />,
        onClick: toolbarActions.createAndPublishAlbum,
    }),
    [EActions.move]: (disabled = false) => ({
        id: EActions.move,
        text: 'Переместить',
        icon: <MoveIcon />,
        disabled,
        onClick: toolbarActions.move,
    }),
    [EActions.copy]: (disabled = false) => ({
        id: EActions.copy,
        text: 'Копировать',
        icon: <CopyIcon className={styles.icon} />,
        disabled,
        onClick: toolbarActions.copy,
    }),
    [EActions.clear]: (disabled = false) => ({
        id: EActions.clear,
        text: 'Очистить корзину',
        icon: <RemoveIcon className={styles.icon} />,
        disabled,
        onClick: toolbarActions.clear,
    }),
    [EActions.restore]: (disabled = false) => ({
        id: EActions.restore,
        text: 'Восстановить',
        icon: <RestoreIcon className={styles.icon} />,
        disabled,
        onClick: () => toolbarActions.restore(),
    }),
};
