import {
    ACTIONS_SELECT_ICON_MAP,
    SCENARIO_SELECT_ICON_MAP,
} from 'reactApp/ui/EditorLLM/EditorLLMDialog/constants/EditorLlmDialog.constants';
import {
    ActionCategory,
    Message,
    MessageCategory,
    Messages,
    Options,
} from 'reactApp/ui/EditorLLM/EditorLLMDialog/types/EditorLlmDialog.types';
import { llmFeature } from 'reactApp/ui/EditorLLM/helpers/EditorLlmHelpers';
import { copy } from 'reactApp/utils/copyToClipboard';

/**
 * Функция для удаления пробелов и переносов строки в начале сообщения.
 */
export const removeLeadingNewlines = (text: string): string => text.replace(/^(\s+|^\n\s+)/g, '');

/**
 * Находит ближайшее сообщение с isOwn: true к заданному целевому сообщению (используется при выборе "Другой вариант").
 */
export const findNearestOwnMessageAbove = (messages: Messages, targetMessage: Message['message']): Message | null => {
    let nearestOwnMessage: Message | null = null;

    for (const message of messages) {
        if (message.isOwn) {
            nearestOwnMessage = message;
        }

        if (message.message === targetMessage) {
            break;
        }
    }

    return nearestOwnMessage;
};

/**
 * Функция удаления связанных сообщений
 */
export const deleteMessagesBeforeNearestOwn = (messages: Messages, targetMessage: string): Messages => {
    const index = messages.findIndex((item) => item.message === targetMessage);
    const messagesToDelete: Messages = [];

    for (let i = index; i >= 0; i--) {
        messagesToDelete.push(messages[i]);

        if (messages[i].isOwn) {
            break;
        }
    }

    // Удаляем найденные сообщения из массива
    messages.splice(index - messagesToDelete.length + 1, messagesToDelete.length);

    return messages;
};

/**
 * Убираем форматирование (стили) при копировании из чата.
 */
export const copyTextWithoutStyles = () => {
    const selection = document?.getSelection();

    if (!selection) {
        return;
    }

    const selectedText = selection.toString() || '';

    if (selectedText) {
        /*
          Делаем эту проверку, т.к. при двойном клике на сообщение и копировании в буфер обмена попадает
          текст кнопки "Скопировать" (vkui используется в качестве родительского элемента кнопки div).
          Если в буфер попал текст кнопки, то убираем ее из массива, иначе оставляем как есть.
         */
        const textToArray = selectedText.split('\n');
        const buttonIndex = textToArray.indexOf('Скопировать');
        const textWithoutStyles = buttonIndex !== -1 ? textToArray.slice(0, buttonIndex).join('\n') : selectedText;

        copy(textWithoutStyles);
    }
};

const isActionCategory = (value: any): value is ActionCategory => Object.values(ActionCategory).includes(value);

const isMessageCategory = (value: any): value is MessageCategory => Object.values(MessageCategory).includes(value);

export const getActionSelectOptions = () => {
    const entries = Object.entries(llmFeature.actionOptions);

    return entries.reduce<Options<ActionCategory>>(
        (initial, [value, label]) =>
            isActionCategory(value) ? [...initial, { label, value, icon: ACTIONS_SELECT_ICON_MAP[value] }] : initial,
        []
    );
};

export const getScenarioSelectOptions = () => {
    const entries = Object.entries(llmFeature.scenarioOptions);

    return entries.reduce<Options<MessageCategory>>(
        (initial, [value, label]) =>
            isMessageCategory(value) ? [...initial, { label, value, icon: SCENARIO_SELECT_ICON_MAP[value] }] : initial,
        []
    );
};
