// https://help.mail.ru/help2/js/v2/sent_me_ads.js
export function getBooleanCookie(name: string, postfix: string): boolean {
    const cookieValues = window.document.cookie.match(`(^|[^;]+)\\s*${name}_${postfix}\\s*=\\s*([^;]+)`);
    const extracted = cookieValues ? cookieValues.pop() : '';
    switch (extracted) {
        case 'false':
            return false;
        case 'true':
        default:
            return true;
    }
}
