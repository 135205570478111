import { ReactElement, useCallback, useEffect, useRef } from 'react';

interface IProps {
    adsUpdateTimerMs: number;
    renderItem: () => ReactElement | null;
    updateAds: () => void;
    suspendTimer?: boolean;
}

export function useShowAdsBannerTimer({ adsUpdateTimerMs, renderItem, updateAds, suspendTimer = false }: IProps) {
    const adsTimer = useRef<number | undefined>();

    const setAdsTimer = useCallback(() => {
        if (adsTimer.current) {
            window.clearTimeout(adsTimer.current);
        }

        if (document.visibilityState !== 'visible' || suspendTimer) {
            return;
        }
        updateAds();

        adsTimer.current = window.setTimeout(setAdsTimer, adsUpdateTimerMs);
    }, [suspendTimer]);

    const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            setAdsTimer();
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setAdsTimer();

            document.addEventListener('visibilitychange', handleVisibilityChange);
        }, 0);

        return () => {
            if (adsTimer.current) {
                window.clearTimeout(adsTimer.current);
            }
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [setAdsTimer]);

    return renderItem();
}
