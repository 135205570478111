import React from 'react';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { NameComponent } from 'reactApp/ui/Name/Name';

import { FileDirection } from '../FileDirection/FileDirection';

const getShareAccess = (hasWeblink: boolean) => `Доступ ${hasWeblink ? 'открыт' : 'закрыт'}`;

const getFileName = (name: string, ext?: string, fullName?: boolean) => (
    <NameComponent name={name} extension={ext} truncate={!fullName} disableEllipsis={fullName} />
);

const canvas = document.createElement('canvas');

export function getTextWidth(text, font) {
    // re-use canvas object for better performance
    const context = canvas.getContext('2d');
    if (!context) {
        return;
    }
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
}

const getFileDirection = (direction: string[]) => <FileDirection direction={direction} />;

const closeFileInfoModal = () => closePopupHelper(popupNames.MOBILE_FILE_INFO_MODAL);

const closeViewerModal = () => closePopupHelper(popupNames.MOBILE_VIEWER);

export { getShareAccess, getFileName, getFileDirection, closeFileInfoModal, closeViewerModal };
