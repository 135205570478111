import { WeblinkDownloadsCounts } from 'reactApp/modules/public/public.types';
import { ESortOder, ESortTypes } from 'reactApp/modules/sort/sort.types';

export type Kind =
    | 'folder'
    | 'invites'
    | 'storage'
    | 'camera-upload'
    | 'shared'
    | 'mounted'
    | 'domain-folder'
    | 'file'
    | 'video'
    | 'audio'
    | 'image'
    | 'source'
    | 'document'
    | 'light-markup'
    | 'text'
    | 'pdf,document'
    | 'album';

export type SubKind =
    | 'video'
    | 'archive'
    | 'picture'
    | 'audio'
    | 'android-package'
    | 'source'
    | 'playlist'
    | 'exec'
    | 'ebook'
    | 'html'
    | 'email'
    | 'plain-text'
    | 'spreadsheet'
    | 'debian-package'
    | 'text'
    | 'calendar'
    | 'json'
    | 'presentation'
    | 'card'
    | 'markdown'
    | 'chart'
    | 'graphics'
    | 'pdf'
    | 'photoshop'
    | 'web-feed'
    | 'flash'
    | 'torrent'
    | 'light-markup'
    | 'unknown';

export type VirusScan = 'pass' | 'fail' | 'not_yet' | 'skip';

export enum AccessRights {
    r = 'r',
    rw = 'rw',
}

export interface Sort {
    type: ESortTypes | null;
    order?: ESortOder;
}

export interface Count {
    folders: number;
    files: number;
    all: number; // вычисляемое
    loaded: number; // вычисляемое
}

export interface Weblink extends WeblinkDownloadsCounts {
    id: string;
    flags?: IFlags;
    expires?: number;
    ctime?: number;
    name: string;
    intra_domain_share?: boolean;
}

export interface IFlags {
    DOMESTIC?: boolean;
    WRITABLE?: boolean;
    AUTODELETE?: boolean;
    DOWNLOADABLE?: boolean;
}

export interface IFileFlags {
    favorite?: boolean;
    blocked?: boolean;
    restricted?: boolean;
}

export interface Url {
    get: string;
    view: string;
    download?: string;
    downloadAPI?: string;
    media?: string;
    viewDirect?: string;
}

export interface IPublicInfo extends WeblinkDownloadsCounts {
    weblink: string;
    weblinkAccessRights: AccessRights;
    weblinkExpires: number;
    weblinkDomestic: boolean;
    weblinkAutoDelete: boolean;
    weblinkDownloadable: boolean;
    ctime: number;
}
