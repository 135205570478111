import classNames from 'clsx';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { IS_FAMILY_USER } from 'reactApp/appHelpers/configHelpers';
import { familyMembersCount } from 'reactApp/appHelpers/featuresHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFamilyLoadingState, getFamilyMemberList, getFamilyOwnerInfo } from 'reactApp/modules/family/family.selectors';
import { InviteCard } from 'reactApp/sections/FamilyPage/FamilySettings/MemberCard/InviteCard';
import { LoadingCard } from 'reactApp/sections/FamilyPage/FamilySettings/MemberCard/LoadingCard';
import { MemberCard } from 'reactApp/sections/FamilyPage/FamilySettings/MemberCard/MemberCard';

import styles from './FamilyMembers.css';

export const FamilyMembers = memo(() => {
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    const owner = useSelector(getFamilyOwnerInfo);
    const members = useSelector(getFamilyMemberList);
    const { isLoaded, isLoading } = useSelector(getFamilyLoadingState);

    // вычитаем из доступных мест владельца и текущий участников
    const freePlaces = familyMembersCount - members.length - 1;

    if (!owner) {
        return null;
    }

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_mobile]: isPhone,
            })}
        >
            {(isLoaded || !IS_FAMILY_USER) && (
                <>
                    <MemberCard isOwner {...owner} />
                    {members.map((member) => (
                        <MemberCard key={member.user} {...member} showDeleteOnClick={isPhone} />
                    ))}
                    {!isPhone && Array.from(new Array(freePlaces)).map((_, idx) => <InviteCard key={idx} />)}
                </>
            )}
            {isLoading && (
                <>
                    {Array.from(new Array(familyMembersCount)).map((_, idx) => (
                        <LoadingCard key={idx} />
                    ))}
                </>
            )}
        </div>
    );
});

FamilyMembers.displayName = 'FamilyMembers';
