import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { store } from 'reactApp/store';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

// https://docs.google.com/spreadsheets/d/1oxESW-0Ne7WtP7gKejV6CJYSv-Q9ssFDC8MY1_chDT0

export enum EButtonAction {
    SHOW = 'show',
    CLICK = 'click',
}

interface IButtonAnalytics {
    action: EButtonAction;
    /* Раздел облака, в котором показали рекламу */
    source?: string;
    /* Место показа рекламы */
    place: string;
    platform?: 'touch' | 'desktop';
}

export const sendDisableAdsAnalytics = ({ action, ...rest }: IButtonAnalytics): void => {
    const platform = rest.platform || IS_PHONE_BROWSER ? 'touch' : 'desktop';
    const storage = rest.source || getCurrentStorage(store.getState());

    sendDwh({
        eventCategory: ECategoryGa.disable_ads_button,
        action,
        dwhData: {
            ...rest,
            platform,
            storage,
        },
    });
};
