import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { sendGaUploaderNew } from 'reactApp/modules/uploading/helpers/uploading.helpers';
import { UploadingDescriptor } from 'reactApp/modules/uploading/serviceClasses/UploadingDescriptor';
import { uploadingService } from 'reactApp/modules/uploading/serviceClasses/UploadingService';
import { ERetryErrorFileOptions } from 'reactApp/modules/uploading/uploading.types';
import { EFileError, EFileStatus } from 'reactApp/modules/uploadList/uploadList.model';
import { updateUploadFilesAction } from 'reactApp/modules/uploadList/uploadList.module';
import { sendXray } from 'reactApp/utils/ga';
import { channel } from 'redux-saga';
import { put, take } from 'redux-saga/effects';

let waitChannel;

const getFilesSizeBytes = (filesOverLimit) => filesOverLimit.reduce((resSize, descriptor) => resSize + descriptor.size, 0);

const getFilesSize = (size) => bytesToNDigits(size, 3).value;

export function* handleFileSizeLimitModal({
    descriptor,
    filesOverLimit,
    uploadLimit,
}: {
    descriptor: UploadingDescriptor;
    filesOverLimit: UploadingDescriptor[];
    uploadLimit: number;
}) {
    const GB_100 = 1024 ** 3 * 100;
    const fileSize = descriptor.size;
    const descriptorId = descriptor.id;
    const cloudPath = descriptor.cloudPath;

    const retryResult: ERetryErrorFileOptions = ERetryErrorFileOptions.cancel;

    if (fileSize > GB_100) {
        sendXray(['big-file-dialog', 'skip-100-gb']);
        descriptor.failPaused();
        yield put(
            updateUploadFilesAction({
                descriptorId,
                cloudPath,
                status: EFileStatus.ERROR,
                error: EFileError.USER_FILE_SIZE_LIMIT_OVER_100_GB,
            })
        );
        return retryResult;
    }

    if (!waitChannel) {
        waitChannel = channel();
    } else {
        return ERetryErrorFileOptions.shouldRetry;
    }

    let result: any = {
        file: {
            extension: descriptor.nameParts?.extension,
            name: descriptor.cloudName,
            size: descriptor.size,
            sizeBytes: fileSize,
        },
    };

    if (filesOverLimit.length > 1) {
        const sizeOverLimit = getFilesSizeBytes(filesOverLimit);
        result = {
            files: {
                size: getFilesSize(sizeOverLimit),
                sizeBytes: sizeOverLimit,
                number: filesOverLimit.length,
            },
        };
    }

    yield put(
        updateUploadFilesAction({
            descriptorId,
            cloudPath,
            status: EFileStatus.WARNING,
            error: EFileError.OVER_QUOTA_LIMIT_DOWNLOAD,
        })
    );

    openPopupHelper({
        popupName: popupNames.UPLOAD_BIG_FILE,
        data: {
            ...result,
            numberOfFiles: uploadingService.queueForUpload.length,
            skipFileLoading: () => {
                sendGaUploaderNew('file', 'over_quota_limit_download');

                const actions = _skipUserFilesOverLimit(filesOverLimit, uploadLimit);
                waitChannel.put(actions);
            },
            continueFileLoading: () => {
                waitChannel.put(true);
            },
        },
    });

    const actions = yield take(waitChannel);
    waitChannel.close();
    waitChannel = null;

    if (Array.isArray(actions)) {
        for (const action of actions) {
            yield put(action);
        }
    } else if (actions === true) {
        return ERetryErrorFileOptions.shouldRetryImmediately;
    }

    return retryResult;
}

function _skipUserFilesOverLimit(filesOverLimit: UploadingDescriptor[], uploadLimit: number) {
    const actions: any[] = [];
    filesOverLimit.forEach((descriptor) => {
        const fileSize = descriptor.size;

        if (fileSize >= uploadLimit) {
            actions.push(
                updateUploadFilesAction({
                    descriptorId: descriptor.id,
                    cloudPath: descriptor.cloudPath,
                    status: EFileStatus.ERROR,
                    error: EFileError.CANCELLED_FILE,
                    hideError: true,
                })
            );
            uploadingService.cancel(descriptor.id);
        }
    });

    return actions;
}
