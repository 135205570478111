import { PayloadAction } from '@reduxjs/toolkit';
import localStore from 'lib/store';
import { ALLOWED_SORTS, IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { loadHomeFolderRequest } from 'reactApp/modules/home/home.actions';
import { getCurrentRouteId, getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { itemSortKey } from 'reactApp/modules/sort/sort.helpers';
import { initSort, initSortSuccess, setSort } from 'reactApp/modules/sort/sort.module';
import { ESortOder, ESortTypes, SetSortAction } from 'reactApp/modules/sort/sort.types';
import { isCameraUploadsFolder } from 'reactApp/modules/storage/folder.helpers';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getCurrentFolder as getCurrentFolderAction } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { noAuthUser, UserStorageActions } from 'reactApp/modules/user/userStorage';
import { Sort } from 'reactApp/types/Tree';
import { put, select, takeEvery } from 'redux-saga/effects';

const DEFAULT_ASC_SORT: Sort = {
    type: ESortTypes.name,
    order: ESortOder.asc,
};

const DEFAULT_CA_SORT = {
    type: ESortTypes.mtime,
    order: ESortOder.desc,
};

const DEFAULT_SORT = {
    type: null,
};

export function* initSortSaga() {
    const email = (yield select(UserSelectors.getEmail)) ?? noAuthUser;
    const id = yield select(getCurrentRouteId);

    const initialSort = localStore.get(`${email}|${itemSortKey}`) || {};
    const querySort = getQueryParams()?.sort;

    if (id && querySort && ALLOWED_SORTS.includes(querySort)) {
        const [type, order] = querySort.split('-');
        initialSort[id] = { type, order };
    }

    yield put(initSortSuccess(initialSort));
}

function* setSortSaga(action: PayloadAction<SetSortAction>) {
    const {
        payload: { id, sort },
    } = action;

    if (!id) {
        return;
    }

    const item = yield select(getCurrentFolderAction);
    const storage = yield select(getCurrentStorage);
    const { isSearch, isTrashBin } = getStorage(storage);

    let itemsSort;

    if ((isTrashBin && !IS_BIZ_USER) || isSearch) {
        itemsSort = DEFAULT_SORT;
    } else {
        itemsSort = (yield put(UserStorageActions.get(itemSortKey))) || {};
    }

    const isCa = isCameraUploadsFolder(item);

    const defaultSort: Sort = isCa ? DEFAULT_CA_SORT : isTrashBin || isSearch ? DEFAULT_SORT : DEFAULT_ASC_SORT;

    if (!sort || (!isCa && sort.type === defaultSort.type && sort.order === defaultSort.order)) {
        delete itemsSort[id];
    } else {
        itemsSort[id] = sort;
    }

    yield put(UserStorageActions.set(itemSortKey, itemsSort));

    if (storage === EStorageType.home && item?.hasMoreToLoad) {
        // Если в папке есть что дозагружать, то надо заново дергать апи с новой сортировкой
        yield put(loadHomeFolderRequest({ id, isFolder: true, force: true }));
    }
}

export function* watchSortRoot() {
    yield takeEvery(initSort.toString(), initSortSaga);
    yield takeEvery(setSort.toString(), setSortSaga);
}
