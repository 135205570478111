import { Method } from 'axios';
import { APICallV4 } from 'reactApp/api/APICall';

export class StoryListApiCall extends APICallV4 {
    _method = 'story/list';
    _type: Method = 'get';
    _retriesCount = 0;
}

export class StoryMarkViewApiCall extends APICallV4 {
    _method = 'story/view';
    _type: Method = 'post';
    _retriesCount = 0;
}

export class StoryInfoApiCall extends APICallV4 {
    _method = 'story';
    _type: Method = 'get';
    _retriesCount = 0;
}

export class StoryActivateApiCall extends APICallV4 {
    _method = 'story/user/activate';
    _type: Method = 'post';
    _retriesCount = 0;
}

export class StoryUnviewedCountApiCall extends APICallV4 {
    _method = 'story/unviewed/count';
    _type: Method = 'get';
    _retriesCount = 0;
}

export class StoryUnviewedResetApiCall extends APICallV4 {
    _method = 'story/unviewed/reset';
    _type: Method = 'post';
    _retriesCount = 0;
}
