import classNames from 'clsx';
import React, { memo, ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDomainFoldersFilterActive } from 'reactApp/modules/home/home.actions';
import { getDomainFoldersFilterActive } from 'reactApp/modules/home/home.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { changeStorageHistory } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { Link } from 'reactApp/ui/Link/Link';
import { ETreeRootIds } from 'reactApp/ui/TreeComponent/TreeComponent.constants';
import opener from 'reactApp/utils/opener';

import styles from './MenuItem.css';

interface Props {
    id: string;
    title: string;
    icon?: ReactElement;
    storage: EStorageType;
    onClose: () => void;
    href?: string;
}

export const MenuItem = memo(({ id, title, icon, storage, onClose, href }: Props): ReactElement => {
    const currentStorage = useSelector(getCurrentStorage);
    const domainFoldersFilterActive = useSelector(getDomainFoldersFilterActive);
    const dispatch = useDispatch();
    const isDomainFolders = id === ETreeRootIds.domain;
    const isCurrent =
        (isDomainFolders && domainFoldersFilterActive) ||
        (currentStorage === storage && !(isDomainFolders || domainFoldersFilterActive) && currentStorage !== EStorageType.subscriptions);
    const linkId = isDomainFolders ? ETreeRootIds.home : id;

    const handleGoTo = useCallback(() => {
        opener(id, true);
    }, [id]);

    const handleClick = useCallback(() => {
        onClose();

        dispatch(setDomainFoldersFilterActive(isDomainFolders));

        if (currentStorage === EStorageType.public) {
            changeStorageHistory({ navigateToStorage: storage });
        }
    }, [onClose, dispatch, isDomainFolders, currentStorage, storage]);

    return (
        <Link id={linkId} href={href} storage={storage} handleGoTo={handleGoTo} handleClick={handleClick}>
            <div onClick={handleClick} data-qa-item={id} className={classNames(styles.root, { [styles.root_active]: isCurrent })}>
                {icon && <div className={styles.icon}>{icon}</div>}
                <div className={styles.title}>{title}</div>
            </div>
        </Link>
    );
});

MenuItem.displayName = 'MenuItem';
