import { ALBUMS_FOLDER_NAME } from 'reactApp/modules/albums/albums.constants';
import { PERSONAL_DOCUMENTS_FOLDER_NAME } from 'reactApp/modules/personalDocuments/personalDocuments.constants';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

/**
 * Папки, которые нужно создать перед тем, как начать загружать файлы в разделе
 */
export const mapStorageToFolder = {
    [EStorageType.documents]: PERSONAL_DOCUMENTS_FOLDER_NAME,
    [EStorageType.albums]: ALBUMS_FOLDER_NAME,
};

export const LOW_SPEED_LIMIT = 0.2;
export const SMART_UPLOADED_BYTES_PERCENT_LIMIT = 0.2;
