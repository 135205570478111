import { AxiosResponse, Method } from 'axios';
import { APICallV2Post, APICallV4 } from 'reactApp/api/APICall';
import { apiMigrateFileV2ToV4 } from 'reactApp/appHelpers/featuresHelpers';

const method = 'file/unpublish';

class UnPublishFileAPIV2Call extends APICallV2Post {
    _method = method;
}

class UnPublishFileAPIV4Call extends APICallV4 {
    _method = method;
    _type: Method = 'post';
}

export const unpublishFileApiCall = (params: Record<string, any>): Promise<AxiosResponse> => {
    if (apiMigrateFileV2ToV4.unpublish) {
        return new UnPublishFileAPIV4Call().makeRequest(params);
    }

    return new UnPublishFileAPIV2Call().makeRequest(params);
};
