import config from 'Cloud/config';
import React, { memo, ReactElement, useCallback, useMemo } from 'react';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { BaseInputDialog } from 'reactApp/components/BaseConfirmDialog/BaseInputDialog';
import { NEW_FOLDER_DEFAULT_NAME } from 'reactApp/constants/magicIdentificators';
import { noop } from 'reactApp/utils/helpers';

import { IProps } from './NewFolderModal.types';

const MAX_NAME_LENGTH = config.get('ITEM_NAME_MAX_LENGTH');

export const NewFolderModal = memo(
    ({
        showShareButton,
        showCreateButton,
        createFolder,
        onClose,
        title = 'Создание папки',
        placeholder,
        folderName = NEW_FOLDER_DEFAULT_NAME,
    }: IProps): ReactElement => {
        const buttons = useMemo(() => {
            if (showCreateButton && showShareButton) {
                return [EButtonType.accept, EButtonType.action];
            }

            return [EButtonType.accept];
        }, [showCreateButton, showShareButton]);

        const onSuccessCallback = useCallback(
            (folderName) => {
                return createFolder(folderName, !showCreateButton);
            },
            [createFolder, showCreateButton]
        );

        const onSuccessAndShareCallback = useCallback(
            (folderName) => {
                return createFolder(folderName, true);
            },
            [createFolder]
        );

        return (
            <BaseInputDialog
                dataQAId="new-folder-modal"
                renderHeader={() => title}
                successButtonText={showCreateButton ? 'Создать' : 'Создать и поделиться'}
                actionButtonText="Создать и поделиться"
                buttons={buttons}
                onAction={onSuccessAndShareCallback}
                onSuccess={onSuccessCallback}
                selectionRange={folderName.length}
                maxLength={MAX_NAME_LENGTH}
                value={folderName}
                onClose={onClose}
                renderContent={noop}
                placeholder={placeholder}
            />
        );
    }
);

NewFolderModal.displayName = 'NewFolderModal';
