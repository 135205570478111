import imageSrc from 'img/empty-folder/archive-viewer.png';
import React from 'react';

import styles from './EmptyFolder.css';

export const EmptyFolder = ({ error }: { error?: string }) => (
    <div className={styles.emptyFolder}>
        <img src={imageSrc} alt="Пустая папка" />
        <div className={styles.emptyFolderTitle}>{error ? 'Ошибка' : 'Пустая папка'}</div>
        <div className={styles.emptyFolderSubtitle}>{error ? 'Что-то пошло не так' : 'В папке еще ничего нет'}</div>
    </div>
);
