import { APICallV3Post } from 'reactApp/api/APICall';

/**
 * Класс, возвращающий инфу о частях файла pdf
 * Response:
 * {
 *  pages_total: int,
 *  parts_total: int,
 *  parts:
 *      [
 *          {
 *              id: string,
 *              page_from: int,
 *              page_to: int
 *          },
 *      ]
 * }
 */
export class PdfInfoAPICall extends APICallV3Post {
    _method = '/ovidius/pdf/parts';
    _responseType = 'arraybuffer' as const;
    _withCredentials = true;
}

export class NewPdfInfoAPICall extends APICallV3Post {
    _method = '/ovidius/client_cdc/pdf/parts';
    _responseType = 'arraybuffer' as const;
    _withCredentials = true;
}
