import { ServerError } from 'reactApp/errors';

import { UploadError } from './UploadError';

export class HttpError extends UploadError {
    responseText = '';
    status = -1;

    constructor(stack: Error, source: string, status: number, responseText: string | null) {
        super(stack, source);

        this.message = this.message.replace('{STATUS}', status.toString());
        this.radarName = this.radarName.replace('{STATUS}', status.toString());
        this.responseText = ServerError.removeHTML(responseText || '').trim().slice(0, 1000);
        this.status = status;
    }

    radarName = 'error_{SOURCE}_http-{STATUS}';
    message = 'Ошибка HTTP {STATUS}';

    toLogStringPattern = '[{TIME}] {ID} {SOURCE} {CLASS_NAME} { {META} }: {STATUS} – {RESPONSE_TEXT}';

    toLogString = () => {
        return super.toLogString()
            .replace('{STATUS}',this.status.toString())
            .replace('{RESPONSE_TEXT}', this.responseText)
    };

    getDetails = () => {
        const details = super.getDetails();

        details.status = this.status;
        details.responseText = this.responseText;

        return details;
    };
}
