import { RefObject, useCallback, useEffect } from 'react';

export interface IClickAwayProps {
    rootRef?: RefObject<HTMLElement>;
    ref?: RefObject<HTMLElement>;
    onClickAway: () => void;
}

export const useClickOutsideTarget = ({ rootRef, ref, onClickAway }: IClickAwayProps) => {
    const onDocumentClick = useCallback(
        (event: Event) => {
            let closeIt = true;
            let target = event.target as HTMLElement;
            while (target && target.parentNode !== document) {
                if ((rootRef && target === rootRef.current) || (ref && target === ref.current)) {
                    closeIt = false;
                    break;
                }
                target = target.parentNode as HTMLElement;
            }

            if (!closeIt) {
                return;
            }

            onClickAway();
        },
        [ref, rootRef, onClickAway]
    );

    useEffect(() => {
        document.addEventListener('click', onDocumentClick, { capture: true });

        return () => {
            document.removeEventListener('click', onDocumentClick, { capture: true });
        };
    }, [onDocumentClick]);
};
