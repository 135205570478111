import React, { MouseEventHandler, VFC } from 'react';
import { ContentEditable } from 'reactApp/ui/ContentEditable/ContentEditable';

type Props = {
    fileName?: string;
    readOnly: boolean;
    onEdit?: (value: { value: string }) => Promise<any>;
    onClick?: MouseEventHandler<HTMLDivElement>;
};

export const FileName: VFC<Props> = ({ readOnly, fileName, onClick, onEdit }) => {
    if (readOnly) {
        if (!fileName) {
            return null;
        }

        return <div>{fileName}</div>;
    }

    return <ContentEditable value={fileName} onEdit={onEdit} onClick={onClick} />;
};
