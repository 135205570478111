import { APICallV3Post } from 'reactApp/api/APICall';

/**
 * Класс, возвращающий инфу об одной части файла pdf
 */
export class PdfViewPartAPICall extends APICallV3Post<ArrayBuffer | null> {
    _method = '/ovidius/pdf/view_part';
    _responseType = 'arraybuffer' as const;
    _withCredentials = true;
}

export class NewPdfViewPartAPICall extends APICallV3Post<ArrayBuffer | null> {
    _method = '/ovidius/client_cdc/pdf/view_part';
    _responseType = 'arraybuffer' as const;
    _withCredentials = true;
}
