import classNames from 'clsx';
import React, { memo } from 'react';

import styles from './ProgressBar.css';

interface IProps {
    percentLoaded: number;
    progressComplete: boolean;
    warning: boolean;
    hasSuccessFiles: boolean;
    isMobile?: boolean;
}

export const ProgressBar = memo(({ percentLoaded, progressComplete, hasSuccessFiles, warning, isMobile = false }: IProps) => {
    return (
        <div className={classNames({ [styles.root]: true, [styles.root_mobile]: isMobile })}>
            <div
                className={classNames({
                    [styles.color]: true,
                    [styles.complete]: progressComplete,
                    [styles.warning]: warning,
                    [styles.empty]: progressComplete && !hasSuccessFiles,
                })}
                style={{ width: `${percentLoaded}%` }}
            />
        </div>
    );
});

ProgressBar.displayName = 'UploadListProgressBar';
