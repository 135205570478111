import classNames from 'clsx';
import React, { memo, useCallback, useEffect, useMemo, VFC } from 'react';
import { useSelector } from 'react-redux';
import { getFacesListRequestState, getSelectedFaceItem, sortFacesByCount } from 'reactApp/modules/faces/faces.selectors';
import { usePublicFaceRadars } from 'reactApp/ui/FaceFilter/hooks/publicFaceRadars';
import { renderFilterFacesModal } from 'reactApp/ui/Mobile/FilterFacesModal/FilterFacesModal.helpers';

import styles from './FacesCounter.css';

const MAX_FIRST_NUMBER_FACES_IN_FILTER = 3;

interface IFaceItem {
    url: string;
}

const FaceItem: VFC<IFaceItem> = ({ url }) => (
    <div className={styles.face}>
        <img src={url} className={styles.img} />
    </div>
);

export const FacesCounter = memo(({ isOptionsModal = false }: { isOptionsModal?: boolean }) => {
    const faces = useSelector(sortFacesByCount);
    const selectedFace = useSelector(getSelectedFaceItem);
    const requestState = useSelector(getFacesListRequestState);

    const showCounter = faces.length > 3;
    const counterText = faces.length < 99 ? faces.length : '99+';

    const facesToShow = useMemo(() => {
        if (!faces.length) {
            return [];
        }

        if (faces.length > MAX_FIRST_NUMBER_FACES_IN_FILTER) {
            return faces.slice(0, MAX_FIRST_NUMBER_FACES_IN_FILTER - 1);
        }

        return faces;
    }, [faces]);

    const { sendFacesHelper } = usePublicFaceRadars({ countFaces: faces?.length });

    const onClick = useCallback(() => {
        renderFilterFacesModal();
    }, []);

    useEffect(() => {
        if (!requestState.isLoaded) {
            return;
        }
        sendFacesHelper('face_filter', true);
    }, [requestState.isLoaded, sendFacesHelper]);

    const content = useMemo(() => {
        if (selectedFace) {
            return <FaceItem url={selectedFace.imageUrl} />;
        }

        return (
            <>
                {facesToShow.map((face) => (
                    <FaceItem key={face.id} url={face.imageUrl} />
                ))}
                {showCounter && <div className={classNames(styles.face, styles.counter)}>{counterText}</div>}
            </>
        );
    }, [counterText, facesToShow, selectedFace, showCounter]);

    if (!faces.length) {
        return null;
    }
    return (
        <div className={classNames(styles.root, { [styles.root_dark]: isOptionsModal })} onClick={onClick}>
            {content}
        </div>
    );
});

FacesCounter.displayName = 'FacesCounter';
