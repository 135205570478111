import React from 'react';

import { List } from './List/List';
import { LogLoader } from './LogLoader/Loader';
import { Stub } from './Stub/Stub';
import { useFileFoldersVersionLog } from './useFileFoldersVersionLog';

type FileFoldersVersionLogProps = {
    itemId: string;
};
export const FileFoldersVersionLog: React.FC<FileFoldersVersionLogProps> = ({ itemId }) => {
    const { items, isLoading, hasError, reloadHandler } = useFileFoldersVersionLog({ itemId });
    const STUB_CAPTION = 'Ошибка загрузки данных';
    if (isLoading) {
        return <LogLoader />;
    }

    if (hasError) {
        return <Stub reloadHandler={reloadHandler} caption={STUB_CAPTION} />;
    }

    return <List items={items} />;
};
