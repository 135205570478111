import { PayloadAction } from '@reduxjs/toolkit';
import { logger } from 'lib/logger';
import { shareApiCall, shareV4ApiCall } from 'reactApp/api/sharing/ShareApiCall';
import { IInvite } from 'reactApp/api/sharing/SharedInfoApiCall';
import { UnshareApiCall } from 'reactApp/api/sharing/UnshareApiCall';
import { IS_B2B_BIZ_USER, IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';
import { EAccessRights } from 'reactApp/components/SharingNewBiz/SharingNew.types';
import { ON_PREMISE_WHITELIST_DOMAINS } from 'reactApp/constants/whiteListDomains';
import { contactsSlice } from 'reactApp/modules/contacts/contacts.module';
import { getOwner } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.selectors';
import { shareFolderSuccess, unshareFolderSuccess } from 'reactApp/modules/modifying/modifying.actions';
import { getInfo } from 'reactApp/modules/modifying/sagas/mount.saga';
import { loadSharedLinksStartRequest } from 'reactApp/modules/shared/shared.module';
import { getItemById, getStorageItemById } from 'reactApp/modules/storage/storage.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { validateEmail, validateEmailWhiteListDomain } from 'reactApp/utils/helpers';
import { all, delay, put, select, takeEvery } from 'redux-saga/effects';

import { showSnackbarAction } from '../snackbar/snackbar.actions';
import { SnackbarTypes } from '../snackbar/snackbar.types';
import { EStorageType } from '../storage/storage.types';
import {
    accessControlListLoadStart,
    deleteAllUsers,
    deleteUser,
    setAccessControlListError,
    setAccessRight,
    setInvitesSuccess,
} from './folderAccessControlList.actions';
import { getSharingError } from './folderAccessControlList.helpers';
import {
    IAccessControlListLoadStart,
    IDeleteAllUser,
    IDeleteUser,
    ISetAccessRight,
    IUnshareRequest,
} from './folderAccessControlList.types';

const unshareApiCall = ({ id, email }) => new UnshareApiCall().makeRequest({ home: id, invite: { email } });

function* handleUnshareRequest({ id, email, name, isMultipleUnshare }: IUnshareRequest) {
    const userData = name && name !== email ? name : email;
    const successSnackbarText = isMultipleUnshare
        ? 'Доступ к папке закрыт для всех пользователей'
        : `Доступ к папке для пользователя ${userData} закрыт`;
    const failureSnackbarText = isMultipleUnshare
        ? 'Не удалось закрыть доступ к папке для всех пользователей'
        : `Не удалось закрыть доступ к папке для пользователя ${userData}`;

    try {
        const { data } = yield unshareApiCall({ id, email });

        if (IS_B2B_BIZ_USER) {
            yield put(
                showSnackbarAction({
                    id: 'user-unshare-success',
                    type: SnackbarTypes.success,
                    text: successSnackbarText,
                    closable: true,
                })
            );
        }

        return { info: data.body };
    } catch (error) {
        logger.error(error);
        if (IS_B2B_BIZ_USER) {
            yield put(
                showSnackbarAction({
                    id: 'user-unshare-failure',
                    type: SnackbarTypes.failure,
                    text: failureSnackbarText,
                    closable: true,
                })
            );
        }
    }
}

function* loadStart(action: PayloadAction<IAccessControlListLoadStart>) {
    const { id, storage } = action.payload;

    let item = yield select((state) => (storage ? getStorageItemById(state, EStorageType[storage], id) : getItemById(state, id)));

    const timer = Date.now();
    while (!item) {
        if (Date.now() - timer > 10 * 1000) {
            yield put(setAccessControlListError({ error: 'Не удалось получить список' }));
            return;
        }
        yield delay(1000);
        item = yield select((state) => getItemById(state, id));
    }

    const { info, error } = yield getInfo(item);

    if (error) {
        yield put(setAccessControlListError({ error: 'Не удалось получить список' }));
        return;
    }

    const userEmail = yield select(UserSelectors.getEmail);
    const owner = info.owner || { email: userEmail };

    yield put(setInvitesSuccess({ invited: info.invited, owner }));
}

function* setAccessRightRequest(action: PayloadAction<ISetAccessRight>) {
    const { additionalWhitelistDomains, accessRight, email, folderId } = action.payload;
    const currentEmail = email.trim();
    const owner = yield select(getOwner);
    if (IS_B2B_BIZ_USER && currentEmail === owner.email) {
        yield put(
            setAccessControlListError({
                error: 'Данный пользователь уже имеет доступ к папке',
            })
        );
        return;
    }

    if (!validateEmail(email)) {
        yield put(
            setAccessControlListError({
                error: 'Вы ввели неправильные символы. Убедитесь в правильном написании электронного адреса и попробуйте снова',
            })
        );
        return;
    }

    // валидируем домены только в коробке, и только когда задан нужный конфиг
    if (IS_ONPREMISE && ON_PREMISE_WHITELIST_DOMAINS && !validateEmailWhiteListDomain(email, ON_PREMISE_WHITELIST_DOMAINS)) {
        yield put(setAccessControlListError({ error: 'Вы можете открыть доступ к папке только другому пользователю домена' }));
        return;
    }

    if (IS_B2B_BIZ_USER && additionalWhitelistDomains && !validateEmailWhiteListDomain(email, additionalWhitelistDomains)) {
        yield put(setAccessControlListError({ error: 'Открыть доступ к папке можно только пользователям внутри домена' }));
        return;
    }

    yield put(contactsSlice.actions.addRequest([{ emails: [email] }]));

    try {
        const { data } = IS_ONPREMISE
            ? yield shareV4ApiCall({ path: folderId, email: currentEmail, access: accessRight })
            : yield shareApiCall({ id: folderId, email: currentEmail, access: accessRight });
        const snackbarText = `Приглашение отправлено с доступом для ${
            accessRight === EAccessRights.READ_ONLY ? 'просмотра' : 'редактирования'
        }`;
        const info = data.body || data;

        yield put(setInvitesSuccess({ invited: info.invited }));
        yield put(shareFolderSuccess({ id: folderId }));
        yield put(loadSharedLinksStartRequest());
        if (IS_B2B_BIZ_USER) {
            yield put(
                showSnackbarAction({
                    id: 'user-share-access-rights-success',
                    type: SnackbarTypes.success,
                    text: snackbarText,
                    closable: true,
                })
            );
        }
    } catch (error) {
        logger.error(error);

        const isVerifiedAccount = yield select(UserSelectors.isVerifiedAccount);
        yield put(setAccessControlListError({ error: getSharingError(error, isVerifiedAccount) }));
    }
}

function* handleDeleteUser(action: PayloadAction<IDeleteUser>) {
    const { item, email, name } = action.payload;
    const { info } = yield getInfo(item);
    const id = info.home;

    if (!info?.invited?.length) {
        yield put(setInvitesSuccess({ invited: [] }));
        yield put(unshareFolderSuccess({ id }));
        return;
    }

    const { info: newInfo } = yield handleUnshareRequest({ id: item.id, email, name });

    yield put(setInvitesSuccess({ invited: newInfo.invited || [] }));

    if (!newInfo.invited?.length) {
        yield put(unshareFolderSuccess({ id }));
    }
}

function* handleDeleteAllUsers(action: PayloadAction<IDeleteAllUser>) {
    const { item } = action.payload;

    const { info } = yield getInfo(item);
    const id = info.home;

    if (info?.invited?.length) {
        yield all(
            info.invited.map((invite: IInvite) =>
                handleUnshareRequest({ id: item.id, email: invite.email, name: invite.name, isMultipleUnshare: true })
            )
        );
    }

    yield put(setInvitesSuccess({ invited: [] }));
    yield put(unshareFolderSuccess({ id }));
}

export function* watchAccessControlList() {
    yield takeEvery(accessControlListLoadStart.toString(), loadStart);
    yield takeEvery(setAccessRight.toString(), setAccessRightRequest);
    yield takeEvery(deleteUser.toString(), handleDeleteUser);
    yield takeEvery(deleteAllUsers.toString(), handleDeleteAllUsers);
}
