/**
 * Allows to require private namespace "_" as module:
 * var _ = require('Cloud/Application/_');
 */
define(function(require, exports, module) {
	'use strict';

	var config = require('Cloud/config');

	module.exports = {
		v2: config.get('API_V2'),
		v4: config.get('API_V4')
	};
});
