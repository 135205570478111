import { Icon20CopyOutline, Icon20ThumbsUpOutline } from '@vkontakte/icons';
import { Button, IconButton, SizeType } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLlmDialogState } from 'reactApp/modules/editor/editor.selectors';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { LLMAnalyticsType, sendTechLLMAnalytics, useGetLLMAnalytics } from 'reactApp/ui/EditorLLM/analytics/EditorLlmAnalytics';
import { ActionsSelect } from 'reactApp/ui/EditorLLM/EditorLLMDialog/components/Messages/components/Message/components/ActionsBlock/components/ActionsSelect/ActionsSelect';
import { Message, ReactionType } from 'reactApp/ui/EditorLLM/EditorLLMDialog/types/EditorLlmDialog.types';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { Icon20ThumbsDownOutline } from 'reactApp/ui/VKUIIcons';
import { copy } from 'reactApp/utils/copyToClipboard';

import styles from './ActionsBlock.css';

interface Props {
    message: Message['message'];
    dataQaId: string;
}

export const ActionsBlock: FC<Props> = ({ message, dataQaId }) => {
    const sendAnalytics = useGetLLMAnalytics();
    const { selectType } = useSelector(getLlmDialogState);
    const [reaction, setReaction] = useState<ReactionType | null>(null);

    const copyHandler = () => {
        copy(message)
            .then(({ hasBeenCopied }) => {
                if (hasBeenCopied) {
                    const macOs = navigator.userAgent.toLowerCase().includes('mac');

                    snackbarController.showSnackbar({
                        id: 'copy-text-from-llm-dialog-success',
                        closable: true,
                        title: 'Текст скопирован',
                        text: `Добавьте его в документ: ${macOs ? 'cmd' : 'ctrl'} + v`,
                        type: SnackbarTypes.success,
                        closeTimeout: 5000,
                    });

                    sendAnalytics({ action: LLMAnalyticsType.ANSWER_COPY, dwh: { scenario: selectType.value } });
                    sendTechLLMAnalytics({ action: LLMAnalyticsType.ANSWER_COPY, result: 'success' });
                }
            })
            .catch(() => {
                snackbarController.showSnackbar({
                    id: 'copy-text-from-llm-dialog-error',
                    closable: true,
                    title: 'Текст не скопирован',
                    text: 'Что-то пошло не так, попробуйте еще.',
                    type: SnackbarTypes.failure,
                    closeTimeout: 5000,
                });

                sendTechLLMAnalytics({ action: LLMAnalyticsType.ANSWER_COPY, result: 'error' });
            });
    };

    const onReactionClick = (reactionType: ReactionType) => {
        setReaction(reactionType);

        const action = reactionType === ReactionType.THUMBS_UP ? LLMAnalyticsType.ANSWER_RATE_GOOD : LLMAnalyticsType.ANSWER_RATE_BAD;

        sendAnalytics({
            action,
            dwh: { scenario: selectType.value },
        });
        sendTechLLMAnalytics({ action });
    };

    return (
        <div className={styles.actions} data-qa-id={`${dataQaId}_actionBlock`}>
            <Button
                className={styles.button_copy}
                size="s"
                mode="tertiary"
                appearance="neutral"
                sizeY={SizeType.COMPACT}
                before={<Icon20CopyOutline />}
                onClick={copyHandler}
                data-qa-id={`${dataQaId}_copyButton`}
            >
                Скопировать
            </Button>
            <div className={styles.reactions} data-qa-id={`${dataQaId}_reactions`}>
                <Hint text="Хороший ответ">
                    <IconButton
                        className={classNames(styles.iconButton, { [styles.iconButton_active]: reaction === ReactionType.THUMBS_UP })}
                        disabled={!!reaction}
                        onClick={() => onReactionClick(ReactionType.THUMBS_UP)}
                        data-qa-id={`${dataQaId}_thumbsUp`}
                    >
                        <Icon20ThumbsUpOutline />
                    </IconButton>
                </Hint>
                <Hint text="Плохой ответ">
                    <IconButton
                        className={classNames(styles.iconButton, { [styles.iconButton_active]: reaction === ReactionType.THUMBS_DOWN })}
                        disabled={!!reaction}
                        onClick={() => onReactionClick(ReactionType.THUMBS_DOWN)}
                        data-qa-id={`${dataQaId}_thumbsDown`}
                    >
                        <Icon20ThumbsDownOutline />
                    </IconButton>
                </Hint>
                <ActionsSelect message={message} dataQaId={dataQaId} />
            </div>
        </div>
    );
};
