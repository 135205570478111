import {
    Icon20ArchiveOutline,
    Icon20ArticleBoxOutline,
    Icon20DocumentOutline,
    Icon20DocumentTextOutline,
    Icon20FolderOutline,
    Icon20MusicOutline,
    Icon20PictureOutline,
    Icon20Search,
    Icon20TextOutline,
    Icon20VideoSquareOutline,
} from '@vkontakte/icons';
import React, { ChangeEvent, KeyboardEvent } from 'react';
import { IHistoryItem } from 'reactApp/modules/search/search.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

export interface IState {
    active: boolean;
    focus: boolean;
    open: boolean;
    value: string;
    type: ESearchOptionType;
    source: ESearchOptionSource;
    forceSearch: boolean;
}

export enum EActions {
    close = 'close',
    setFocus = 'setFocus',
    setActive = 'setActive',
    setValue = 'setValue',
    setFilters = 'setFilters',
    setPopupFilters = 'setPopupFilters',
    setOpenFilters = 'setOpenFilters',
    toggleFilters = 'toggleFilters',
    searchValue = 'searchValue',
    setForceSearch = 'setForceSearch',
}

export interface IFilters {
    type?: ESearchOptionType;
    source?: ESearchOptionSource;
}

export interface IAction {
    type: EActions;
    payload?: boolean | string | IFilters;
}

export interface ISearchProps {
    dropdownAvailable?: boolean;
    toggleSearch: (state: boolean) => void;
    isExpanded: boolean;
    onlyExpanded?: boolean;
    storage: EStorageType;
}

export interface ISearchContent {
    isFocus: boolean;
    isOpen: boolean;
    value: string;
    type: ESearchOptionType;
    source: ESearchOptionSource;
    placeholder: string;
    onSearchClick: () => void;
    onInputClick: () => void;
    onClickAway: () => void;
    onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onInputClear: () => void;
    onInputBlur: () => void;
    searchValue: (value: string) => void;
    onKeyUp: (event: KeyboardEvent) => void;
    onToggleFilters: () => void;
    setActiveInput: () => void;
    setFilters: ({ type, source }: IFilters) => void;
    setFiltersClose: () => void;
    historyItems: IHistoryItem[];
}

export interface IWebFilters {
    type: ESearchOptionType;
    source: ESearchOptionSource;
    onSelect: (
        { query, type, source }: { query?: string; type?: ESearchOptionType; source?: ESearchOptionSource },
        force?: boolean
    ) => void;
    onReset: (force?: boolean) => void;
}

export enum ESearchOptionType {
    image = 'image',
    audio = 'audio',
    video = 'video',
    document = 'document',
    archive = 'archive',
    folder = 'folder',
    all = 'all',
}

export const fileTypeOptions = {
    [ESearchOptionType.all]: {
        id: ESearchOptionType.all,
        text: 'Все файлы',
        icon: <Icon20DocumentOutline />,
    },
    [ESearchOptionType.image]: {
        id: ESearchOptionType.image,
        text: 'Изображения',
        icon: <Icon20PictureOutline />,
    },
    [ESearchOptionType.document]: {
        id: ESearchOptionType.document,
        text: 'Документы',
        icon: <Icon20DocumentTextOutline />,
    },
    [ESearchOptionType.audio]: {
        id: ESearchOptionType.audio,
        text: 'Музыка',
        icon: <Icon20MusicOutline />,
    },
    [ESearchOptionType.video]: {
        id: ESearchOptionType.video,
        text: 'Видео',
        icon: <Icon20VideoSquareOutline />,
    },
    [ESearchOptionType.folder]: {
        id: ESearchOptionType.folder,
        text: 'Папки',
        icon: <Icon20FolderOutline />,
    },
    [ESearchOptionType.archive]: {
        id: ESearchOptionType.archive,
        text: 'Архивы',
        icon: <Icon20ArchiveOutline />,
    },
} as const;

export const fileTypeOptionsList = Object.values(fileTypeOptions);
export const typesOptionsList = Object.values(fileTypeOptions).filter((item) => item.id !== 'all');

export enum ESearchOptionSource {
    name = 'filename',
    content = 'text_content',
    all = 'filename,text_content',
}

export const sourceTypeOptions = {
    [ESearchOptionSource.all]: {
        id: ESearchOptionSource.all,
        text: 'Искать везде',
        icon: <Icon20Search />,
    },
    [ESearchOptionSource.name]: {
        id: ESearchOptionSource.name,
        text: 'В названии',
        icon: <Icon20TextOutline />,
    },
    [ESearchOptionSource.content]: {
        id: ESearchOptionSource.content,
        text: 'В содержании',
        icon: <Icon20ArticleBoxOutline />,
    },
} as const;

export const sourceTypeOptionsList = Object.values(sourceTypeOptions);
