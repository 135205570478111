export const tooltipPlacements = {
    RIGHT: 'right',
    RIGHT_TOP: 'rightTop',
    RIGHT_BOTTOM: 'rightBottom',
    LEFT: 'left',
    LEFT_TOP: 'leftTop',
    LEFT_BOTTOM: 'leftBottom',
    TOP: 'top',
    TOP_RIGHT: 'topRight',
    TOP_LEFT: 'topLeft',
    BOTTOM: 'bottom',
    BOTTOM_RIGHT: 'bottomRight',
    BOTTOM_LEFT: 'bottomLeft',
};
