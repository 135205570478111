import { useEffect } from 'react';
import { O2AuthClient } from 'reactApp/api/O2AuthClient';
import { useO2AuthClient } from 'reactApp/hooks/useO2AuthClient';
import videojs from 'video.js';

export const usePlayerO2Header = () => {
    const { token } = useO2AuthClient();

    useEffect(() => {
        if (!token) {
            return;
        }

        videojs.Vhs.xhr.beforeRequest = (options) => {
            options.headers = {
                ...options.headers,
                ...O2AuthClient.prepareAuthHeader(token),
            };

            return options;
        };
    }, [token]);
};
