import { captureException } from '@sentry/browser';
import { logger } from 'lib/logger';
import { BindCreateAPICall } from 'reactApp/api/billing/bind/BindCreateAPICall';
import { IS_MOBILE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { BillingBuyTemplates } from 'reactApp/modules/buySubscription/buySubscription.types';
import { CreditCardDmrLinkActions } from 'reactApp/modules/creditCardDmrLink/creditCardDmrLink.module';
import { IApiCreateResponse } from 'reactApp/modules/creditCardDmrLink/creditCardDmrLink.types';
import { getFeatureAddCardSkin, getFeatureAddCardSkinTouch } from 'reactApp/modules/features/features.selectors';
import { call, cancel, put, select, takeEvery } from 'redux-saga/effects';

function* addCard() {
    const skinDesktop = yield select(getFeatureAddCardSkin);
    const skinTouch = yield select(getFeatureAddCardSkinTouch);
    const skin = (IS_MOBILE_BROWSER ? skinTouch : skinDesktop) || BillingBuyTemplates.CHECKOUT_VKPAY;

    try {
        const { data } = yield call(() => new BindCreateAPICall().makeRequest({ skin }));
        yield put(CreditCardDmrLinkActions.addCardSuccess(data as IApiCreateResponse));
    } catch (error) {
        yield put(CreditCardDmrLinkActions.hasError());
        logger.error(error);
        captureException(error);
        yield cancel();
    }
}

export function* watchCreditCardDmrLink() {
    yield takeEvery(CreditCardDmrLinkActions.addCard.toString(), addCard);
}
