import { useCallback, useState } from 'react';
import { usePortal } from 'reactApp/hooks/usePortal';

const useLocalModal = (uniqModalName: string) => {
    const [isShow, setShow] = useState(false);
    const portalElement = usePortal(`local-modal-${uniqModalName}`);

    const showModal = useCallback(() => {
        setShow(true);
    }, []);

    const hideModal = useCallback(() => {
        setShow(false);
    }, []);

    return [isShow, showModal, hideModal, portalElement] as const;
};

export default useLocalModal;
