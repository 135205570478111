import { Method } from 'axios';
import { APICallV2Get, APICallV4 } from 'reactApp/api/APICall';
import { IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';

/** Запрос за доменными папками */
const method = 'domain/folders';

export class DomainFoldersApiV2Call extends APICallV2Get {
    _method = method;
}

export class DomainFoldersApiV4Call extends APICallV4 {
    _method = method;
    _type: Method = 'get';
}

export const getDomainFoldersApiCall = () => {
    if (IS_ONPREMISE) {
        return new DomainFoldersApiV4Call();
    }

    return new DomainFoldersApiV2Call();
};
