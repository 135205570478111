import { UploadFail } from './UploadFail';

export class LocalFileNotReadableFail extends UploadFail {
    static isNotReadableError(error) {
        let isNotReadableError = false;

        if (window.DOMException && error instanceof DOMException) {
            // Chrome
            // @ts-ignore
            isNotReadableError = error.code === DOMException.NOT_READABLE_ERR || error.code === DOMException.NS_ERROR_FILE_ACCESS_DENIED;
        } else {
            // @ts-ignore
            // eslint-disable-next-line no-lonely-if
            if (window.FileError && error instanceof FileError) {
                // Safari
                // @ts-ignore
                isNotReadableError = error.code === FileError.NOT_READABLE_ERR || error.code === FileError.NS_ERROR_FILE_ACCESS_DENIED;
            }
        }

        return isNotReadableError;
    }

    radarName = 'fail_{SOURCE}_local-file-not-readable';

    message = ['Файл не может быть прочитан, из-за проблем с правами доступа.', 'Возможно файл используется другой программой.'].join(' ');
}
