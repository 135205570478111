import { clearItemFromLocalStorage, getItemFromLocalStorage, setItemFromLocalStorage } from './localstorageHelpers';

const LOCAL_STORAGE_KEY = 'cloud-renamed-file-id' as const;

export type RenamedFileMeta = {
    newId: string;
    outdateId: string;
};

export const getRenamedFileIdInsideViewer = () => getItemFromLocalStorage<RenamedFileMeta>(LOCAL_STORAGE_KEY);

export const setRenamedFileIdInsideViewer = (init: RenamedFileMeta) => setItemFromLocalStorage<typeof init>(LOCAL_STORAGE_KEY, init);

export const clearRenamedFileIdInsideViewer = () => clearItemFromLocalStorage(LOCAL_STORAGE_KEY);
