import { ACTION_PANEL_ID, ADD_TOOLBAR_ID, HEADER_ID, REACT_VIEWER_ID, TOP_TOOLBAR_ID } from 'reactApp/constants/magicIdentificators';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';

export const ROW_HEIGHT = 56;

export const gaHotkeyCategory = 'datalist-hotkey';

export const HOT_KEY_SCOPE_NAME = 'datalist';

export const TAGNAME_NO_RESET_WHITE_LIST = {
    input: true,
    textarea: true,
    a: true,
    button: true,
};

export const IDS_NO_RESET_WHITE_LIST = {
    cloud_toolbars: true,
    tipClose: true,
    dropdownList: true,
    [TOP_TOOLBAR_ID]: true,
    [ADD_TOOLBAR_ID]: true,
    [REACT_VIEWER_ID]: true,
    [HEADER_ID]: true,
    preview: true,
    [ACTION_PANEL_ID]: true,
};

export const leaderKey = EnvironmentSelectors.isMac() ? 'cmd' : 'ctrl';

export const isMobile = EnvironmentSelectors.isMobile() || EnvironmentSelectors.isIpadOs();
