import { Spacing, Text } from '@vkontakte/vkui';
import { parse, stringify } from 'qs';
import React, { memo, ReactElement, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { DOMAIN_HOST } from 'reactApp/appHelpers/configHelpers';
import { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { Avatar } from 'reactApp/ui/Avatar/Avatar';
import { Button } from 'reactApp/ui/Button/Button';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';

import styles from './UserChangeModal.css';

export const UserChangeModal = memo<IPropsWithPopup>(({ onClose, reloginAction }): ReactElement => {
    const ref = useRef<HTMLDivElement>(null);
    const email = useSelector(UserSelectors.getEmail);
    const login = useSelector(UserSelectors.getLogin);
    const storage = useSelector(getCurrentStorage);

    const handleGhangeAccountClick = useCallback(() => {
        const search = parse(window.location.search, { ignoreQueryPrefix: true });
        const query = {
            ...search,
            ...(reloginAction ? { action: 'show-left-menu' } : { action: '' }),
        };
        const path = storage === EStorageType.home ? '/home' : window.location.pathname;

        const successPage = `${window.location.origin}${path}${stringify(query, {
            addQueryPrefix: true,
            skipNulls: true,
        })}`;

        window.__PH.authForm.show({
            formType: 'relogin',
            successPage,
            email,
        });
    }, [email, reloginAction, storage]);

    return (
        <MobileDialog id="mobile-user-change-modal" onClose={onClose} closeOnDimmerClick mod="base" topmost scrollRef={ref}>
            <div className={styles.root} ref={ref}>
                <div className={styles.header}>
                    <Avatar size={32} email={email} />
                    <div className={styles.info}>
                        <Text className={styles.login} weight="1">
                            {login}
                        </Text>
                        <Spacing size={2} />
                        <Text className={styles.email}>{email}</Text>
                    </div>
                </div>
                <Spacing size={24} />
                <Button href={`https://id.${DOMAIN_HOST}/`} theme="vk" qaId="visit-account">
                    Перейти в аккаунт
                </Button>
                <Spacing size={12} />
                <Button className={styles.buttonChangeAccount} theme="vk" onClick={handleGhangeAccountClick} qaId="change-acccount">
                    Сменить аккаунт
                </Button>
                <Spacing size={12} />
            </div>
        </MobileDialog>
    );
});

UserChangeModal.displayName = 'UserChangeModal';
