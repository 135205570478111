import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { inlineEditStart, inlineEditStop, modifyingStart, modifyingStop } from './modifying.actions';
import { IState } from './modifying.types';

export const initialState: IState = {
    isModifyingInProgress: false,
    isInlineEditInProgress: false,
    // tempexp-14737-next-line
    inlineEditItemId: undefined,
};

export const modifyingReducer = createReducer(initialState, {
    [modifyingStart.type]: (state) => {
        return {
            ...state,
            isModifyingInProgress: true,
        };
    },
    [modifyingStop.type]: (state) => {
        return {
            ...state,
            isModifyingInProgress: false,
        };
    },
    // tempexp-14737-next-line
    [inlineEditStart.type]: (state, action: PayloadAction<string | undefined>) => {
        return {
            ...state,
            isInlineEditInProgress: true,
            // tempexp-14737-next-line
            inlineEditItemId: action.payload,
        };
    },
    [inlineEditStop.type]: (state) => {
        return {
            ...state,
            isInlineEditInProgress: false,
            // tempexp-14737-next-line
            inlineEditItemId: undefined,
        };
    },
});
