import { BUILD_VERSION, IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { getUserSearchParams } from 'reactApp/modules/search/search.helpers';
import { EActionSearch, NewSearchApiRequestParams } from 'reactApp/modules/search/search.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { sendDwh } from 'reactApp/utils/ga';
import { roundCustom } from 'reactApp/utils/helpers';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

interface ISearchStatisticsParams {
    kaktam: {
        label: string;
        data: {
            time: number;
            query?: string;
            searchType?: string;
            resultsCount?: number;
            resultCount?: number;
            offset?: number;
        };
    };
    status: number;
    apiRequestDeltaT: number;
}

const sendRadarStatistics = (radarGroup: 'search' | 'virus-scan', params: ISearchStatisticsParams) => {
    const {
        apiRequestDeltaT,
        status,
        kaktam: { label, data },
    } = params;
    const deltaT = roundCustom(Math.round(apiRequestDeltaT), [0, 10, 100, 250, 500, 750, 1000, 2000, 3000, 4000, 5000, 10000]);

    const radar = RADAR.group(radarGroup);

    if (status === 200) {
        radar.add('success_all', deltaT);
    } else {
        radar.add('fail_all', deltaT);
        radar.add(`fail_status-${status}_all`, deltaT);
    }

    radar.kaktam(label, JSON.stringify(data));

    radar.send();
};

export const sendVirusScanStatistics = (status: number, apiRequestDeltaT: number, offset: number, resultsCount: number) => {
    sendRadarStatistics('virus-scan', {
        apiRequestDeltaT,
        status,
        kaktam: {
            label: 'virus-scan',
            data: {
                time: apiRequestDeltaT,
                offset,
                resultsCount,
            },
        },
    });
};

export const sendSearchStatistics = (status: number, query: string, searchType: string, apiRequestDeltaT: number, resultCount: number) => {
    sendRadarStatistics('search', {
        apiRequestDeltaT,
        status,
        kaktam: {
            label: 'search',
            data: {
                time: apiRequestDeltaT,
                query,
                searchType,
                resultCount,
            },
        },
    });
};

export const sendSearchAnalytics = (params: NewSearchApiRequestParams, place: string, xPageId: string, xReqId: string, filters: string) => {
    const { query: search_phrase, path, srch_src } = params;

    sendDwh({
        eventCategory: ECategoryGa.search,
        action: 'enter',
        dwhData: {
            page_id: xPageId,
            req_id: xReqId,
            search_phrase,
            path,
            place,
            filters,
            filters_placement: srch_src,
        },
    });
};

export const sendSearchInputClickRadar = ({ storage, path }: { storage: EStorageType; path?: string }) => {
    const isPhone = Boolean(IS_PHONE_BROWSER);
    const platform = isPhone ? 'touch' : 'web';
    sendDwh({
        eventCategory: ECategoryGa.search,
        action: EActionSearch.initialClick,
        dwhData: {
            place: platform,
            section: storage,
            path: path ? path : '/',
        },
    });
};

export const sendSearchRadarAnalytics = ({ eventCategory, action, ...other }, items: any[] = []) => {
    let dwhData = other;
    if (items?.filter((item) => !!item).length) {
        dwhData = {
            ...dwhData,
            build: BUILD_VERSION,
            name_files: items.map(({ nameWithoutExt }) => nameWithoutExt).join(', '),
            type_content: items.map(({ kind, subKind }) => kind || subKind).join(', '),
            pos: items.map(({ pos }) => pos).join(', '),
            hash: items.map(({ hash }) => hash).join(', '),
            size: items.map(({ size }) => size || 0).join(', '),
            placement: items.map((item) => item.srchSrc),
        };
    }
    sendDwh({
        eventCategory,
        action,
        dwhData,
    });
};

export const sendSearchRadarWithUserParams = ({ eventCategory, action, ...restData }) => {
    const { isPaidUser, isProUser } = getUserSearchParams();
    const isPhone = Boolean(IS_PHONE_BROWSER);

    const platform = isPhone ? 'touch' : 'web';

    const dwhData = {
        free_user: !isPaidUser,
        paid_user: isPaidUser,
        pro_user: isProUser,
        place: platform,
        ...restData,
    };
    sendDwh({ eventCategory, action, dwhData });
};

export const newSendSearchRadarAnalytics = ({ eventCategory, action, items, searchParams = {}, isSearchPhrase = true, ...other }) => {
    const { search_phrase, req_id, page_id } = searchParams as any;
    let dwhData = isSearchPhrase ? { search_phrase, ...other } : other;
    const isPhone = Boolean(IS_PHONE_BROWSER);
    const platform = isPhone ? 'touch' : 'web';

    dwhData = {
        ...dwhData,
        page_id,
        req_id,
        place: platform,
        items,
    };

    sendDwh({
        eventCategory,
        action,
        dwhData,
    });
};
