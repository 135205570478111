import { Icon12ChevronUp, Icon20Check, Icon20GearOutline } from '@vkontakte/icons';
import { Panel, Spacing, Text } from '@vkontakte/vkui';
import { Popper } from '@vkontakte/vkui/unstable';
import classNames from 'clsx';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useClickOutsideTarget } from 'reactApp/hooks/useClickOutsideTarget';
import { setIsOptionDlgShown, setOptionsConfig } from 'reactApp/modules/ebook/ebook.module';
import { getEbookConfig } from 'reactApp/modules/ebook/ebook.selectors';
import { EbookFont, EbookTheme } from 'reactApp/modules/ebook/ebook.types';
import { EActions } from 'reactApp/modules/storage/storage.types';
import { EHeaderColor } from 'reactApp/ui/Header/HeaderWrapper';
import { ToolbarItem } from 'reactApp/ui/Toolbar/ToolbarItem/ToolbarItem';

import styles from './OptionsButton.css';

const THEMES = [EbookTheme.LIGHT, EbookTheme.CEPIA, EbookTheme.DARK];
const FONTS = [EbookFont.Arial, EbookFont.Helvetica, EbookFont.TimesNewRoman, EbookFont.Georgia];

const FONT_SIZE_MAX = 40;
const FONT_SIZE_MIN = 6;

interface Props {
    color?: EHeaderColor;
}

export const OptionsButton: FC<Props> = ({ color }) => {
    const refBttn = useRef<HTMLDivElement | null>(null);
    const ref = useRef<HTMLDivElement>(null);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    const config = useSelector(getEbookConfig);

    const onClickAway = useCallback(() => setShow(false), []);

    useClickOutsideTarget({ ref, onClickAway });

    const saveConfig = useCallback((newConfig) => {
        dispatch(setOptionsConfig(newConfig));
    }, []);

    const handleOnBlur = useCallback(() => setShow(false), []);

    const handleThemeClick = useCallback(
        (theme: EbookTheme) => {
            saveConfig({ ...config, theme });
            setShow(false);
        },
        [config, saveConfig]
    );

    const handleFontClick = useCallback(
        (fontFamily: EbookFont) => {
            saveConfig({ ...config, fontFamily });
            setShow(false);
        },
        [config, saveConfig]
    );

    const handleShowOptions = useCallback((_, _2, event) => {
        refBttn.current = event.currentTarget;
        setShow(true);
    }, []);

    const handleSizeChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.currentTarget.value === '') {
                return;
            }

            event.stopPropagation();

            const size = Number(event.currentTarget.value);
            saveConfig({ ...config, size });
        },
        [config, saveConfig]
    );

    const incrementSize = useCallback(() => {
        const size = Math.min(config.size + 1, FONT_SIZE_MAX);
        saveConfig({ ...config, size });
    }, [config, saveConfig]);

    const decrementSize = useCallback(() => {
        const size = Math.max(config.size - 1, FONT_SIZE_MIN);
        saveConfig({ ...config, size });
    }, [config, saveConfig]);

    useEffect(() => {
        if (!show) {
            return;
        }

        ref.current?.focus();
    }, [ref?.current, show]);

    useEffect(() => {
        dispatch(setIsOptionDlgShown(show));
    }, [show]);

    return (
        <>
            <ToolbarItem
                id={EActions.options}
                onClick={handleShowOptions}
                icon={<Icon20GearOutline width={20} height={20} />}
                text="Настройки"
                hint="Настройки"
                collapse
                color={color}
            />
            {show && (
                <Popper offsetDistance={10} placement="bottom-start" targetRef={refBttn} forcePortal={false}>
                    <div ref={ref} className={styles.dialog} id="options" data-qa-id="ebook-options" onBlur={handleOnBlur} tabIndex={0}>
                        <Panel id="ebook-options">
                            <Text weight="medium" className={styles.title}>
                                Настройки
                            </Text>
                            <Spacing size={20} />
                            <div className={styles.buttons}>
                                {THEMES.map((theme) => (
                                    <div
                                        key={theme}
                                        onClick={() => handleThemeClick(theme)}
                                        className={classNames(styles.button, {
                                            [styles.active]: config.theme === theme,
                                            [styles[`button_${theme.toLowerCase()}`]]: Boolean(theme),
                                        })}
                                    >
                                        A
                                    </div>
                                ))}
                            </div>
                            <div className={styles.group}>
                                <div className={styles.groupTitle}>Шрифт</div>
                                {FONTS.map((font) => (
                                    <div
                                        key={font}
                                        className={styles.font}
                                        style={{ fontFamily: font }}
                                        onClick={() => handleFontClick(font)}
                                    >
                                        {font}
                                        {font === config.fontFamily && <Icon20Check />}
                                    </div>
                                ))}
                            </div>
                            <div className={classNames(styles.group, styles.fontSize)}>
                                <Text weight="medium">Размер шрифта</Text>
                                <div className={classNames(styles.numberWrapper)}>
                                    <input
                                        type="number"
                                        className={classNames(styles.number)}
                                        value={config.size ?? 16}
                                        onChange={handleSizeChange}
                                        max={FONT_SIZE_MAX}
                                        min={FONT_SIZE_MIN}
                                    />
                                    <div onClick={incrementSize} className={classNames(styles.arrow, styles.arrowUp)}>
                                        <Icon12ChevronUp />
                                    </div>
                                    <div onClick={decrementSize} className={classNames(styles.arrow, styles.arrowDown)}>
                                        <Icon12ChevronUp />
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </div>
                </Popper>
            )}
        </>
    );
};
