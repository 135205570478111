import { ERecognitionStatus } from 'reactApp/modules/personalDocuments/personalDocuments.types';

export const getTitle = (recognitionStatus: ERecognitionStatus) => {
    switch (recognitionStatus) {
        case ERecognitionStatus.DISABLED:
        case ERecognitionStatus.PROCESSED: {
            return 'Настройки распознавания документов';
        }
        case ERecognitionStatus.PROCESSING: {
            return 'Распознавание запущено';
        }
    }
};

export const getDescription = (recognitionStatus: ERecognitionStatus) => {
    switch (recognitionStatus) {
        case ERecognitionStatus.DISABLED:
        case ERecognitionStatus.PROCESSED: {
            return 'Мы находим, распознаём и автоматически раскладываем ваши документы в Облаке по категориям.';
        }
        case ERecognitionStatus.PROCESSING: {
            return 'Мы запустили распознавание в фоновом режиме. Облаку потребуется некоторое время, чтобы найти все ваши документы. Как только всё будет готово, мы отправим вам письмо.';
        }
    }
};
