import store from 'lib/store';
import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { renderNewComfirmationDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog.helpers';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { AB_FEATURE_NAME } from 'reactApp/components/BuyModalTrial/BuyModalTrial.types';

export const shouldTrialBeShown = (userEmail: string | null, subscriptionId: string): boolean => {
    const SHOW_COUNT = 4;
    const id = `${userEmail}|${AB_FEATURE_NAME}_${subscriptionId}`;
    const idCount = `${userEmail}|${AB_FEATURE_NAME}_count_${subscriptionId}`;

    const showCount = store.get(idCount) ?? 0;

    if (showCount > SHOW_COUNT) {
        store.remove(id);
        return false;
    }

    const endDate = store.get(id);
    const now = new Date();

    if (!showCount || !endDate || now > endDate) {
        const newEndDate = new Date().setDate(now.getDate() + 1);

        store.set(id, newEndDate);
        store.set(idCount, showCount + 1);

        return true;
    }

    return false;
};

export const showNoTariffDialog = () => {
    renderNewComfirmationDialog({
        dataQAId: 'no-tariff-dialog',
        renderHeader: () => (IS_PHONE_BROWSER ? ' Тариф недоступен' : 'Данный тариф недоступен'),
        renderContent: () =>
            IS_PHONE_BROWSER
                ? 'Этот тариф для новых подписчиков Облака или открывается по промокоду. Присмотритесь к другим вариантам'
                : 'Попробуйте сменить аккаунт или выберите другой тариф',
        buttons: [EButtonType.accept],
        successButtonText: IS_PHONE_BROWSER ? 'Понятно' : 'Ок',
    });
};
