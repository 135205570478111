import classNames from 'clsx';
import React, { memo } from 'react';
import { FavoritesIcon, FilledFavoritesIcon } from 'reactApp/ui/VKUIIcons';

import styles from './FavoriteIcon.css';

export enum EColor {
    RED = 'red',
    BLACK = 'black',
    WHITE = 'white',
}

interface IProps extends React.SVGProps<SVGSVGElement> {
    active?: boolean;
    activeColor?: EColor;
}

export const FavoriteIcon = memo((props: IProps) => {
    const { active, color, ...rest } = props;

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles.root_active]: active,
                [styles[`root_color_${color}`]]: color,
            })}
        >
            {active ? <FilledFavoritesIcon {...rest} /> : <FavoritesIcon {...rest} />}
        </div>
    );
});

FavoriteIcon.displayName = 'FavoriteIcon';
