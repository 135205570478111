import { Icon16ChevronLeft } from '@vkontakte/icons';
import React, { VFC } from 'react';
import { ProtectedTooltip } from 'reactApp/ui/Mobile/ProtectedTooltip/ProtectedTooltip';
import { NameComponent } from 'reactApp/ui/Name/Name';

import styles from './Breadcrumbs.css';

interface IBreadcrumbsProps {
    showBackButton?: boolean;
    showProtectedHint?: boolean;
    onClickBackButton?: () => void;
    name: string;
}

export const Breadcrumbs: VFC<IBreadcrumbsProps> = ({ showBackButton, showProtectedHint, onClickBackButton, name }) => (
    <div className={styles.root}>
        {showBackButton && (
            <div className={styles.button_back} onClick={onClickBackButton} data-qa-button="back">
                <Icon16ChevronLeft width={16} height={16} fill="currentColor" />
            </div>
        )}
        <NameComponent name={name} truncate delayTruncate />
        {showProtectedHint && <ProtectedTooltip />}
    </div>
);
