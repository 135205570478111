import { parse, stringify } from 'qs';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getSearchQueryString } from 'reactApp/modules/search/search.helpers';
import { searchSetEmptyFilters } from 'reactApp/modules/search/search.module';
import { getSearchContentStatus } from 'reactApp/modules/search/search.selectors';
import { setQueryParams } from 'reactApp/modules/settings/settings.module';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';

import { ESearchOptionSource, ESearchOptionType, IFilters, IWebFilters } from '../WebSearch.data';

export const getRestParams = () => {
    const { query: _query, searchType: _type, searchSource: _source, ...rest } = parse(window.location.search, { ignoreQueryPrefix: true });

    return rest;
};

export const getFilters = ({ type, source }: IFilters) => {
    const queryFilters: { searchType?: ESearchOptionType; searchSource?: ESearchOptionSource } = {};
    if (!!type && type !== ESearchOptionType.all) {
        queryFilters.searchType = type;
    }
    if (!!source && source !== ESearchOptionSource.all) {
        queryFilters.searchSource = source;
    }

    return queryFilters;
};

export const useWebSearchFilters = (): IWebFilters => {
    const {
        query: searchQuery = '',
        searchType = ESearchOptionType.all,
        searchSource = ESearchOptionSource.all,
    } = useSelector(SettingsSelectors.getQueryParams) || {};
    const { webSearchContentEnabled } = useSelector(getSearchContentStatus);
    const dispatch = useDispatch();

    const onSelect = useCallback(
        (
            {
                query = searchQuery,
                type = searchType,
                source = searchSource,
            }: { query?: string; type?: ESearchOptionType; source?: ESearchOptionSource },
            force?: boolean
        ) => {
            const rest = getRestParams();
            const searchOther = stringify(rest);
            const queryFilters = getFilters({ type, source });
            const search = getSearchQueryString(query, queryFilters);

            dispatch(setQueryParams({ query, ...queryFilters, ...rest }));

            if (force) {
                dispatch(
                    historyPush({
                        id: '/search/',
                        search: `${search}${searchOther ? `&${searchOther}` : ''}`,
                    })
                );
            }
        },
        [dispatch, searchQuery, searchSource, searchType]
    );

    const onReset = useCallback(
        (force?: boolean) => {
            const rest = getRestParams();
            const searchOther = stringify(rest);
            const search = getSearchQueryString(searchQuery, {});

            dispatch(setQueryParams({ query: searchQuery, ...rest }));

            if (force) {
                if (!searchQuery && !webSearchContentEnabled) {
                    dispatch(searchSetEmptyFilters(true));
                    dispatch(
                        historyPush({
                            id: '/home/',
                        })
                    );
                    return;
                }

                dispatch(
                    historyPush({
                        id: '/search/',
                        search: `${search}${searchOther ? `&${searchOther}` : ''}`,
                    })
                );
            }
        },
        [dispatch, searchQuery, webSearchContentEnabled]
    );

    return { type: searchType, source: searchSource, onSelect, onReset };
};
