define(function (require, exports, module) {
	'use strict';

	module.exports = function () {

		return {
			"folder/find": {
				"success": {
					"status": 200,
					"body": {
						"home": "/",
						"kind": "storage",
						"list": [
							{
								"home": "/TestFolder1",
								"kind": "folder",
								"name": "TestFolder1",
								"name_parts": [
									["TestFolder", 1],
									["1", 0]
								]
							},
							{
								"home": "/TestFile1.txt",
								"kind": "file",
								"name": "TestFile1.txt",
								"name_parts": [
									["TestF", 1],
									["ile1.txt", 0]
								]
							},
							{
								"home": "/TestFolder2",
								"kind": "folder",
								"name": "TestFolder2",
								"name_parts": [
									["TestFolder", 1],
									["2", 0]
								]
							},
							{
								"home": "/TestFile2.ext",
								"kind": "file",
								"name": "TestFile2.ext",
								"name_parts": [
									["TestF", 1],
									["ile2.ext", 0]
								]
							},
							{
								"home": "/TestFolder3",
								"kind": "folder",
								"name": "TestFolder3",
								"name_parts": [
									["TestFolder", 1],
									["3", 0]
								]
							},
							{
								"home": "/TestFile3.jpg",
								"kind": "file",
								"name": "TestFile3.jpg",
								"name_parts": [
									["TestF", 1],
									["ile3.jpg", 0]
								]
							},
							{
								"home": "/TestFolder4",
								"kind": "folder",
								"name": "TestFolder4",
								"name_parts": [
									["TestFolder", 1],
									["4", 0]
								]
							},
							{
								"home": "/TestFile4.png",
								"kind": "file",
								"name": "TestFile4.png",
								"name_parts": [
									["TestF", 1],
									["ile4.png", 0]
								]
							},
							{
								"home": "/TestFolder5",
								"kind": "folder",
								"name": "TestFolder5",
								"name_parts": [
									["TestFolder", 1],
									["5", 0]
								]
							},
							{
								"home": "/TestFile5.docx",
								"kind": "file",
								"name": "TestFile5.docx",
								"name_parts": [
									["TestF", 1],
									["ile5.docx", 0]
								]
							}
						],
						"name": "/",
						"type": "folder"
					}
				}
			}
		};
	};
});
