export type TAskUserConflictOptions = 'rewrite' | 'skip' | 'rename';
type TAskUserForPaymentOptions = 'payment' | 'skip' | 'skip_b2b';

interface PromiseResolve {
    option: TAskUserConflictOptions;
    saveForAll: boolean;
    id?: string;
}

/* eslint-disable import/no-mutable-exports */
/* eslint-disable init-declarations*/
export let askUserForConflictFiles: (value: PromiseResolve) => void;

export let askUserForPaymentFiles: (value: TAskUserForPaymentOptions, descriptorId?: string) => void;

export let createFolderIfNotExists: (value?: any) => void;

export function askUserForConflict() {
    return new Promise<PromiseResolve>((resolve) => {
        askUserForConflictFiles = resolve;
    });
}

export const askUserForPayment = (func) => {
    return new Promise<TAskUserForPaymentOptions>((resolve) => {
        askUserForPaymentFiles = resolve;
    }).then((result) => {
        func(result);
        // на случай закрытия окна оплаты перезаписываем внешнюю переменную, чтобы вызывать окно снова
        askUserForPaymentFiles = func;
    });
};

export const waitForCreateFolderIfNotExists = () => {
    return new Promise((resolve) => {
        createFolderIfNotExists = resolve;
    });
};
