import { useCallback } from 'react';
import { onMouseDownOnItem, onMouseLeave, onMouseMove } from 'reactApp/ui/Datalist/UserActions/onMouseAction';

export const useHandleMouseCallback = ({ dragParams, storage, setDragOverItem, setDragPosX, setDragPosY, isDragging, handleStartDrag }) => {
    const handleMouseMove = (e) => {
        onMouseMove({ e, dragParams, handleStartDrag, setDragPosX, setDragPosY });
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    const handleMouseDownOnItem = useCallback(
        (event, { item }) => {
            onMouseDownOnItem({ event, storage, dragParams, item, handleMouseMove, handleMouseUp });
        },
        [handleMouseMove, handleMouseUp, storage]
    );

    const handleMouseLeave = useCallback(() => {
        onMouseLeave({ isDragging, setDragOverItem });
    }, [isDragging]);

    return { handleMouseMove, handleMouseUp, handleMouseDownOnItem, handleMouseLeave };
};
