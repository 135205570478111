import { Text, Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { HTMLAttributes, ReactNode } from 'react';

import styles from './BaseMessage.css';

interface IProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
    img?: ReactNode;
    title?: ReactNode;
    text?: ReactNode;
    isMobile?: boolean;
}

export const BaseMessage: React.FC<IProps> = ({ img, title = null, text = null, children, isMobile = false, ...props }) => (
    <div className={classNames(styles.root, { [styles.rootMobile]: isMobile })} {...props}>
        {img}
        {title && (
            <Title weight="2" className={styles.title}>
                {title}
            </Title>
        )}
        {text && <Text className={styles.text}>{text}</Text>}
        {children}
    </div>
);

BaseMessage.displayName = 'BaseMessage';
