export type SelectType = {
    value: MessageCategory;
    label: string;
};

export type Message = {
    message: string;
    isOwn: boolean;
    hasError?: boolean;
};

export type Messages = Message[];

export type Option<T> = { value: T; label: string; icon: JSX.Element };

export type Options<T> = Option<T>[];

export enum SendType {
    ON_ENTER_PRESS = 'enter_press',
    ON_BUTTON_CLICK = 'button_click',
}

export enum ReactionType {
    THUMBS_UP = 'thumbsUp',
    THUMBS_DOWN = 'thumbsDown',
}

export enum MessageCategory {
    TEXT_BY_THEME = 'textbytheme',
    POST = 'post',
    BRAINSTORM = 'brainstorm',
}

export enum ActionCategory {
    CONTINUE = 'continue',
    REWRITE = 'rewrite',
    DELETE = 'delete',
}
