define(function (require, exports, module) {
	'use strict';

	module.exports = function (email) {
		email = String(email);

		var login = email.replace(/@.*/, '');
		var domain = email.replace(/.*@/, '');
		var now = Date.now();

		return {
			"user": {
				"noauth": {
					"status": 403,
					"body": "user"
				},
				"tariff": {
					"status": 200,
					"body": {
						"login": login,
						"domain": domain,
						"account_type": "regular",
						"newbie": false,
						"cloud": {
							"metad": 2,
							"file_size_limit": 34359738368,
							"enable": {
								"sharing": true
							},
							"beta": {
								"allowed": true,
								"asked": true
							},
							"bonuses": {
								"camera_upload": false,
								"desktop": false,
								"mobile": false,
								"complete": false,
								"registration": false,
								"feedback": false,
								"links": false
							},
							"space": {
								"overquota": true,
								"used": 11196958,
								"total": 204800
							},
							"billing": {
								"active_cost_id": "256G1m",
								"active_rate_id": "256G",
								"auto_prolong": true,
								"subscription": [],
								"prolong": true,
								"enabled": true,
								"expires": now + 2592000000
							}
						},
						"ui": {
							"sidebar": true,
							"kind": "all",
							"thumbs": false,
							"expand_loader": true,
							"sort": {
								"order": "asc",
								"type": "name"
							}
						}
					}
				},
				"subscriptions": {
					"status": 200,
					"body": {
						"login": login,
						"domain": domain,
						"account_type": "regular",
						"newbie": false,
						"cloud": {
							"metad": 2,
							"file_size_limit": 34359738368,
							"enable": {
								"sharing": true
							},
							"beta": {
								"allowed": true,
								"asked": true
							},
							"bonuses": {
								"camera_upload": false,
								"desktop": false,
								"mobile": false,
								"complete": false,
								"registration": false,
								"feedback": false,
								"links": false
							},
							"space": {
								"overquota": false,
								"used": 96958,
								"total": 204800
							},
							"billing": {
								"active_cost_id": "",
								"active_rate_id": "ZERO",
								"auto_prolong": false,
								"subscription": [
									{
										"quota": 107374182400,
										"platform": "android",
										"expires": now + 2592000000
									},
									{
										"quota": 107374182400,
										"platform": "ios",
										"expires": now + 2592000000
									}
								],
								"prolong": false,
								"enabled": true,
								"expires": now + 2592000000
							}
						},
						"ui": {
							"sidebar": true,
							"kind": "all",
							"thumbs": false,
							"expand_loader": true,
							"sort": {
								"order": "asc",
								"type": "name"
							}
						}
					}
				},
				"tariffAndSubscriptions": {
					"status": 200,
					"body": {
						"login": login,
						"domain": domain,
						"account_type": "regular",
						"newbie": false,
						"cloud": {
							"metad": 2,
							"file_size_limit": 34359738368,
							"enable": {
								"sharing": true
							},
							"beta": {
								"allowed": true,
								"asked": true
							},
							"bonuses": {
								"camera_upload": false,
								"desktop": false,
								"mobile": false,
								"complete": false,
								"registration": false,
								"feedback": false,
								"links": false
							},
							"space": {
								"overquota": false,
								"used": 96958,
								"total": 204800
							},
							"billing": {
								"active_cost_id": "100G1m",
								"active_rate_id": "100G",
								"auto_prolong": false,
								"subscription": [
									{
										"quota": 107374182400,
										"platform": "android",
										"expires": now + 2592000000
									},
									{
										"quota": 107374182400,
										"platform": "ios",
										"expires": now + 2592000000
									}
								],
								"prolong": true,
								"enabled": true,
								"expires": now + 2592000000
							}
						},
						"ui": {
							"sidebar": true,
							"kind": "all",
							"thumbs": false,
							"expand_loader": true,
							"sort": {
								"order": "asc",
								"type": "name"
							}
						}
					}
				},
				"tariffAndSubscriptionsAndPromoCodes": {
					"status": 200,
					"body": {
						"login": login,
						"domain": domain,
						"account_type": "regular",
						"newbie": false,
						"cloud": {
							"metad": 2,
							"file_size_limit": 34359738368,
							"enable": {
								"sharing": true
							},
							"beta": {
								"allowed": true,
								"asked": true
							},
							"bonuses": {
								"camera_upload": false,
								"desktop": false,
								"mobile": false,
								"complete": false,
								"registration": false,
								"feedback": false,
								"links": false
							},
							"space": {
								"overquota": false,
								"used": 96958,
								"total": 204800
							},
							"billing": {
								"active_cost_id": "pro1y",
								"active_rate_id": "pro",
								"auto_prolong": false,
								"subscription": [
									{
										"quota": 107374182400,
										"platform": "android",
										"expires": now + 2592000000
									},
									{
										"quota": 107374182400,
										"platform": "ios",
										"expires": now + 2592000000
									},
									{
										"quota": 107374182400,
										"platform": "ios",
										"expires": now + 2592000000
									},
									{
										"quota": 107374182400,
										"platform": "ios",
										"expires": now + 2592000000
									}
								],
								"promocodes": {
									"happy_new_year2018": {
										"promocode": "AABA234",
										"services": {
											"quota": 68719476736,
											"interval": "1y",
											"flags": {
												"UFLAG_PAID_ACCOUNT": 1,
												"UFLAG_PAID_UPLOAD": 1
											}
										},
										"title": "happy_new_year2018",
										"expires": 1544054400
									},
									"happy_new_year2017": {
										"promocode": "ABRADAS",
										"services": {
											"quota": 68719476736,
											"interval": "1y",
											"flags": {
												"UFLAG_PAID_ACCOUNT": 1,
												"UFLAG_PAID_UPLOAD": 1
											}
										},
										"title": "happy_new_year2017",
										"expires": 1504054400
									}
								},
								"prolong": true,
								"enabled": true,
								"expires": now + 2592000000
							}
						},
						"ui": {
							"sidebar": true,
							"kind": "all",
							"thumbs": false,
							"expand_loader": true,
							"sort": {
								"order": "asc",
								"type": "name"
							}
						}
					}
				}
			},

			"user/space": {
				"noauth": {
					"status": 403,
					"body": "user"
				}
			}
		};
	};
});
