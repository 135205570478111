import { handleAddToUploadingQueue } from 'reactApp/modules/uploading/sagas/addToUploadingQueue';
import { handleClearUploadedQueue } from 'reactApp/modules/uploading/sagas/clearUploadedQueue';
import { handleSaveDocument } from 'reactApp/modules/uploading/sagas/handleSaveDocument';
import { handleProcessUploadQueue } from 'reactApp/modules/uploading/sagas/processUploadQueue';
import { handleSendDescriptorsToUI } from 'reactApp/modules/uploading/sagas/sendDescriptorsToUI';
import { handleStartFileUploading } from 'reactApp/modules/uploading/sagas/startFileUploading';
import {
    addToUploadQueue,
    cancelDescriptor,
    cancelUploading,
    clearUploadedQueue,
    processUploadQueue,
    saveDocument,
    sendDescriptorsToUI,
    startFileUploading,
} from 'reactApp/modules/uploading/uploading.module';
import { takeEvery, takeLatest } from 'redux-saga/effects';

import { handleCancelDescriptor } from './sagas/handleCancelDescriptor';
import { handleCancelUploading } from './sagas/handleCancelUploading';

export function* watchUploadingRoot() {
    yield takeEvery(addToUploadQueue.toString(), handleAddToUploadingQueue);
    yield takeEvery(sendDescriptorsToUI.toString(), handleSendDescriptorsToUI);
    yield takeLatest(processUploadQueue.toString(), handleProcessUploadQueue);
    yield takeEvery(startFileUploading.toString(), handleStartFileUploading);
    yield takeEvery(cancelUploading.toString(), handleCancelUploading);
    yield takeLatest(clearUploadedQueue.toString(), handleClearUploadedQueue);
    yield takeEvery(saveDocument.toString(), handleSaveDocument);
    yield takeEvery(cancelDescriptor.toString(), handleCancelDescriptor);
}
