import { Promo } from 'Cloud/Application/Promo';
import settings from 'Cloud/settings';
import { IS_AUTO_TEST_HIDE, IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';

class PromoController {
    promos: { [key: number]: Promo } = {};

    register = (priority: number, promo): void => {
        this.promos[priority] = promo;
    };

    unregister = (priority: number): void => {
        if (this.promos[priority]) {
            delete this.promos[priority];
        }
    };

    canShow = (key: string): boolean => {
        if (IS_AUTO_TEST_HIDE || settings?.request?.action || IS_PHONE_BROWSER) {
            return false;
        }

        let promo;

        Object.keys(this.promos)
            .map((priority) => Number(priority))
            .sort((a, b) => b - a)
            .forEach((priority) => {
                const p = this.promos[priority];

                if (p.isEnabled() && !p.isShown()) {
                    promo = p;
                }
            });

        return promo && promo.key === key;
    };

    markAsShown = (
        key: string,
        options?: {
            daysCount: number;
        }
    ): void => {
        const promo = Object.values(this.promos).find((p) => p.key === key);

        if (promo) {
            promo.markAsShown(options);
        }
    };
}

const promoController = new PromoController();

export { promoController };
