import config from 'Cloud/config';
import { logger } from 'lib/logger';
import { IS_AUTO_TEST_HIDE, IS_DEV } from 'reactApp/appHelpers/configHelpers';
import { renderWhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog.helpers';
import { isDialogVisible } from 'reactApp/modules/dialog/dialog.selectors';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { store } from 'reactApp/store';
import { sendGa } from 'reactApp/utils/ga';
import { put } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

const WHATS_NEW_PROMO_CONFIG = config.get('WHATS_NEW_PROMO');
const BANNER_ID = WHATS_NEW_PROMO_CONFIG && WHATS_NEW_PROMO_CONFIG.id;
const banner = BANNER_ID ?? null;

const IS_DEV_OR_DEVSTAND = IS_DEV || location.hostname?.match(/[^/]+\.(devmail|mini-mail)\.ru(:\d+)?$/i);

const sendPixel = (pixelType: 'show_pixel' | 'click_pixel' | 'close_pixel') => {
    if (WHATS_NEW_PROMO_CONFIG[pixelType]) {
        new Image().src = WHATS_NEW_PROMO_CONFIG[pixelType];
    } else {
        logger.info(`${pixelType}: not found pixelType of WHATS_NEW_PROMO_CONFIG`);
    }
};

const onBannerClick = () => {
    sendPixel('click_pixel');
    sendPromoGa('primclick');
};

const onSecondary = () => {
    sendPixel('click_pixel');
    sendPromoGa('secclick');
};

const onBannerShow = () => {
    sendPixel('show_pixel');
    sendPromoGa('show');
    store.dispatch(promoShown(EPromoType.whatsNew));
    if (IS_DEV_OR_DEVSTAND) {
        storeHelper.markAsShown(BANNER_ID);
    }
};

const onBannerClose = () => {
    sendPixel('close_pixel');
    sendPromoGa('close');
    store.dispatch(removePromo(EPromoType.whatsNew));
};

const sendPromoGa = (action: string): void => {
    sendGa('whatsnew-promo', action, BANNER_ID);
};

export function* initWhatsNewPromo() {
    if (!banner) {
        return;
    }

    const storage = yield* select(getCurrentStorage);
    const isPromoShown = IS_DEV_OR_DEVSTAND && storeHelper.getValue(BANNER_ID);

    if (storage !== EStorageType.home || IS_AUTO_TEST_HIDE || isPromoShown) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.whatsNew,
            onShow: onBannerShow,
            onClose: onBannerClose,
            onClick: onBannerClick,
            onSecondary,
            promoId: BANNER_ID,
            primaryButtonText: WHATS_NEW_PROMO_CONFIG.mainbtntext,
            primaryButtonHref: WHATS_NEW_PROMO_CONFIG.mainbttnlink,
            secondaryButtonText: WHATS_NEW_PROMO_CONFIG.optbttntext,
            secondaryButtonHref: WHATS_NEW_PROMO_CONFIG.optbttnlink,
            closeByPrimaryButton: WHATS_NEW_PROMO_CONFIG.closebymainbtn,
            title: WHATS_NEW_PROMO_CONFIG.title,
            text: WHATS_NEW_PROMO_CONFIG.description,
            date: WHATS_NEW_PROMO_CONFIG.date,
            imageUrl: WHATS_NEW_PROMO_CONFIG.image,
        })
    );
}

export function* showWhatsNewPromo() {
    const whatsNewPromo = yield* select(PromoSelectors.getPromo(EPromoType.whatsNew));
    const isAnyDialogShown = yield* select(isDialogVisible);
    const isViewerOpening = yield* select(ViewerSelectors.isViewerOpening);
    const isViewerActive = yield* select(ViewerSelectors.isViewerActive);

    if (!whatsNewPromo || isAnyDialogShown || isViewerActive || isViewerOpening) {
        return;
    }

    renderWhatsNewDialog(whatsNewPromo);
}
