import coverImage from 'img/editor/co-edit-alert-popup.jpg';
import React, { useCallback, VFC } from 'react';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';

export interface CoEditPublicAlertPopupProps {
    onClick: () => void;
    onClose: () => void;
    onShow: () => void;
}

export const CoEditPublicAlertPopup: VFC<CoEditPublicAlertPopupProps> = ({ onClose, onShow, onClick }) => {
    const handleClick = useCallback(() => {
        onClick();
        onClose();
    }, [onClick, onClose]);

    return (
        <WhatsNewDialog
            primaryButtonText="Хорошо"
            onShow={onShow}
            onClick={handleClick}
            onClose={onClose}
            title="Документ открыт для совместного редактирования"
            text="Редактировать документ могут все, у кого есть ссылка"
            imageUrl={coverImage}
        />
    );
};
