import { ReactElement } from 'react';

export enum FeaturesColor {
    blue = 'blue',
    green = 'green',
    purple = 'purple',
    orange = 'orange',
    darkGreen = 'darkGreen',
    violet = 'violet',
}

export interface FamilyFeatureProps {
    color: FeaturesColor;
    title: string;
    text: string;
    icon: ReactElement;
    id: string;
    isCompact?: boolean;
    compactIcon?: ReactElement;
    compactColor?: FeaturesColor;
}

export interface FamilyPromoProps {
    hidePromoButton?: boolean;
}
