import classNames from 'clsx';
import React, { memo } from 'react';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { isProgressComplete } from 'reactApp/modules/uploadList/uploadList.getters';
import { EProgressStatus, ICountStatistic } from 'reactApp/modules/uploadList/uploadList.model';
import { Controls } from 'reactApp/ui/UploadList/Controls/Controls';
import { Progress } from 'reactApp/ui/UploadList/Progress/Progress';
import { ProgressBar } from 'reactApp/ui/UploadList/ProgressBar/ProgressBar';

import styles from './Header.css';

interface IProps {
    onExpand: () => void;
    toggleExpand: boolean;
    percentLoaded: number;
    warning: boolean;
    showUploadButton: boolean;
    countStatistic: ICountStatistic;
    progressStatus: EProgressStatus;
    onClose: () => void;
    onUpload: () => void;
    scrollToStart: () => void;
    remainTime: number;
    narrow?: boolean;
}

export const Header = memo(
    ({
        onExpand,
        toggleExpand,
        percentLoaded,
        progressStatus,
        onClose,
        warning,
        onUpload,
        showUploadButton,
        scrollToStart,
        countStatistic,
        remainTime,
        narrow,
    }: IProps) => (
        <div className={classNames(styles.root, { [styles.responsive]: ENABLE_FULL_RESPONSIVE, [styles.narrow]: narrow })}>
            <div className={styles.controlsWrapper}>
                <Progress
                    percentLoaded={percentLoaded}
                    countStatistic={countStatistic}
                    scrollToStart={scrollToStart}
                    status={progressStatus}
                    remainTime={remainTime}
                />
                <Controls
                    onExpand={onExpand}
                    toggleExpand={toggleExpand}
                    onClose={onClose}
                    onUpload={onUpload}
                    progressComplete={isProgressComplete(progressStatus)}
                    showUploadButton={showUploadButton}
                />
            </div>
            <ProgressBar
                percentLoaded={percentLoaded > 0 ? percentLoaded : 0}
                progressComplete={isProgressComplete(progressStatus)}
                warning={warning}
                hasSuccessFiles={countStatistic.successCount > 0}
            />
        </div>
    )
);

Header.displayName = 'UploadListHeader';
