import classNames from 'clsx';
import { plural } from 'lib/plural';
import { ReactComponent as TimeIcon } from 'mrg-icons/src/mailru/status/time_outline.svg';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import styles from './Countdown.css';

export default class Countdown extends PureComponent {
    state = {
        daysLeft: 0,
        hoursLeft: 0,
        minutesLeft: 0,
        secondsLeft: 0,
    };

    static propTypes = {
        endDate: PropTypes.number.isRequired,
        showSeconds: PropTypes.bool,
        showAsPlainText: PropTypes.bool,
        showDays: PropTypes.bool,
        showIcon: PropTypes.bool,
        theme: PropTypes.oneOf(['dark', 'light', '']),
    };

    static defaultProps = {
        showSeconds: true,
        showAsPlainText: false,
        showIcon: false,
        showDays: false,
        theme: '',
    };

    componentDidMount() {
        this.runTimer();
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    runTimer = () => {
        this.timerId = setTimeout(
            function tick() {
                this.getTimeLeft();
                this.timerId = setTimeout(tick.bind(this), 1000);
            }.bind(this),
            0
        );
    };

    stopTimer = () => {
        clearTimeout(this.timerId);
    };

    getTimeLeft = () => {
        const { endDate, showDays } = this.props;
        const now = new Date();
        const diff = endDate - now;

        if (diff <= 0) {
            this.setState({
                daysLeft: '00',
                hoursLeft: '00',
                minutesLeft: '00',
                secondsLeft: '00',
            });

            return;
        }

        const daysLeft = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hoursLeft = Math.floor((showDays ? diff % (1000 * 60 * 60 * 24) : diff) / (1000 * 60 * 60));
        const minutesLeft = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const secondsLeft = Math.floor((diff % (1000 * 60)) / 1000);

        this.setState({
            daysLeft,
            hoursLeft: hoursLeft < 10 ? `0${hoursLeft}` : hoursLeft,
            minutesLeft: minutesLeft < 10 ? `0${minutesLeft}` : minutesLeft,
            secondsLeft: secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft,
        });
    };

    renderTime = ({ value, name }) => (
        <div className={styles.time}>
            <div className={styles.value}>{value}</div>
            <div className={styles.name}>{name}</div>
        </div>
    );

    render() {
        const { hoursLeft, minutesLeft, secondsLeft, daysLeft } = this.state;
        const { showSeconds, showDays, showIcon, theme } = this.props;

        if (this.props.showAsPlainText) {
            return (
                <span
                    className={classNames({
                        [styles.plainText]: true,
                        [styles[`plainText_${theme}`]]: !!theme,
                    })}
                >
                    {showIcon && <TimeIcon className={styles.icon} width={12} height={12} />}
                    <div>
                        {showDays && daysLeft > 0 ? `${daysLeft} ${['день', 'дня', 'дней'][plural(daysLeft)]} ` : ''}
                        {hoursLeft}:{minutesLeft}
                        {showSeconds ? `:${secondsLeft}` : null}
                    </div>
                </span>
            );
        }

        return (
            <div className={styles.root}>
                {this.renderTime({ value: hoursLeft, name: 'час.' })}
                {this.renderTime({ value: minutesLeft, name: 'мин.' })}
                {this.props.showSeconds && this.renderTime({ value: secondsLeft, name: 'сек.' })}
            </div>
        );
    }
}
