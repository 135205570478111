import { attachesViewerSuccess } from 'reactApp/modules/attaches/attaches.actions';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { delay, race, take } from 'redux-saga/effects';

export function* waitUntilLoadedItem(storage?: EStorageType | null, itemId?: string) {
    if (!itemId || !storage) {
        return;
    }

    let isLoaded = false;

    while (!isLoaded) {
        if (storage === EStorageType.viewerAttaches) {
            const { action, timeout } = yield race({
                action: take(attachesViewerSuccess),
                timeout: delay(2000, true),
            });
            if (timeout || action.payload.attaches[itemId]) {
                isLoaded = true;
            }
        } else {
            isLoaded = true;
        }
    }
}
