import { useEffect, useRef } from 'react';

export function usePortal(id?: string | null) {
    const rootElemRef = useRef(null as HTMLDivElement | null);

    const getRootElem = () => {
        if (!rootElemRef.current) {
            rootElemRef.current = document.createElement('div');
        }
        return rootElemRef.current;
    };

    useEffect(
        function setupElement() {
            const parentElem = document.querySelector(`#${id}`) || document.body;
            if (rootElemRef?.current) {
                parentElem?.appendChild(rootElemRef?.current);
            }
            return function cleanup() {
                rootElemRef?.current?.remove();
            };
        },
        [id]
    );

    return getRootElem();
}
