import settings from 'Cloud/settings';
import {
    GetExpiredAndroidSubscriptionAPICall,
    GetExpiredIosSubscriptionAPICall,
} from 'reactApp/api/billing/GetExpiredDeviceSubscriptionAPICall';
import { IS_IOS_MOBILE } from 'reactApp/appHelpers/configHelpers';
import { SPACE_LIST } from 'reactApp/constants/spaceList';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { productsController } from 'reactApp/modules/products/products.controller';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage, isReactLandingPage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { store } from 'reactApp/store';
import { renderMobileSplashScreen } from 'reactApp/ui/Mobile/SplashScreen/helpers/SplashScreen.helpers';
import { useExpiredSubscriptionScreen } from 'reactApp/ui/Mobile/SplashScreen/hooks/useExpiredSubscriptionScreen';
import { put } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';

const EXPIRED_DEVICE_SUBSCRIPTION_PROMO_ID = 'ExpiredDeviceSubscriptionPromo';
const CHECK_EXPIRED_SUBS_DATE = 'checkExpiredDeviceDate';
const EXPIRED_SUBS_SCREEN = 'expiredSubsScreen';

// показывем сплеш скрин 4 раза, каждые 5 дней
const canShowExpiredScreen = (): boolean => {
    const MAX_SHOW_COUNT = 4;

    const showCount = storeHelper.getValue(EXPIRED_SUBS_SCREEN);

    if (showCount >= MAX_SHOW_COUNT) {
        return false;
    }

    if (!showCount || storeHelper.isPassed(EXPIRED_SUBS_SCREEN, { daysCount: 5 })) {
        return true;
    }

    return false;
};

// делаем запрос за отмененной подпиской раз в месяц
const canCheckExpiredSubs = (): boolean => {
    const screenWasShown = storeHelper.getValue(EXPIRED_SUBS_SCREEN);

    // если баннер был когда-то показан, то больше не показываем
    if (screenWasShown) {
        return false;
    }

    if (!storeHelper.getValue(CHECK_EXPIRED_SUBS_DATE) || storeHelper.isPassed(CHECK_EXPIRED_SUBS_DATE, { daysCount: 30 })) {
        return true;
    }

    return false;
};

export function* initExpiredDeviceSubscriptionPromo() {
    const isOverQuota = yield* select(UserSelectors.isOverQuotaUser);
    const isPaidUser = yield* select(UserSelectors.isPaidUser);
    const isPhone = yield* select(EnvironmentSelectors.isPhone);
    const isWebview = yield* select(EnvironmentSelectors.isWebview);
    const isAnonymous = yield* select(UserSelectors.isAnonymous);
    const isBizUser = yield* select(UserSelectors.isBizUser);
    const storage = yield* select(getCurrentStorage);
    const isLP = isReactLandingPage();

    if (
        isAnonymous ||
        isOverQuota ||
        isPaidUser ||
        isBizUser ||
        !isPhone ||
        isWebview ||
        storage === EStorageType.subscriptions ||
        settings?.request?.action ||
        isLP
    ) {
        return;
    }

    const canShowScreen = canShowExpiredScreen();
    const canCheckSubs = canCheckExpiredSubs();

    if (!canShowScreen && !canCheckSubs) {
        return;
    }

    const apiCall = IS_IOS_MOBILE ? new GetExpiredIosSubscriptionAPICall() : new GetExpiredAndroidSubscriptionAPICall();
    const { data } = yield* call(apiCall.makeRequest);

    storeHelper.markAsShown(CHECK_EXPIRED_SUBS_DATE);

    const period = data?.period;
    const quota = data?.services?.quota;

    if (!quota) {
        return;
    }

    yield productsController.loadProducts();

    const product = yield select(
        ProductsSelectors.getExpiredDeviceProduct,
        quota < SPACE_LIST.gb_32 ? SPACE_LIST.gb_32 : quota,
        period || '1y'
    );

    if (!product) {
        return;
    }

    const countShow = storeHelper.getValue(EXPIRED_SUBS_SCREEN) || 0;

    yield put(
        addPromoToShowQueue({
            type: EPromoType.expiredDeviceSubscription,
            promoId: EXPIRED_DEVICE_SUBSCRIPTION_PROMO_ID,
            onShow: () => {
                store.dispatch(promoShown(EPromoType.expiredDeviceSubscription));
                storeHelper.markAsShown(EXPIRED_SUBS_SCREEN, countShow + 1);
            },
            onClose: () => {
                store.dispatch(removePromo(EPromoType.expiredDeviceSubscription));
            },
            product,
        })
    );
}

export function* showExpiredDeviceSubscriptionPromo() {
    const expiredDeviceSubscriptionPromo = yield* select(PromoSelectors.getPromo(EPromoType.expiredDeviceSubscription));

    if (!expiredDeviceSubscriptionPromo) {
        return;
    }

    expiredDeviceSubscriptionPromo.onShow();

    yield call(
        renderMobileSplashScreen,
        { contentHook: useExpiredSubscriptionScreen, hookParams: { productId: expiredDeviceSubscriptionPromo.product?.id } },
        expiredDeviceSubscriptionPromo.onClose
    );
}
