import { extInfo } from 'lib/extInfo';
import { getExtension } from 'reactApp/modules/file/helpers/getExtention';
import { getItemNameWithoutExt } from 'reactApp/modules/file/utils';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

import type { ViewedFile } from './embedded.types';

export const getViewerFile = (state: RootState): ViewedFile => {
    const { item } = state.embedded || {};
    const ext = getExtension(item) || item?.ext || '';
    const { kind, subKind } = extInfo.get(ext);

    return {
        ext,
        kind,
        subKind,
        isFolder: false,
        id: item?.name || '',
        name: item?.name || '',
        mtime: item?.mtime || '',
        nameWithoutExt: getItemNameWithoutExt(item?.name, item?.ext),
        parent: '',
        size: item?.size || 0,
        storage: EStorageType.embedded,
        thumbnails: {
            original: item?.url || '',
        },
        url: {
            get: item?.url || '',
            view: item?.url || '',
        },
    };
};

export const getViewerRequestId = (state: RootState): string | undefined => state.embedded?.item?.external_req_id;
export const getViewerUserId = (state: RootState): string | undefined => {
    const { external_uid: userId } = state.embedded?.item || {};
    return userId ? `${userId}@vk` : undefined;
};

export const getEmbeddedItemById = createSelector(
    (state: RootState) => state,
    (state) => getViewerFile(state)
);
