import { Method } from 'axios';
import { APICallV4 } from 'reactApp/api/APICall';

interface CreateApiCallParams {
    name: string;
}

export class CreateFamilyAPICall extends APICallV4<CreateApiCallParams | null> {
    _method = 'family/create';
    _type: Method = 'post';
    _retriesCount = 0;
}
