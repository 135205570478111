import {
    filesFilterAddToFilter,
    filesFilterAll,
    filesFilterOne,
    filesFilterRemoveAll,
    filesFilterRemoveFromFilter,
} from 'reactApp/modules/filesFilter/filesFilter.module';
import { takeLatest } from 'redux-saga/effects';

import { resetSelected } from '../selections/selection.saga';

export function* watchFilesFilter() {
    yield takeLatest(
        [
            filesFilterAll.toString(),
            filesFilterRemoveAll.toString(),
            filesFilterOne.toString(),
            filesFilterAddToFilter.toString(),
            filesFilterRemoveFromFilter.toString(),
        ],
        resetSelected
    );
}
