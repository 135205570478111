import { bytesToNDigits } from '@mail/cross-sizes-utils';
import classNames from 'clsx';
import format from 'date-fns/format';
import { isNil } from 'ramda';
import React, { memo, ReactElement, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fileHistoryRecoveryRequest } from 'reactApp/modules/fileHistory/fileHistory.module';
import { FileHistorySelectors } from 'reactApp/modules/fileHistory/fileHistory.selectors';
import { RootState } from 'reactApp/store';
import { DataListControl } from 'reactApp/ui/DataListControl/DataListControl';
import { getDropdownItem, sendFilesHistoryGa } from 'reactApp/ui/FileHistory/FileHistory.helpers';
import { EDialogsType, IFileHistoryItemProps } from 'reactApp/ui/FileHistory/FileHistory.types';
import { RecoveryDialog } from 'reactApp/ui/FileHistory/FileHistoryDialogs/RecoveryDialog';
import { FilePic } from 'reactApp/ui/FilePic/FilePic';
import { NameComponent } from 'reactApp/ui/Name/Name';

import styles from './FileHistoryList.css';

export const FileHistoryItem = memo<IFileHistoryItemProps>(({ id, onPaidInfo }): ReactElement => {
    const dispatch = useDispatch();

    const [showDialog, setDialog] = useState<EDialogsType | ''>('');
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const item = useSelector((state: RootState) => FileHistorySelectors.getEntry(state, id));

    const handleDropdown = useCallback((isOpen) => setDropdownOpen(isOpen), [setDropdownOpen]);

    const handleOnClick = useCallback(
        (isRewrite = false, sendGa = true) => {
            if (sendGa) {
                sendFilesHistoryGa('click-dropdown', isRewrite ? 'rewrite' : 'copy');
            }

            if (!item.hash) {
                onPaidInfo();
                return;
            }

            setDialog(isRewrite ? EDialogsType.rewrite : EDialogsType.copy);
        },
        [item.hash]
    );

    const handleOnControlClick = useCallback(() => {
        handleOnClick(true);
        sendFilesHistoryGa('click-control', 'rewrite');
    }, [handleOnClick]);

    const recovery = useCallback(
        (isRewrite = false) => {
            dispatch(fileHistoryRecoveryRequest({ version: id, isRewrite }));
        },
        [dispatch, id]
    );

    const closeDialog = useCallback(() => {
        setDialog('');
    }, []);

    return (
        <>
            <div
                className={classNames({
                    [styles.item]: true,
                    [styles.item_current]: item.isCurrent,
                    [styles.item_selected]: isDropdownOpen,
                })}
            >
                <FilePic isFolder={false} isVirus={false} ext={item.ext || ''} isPublic={false} />
                <div className={styles.name}>
                    <NameComponent name={item.nameWithoutExt} extension={item.ext} truncate showTooltip />
                </div>
                <div className={styles.size}>{!isNil(item.size) && bytesToNDigits(item.size, 3).value}</div>
                <div className={styles.time}>{format(item.date, 'HH:mm')}</div>
                <div className={styles.controls}>
                    {item.isCurrent ? (
                        'текущая'
                    ) : (
                        <div className={styles.recovery}>
                            <DataListControl
                                type="restore"
                                size={24}
                                hint="Перезаписать"
                                list={getDropdownItem({ onClick: handleOnClick })}
                                onDropdown={handleDropdown}
                                onClick={handleOnControlClick}
                            />
                        </div>
                    )}
                </div>
            </div>
            {(showDialog === EDialogsType.copy || showDialog === EDialogsType.rewrite) && (
                <RecoveryDialog type={showDialog} onClose={closeDialog} fileName={item.name} onSuccess={recovery} />
            )}
        </>
    );
});

FileHistoryItem.displayName = 'FileHistoryItem';
