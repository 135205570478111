export const HttpErrorCodes = {
    BAD_REQUEST: 400,
    NOT_FOUND: 404,
    READ_ONLY_ACCESS: 405,
    REQUEST_TIMEOUT: 408,
    TOO_LARGE: 413,
    ILLEGAL: 451,
    INTERNAL_SERVER_ERROR: 500,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIMEOUT: 504,
    INSUFFICIENT_STORAGE: 428,
    INSUFFICIENT_STORAGE_PREV: 507,
};

const readOnlyHttpStatuses = [
    HttpErrorCodes.READ_ONLY_ACCESS,
    HttpErrorCodes.INSUFFICIENT_STORAGE,
    HttpErrorCodes.INSUFFICIENT_STORAGE_PREV,
];

export const isReadOnlyHttpStatus = (status: number) => readOnlyHttpStatuses.includes(status);
