import { endOfYear, getUnixTime } from 'date-fns';
import type { UserQuotaCleanerListParams } from 'reactApp/types/QuotaCleaner';

export const getYearParams = (year: string): Partial<UserQuotaCleanerListParams> => {
    const validYear = +year;
    if (!Number.isInteger(validYear)) {
        return {};
    }

    // получаем начало и конец года
    const yearStart = new Date(validYear, 0, 1);
    const yearEnd = endOfYear(yearStart);

    return {
        newer_than: getUnixTime(yearStart),
        older_than: getUnixTime(yearEnd),
    };
};
