import { getWeblinkFromPublicId } from 'reactApp/modules/file/utils';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

export const sendFacesDwh = ({ action, eventCategory = ECategoryGa.toolbar, ...data }): void => {
    sendDwh({ eventCategory, action, dwhData: data });
};

export const getCoordinatesId = (filePath: string, faceId: string) => filePath + faceId;

export const getFacesFileId = (id: string) => {
    return id.replace(getWeblinkFromPublicId(id) ?? '', '');
};
