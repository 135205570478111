import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { PageTabMenu } from 'reactApp/ui/PageTabMenu/PageTabMenu';

import { SharedTab } from './SharedTabas.types';
import styles from './SharedTabs.css';

const TABS = [
    {
        value: SharedTab.links,
        name: 'Вы поделились',
    },
    {
        value: SharedTab.incoming,
        name: 'С вами поделились',
    },
    {
        value: SharedTab.autodelete,
        name: 'Автоудаляемые',
    },
];

export const SharedTabs = memo(() => {
    const dispatch = useDispatch();
    const storage = useSelector(getCurrentStorage);

    const getCurrenTab = (): SharedTab => {
        switch (storage) {
            case EStorageType.sharedIncoming:
                return SharedTab.incoming;
            case EStorageType.sharedAutodelete:
                return SharedTab.autodelete;
            default:
                return SharedTab.links;
        }
    };

    const handleOnClickTab = useCallback(
        (value) => {
            dispatch(historyPush({ id: `shared/${value}` }));
        },
        [dispatch]
    );

    return (
        <div className={styles.root}>
            <PageTabMenu current={getCurrenTab()} tabs={TABS} onClick={handleOnClickTab} changeHash={false} />
        </div>
    );
});

SharedTabs.displayName = 'SharedTabs';
