import React, { memo, ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDiscountGiftList } from 'reactApp/modules/giftReceived/giftReceived.selectors';
import { sortDiscountPromoBySpace } from 'reactApp/modules/promoTariffs/promoTariffs.selectors';
import { historyPush } from 'reactApp/modules/router/router.module';
import { setQueryParams } from 'reactApp/modules/settings/settings.module';
import { DiscountPromocodeItem } from 'reactApp/sections/SubscriptionsPage/ui/DiscountPromocodesList/DiscountPromocodeItem';
import { createGaSender } from 'reactApp/utils/ga';
import { EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './DiscountPromocodesList.css';

const sendGa = createGaSender('promocodes-list');

interface Props {
    onPromocodeClick?: ({ productId, isTrial }: { productId: string; isTrial: boolean }) => void;
    gaSuffix?: string;
}

export const DiscountPromocodesList = memo(({ onPromocodeClick, gaSuffix = '' }: Props): ReactElement => {
    const promocodes = useSelector(sortDiscountPromoBySpace);
    const discountGifts = useSelector(getDiscountGiftList);

    const dispatch = useDispatch();

    const discountList = [...promocodes, ...discountGifts];

    const sendHomePaymentGa = useCallback(
        (label, promo = '') => {
            if (gaSuffix === 'tree') {
                sendPaymentGa({ action: EPaymentGa.promocodesSection, label, promo });
            }
        },
        [gaSuffix]
    );

    useEffect(() => {
        sendGa(`show${gaSuffix ? `-${gaSuffix}` : ''}`);
        sendHomePaymentGa('view');
    }, [gaSuffix]);

    const handleOnPromocodeClick = useCallback(
        ({ productId, isTrial }) => {
            sendGa(`click-item${gaSuffix ? `-${gaSuffix}` : ''}`, isTrial ? 'trial' : 'discount');
            sendHomePaymentGa('click', productId);

            if (onPromocodeClick) {
                onPromocodeClick({ productId, isTrial });
                return;
            }

            dispatch(setQueryParams({ action: 'request-payment', tariffId: productId, productId, from: isTrial ? 'open-trial' : '' }));

            dispatch(
                historyPush({
                    id: '/subscriptions',
                })
            );
        },
        [onPromocodeClick, gaSuffix]
    );

    return (
        <div className={styles.root}>
            {discountList.map((promocode) => {
                return (
                    <DiscountPromocodeItem
                        onItemClick={handleOnPromocodeClick}
                        expires={promocode.expires}
                        productId={promocode.productId}
                        id={promocode.id}
                        key={promocode.id}
                    />
                );
            })}
        </div>
    );
});

DiscountPromocodesList.displayName = 'DiscountPromocodesList';
