import { Method } from 'axios';
import api from 'Cloud/Application/api';
import { APICallV4 } from 'reactApp/api/APICall';
import { apiMigrateFileV2ToV4 } from 'reactApp/appHelpers/featuresHelpers';
import { promisifyDeferredCall } from 'reactApp/utils/helpers';

const method = 'file/add';

class AddFileAPIV4Call extends APICallV4 {
    _method = method;
    _type: Method = 'post';
}

export const addFileApiCall = (params: Record<string, any>): Promise<unknown> => {
    if (apiMigrateFileV2ToV4.add) {
        return new AddFileAPIV4Call().makeRequest({ ...params, path: params.home }).catch((error) => {
            throw [error.response, error.status, error, error.responseText];
        });
    }

    return promisifyDeferredCall(api.file.add, params);
};
