import { isValid } from 'date-fns';

export const renameCameraUploadFolder = (folder) => {
    if (folder.name?.toLowerCase() === 'camera uploads') {
        return 'Фотографии с телефона';
    }

    return folder?.name;
};

export const normalizeMtime = (mtime?: number): number => {
    if (!mtime) {
        return 0;
    }

    const newMtime = mtime * 1000;

    return isValid(newMtime) ? newMtime : 0;
};
