export const getThumbnailImageUrl = function(thumbnails, width, height) {
    let thumb;
    let currentHeight = 0;

    for (let key in thumbnails) {
        if (thumbnails.hasOwnProperty(key) && key[0] !== '_') {
            const size = key.split('x');

            if (size.length > 1) {
                const newWidth = size[0] * 1;
                const newHeight = size[1] * 1;
                if (thumbnails[key] && newWidth <= width && newHeight <= height && newHeight > currentHeight) {
                    thumb = thumbnails[key] || thumb;
                    currentHeight = newHeight;
                }
            } else {
                thumb = thumb || thumbnails[size];
                break;
            }
        }
    }

    return thumb;
};

export const tempFixForThumbUrls = (item, url) => {
    if (url) {
        // fix for docs
        // TODO: should be fixed on back??
        url = url.replace(/src=%2F%2F/, 'src=https:%2F%2F');
        if (item.type === 'document' || item.type === 'docs' || item.type === 'pdf') {
            url = url.replace('apf.mail.ru', 'docs.mail.ru');
        }
    }

    return url;
};
