import { Sort } from 'reactApp/types/Tree';

export interface ISortState {
    [key: string]: Sort;
}

export interface InitSortAction {
    [key: string]: Sort;
}

export interface SetSortAction {
    id: string;
    sort: Sort;
}

export enum ESortTypes {
    mtime = 'mtime',
    size = 'size',
    name = 'name',
    unmountedFirst = 'unmountedFirst',
    publicExpires = 'publicExpires',
    deletedAt = 'deletedAt',
}

export enum ESortOder {
    asc = 'asc',
    desc = 'desc',
}
