import classNames from 'clsx';
import { ReactComponent as UploadIcon } from 'img/icons/mobile/upload.svg';
import React, { memo, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { isUploadEnabled } from 'reactApp/modules/storage/storage.selectors';
import { getContent } from 'reactApp/ui/Mobile/Datalist/EmptyFolder/EmptyFolder.helpers';
import { UploadInput } from 'reactApp/ui/Mobile/UploadInput/UploadInput';

import styles from './EmptyFolder.css';

export const EmptyFolder = memo(function EmptyFolder(): ReactElement {
    const storage = useSelector(getCurrentStorage);
    const uploadEnabled = useSelector(isUploadEnabled);

    const { title, description } = getContent(storage);

    if (uploadEnabled) {
        return (
            <div className={styles.root}>
                <div className={styles.provocation}>
                    <div className={styles.upload}>
                        <UploadInput inputName="public-carousel" />
                    </div>
                    <UploadIcon className={styles.icon} />
                    <span>
                        Нажмите, чтобы
                        <br />
                        добавить файлы
                    </span>
                </div>
            </div>
        );
    }

    return (
        <div className={classNames(styles.root, styles.disableUpload)}>
            <div className={styles.folder} />
            <div className={styles.title}>{title}</div>
            {description && <div className={styles.description}>{description}</div>}
        </div>
    );
});
