import { IS_AUTO_TEST_HIDE } from 'reactApp/appHelpers/configHelpers';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { store } from 'reactApp/store';
import { renderMobileSearchNotification } from 'reactApp/ui/Mobile/SearchNotification/helpers/SearchNotification.helpers';
import { call, put } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

export const SEARCH_BANNER_ID = 'search-tooltip-touch-notification';

export function* initMobileSearchBanner() {
    const storage = yield* select(getCurrentStorage);
    const { isHome, isGallery } = getStorage(storage);
    const wasShown = storeHelper.getValue(SEARCH_BANNER_ID);

    if ((!isHome && !isGallery) || IS_AUTO_TEST_HIDE || wasShown) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.mobileSearchBanner,
            promoId: SEARCH_BANNER_ID,
            onShow: () => {
                store.dispatch(promoShown(EPromoType.mobileSearchBanner));
                storeHelper.markAsShown(SEARCH_BANNER_ID);
            },
            onClose: () => {
                store.dispatch(removePromo(EPromoType.mobileSearchBanner));
            },
        })
    );
}

export function* showMobileSearchBanner() {
    const mobileSearchBanner = yield* select(PromoSelectors.getPromo(EPromoType.mobileSearchBanner));

    if (!mobileSearchBanner) {
        return;
    }

    mobileSearchBanner.onShow();
    yield call(renderMobileSearchNotification, mobileSearchBanner.onClose());
}
