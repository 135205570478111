import { parse } from 'qs';
import React, { ClipboardEvent, memo, ReactElement, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';
import { isTouchSearchHistoryEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { useLongLoad } from 'reactApp/hooks/useLongLoad';
import { IDocumentSection } from 'reactApp/modules/personalDocuments/personalDocuments.types';
import { searchFakeLoadStart, searchLoadRequestStart } from 'reactApp/modules/search/search.module';
import {
    getSearchContentIndexed,
    getSearchContentStatus,
    getSearchLoadingState,
    getSearchSection,
} from 'reactApp/modules/search/search.selectors';
import { EActionSearch } from 'reactApp/modules/search/search.types';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { getStorageCurrentFolder } from 'reactApp/modules/storage/storage.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { useRouteChangeProcessing } from 'reactApp/sections/MainPage/hooks/useRouteChangeProcessing';
import { RootState } from 'reactApp/store';
import { ActionsBar, EActionsMode } from 'reactApp/ui/Mobile/ActionsBar/ActionsBar';
import { BreadcrumbsContainer as Breadcrumbs } from 'reactApp/ui/Mobile/Breadcrumbs/BreadcrumbsContainer';
import { Datalist } from 'reactApp/ui/Mobile/Datalist/Datalist';
import { SearchBar } from 'reactApp/ui/Mobile/SearchBar/SearchBar';
import { Toolbar } from 'reactApp/ui/Mobile/Toolbar/Toolbar';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import { useMobileSearchContent } from './hooks/useMobileSearchContent';
import { useMobileSearchHistory } from './hooks/useMobileSearchHistory';
import { useMobileSearchSections } from './hooks/useMobileSearchSections';
import { MobileSearchDatalist } from './MobileSearchDatalist/MobileSearchDatalist';
import styles from './MobileSearchPage.css';
import { SearchSuggests } from './SearchSuggests/SearchSuggests';

export const MobileSearchPage = memo(function MobileSearchPage(): ReactElement {
    const dispatch = useDispatch();
    const location = useLocation();
    const { query } = useSelector(SettingsSelectors.getQueryParams);
    const isSelecting = useSelector(SelectionsSelectors.isSelecting);
    const isSelected = useSelector(SelectionsSelectors.isAnyIndexSelected);
    const { isLoading } = useSelector(getSearchLoadingState);
    const folder = useSelector((state: RootState) => getStorageCurrentFolder(state, EStorageType.search)) as IDocumentSection;
    const searchSection = useSelector(getSearchSection);
    const { touchSearchContentEnabled } = useSelector(getSearchContentStatus);

    const isContentIndexed = useSelector(getSearchContentIndexed);

    const firstRender = useRef(true);
    const pasteData = useRef<{ phrase_before?: string; paste?: string; wasPasted?: boolean }>({});

    useRouteChangeProcessing({ storage: EStorageType.search, path: location.pathname, search: location.search });

    const { inputValue, placeholder, onInputChange, onInputClear, getInputRef, onKeyUp, searchValue } = useMobileSearchContent();
    const { removeHistoryItem, clearHistory, historyItems } = useMobileSearchHistory({ inputValue });
    const { sections, byOneSource, noResults } = useMobileSearchSections();
    const showSelection = Boolean(
        touchSearchContentEnabled ? (folder.count.all !== 0 && Boolean(searchSection)) || Boolean(byOneSource) : folder.count.all !== 0
    );
    const showViewChange =
        Boolean(
            touchSearchContentEnabled ? (folder.count.all !== 0 && Boolean(searchSection)) || Boolean(byOneSource) : folder.count.all !== 0
        ) && !IS_MY_TEAM;
    const { longLoad } = useLongLoad();

    const forceSearchRequest = useCallback(
        (value: string) => {
            if (value === query) {
                dispatch(searchLoadRequestStart({ queryParam: value }));
            }
            searchValue(value);
        },
        [dispatch, searchValue, query]
    );

    useEffect(() => {
        if (typeof isContentIndexed === 'undefined') {
            return;
        }
        if (typeof query !== 'undefined') {
            dispatch(searchLoadRequestStart());
        }
    }, [dispatch, query, isContentIndexed]);

    useEffect(() => {
        if (!firstRender.current) {
            const searchSection = parse(location.search).searchSection;
            dispatch(searchFakeLoadStart({ searchSection }));
        }
        firstRender.current = false;
    }, [location, dispatch]);

    const onInputPaste = useCallback(
        (event: ClipboardEvent<HTMLInputElement>) => {
            pasteData.current = {
                phrase_before: inputValue,
                paste: event.clipboardData.getData('text'),
            };
            pasteData.current.wasPasted = true;
        },
        [inputValue]
    );

    useEffect(() => {
        if (pasteData.current.wasPasted) {
            const { phrase_before, paste } = pasteData.current;
            sendDwh({
                eventCategory: ECategoryGa.search,
                action: EActionSearch.copyPastText,
                dwhData: {
                    phrase_before,
                    paste,
                    place: 'touch',
                    result_after: inputValue,
                },
            });
        }
        pasteData.current = {};
    }, [inputValue]);

    return (
        <>
            <div className={styles.root}>
                <Toolbar
                    hideLogo
                    showBack
                    showSelection={showSelection}
                    showViewChange={showViewChange}
                    showSearch={false}
                    showOptions={false}
                />
                <div className={styles.content}>
                    {!touchSearchContentEnabled && <Breadcrumbs />}
                    <SearchBar
                        disabled={isSelecting}
                        getRef={getInputRef}
                        value={inputValue}
                        placeholder={placeholder}
                        onChange={onInputChange}
                        onPaste={onInputPaste}
                        onKeyUp={onKeyUp}
                        onInputClear={onInputClear}
                        isLoading={longLoad}
                    />
                    {isTouchSearchHistoryEnabled && historyItems.length > 0 && (
                        <SearchSuggests
                            onClick={forceSearchRequest}
                            onRemoveItem={removeHistoryItem}
                            onClear={clearHistory}
                            items={historyItems}
                        />
                    )}
                    {(isLoading || query) &&
                        !historyItems.length &&
                        (touchSearchContentEnabled ? (
                            <MobileSearchDatalist sections={sections} byOneSource={byOneSource} noResults={noResults} />
                        ) : (
                            <Datalist />
                        ))}
                </div>
            </div>
            {isSelected && <ActionsBar mode={EActionsMode.PRIMARY} />}
        </>
    );
});
