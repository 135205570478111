export interface IAuthDwhData {
    action: EAuthDwhAction;
    source?: string;
    place?: string;
}

export enum EAuthDwhAction {
    licenseOpen = 'license-open',
    licenseAccept = 'license-accept',
    licenseReject = 'license-reject',
    regAuth = 'reg-auth',
}

export interface IToolbarActionAnalyticsParams {
    action: string;
    source: string;
    count_files: number;
    items?: any;
}

export interface ISearchRadarData {
    dwhData: {
        [key: string]: string | number | boolean | undefined;
    };
    files?: any[];
}

export interface INewSearchRadarData {
    dwhData: {
        [key: string]: string | number | boolean | undefined;
    };
    items?: any[];
    isSearchPhrase?: boolean;
}
