import React, { useRef, VFC } from 'react';
import { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';

import { renderSubscriptionsModal } from '../SubscriptionsModal/SubscriptionsModal.helpers';
import { Subscription } from '../SubscriptionsModal/SubscriptionsModal.types';
import { BaseSubscription } from './Components/BaseSubscription/BaseSubscription';
import { ModernSubscription } from './Components/ModernSubscription/ModernSubscription';
import { useSendAnalyticsAfterOpen } from './hooks/useSendAnalyticsAfterOpen';
import styles from './SubscriptionInfoModal.css';
import { isBaseSubscription } from './SubscriptionInfoModal.helpers';

type Props = IPropsWithPopup<{ subscription: Subscription }>;

export const SubscriptionInfoModal: VFC<Props> = ({ subscription, onClose }) => {
    const scrollRef = useRef<HTMLDivElement>(null);

    useSendAnalyticsAfterOpen(subscription);

    const handleCloseDialog = () => {
        onClose();
        renderSubscriptionsModal();
    };

    return (
        <MobileDialog
            id="mobile-subscription-info-modal"
            onClose={handleCloseDialog}
            closeOnDimmerClick
            mod="base"
            topmost
            scrollRef={scrollRef}
        >
            <div ref={scrollRef} className={styles.root}>
                {isBaseSubscription(subscription) ? (
                    <BaseSubscription subscription={subscription} />
                ) : (
                    <ModernSubscription subscription={subscription} onClose={onClose} />
                )}
            </div>
        </MobileDialog>
    );
};
