import { Method } from 'axios';
import { APICallV4 } from 'reactApp/api/APICall';

export class FaceListApiCall extends APICallV4 {
    _method = 'public/faces/list';
    _type: Method = 'get';
    _retriesCount = 0;
}

export class PublicFilteredByFaceApiCall extends APICallV4 {
    _method = 'public/files/list';
    _type: Method = 'get';
    _retriesCount = 0;
}
