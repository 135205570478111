import clsx from 'clsx';
import { ReactComponent as Skeleton } from 'img/fileFolderHistoryLog/skeleton.svg';
import React from 'react';

import styles from './Loader.css';

type Props = {
    className?: string;
};

export const LogLoader: React.FC<Props> = (props: Props) => {
    const { className } = props;

    return (
        <div className={clsx(styles.wrapper, className)}>
            <Skeleton />
        </div>
    );
};
