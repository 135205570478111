import { toNDigits } from '@mail/cross-sizes-utils';
import app from 'Cloud/Application/app';
import React, { memo, ReactElement, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { isVirusItem } from 'reactApp/modules/file/utils';
import { getCurrentPublicItem } from 'reactApp/modules/public/public.selectors';
import { PublicFile } from 'reactApp/modules/public/public.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getCurrentStockItem } from 'reactApp/modules/stock/stock.selectors';
import { StockFile } from 'reactApp/modules/stock/stock.type';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { Button } from 'reactApp/ui/Button/Button';
import { FileIcon } from 'reactApp/ui/FileIcon/FileIcon';
import { canCloneWeblink } from 'reactApp/utils/cloneHelpers';
import { formatDefaultDate } from 'reactApp/utils/formatDate';

import styles from './File.css';

export const File = memo(function File(): ReactElement {
    const storage = useSelector(getCurrentStorage);
    const isStock = storage === EStorageType.stock;
    const publicItem = useSelector(getCurrentPublicItem) as PublicFile;
    const stockItem = useSelector(getCurrentStockItem) as StockFile;
    const item = isStock ? stockItem : publicItem;
    const mtime = item && 'mtime' in item && item?.mtime;

    const size = toNDigits({ bytes: item?.size || 0, maxDigitsCount: 3 }).value;
    const isAnonymous = useSelector(UserSelectors.isAnonymous);
    const canClone = canCloneWeblink(!isAnonymous, storage);

    const onDownload = useCallback(
        () => app.download(item, undefined, undefined, storage === EStorageType.stock ? undefined : storage, true),
        [item, storage]
    );
    const onClone = useCallback(() => toolbarActions.clone({ id: item?.id, source: 'shoved' }), [item?.id]);

    return (
        <div className={styles.root}>
            <div className={styles.icon}>
                <FileIcon type={isVirusItem(item) ? 'virus' : item?.ext} />
            </div>
            <div className={styles.fileName}>{item?.name}</div>
            <div className={styles.description}>
                <span data-name="size">{size}</span>{' '}
                {mtime && (
                    <>
                        , <span data-name="date">{formatDefaultDate(mtime)}</span>
                    </>
                )}
            </div>
            <div className={styles.buttonWrapper}>
                <Button theme="octavius" primary middle data-name="download" onClick={onDownload}>
                    Скачать
                </Button>
                {canClone && (
                    <Button theme="octavius" middle data-name="clone" onClick={onClone}>
                        Сохранить в облако
                    </Button>
                )}
            </div>
        </div>
    );
});
