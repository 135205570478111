import classNames from 'clsx';
import { ReactComponent as CloneIcon } from 'img/icons/add2Cloud.svg';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IS_ONPREMISE, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { abRequiredSignUpWhenDownloadingTouchSelector } from 'reactApp/appHelpers/featuresHelpers';
import { FeatureSelector } from 'reactApp/appHelpers/featuresHelpers/FeatureSelector';
import { getIsViewerFileVerifiedByKaspersky } from 'reactApp/modules/file/utils';
import { RequiredSignUpTouchSource } from 'reactApp/modules/requiredAuthorization/constants';
import { requiredAuthorizationHelpers } from 'reactApp/modules/requiredAuthorization/helpers';
import { RootState } from 'reactApp/store';
import { Button } from 'reactApp/ui/Button/Button';
import { ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { ProtectedTooltip } from 'reactApp/ui/Mobile/ProtectedTooltip/ProtectedTooltip';
import { NameComponent } from 'reactApp/ui/Name/Name';
import { DownloadTooltip } from 'reactApp/ui/ReactViewer/ViewerHeader/DownloadTooltip';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';
import { ViewerShareButton } from 'reactApp/ui/ViewerShareButton/ViewerShareButton';
import { DownloadAppButton } from 'reactApp/ui/ViewerToolbar/DownloadAppButton';
import type { IFile } from 'reactApp/ui/ViewerToolbar/ViewerToolbar.types';
import { DownloadIcon } from 'reactApp/ui/VKUIIcons';

import styles from './ViewerToolbar.css';

interface IProps {
    file: IFile;
    isPreviewableItem?: boolean;
    canClone: boolean;
    socialList: any[];
    date: string;
    expires: string;
    handleOnClone: () => void;
    handleOnDownload: () => void;
    handleOnSocial: (id: string) => void;
    noBottomRound?: boolean;
}

export const ViewerToolbarMobile: FC<IProps> = ({
    file,
    canClone,
    date,
    expires,
    handleOnClone,
    handleOnDownload,
    handleOnSocial,
    socialList,
    isPreviewableItem,
    noBottomRound = false,
}) => {
    const [showTip, setShowTip] = useState(false);
    const isViewerFileVerifiedByKaspersky = useSelector(getIsViewerFileVerifiedByKaspersky);
    const isRequiredSingUpTouchSource = useSelector((state: RootState) =>
        requiredAuthorizationHelpers.isRequiredSingUpTouchSource(state, RequiredSignUpTouchSource.PUBLIC_TOOLBAR, file.id)
    );
    const downloadBtnRef = useRef<HTMLDivElement>(null);

    const requiredSingUpTooltip = (
        <DownloadTooltip btnText="Скачать" downloadRef={downloadBtnRef.current} closeOnOutsideClick placement={ETooltipPlacement.topEnd} />
    );

    useEffect(() => {
        if (downloadBtnRef?.current && isRequiredSingUpTouchSource) {
            setShowTip(true);
        }
    }, [downloadBtnRef?.current, isRequiredSingUpTouchSource]);

    return (
        <div className={classNames(styles.root, styles.root_mobile, { [styles.root_noBottomRound]: noBottomRound })}>
            <div className={styles.content}>
                <div className={styles.info}>
                    <div className={styles.name}>
                        <NameComponent name={file.name} extension={file.extension} />
                        {isViewerFileVerifiedByKaspersky && <ProtectedTooltip />}
                    </div>
                    <div className={styles.meta}>
                        {file.size}
                        {date ? `, ${date}` : ''}
                    </div>
                </div>
                <div className={styles.download}>
                    {canClone && (
                        <Button onClick={handleOnClone} color="black" size="big" data-name="clone">
                            <CloneIcon width={24} height={24} viewBox="12 12 24 24" />
                        </Button>
                    )}
                    <Button onClick={handleOnDownload} color="black" size="big" data-name="download">
                        <div ref={downloadBtnRef}>
                            <DownloadIcon />
                        </div>
                    </Button>
                    {showTip && (
                        <FeatureSelector
                            selector={() => abRequiredSignUpWhenDownloadingTouchSelector(false)}
                            control={<></>}
                            variant1={requiredSingUpTooltip}
                            variant2={requiredSingUpTooltip}
                        />
                    )}
                </div>
            </div>
            {expires && <div className={styles.expires}>Доступ по ссылке открыт до {expires}</div>}
            <div className={styles.actions}>
                {IS_WEBVIEW ? (
                    <ViewerShareButton onSocial={handleOnSocial} socialList={socialList} placement={tooltipPlacements.TOP_LEFT} />
                ) : (
                    !IS_ONPREMISE && (
                        <div className={styles.downloadAppBttnWrapper}>
                            <DownloadAppButton file={file} isPreviewableItem={isPreviewableItem} />
                        </div>
                    )
                )}
            </div>
        </div>
    );
};
