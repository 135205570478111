import { getNameById } from 'reactApp/modules/file/utils';
import { ConnectionFail } from 'reactApp/modules/uploading/fails/ConnectionFail';
import { FileExistsFail } from 'reactApp/modules/uploading/fails/FileExistsFail';
import { OverQuotaFail } from 'reactApp/modules/uploading/fails/OverQuotaFail';
import { UserFileSizeLimitFail } from 'reactApp/modules/uploading/fails/UserFileSizeLimitFail';
import { ViolatedFilenameFail } from 'reactApp/modules/uploading/fails/ViolatedFilenameFail';
import { addFile } from 'reactApp/modules/uploading/helpers/cloudFs/addFile';
import { addFolder } from 'reactApp/modules/uploading/helpers/cloudFs/addFolder';
import { getVisibleNameParts, getVisiblePath } from 'reactApp/modules/uploading/helpers/fs/fs.helpers';
import { getThumbnail, sendGaUploaderNew } from 'reactApp/modules/uploading/helpers/uploading.helpers';
import { UploadingDescriptor } from 'reactApp/modules/uploading/serviceClasses/UploadingDescriptor';
import { EUploadingState } from 'reactApp/modules/uploading/uploading.types';
import { EFileStatus } from 'reactApp/modules/uploadList/uploadList.model';
import { updateUploadFilesAction } from 'reactApp/modules/uploadList/uploadList.module';
import { put } from 'redux-saga/effects';

function* _addToUserCloud(descriptor: UploadingDescriptor, workingDirectory = '') {
    const isDirectory = descriptor.isDirectory;

    if (descriptor.state === EUploadingState.STATE_CANCEL || descriptor.hasCanceledParent()) {
        return;
    }

    try {
        let cloudPath;
        if (isDirectory) {
            cloudPath = yield addFolder(descriptor);
        } else {
            cloudPath = yield addFile(descriptor, workingDirectory);
        }

        const nameParts = getVisibleNameParts(cloudPath);
        descriptor.cloudName = getNameById(cloudPath);
        descriptor.cloudPath = cloudPath;
        descriptor.visibleName = getVisiblePath(cloudPath);
        descriptor.visiblePath = getVisiblePath(cloudPath);
        descriptor.nameParts = nameParts;
        descriptor.state = EUploadingState.STATE_DONE;

        if (descriptor.hasInvalidCharAutoFix) {
            sendGaUploaderNew('auto_fix', 'invalid_char_done');
        } else if (descriptor.hasNameTooLongAutoFix) {
            sendGaUploaderNew('auto_fix', 'name_too_long_done');
        }

        if (!isDirectory) {
            yield put(
                updateUploadFilesAction({
                    descriptorId: descriptor.id,
                    cloudPath,
                    currentUpload: false,
                    status: EFileStatus.DONE,
                    loaded: descriptor.size,
                    progress: 100,
                    error: '',
                    thumb: getThumbnail(descriptor),
                    // @ts-ignore
                    name: nameParts?.name ? nameParts.name : '', // имя может изменится после сохранения обоих файлов в случае file_exist
                })
            );
        }
    } catch (error: any) {
        let state;

        if (
            error instanceof OverQuotaFail ||
            error instanceof UserFileSizeLimitFail ||
            error instanceof FileExistsFail ||
            error instanceof ConnectionFail ||
            error instanceof ViolatedFilenameFail
        ) {
            state = EUploadingState.STATE_PAUSED;
        } else {
            state = EUploadingState.STATE_FAIL;
        }

        descriptor.error = error;
        descriptor.state = state;
        throw error;
    }
}

export function* addToUserCloud(descriptor: UploadingDescriptor, workingDirectory = '') {
    const state = descriptor.state;
    if (state === EUploadingState.STATE_UPLOADED) {
        descriptor.state = EUploadingState.STATE_APPENDING;
        yield _addToUserCloud(descriptor, workingDirectory);
    } else {
        throw new Error('addToUserCloud() called in wrong state');
    }
}
