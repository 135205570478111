import classNames from 'clsx';
import React, { memo, useCallback } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import styles from 'reactApp/ui/PollPopup/PollPopup.css';

// eslint-disable-next-line react/display-name
export const SelectableButton = memo(({ title, onClick, isSelected }: { title: string; onClick(id: string); isSelected?: boolean }) => {
    const choiceClick = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();

            onClick(title);
        },
        [onClick, title]
    );

    return (
        <Button className={classNames(styles.button, { [styles.button_selected]: isSelected })} onClick={choiceClick} theme="octavius">
            {title}
        </Button>
    );
});
