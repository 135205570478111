import React, { memo, ReactElement, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    filesFilterAddToFilter,
    filesFilterAll,
    filesFilterOne,
    filesFilterRemoveAll,
    filesFilterRemoveFromFilter,
} from 'reactApp/modules/filesFilter/filesFilter.module';
import { getCurrentAuthorInFilter, isAllFiltered } from 'reactApp/modules/filesFilter/filesFilter.selectors';
import {
    getFolderAuthors,
    isFolderOwner,
    isFolderWithAuthor,
    mapFolderAuthorsWithContacts,
} from 'reactApp/modules/storage/storage.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { RootState } from 'reactApp/store';
import { AuthorItem } from 'reactApp/ui/Mobile/FilterAuthorsModal/AuthorItem';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { OptionsBlock } from 'reactApp/ui/Mobile/OptionsModal/OptionsBlock/OptionsBlock';
import { Switch } from 'reactApp/ui/Switch/Switch';
import { noopVoid } from 'reactApp/utils/helpers';

import styles from './FilterAuthorsModal.css';

export const FilterAuthorsModal = memo(({ onClose = noopVoid }: { onClose?: () => void }): ReactElement => {
    const dispatch = useDispatch();

    const userEmail = useSelector(UserSelectors.getEmail);
    const authorsEmails = useSelector(getFolderAuthors);
    const authors = useSelector((state: RootState) => mapFolderAuthorsWithContacts(state, authorsEmails));
    const isAll = useSelector(isAllFiltered);
    const filter = useSelector(getCurrentAuthorInFilter);
    const isOnlyMine = filter.includes(userEmail || '') && filter.length === 1;
    const isOwn = useSelector(isFolderOwner);
    const hasAuthor = useSelector(isFolderWithAuthor) && isOwn;

    const onAuthorClick = useCallback(
        (email, checked) => {
            if (checked) {
                dispatch(filesFilterRemoveFromFilter({ author: email, folderAuthors: authorsEmails }));
            } else {
                dispatch(filesFilterAddToFilter({ author: email, folderAuthors: authorsEmails }));
            }
        },
        [authorsEmails, dispatch]
    );

    const onAllClick = useCallback(() => {
        if (isAll) {
            dispatch(filesFilterRemoveAll());
        } else {
            dispatch(filesFilterAll());
        }
        onClose();
    }, [dispatch, isAll, onClose]);

    const onUserClick = useCallback(() => {
        if (isAll || filter.length === 0) {
            dispatch(filesFilterOne(userEmail || ''));
        } else {
            dispatch(filesFilterAll());
        }
        onClose();
    }, [dispatch, filter.length, isAll, onClose, userEmail]);

    const renderSwitch = useCallback(({ checked, id }) => {
        return <Switch key={`author_${id}`} name={`author_${id}`} checked={checked} theme="rounded" disabled={false} />;
    }, []);

    const selectOptions = useMemo(
        () => [
            {
                id: 'all',
                key: 'all',
                text: <div className={styles.menuItem}>Показывать файлы от всех {renderSwitch({ checked: isAll, id: 'all' })}</div>,
                onClick: onAllClick,
            },
            {
                id: 'mine',
                key: 'mine',
                text: <div className={styles.menuItem}>Показывать только мои файлы{renderSwitch({ checked: isOnlyMine, id: 'mine' })}</div>,
                onClick: onUserClick,
            },
        ],
        [isAll, isOnlyMine, onAllClick, onUserClick, renderSwitch]
    );

    return (
        <MobileDialog
            id="mobile-authors-modal"
            open
            dimmer
            onClose={onClose}
            disableScrolling
            closable
            closeOnDimmerClick
            mod="base"
            topmost
            title={<div className={styles.title}>Фильтр по участникам</div>}
        >
            <div className={styles.root}>
                <div className={styles.filter}>
                    <OptionsBlock id="authors" onClose={onClose} items={selectOptions} />
                </div>
                {hasAuthor && (
                    <div className={styles.block}>
                        <div className={styles.blockTitle}>Участники</div>
                        {authors.map((author) => (
                            <AuthorItem
                                checked={isAll || filter.includes(author.email)}
                                key={author.email}
                                email={author.email}
                                name={author.nick || author.name}
                                onClick={onAuthorClick}
                            />
                        ))}
                    </div>
                )}
            </div>
        </MobileDialog>
    );
});

FilterAuthorsModal.displayName = 'FilterAuthorsModal';
