import { xray } from 'lib/xray';
import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';

export enum createDocumentByLinkDialogAnalytics {
    LINK_OPEN = 'link_open',
    LINK_CREATE_DOC = 'link_create-doc',
}

export const sendCreateDocumentByLinkDialogRadar = ({ i, isLlmOpen }: { i: createDocumentByLinkDialogAnalytics; isLlmOpen: boolean }) =>
    xray.send('editor', {
        i,
        dwh: {
            place: IS_PHONE_BROWSER ? 'touch' : 'desktop',
            GPTOpen: isLlmOpen,
        },
    });
