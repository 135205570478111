import { RefObject, useCallback, useEffect, useRef } from 'react';

const mobileVisibilityThreshold = 0.3;

interface IIntersection {
    onIntersecting?: () => void;
    onIntersectingOut?: () => void;
    rootMargin?: string;
    threshold?: number | number[];
}

export const useIntersectionObserver = ({
    onIntersecting,
    onIntersectingOut,
    rootMargin = '',
    threshold = 0.1,
}: IIntersection): RefObject<HTMLDivElement> => {
    const ref = useRef<HTMLDivElement>(null);
    const onIntersectingCallback = useCallback(
        (entries: IntersectionObserverEntry[]): void => {
            entries.forEach((entry: IntersectionObserverEntry) => {
                const condition = Array.isArray(threshold) ? entry.intersectionRatio >= mobileVisibilityThreshold : entry.isIntersecting;
                if (condition) {
                    onIntersecting?.();
                } else {
                    onIntersectingOut?.();
                }
            });
        },
        [onIntersecting]
    );

    const observer = useRef<IntersectionObserver | null>(null);

    const unobserve = useCallback(() => {
        if (!ref.current) {
            return;
        }

        observer.current?.unobserve(ref.current);
        observer.current?.disconnect();
    }, []);

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        if (observer.current) {
            unobserve();
        }

        // eslint-disable-next-line compat/compat
        observer.current = new IntersectionObserver(onIntersectingCallback, { threshold, rootMargin });
        observer.current?.observe(ref.current);

        return (): void => unobserve();
    }, [onIntersectingCallback, unobserve, rootMargin]);

    return ref;
};
