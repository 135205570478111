define(function (require, exports, module) {
	'use strict';

	module.exports = function (email) {
		email = String(email);

		var login = email.replace(/@.*/, '');
		var domain = email.replace(/.*@/, '');
		var now = Date.now();

		return {
			"file/add": {
				"noauth": {
					"status": 403,
					"body": "user"
				}
			}
		};
	};
});
