import classNames from 'clsx';
import React, { memo, useCallback } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import { openerDelayed } from 'reactApp/utils/opener';

import styles from './ErrorPageButton.css';
import { IProps } from './ErrorPageButton.types';

export const ErrorPageButton = memo<IProps>(function ErrorPageButton({ link, text, onClick }) {
    const handleClick = useCallback(() => {
        onClick?.();
        openerDelayed(link, 200, true);
    }, [link, onClick]);

    // При рендере статической страницы нужно рендерить ссылку
    if (process.env.SSR) {
        return (
            <a href={link} className={classNames(styles.root, 'js-error-page-button')}>
                {text}
            </a>
        );
    }

    return (
        <Button theme="vk" secondary middle className={styles.root} onClick={handleClick}>
            {text}
        </Button>
    );
});
