import { isQuotaCleanerMobLetterUrlToTouch } from 'reactApp/appHelpers/featuresHelpers';
import { QuotaCleanerItem } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.types';
import { UserQuotaGroupType } from 'reactApp/types/QuotaCleaner';
import opener from 'reactApp/utils/opener';
import { getEncodePath, getHomeFolderPath } from 'reactApp/utils/urlHelper';

export const openItemView = (item: QuotaCleanerItem, isMobile?: boolean) => {
    const { groupType, viewPath } = item;
    let url = viewPath;

    if (groupType === UserQuotaGroupType.Cloud) {
        url = getHomeFolderPath(getEncodePath(viewPath));
    }

    if (isMobile && isQuotaCleanerMobLetterUrlToTouch) {
        if (url.includes('e.mini-mail.ru')) {
            url = url.replace('e.mini-mail', 'touch.mini-mail');
        } else {
            url = url.replace('e.mail', 'touch.mail');
        }
    }
    opener(url);
};
