import { loadMoreRequest } from 'reactApp/modules/modifying/modifying.actions';

export const onScroll = ({ storage, items, folderId, hasMoreToLoad = false, dispatch }) => {
    // todo: по-хорошему, тут надо через folder?.count?.loaded,
    // Но что-то идет не так с перерисовками
    const offset = items.length;
    const id = folderId;

    if (hasMoreToLoad) {
        dispatch(
            loadMoreRequest({
                id,
                storage,
                offset,
            })
        );
    }
};
