import { ReactComponent as DropdownIcon } from '@vkontakte/icons/src/svg/16/dropdown_outline_16.svg';
import { ReactComponent as UsersIcon } from '@vkontakte/icons/src/svg/16/users_2_outline_16.svg';
import classNames from 'clsx';
import React from 'react';

import stylesWeblink from '../Weblink/SharingNewWeblink.css';
import styles from './SharingNewSharing.css';

interface IProps {
    isCloudUsersOpened: boolean;
    setShowUsersScreen(value: boolean): void;
}

const SharingNewSharingToggler: React.FC<IProps> = ({ isCloudUsersOpened, setShowUsersScreen }) => {
    return (
        <div className={classNames(stylesWeblink.rightsItem, { [stylesWeblink.rightsItemNoMargin]: true })}>
            <div className={stylesWeblink.titleWrapper}>
                <span className={stylesWeblink.icon}>
                    <UsersIcon />
                </span>
                <span className={stylesWeblink.rightsTitle}>{isCloudUsersOpened ? 'Доступ по приглашению' : 'Доступ закрыт'}</span>
            </div>
            <div className={styles.toggler} onClick={() => setShowUsersScreen(true)}>
                Настроить <DropdownIcon />
            </div>
        </div>
    );
};

export default SharingNewSharingToggler;
