import config from 'Cloud/config';

const AVATAR_SIZES = {
    32: true,
    40: true,
    45: true,
    90: true,
    180: true,
};

const FILIN_HOST = config.get('FILIN_HOST');

/**
 * Генерит урл картинки-аватара юзера по его логину и имени
 *
 * @param {string} email - User login email
 * @param {string} name - User name
 * @param {number} [size=45] - Avatar width and height
 * @return {string} User avatar picture url
 */
export const createAvatarUrl = (email: string, name?: string, size: keyof typeof AVATAR_SIZES = 45) => {
    const version = '&version=4&build=7';
    const realSize = AVATAR_SIZES[size] ? size : 45;

    return `${`//${FILIN_HOST}/pic?width=${realSize}&height=${realSize}&email=${encodeURIComponent(email)}&name=${encodeURIComponent(
        name || email
    )}`}${version}`;
};
