/* eslint-disable complexity */
/* eslint-disable object-shorthand */
/* eslint-disable max-lines-per-function */
import { captureException, captureMessage } from '@sentry/browser';
import _ from 'Cloud/Application/_';
import app from 'Cloud/Application/app';
import { editor } from 'Cloud/Application/Editor';
import { Rubles } from 'Cloud/Application/Rubles';
import { user } from 'Cloud/Application/User';
import $ from 'jquery';
import { parse } from 'qs';
import { isNil } from 'ramda';
import { IS_PHONE_BROWSER, IS_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import { initMedia } from 'reactApp/modules/media/media.module';
import { routeChangeStart, routeChangeSuccess, routerStart, routeStatusPage } from 'reactApp/modules/router/router.module';
import { resetSelect } from 'reactApp/modules/selections/selections.actions';
import { settingsController } from 'reactApp/modules/settings/settings.controller';
import { initSort } from 'reactApp/modules/sort/sort.module';
import { loadUserQuotaRequest } from 'reactApp/modules/userQuota/userQuota.module';
import { checkViewerAtIdChange } from 'reactApp/modules/viewer/viewer.module';
import { EStatus } from 'reactApp/sections/ErrorPage/ErrorPage.types';
import { store } from 'reactApp/store';
import { sendXray } from 'reactApp/utils/ga';

// При вызове replaceState срабатывал обработчик pagechange и вызывал
// goToLocation, который при переименовании в редакторе открывал 404
// Пишем в history.state forceNoGoTo чтобы не вызывать goToLocation
// @see CLOUDWEB-12475
export function replaceState(title, url) {
    const documentTitle = title || document.title;
    const state = history.state || {};

    state.forceNoGoTo = true;
    history.replaceState(state, documentTitle, url);

    document.title = documentTitle;
}

export default function () {
    const handlerAtRouteEndResetSelection = () => {
        app.off('routeEnd', handlerAtRouteEndResetSelection);
        store.dispatch(resetSelect());
    };

    const fixInitialState = () => {
        // CLOUDWEB-635, CLOUDWEB-5069
        const loc = window.location;
        const hash = loc.hash;

        if (hash && /^#\//.test(hash)) {
            const path = app.buildHref(
                `/${hash
                    .replace(/^[^#]*#/, '')
                    .replace(/([\/\\])+/gi, '/')
                    .replace(/^\//, '')}`
            );
            loc.hash = '';
            history.replaceState(null, document.title, path);
        }
    };

    // приватные методы
    Object.assign(_, {
        cid: function () {
            return _.history.currentState().id;
        },

        sid: function () {
            return _.history.currentState().storage;
        },
    });

    Object.assign(app, {
        isHome: function (storage) {
            storage = storage || _.history.currentState().storage;
            return storage === 'home';
        },

        isEditor: function (storage) {
            storage = storage || _.history.currentState().storage;

            return ['myoffice/edit/home', 'myoffice/edit/attaches', 'r-seven/edit/home', 'office/edit/home', 'r7w/edit/home'].includes(
                storage
            );
        },

        isLinks: function (storage) {
            storage = storage || _.history.currentState().storage;
            return storage === 'links';
        },

        isShared: function (storage) {
            return this.isSharedIncoming(storage) || this.isSharedLinks(storage);
        },

        isSharedIncoming: function (storage) {
            storage = storage || _.history.currentState().storage;
            return storage === 'shared/incoming';
        },

        isSharedLinks: function (storage) {
            storage = storage || _.history.currentState().storage;
            return storage === 'shared/links';
        },

        isTrashBin: function (storage) {
            storage = storage || _.history.currentState().storage;
            return storage === 'trashbin';
        },

        isAttaches: function (storage) {
            storage = storage || _.history.currentState().storage;
            return storage === 'attaches';
        },

        isFavorites: function (storage) {
            storage = storage || _.history.currentState().storage;
            return storage === 'favorites';
        },

        isStart: function (storage) {
            storage = storage || _.history.currentState().storage;
            return storage === 'start';
        },
        isSearch: function (storage) {
            storage = storage || _.history.currentState().storage;
            return storage === 'search';
        },

        isFeed: function (storage) {
            storage = storage || _.history.currentState().storage;
            return storage === 'feed';
        },

        getState: function () {
            return _.history.currentState();
        },

        getStorage: function () {
            return _.sid();
        },

        goToForced: function (state) {
            return this.goTo(state, true, false);
        },

        goTo: function (state, forceReload, fromLocation, fromHistory, isPopstate, forceNoApi) {
            const isStart = state === ':start:';

            if (state && state.error) {
                captureMessage('GO TO, bad state', {
                    arguments: state,
                });

                return this.emit('routeNotFound', state);
            }
            const radarState = state;

            if (typeof state === 'object') {
                state.params = state.params || {};

                if (!state.params.sort && app.settings.request.query_params.sort) {
                    state.params.sort = app.settings.request.query_params.sort;
                }
            }

            const record = _.history.setState(state, fromHistory, isPopstate, forceReload);
            if (record.prevent) {
                return;
            }

            if (state && state.params) {
                settingsController.setQueryParams(state.params);
            }

            state = record.state;
            const hasDiff = record.hasDiff;
            const bigDeal = forceReload || record.bigDeal;
            const isFromViewToFolder = record.prev && !isNil(record.prev.__isFolder) && !record.prev.__isFolder && state.__isFolder;

            const loadingDeferred = new $.Deferred();

            if (state.error) {
                captureMessage('GO TO, bad state', {
                    arguments: state,
                });

                return this.emit('routeNotFound', state, bigDeal);
            } else if (hasDiff || !fromLocation) {
                // TODO: call new radar
                const sendRadar = (name) => sendXray(['router_go-to', name]);
                const isStorageChanged = record.isStorageChanged;

                sendRadar(`bigdeal-${bigDeal ? 'on' : 'off'}`);
                sendRadar(`storage-${isStorageChanged ? 'on' : 'off'}`);

                app.emit('routeStart', state, bigDeal);

                if (bigDeal) {
                    app.emit('routeChangeStart', state);
                    store.dispatch(
                        routeChangeStart({
                            ...state,
                        })
                    );
                    if (!IS_PHONE_BROWSER) {
                        store.dispatch(
                            checkViewerAtIdChange({
                                id: state.id,
                                storage: state.storage,
                            })
                        );
                    }

                    if (isStorageChanged) {
                        app.emit('routeChangeStorageStart', state);
                    }
                }

                const deffered = forceNoApi ? new $.Deferred().resolve() : app.load(state.id, state.storage);
                deffered
                    .done(function () {
                        loadingDeferred.resolve();
                        sendRadar('success');

                        if (bigDeal) {
                            if (isStorageChanged) {
                                app.emit('routeChangeStorageEnd', state);
                            }

                            app.emit('routeChangeEnd', state);
                        }

                        if (!forceNoApi) {
                            user.updateSpace();
                        }

                        if (isStorageChanged || (hasDiff && !isFromViewToFolder && state.__isFolder)) {
                            app.on('routeEnd', handlerAtRouteEndResetSelection);
                        }

                        if (!isStart) {
                            store.dispatch(routeChangeSuccess({ ...state }));
                        }

                        app.emit('route', state);
                        app.emit('routeEnd', state);
                    })
                    .fail(function (result, status, response) {
                        captureMessage('GO TO, fail load', {
                            arguments: result,
                        });

                        const radarFail = RADAR.group('router_app_load');

                        radarFail.kaktam(
                            'Router',
                            JSON.stringify({
                                newState: radarState,
                                currentState: _.history.currentState(),
                                newRecord: record,
                                forceReload: forceReload,
                                fromLocation: fromLocation,
                                fromHistory: fromHistory,
                                isPopstate: isPopstate,
                            })
                        );

                        radarFail.send();

                        loadingDeferred.reject();
                        radarFail.add('error');

                        if (!result || !response || response.isInvalid() || response.isNotfound()) {
                            store.dispatch(routeStatusPage({ status: EStatus.NOT_FOUND }));
                        } else {
                            app.emit('routeChangeFailed', state, bigDeal);
                        }
                    });
            }

            if (hasDiff && !fromHistory) {
                loadingDeferred.always(function () {
                    sendXray('router_push-state');
                    _.history.historySet(state, !hasDiff);
                });
            } else if (isStart) {
                _.history.historySet(state, true);
            }

            if (!hasDiff) {
                sendXray('router_no-diff');
            }

            return state;
        },

        goToLocation: function (url, fromHistory, isPopstate) {
            return this.goTo(url, false, true, fromHistory, isPopstate);
        },

        refresh: function () {
            sendXray('router_refresh');
            return this.goToForced(':current:');
        },

        start: function () {
            try {
                Rubles.runPhoneTopBannerUpdater();
            } catch (error) {
                captureException(error);
            }

            sendXray('router_start');
            fixInitialState();
            _.initTree();
            initMedia();

            settingsController.loadSettingsRequest();
            settingsController.setQueryParams(parse(window.location.search, { ignoreQueryPrefix: true }));

            const state = this.goToForced(':start:');

            store.dispatch(initSort());

            const isEditor = (user.isAuthorized() || IS_PUBLIC) && editor?.isEditor();

            store.dispatch(
                routerStart(
                    isEditor
                        ? {
                              ...state,
                              storage: 'editor',
                          }
                        : { ...state }
                )
            );
            store.dispatch(loadUserQuotaRequest());

            this.emit('page:start', state);
            editor?.clearWindowName(); // CLOUDWEB-6848
        },
    });

    const isOnlyTrailingSlashChanged = function (oldUrl, newUrl) {
        oldUrl = String(oldUrl);
        newUrl = String(newUrl);

        return `${oldUrl}/` === newUrl || `${newUrl}/` === oldUrl;
    };

    // вешаемся на изменения урла
    $(window).bind('pagechange', function (event) {
        if ('originalEvent' in event) {
            // if event is jQuery event
            event = event.originalEvent;
        }

        sendXray('router_pagechange');

        if (isOnlyTrailingSlashChanged(event.oldUrl, event.newUrl)) {
            sendXray('router_diff-trailing-slash');
        } else {
            const state = history.state || {};

            if (state.forceNoGoTo) {
                return;
            }

            app.goToLocation(event.newUrl, true, event.popstate || (history.state || {}).forceIsPopstate);
        }
    });

    _.history.setState(app.settings.state);
}
