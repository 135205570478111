import { channel } from '@redux-saga/core';
import { PayloadAction } from '@reduxjs/toolkit';
import api from 'Cloud/Application/api';
import { logger } from 'lib/logger';
import { isReadOnlyHttpStatus } from 'reactApp/api/HttpErrorCodes';
import { MountFolderApiCall } from 'reactApp/api/mount/MountFolderApiCall';
import { RejectFolderApiCall } from 'reactApp/api/mount/RejectFolderApiCall';
import { InvitesInfoApiCall } from 'reactApp/api/sharing/InvitesInfoApiCall';
import { ISharedInfo, SharedInfoApiCall } from 'reactApp/api/sharing/SharedInfoApiCall';
import { renderSharingNewMount } from 'reactApp/components/SharingNewBiz/Mount/SharingNewMount.helpers';
import { renderSharingNewReject } from 'reactApp/components/SharingNewBiz/Reject/SharingNewReject.helpers';
import { renderSharingNewUnMount } from 'reactApp/components/SharingNewBiz/Unmount/SharingNewUnMount.helpers';
import { EAction } from 'reactApp/components/SharingNewBiz/Unmount/SharingNewUnMount.types';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { resetSelect } from 'reactApp/modules/selections/selections.actions';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { isMountedFolder } from 'reactApp/modules/storage/folder.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { updateUser } from 'reactApp/modules/user/user.thunkActions';
import { EConflictMode } from 'reactApp/types/EConflictMode';
import { callSagaFromAction } from 'reactApp/utils/callSagaFromAction';
import { promisifyDeferredCall } from 'reactApp/utils/helpers';
import { put, select, take } from 'redux-saga/effects';

import { mountSuccess, unmountSuccess, unshareFolderSuccess } from '../modifying.actions';
import { mountErrors } from '../modifying.constants';
import { IMountRequest, IUnmountRequest } from '../modifying.types';
import { handleRemoveItemsRequest } from './remove.saga';

const mountFolderApiCall = ({ id, inviteToken }) =>
    new MountFolderApiCall().makeRequest({ home: id, invite_token: inviteToken, conflict: EConflictMode.RENAME });
const rejectFolderApiCall = ({ inviteToken }) => new RejectFolderApiCall().makeRequest({ invite_token: inviteToken });
const sharedInfoApiCall = ({ id }) => new SharedInfoApiCall().makeRequest({ home: id });
const invitesInfoApiCall = ({ inviteToken }) => new InvitesInfoApiCall().makeRequest({ invite_token: inviteToken });

export function* getInfo(item) {
    const { inviteToken, id, kind, info, home } = item;
    const isInvites = kind === 'invites';

    if (info?.invites) {
        return item.info;
    }

    try {
        const { data } = yield isInvites ? invitesInfoApiCall({ inviteToken }) : sharedInfoApiCall({ id: home || id });

        return { info: data.body };
    } catch (error: any) {
        return { error: error?.response?.body };
    }
}

function* askForUnmountFolder(info: ISharedInfo, item, onUnmount?: () => void) {
    const unmountChannel = channel();
    const currentUserEmail = yield select(UserSelectors.getEmail);
    const access = info.invited?.find((user) => user.email === currentUserEmail)?.access || 'r';

    renderSharingNewUnMount({
        info: { ...info, access },
        onClose: () => unmountChannel.close(),
        onUnmount: (action) => unmountChannel.put(action),
    });

    const action = yield take(unmountChannel);
    const id = info.home || item.home || item.id;

    try {
        if (action === EAction.REMOVE) {
            const itemToDelete = { ...item, id: item.home };
            yield handleRemoveItemsRequest([itemToDelete]);
        }
        if (action === EAction.UNMOUNT) {
            yield promisifyDeferredCall(api.folder.unmount, {
                home: id,
                clone_copy: true,
            });
        }

        yield put(unmountSuccess({ id }));
        yield put(unshareFolderSuccess({ id }));
        yield closePopupHelper(popupNames.UNMOUNT_DIALOG);
        onUnmount?.();
        // @ts-ignore
        yield put(updateUser());
    } catch (error: any) {
        logger.error(error);

        if (!isReadOnlyHttpStatus(error.status)) {
            return;
        }

        yield put(
            showSnackbarAction({
                id: 'ins-error',
                text: 'В Облаке недостаточно места',
                type: SnackbarTypes.failure,
                closable: true,
            })
        );
    }
}

function* askForRejectFolder(info: ISharedInfo, item) {
    const rejectChannel = channel();

    renderSharingNewReject({
        info,
        onClose: () => rejectChannel.close(),
        onReject: () => rejectChannel.put(true),
    });

    yield take(rejectChannel);
    const id = info.home || item.home || item.id;
    yield rejectChannel.close();

    try {
        const { inviteToken } = item;
        yield rejectFolderApiCall({ inviteToken });
        yield put(unmountSuccess({ id }));
        yield put(unshareFolderSuccess({ id }));
        yield closePopupHelper(popupNames.REJECT_DIALOG);
        yield put(resetSelect());
    } catch (error) {
        logger.error(error);
        yield put(
            showSnackbarAction({
                id: 'folder-reject',
                type: SnackbarTypes.failure,
                text: 'Не удалось отказаться',
                closable: true,
            })
        );
    }
}

export function* handleUmountFolder({ item, onUnmount }: IUnmountRequest) {
    const { info } = yield getInfo(item);

    if (isMountedFolder(item)) {
        yield askForUnmountFolder(info, item, onUnmount);
        return;
    }

    yield askForRejectFolder(info, item);
}

export function* handleMountRequest(action: PayloadAction<IMountRequest>) {
    const { item } = action.payload;
    const { info } = yield getInfo(item);
    const mountChannel = channel();

    renderSharingNewMount({
        info,
        onClose: () => mountChannel.close(),
        onMount: () => mountChannel.put(true),
    });

    try {
        const { inviteToken, name } = item as any;
        const id = ('tree' in item && item.tree) || '';

        yield take(mountChannel);
        yield mountChannel.close();
        const result = yield mountFolderApiCall({ id: `/${name}`, inviteToken });
        const { home } = result?.data?.body;
        yield put(mountSuccess({ id, home }));
        yield closePopupHelper(popupNames.MOUNT_DIALOG);

        // @ts-ignore
        yield put(updateUser());
    } catch (error: any) {
        logger.error(error);
        const errorMessage = error?.response?.body;

        yield put(
            showSnackbarAction({
                id: 'folder-reject',
                type: SnackbarTypes.failure,
                text: mountErrors.getMessage(errorMessage),
                closable: true,
            })
        );
    }
}

export const handleUnmountRequest = (payload: PayloadAction<IUnmountRequest>) =>
    callSagaFromAction<IUnmountRequest>(handleUmountFolder, payload);
