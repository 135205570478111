import { Method } from 'axios';
import { APICallV4 } from 'reactApp/api/APICall';
import { Weblink } from 'reactApp/types/Tree';

interface IShareAlbumApiCall extends Weblink {
    name: string;
    path: string;
    type: 'album';
}

export class ShareAlbumAPICall extends APICallV4<IShareAlbumApiCall> {
    _method = 'album/share';
    _type: Method = 'post';
}
