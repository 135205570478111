import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';

const getDescriptionPart = (item, isAlbum) => {
    if (isAlbum) {
        return 'этот альбом';
    }

    if (item?.isFolder) {
        return 'эта папка';
    }

    return 'этот файл';
};

export const renderUnpublishWeblinkDialog = ({ onActionClick, item, isAlbum }) => {
    const description = `После отключения ${getDescriptionPart(item, isAlbum)} перестанет быть ${
        item?.isFolder ? 'доступной' : 'доступным'
    } для пользователей, у которых была ссылка`;

    openPopupHelper({
        popupName: popupNames.NO_WARNING,
        data: {
            onActionClick,
            id: 'weblink-warning-dialog',
            title: 'Вы действительно хотите отключить доступ по ссылке?',
            description,
            buttonAcceptText: 'Отключить',
            buttonRejectText: 'Не отключать',
        },
    });
};
