import classNames from 'clsx';
import React, { memo, ReactElement, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { QuotaLandingSelectors } from 'reactApp/modules/quotaLanding/quotaLanding.selector';

import styles from './Container.css';

export const Container = memo(({ children, isNarrow }: { children: ReactNode; isNarrow?: boolean }): ReactElement => {
    const isMobile = useSelector(QuotaLandingSelectors.isMobile);

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles.root_mobile]: isMobile,
                [styles.root_narrow]: isNarrow,
            })}
        >
            {children}
        </div>
    );
});

Container.displayName = 'Container';
