import { EStorageType } from 'reactApp/modules/storage/storage.types';

export const trashbinCrumb = [
    {
        name: 'Корзина',
        id: '/',
        kind: 'storage' as const,
        storage: EStorageType.trashbin,
    },
];

export const favoritesCrumb = [
    {
        name: 'Избранные',
        id: '/',
        kind: 'storage' as const,
        storage: EStorageType.favorites,
    },
];

export const recomendCrumb = [
    {
        name: 'VK рекомендует',
        id: '/',
        kind: 'storage' as const,
        storage: EStorageType.recommend,
    },
];

export const feedCrumb = [
    {
        name: 'Последние файлы',
        id: '/',
        kind: 'storage' as const,
        storage: EStorageType.feed,
    },
];

export const galleryCrumb = [
    {
        name: 'Галерея',
        id: '/',
        kind: 'storage' as const,
        storage: EStorageType.gallery,
    },
];

export const autodeleteCrumb = [
    {
        name: 'Автоудаляемые',
        id: '/',
        kind: 'storage' as const,
        storage: EStorageType.sharedAutodelete,
    },
];

export const familyCrumb = [
    {
        name: 'Общая подписка',
        id: '/',
        kind: 'storage' as const,
        storage: EStorageType.family,
    },
];
