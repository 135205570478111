import { useMemo, useState } from 'react';
import { DropdownTheme, ListItem } from 'reactApp/ui/DropdownList/DropdownList';
import { EHeaderColor } from 'reactApp/ui/Header/HeaderWrapper';
import { EDropdownPosition } from 'reactApp/ui/Toolbar/Toolbar.types';

export const EVENTS_FILTERS = [
    {
        text: 'Все изменения',
        id: 'all',
        key: 'all',
    },
    {
        text: 'Создание',
        id: 'create',
        key: 'create',
    },
    {
        text: 'Перемещение',
        id: 'move',
        key: 'move',
    },
    {
        text: 'Удаление / восстановление',
        id: 'remove_restore',
        key: 'remove_restore',
    },
    {
        text: 'Переименование',
        id: 'rename',
        key: 'rename',
    },
    {
        text: 'Изменение настроек доступа',
        id: 'access_edit',
        key: 'access_edit',
    },
    {
        text: 'Открытие / закрытие доступа',
        id: 'sharing',
        key: 'sharing',
    },
];

export const DEFAULT_FILTER = EVENTS_FILTERS[0].id;

export const useFileFolderChangesHook = (isFolder, changeHandler) => {
    const [filter, setFilter] = useState(DEFAULT_FILTER);

    const filterItems = useMemo(() => {
        return EVENTS_FILTERS.reduce((acc: ListItem[], item: ListItem) => {
            const listItem = {
                ...item,
                withStatus: true,
                active: item.id === filter,
                onClick: changeHandler,
            };
            if ('onlyFolder' in listItem) {
                if (isFolder) {
                    acc.push(listItem);
                }
                return acc;
            }
            if ('onlyFile' in listItem) {
                if (!isFolder) {
                    acc.push(listItem);
                }
                return acc;
            }
            acc.push(listItem);
            return acc;
        }, []);
    }, [isFolder, changeHandler, filter]);

    return {
        filter,
        setFilter,
        filterItems,
        config: {
            key: 'FileFolderChangesLogFilter',
            id: 'FileFolderChangesLogFilter',
            color: EHeaderColor.WHITE,
            collapse: false,
            dropdownTheme: DropdownTheme.large,
            dropdownPosition: EDropdownPosition.left,
            maxDropdownListHeight: 400,
        },
    };
};
