import React, { memo } from 'react';
import { IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';

import styles from './Logo.css';
import { getAlt, getLogoSrc } from './Logo.helpers';
import { IProps } from './Logo.types';

export const LogoComponent = memo<IProps>(function LogoComponent({ isBizUser, logoSrc: src, bizLogo }) {
    const logoSrc = getLogoSrc({ isBizUser, logoSrc: src, bizLogo });
    const alt = getAlt(isBizUser);
    if (isBizUser && IS_MY_TEAM) {
        return null;
    }

    return (
        <a href="/" title={alt} className={styles.root} data-qa-button="logo">
            <img height={24} src={logoSrc} alt={alt} />
        </a>
    );
});
