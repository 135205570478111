import { ReactComponent as VirusIcon } from 'img/filetype/flatNew/virus.svg';
import React, { memo, ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getItemById } from 'reactApp/modules/storage/storage.selectors';
import { RootState } from 'reactApp/store';
import { Button } from 'reactApp/ui/Button/Button';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { ActionName, ActionTarget } from 'reactApp/ui/VirusDialog/VirusDialog.types';

import styles from './VirusDialog.css';

interface IProps {
    onDownload: () => void;
    onDownloadOnlySafe: () => void;
    onClose: () => void;
    id: string;
    actionTarget: ActionTarget;
    actionName: ActionName;
    isPublic: boolean;
    hasSafeFiles: boolean;
}

export const VirusDialog = memo(function VirusDialog(props: IProps): ReactElement {
    const { onClose, onDownload, onDownloadOnlySafe, id, actionTarget, isPublic, actionName, hasSafeFiles } = props;
    const item = useSelector((state: RootState) => getItemById(state, id));
    const canDownload = !isPublic && (actionName === ActionName.download || actionName === ActionName.zip);

    const footer = useMemo(
        () => (
            <div className={styles.footer}>
                {hasSafeFiles && (
                    <Button theme="octavius" className={styles.button} primary fluid onClick={onDownloadOnlySafe}>
                        Скачать только безопасные
                    </Button>
                )}
                <Button theme="octavius" className={styles.button} fluid onClick={onDownload}>
                    {actionName === ActionName.zip ? 'Скачать все' : 'Сохранить'}
                </Button>
            </div>
        ),
        [onDownload, onDownloadOnlySafe, hasSafeFiles]
    );

    const content = useMemo(() => {
        if (actionTarget === ActionTarget.file) {
            return 'Файл содержит вирус. Мы рекомендуем не скачивать файл и не использовать его на вашем устройстве';
        }

        if (actionTarget === ActionTarget.several) {
            return 'В выбранных файлах содержится вирус. Мы рекомендуем не скачивать файлы и не использовать их на вашем устройстве';
        }
    }, [actionTarget]);

    return (
        <MobileDialog
            id="mobile-virus-modal"
            open
            dimmer
            onClose={onClose}
            disableScrolling
            closable
            closeOnDimmerClick
            mod="confirm"
            topmost
            footer={canDownload ? footer : undefined}
        >
            <div className={styles.root}>
                <VirusIcon className={styles.icon} />
                {actionTarget === ActionTarget.file && <div className={styles.title}>{item?.name}</div>}
                <div>{content}</div>
            </div>
        </MobileDialog>
    );
});
