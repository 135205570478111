import { Text } from '@vkontakte/vkui';
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { SelectFromCloudContentMod } from 'reactApp/sections/AlbumsPage/SelectFromCloudPopup/components/Content/SelectFromCloudContent';
import { DataList } from 'reactApp/ui/Datalist/DataList';
import { noop } from 'reactApp/utils/helpers';
import { filesPlural, selectedPlural } from 'reactApp/utils/pluralHelpers';

import styles from './ShowSelected.css';

interface ShowSelectedProps {
    setMod: Dispatch<SetStateAction<SelectFromCloudContentMod>>;
}

export const ShowSelected: FC<ShowSelectedProps> = ({ setMod }) => {
    const [isLoaded, setLoaded] = useState(false);
    const datalistRef = useRef<HTMLDivElement>(null);
    const selectedIdxs = useSelector(SelectionsSelectors.getSelectedIdxs);

    useEffect(() => {
        setLoaded(true);
    }, []);

    useEffect(() => {
        if (!selectedIdxs.length) {
            setMod(SelectFromCloudContentMod.tree);
        }
    }, [selectedIdxs.length, setMod]);

    if (!selectedIdxs.length) {
        return null;
    }

    return (
        <>
            <div className={styles.header}>
                <Text className={styles.title}>{`${selectedPlural(selectedIdxs.length)} ${selectedIdxs.length} ${filesPlural(
                    selectedIdxs.length
                )}`}</Text>
            </div>
            <div className={styles.datalist} ref={datalistRef}>
                {isLoaded && (
                    <DataList
                        storage={EStorageType.gallery}
                        goTo={noop}
                        viewMode={EViewMode.squares180}
                        isPopup
                        customScrollElement={datalistRef.current}
                        itemsList={selectedIdxs}
                        withinDialog
                        disableLoadOnScroll
                        albumShowSelected
                    />
                )}
            </div>
        </>
    );
};

ShowSelected.displayName = 'ShowSelected';
