import { PayloadAction } from '@reduxjs/toolkit';
import { focusOnCopiedFile } from 'reactApp/components/CreateCopyOfNoneditableFileModal/useCreateCopyOfFile.actions';
import { loadFolderFail, loadFolderSuccess } from 'reactApp/modules/home/home.actions';
import { HomeFile } from 'reactApp/modules/home/home.types';
import { resetSelect, scrollToHomeItemAction } from 'reactApp/modules/selections/selections.actions';
import { put, race, takeEvery } from 'redux-saga/effects';
import { take } from 'typed-redux-saga';

function* handleFocusOnCopiedFile(action: PayloadAction<{ file: HomeFile; home: string }>) {
    const { file, home } = action.payload;

    if (file.parent === '/') {
        yield put(resetSelect());
        yield put(scrollToHomeItemAction({ parentFolderId: file.parent, itemId: home }));
        return;
    }

    const { success } = yield race({
        success: take(loadFolderSuccess.toString()),
        fail: take(loadFolderFail.toString()),
    });

    if (success) {
        yield put(resetSelect());
        yield put(scrollToHomeItemAction({ parentFolderId: file.parent, itemId: home }));
    }
}

export function* watchCopyOfNoneEditableFile() {
    yield takeEvery(focusOnCopiedFile.toString(), handleFocusOnCopiedFile);
}
