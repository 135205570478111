import { ReactComponent as InfoIcon } from 'img/icons/mobile/info.svg';
import { ReactComponent as DownloadIcon } from 'img/icons/mobile/save.svg';
import React, { useRef, VFC } from 'react';
import { EActions, EStorageType } from 'reactApp/modules/storage/storage.types';
import { IArchiveItem } from 'reactApp/modules/viewer/viewer.types';
import { renderFileInfoModal } from 'reactApp/ui/Mobile/FileInfoModal/FileInfoModal.helpers';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { ItemInfo } from 'reactApp/ui/Mobile/OptionsModal/ItemInfo/ItemInfo';
import { OptionsBlock } from 'reactApp/ui/Mobile/OptionsModal/OptionsBlock/OptionsBlock';
import styles from 'reactApp/ui/Mobile/OptionsModal/OptionsModal.css';

interface IOptionsModalArchiveProps {
    item: IArchiveItem;
    storage: EStorageType;
    disableDownload?: boolean;

    onClose: () => void;
    handleDownload: () => void;
}

export const OptionsModalArchive: VFC<IOptionsModalArchiveProps> = ({
    item,
    onClose,
    handleDownload,
    storage,
    disableDownload = false,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    return (
        <MobileDialog id="mobile-archive-options-modal" onClose={onClose} closeOnDimmerClick mod="base" topmost scrollRef={ref}>
            <div className={styles.root}>
                {item && (
                    <div className={styles.itemInfo}>
                        <ItemInfo
                            isFolder={item.isFolder}
                            name={item.nameWithoutExt || ''}
                            size={item.size}
                            kind={item.kind}
                            storage={storage}
                            isVirus={false}
                            ext={item.ext}
                        />
                    </div>
                )}

                {!item.isEncrypted && (
                    <div className={styles.content} ref={ref}>
                        {!disableDownload && (
                            <div className={styles.option}>
                                <OptionsBlock
                                    id="actions"
                                    onClose={onClose}
                                    items={[
                                        {
                                            id: EActions.download,
                                            text: 'Скачать',
                                            icon: <DownloadIcon height={28} width={28} />,
                                            onClick: () => handleDownload(),
                                        },
                                    ]}
                                />
                            </div>
                        )}
                        <div className={styles.option}>
                            <OptionsBlock
                                id="actions"
                                onClose={onClose}
                                items={[
                                    {
                                        id: 'info',
                                        icon: <InfoIcon />,
                                        text: `Информация о ${item ? 'файле' : 'об архиве'}`,
                                        onClick: () =>
                                            renderFileInfoModal({
                                                id: item.path,
                                                storage: EStorageType.archive,
                                                isArchiveInfo: !item,
                                            }),
                                    },
                                ]}
                            />
                        </div>
                    </div>
                )}
            </div>
        </MobileDialog>
    );
};
