import { Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { ESplashScreenAction, sendSplashScreenAnalytics } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen.analytics';

import styles from './FamilyErrorDialog.css';
import { FamilyErrorData } from './FamilyErrorDialog.data';
import { FamilyErrorDialogProps } from './FamilyErrorDialog.types';

export const FamilyErrorDialog = memo<FamilyErrorDialogProps>(({ onClose, errorType, storage, isMobile }) => {
    const { title, text, successButtonText, cancelButtonText, onSuccess, onCancel } = FamilyErrorData[errorType];
    const currentStorage = useSelector(getCurrentStorage);

    const buttons = useMemo(() => {
        const buttons: EButtonType[] = [];
        if (successButtonText) {
            buttons.push(EButtonType.accept);
        }

        if (cancelButtonText) {
            buttons.push(EButtonType.cancel);
        }

        return buttons;
    }, [successButtonText, cancelButtonText]);

    const handleSuccess = useCallback(() => {
        onClose?.();
        onSuccess?.();
    }, [onClose, onSuccess]);

    const handleCancel = useCallback(() => {
        onClose?.();
        onCancel?.();
    }, [onClose, onCancel]);

    useEffect(() => {
        sendSplashScreenAnalytics({
            action: ESplashScreenAction.show,
            name: 'family_show_error',
            reason: errorType,
            storage: storage || currentStorage,
        });
    }, []);

    return (
        <BaseConfirmDialog
            dataQAId={errorType}
            renderHeader={() => (
                <Text
                    weight="2"
                    className={classNames(styles.title, {
                        [styles.title_mobile]: isMobile,
                    })}
                >
                    {title}
                </Text>
            )}
            renderContent={() => (
                <Text
                    weight="3"
                    className={classNames(styles.text, {
                        [styles.text_mobile]: isMobile,
                    })}
                >
                    {text}
                </Text>
            )}
            successButtonText={successButtonText}
            onSuccess={handleSuccess}
            cancelButtonText={cancelButtonText}
            onClose={handleCancel}
            size="tiny"
            buttons={buttons}
        />
    );
});

FamilyErrorDialog.displayName = 'FamilyErrorDialog';
