import { Icon20NotificationOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { ECloudNewFeaturesActions, sendCloudNewFeaturesAnalytics } from 'reactApp/ui/CloudNewFeatures/CloudNewFeatures.analytics';
import {
    getCloudFeatures,
    getLoadedCloudFeatures,
    updateLoadedCloudFeaturesList,
} from 'reactApp/ui/CloudNewFeatures/CloudNewFeatures.helpers';
import { ANIMATED_BELL_SESSION_ID, AnimatedBell } from 'reactApp/ui/CloudNewFeatures/FeaturesButton/AnimatedBell/AnimatedBell';

import styles from './FeaturesButton.css';

interface IProps {
    onClick?: () => void;
}

export const FeaturesButton = memo<IProps>(({ onClick }) => {
    const isPhone = useSelector(EnvironmentSelectors.isPhone);

    const features = getCloudFeatures();
    const loadedFeatures = getLoadedCloudFeatures();
    const hasNewFeatures = !features.every((item) => loadedFeatures.includes(item));

    const [showInformer, setShowInformer] = useState(hasNewFeatures);

    const email = useSelector(UserSelectors.getEmail);
    const isAlreadyAnimated = sessionStorage.getItem(`${email}|${ANIMATED_BELL_SESSION_ID}`);
    const [shouldAnimate, setShouldAnimate] = useState(!isPhone && !isAlreadyAnimated && hasNewFeatures);

    useEffect(() => {
        sendCloudNewFeaturesAnalytics({ action: ECloudNewFeaturesActions.show });
    }, []);

    const handleClick = useCallback(() => {
        if (hasNewFeatures) {
            updateLoadedCloudFeaturesList();
        }
        setShowInformer(false);
        setShouldAnimate(false);
        sendCloudNewFeaturesAnalytics({ action: ECloudNewFeaturesActions.click });
        onClick?.();
    }, [setShowInformer, setShouldAnimate, onClick, hasNewFeatures]);

    const handleStopAnimation = useCallback(() => {
        setShouldAnimate(false);
    }, [setShouldAnimate]);

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_mobile]: isPhone,
            })}
            onClick={handleClick}
            onMouseEnter={handleStopAnimation}
        >
            {shouldAnimate ? <AnimatedBell stopAnimation={handleStopAnimation} /> : <Icon20NotificationOutline />}
            {showInformer && !shouldAnimate && (
                <div className={styles.informer}>
                    <div className={styles.dot} />
                </div>
            )}
        </div>
    );
});

FeaturesButton.displayName = 'FeaturesButton';
