import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';

const SNACKBAR_CLOSE_TIMEOUT = 7000;
const TRANSITION_TIMEOUT = 150;

interface UseTimerArgs {
    id: string;
    closeTimeout?: number;
    disableCloseTimeout?: boolean;
    onClose?: () => void;
    closable?: boolean;
}
export const useTimer = ({
    id,
    closable,
    closeTimeout = SNACKBAR_CLOSE_TIMEOUT,
    disableCloseTimeout,
    onClose: customOnClose,
}: UseTimerArgs) => {
    const timerId = useRef<number | undefined>(undefined);
    const transitionId = useRef<number | undefined>(undefined);

    const dispatch = useDispatch();

    const [isVisible, setVisible] = useState<boolean>(false);

    const stopTimer = useCallback(() => {
        if (timerId.current) {
            clearTimeout(timerId.current);
        }
    }, [timerId]);

    const onClose = useCallback(() => {
        dispatch(hideSnackbarAction(id));
        customOnClose?.();
    }, [customOnClose, dispatch, id]);

    const handleClose = useCallback(() => {
        setVisible(false);
        stopTimer();

        transitionId.current = window.setTimeout(onClose, TRANSITION_TIMEOUT);
    }, [onClose, stopTimer]);

    const startTimer = useCallback(() => {
        if (closable) {
            stopTimer();
            timerId.current = window.setTimeout(handleClose, closeTimeout);
        }
    }, [closable, stopTimer, closeTimeout, handleClose]);

    useEffect(() => {
        if (!disableCloseTimeout) {
            startTimer();
        }

        setVisible(true);

        return () => {
            clearTimeout(transitionId.current);
            clearTimeout(timerId.current);
        };
    }, []);

    return { isVisible, handleClose };
};
