import { HIDE_ADS } from 'reactApp/appHelpers/configHelpers';
import { openDisableAdsPromo } from 'reactApp/components/DisableAdsPromo/DisableAdsPromo.helpers';
import { getFeatureABDisableAdsSnackbar } from 'reactApp/modules/features/features.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import opener from 'reactApp/utils/opener';
import { EPromoAction, sendSplashscreenAnalytics } from 'reactApp/utils/splashscreenAlertGa';
import { select } from 'redux-saga/effects';

const PROMO_NAME = 'disable-adv';

export const getAdvDisableLink = ({ isAnonymous }) => {
    return isAnonymous
        ? `/promo/?utm_source=cloud-subs-ads-up-buy-new&utm_medium=referral&utm_campaign=subs-ads-upline#year`
        : `/subscriptions/?utm_source=cloud-subs-ads-up-buy-new&utm_medium=referral&utm_campaign=subs-ads-upline#year`;
};

export function* initDisableAdsBanner() {
    const storage = yield select(getCurrentStorage);
    const isAnonymous = yield select(UserSelectors.isAnonymous);
    const isFeatureEnabled = yield select(getFeatureABDisableAdsSnackbar);

    if (storage !== EStorageType.public || HIDE_ADS || isFeatureEnabled !== 'b') {
        return;
    }

    const onClick = () => {
        sendSplashscreenAnalytics({
            action: EPromoAction.CLICK,
            name: PROMO_NAME,
            source: storage,
            name_button: PROMO_NAME,
        });

        opener(getAdvDisableLink({ isAnonymous }));
    };

    const onShow = () => {
        sendSplashscreenAnalytics({
            action: EPromoAction.SHOW,
            name: PROMO_NAME,
            source: storage,
        });
    };

    openDisableAdsPromo({ onClick, onShow });
}
