import { Method } from 'axios';
import { APICallV4 } from 'reactApp/api/APICall';

export interface UserFullQuotaAPIResponse {
    family_total: number;
    family_used: number;
    user_total: number;
    user_used: number;
    total: number;
    used: number;
    status?: string;
}

export class UserFullQuotaAPICall extends APICallV4<{ body: UserFullQuotaAPIResponse }> {
    _method = 'user/full_quota';
    _type: Method = 'get';
}
