import { Icon20Check } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { startSelecting } from 'reactApp/modules/selections/selections.actions';
import { setShowUploaderAction } from 'reactApp/modules/uploadList/uploadList.module';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { useYearFilter } from 'reactApp/ui/QuotaCleanerPage/hooks/useYearFilter';
import { noopVoid } from 'reactApp/utils/helpers';

import styles from './YearFilterModal.css';

export type Props = {
    onClose: () => void;
};

const yearFilterHeader = 'Фильтр по дате';
const UPLOAD_ID = 'upload';

export const YearFilterModal = memo<Props>(({ onClose }) => {
    const { list, setCurrentYear } = useYearFilter();
    const dispatch = useDispatch();

    const onItemClick = useCallback(
        (onClick = noopVoid, id: string) => {
            onClick();
            if (id !== UPLOAD_ID) {
                onClose();
            }
            dispatch(setShowUploaderAction(false));
            dispatch(startSelecting());
        },
        [onClose]
    );

    return (
        <MobileDialog id="mobile-year-filte-modal" onClose={onClose} closeOnDimmerClick mod="base" topmost disableScrolling={false}>
            <div className={styles.root}>
                <div className={styles.header}>{yearFilterHeader}</div>
                <div className={styles.options}>
                    {list.map((item) => (
                        <div
                            key={item.id}
                            className={classNames(styles.item, { [styles.item_selected]: item.isCurrent })}
                            onClick={() => onItemClick(setCurrentYear(item.year), item.id)}
                            data-qa-option={item.id}
                        >
                            <div className={classNames(styles.icon, { [styles.icon_disabled]: !item.isCurrent })}>
                                <Icon20Check />
                            </div>
                            <div className={styles.text}>{item.yearString}</div>
                            {item.sizeString && <div className={styles.rightItem}>{item.sizeString}</div>}
                        </div>
                    ))}
                </div>
            </div>
        </MobileDialog>
    );
});

YearFilterModal.displayName = 'YearFilterModal';
