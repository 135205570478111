import { logger } from 'lib/logger';
import { IS_PUBLIC_ALBUM, IS_PUBLIC_FOLDER, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { isViewContentOnly } from 'reactApp/appHelpers/settingsHelpers';
import { getFeatureAbDownloadAppPublic, getFeatureDownloadAppModalDetails } from 'reactApp/modules/features/features.selectors';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { forceShowDownloadAppButton } from 'reactApp/modules/public/public.actions';
import { getPublicItemById, getPublicRootWeblink } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { isReactMobilePublic } from 'reactApp/sections/MobilePublicPage/isReactMobilePublic';
import { store } from 'reactApp/store';
import { renderDownloadAppModal } from 'reactApp/ui/Mobile/DownloadAppModal/DownloadAppModal.helpers';
import { put } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';

export const DOWNLOAD_APP_PROMO_ID = 'downloadAppModalShown';

export function* initDownloadAppPromo() {
    const storage = yield* select(getCurrentStorage);
    if (storage !== EStorageType.public || IS_PUBLIC_ALBUM || isViewContentOnly) {
        return;
    }
    const { weblink } = yield* select(getPublicRootWeblink);
    const item = yield* select(getPublicItemById, weblink);

    if (item?.isFolder === false) {
        return;
    }

    const downloadAppAbFeature = yield select(getFeatureAbDownloadAppPublic);

    if (
        !IS_PUBLIC_FOLDER ||
        !isReactMobilePublic ||
        IS_WEBVIEW ||
        !downloadAppAbFeature ||
        !['b', 'c'].includes(downloadAppAbFeature) ||
        sessionStorage.getItem(DOWNLOAD_APP_PROMO_ID)
    ) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.downloadApp,
            promoId: DOWNLOAD_APP_PROMO_ID,
            onShow: () => store.dispatch(promoShown(EPromoType.downloadApp)),
            onClose: () => {
                store.dispatch(removePromo(EPromoType.downloadApp));
                sessionStorage.setItem(DOWNLOAD_APP_PROMO_ID, 'true');
            },
        })
    );
}

export function* showDownloadAppPromo() {
    const downloadAppPromo = yield* select(PromoSelectors.getPromo(EPromoType.downloadApp));

    if (!downloadAppPromo) {
        return;
    }

    try {
        downloadAppPromo.onShow();

        const downloadAppModalDetailsFeature = yield* select(getFeatureDownloadAppModalDetails);
        const { imgUrl, title, description, buttonText, buttonLink, noMtParameter } = downloadAppModalDetailsFeature;

        yield call(renderDownloadAppModal, {
            onClose: downloadAppPromo.onClose,
            data: {
                imgUrl,
                title,
                description,
                buttonText,
                buttonLink,
                noMtParameter,
            },
        });
    } catch (error) {
        downloadAppPromo.onClose();
        yield put(forceShowDownloadAppButton());
        logger.error(error);
    }
}
