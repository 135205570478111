import { ChangeEvent, Dispatch, KeyboardEvent, SetStateAction, useCallback, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { searchLoadRequestStart } from 'reactApp/modules/search/search.module';
import { SelectFromCloudContentMod } from 'reactApp/sections/AlbumsPage/SelectFromCloudPopup/components/Content/SelectFromCloudContent';

import { searchFileTypeOptions } from './SelectFromCloudSearch.data';
import { SearchAction, SearchActions, SearchOptionType, SearchState } from './SelectFromCloudSearch.types';

interface SelectFromCloudSearchProps {
    toggleSearch: (state: boolean) => void;
    isSearchExpanded: boolean;
    setMod: Dispatch<SetStateAction<SelectFromCloudContentMod>>;
}

const webSearchReducer = (state: SearchState, action: SearchAction): SearchState => {
    switch (action.type) {
        case SearchActions.close:
            return {
                active: false,
                focus: false,
                open: false,
                value: '',
                type: SearchOptionType.media,
            };
        case SearchActions.removeType:
            return {
                ...state,
                type: SearchOptionType.media,
                open: false,
                focus: false,
            };
        case SearchActions.setFocus:
            return {
                ...state,
                open: false,
                focus: Boolean(action.payload),
            };
        case SearchActions.setType:
            return {
                ...state,
                open: false,
                type: String(action.payload) as SearchOptionType,
            };
        case SearchActions.setActive:
            return {
                ...state,
                active: true,
                focus: true,
            };
        case SearchActions.setValue:
            return {
                ...state,
                open: false,
                value: String(action.payload),
            };
        case SearchActions.setOpenFilters:
            return {
                ...state,
                open: Boolean(action.payload),
            };
        case SearchActions.toggleFilters:
            return {
                ...state,
                focus: false,
                open: !state.open,
            };
        default:
            return state;
    }
};

export const useSelectFromCloudSearchHandlers = ({ toggleSearch, isSearchExpanded, setMod }: SelectFromCloudSearchProps) => {
    const [state, invokeDispatch] = useReducer(webSearchReducer, {
        active: false,
        focus: false,
        open: false,
        value: '',
        type: SearchOptionType.image,
    });

    const dispatch = ({ type, payload }: { type: SearchActions; payload?: any }) => {
        invokeDispatch({ type, payload });
    };

    const storeDispatch = useDispatch();

    const searchOption = searchFileTypeOptions?.[state.type];

    const onInputClick = useCallback(() => {
        if (isSearchExpanded) {
            dispatch({ type: SearchActions.setFocus, payload: true });
        } else {
            toggleSearch(true);
        }
    }, [isSearchExpanded, toggleSearch]);

    const onSearchClick = useCallback(() => {
        if (state.active) {
            dispatch({ type: SearchActions.setFocus, payload: false });
            storeDispatch(searchLoadRequestStart({ queryParam: state.value, type: state.type, isAlbumsSearch: true }));
            setMod(SelectFromCloudContentMod.search);
            return;
        }

        toggleSearch(true);
    }, [state.active, toggleSearch, state.value, state.type, storeDispatch, setMod]);

    const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: SearchActions.setValue, payload: event.target?.value });
    }, []);

    const onInputClear = useCallback(() => {
        if (state.value) {
            dispatch({ type: SearchActions.setValue, payload: '' });
            return;
        }

        dispatch({ type: SearchActions.removeType });

        toggleSearch(false);
        setMod(SelectFromCloudContentMod.tree);
    }, [state.value, dispatch, toggleSearch, setMod]);

    const onInputBlur = useCallback(() => {
        if (!state.open) {
            dispatch({ type: SearchActions.setFocus, payload: false });
        }
    }, [state.open]);

    const onClickAway = useCallback(() => {
        dispatch({ type: SearchActions.setFocus, payload: false });
        toggleSearch(false);
        setMod(SelectFromCloudContentMod.tree);
    }, [toggleSearch, setMod]);

    const setActiveInput = useCallback(() => {
        dispatch({ type: SearchActions.setActive });
    }, []);

    const chooseSearchType = useCallback(
        (type: string) => {
            if (searchFileTypeOptions[type]) {
                dispatch({ type: SearchActions.setType, payload: type });
                storeDispatch(searchLoadRequestStart({ queryParam: state.value, type, isAlbumsSearch: true }));
                setMod(SelectFromCloudContentMod.search);
                return;
            }

            dispatch({ type: SearchActions.setOpenFilters, payload: false });
        },
        [storeDispatch, state.value, setMod]
    );

    const removeSearchType = useCallback(() => {
        dispatch({ type: SearchActions.removeType });
        storeDispatch(searchLoadRequestStart({ queryParam: state.value, type: SearchOptionType.media, isAlbumsSearch: true }));
    }, [storeDispatch, state.value]);

    const onKeyUp = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                dispatch({ type: SearchActions.setFocus, payload: false });
                storeDispatch(searchLoadRequestStart({ queryParam: state.value, type: state.type, isAlbumsSearch: true }));
                setMod(SelectFromCloudContentMod.search);
            }
        },
        [state.type, state.value, storeDispatch, setMod]
    );

    const onToggleFilters = useCallback(() => {
        dispatch({ type: SearchActions.toggleFilters });
    }, []);

    useEffect(() => {
        if (!isSearchExpanded) {
            dispatch({ type: SearchActions.close });
        }
    }, [isSearchExpanded]);

    return {
        isOpen: state.open,
        value: state.value,
        searchOption,
        onSearchClick,
        onInputClick,
        onInputClear,
        onInputBlur,
        onClickAway,
        onInputChange,
        onKeyUp,
        onToggleFilters,
        setActiveInput,
        chooseSearchType,
        removeSearchType,
    };
};
