import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { SelectFromCloudContentMod } from 'reactApp/sections/AlbumsPage/SelectFromCloudPopup/components/Content/SelectFromCloudContent';
import { BreadcrumbItem } from 'reactApp/ui/Breadcrumbs/Breadcrumbs.types';
import { renameCameraUploadFolder } from 'reactApp/utils/tree.helpers';

export const getSelectFromCloudBreadcrumbsById = (id: string, mod: SelectFromCloudContentMod) => {
    if (mod === SelectFromCloudContentMod.search) {
        return [
            {
                text: 'Результаты поиска',
                id: ROOT_FOLDER_ID,
                kind: 'folder',
                storage: EStorageType.search,
                noLink: true,
                disableEdit: true,
            },
        ] as BreadcrumbItem[];
    }

    const items: BreadcrumbItem[] = [
        {
            text: 'Все файлы',
            id: ROOT_FOLDER_ID,
            kind: 'folder',
            storage: EStorageType.home,
            noLink: true,
            disableEdit: true,
        },
    ];

    const pathParts = id.split('/').filter(Boolean);

    pathParts.reduce((fullPath, path) => {
        const currentPath = `${fullPath}/${path}`;

        items.push({
            text: renameCameraUploadFolder({ name: path }),
            id: currentPath,
            kind: 'folder',
            storage: EStorageType.home,
            noLink: true,
            disableEdit: true,
        });

        return currentPath;
    }, '');

    return items;
};
