import { useSelector } from 'react-redux';
import { IS_DOCUMENTS_DOMAIN } from 'reactApp/appHelpers/configHelpers';
import { ALL_DOCUMENTS_DOMAIN_ROUTES } from 'reactApp/modules/allDocuments/allDocuments.constants';
import { getIntegrationQueryString } from 'reactApp/modules/integration/integration.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { ETreeRootIds } from 'reactApp/ui/TreeComponent/TreeComponent.constants';

export const useHref = (storage: EStorageType, useRouterLink: boolean, isBiz: boolean) => {
    const { isAllDocuments, isIntegration, isInlineIntegration } = getStorage(storage);
    const integrationQueryString = useSelector(getIntegrationQueryString);

    if (isBiz) {
        return '/';
    }

    if (!useRouterLink) {
        return '/';
    }

    if (isAllDocuments) {
        return IS_DOCUMENTS_DOMAIN ? ALL_DOCUMENTS_DOMAIN_ROUTES.document : ETreeRootIds.alldocumentsDocument;
    }
    if (isIntegration) {
        return `/${EStorageType.integration}${integrationQueryString}`;
    }
    if (isInlineIntegration) {
        return `/${EStorageType.inlineIntegration}${integrationQueryString}`;
    }

    return '/home';
};
