import React from 'react';
import { formatFileHistoryDate } from 'reactApp/utils/formatDate';

import styles from './DateHeader.css';
type DateHeaderProps = {
    text: string;
};
export const DateHeader: React.FC<DateHeaderProps> = ({ text }) => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    const isToday = formatFileHistoryDate(today.getTime()) === text;
    const isYesterday = formatFileHistoryDate(yesterday.getTime()) === text;
    return (
        <div className={styles.wrapper}>
            <span className={styles.header}>{text}</span>
            {isToday && <span className={styles.today}>сегодня</span>}
            {isYesterday && <span className={styles.today}>вчера</span>}
        </div>
    );
};
