import { Icon16Cancel } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useMemo } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import { EInformerColor, IProps } from 'reactApp/ui/Informer/Informer.types';

import styles from './Informer.css';

export const Informer = memo(
    ({ children, color, icon, closable = false, onClose, buttonText, onButtonClick, centered, title }: IProps): ReactElement => {
        const handleClose = useCallback(() => {
            if (onClose) {
                onClose();
            }
        }, [onClose]);

        const buttonTheme = useMemo(() => {
            if (color === EInformerColor.grey) {
                return 'octavius';
            }

            return 'white';
        }, [color]);

        const isPrimaryButton = useMemo(() => color === EInformerColor.grey, [color]);

        return (
            <div
                className={classNames({
                    [styles.root]: true,
                    [styles[`root_color_${color}`]]: color,
                    [styles.root_centered]: centered,
                })}
            >
                {!!icon && <div className={styles.icon}>{icon}</div>}
                <div className={styles.text}>
                    {title && <div className={styles.title}>{title}</div>}
                    <div>{children}</div>
                </div>
                <div className={styles.rightBlock}>
                    {Boolean(buttonText) && (
                        <div className={styles.buttonBlock}>
                            <Button theme={buttonTheme} onClick={onButtonClick} primary={isPrimaryButton}>
                                <span className={styles.buttonText}>{buttonText}</span>
                            </Button>
                        </div>
                    )}
                    {closable && (
                        <div className={styles.close} onClick={handleClose}>
                            <Icon16Cancel width={20} height={20} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

Informer.displayName = 'Informer';
