import addDays from 'date-fns/addDays';
import isAfter from 'date-fns/isAfter';
import store from 'lib/store';
import { USER_EMAIL } from 'reactApp/appHelpers/configHelpers';

function storeKey(key: string) {
    return `${USER_EMAIL}|${key}`;
}
function lastTimeStoreKey(key: string) {
    return `${USER_EMAIL}|${key}-time`;
}

function getValue(key: string) {
    return store.get(storeKey(key));
}

function setValue(key: string, value: any) {
    return store.set(storeKey(key), value);
}

function markAsShown(key: string, value?: number | string) {
    store.set(storeKey(key), value || true);
    store.set(lastTimeStoreKey(key), Date.now());
}

function getLastTime(key: string) {
    return getValue(key) ? store.get(lastTimeStoreKey(key)) : null;
}

function isPassed(key: string, { daysCount }: { daysCount: number }) {
    const lastTime = getLastTime(key);
    return lastTime ? isAfter(new Date(), addDays(lastTime, daysCount)) : false;
}

function clearStore(key: string) {
    store.remove(storeKey(key));
    store.remove(lastTimeStoreKey(key));
}

export const storeHelper = {
    getValue,
    setValue,
    markAsShown,
    getLastTime,
    isPassed,
    clearStore,
};
