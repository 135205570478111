import { defaultMillisecondsToLive, fetchFunctionType, INFINITE_CACHE_TIME, TCacheType } from './MemoryCache.models';

export class MemoryCacheItem {
    private fetchFunction: fetchFunctionType;
    private millisecondsToLive: TCacheType;
    private fetchDate: Date;
    private cache: any;
    private deferred: Promise<any> | null;

    public constructor(fetchFunction: fetchFunctionType, millisecondsToLive: TCacheType = defaultMillisecondsToLive) {
        this.millisecondsToLive = millisecondsToLive;
        this.fetchFunction = fetchFunction;
        this.fetchDate = new Date(0);
        this.cache = null;
        this.deferred = null;
    }

    private isCacheExpired = (): boolean => {
        if (this.millisecondsToLive === INFINITE_CACHE_TIME) {
            return false;
        }
        return this.fetchDate.getTime() + this.millisecondsToLive < new Date().getTime();
    };

    public getData = (): any => {
        if (!this.cache || this.isCacheExpired()) {
            if (this.deferred !== null) {
                return this.deferred;
            }
            try {
                this.deferred = this.fetchFunction()
                    .then((data: any): any => {
                        this.cache = data;
                        this.fetchDate = new Date();
                        return data;
                    })
                    .catch((error: any): any => Promise.reject({ error }))
                    .finally((): void => {
                        this.deferred = null;
                    });
                return this.deferred;
            } catch (error) {
                return Promise.reject(error);
            }
        } else {
            return Promise.resolve(this.cache);
        }
    };

    public resetCache = () => {
        this.fetchDate = new Date(0);
        this.cache = null;
    };
}
