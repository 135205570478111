import React, { ImgHTMLAttributes, memo, useCallback, useEffect, useRef, useState } from 'react';
import { emptyImageUrl } from 'reactApp/constants';
import { Spinner } from 'reactApp/ui/Spinner/Spinner';
import { noop } from 'reactApp/utils/helpers';

import styles from './ImageCancelable.css';

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
    id?: string;
    showLoader?: boolean;
}

export const preventDragHandler = (event) => {
    // For disable img dragging in FF
    event.preventDefault();
    return false;
};

export const ImageCancelable = memo(({ id, showLoader, onError = noop, onLoad = noop, src, ...restProps }: Props) => {
    const imgRef = useRef<HTMLImageElement | null>(null);
    const [isLoading, setIsLoading] = useState(Boolean(showLoader));

    useEffect(() => {
        return () => {
            if (!imgRef.current) {
                return;
            }
            imgRef.current.src = emptyImageUrl;
            imgRef.current.onload = function () {};
            imgRef.current.onerror = function () {};
            imgRef.current = null;
        };
    }, [id]);

    useEffect(() => {
        setIsLoading(Boolean(showLoader));
    }, [id, showLoader, setIsLoading]);

    const handleError = useCallback(
        (event) => {
            if (src) {
                setIsLoading(false);
                onError(event);
            }
        },
        [onError, setIsLoading, src]
    );

    const handleLoad = useCallback(
        (event) => {
            setIsLoading(false);
            onLoad(event);
        },
        [onLoad, setIsLoading]
    );

    return (
        <>
            {(!showLoader || Boolean(src)) && (
                <img
                    key={id}
                    ref={imgRef}
                    src={src}
                    onDragStart={preventDragHandler}
                    onMouseDown={preventDragHandler}
                    onError={handleError}
                    onLoad={handleLoad}
                    {...restProps}
                />
            )}
            {showLoader && isLoading && (
                <div className={styles.spinnerWrapper}>
                    <Spinner />
                </div>
            )}
        </>
    );
});

ImageCancelable.displayName = 'ImageCancelable';
