import { useEffect, useState } from 'react';
import { isUseO2Token } from 'reactApp/api/O2AuthClient';
import { o2AuthedSourceLoader } from 'reactApp/ui/ReactViewer/O2AuthedSourceLoader';

export const useO2AuthedImage = (src: string, ext: string) => {
    const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
    useEffect(() => {
        if (!isUseO2Token()) {
            return;
        }

        const authedBlob =
            ext === 'gif' ? o2AuthedSourceLoader.getAuthedSourceFromRedirect(src) : o2AuthedSourceLoader.getAuthedSource(src);
        authedBlob.then(o2AuthedSourceLoader.getBase64FromBlob).then(setImageSrc);
    }, [src, ext]);
    return imageSrc;
};
