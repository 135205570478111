import { captureException } from '@sentry/browser';
import escapeForHtml from 'lib/escapeForHtml';
import { UploadError } from 'reactApp/modules/uploading/errors/UploadError';

export class JavaScriptError extends UploadError {
    constructor(stack: Error, source: string, error: Error | DOMException | null) {
        super(stack, source);

        const details = this.getErrorDetails(error);

        this.message = this.message.replace('{DETAILS}', details);
        this.error = error;

        if (!this.stack && error?.stack) {
            this.stack = error;
        }

        captureException(this.error);
    }

    getErrorStack = (error) => {
        let stack = error.stack;

        if (stack) {
            const origin = location.origin;

            stack = stack.split(origin).join('').split('    at ').join('');
        }

        return stack;
    };

    getErrorCodeName = (error) => {
        let name;
        const code = error.code;
        const Constructor = error.constructor;

        if (code && Constructor) {
            name = Object.keys(Constructor).find(function (key) {
                // e.g. DOMException.NOT_FOUND_ERR or MediaError.MEDIA_ERR_DECODE
                const isCodeName = key.toUpperCase() === key;

                return isCodeName && Constructor[key] === code;
            });
        }

        if (!name && error.name !== 'Error') {
            // e.g. DOMException.name → "Error"
            name = error.name; // e.g. "NotFoundError" or "SecurityError"
        }

        return name;
    };

    getErrorType = (error) => {
        let type;
        let name;
        const Constructor = error.constructor;

        if (Constructor) {
            // Object.create(null).constructor → undefined
            const proto = Constructor.prototype;

            if (proto && window.Symbol && Symbol.toStringTag) {
                name = proto[Symbol.toStringTag]; // e.g. "TypeError", "DOMException" or "MediaError"
            }

            if (!name) {
                name = Constructor.name; // e.g. "TypeError", "DOMException" or "MediaError"
            }

            if (name !== 'Object') {
                type = name;
            }
        }

        if (!type) {
            type = Object.prototype.toString.call(error); // e.g. "[object MediaError]"
            type = type.slice(8, -1); // "[object MediaError]" → "MediaError"
        }

        return type;
    };

    getErrorDetails = (error) => {
        const type = this.getErrorType(error);
        const stack = this.getErrorStack(error);
        const codeName = this.getErrorCodeName(error);
        const codeNumber = error.code;
        const message = error.message;
        let details;

        if (stack) {
            details = '{STACK}';
        } else if (message && codeName) {
            details = '{TYPE}: {CODE_NAME} – {MESSAGE}';
        } else if (message && codeNumber) {
            details = '{TYPE}: code {CODE_NUMBER} – {MESSAGE}';
        } else if (codeName) {
            details = '{TYPE}: {CODE_NAME}';
        } else if (codeNumber) {
            details = '{TYPE}: code {CODE_NUMBER}';
        } else if (message) {
            details = '{TYPE}: {MESSAGE}';
        } else {
            details = '{TYPE}';
        }

        details = details.replace('{TYPE}', type);

        if (stack) {
            details = details.replace('{STACK}', escapeForHtml(stack));
        }

        if (message) {
            details = details.replace('{MESSAGE}', escapeForHtml(message));
        }

        if (codeName) {
            details = details.replace('{CODE_NAME}', codeName);
        }

        if (codeNumber) {
            details = details.replace('{CODE_NUMBER}', codeNumber);
        }

        return details;
    };

    radarName = 'error_{SOURCE}_js';
    message = 'Произошла JavaScript ошибка: <pre>{DETAILS}</pre>';
    error: Error | DOMException | null = null;

    toLogStringPattern = '[{TIME}] {ID} {SOURCE} {CLASS_NAME} { {META} }: {ERROR}';

    override toLogString = () => {
        return super.toLogString().replace('{ERROR}', String(this.error))
    };

    override getDetails = () => {
        const details = super.getDetails();

        details.error = String(this.error);

        return details;
    };
}
