import classNames from 'clsx';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { DropdownItem } from 'reactApp/ui/Dropdown/DropdownItem';

import styles from './Dropdown.css';

export class DropdownMenu extends PureComponent {
    static propTypes = {
        children: PropTypes.node,
        list: PropTypes.array,
        onClick: PropTypes.func,
        theme: PropTypes.oneOf(['', 'octavius']),
        className: PropTypes.string,
    };

    render() {
        const { list, onClick, theme, className } = this.props;

        return (
            <ul className={classNames({ [styles.menu]: true, [styles[`menu_${theme}`]]: !!theme, [className]: Boolean(className) })}>
                {list.map((item) => (
                    <DropdownItem
                        onClick={onClick}
                        theme={theme}
                        id={item.id}
                        text={item.text}
                        key={item.id}
                        icon={item.icon}
                        label={item.label}
                        check={item.check}
                        qaValue={item.qaValue}
                        isDanger={item.isDanger}
                        disabled={item.disabled}
                    />
                ))}
            </ul>
        );
    }
}
