import classNames from 'clsx';
import React, { memo, useCallback } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { DataListControl, EDatalistControlTheme } from 'reactApp/ui/DataListControl/DataListControl';
import { Hint } from 'reactApp/ui/Hint/Hint';

import styles from '../DataListGalleryItem.css';

export const Favorites = memo(({ isActive, onClick }: { isActive: boolean; onClick: () => void }) => {
    const handleOnFavorites = useCallback(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            e.nativeEvent.stopImmediatePropagation();

            onClick();
        },
        [onClick]
    );

    const handleDoubleClick = useCallback((e) => {
        e.stopPropagation();
    }, []);

    return (
        <Hint text={isActive ? 'Убрать из избранных' : 'Добавить в избранные'}>
            <div className={classNames(styles.control, styles.favorites)} onDoubleClick={handleDoubleClick}>
                <DataListControl
                    type="favorite"
                    active={isActive}
                    size={28}
                    onClick={handleOnFavorites}
                    theme={EDatalistControlTheme.dark}
                    viewType={EViewMode.gallery}
                />
            </div>
        </Hint>
    );
});

Favorites.displayName = 'Favorites';
