import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { UserFullQuotaAPIResponse } from 'reactApp/api/user/FullQuotaAPICall';

import { State } from './userAndFamilyQuota.types';

const initialState: State = {
    isLoading: false,
    isLoaded: false,
    quota: null,
    used: {
        total: null,
        family: null,
        user: null,
    },
};

export const loadUserQuotaWithFamilyRequest = createAction('userFullQuota/loadQuotaRequest');
export const loadUserQuotaWithFamilySuccess = createAction<UserFullQuotaAPIResponse>('userFullQuota/loadQuotaSuccess');

export const userQuotaWithFamilyReducer = createReducer(initialState, {
    [loadUserQuotaWithFamilyRequest.type]: (state) => {
        state.isLoading = true;
        state.isLoaded = false;
    },
    [loadUserQuotaWithFamilySuccess.type]: (state, action: PayloadAction<UserFullQuotaAPIResponse>) => {
        state.isLoading = false;
        state.isLoaded = true;

        const { total, user_used, used } = action.payload;

        const familyUsedWithoutUser = used - user_used;

        state.quota = bytesToNDigits(total, 3);
        state.used = {
            total: bytesToNDigits(used, 3),
            family: bytesToNDigits(familyUsedWithoutUser, 3),
            user: bytesToNDigits(user_used, 3),
        };
    },
});
