import { MouseEvent, useEffect, useRef, useState } from 'react';

interface IClickOptions {
    delay?: number;
    stopPropagation?: boolean;
    emitSingleClickOnDblClick?: boolean;
}

interface IAdditionalData {
    path?: string;
}

type TClickHandler<T extends IAdditionalData> = (event: MouseEvent, additionalData?: T) => void;

export function useSingleAndDoubleClick<T extends IAdditionalData>(
    actionSimpleClick: TClickHandler<T>,
    actionDoubleClick: TClickHandler<T>,
    options: IClickOptions = {
        delay: 250,
        stopPropagation: true,
        emitSingleClickOnDblClick: true,
    }
) {
    const [clickCount, setClickCount] = useState(0);
    const ref = useRef<MouseEvent | null>(null);
    const refData = useRef<T>();

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!options.emitSingleClickOnDblClick && clickCount === 1 && ref.current) {
                 actionSimpleClick(ref.current, refData.current);
            }
            setClickCount(0);
        }, options.delay);

        if (clickCount === 2 && ref.current) {
            actionDoubleClick(ref.current, refData.current);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [clickCount]);

    const handleSingleOrDblClick = (event, data: T) => {
        if (options.stopPropagation) {
            event.stopPropagation();
        }
        event.persist();
        ref.current = event;
        if (refData.current?.path !== data?.path) {
            setClickCount(1);
        } else {
            setClickCount((prev) => prev + 1);
        }
        refData.current = data;

        if (ref.current && options.emitSingleClickOnDblClick) {
            actionSimpleClick(ref.current, refData.current);
        }
    };

    return { handleSingleOrDblClick };
}
