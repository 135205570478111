import classNames from 'clsx';
import { ReactComponent as DoneIcon } from 'mrg-icons/src/mailru/status/done.svg';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { noop } from 'reactApp/utils/helpers';

import styles from './Dropdown.css';

export class DropdownItem extends PureComponent {
    static propTypes = {
        text: PropTypes.string,
        label: PropTypes.string,
        icon: PropTypes.node,
        id: PropTypes.string,
        qaValue: PropTypes.string,
        onClick: PropTypes.func,
        check: PropTypes.bool,
        isDanger: PropTypes.bool,
        disabled: PropTypes.bool,
    };

    handleOnClick = (e) => {
        const { onClick, id } = this.props;

        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        if (onClick) {
            onClick(id);
        }
    };

    render() {
        const { icon, text, label, theme, check, qaValue, isDanger, disabled = false } = this.props;

        return (
            <li
                onClick={disabled && noop || this.handleOnClick}
                className={classNames({
                    [styles.item]: true,
                    [styles.item_icon]: !!icon,
                    [styles[`item_icon_${theme}`]]: !!theme,
                    [styles[`item_${theme}`]]: !!theme,
                    [styles.item_danger]: !!isDanger,
                    [styles.item_disabled]: disabled,
                })}
                data-qa-value={qaValue}
            >
                {!!check && (
                    <div className={classNames(styles.icon, styles.check)}>
                        <DoneIcon />
                    </div>
                )}
                {!!icon && <div className={styles.icon}>{icon}</div>}
                <div className={styles.text}>{text}</div>
                {!!label && <div className={styles.label}>{label}</div>}
            </li>
        );
    }
}
