import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { createGaSender } from 'reactApp/utils/ga';

interface IProps {
    onSuccess: () => void;
    onClose: () => void;
}

const sendGa = createGaSender('unfreezed-dialog');

export const UnfreezedDialog = ({ onSuccess, onClose }: IProps): ReactElement => {
    const [loading, setLoading] = useState<boolean>(false);

    const handleOnSuccess = useCallback(() => {
        setLoading(true);
        sendGa('confirm');
        onSuccess();
        onClose();
    }, [onSuccess, onClose]);

    const renderHeader = useCallback(() => <div>Ваш аккаунт разморожен</div>, []);

    const renderContent = useCallback(() => <div>Вы можете продолжить пользоваться Облаком</div>, []);

    useEffect(() => sendGa('show'), []);

    return (
        <BaseConfirmDialog
            closable={false}
            renderHeader={renderHeader}
            renderContent={renderContent}
            successButtonText="Перейти в Облако"
            buttons={[EButtonType.accept]}
            loading={loading}
            onSuccess={handleOnSuccess}
            onClose={onClose}
        />
    );
};

UnfreezedDialog.displayName = 'UnfreezedDialog';
