import { useCallback, useEffect, useRef } from 'react';
import { ebookDesktopConfig, ebookTouchConfig } from 'reactApp/appHelpers/featuresHelpers';
import { ESources } from 'reactApp/hooks/useDownloadEbook';

export const useRenderTimeout = (source, isError, setIsError): (() => void) => {
    const renderTimeout = source === ESources.desktop ? ebookDesktopConfig?.timeout : ebookTouchConfig?.timeout;

    const timeoutId = useRef<ReturnType<typeof setTimeout>>();
    const _clearTimeout = useCallback(() => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }
        timeoutId.current = undefined;
    }, []);

    useEffect(() => {
        if (isError || !renderTimeout) {
            return;
        }

        timeoutId.current = setTimeout(() => {
            if (timeoutId.current) {
                setIsError(true);
            }
        }, renderTimeout * 1000);

        return () => _clearTimeout();
    }, [
        isError,
        renderTimeout,
        setIsError,
        /** _clearTimeout */
    ]);

    return _clearTimeout;
};
