import { createSelector } from '@reduxjs/toolkit';
import { ICreditCardState } from 'reactApp/modules/creditCard/creditCard.types';
import { RootState } from 'reactApp/store';
import { LoadingState } from 'reactApp/types/commonStates';

const getCard = (state: RootState): ICreditCardState => state.creditCard as ICreditCardState;

const getCardId = createSelector(getCard, (data) => data.card?.bindId);

const getLoadingState = createSelector(
    getCard,
    (state: ICreditCardState): LoadingState => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
    })
);

export const CardSelectors = {
    getCard,
    getCardId,
    getLoadingState,
};
