import React, { FC } from 'react';
import { EditorLLMDialogInput } from 'reactApp/ui/EditorLLM/EditorLLMDialog/components/Footer/components/EditorLLMDialogInput/EditorLLMDialogInput';
import { EditorLLMDialogSelect } from 'reactApp/ui/EditorLLM/EditorLLMDialog/components/Footer/components/EditorLLMDialogSelect/EditorLLMDialogSelect';

import styles from './EditorLLMDialogFooter.css';

export const EditorLLMDialogFooter: FC = () => (
    <div className={styles.footer}>
        <EditorLLMDialogSelect />
        <EditorLLMDialogInput />
    </div>
);
