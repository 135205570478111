import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

interface IParams {
    action: 'show' | 'view' | 'click';
    place: EStorageType;
    platform: 'touch' | 'desktop';
}

export const sendProtectedAnalytics = ({ action, ...rest }: IParams): void => {
    sendDwh({
        eventCategory: ECategoryGa.securityShield,
        action,
        dwhData: rest,
        sendImmediately: true,
    });
};
