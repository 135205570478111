import classNames from 'clsx';
import React, { memo, useCallback } from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from 'reactApp/ui/VKUIIcons';

import styles from './NavButtons.css';

interface Props {
    isStart: boolean;
    isLast: boolean;
    onPrev?: () => void;
    onNext?: () => void;
    updateNavState: () => void;
}

export const NavButtons = memo(({ isStart, isLast, onNext, onPrev, updateNavState }: Props) => {
    const handlePrev = useCallback(() => {
        onPrev?.();
        updateNavState();
    }, [onPrev, updateNavState]);

    const handleNext = useCallback(() => {
        onNext?.();
        updateNavState();
    }, [onNext, updateNavState]);

    return (
        <div className={styles.root}>
            <div
                className={classNames({
                    [styles.navButton]: true,
                    [styles.navButton_disabled]: isStart,
                })}
                onClick={handlePrev}
            >
                <ArrowLeftIcon />
            </div>
            <div
                className={classNames({
                    [styles.navButton]: true,
                    [styles.navButton_disabled]: isLast,
                })}
                onClick={handleNext}
            >
                <ArrowRightIcon />
            </div>
        </div>
    );
});

NavButtons.displayName = 'NavButtons';
