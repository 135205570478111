import { xray } from 'lib/xray';
import { ReactNode, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { sendDwh } from 'reactApp/utils/ga';

// https://docs.google.com/spreadsheets/d/1oxESW-0Ne7WtP7gKejV6CJYSv-Q9ssFDC8MY1_chDT0/edit#gid=1516018294

export enum ESplashScreenAction {
    show = 'show',
    click = 'click',
    close = 'close',
    preview = 'preview',
}

interface ISplashScreenAnalytics {
    action: ESplashScreenAction;
    name: string;
    storage?: EStorageType;
    button_name?: string | ReactNode;
    reason?: string;
}

export const sendSplashScreenAnalytics = ({ action, ...rest }: ISplashScreenAnalytics): void => {
    sendDwh({
        eventCategory: 'splashscreen-alert',
        action,
        dwhData: {
            ...rest,
            source: rest.storage,
            platform: IS_PHONE_BROWSER ? 'touch' : 'web',
            button_name: typeof rest.button_name === 'string' ? rest.button_name : '',
        },
        sendImmediately: true,
    });
};

type TUseSplashScreenAnalytics = Pick<ISplashScreenAnalytics, 'button_name' | 'name'>;

export const useSplashScreenAnalytics = ({ button_name, name }: TUseSplashScreenAnalytics) => {
    const storage = useSelector(getCurrentStorage);

    return useCallback(
        (action: ESplashScreenAction) => {
            xray.send(`splsh_${name}_${action}`);
            sendSplashScreenAnalytics({
                action,
                storage,
                name,
                button_name,
            });
        },
        [button_name, name, storage]
    );
};
