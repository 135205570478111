import { addDays, addMonths } from 'date-fns';
import formatFns from 'date-fns/format';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import getMonth from 'date-fns/getMonth';
import isBefore from 'date-fns/isBefore';
import isThisYear from 'date-fns/isThisYear';
import isToday from 'date-fns/isToday';
import ruLocale from 'date-fns/locale/ru';

// не сокращаем "мая, июня, июля"
const SHORT_MONTHS = { 4: true, 5: true, 6: true };

export const defaultDateFormat = 'dd.MM.yyyy';

export const getDateFormat = (date: number): string => (SHORT_MONTHS[getMonth(date)] ? 'dd MMMM' : 'dd MMM');

export const isBeforeDate = (date: number, dateToCompare = Date.now()) => isBefore(date, dateToCompare);

export const formatLocale = (date: number | Date, format: string) => formatFns(date, format, { locale: ruLocale });

export const formatDefaultDate = (date: number) => formatFns(date, defaultDateFormat);

export const formatDate = (date: number): string => {
    if (isToday(date)) {
        return formatFns(date, 'HH:mm');
    }

    return isThisYear(date) ? formatFns(date, getDateFormat(date), { locale: ruLocale }) : formatFns(date, defaultDateFormat);
};

export const formatExpires = (date: number): string => {
    if (isToday(date)) {
        return formatFns(date, 'HH:mm');
    }

    const dateFormat = isThisYear(date) ? 'd MMMM HH:mm' : 'd MMMM yyyy HH:mm';

    return formatFns(date, dateFormat, { locale: ruLocale });
};

export const formatExpiresDropdown = (date: number, short?: boolean): string => {
    let dateFormat = 'dd.MM.yy в HH:mm';

    if (isThisYear(date)) {
        dateFormat = short ? 'd MMM HH:mm' : 'd MMMM HH:mm';
    }

    return formatFns(date, dateFormat, { locale: ruLocale });
};

export const formatAutoDeleteExpires = (date: number, short?: boolean): string => {
    let dateFormat = short ? 'dd.MM.yy' : 'dd.MM.yy в HH:mm';

    if (isThisYear(date)) {
        dateFormat = short ? 'd MMM в HH:mm' : 'd MMMM в HH:mm';
    }
    return formatFns(date, dateFormat, { locale: ruLocale });
};

export const formatSubscriptionData = (date: number): string => {
    const dateFormat = isThisYear(date) ? 'd MMMM' : 'd MMM yyyy';

    return formatFns(date, dateFormat, { locale: ruLocale });
};

export const formatFileHistoryDate = (date: number): string => {
    const dateFormat = isThisYear(date) ? 'd MMMM' : 'd MMMM yyyy';

    return formatFns(date, dateFormat, { locale: ruLocale });
};

export const getAlbumName = () => {
    const albumDateFormat = 'dd MMMM yyyy';

    return formatFns(Date.now(), albumDateFormat, { locale: ruLocale });
};

export const formatFileInfoDate = (date: number) => formatFns(date, "dd MMMM yyyy' г.', HH:mm", { locale: ruLocale });

/**
 * Отображение в формате "дней", если дата в диапазоне месяца
 * @param {number} date - таймстемп
 * @returns {"day" | undefined} - вывод типа unit, либо day, либо undefined
 */
const unitType = (date: number): 'day' | undefined => {
    if (addDays(Date.now(), 1).valueOf() > date) {
        return undefined;
    }
    if (addMonths(Date.now(), 1).valueOf() < date) {
        return undefined;
    }
    return 'day';
};

export const formatExpiresLeftDropdown = (date: number): string => {
    return formatDistanceStrict(Date.now(), date, { locale: ruLocale, roundingMethod: 'floor', unit: unitType(date) });
};
