import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { ISmsSenderRequestData, ISmsSenderState } from 'reactApp/modules/smsSender/smsSender.types';

const initialState: ISmsSenderState = {
    isLoading: false,
    isLoaded: false,
    hasError: false,
    message: '',
};

export const sendLinkBySmsRequest = createAction<ISmsSenderRequestData>('sendLinkBySmsRequest/request');
export const sendLinkBySmsSuccess = createAction<string | undefined>('sendLinkBySmsRequest/success');
export const sendLinkBySmsError = createAction<string>('sendLinkBySmsRequest/error');

export const sendSmsReducer = createReducer(initialState, {
    [sendLinkBySmsRequest.type]: (state) => {
        state.isLoading = true;
        state.isLoaded = false;
        state.hasError = false;
    },
    [sendLinkBySmsSuccess.type]: (state, action: PayloadAction<string>) => {
        state.isLoading = false;
        state.isLoaded = true;
        state.message = action.payload ?? '';
    },
    [sendLinkBySmsError.type]: (state, action: PayloadAction<string>) => {
        state.isLoading = false;
        state.isLoaded = true;
        state.message = action.payload;
        state.hasError = true;
    },
});
