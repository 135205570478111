import { APIConfig } from 'reactApp/api/APIConfig';
import { getAxiosInstance } from 'reactApp/api/axiosFactory';

const axios = getAxiosInstance(3);

class TokensCSRFAPICall {
    _method = 'tokens/csrf';
    _type = 'post';

    makeRequest() {
        return this._getAxiosInstance()
            .request({
                method: this._type,
                url: this._method,
            })

            .then((response) => {
                APIConfig.set('csrf-token', response.data.token);
            });
    }

    _getAxiosInstance() {
        return axios;
    }
}

export { TokensCSRFAPICall };
