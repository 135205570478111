import { xray } from 'lib/xray';
import { myofficeBMetricConfig, myofficeBMetricProjectList } from 'reactApp/appHelpers/featuresHelpers';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { store as reduxStore } from 'reactApp/store';

/* https://jira.vk.team/browse/CLOUDWEB-15029 */
export enum MyOfficeBusinessMetric {
    mention = 'mention',
    openPartner = 'open_prtnr',
    start = 'start',
    request = 'request',
    loaded = 'loaded',
    error = 'error',
    sessionTime = 'session_t',
    sessionHit = 'session_h',
}

const getProjectMetricByRef = (referrer?: string): string => {
    const defaultProject = 'cloud';
    const maxLength = 7; // чтобы влезло в радар

    if (!referrer) {
        return defaultProject;
    }

    try {
        const { host } = new URL(referrer);
        if (!myofficeBMetricProjectList.includes(host)) {
            return defaultProject;
        }

        return host.replace(new RegExp('\\.', 'g'), '_').slice(0, maxLength);
    } catch (_) {
        return defaultProject;
    }
};

export const sendMyOfficeMetric = (
    name: MyOfficeBusinessMetric | string,
    data: { t?: Array<string | undefined>; i?: Record<string, number> } = {}
) => {
    if (!myofficeBMetricConfig[name]) {
        return;
    }

    const state = reduxStore.getState();
    const storage = getCurrentStorage(state);
    const isFirstClosed = ViewerSelectors.getIsFirstClosed(state);
    /**
     * Referrer сохраняется даже после закрытия просмотрщика,
     * хотя юзер дальше может пойти в другие разделы
     * Поэтому после первого закрытия referrer перестает учитываться
     */
    const referrer = isFirstClosed ? '' : document.referrer;
    let projectMetric = getProjectMetricByRef(referrer); // project на базе referrer

    // prject которые точно знаем и перетираем
    switch (storage) {
        case EStorageType.attaches:
        case EStorageType.viewerAttaches:
        case EStorageType.myofficeAttaches:
            projectMetric = 'mail';
            break;
        case EStorageType.embedded: {
            projectMetric = 'vk';
            break;
        }
        case EStorageType.myoffice: {
            projectMetric = 'cloud';
            break;
        }
    }

    const { t = [], i } = data;

    const base = 'myoffice';
    const prefix = t.filter((metric) => !!metric).join('_');
    let tMetric = `${base}_${name}_${projectMetric}`;
    if (prefix) {
        tMetric += `_${prefix}`;
    }

    const params: any = {};
    if (i) {
        params.i = Object.keys(i).reduce((acc, key) => {
            if (key) {
                acc[key] = i[key];
            }

            return acc;
        }, {});
    }

    xray.send(tMetric, params);
};
