export interface ICard {
    userId: string;
    payMethod: string;
    payerId: string;
    expDate?: string;
    added: string;
    bindId: string;
    number: string;
    isExpired: boolean;
    isSbp?: boolean;
}

export interface ICreditCardState {
    isLoaded: boolean;
    isLoading: boolean;
    hasError: boolean;
    card: ICard | null;
}

export interface IUpdateCardAction {
    isSilent?: boolean;
    showNotifications?: boolean;
    onSuccess?: () => void;
}

export interface IShowCardAction {
    isSilent?: boolean;
    showNotifications?: boolean;
    isMobile?: boolean;
    onSuccess?: () => void;
    onCancel?: () => void;
    onUnlinkCard?: () => void;
}

export interface IRemoveCardAction {
    onSuccess?: () => void;
}

export interface IApiCard {
    exp_date?: string;
    merchant_user_id: string;
    pay_method: string;
    payer_id: string;
    added: string;
    bind_id: string;
    description: string;
}

export interface IApiLoadResponse {
    card: IApiCard;
}

export enum ECardNotifications {
    updateSuccess = 'CARD_UPDATE_SUCCESS',
    updateFailure = 'CARD_UPDATE_FAILURE',
    removeSuccess = 'CARD_REMOVE_SUCCESS',
    removeFailure = 'CARD_REMOVE_FAILURE',
}
