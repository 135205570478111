import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { getNameById } from 'reactApp/modules/file/utils';
import { ApiIncomingResponse, IncomingState } from 'reactApp/modules/incoming/incoming.types';
import { mountSuccess, unmountSuccess } from 'reactApp/modules/modifying/modifying.actions';
import { MountActionSuccess, UnmountActionSuccess } from 'reactApp/modules/modifying/modifying.types';
import { ESortOder, ESortTypes } from 'reactApp/modules/sort/sort.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { renameCameraUploadFolder } from 'reactApp/utils/tree.helpers';

const initialState: IncomingState = {
    [ROOT_FOLDER_ID]: {
        id: ROOT_FOLDER_ID,
        name: 'С вами поделились',
        kind: 'storage',
        childs: [],
        isLoaded: false,
        isLoading: false,
        hasMoreToLoad: false,
        sort: {
            type: ESortTypes.name,
            order: ESortOder.asc,
        },
    },
    list: {},
};

export const loadIncomingStartRequest = createAction('incoming/load/requestStart');
export const loadIncomingRequest = createAction('incoming/load/request');
export const loadIncomingSuccess = createAction<ApiIncomingResponse>('incoming/load/success');
export const mountFolderSuccess = createAction<ApiIncomingResponse>('incoming/mountFolderSuccess');

export const incomingReducer = createReducer(initialState, {
    [loadIncomingRequest.type]: (state) => {
        state[ROOT_FOLDER_ID].isLoaded = false;
        state[ROOT_FOLDER_ID].isLoading = true;
    },
    [loadIncomingSuccess.type]: (state, action: PayloadAction<ApiIncomingResponse>) => {
        state[ROOT_FOLDER_ID].childs = [];
        state.list = {};
        state[ROOT_FOLDER_ID].isLoading = false;
        state[ROOT_FOLDER_ID].isLoaded = true;
        action.payload.list.forEach((item) => {
            const name = renameCameraUploadFolder(item);
            const tree = item.home || item.tree || ''; // In new digest api no more tree field
            const id = state.list[tree] ? `${tree}__collision__` : tree;

            state.list[id] = {
                __reduxTree: true,
                owner: {
                    name: item?.owner?.name || '',
                    email: item?.owner?.email || '',
                },
                id,
                name,
                isFolder: true,
                size: item.size,
                kind: item.invite_token ? 'invites' : 'mounted',
                parent: ROOT_FOLDER_ID,
                home: item.home,
                storage: EStorageType.sharedIncoming,
                access: item.access,
                isReadOnly: item.access === 'read_only',
                inviteToken: item.invite_token,
                tree: item.tree,
                rev: 0,
                isOwner: !item.invite_token,
            };
            state[ROOT_FOLDER_ID].childs.push(id);
        });
    },
    [mountSuccess.type]: (state, action: PayloadAction<MountActionSuccess>) => {
        const { id, home } = action.payload;
        const item = state.list[id];

        if (item && home) {
            state.list[home] = {
                ...item,
                kind: 'mounted',
                inviteToken: undefined,
                id: home,
                name: getNameById(home),
                home,
            };

            const idx = state[ROOT_FOLDER_ID].childs.findIndex((item) => item === id);
            state[ROOT_FOLDER_ID].childs[idx] = home;
        }
    },
    [unmountSuccess.type]: (state, action: PayloadAction<UnmountActionSuccess>) => {
        const item = state.list[action.payload.id];

        if (item) {
            state[ROOT_FOLDER_ID].childs = state[ROOT_FOLDER_ID].childs.filter((item) => item !== action.payload.id);
        }
    },
});
