import { FC, ReactElement } from 'react';

export interface Props {
    active: boolean;
    children: ReactElement;
    popup: {
        sequence: {
            name: string | number;
            data: any;
        }[];
    };
    name: string | number;
}

export const Popup: FC<Props> = ({ active, children, popup, name }) => {
    const isShown = Boolean(popup.sequence?.find((popup) => popup.name === name));

    return isShown || active ? children : null;
};
