import errorHandler from 'lib/errorHandler';
import { NotifyApplinkAPICall } from 'reactApp/api/NotifyApplinkAPICall';
import { sendLinkBySmsError, sendLinkBySmsRequest, sendLinkBySmsSuccess } from 'reactApp/modules/smsSender/smsSender.module';
import { normalizeSymbols } from 'reactApp/utils/helpers';
import { isValidPhone } from 'reactApp/utils/phoneHelpers';
import { Radar } from 'reactApp/utils/Radar';
import { call, cancel, put, takeEvery } from 'redux-saga/effects';

// @ts-ignore
const radar = new Radar({
    logFile: 'smsSender',
    groupName: 'smsSender',
});

const errors = {
    default: 'Произошла ошибка',
    arguments: 'Введите корректный номер телефона +x(xxx)xxx-xx-xx',
    'condition/try_again_later': 'Превышено количество попыток. Попробуйте позже',
};

function* handleSendLinkBySmsRequest(action) {
    try {
        const { phone, gaCategory = '' } = action.payload;

        if (!isValidPhone(phone)) {
            yield put(sendLinkBySmsError('Введите корректный номер телефона +x(xxx)xxx-xx-xx'));
            return;
        }

        if (gaCategory) {
            radar.setGroupName(gaCategory);
        }

        radar.addCounter('send-sms');
        radar.send();

        yield call(new NotifyApplinkAPICall().makeRequest, { phone });

        radar.addCounter('send-sms_success');
        radar.send();

        yield put(sendLinkBySmsSuccess('Сообщение отправлено на указанный вами номер'));
    } catch (error) {
        // @ts-ignore
        const errorProcessed = errorHandler.getErrorsFromResponses(error.response);

        const errorName = normalizeSymbols(errorProcessed[0]);

        radar.addCounter('sms-send_error');
        radar.addCounter(`sms-send_error_${errorName}`);
        radar.send();

        // @ts-ignore
        yield put(sendLinkBySmsError(errorHandler.getErrorMessage(errorProcessed, errors)));
        cancel();
    }
}

export function* watchSmsSender() {
    yield takeEvery(sendLinkBySmsRequest.toString(), handleSendLinkBySmsRequest);
}
