import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { ESubscriptionsTypes, IBaseSubscription } from 'reactApp/modules/subscriptions/subscriptions.types';
import { Subscription } from 'reactApp/ui/Mobile/SubscriptionsModal/SubscriptionsModal.types';

export const renderSubscriptionInfoModal = (data: { subscription: Subscription }) =>
    openPopupHelper({ popupName: popupNames.SUBSCRIPTION_INFO_MOBILE, data });

export const isBaseSubscription = (subscription: Subscription): subscription is IBaseSubscription => {
    return subscription.type === ESubscriptionsTypes.base;
};
