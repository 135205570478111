import { Button, ButtonProps, Headline } from '@vkontakte/vkui';
import React, { FC } from 'react';

export interface WebSearchResetFiltersButtonProps extends ButtonProps {}

export const WebSearchResetFiltersButton: FC<WebSearchResetFiltersButtonProps> = ({ children, ...props }) => (
    <Button hasHover={false} mode="tertiary" {...props}>
        <Headline weight="2">{children}</Headline>
    </Button>
);

WebSearchResetFiltersButton.displayName = 'WebSearchResetFiltersButton';
