/** tempexp_14729-full-file */
import { Spacing } from '@vkontakte/vkui';
import classNames from 'clsx';
import format from 'date-fns/format';
import ruLocale from 'date-fns/locale/ru';
import React, { FC, useMemo } from 'react';
import { formatAutoDeleteExpires } from 'reactApp/utils/formatDate';

import styles from './Date.css';

interface DateProps {
    className?: string;
    date?: number;
    expires?: number;
    isAutoDelete?: boolean;
}

export const Date: FC<DateProps> = ({ className, date, expires, isAutoDelete }) => {
    const isExpires = !!expires && isAutoDelete;

    const content = useMemo(() => {
        if (isExpires) {
            return `Удалится ${formatAutoDeleteExpires(expires, true)}`;
        }

        if (date) {
            return format(date, 'd MMM HH:mm', {
                locale: ruLocale,
            });
        }

        return null;
    }, [date, expires, isExpires]);

    if (!isExpires && !date) {
        return <Spacing size={14} />;
    }

    return (
        <div
            className={classNames(styles.root, className, {
                [styles.expires]: isExpires,
            })}
        >
            {content}
        </div>
    );
};
