import { useCallback, useState } from 'react';

export interface UseDisclosureProps {
    isOpen?: boolean;
    defaultIsOpen?: boolean;
    onClose?(): void;
    onOpen?(): void;
    id?: string;
}

export function useDisclosure(props: UseDisclosureProps = {}) {
    const { onClose: onCloseProp, onOpen: onOpenProp, isOpen: isOpenProp, defaultIsOpen } = props;
    const [isOpenState, setIsOpen] = useState(defaultIsOpen || false);

    const isOpen = isOpenProp !== undefined ? isOpenProp : isOpenState;

    const isControlled = isOpenProp !== undefined;

    const onClose = useCallback(() => {
        if (!isControlled) {
            setIsOpen(false);
        }
        onOpenProp?.();
    }, [isControlled, onOpenProp]);

    const onOpen = useCallback(() => {
        if (!isControlled) {
            setIsOpen(true);
        }
        onCloseProp?.();
    }, [isControlled, onCloseProp]);

    const onToggle = useCallback(() => {
        if (isOpen) {
            onClose();
        } else {
            onOpen();
        }
    }, [isOpen, onOpen, onClose]);

    return {
        isOpen,
        onOpen,
        onClose,
        onToggle,
        isControlled,
    };
}
