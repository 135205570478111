const DISPATCH_BATCH_TIME_MS = 250;

export class ActionsBatcher<T> {
    private prevItems: T[] = [];
    private startBatchPacketTime = 0;
    private batchDispatchTimerId;
    private readonly dispatchAggregateHelper: (data: T[]) => void;
    private readonly batchTimerMs;

    constructor(dispatchAggregateHelper, batchTimerMs = DISPATCH_BATCH_TIME_MS) {
        this.dispatchAggregateHelper = dispatchAggregateHelper;
        this.batchTimerMs = batchTimerMs;
    }

    private runBatchHelper = () => {
        if (this.batchDispatchTimerId) {
            clearTimeout(this.batchDispatchTimerId);
        }

        this.dispatchAggregateHelper(this.prevItems);

        this.prevItems = [];
        this.startBatchPacketTime = 0;
    };

    public push = (data: T) => {
        const now = Date.now();
        let runDispatched = false;

        if (this.startBatchPacketTime && now - this.startBatchPacketTime > this.batchTimerMs) {
            runDispatched = true;
        }

        if (!this.startBatchPacketTime) {
            this.startBatchPacketTime = Date.now();
            if (this.batchDispatchTimerId) {
                clearTimeout(this.batchDispatchTimerId);
            }
            this.batchDispatchTimerId = setTimeout(this.runBatchHelper, this.batchTimerMs);
        }

        if (runDispatched) {
            if (this.batchDispatchTimerId) {
                clearTimeout(this.batchDispatchTimerId);
            }
            this.batchDispatchTimerId = setTimeout(this.runBatchHelper, 0);
        }

        this.prevItems.push(data);
    };
}
