import { UploadError } from './UploadError';

export class UnknownError extends UploadError {
    constructor(stack: Error, source: string, error: string) {
        super(stack, source);

        const reason = error.replace(/[^\w]/g, '-');

        this.radarName = this.radarName.replace('{ERROR}', reason);
        this.message = this.message.replace('{ERROR}', error);
        this.error = error;
    }

    radarName = 'error_{SOURCE}_unknown-{ERROR}';
    message = 'Ошибка {ERROR}.';
    error = '';

    toLogStringPattern = '[{TIME}] {ID} {SOURCE} {CLASS_NAME} { {META} }: {ERROR}';

    toLogString = () => {
        const result = super.toLogString();

        return result.replace('{ERROR}', String(this.error));
    };

    getDetails = () => {
        const details = super.getDetails();

        details.error = String(this.error);

        return details;
    };
}
