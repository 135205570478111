import api from 'Cloud/Application/api';
import { logger } from 'lib/logger';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { mountRequest, unmountRequest } from 'reactApp/modules/modifying/modifying.actions';
import { historyPush } from 'reactApp/modules/router/router.module';
import { ETreeRootIds } from 'reactApp/ui/TreeComponent/TreeComponent.constants';
import { put, select, takeEvery } from 'redux-saga/effects';

import { loadIncomingRequest, loadIncomingStartRequest, loadIncomingSuccess } from './incoming.module';
import { getWaitingAccceptIncomingItemByInviteToken } from './incoming.selectors';

function* loadIncoming() {
    try {
        yield put(loadIncomingRequest());
        const { list } = yield Promise.resolve(api.folder.shared.incoming());
        yield put(
            loadIncomingSuccess({
                list: [...list],
            })
        );

        const { token, action } = yield select(getQueryParams);

        if (token && action) {
            const requestedFolder = yield select(getWaitingAccceptIncomingItemByInviteToken, token);
            yield put(historyPush({ id: ETreeRootIds.sharedIncoming }));

            if (action === 'accept' && requestedFolder) {
                yield put(mountRequest({ item: requestedFolder }));
            }

            if (action === 'reject' && requestedFolder) {
                yield put(unmountRequest({ item: requestedFolder }));
            }
        }
    } catch (error) {
        logger.error(error);
    }
}

export function* watchIncoming() {
    yield takeEvery(loadIncomingStartRequest.toString(), loadIncoming);
}
