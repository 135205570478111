import { clear } from 'idb-keyval';
import { uploadingService } from 'reactApp/modules/uploading/serviceClasses/UploadingService';
import { sendXray } from 'reactApp/utils/ga';

export function* handleClearUploadedQueue() {
    uploadingService.clearUploadedQueue();

    /**
     * При отмене всех загрузок сразу чистим Idb хранилище
     *
     * Точно такая же реаизация есть в попапе продолжение
     * загрузки в экшене @{clearContinueUploadFilesAction}
     */
    try {
        yield clear();
    } catch (error) {
        sendXray(['uploader', 'idb-keyval', 'clear', 'error']);
    }
}
