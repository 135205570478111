import classNames from 'clsx';
import React, { memo } from 'react';

import styles from './SubscriptionListItem.css';

type Props = {
    icon: string;
    title: string;
    description: string;
    type?: string;
    onClick?: () => void;
    showWaiting?: boolean;
    isDisable?: boolean;
};

export const SubscriptionItem = memo<Props>(({ icon, title, description, onClick, type = 'default', isDisable, showWaiting }) => (
    <div className={classNames(styles.root, { [styles[`root_${type}`]]: type, [styles.root_disable]: isDisable })} onClick={onClick}>
        <div className={styles.content}>
            <p className={styles.title}>{title}</p>
            <p className={classNames(styles.description, { [styles.waiting]: showWaiting })}>{description}</p>
        </div>
        <img src={icon} className={styles.icon} width={28} height={28} />
    </div>
));

SubscriptionItem.displayName = 'SubscriptionItem';
