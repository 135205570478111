import { IProps } from 'reactApp/components/SharingWindow/Sharing.types';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { createGaSender } from 'reactApp/utils/ga';

import { AB_FEATURE_NAME } from './Sharing.constants';

export const sendGa = createGaSender(AB_FEATURE_NAME);

export const renderSharingNew = (data: IProps) => openPopupHelper<IProps>({ popupName: popupNames.SHARING_DIALOG, data });
