import { EStoreType } from 'reactApp/ui/StoreButton/StoreButton';

export interface IAppCard {
    id: string;
    title: string;
    text: string;
    appStoreLink: string;
    googlePlayLink: string;
    ruStoreLink?: string;
    mobileType?: EStoreType;
}

interface ILink {
    href: string;
    title: string;
}

export const helpUrl = 'https://help.mail.ru/mail';
const feedbackUrl = 'https://help.mail.ru/cloud_web/size/trouble/quota';

export const appCardsList: IAppCard[] = [
    {
        id: 'mail',
        title: 'Почта',
        text: 'Отправляйте письма, читайте новости, делитесь файлами в новом суперприложении',
        appStoreLink: 'https://trk.mail.ru/c/u4t8i3',
        googlePlayLink: 'https://trk.mail.ru/c/kybd67',
    },
    {
        id: 'cloud',
        title: 'Облако',
        text: 'Персональное хранилище, где ваши файлы находятся в безопасности',
        appStoreLink: 'https://apps.apple.com/app/id696551382',
        googlePlayLink: 'https://play.google.com/store/apps/details?id=ru.mail.cloud&hl=ru&showAllReviews=true',
        ruStoreLink: 'https://apps.rustore.ru/app/ru.mail.cloud',
    },
];

export const linkList: ILink[] = [
    {
        href: feedbackUrl,
        title: 'Обратная связь',
    },
    {
        href: helpUrl,
        title: 'Помощь',
    },
];
