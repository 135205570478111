import { UploadFail } from 'reactApp/modules/uploading/fails/UploadFail';

export class LocalFileNotFoundFail extends UploadFail {
    static isNotFoundError(error) {
        let isNotFoundError = false;

        if (window.DOMException && error instanceof DOMException) {
            // Chrome
            isNotFoundError = error.code === DOMException.NOT_FOUND_ERR;
            // @ts-ignore
        } else if (window.FileError && error instanceof FileError) {
            // Safari
            // @ts-ignore
            isNotFoundError = error.code === FileError.NOT_FOUND_ERR;
        }

        return isNotFoundError;
    }

    radarName = 'fail_{SOURCE}_local-file-not-found';
    message = [
        'Файл больше недоступен по указанному пути в локальной файловой системе.',
        'Возможно вы извлекли съемный диск, переименовали или удалили файл.',
    ].join(' ');
}
