import { sendDwh } from 'reactApp/utils/ga';

import { ECategoryGa } from './paymentGa';

export enum ERequiredSignUpTouchAnalytics {
    SHOW_POPUP = 'authorization-or-installation',
    DOWNLOAD_APP_CLICK = 'necessarily-download-app',
    SIGNUP_CLICK = 'necessarily-authorization',
}

interface DWHOptions {
    type_public: 'folder' | 'file';
}

export const requiredSignUpTouchAnalytics = (action: ERequiredSignUpTouchAnalytics, options?: DWHOptions): void => {
    const dwhData = options;

    sendDwh({
        eventCategory: ECategoryGa.public,
        action,
        dwhData,
    });
};
