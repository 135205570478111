import { addDays, addHours, addMonths, addWeeks, addYears } from 'date-fns';
import {
    AccessStatus,
    AutoDeletePeriod,
    DomainAccess,
    ExpiryPeriod,
    IAutoDeleteOptions,
    IExpiresOptions,
} from 'reactApp/components/SharingWindow/Sharing.types';

export const ACCESS_FILE_RIGHTS_OPTIONS = {
    [AccessStatus.ReadOnly]: {
        id: AccessStatus.ReadOnly,
        text: 'Только смотреть и скачивать',
    },
    [AccessStatus.Edit]: {
        id: AccessStatus.Edit,
        text: 'Редактировать документ',
    },
};

export const ACCESS_FOLDER_RIGHTS_OPTIONS = {
    [AccessStatus.ReadOnly]: {
        id: AccessStatus.ReadOnly,
        text: 'Только смотреть и скачивать',
    },
    [AccessStatus.Upload]: {
        id: AccessStatus.Upload,
        text: 'Смотреть, скачивать и загружать',
    },
};

export const EXPIRES_OPTIONS: Record<string, IExpiresOptions> = {
    [ExpiryPeriod.Unlimited]: {
        id: ExpiryPeriod.Unlimited,
        text: 'Всегда',
        getValue: () => 0,
    },
    [ExpiryPeriod.Hour]: {
        id: ExpiryPeriod.Hour,
        text: 'Час',
        getValue: (): number => addHours(new Date(), 1).valueOf(),
    },
    [ExpiryPeriod.Day]: {
        id: ExpiryPeriod.Day,
        text: 'День',
        getValue: (): number => addDays(new Date(), 1).valueOf(),
    },
    [ExpiryPeriod.Week]: {
        id: ExpiryPeriod.Week,
        text: 'Неделя',
        getValue: (): number => addWeeks(new Date(), 1).valueOf(),
    },
    [ExpiryPeriod.Month]: {
        id: ExpiryPeriod.Month,
        text: 'Месяц',
        getValue: (): number => addMonths(new Date(), 1).valueOf(),
    },
    [ExpiryPeriod.Months3]: {
        id: ExpiryPeriod.Months3,
        text: '3 месяца',
        getValue: (): number => addMonths(new Date(), 3).valueOf(),
    },
    [ExpiryPeriod.HalfYear]: {
        id: ExpiryPeriod.HalfYear,
        text: 'Полгода',
        getValue: (): number => addMonths(new Date(), 6).valueOf(),
    },
    [ExpiryPeriod.Year]: {
        id: ExpiryPeriod.Year,
        text: 'Год',
        getValue: (): number => addYears(new Date(), 1).valueOf(),
    },
};

export const AUTODELETE_OPTIONS: Record<string, IAutoDeleteOptions> = {
    [AutoDeletePeriod.None]: {
        id: AutoDeletePeriod.None,
        text: 'Не удалять',
        getValue: () => 0,
    },
    [AutoDeletePeriod.Day]: {
        id: AutoDeletePeriod.Day,
        text: 'Удалить через день',
        getValue: (): number => addDays(new Date(), 1).valueOf(),
    },
    [AutoDeletePeriod.Week]: {
        id: AutoDeletePeriod.Week,
        text: 'Удалить через неделю',
        getValue: (): number => addWeeks(new Date(), 1).valueOf(),
    },
    [AutoDeletePeriod.Month]: {
        id: AutoDeletePeriod.Month,
        text: 'Удалить через месяц',
        getValue: (): number => addMonths(new Date(), 1).valueOf(),
    },
    [AutoDeletePeriod.Months3]: {
        id: AutoDeletePeriod.Months3,
        text: 'Удалить через 3 месяца',
        getValue: (): number => addMonths(new Date(), 3).valueOf(),
    },
    [AutoDeletePeriod.HalfYear]: {
        id: AutoDeletePeriod.HalfYear,
        text: 'Удалить через полгода',
        getValue: (): number => addMonths(new Date(), 6).valueOf(),
    },
    [AutoDeletePeriod.Year]: {
        id: AutoDeletePeriod.Year,
        text: 'Удалить через год',
        getValue: (): number => addYears(new Date(), 1).valueOf(),
    },
};

export const DOMAIN_ACCESS_OPTIONS = {
    [DomainAccess.EXTERNAL]: {
        id: DomainAccess.EXTERNAL,
        text: 'Для всех пользователей',
    },
    [DomainAccess.DOMESTIC]: {
        id: DomainAccess.DOMESTIC,
        text: 'Только для моих коллег',
    },
};

export enum QA_VALUE {
    accessItem = 'item_access',
    accessDropdown = 'dropdown_access',
    accessDropdownContent = 'dropdownContent_access',
    expiresItem = 'item_expires',
    expiresDropdown = 'dropdown_expires',
    expiresDropdownContent = 'dropdownContent_expires',
    domesticItem = 'item_domestic',
    domesticDropdown = 'dropdown_domestic',
    domesticDropdownContent = 'dropdownContent_domestic',
    autoDeleteItem = 'item_autoDelete',
    autoDeleteDropdown = 'dropdown_autoDelete',
    autoDeleteDropdownContent = 'dropdownContent_autoDelete',
}

export const AB_FEATURE_NAME = 'new-sharing';
export const AB_FEATURE_NAME_MODIFIED = 'sharing-modified';

export const TOOLTIP_OFFSET = { y: 4 };

export const getTitle = ({
    isPublic,
    isMounted,
    name,
    isAbNewSharingFolder,
}: {
    isPublic: boolean;
    isMounted: boolean;
    name: string;
    isAbNewSharingFolder?: boolean;
}): string => {
    // tempexp_15752-next-line
    if (isPublic) {
        return 'Совместный доступ';
    }

    if (isMounted) {
        return `Доступ к папке «${name}»`;
    }

    // tempexp_15752-start
    if (isAbNewSharingFolder && name) {
        return `Включить совместный доступ для`;
    }
    // tempexp_15752-end

    return 'Включить совместный доступ';
};
