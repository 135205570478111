export const copy = (text: string): Promise<{ hasBeenCopied: boolean | undefined }> => {
    try {
        const fakeElem = document.createElement('textarea');
        // Prevent zooming on iOS
        fakeElem.style.fontSize = '12pt';
        // Reset box model
        fakeElem.style.border = '0';
        fakeElem.style.padding = '0';
        fakeElem.style.margin = '0';
        // Move element out of screen horizontally
        fakeElem.style.position = 'absolute';
        fakeElem.style.left = '-9999px';
        // Move element to the same position vertically
        const yPosition = window.pageYOffset || document.documentElement.scrollTop;
        fakeElem.style.top = `${yPosition}px`;
        fakeElem.value = text;

        fakeElem.setAttribute('readonly', '');

        document.body.appendChild(fakeElem);
        fakeElem.select();
        fakeElem.setSelectionRange(0, fakeElem.value.length);

        // FF: https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
        // These commands can be used without any special permission if you are using them
        // in a short-lived event handler for a user action (for example, a click handler).
        // А у нас если еще нет паблик ссылки, дергается бэк апи и ссылка берется оттуда
        // - ФФ не даст выполнить эту команду в асинхронном then() и вернет false
        // error: document.execCommand(‘cut’/‘copy’) was denied because it was not called from inside a short running user-generated event handler.
        const hasBeenCopied = document.execCommand('copy');

        if (document.body.contains(fakeElem)) {
            document.body.removeChild(fakeElem);
        }

        return Promise.resolve({ hasBeenCopied });
    } catch (error) {
        return Promise.reject();
    }
};
