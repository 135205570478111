import React, { memo, useCallback } from 'react';
import { getItemNameWithoutExt } from 'reactApp/modules/file/utils';
import { CloudFile } from 'reactApp/modules/storage/storage.types';
import { Stub } from 'reactApp/ui/ReactViewer/Stub/Stub';
import { ViewerDoc } from 'reactApp/ui/ReactViewer/ViewerDoc/ViewerDoc';

import styles from './MobileViewerDoc.css';

export const MobileViewerDoc = memo<{ file: CloudFile }>(({ file }) => (
    <ViewerDoc
        file={file}
        isMobile
        renderError={useCallback(
            () => (
                <div className={styles.iconBlock}>
                    <Stub
                        ext={file?.ext || ''}
                        isVirus={false}
                        name={getItemNameWithoutExt(file?.name || '', file?.ext || '')}
                        kind="file"
                        mode="dark"
                        size="big"
                    />
                </div>
            ),
            [file?.ext, file?.name]
        )}
    />
));

MobileViewerDoc.displayName = 'MobileViewerDoc';
