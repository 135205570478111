import { MalwareStatus } from 'reactApp/modules/start/start.types';
import { IFileFlags, IFlags } from 'reactApp/types/Tree';

import { IFoldersV2, THomeItem } from './IFolderV2';
import { EAccountType } from './IUser';

interface ICount {
    folders: number;
    files: number;
}

export enum EPublicType {
    FOLDER = 'folder',
    FILE = 'file',
    ALBUM = 'album',
}

interface IApiFlags {
    is_gallery?: boolean;
}

interface IPublicInfo {
    type: EPublicType;
    path: string;
    name: string;
    id: string;
    flags: IFlags;
    ctime: number;
    count_downloads_left?: number;
    count_downloads_total?: number;
}

type Item = IFileItem | IFolderItem;

interface IFolderItem {
    count: ICount;
    name: string;
    path: string;
    weblink: string;
    size: number;
    rev: number;
    kind: 'folder';
    type: EPublicType.FOLDER;
    list: Item[];
    flags?: IApiFlags;
}

interface IFileItem {
    name: string;
    path: string;
    weblink: string;
    size: number;
    rev: number;
    kind: 'file';
    type: EPublicType.FILE;
    flags?: IFileFlags;
    malware: {
        status: MalwareStatus;
    };
    attr?: {
        actor: string;
    };
    ghostItemFromSSR?: boolean;
}

export interface IOwner {
    email: string;
    first_name: string;
    last_name: string;
    account_type?: EAccountType;
    upload_limit: number;
    nick?: string;
    user_flags?: {
        PAID_ACCOUNT?: boolean;
    };
}

export interface IPublicFolder extends IFolderItem {
    public: IPublicInfo;
    owner?: IOwner;

    error?: 'not_available';
}

export interface IPublicFile extends IFileItem {
    public: IPublicInfo;
    owner?: IOwner;
    home?: string;

    error?: 'not_available';
}

interface IPublicAlbumItem {
    album_id: string;
    element_id: string;
    time: number;
    type: string;
    name: string;
    size: number;
    ext: string;
    flags?: IFileFlags;
}

export interface IPublicAlbum {
    album_id: string;
    title: string;
    type: EPublicType.ALBUM;
    public?: IPublicInfo;
    owner?: IOwner;
    list: IPublicAlbumItem[];
    cursor: string;

    error?: string;
    weblink: string;
}

export type IPublicApiFolder = IPublicFile | IPublicFolder | IPublicAlbum;

export const isPublicAlbum = (file: IPublicApiFolder | THomeItem): file is IPublicAlbum =>
    file && (file as IPublicAlbum).type === EPublicType.ALBUM;

export const isPublicFile = (file: IFileItem | IFolderItem | IFolder): file is IPublicFile => {
    return file && 'type' in file && file.type === EPublicType.FILE;
};

export const isV2Folders = (folder: IFolder): folder is IFoldersV2 => {
    return typeof (folder as IFoldersV2)?.folder !== 'undefined';
};

export type IFolder = IPublicApiFolder | IFoldersV2;
