import classNames from 'clsx';
import { ReactComponent as MailIcon } from 'img/confirm-email/send-mail.svg';
import React, { PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { getEmail } from 'reactApp/modules/socialUser/socialUser.selectors';
import { Button } from 'reactApp/ui/Button/Button';
import { noop } from 'reactApp/utils/helpers';

import styles from './Step.css';

interface Props {
    email: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onButtonClick: Function;
}

const mapStateToProps = (state): { email: string } => ({
    email: getEmail(state),
});

export class FinalStepComponent extends PureComponent<Props> {
    public static defaultProps: Props = {
        onButtonClick: noop,
        email: '',
    };

    private handleClick = (): void => {
        this.props.onButtonClick();
    };

    public render(): ReactElement {
        return (
            <div className={classNames(styles.step, styles.finalStep)}>
                <div className={styles.icon}>
                    <MailIcon width={185} height={123} />
                </div>
                <div className={styles.title}>Письмо отправлено</div>
                <div className={styles.text}>
                    На адрес <span className={styles.email}>{this.props.email}</span> отправлено письмо.
                    <br />
                    Откройте его и нажмите кнопку, чтобы потвердить ваш email.
                </div>
                <div className={styles.control}>
                    <div>
                        <Button primary fluid size="big" theme="octavius" onClick={this.handleClick}>
                            <div className={styles.buttonText}>Перейти в Облако</div>
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export const FinalStep = connect(mapStateToProps)(FinalStepComponent);
