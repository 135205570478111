import React, { FC, useEffect, useRef } from 'react';
import { EditLLMDialogMessage } from 'reactApp/ui/EditorLLM/EditorLLMDialog/components/Messages/components/Message/EditLLMDialogMessage';
import { Messages } from 'reactApp/ui/EditorLLM/EditorLLMDialog/types/EditorLlmDialog.types';

import styles from './EditLLMDialogMessages.css';

interface Props {
    messages: Messages;
}

export const EditLLMDialogMessages: FC<Props> = ({ messages }) => {
    const refToLastMessage = useRef<HTMLDivElement>(null);

    useEffect(() => {
        refToLastMessage?.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    return (
        <div className={styles.messages} data-qa-id="LLMDialog_messages">
            {messages.map(({ message, isOwn, hasError }, index) => (
                <EditLLMDialogMessage
                    message={message}
                    isOwn={isOwn}
                    hasError={hasError}
                    key={`${message}_${index}`}
                    dataQaId={`LLMDialog_message_${index}`}
                />
            ))}
            <div ref={refToLastMessage} />
        </div>
    );
};
