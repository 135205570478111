import { Product } from 'reactApp/types/Billing';

export enum EBannerOfferFeature {
    DARK = 'banner_dark',
    LIGHT = 'banner_light',
}

export enum EBannerOfferTheme {
    DARK = 'dark',
    LIGHT = 'light',
}

export interface Props {
    onClose: () => void;
    onClick: () => void;
    onShow: () => void;
    productId: string;
    theme: EBannerOfferFeature;
    title: string;
    text: string;
    button?: string;
    icon: string;
    product: Product;
    id?: string;
}
