import classNames from 'clsx';
import PropTypes from 'prop-types';
import { range } from 'ramda';
import React, { PureComponent } from 'react';

import styles from './Pager.css';

export default class Pager extends PureComponent {
    static propTypes = {
        onStepClick: PropTypes.func,
        currentStepIdx: PropTypes.number,
        numberOfSteps: PropTypes.number,
    };

    static defaultProps = {
        onStepClick: (currentStepIdx, nextStepIdx) => true,
    };

    handleClick = event => this.props.onStepClick(this.props.currentStepIdx, Number(event.target.id));

    render() {
        const { currentStepIdx, numberOfSteps } = this.props;

        if (!numberOfSteps) {
            return null;
        }

        return (
            <div className={styles.root}>
                {range(0, numberOfSteps).map(idx => (
                    <div
                        key={idx}
                        id={idx}
                        className={classNames({
                            [styles.item]: true,
                            [styles.active]: idx === currentStepIdx,
                        })}
                        onClick={this.handleClick}
                    />
                ))}
            </div>
        );
    }
}
