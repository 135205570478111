import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IS_B2B_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { CAMERA_UPLOADS_FOLDER_ID, DEFAULT_FILES_HASHES, ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { normalizeBaseFeedItem } from 'reactApp/modules/feed/feed.helpers';
import { FeedItemApiResponse } from 'reactApp/modules/feed/feed.types';
import { isFolder, normalizeId } from 'reactApp/modules/file/utils';
import { GALLERY_B2B_IMAGES_EXTENSIONS, GALLERY_B2B_VIDEO_EXTENSIONS } from 'reactApp/modules/gallery/gallery.data';
import {
    ChangeGalleryCategoryAction,
    ChangeGalleryFolderAction,
    GalleryCategory,
    GalleryCategoryType,
    GalleryItem,
    GalleryLoadMoreSuccessAction,
    GalleryLoadRequestAction,
    GalleryLoadSuccessAction,
    GalleryState,
} from 'reactApp/modules/gallery/gallery.types';
import {
    addFilesSuccess,
    addToFavoritesSuccess,
    moveItemsSuccess,
    publishWeblink,
    removeFileSuccess,
    removeFromFavoritesSuccess,
    renameItemSuccess,
    resetWeblinkCountDownloads,
    toggleWeblinkAccessRights,
    toggleWeblinkDomestic,
    toggleWeblinkDownloadable,
    unPublishWeblink,
    updateWeblinkCountDownloads,
    updateWeblinkExpires,
} from 'reactApp/modules/modifying/modifying.actions';
import {
    AddFile,
    AddFileSuccessAction,
    EModifyReason,
    IAddToFavoritesSuccess,
    IRemoveFromFavoritesSuccess,
    IRenameItemSuccess,
    MoveItemsSuccessAction,
    RemoveFileSuccessAction,
    ResetWeblinkCountDownloads,
    ToggleWeblinkAccessRights,
    ToggleWeblinkDomestic,
    ToggleWeblinkDownloadable,
    UnpublishWeblinkAction,
    UpdateWeblinkCountDownloads,
    UpdateWeblinkExpires,
} from 'reactApp/modules/modifying/modifying.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { AccessRights } from 'reactApp/types/Tree';

const filterOutDefaultContentAdnFoldersItems = (item: FeedItemApiResponse) =>
    !isFolder(item) && !(item.hash && DEFAULT_FILES_HASHES.includes(item.hash));

// Проверяем что файл лежит строго в указанной папке, а не в подпапках.
const filterFilesInExactFolder = (item: FeedItemApiResponse, isExact = false, folder?: string) => {
    if (!isExact || !folder || folder === ROOT_FOLDER_ID) {
        return true;
    }

    return item.home === `${folder}/${item.name}`;
};

const categoryBaseProps = {
    childs: [],
    cursor: '',
    isLoaded: false,
    isLoading: false,
    hasMoreToLoad: false,
};

const assemble_B2C_categories = (): GalleryCategory[] => [
    {
        name: 'Все',
        id: GalleryCategoryType.all,
        value: GalleryCategoryType.all,
        ...categoryBaseProps,
    },
    {
        name: 'С телефона',
        id: GalleryCategoryType.mobile,
        value: GalleryCategoryType.mobile,
        ...categoryBaseProps,
    },
    {
        name: 'С компьютера',
        id: GalleryCategoryType.desktop,
        value: GalleryCategoryType.desktop,
        ...categoryBaseProps,
    },
];

const assemble_B2B_categories = (): GalleryCategory[] => [
    {
        name: 'Изображения',
        id: GalleryCategoryType.images,
        value: GalleryCategoryType.images,
        ...categoryBaseProps,
    },
    {
        name: 'Видео',
        id: GalleryCategoryType.videos,
        value: GalleryCategoryType.videos,
        ...categoryBaseProps,
    },
    {
        name: 'Другие',
        id: GalleryCategoryType.all,
        value: GalleryCategoryType.all,
        ...categoryBaseProps,
    },
];

const initialState: GalleryState = {
    categories: IS_B2B_BIZ_USER ? assemble_B2B_categories() : assemble_B2C_categories(),
    list: {},
    currentCategory: IS_B2B_BIZ_USER ? GalleryCategoryType.images : GalleryCategoryType.all,
    currentFolder: undefined,
};

export const galleryLoadRequest = createAction<undefined | GalleryLoadRequestAction>('gallery/loadRequest');
export const galleryLoadStart = createAction<GalleryLoadRequestAction>('gallery/loadStart');
export const galleryLoadSuccess = createAction<GalleryLoadSuccessAction>('gallery/loadSuccess');
export const changeGalleryCategory = createAction<ChangeGalleryCategoryAction>('gallery/changeCategory');
export const galleryLoadMoreSuccess = createAction<GalleryLoadMoreSuccessAction>('gallery/loadMoreSuccess');
export const galleryLoadMoreRequest = createAction<undefined | GalleryLoadRequestAction>('gallery/loadMoreRequest');
export const changeGalleryFolder = createAction<ChangeGalleryFolderAction>('gallery/changeFolder');
export const resetGallery = createAction('gallery/resetCurrentFolder');

export const galleryReducer = createReducer(initialState, {
    [galleryLoadStart.type]: (state, action: PayloadAction<GalleryLoadRequestAction>) => {
        const category = state.categories.find((category) => category.id === action.payload.category);

        if (category) {
            category.isLoaded = false;
            category.isLoading = true;
        }
    },
    [galleryLoadSuccess.type]: (state, action: PayloadAction<GalleryLoadSuccessAction>) => {
        const category = state.categories.find((category) => category.id === action.payload.category);
        const folder = action.payload?.folder;
        const isExactFolder = action.payload?.isExactFolder;

        if (!category) {
            return;
        }

        const {
            payload: { data },
        } = action;

        category.cursor = data.cursor || '';
        category.hasMoreToLoad = !!data.cursor;
        category.childs = [];
        if (data.objects) {
            data.objects
                .filter(filterOutDefaultContentAdnFoldersItems)
                .filter((item) => filterFilesInExactFolder(item, isExactFolder, folder))
                .forEach((item) => {
                    state.list[item.home] = { ...normalizeBaseFeedItem(item), storage: EStorageType.gallery };
                    category.childs.push(item.home);
                });
        }

        category.isLoaded = true;
        category.isLoading = false;
        state.currentCategory = action.payload.category;

        if (folder) {
            state.currentFolder = folder;
        }
    },
    [changeGalleryCategory.type]: (state, action: PayloadAction<ChangeGalleryCategoryAction>) => {
        state.currentCategory = action.payload.category;

        const category = state.categories.find((category) => category.id === action.payload.category);

        if (category) {
            category.cursor = '';
        }
    },
    [toggleWeblinkDomestic.type]: (state, action: PayloadAction<ToggleWeblinkDomestic>): void => {
        const { id, domestic } = action.payload;

        if (state.list[id]) {
            state.list[id].weblinkDomestic = domestic;
        }
    },
    [updateWeblinkExpires.type]: (state, action: PayloadAction<UpdateWeblinkExpires>): void => {
        const { id, expires } = action.payload;

        if (state.list[id]) {
            state.list[id].weblinkExpires = expires;
        }
    },
    [publishWeblink.type]: (state, action: PayloadAction<{ weblink: string; id: string }>) => {
        const { id, weblink } = action.payload;

        if (state.list[id]) {
            state.list[id].weblink = weblink;
        }
    },
    [toggleWeblinkAccessRights.type]: (state, action: PayloadAction<ToggleWeblinkAccessRights>): void => {
        const { id, type } = action.payload;
        if (state.list[id]) {
            state.list[id].weblinkAccessRights = type;
        }
    },
    [toggleWeblinkDownloadable.type]: (state, action: PayloadAction<ToggleWeblinkDownloadable>): void => {
        const { id, downloadable } = action.payload;

        if (state.list[id]) {
            state.list[id].weblinkDownloadable = downloadable;
        }
    },
    [updateWeblinkCountDownloads.type]: (state, action: PayloadAction<UpdateWeblinkCountDownloads>): void => {
        const { id, count_downloads } = action.payload;
        const item = state.list[id];
        if (item) {
            item.count_downloads_left = count_downloads;
            item.count_downloads_total = count_downloads;
        }
    },
    [resetWeblinkCountDownloads.type]: (state, action: PayloadAction<ResetWeblinkCountDownloads>): void => {
        const { id } = action.payload;

        if (state.list[id]) {
            state.list[id].count_downloads_total = undefined;
            state.list[id].count_downloads_left = undefined;
        }
    },
    [unPublishWeblink.type]: (state, action: PayloadAction<UnpublishWeblinkAction>) => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.list[id];

            if (!item) {
                return;
            }

            item.weblink = undefined;
            item.weblinkAccessRights = AccessRights.r;
            item.weblinkDomestic = false;
            item.weblinkExpires = 0;

            if (item.isFolder) {
                item.weblinkAutoDelete = false;
            }
        });
    },
    [removeFileSuccess.type]: (state, action: PayloadAction<RemoveFileSuccessAction>) => {
        const { ids, reason } = action.payload;

        if (reason === EModifyReason.move) {
            return;
        }

        const category = state.categories.find((category) => category.id === state.currentCategory);

        if (category) {
            category.childs = category.childs.filter((child) => !ids.includes(child));
        }
        ids.forEach((id) => delete state.list[id]);
    },
    [addFilesSuccess.type]: (state, action: PayloadAction<AddFileSuccessAction>) => {
        const { items, storage, reason } = action.payload;
        const category = state.categories.find((category) => category.id === state.currentCategory);

        if (reason === EModifyReason.move || !category || category?.id === GalleryCategoryType.mobile || storage !== EStorageType.gallery) {
            return;
        }

        const files = items as AddFile[];

        files.forEach((file) => {
            if (!file.home || file.kind === 'folder') {
                return;
            }

            const name = normalizeId(file.home).split('/').pop();
            const normalizedItem = normalizeBaseFeedItem({ ...file, name, favorite: false, home: file.home });

            if (
                (state.currentCategory === GalleryCategoryType.mobile && file.home.startsWith(CAMERA_UPLOADS_FOLDER_ID)) ||
                (state.currentCategory === GalleryCategoryType.desktop && !file.home.startsWith(CAMERA_UPLOADS_FOLDER_ID)) ||
                (state.currentCategory === GalleryCategoryType.images && GALLERY_B2B_IMAGES_EXTENSIONS.includes(normalizedItem.ext)) ||
                (state.currentCategory === GalleryCategoryType.videos && GALLERY_B2B_VIDEO_EXTENSIONS.includes(normalizedItem.ext)) ||
                state.currentCategory === GalleryCategoryType.all
            ) {
                state.list[file.home] = {
                    ...normalizedItem,
                    storage: EStorageType.gallery,
                };
                state.list[file.home].mtime = state.list[file.home].mtime / 1000;
                category.childs = [file.home, ...category.childs];
            }
        });
    },
    [moveItemsSuccess.type]: (state, action: PayloadAction<MoveItemsSuccessAction>) => {
        const { newItems, oldIds, storage } = action.payload;

        const category = state.categories.find((category) => category.id === state.currentCategory);

        if (!category || storage !== EStorageType.gallery) {
            return;
        }

        const isMobile = state.currentCategory === GalleryCategoryType.mobile;
        const isDesktop = state.currentCategory === GalleryCategoryType.desktop;
        const isAll = state.currentCategory === GalleryCategoryType.all;
        const isImages = state.currentCategory === GalleryCategoryType.images;
        const isVideos = state.currentCategory === GalleryCategoryType.videos;

        newItems.forEach((newItem, index) => {
            const file = newItem as GalleryItem;
            const oldItemId = oldIds[index];
            const oldItem = state.list[oldItemId];

            if (!file?.home || !oldItem) {
                return;
            }

            const cameraUploadsFolder = file.home.startsWith(CAMERA_UPLOADS_FOLDER_ID);
            const currentCategory =
                (isMobile && cameraUploadsFolder) || (isDesktop && !cameraUploadsFolder) || isImages || isVideos || isAll;

            if (!currentCategory) {
                delete state.list[oldItemId];
                category.childs = category.childs.filter((childId) => childId !== oldItemId);

                return;
            }

            state.list[file.home] = file;
            const oldIdx = category.childs.indexOf(oldItemId);

            if (oldIdx !== -1) {
                category.childs[oldIdx] = file.home;
                category.childs = [...category.childs];
            } else {
                category.childs = [file.home, ...category.childs];
            }
            delete state.list[oldItemId];
        });
    },
    [renameItemSuccess.type]: (state, action: PayloadAction<IRenameItemSuccess>) => {
        const { newItem, oldId } = action.payload;
        const file = newItem as GalleryItem;

        if (!file?.home || !oldId) {
            return;
        }

        const category = state.categories.find((category) => category.id === state.currentCategory);

        if (!category) {
            return;
        }

        state.list[file.home] = newItem as GalleryItem;
        delete state.list[oldId];

        category.childs = category.childs.filter((id) => id !== oldId);
        category.childs = [file.home, ...category.childs];
    },
    [galleryLoadMoreRequest.type]: (state, action: PayloadAction<undefined | GalleryLoadRequestAction>) => {
        const categoryId = action?.payload?.category || state.currentCategory;
        const category = state.categories.find((category) => category.id === categoryId);

        if (category) {
            category.isLoading = true;
            category.isLoaded = false;
        }
    },
    [galleryLoadMoreSuccess.type]: (state, action: PayloadAction<GalleryLoadMoreSuccessAction>) => {
        const currentCategory = action.payload.category || state.currentCategory;
        const category = state.categories.find((category) => category.id === currentCategory);

        const folder = action.payload?.folder;
        const isExactFolder = action.payload?.isExactFolder;

        if (!category) {
            return;
        }

        const {
            payload: { data },
        } = action;

        category.isLoading = false;
        category.isLoaded = true;

        category.cursor = data.cursor || '';
        category.hasMoreToLoad = !!data.cursor;
        if (data.objects) {
            data.objects
                .filter(filterOutDefaultContentAdnFoldersItems)
                .filter((item) => filterFilesInExactFolder(item, isExactFolder, folder))
                .forEach((item) => {
                    state.list[item.home] = { ...normalizeBaseFeedItem(item), storage: EStorageType.gallery };
                    category.childs.push(item.home);
                });
        }
    },
    [addToFavoritesSuccess.type]: (state, action: PayloadAction<IAddToFavoritesSuccess>): void => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.list[id];

            if (!item) {
                return;
            }

            item.isInFavorites = true;
        });
    },
    [removeFromFavoritesSuccess.type]: (state, action: PayloadAction<IRemoveFromFavoritesSuccess>): void => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.list[id];

            if (!item) {
                return;
            }

            item.isInFavorites = false;
        });
    },
    [resetGallery.type]: (state): void => {
        state.categories = IS_B2B_BIZ_USER ? assemble_B2B_categories() : assemble_B2C_categories();
        state.list = {};
        state.currentCategory = GalleryCategoryType.all;
        state.currentFolder = undefined;
    },
});
