import React, { Component } from 'react';
import { Portal } from 'react-portal';
import { BrowserRouter as Router } from 'react-router-dom';
import { IS_REACT_PAGE } from 'reactApp/appHelpers/configHelpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { Popup } from 'reactApp/ui/Popup/Popup';
import { PopupsNew } from 'reactApp/ui/Popup/PopupsNew';
import { SpacePromo } from 'reactApp/ui/SpacePromo/SpacePromo';
import { ReduxPopup } from 'redux-popup';

export class Popups extends Component {
    render() {
        return (
            <Router>
                <Portal>
                    <ReduxPopup name={popupNames.SPACE_PROMO} component={SpacePromo} modal={Popup} />
                    {!IS_REACT_PAGE && <PopupsNew /> /* На реакт страницах подключаем напрямую, без всяких BReactComponent */}
                </Portal>
            </Router>
        );
    }
}
