import dateTime from 'lib/dateTime';

export enum EUploadReasonSource {
    SOURCE_BACKEND = 'backend',
    SOURCE_WEB_BACKEND = 'web-backend',
    SOURCE_WEB_CLIENT = 'web-client',
}

export class UploadingReason {
    ERROR_WRONG_INSTANCE = 'UploadReason called on non-instance of UploadReason';
    ERROR_ABSTRACT_CLASS = 'UploadReason is abstract class';
    ERROR_NO_STACK = 'UploadReason requires stack';
    ERROR_WRONG_SOURCE = 'wrong UploadReason source';

    constructor(stack: Error, source: string) {
        if (!(this instanceof UploadingReason)) {
            // @ts-ignore
            throw new TypeError(this.ERROR_WRONG_INSTANCE);
        }

        if (this.constructor === UploadingReason) {
            throw new TypeError(this.ERROR_ABSTRACT_CLASS);
        }

        if (!(stack instanceof Error)) {
            throw new TypeError(this.ERROR_NO_STACK);
        }

        if (
            source !== EUploadReasonSource.SOURCE_BACKEND &&
            source !== EUploadReasonSource.SOURCE_WEB_BACKEND &&
            source !== EUploadReasonSource.SOURCE_WEB_CLIENT
        ) {
            throw new TypeError(this.ERROR_WRONG_SOURCE);
        }

        this.timeStamp = Date.now();
        this.stack = stack;
        this.source = source;
        this.radarName = this.radarName.replace('{SOURCE}', source);
    }

    className = 'UploadReason';

    radarName = 'reason_{SOURCE}_abstract';

    message = 'Не загружено.';

    /**
     * Источник ошибки: backend, web-backend, web-client.
     */
    source = 'abstract';

    timeStamp = -1;

    isRetryable = false;

    stack: Error;

    id = '';

    meta = {};

    timePattern = 'hh:ss:mm.SSS(z)';

    toStringPattern = '{MESSAGE}';

    toString = () => this.toStringPattern.replace('{MESSAGE}', this.message);

    /**
     * @param {string} id
     */
    setId = (id) => {
        this.id = id;
    };

    setMeta = (name: string, value: string) => {
        this.meta[name] = value;
    };

    _getMetaString = () => {
        return Object.entries(this.meta).reduce((acc, [key, value]) => `${acc}${key}=${value}, `, '');
    };

    toLogStringPattern = '[{TIME}] {ID} {SOURCE} {CLASS_NAME} { {META} }';

    public toLogString() {
        const time = dateTime.format(this.timeStamp, this.timePattern);
        return this.toLogStringPattern
               .replace('{TIME}', time)
               .replace('{ID}', this.id)
               .replace('{SOURCE}', this.source)
               .replace('{CLASS_NAME}', this.className)
               .replace('{META}', this._getMetaString());
    }

    public getDetails(): Record<any, any> {
        return {
            id: this.id,
            source: this.source,
            type: this.className,
            timeStamp: this.timeStamp,
            meta: this.meta,
            error: '',
        };
    }
}
