import classNames from 'clsx';
import React, { memo } from 'react';
import { Avatar } from 'reactApp/ui/Avatar/Avatar';

import styles from './AuthorsCounter.css';
import { useAuthors } from './hooks/useAuthors';

export const AuthorsCounter = memo(() => {
    const { counter, isOwn, renderedAuthors } = useAuthors();

    if (!isOwn) {
        return null;
    }

    return (
        <div className={styles.root}>
            {renderedAuthors.map((email) => (
                <div className={styles.item} key={email} data-qa-email={email}>
                    <Avatar size={32} email={email} />
                </div>
            ))}
            {Boolean(counter) && <div className={classNames(styles.counter, styles.item)}>{counter}</div>}
        </div>
    );
});

AuthorsCounter.displayName = 'AuthorsCounter';
