import { getErrorMsg, logger } from 'lib/logger';
import { useEffect } from 'react';
import { QuotaAPICall } from 'reactApp/api/QuotaAPICall';

export const useCheckFreeSpaceAfterVisibilityChange = ({
    totalUploadSize,
    maxUploadSize,
    uploadLimit,
    isCloudStock,
    canUploadCallback,
}: {
    totalUploadSize: number;
    isCloudStock: boolean;
    maxUploadSize: number;
    uploadLimit: number;
    canUploadCallback: () => void;
}) => {
    useEffect(() => {
        if (!isCloudStock) {
            return;
        }

        const onVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                new QuotaAPICall()
                    .makeRequest()
                    .then(({ data: { quota, used } }) => {
                        const freeSpace = quota - used.cloud;

                        const isEnoughSpace = totalUploadSize <= freeSpace;
                        const canUpload = maxUploadSize <= uploadLimit && isEnoughSpace;

                        if (canUpload) {
                            canUploadCallback();
                        }
                    })
                    .catch((error) => {
                        logger.error(getErrorMsg(error), error);
                    });
            }
        };
        document.addEventListener('visibilitychange', onVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', onVisibilityChange);
        };
    }, []);
};
