import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { loadDomainFoldersSuccess } from './domainFolders.actions';
import { ApiDomainFoldersResponse, DomainFoldersState } from './domainFolders.types';

const initialState: DomainFoldersState = {
    list: {},
};

export const domainFoldersReducer = createReducer(initialState, {
    [loadDomainFoldersSuccess.type]: (state, action: PayloadAction<ApiDomainFoldersResponse | undefined>) => {
        const domainFolders = action.payload;

        (domainFolders ?? []).forEach((folder) => {
            state.list[folder.home.toLowerCase()] = {
                __reduxTree: true,
                id: folder.home,
                name: folder.name,
                size: folder.size,
                mode: folder.mode,
                home: folder.home,
                quota: folder.quota,
                tree: folder.tree,
                icon: '',
                href: '',
                text: '',
            };
        });
    },
});
