import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { SPACE_SIZES } from 'reactApp/modules/products/products.helpers';
import { path, reduce } from 'ramda';
import { GiftBuy, GiftFetch } from 'reactApp/modules/gift/gift.constants';

const INITIAL_STATE = {
    isLoading: false,
    isLoaded: false,
    hasError: false,
    list: {},
    dmrUrl: null,
};

// Вы наверное спросите что это такое и почему это тут?
// Это цены на месяц за тарифы(не подарки)
// А тут они потому что, мы зачем-то должны выводить в подарках экономию, при покупке подарка за год, хотя месячное альтернативы и т.д нет
// А почему бы тогда не получить список продуктов, найти там месячный по квоте подарка и не взять оттуда цену?
// А потому что в списке подарков вам может прийти подарок на 4тб, а тариф на 4тб нет (ограничение квоты в 10тб и вот это вот все)
const MONTHS_PRICE = {
    68719476736: 75,
    137438953472: 149,
    274877906944: 229,
    549755813888: 379,
    1099511627776: 699,
    2199023255552: 1390,
    4398046511104: 2690,
};

export const giftReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GiftFetch.SUCCESS:
            return {
                ...state,
                list: reduce(
                    (acc, cur) => ({
                        ...acc,
                        [cur.gift_id]: {
                            id: cur.gift_id,
                            space: bytesToNDigits(cur.services.quota, 3),
                            price: cur.price,
                            available: cur.available,
                            oldPrice: MONTHS_PRICE[cur.services.quota] * 12,
                            flags: {
                                upload: !!path(['services', 'flags', 'UFLAG_PAID_UPLOAD'], cur),
                                paidFeatures: !!path(['services', 'flags', 'UFLAG_PAID_ACCOUNT'], cur),
                            },
                        },
                    }),
                    {},
                    action.list.filter(item => IS_PHONE_BROWSER || item.services.quota >= SPACE_SIZES._128)
                ),
            };
        case GiftBuy.SUCCESS:
            return {
                ...state,
                dmrUrl: action.url,
            };
        case GiftBuy.RESET:
            return {
                ...state,
                dmrUrl: null,
            };
        default:
            return state;
    }
};
