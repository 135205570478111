import { useSelector } from 'react-redux';
import { useIsWindowHeightLess } from 'reactApp/hooks/useIsWindowHeightLess';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';

export const useIsWindowIntegrationSmall = () => {
    const storage = useSelector(getCurrentStorage);
    const { isInlineIntegration } = getStorage(storage);

    const isWindowSmall = useIsWindowHeightLess();
    return isInlineIntegration && isWindowSmall;
};
