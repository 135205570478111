import { Snackbar } from 'reactApp/modules/snackbar/snackbar.constants';
import { SnackbarActionTypes, State } from 'reactApp/modules/snackbar/snackbar.types';

const INITIAL_STATE: State = {
    list: {},
    // Used for mobile because of dialogs
    bottomMargin: 0,
};

export const snackbarReducer = (state: State = INITIAL_STATE, action: SnackbarActionTypes): State => {
    switch (action.type) {
        case Snackbar.SHOW:
            return {
                ...state,
                list: {
                    ...state.list,
                    [action.payload.id]: action.payload,
                },
            };
        case Snackbar.HIDE: {
            const list = { ...state.list };
            delete list[action.payload];

            return {
                ...state,
                list,
            };
        }
        case Snackbar.SET_BOTTOM_MARGIN:
            return {
                ...state,
                bottomMargin: action.payload,
            };

        default:
            return state;
    }
};
