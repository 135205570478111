import { Spacing, Title } from '@vkontakte/vkui';
import errorImg from 'img/errors/error-504.png';
import React, { FC } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import { useCloseCleanerPage } from 'reactApp/ui/QuotaCleanerPage/hooks/useCloseCleanerPage';

import styles from './QuotaCleanerPlug.css';

export const QuotaCleanerPlug: FC = () => {
    const { onClose } = useCloseCleanerPage();

    return (
        <div className={styles.root}>
            <img src={errorImg} width={480} />
            <Spacing size={8} />
            <Title level="1" weight="2">
                Ничего не найдено
            </Title>
            <Spacing size={12} />
            <div className={styles.text}>
                Попробуйте обновить страницу
                <br />
                или вернитесь на главную
            </div>
            <Spacing size={24} />
            <Button theme="octavius" middle onClick={onClose}>
                <div className={styles.button}>Перейти на главную</div>
            </Button>
        </div>
    );
};
