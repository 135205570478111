/* eslint-disable complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines-per-function */
import classNames from 'clsx';
import React, { memo, ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SharingNewSection } from 'reactApp/components/SharingNewBiz/Section/SharingNewSection';
import { AutoDelete } from 'reactApp/components/SharingNewBiz/Weblink/AutoDelete/AutoDelete';
import { EFrom } from 'reactApp/components/SharingWindow/Sharing.types';
import { getFeaturePublicAutoDeletePromo, getFeaturePublicDomestic } from 'reactApp/modules/features/features.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { isAutoDeleteEnabled } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { CopyWeblink } from 'reactApp/ui/CopyWeblink/CopyWeblink';
import { getPublicUrl } from 'reactApp/utils/urlHelper';

import { AccessRightsDropdown } from '../AccessRightsDropdown/AccessRightsDropdown';
import AdditionalSettings from '../AdditionalSettings/AdditionalSettings';
import { DomesticDropdown } from '../DomesticDropdown/DomesticDropdown';
import styles from './SharingNewWeblink.css';
import { IProps } from './SharingNewWeblink.types';

export const SharingNewWeblink = memo(function SharingNewWeblink(props: IProps): ReactElement | null {
    const {
        item,
        from,
        isSharedOrMouted,
        gaSuffix,
        noExpiresControl = false,
        isPhone,
        isWeblinkOpened,
        setWeblinkOpened,
        onActionClick,
    } = props;

    const publicUrl = getPublicUrl(item);
    const storage = useSelector(getCurrentStorage);
    const isAlbum = from === EFrom.ALBUMS || storage === EStorageType.albums;

    const isBizUser = useSelector(UserSelectors.isBizUser);
    const isCorpUser = useSelector(UserSelectors.isCorpUser);
    const domain = useSelector(UserSelectors.getDomain);
    const isDomesticFeatureEnabled = useSelector(getFeaturePublicDomestic);
    const showDomesticSettings = !isAlbum && (isBizUser || isCorpUser) && Boolean(isDomesticFeatureEnabled);
    const isAutodeleteFeatureEnabled = Boolean(useSelector(isAutoDeleteEnabled));
    const isAutodeletePromoFeatureEnabled = Boolean(useSelector(getFeaturePublicAutoDeletePromo));
    const showExpires = !isAlbum && !noExpiresControl;
    const showAccessRights = !isAlbum;
    const isFolder = item?.isFolder;
    const isDownloadable = item?.weblinkDownloadable;
    const showAutoDeleteSettings =
        !isAlbum && isFolder && !isSharedOrMouted.isMounted && (isAutodeleteFeatureEnabled || isAutodeletePromoFeatureEnabled);

    useEffect(() => {
        setWeblinkOpened(Boolean(publicUrl));
    }, [publicUrl]);

    return (
        <SharingNewSection isOpened name="weblink" isPhone={isPhone}>
            <div className={classNames(styles.root, { [styles.root_phone]: isPhone })}>
                <div className={classNames(styles.innerContent, { [styles.short]: !isWeblinkOpened })}>
                    {isWeblinkOpened && (
                        <div className={styles.copyWeblink}>
                            <CopyWeblink
                                type="new"
                                publicUrl={publicUrl}
                                autoCopy={!isPhone}
                                isPhone={isPhone}
                                isFolder={isFolder}
                                isDownloadable={item?.weblinkDownloadable}
                                weblinkAccessRights={item?.weblinkAccessRights}
                                fileName={item?.name}
                                weblinkDomestic={item?.weblinkDomestic}
                            />
                        </div>
                    )}

                    {showAccessRights && isWeblinkOpened && <AccessRightsDropdown isSharedOrMouted={isSharedOrMouted} item={item} />}
                    {showDomesticSettings && (
                        <DomesticDropdown isWeblinkOpened={isWeblinkOpened} item={item} onActionClick={onActionClick} domain={domain} />
                    )}

                    <AdditionalSettings
                        item={item}
                        isWeblinkOpened={isWeblinkOpened}
                        showExpires={showExpires}
                        isDownloadable={isDownloadable}
                        isPhone={isPhone}
                    />
                    {showAutoDeleteSettings && (
                        <AutoDelete
                            item={item}
                            disabled={!isAutodeleteFeatureEnabled && isAutodeletePromoFeatureEnabled && isWeblinkOpened}
                            gaSuffix={gaSuffix}
                        />
                    )}
                </div>
            </div>
        </SharingNewSection>
    );
});
