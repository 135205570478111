import React from 'react';

import { DateHeader } from '../DateHeader/DateHeader';
import { Item } from '../Item/Item';
import { LogItem } from '../types';
import styles from './List.css';

type ListProps = {
    items: LogItem[];
};
export const List: React.FC<ListProps> = ({ items }) => {
    return (
        <div className={styles.wrapper}>
            {items.map((item) => {
                if (typeof item === 'string') {
                    return <Item id={item} key={item} />;
                }
                if (item.text) {
                    return <DateHeader text={item.text} key={item.text} />;
                }
                return null;
            })}
        </div>
    );
};
