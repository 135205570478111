export interface IProps {
    id: string;
    onClose: () => void;
    retry?: (() => void) | null;
    link?: string | null;
    onSuccess: () => void;
    source?: string;
}

export enum EBuyModalTheme {
    DARK = 'dark',
    LIGHT = 'light',
}

export const AB_FEATURE_NAME = 'buy-modal-trial-ab';
