import { editor } from 'Cloud/Application/Editor';
import { EditorID } from 'Cloud/Application/Editor/types';
import { isReadOnly, isSharedOrMountedStatus } from 'reactApp/appHelpers/appHelpers';
import { IS_FREE_BIZ_SAAS_USER, IS_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import { isOnPremR7Product } from 'reactApp/modules/features/features.helpers';
import { getOwner } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.selectors';
import { isDomainFolder } from 'reactApp/modules/storage/folder.helpers';
import { getItemFolder } from 'reactApp/modules/storage/storage.selectors';
import { CloudItem } from 'reactApp/modules/storage/storage.types';
import { store as reduxStore } from 'reactApp/store';

export const openEditor = (item: CloudItem, editorId = '') => editor?.openEditor(item, editorId);

export const isFileForOpenInR7Editor = (item) => {
    const state = reduxStore.getState();
    const folder = getItemFolder(state, item);
    const owner = getOwner(state);
    const isEditFileInSharedFolder =
        IS_FREE_BIZ_SAAS_USER &&
        !item.isFolder &&
        !isReadOnly(item.parent) &&
        isSharedOrMountedStatus(item.parent) &&
        !isDomainFolder(folder) &&
        owner?.is_paid;

    if (!isEditFileInSharedFolder && (IS_FREE_BIZ_SAAS_USER || IS_PUBLIC || isReadOnly(item) || item.isFile || item.isReadOnly)) {
        return false;
    }

    const editors = editor?.getEditorsFor(item);

    if (isOnPremR7Product) {
        // В OnPrem R7 работает через МойОфис на фронте
        return editors?.some((editor) => [EditorID.MYOFFICE].includes(editor.id));
    }

    return editors?.some((editor) => [EditorID.R7].includes(editor.id));
};
