import { APICallV3Post } from 'reactApp/api/APICall';

/**
 * Конвертирует документ в pdf

 * **Используется в аттачах**
 *
 * Принимает body формата 'application/octet-stream'
 * Возвращает данные в формате 'arraybuffer'
 */
export class PDFConvertAttachAPICall extends APICallV3Post<Buffer | null> {
    _method = 'ovidius/pdf/file';
    _responseType = 'arraybuffer' as const;
    _headers = { 'Content-Type': 'application/octet-stream' as const };
}
