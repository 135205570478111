import { getFeature } from 'Cloud/Application/FeaturesEs6';

interface FeatureOptions {
    folderId: string;
    files: string[];
}

const mailRecommend: FeatureOptions | undefined = getFeature('mail-recommend');

class ShowcaseController {
    public isFolderForShowcase = (folder): boolean =>
        !!mailRecommend &&
        (folder?.id || folder?.home)?.toLowerCase() === mailRecommend?.folderId?.toLowerCase() &&
        folder?.count?.files === 3;
}

export const showcaseController = new ShowcaseController();
