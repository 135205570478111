define(function(require, exports, module) {
	'use strict';

	var mock = require('Cloud/API/Mock');
	var mockTrashbin = require('Cloud/API/mocks/trashbin.v2.mock');
	var mockUser = require('Cloud/API/mocks/user.v2.mock');
	var mockBilling = require('Cloud/API/mocks/billing.v2.mock');
	var mockFolder = require('Cloud/API/mocks/folder.v2.mock');
	var mockDomain = require('Cloud/API/mocks/domain.v2.mock');
	var mockFileHistory = require('Cloud/API/mocks/fileHistory.v2.mock');
	var mockFile = require('Cloud/API/mocks/file.v2.mock');

	module.exports = function (userEmail) {
		return mock({
			url: '/api/v2/',
			email: userEmail,
			methods: Object.assign(
				{},
				mockTrashbin(),
				mockUser(userEmail),
				mockBilling(),
				mockFolder(),
				mockDomain(),
				mockFileHistory(),
				mockFile()
			)
		});
	};
});
