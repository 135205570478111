/* eslint-disable react/jsx-fragments */
import PropTypes from 'prop-types';
import { path } from 'ramda';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getGiftById } from 'reactApp/modules/gift/gift.selectors';
import { getGiftByCode } from 'reactApp/modules/purchasedGifts/purchasedGifts.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { Button } from 'reactApp/ui/Button/Button';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { PromoCodeDialog } from 'reactApp/ui/Promocode/PromoCodeDialog';
import {TariffIcon} from 'reactApp/ui/TariffIcon/TariffIcon';
import { CopyIcon } from 'reactApp/ui/VKUIIcons';
import { copy } from 'reactApp/utils/copyToClipboard';
import { formatFileHistoryDate } from 'reactApp/utils/formatDate';

import styles from './GiftPromoCodeModal.css';

const mapStateToProps = (state, props) => {
    const gift = getGiftByCode(state, props.code);
    const space = gift.space || path(['space'], getGiftById(state, gift.id)) || {};
    const expires = gift.expires;

    return {
        space,
        expires,
    };
};

const mapDispatchToProps = (dispatch) => ({
    showNotify: (options) => dispatch(showSnackbarAction(options)),
});

export const BASE_ACTIVATE_URL = `${window.location.protocol}//${window.location.host}/promocodes?promocode=`;

@connect(mapStateToProps, mapDispatchToProps)
export class GiftPromoCodeModal extends PureComponent {
    static propTypes = {
        code: PropTypes.string,
        space: PropTypes.object,
        onClose: PropTypes.func,
        showNotify: PropTypes.func,
        isSpecialOffer: PropTypes.bool,
        expires: PropTypes.number,
    };

    state = {
        showActivateModal: false,
    };

    renderFooter = () => (
        <div className={styles.footer}>
            <label htmlFor="link" className={styles.label}>
                Ссылка на активацию
            </label>
            <div className={styles.link}>
                <input
                    ref={this.handleLinkRef}
                    className={styles.linkInput}
                    id="link"
                    readOnly
                    value={`${BASE_ACTIVATE_URL}${this.props.code}&quota=${this.props.space.original}&mt_link_id=pvuin6`}
                />
                <Button size="big" onClick={this.handleOnCopyLink}>
                    <CopyIcon />
                </Button>
            </div>
            <div className={styles.footerDescription}>
                {this.props.isSpecialOffer ? (
                    'Вы можете активировать подарок сами или поделиться с вашими друзьями и близкими'
                ) : (
                    <span>Поделитесь ссылкой с&nbsp;получателем подарка, при переходе промокод автоматически активируется</span>
                )}
            </div>
        </div>
    );

    handleRef = (node) => {
        this.input = node;
    };

    handleLinkRef = (node) => {
        this.linkInput = node;
    };

    handleOnCopyClick = () => {
        if (this.input) {
            copy(this.input.value)
                .then(() => {
                    this.props.showNotify({
                        text: 'Текст успешно скопирован',
                        type: SnackbarTypes.success,
                        id: 'COPY_SUCCESS',
                        closable: true,
                    });
                })
                .catch(() => {
                    this.props.showNotify({
                        text: 'Не удалось скопировать текст',
                        type: SnackbarTypes.failure,
                        id: 'COPY_ERROR',
                        closable: true,
                    });
                });
        }
    };

    handleOnCopyLink = () => {
        if (this.linkInput) {
            copy(this.linkInput.value)
                .then(({ hasBeenCopied }) => {
                    if (!hasBeenCopied) {
                        return;
                    }

                    this.props.showNotify({
                        text: 'Ссылка успешно скопирована',
                        type: SnackbarTypes.success,
                        id: 'copyLink',
                        closable: true,
                    });
                })
                .catch(() => {
                    this.props.showNotify({
                        text: 'Не удалось скопировать ссылку',
                        type: SnackbarTypes.failure,
                        id: 'copyLink',
                        closable: true,
                    });
                });
        }
    };

    handleOnActivateClick = () => {
        this.setState({
            showActivateModal: true,
        });
    };

    handleOnCloseActivate = () => {
        if (this.state.activateSuccess) {
            this.props.onClose();

            return;
        }

        this.setState({
            showActivateModal: false,
        });
    };

    handleOnSuccessActivate = () => {
        this.setState({
            activateSuccess: true,
        });
    };

    render() {
        const { space, onClose, isSpecialOffer, code, expires } = this.props;
        const { showActivateModal } = this.state;

        return (
            <>
                {showActivateModal && (
                    <PromoCodeDialog code={code} onClose={this.handleOnCloseActivate} onSuccess={this.handleOnSuccessActivate} />
                )}
                <Dialog open size="small" footer={this.renderFooter()} onCancel={onClose}>
                    <div className={styles.root}>
                        <div className={styles.icon}>
                            <TariffIcon size={74} space={space.original} showGiftsIcons />
                        </div>
                        <div className={styles.title}>Ваш подарок</div>
                        <div className={styles.name}>
                            <strong>+{space.value}</strong> в Облаке
                        </div>
                    </div>
                    <div className={styles.description}>
                        {isSpecialOffer ? (
                            <Button primary onClick={this.handleOnActivateClick}>
                                Активировать подарок
                            </Button>
                        ) : (
                            <>
                                Подарок можно активировать{' '}
                               {expires ? <span className={styles.expires}>до {formatFileHistoryDate(expires)}</span> : <>в&nbsp;течение двух лет с&nbsp;момента покупки</>}
                            </>
                        )}
                    </div>
                </Dialog>
            </>
        );
    }
}
