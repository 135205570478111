import { Icon24SmileAddOutline } from '@vkontakte/icons';
import { Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { inviteToFamilyOrCreate } from 'reactApp/modules/family/family.actions';
import { FamilyAnalyticsCategory, FamilyAnalyticsPageActions, sendFamilyAnalytics } from 'reactApp/modules/family/family.analytics';

import styles from './MemberCard.css';

export const InviteCard = () => {
    const dispatch = useDispatch();

    const onInviteClick = useCallback(() => {
        sendFamilyAnalytics({
            eventCategory: FamilyAnalyticsCategory.pageOwner,
            action: FamilyAnalyticsPageActions.clickTabInvite,
        });

        dispatch(inviteToFamilyOrCreate());
    }, [dispatch]);

    useEffect(() => {
        sendFamilyAnalytics({
            eventCategory: FamilyAnalyticsCategory.pageOwner,
            action: FamilyAnalyticsPageActions.showTabInvite,
        });
    }, []);

    return (
        <div className={classNames(styles.item, styles.item_invite)} onClick={onInviteClick}>
            <Icon24SmileAddOutline />
            <Text weight="2">Пригласить</Text>
        </div>
    );
};
