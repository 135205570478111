import throttle from 'lodash.throttle';
import { useCallback, useRef, useState } from 'react';

const TIMEOUT_TO_INACTIVE = 6000;

export const useDimoutUITimer = () => {
    const timerId = useRef<number | undefined>(undefined);
    const [isInactive, setIsInactive] = useState(false);

    const runUITimer = useCallback(
        (makeActive = true) => {
            if (isInactive && makeActive) {
                setIsInactive(false);
            }

            if (timerId.current) {
                window.clearTimeout(timerId.current);
            }

            timerId.current = window.setTimeout(() => setIsInactive(true), TIMEOUT_TO_INACTIVE);
        },
        [isInactive, setIsInactive]
    );

    const showUIAndSetupTimer = throttle(() => {
        runUITimer();
    }, 250);

    return { isInactive, runUITimer, showUIAndSetupTimer };
};
