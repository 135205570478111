import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

export const getContent = (storage: EStorageType) => {
    const { isTrashBin, isGallery } = getStorage(storage);

    if (isTrashBin) {
        return {
            title: 'Корзина пуста',
            description: 'Вы всегда можете удалить файлы из корзины, чтобы освободить место.',
        };
    }

    if (isGallery) {
        return {
            title: 'Сейчас у вас нет фотографий',
        };
    }

    return {
        title: 'Пустая папка',
        description: 'В папке еще ничего нет, как только владелец папки загрузит файлы, вы их увидите.',
    };
};
