/* eslint-disable max-lines-per-function */
define(function (require, exports, module) {
    const config = require('Cloud/config');
    const settings = require('Cloud/settings');
    const browser = settings.browser || { userAgent: {}, os: {}, ua: {} };

    module.exports = {
        isRussianWindows() {
            if (this.isWindows()) {
                const n = typeof window !== 'undefined' && window.navigator;
                const lang = n.language || n.systemLanguage || n.userLanguage || n.browserLanguage;

                if (/^ru(-RU)?$/.test(lang)) {
                    return true;
                }
            }

            return false;
        },

        isIE() {
            return browser.userAgent.family === 'IE';
        },

        isEdge() {
            const match = String(browser.string).match(/Edge\/(\d{2,})/i);
            return match && (match[1] | 0) >= 12;
        },

        // OSX
        isSafari() {
            return browser.userAgent.family === 'Safari';
        },

        isChrome() {
            return browser.userAgent.family === 'Chrome';
        },

        isIosChrome() {
            return browser.string.includes('CriOS');
        },

        isMobileSafari() {
            return browser.userAgent.family === 'Mobile Safari';
        },

        // desktop
        isWebKit() {
            const string = browser.string;
            return !this.isMobile() && !this.isEdge() && (/ (apple)?webkit\//i.test(string) || / chrome\//i.test(string));
        },

        isVKTWebViewVersion() {
            return browser.string.includes('Chrome/87.0.4280.144');
        },

        // desktop
        isWindows() {
            return /Windows/i.test(browser.os.family) && !/Windows Phone/i.test(browser.os.family);
        },

        isMac() {
            return /Mac OS/i.test(browser.os.family);
        },

        isIOS() {
            return browser.os.family === 'iOS';
        },

        isOldIOS() {
            return browser.os.family === 'iOS' && browser.major < 8;
        },

        // phone or tablet
        isMobile() {
            return !!config.MOBILE_BROWSER;
        },

        isPhone() {
            return !!config.PHONE_BROWSER;
        },

        isTablet() {
            return !!config.TABLET_BROWSER;
        },

        isIpadOs() {
            return (
                typeof window !== 'undefined' &&
                window.navigator.platform === 'MacIntel' &&
                window.navigator.maxTouchPoints > 1 &&
                !window?.MSStream
            );
        },

        isIpad() {
            return browser.string?.includes('iPad');
        },

        isWebview: () => !!config.IS_WEBVIEW,

        getPlatform() {
            const PHONE_BROWSER = String(config.PHONE_BROWSER).toLowerCase();
            const TABLET_BROWSER = String(config.TABLET_BROWSER).toLowerCase();
            const MOBILE_BROWSER = String(config.MOBILE_BROWSER).toLowerCase();

            switch (true) {
                case this.isPhone():
                    return `phone-${PHONE_BROWSER}`;
                case this.isTablet():
                    return `tablet-${TABLET_BROWSER}`;
                case this.isMobile():
                    return `mobile-${MOBILE_BROWSER}`;
                default:
                    return 'desktop';
            }
        },

        isDesktopChrome() {
            return !!config.IS_DESKTOP_CHROME;
        },

        isDesktopYandex() {
            return !!config.IS_DESKTOP_YANDEX;
        },

        isHiDPI() {
            return window.devicePixelRatio >= 2;
        },

        getIOsVersion() {
            if (browser?.os?.family === 'iOS') {
                return browser?.os?.major || browser?.os?.version;
            }

            return false;
        },
    };
});
