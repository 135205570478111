import { AxiosResponse } from 'axios';
import { EditorID } from 'Cloud/Application/Editor/types';
import { WopiData, WopiProvider } from 'reactApp/api/wopi/WopiAPI.types';
import { WopiEditAPICall } from 'reactApp/api/wopi/WopiEditAPICall';
import { WopiEmbeddedViewAPICall, WopiViewAPICall } from 'reactApp/api/wopi/WopiViewAPICall';
import { IS_PUBLIC, IS_STOCK } from 'reactApp/appHelpers/configHelpers';

const PROVIDER_ID_MAP: Record<EditorID.R7 | EditorID.R7_WOPI | EditorID.MYOFFICE | EditorID.OFFICE, WopiProvider> = {
    [EditorID.R7]: WopiProvider.R7,
    [EditorID.R7_WOPI]: WopiProvider.R7_WOPI,
    [EditorID.MYOFFICE]: WopiProvider.MYOFFICE,
    [EditorID.OFFICE]: WopiProvider.OFFICE,
};

const getData = (itemPath: string): { stock: string } | { public: string } | { home: string } => {
    if (IS_STOCK) {
        return { stock: itemPath };
    }

    if (IS_PUBLIC) {
        return { public: `/${itemPath}` };
    }

    return { home: itemPath };
};

export const callWopiViewAPICall = (itemPath: string, editorId: EditorID): Promise<AxiosResponse<WopiData>> =>
    new WopiViewAPICall().makeRequest({ ...getData(itemPath), provider: PROVIDER_ID_MAP[editorId] });

export const callWopiEditAPICall = (itemPath: string, editorId: EditorID): Promise<AxiosResponse<WopiData>> =>
    new WopiEditAPICall().makeRequest({ ...getData(itemPath), provider: PROVIDER_ID_MAP[editorId] });

export const callWopiEmbeddedViewAPICall = (
    params: Record<'email' | 'name' | 'url', string>,
    editorId: EditorID
): Promise<AxiosResponse<WopiData>> => new WopiEmbeddedViewAPICall().makeRequest({ ...params, provider: PROVIDER_ID_MAP[editorId] });
