import classNames from 'clsx';
import React, { FC, ReactElement } from 'react';

import styles from './SubscriptionInfoBlock.css';

interface IProps {
    title: string;
    id: string;
}

export const SubscriptionInfoBlock: FC<IProps> = ({ title, id, children }): ReactElement => {
    return (
        <div
            className={classNames(styles.root, {
                [styles[`root_${id}`]]: id,
            })}
        >
            <div className={styles.title}>{title}</div>
            <div className={styles.info}>{children}</div>
        </div>
    );
};

SubscriptionInfoBlock.displayName = 'SubscriptionInfoBlock';
