import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IS_SOCIAL_USER } from 'reactApp/appHelpers/configHelpers';
import { CardActions } from 'reactApp/modules/creditCard/creditCard.module';
import { loadFeatures } from 'reactApp/modules/features/features.thunkActions';
import { loadGiftsAction } from 'reactApp/modules/gift/gift.actions';
import { loadReceivedGiftsAction } from 'reactApp/modules/giftReceived/giftReceived.actions';
import { initProducts } from 'reactApp/modules/products/products.module';
import { initPromoTariffs } from 'reactApp/modules/promoTariffs/promoTariffs.module';
import { loadPurchasedGiftsAction } from 'reactApp/modules/purchasedGifts/purchasedGifts.actions';
import { loadSocialUser } from 'reactApp/modules/socialUser/actions/loadSocialUser.action';
import { getSubscriptionsRequest, startSubscriptionsPageRequest } from 'reactApp/modules/subscriptions/subscriptions.module';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

export const useOnPageLoad = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        // вынести в старт сагу в тач хомяке или лучше в инит сагу
        if (IS_SOCIAL_USER) {
            dispatch(loadSocialUser());
        }
        dispatch(startSubscriptionsPageRequest());
        dispatch(CardActions.loadCard());
        dispatch(loadFeatures());
        dispatch(loadGiftsAction());
        dispatch(loadReceivedGiftsAction());
        dispatch(loadPurchasedGiftsAction());
        dispatch(initProducts());
        dispatch(initPromoTariffs());
        dispatch(getSubscriptionsRequest());

        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'touch-subs' });
        sendPaymentGa({ eventCategory: ECategoryGa.entered, action: 'page-entered', source: 'subs' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
