import { plural } from 'lib/plural';

const names = {
    day: ['день', 'дня', 'дней'],
    hour: ['час', 'часа', 'часов'],
    minute: ['минута', 'минуты', 'минут'],
    second: ['секунда', 'секунды', 'секунд'],
};

const namesGenetive = {
    day: ['день', 'дня', 'дней'],
    hour: ['час', 'часа', 'часов'],
    minute: ['минуту', 'минуты', 'минут'],
    second: ['секунду', 'секунды', 'секунд'],
};

const namesShort = {
    day: 'д.',
    hour: 'ч.',
    minute: 'мин.',
    second: 'сек.',
};

export const formatDuration = (seconds: number, shortNames = false, genetive = false) => {
    const time = {
        day: Math.floor(seconds / 86400),
        hour: Math.floor(seconds / 3600) % 24,
        minute: Math.floor(seconds / 60) % 60,
        second: Math.floor(seconds) % 60,
    };
    const formatKeyName = (key, value) => {
        if (shortNames && seconds >= 60) {
            return namesShort[key];
        }

        return (genetive ? namesGenetive : names)[key][plural(value)];
    };

    // Для краткой версии показываем сокращенные имена и максимум 2 бОльших значения.
    // То есть, если есть часы, то не показываем секунды, а только часы и минуты
    return Object.entries(time)
        .filter((value) => value[1] !== 0)
        .filter((_, index) => !shortNames || index < 2)
        .map(([key, value]) => `${value} ${formatKeyName(key, value)}`)
        .join(' ');
};
