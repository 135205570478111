import React from 'react';
import { isFamilySubsInTariff, isOnlyWebAdInTariffs } from 'reactApp/appHelpers/featuresHelpers';
import { Product } from 'reactApp/types/Billing';
import { getPeriodName, isMonthPeriod } from 'reactApp/utils/Period';
import { formatPrice, getMonthProductPrice } from 'reactApp/utils/priceHelpers';

export const getButtonText = (price: number, period: string, isFullText: boolean, product: Product, priceByMonth?: boolean): string => {
    if (product.hasTrial && product.trialPeriod) {
        return `${isMonthPeriod(product.trialPeriod) ? 'Месяц' : 'Год'} бесплатно`;
    }

    if (priceByMonth) {
        return `${formatPrice(getMonthProductPrice(product))} ₽ в месяц`;
    }

    if (isFullText) {
        return `Купить за ${formatPrice(price, 'ru')} ₽ в ${getPeriodName(period)}`;
    }

    return `${formatPrice(price, 'ru')} ₽ в ${getPeriodName(period)}`;
};

export const getYearPriceWithMonthProduct = (products) => {
    const monthProduct = products.find((p) => isMonthPeriod(p.period));

    return `${formatPrice((monthProduct?.price || 0) * 12, 'ru')} ₽ в год`;
};

export const getDiscountText = (discountPrice: number, discountPeriod: string): string => {
    return `${formatPrice(discountPrice, 'ru')} ₽/${getPeriodName(discountPeriod)}`;
};

export const getTariffLabelByQuota = (quota: number): string => {
    if (quota >= 256) {
        return 'Оптимальный';
    } else if (quota >= 128) {
        return 'Популярный';
    } else if (quota >= 64) {
        return 'Специальный';
    }

    return 'Выгодный';
};

export const getFeatureList = (countSnapshotsBySpace?: number, isSingleQuota?: boolean, skipBenefitsFeature?: boolean) => {
    if (isSingleQuota) {
        return [
            isOnlyWebAdInTariffs ? 'Без рекламы в веб-версии' : 'Без рекламы в Почте и Облаке',
            'Загрузка файлов до 100 ГБ',
            isFamilySubsInTariff ? 'Делитесь местом с близкими' : 'Быстрый ответ поддержки',
            !skipBenefitsFeature && (
                <>
                    И другие{'\u00A0'}
                    <a href="#benefits">возможности</a>
                </>
            ),
        ].filter((val) => !!val);
    }

    return [
        isFamilySubsInTariff ? 'Делитесь местом с близкими' : countSnapshotsBySpace && `+${countSnapshotsBySpace} новых фото в Облаке`,
        'Загрузка файлов до 100 ГБ',
        'Отправка файлов до 100 ГБ',
        isOnlyWebAdInTariffs ? 'Без рекламы в веб-версии' : 'Без рекламы в Почте и Облаке',
    ].filter((val) => !!val);
};
