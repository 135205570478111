import { authPopup } from 'reactApp/modules/ph/ph.thunkActions';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getIdByStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { store as reduxStore } from 'reactApp/store';

import { FamilyError } from './FamilyErrorDialog.types';

type ErrorData = {
    title: string;
    text: string;
    successButtonText?: string;
    onSuccess?: () => void;
    cancelButtonText?: string;
    onCancel?: () => void;
};

export const FamilyErrorData: Record<FamilyError, ErrorData> = {
    [FamilyError.linkExpired]: {
        title: 'Срок действия ссылки истёк',
        text: 'Попросите новую ссылку-приглашение, чтобы присоединиться к\u00A0общей подписке',
        cancelButtonText: 'Понятно',
    },
    [FamilyError.linkLimit]: {
        title: 'В подписке не осталось мест',
        text: 'К этой подписке больше нельзя присоединиться.',
        cancelButtonText: 'Понятно',
    },
    [FamilyError.linkError]: {
        title: 'Ссылка не работает',
        text: 'С этой ссылкой что-то не так. Попросите прислать вам новую.',
        cancelButtonText: 'Понятно',
    },
    [FamilyError.linkRemoved]: {
        title: 'Вас удалили из подписки',
        text: 'В течение ближайших 24 часов не получится вернуться.',
        cancelButtonText: 'Понятно',
    },
    [FamilyError.wrongAccount]: {
        title: 'Войдите в личное Облако',
        text: 'Принять приглашение можно только с личного аккаунта. Войдите в\u00A0личный аккаунт или создайте его.',
        successButtonText: 'Сменить аккаунт',
        onSuccess: () => {
            reduxStore.dispatch(
                authPopup({
                    closable: true,
                    loginRequest: true,
                    successPage: window.location.href,
                })
            );
        },
    },
    [FamilyError.wrongAccountEnter]: {
        title: 'Войдите в личное Облако',
        text: 'Делиться подпиской можно только с личного аккаунта. Войдите в\u00A0личный аккаунт или создайте его.',
        successButtonText: 'Сменить аккаунт',
        // eslint-disable-next-line sonarjs/no-identical-functions
        onSuccess: () => {
            reduxStore.dispatch(
                authPopup({
                    closable: true,
                    loginRequest: true,
                    successPage: window.location.href,
                })
            );
        },
    },
    [FamilyError.alredyInFamily]: {
        title: 'Вы уже пользуетесь подпиской вместе с близкими',
        text: 'Если вы решили присоединиться к другой подписке, нужно покинуть текущую в разделе «Общая подписка».',
        successButtonText: 'Перейти в раздел',
        onSuccess: () => {
            reduxStore.dispatch(historyPush({ id: getIdByStorage(EStorageType.family) }));
        },
        cancelButtonText: 'Оставить всё как есть',
        onCancel: () => {
            reduxStore.dispatch(historyPush({ id: getIdByStorage(EStorageType.home) }));
        },
    },
    [FamilyError.ownerSubscriptionOrLinkExpired]: {
        title: 'Ссылка больше не работает или закончилась подписка',
        text: 'Уточните у пригласившего, всё ли в силе, и попросите новую ссылку.',
        cancelButtonText: 'Понятно',
    },
    [FamilyError.familyMembersLimit]: {
        title: 'К этой подписке нельзя присоединиться',
        text: 'В течение года в эту подписку пригласили максимальное количество участников, поэтому больше нельзя присоединиться.',
        cancelButtonText: 'Понятно',
    },
    [FamilyError.familyOverquota]: {
        title: 'Не удалось добавить вас в подписку',
        text: 'В подписке не осталось места — хранилище заполнено. Ссылка снова заработает, когда появятся свободные гигабайты.',
        cancelButtonText: 'Понятно',
    },
    [FamilyError.tooManyFamilies]: {
        title: 'Сейчас вы не можете поделиться подпиской',
        text: 'В течение года вы создали 2 общие подписки, то есть дважды удаляли всех участников из подписки. Это максимум, больше пока нельзя. Вы снова сможете добавлять близких через год с даты, когда впервые поделились подпиской.',
        cancelButtonText: 'Понятно',
    },
    [FamilyError.touchIsUnavailable]: {
        title: 'Принять приглашение пока можно только с компьютера',
        text: 'Откройте ссылку-приглашение на компьютере.',
        cancelButtonText: 'Понятно',
    },
};
