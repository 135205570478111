import React, { PureComponent, ReactElement, ReactNode } from 'react';
import { DropdownItemAction } from 'reactApp/ui/DropdownItemAction/DropdownItemAction';
import { DropdownList, DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';
import { createGaSender } from 'reactApp/utils/ga';

const sendGa = createGaSender('context-menu');

interface Props {
    posX: number;
    posY: number;
    close: (param?: any) => void;
    list: ContextMenuItem[];
    gaSuffix?: string;
    theme?: DropdownTheme;
}

export interface ContextMenuItem {
    id: string;
    text: string;
    icon?: ReactNode;
    withStatus?: boolean;
    active?: boolean;
    asc?: boolean;
    divider?: boolean;
    onClick?: (params) => any;
    list?: ContextMenuItem[];
    theme?: DropdownTheme;
    mod?: 'new';
    borderedArrow?: boolean;
    openNestedByArrow?: boolean;
    disablePreventDefault?: boolean;
    hotkey?: string;
}

export class ContextMenu extends PureComponent<Props> {
    public componentDidMount(): void {
        const { gaSuffix = '' } = this.props;
        sendGa('show', gaSuffix);
    }

    renderItem = (item) => {
        const isSort = item.id.includes('sort') && item.list === undefined;

        return (
            <DropdownItemAction
                active={item.active}
                text={item.text}
                asc={item.asc}
                withStatus={item.withStatus}
                icon={item.icon}
                mod={item.mod}
                hotkey={item.hotkey}
                id={item.id}
                isSort={isSort}
            />
        );
    };

    public render(): ReactElement {
        const { list, posX, posY, close, gaSuffix, theme } = this.props;
        return (
            <DropdownList
                list={list}
                posY={posY}
                posX={posX}
                close={close}
                gaId="context-menu"
                gaSuffix={gaSuffix}
                renderItem={this.renderItem}
                closeOnResize
                closeOnScroll
                openNestedOnHover
                theme={theme}
            />
        );
    }
}
