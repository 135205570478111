import config from 'Cloud/config';

/** Определяется по папке, которая, видимо, является фильтром по типу файлов.
 * Этот тип проставляется в ссылки всем дочерним. Влияет на offset */
export enum EAttachesItemContentType {
    'photo' = 1,
    'docs' = 4,
    'music' = 2,
    'video' = 3,
    'other' = 0,
    'allTypes' = -1,
}

export enum EAttachesType {
    'photo' = 'image',
    'music' = 'music',
    'docs' = 'docs',
    'video' = 'video',
    'other' = 'other',
    'all' = '',
}

export const convertContentTypeToEmailFormat = (type: EAttachesType): EAttachesItemContentType => {
    // eslint-disable-next-line eqeqeq
    const key = Object.keys(EAttachesType).filter((x): boolean => EAttachesType[x] == type)[0];
    // 'other' = 0 - нам тоже нужен
    return EAttachesItemContentType.hasOwnProperty(key) ? EAttachesItemContentType[key] : EAttachesItemContentType.allTypes;
};

export const MAIL_HOST = config.get('MAIL_HOST');
export const TOUCH_MAIL_HOST = config.get('TOUCH_MAIL_HOST');
export const DOMAIN_HOST = config.get('DOMAIN_HOST');
export const CORSAPI_HOST = config.get('CORS_API_HOST');
export const ZIP_VIEW_HOST = config.get('ZIP_VIEW_HOST');
export const RE_AMP = /&amp;/g;

export const docExtensionsSupported = [
    'doc',
    'docx',
    'docm',
    'dot',
    'dotx',
    'odt',
    'ods',
    'xls',
    'xlsx',
    'xlsm',
    'xlsb',
    'odp',
    'pot',
    'potx',
    'potm',
    'ppt',
    'pptx',
    'pptm',
    'pps',
    'ppsx',
    'ppsm',
];

export enum AttachesFolders {
    inbox = '0',
    sent = '500000',
    all = 'all',
}
