import { ChangeEvent, KeyboardEvent, Ref, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { isTouchSearchHistoryEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getSearchQueryString, parseSearchQuery } from 'reactApp/modules/search/search.helpers';
import { searchHistoryHide } from 'reactApp/modules/search/search.module';
import { getSearchHistory } from 'reactApp/modules/search/search.selectors';
import { EActionSearch, EActionSearchHistory } from 'reactApp/modules/search/search.types';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { getIdByStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { ESearchOptionType } from 'reactApp/ui/WebSearch/WebSearch.data';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import { ISearchContent } from '../MobileSearch.data';

const PLACEHOLDER = 'Поиск по Облаку';

export const useMobileSearchContent = (): ISearchContent => {
    const inputRef = useRef<HTMLInputElement>(null);

    const { query = '' } = useSelector(SettingsSelectors.getQueryParams) || {};
    const historyItems = useSelector(getSearchHistory);

    const isPhone = Boolean(IS_PHONE_BROWSER);

    const [inputValue, setInputValue] = useState(query);

    const dispatch = useDispatch();

    const showSearchPage = useCallback(
        (value?: string) => {
            if (!value) {
                return;
            }

            const search = getSearchQueryString(value, { searchType: ESearchOptionType.all });
            const id = `${getIdByStorage(EStorageType.search)}/`;
            dispatch(historyPush({ id, search }));
            if (isTouchSearchHistoryEnabled) {
                dispatch(searchHistoryHide());
            }
            inputRef.current?.blur();
        },
        [dispatch]
    );

    const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const parsedValue = parseSearchQuery(event.target?.value);
        setInputValue(parsedValue);
    }, []);

    const onInputClear = useCallback(() => {
        if (inputValue) {
            sendDwh({
                eventCategory: ECategoryGa.search,
                action: EActionSearch.searchClear,
                dwhData: { search_phrase: inputValue, place: 'touch' },
            });
        }
        setInputValue('');
        inputRef.current?.focus();
    }, [inputValue]);

    const onKeyUp = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                showSearchPage(inputValue);
            }
        },
        [showSearchPage, inputValue]
    );

    const searchValue = useCallback(
        (value: string) => {
            sendDwh({
                eventCategory: ECategoryGa.search_history,
                action: EActionSearchHistory.open,
                dwhData: {
                    search_phrase: value,
                    history_pos: historyItems.findIndex((item) => item.text === value),
                    count_history: historyItems.length,
                    place: isPhone ? 'touch' : 'web',
                },
            });

            setInputValue(value);
            showSearchPage(value);
        },
        [showSearchPage, historyItems, isPhone]
    );

    const getInputRef = useCallback((el) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        inputRef.current = el;
    }, []) as Ref<HTMLInputElement>;

    useEffect(() => {
        if (query !== inputValue) {
            setInputValue(query);
        }
    }, [query]);

    return {
        inputValue,
        placeholder: PLACEHOLDER,
        onInputChange,
        onInputClear,
        getInputRef,
        onKeyUp,
        searchValue,
    };
};
