import React, { VFC } from 'react';
import { IBaseSubscription } from 'reactApp/modules/subscriptions/subscriptions.types';

import { CommonInfo } from '../CommonInfo/CommonInfo';
import styles from './BaseSubscription.css';

export const BaseSubscription: VFC<{ subscription: IBaseSubscription }> = ({ subscription: { space } }) => (
    <>
        <CommonInfo space={space} isBase />
        <span className={styles.tariffManagement}>Данная подписка не может быть изменена или отменена</span>
    </>
);
