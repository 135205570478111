import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fileHistoryRecoveryRequest } from 'reactApp/modules/fileHistory/fileHistory.module';
import { FileHistorySelectors } from 'reactApp/modules/fileHistory/fileHistory.selectors';
import { FileHistoryItem } from 'reactApp/modules/fileHistory/fileHistory.types';
import { RootState } from 'reactApp/store';
import { sendFilesHistoryGa } from 'reactApp/ui/FileHistory/FileHistory.helpers';
import { EDialogsType } from 'reactApp/ui/FileHistory/FileHistory.types';
import { createAvatarUrl } from 'reactApp/utils/avatar';

type ItemController = {
    (id: string): {
        showDialog: EDialogsType | null;
        closeDialog: () => void;
        item: FileHistoryItem;
        avatarUrl: string | undefined;
        handleCopy: () => void;
        handleRewrite: () => void;
        recovery: (isRewrite?: any) => void;
    };
};
export const useItem: ItemController = (id) => {
    const [showDialog, setDialog] = useState<EDialogsType | null>(null);
    const dispatch = useDispatch();
    const item = useSelector((state: RootState) => FileHistorySelectors.getEntry(state, id));
    const { actor } = item;
    const avatarUrl = actor && createAvatarUrl(actor?.email, actor?.name, 32);

    const closeDialog = useCallback(() => {
        setDialog(null);
    }, []);

    const handleRewrite = useCallback(() => {
        sendFilesHistoryGa('click-dropdown', 'rewrite');
        setDialog(EDialogsType.rewrite);
    }, []);
    const handleCopy = useCallback(() => {
        sendFilesHistoryGa('click-dropdown', 'copy');
        setDialog(EDialogsType.copy);
    }, []);
    const recovery = useCallback(
        (isRewrite = false) => {
            dispatch(fileHistoryRecoveryRequest({ version: id, isRewrite }));
        },
        [dispatch, id]
    );

    return {
        showDialog,
        closeDialog,
        item,
        avatarUrl,
        handleCopy,
        handleRewrite,
        recovery,
    };
};
