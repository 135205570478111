import { requestPayment } from 'Cloud/Application/Payment';
import overquotaImg from 'img/overquotaMobile.png';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getProductById } from 'reactApp/modules/products/products.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { RootState } from 'reactApp/store';
import { ESplashScreenMod, UseContentHook } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen.types';

export const useOverquotaSplashScreen: UseContentHook = ({ hookParams = {} }) => {
    const { over } = useSelector(UserSelectors.getCloudSpace);
    const product = useSelector((state: RootState) => getProductById(state, hookParams?.productId));

    const onClick = useCallback(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        requestPayment({ isMobile: true, tariff: product?.id });
    }, [product?.id]);

    return {
        id: 'mobile-overquota',
        title: 'Работа вашего Облака остановлена',
        description: `Ваше хранилище переполнено на ${over?.value}. Вы больше не можете загружать фото, делиться файлами, создавать папки. Для снятия ограничений увеличьте Облако.`,
        buttonText: `Увеличить на ${product?.space?.value}`,
        onClick,
        img: overquotaImg,
        mod: ESplashScreenMod.red,
    };
};
