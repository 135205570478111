import {
    Icon20Chain,
    Icon20CloudOutline,
    Icon20DeleteOutline,
    Icon20DiscountOutline,
    Icon20DocumentListOutline,
    Icon20DocumentOutline,
    Icon20DocumentStatsOutline,
    Icon20DocumentTextOutline,
    Icon20GovernmentOutline,
    Icon20HistoryBackwardOutline,
    Icon20LikeOutline,
    Icon20LockOutline,
    Icon20LogoVkOutline,
    Icon20MailOutline,
    Icon20PictureOutline,
    Icon20PictureStackOutline,
    Icon20RecentOutline,
    Icon20ShareExternalOutline,
    Icon20UsersOutline,
    Icon24DeleteOutline,
    Icon24FolderOutline,
    Icon24PictureOutline,
} from '@vkontakte/icons';
import React from 'react';
import { IS_BIZ_USER, IS_MY_TEAM, IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { DiscountPromocodesList } from 'reactApp/sections/SubscriptionsPage/ui/DiscountPromocodesList/DiscountPromocodesList';
import { EDocumentsDomainTreeRootIds, ETreeRootIds } from 'reactApp/ui/TreeComponent/TreeComponent.constants';
import { TreeNodeData } from 'reactApp/ui/TreeComponent/TreeNode.types';
import { Icon20DeleteClockOutline, Icon20FolderOutline } from 'reactApp/ui/VKUIIcons';

const myTeamParam = IS_MY_TEAM ? '?wv=1' : '';

export const treeItemsFlatten: Record<string, TreeNodeData> = {
    [ETreeRootIds.start]: {
        id: ETreeRootIds.start,
        gaId: 'start',
        title: 'Быстрый доступ',
        storage: EStorageType.start,
        folderCounter: 2,
        icon: <Icon20CloudOutline />,
        href: `/start`,
    },
    [ETreeRootIds.incoming]: {
        id: ETreeRootIds.incoming,
        // eslint-disable-next-line sonarjs/no-duplicate-string
        title: 'Совместные',
        gaId: 'sovm',
        storage: EStorageType.sharedLinks,
        folderCounter: 2,
        icon: <Icon20Chain />,
    },
    [ETreeRootIds.sharedLinks]: {
        id: ETreeRootIds.sharedLinks,
        title: 'Вы поделились',
        storage: EStorageType.sharedLinks,
        icon: <Icon20ShareExternalOutline />,
        folderCounter: 0,
        href: `/shared/links`,
    },
    [ETreeRootIds.sharedIncoming]: {
        id: ETreeRootIds.sharedIncoming,
        title: 'С вами поделились',
        storage: EStorageType.sharedIncoming,
        icon: <Icon20UsersOutline />,
        folderCounter: 0,
        href: `/shared/incoming`,
    },
    [ETreeRootIds.feed]: {
        id: ETreeRootIds.feed,
        gaId: 'feed',
        title: 'Последние',
        storage: EStorageType.feed,
        icon: <Icon20RecentOutline />,
        href: `/feed`,
    },
    [ETreeRootIds.favorites]: {
        id: ETreeRootIds.favorites,
        gaId: 'favorites',
        title: 'Избранные',
        storage: EStorageType.favorites,
        icon: <Icon20LikeOutline />,
        href: `/favorites`,
    },
    [ETreeRootIds.gallery]: {
        id: ETreeRootIds.gallery,
        title: 'Галерея',
        gaId: 'camera uploads',
        storage: EStorageType.gallery,
        icon: IS_PHONE_BROWSER ? <Icon24PictureOutline /> : <Icon20PictureOutline />,
        isMobile: !IS_MY_TEAM,
        href: `/gallery`,
    },
    [ETreeRootIds.sharedAutodelete]: {
        id: ETreeRootIds.sharedAutodelete,
        title: 'Автоудаляемые',
        gaId: 'autodelete',
        storage: EStorageType.sharedAutodelete,
        icon: <Icon20DeleteClockOutline />,
        href: `/shared/autodelete`,
    },
    [ETreeRootIds.documents]: {
        id: ETreeRootIds.documents,
        title: 'Личные документы',
        gaId: 'documents',
        storage: EStorageType.documents,
        icon: <Icon20GovernmentOutline />,
        href: `/documents`,
    },
    [ETreeRootIds.home]: {
        id: ETreeRootIds.home,
        gaId: 'home',
        title: 'Все файлы',
        storage: EStorageType.home,
        folderCounter: 2,
        icon: IS_PHONE_BROWSER ? <Icon24FolderOutline /> : <Icon20FolderOutline />,
        href: `/home`,
        isMobile: true,
    },
    [ETreeRootIds.domain]: {
        id: ETreeRootIds.domain,
        title: 'Общие папки',
        storage: EStorageType.home,
        folderCounter: 2,
        icon: <Icon20FolderOutline />,
        isMobile: IS_BIZ_USER && IS_MY_TEAM,
    },
    [ETreeRootIds.attaches]: {
        id: ETreeRootIds.attaches,
        gaId: 'attaches',
        title: 'Из почты',
        storage: EStorageType.attaches,
        folderCounter: 2,
        icon: <Icon20MailOutline />,
        href: `/attaches`,
    },
    [ETreeRootIds.recommend]: {
        id: ETreeRootIds.recommend,
        gaId: 'recomm',
        title: 'VK рекомендует',
        storage: EStorageType.recommend,
        folderCounter: 0,
        icon: <Icon20LogoVkOutline />,
        href: `/recommend`,
    },
    [ETreeRootIds.trashbin]: {
        id: ETreeRootIds.trashbin,
        gaId: 'trash',
        title: 'Корзина',
        storage: EStorageType.trashbin,
        folderCounter: 0,
        itemsCounter: 0,
        icon: IS_PHONE_BROWSER ? <Icon24DeleteOutline /> : <Icon20DeleteOutline />,
        isMobile: true,
        href: `/trashbin`,
    },
    [ETreeRootIds.promocodes]: {
        id: ETreeRootIds.promocodes,
        title: 'Промокоды',
        gaId: 'promocodes',
        storage: EStorageType.promocodes,
        icon: <Icon20DiscountOutline />,
        folderCounter: 2,
        component: <DiscountPromocodesList gaSuffix="tree" />,
    },
    [ETreeRootIds.albums]: {
        id: ETreeRootIds.albums,
        title: 'Альбомы',
        gaId: 'albums',
        storage: EStorageType.albums,
        icon: <Icon20PictureStackOutline />,
        href: '/albums',
    },
    [ETreeRootIds.alldocuments]: {
        id: ETreeRootIds.alldocuments,
        title: 'Документы Mail.ru',
        gaId: 'alldocuments',
        storage: EStorageType.alldocuments,
        icon: <Icon20DocumentOutline />,
    },
    [ETreeRootIds.alldocumentsDocument]: {
        id: ETreeRootIds.alldocumentsDocument,
        title: 'Документы',
        gaId: 'alldocumentsDocuments',
        storage: EStorageType.alldocuments,
        icon: <Icon20DocumentTextOutline />,
        href: `${ETreeRootIds.alldocumentsDocument}${myTeamParam}`,
    },
    [ETreeRootIds.alldocumentsSpreadsheet]: {
        id: ETreeRootIds.alldocumentsSpreadsheet,
        title: 'Таблицы',
        gaId: 'alldocumentsSpreadsheets',
        storage: EStorageType.alldocuments,
        icon: <Icon20DocumentListOutline />,
        href: `${ETreeRootIds.alldocumentsSpreadsheet}${myTeamParam}`,
    },
    [ETreeRootIds.alldocumentsPresentation]: {
        id: ETreeRootIds.alldocumentsPresentation,
        title: 'Презентации',
        gaId: 'alldocumentsPresentations',
        storage: EStorageType.alldocuments,
        icon: <Icon20DocumentStatsOutline />,
        href: `${ETreeRootIds.alldocumentsPresentation}${myTeamParam}`,
    },
    [ETreeRootIds.alldocumentsPdf]: {
        id: ETreeRootIds.alldocumentsPdf,
        title: 'PDF',
        gaId: 'alldocumentsPdf',
        storage: EStorageType.alldocuments,
        icon: <Icon20DocumentOutline />,
        href: `${ETreeRootIds.alldocumentsPdf}${myTeamParam}`,
    },
    [ETreeRootIds.alldocumentsTrashbin]: {
        id: ETreeRootIds.alldocumentsTrashbin,
        title: 'Корзина',
        gaId: 'alldocumentsTrashbin',
        storage: EStorageType.alldocuments,
        icon: <Icon20DeleteOutline />,
        href: `${ETreeRootIds.alldocumentsTrashbin}${myTeamParam}`,
    },
    [ETreeRootIds.safe]: {
        id: ETreeRootIds.safe,
        title: 'Сейф',
        gaId: 'safe',
        storage: EStorageType.safe,
        icon: <Icon20LockOutline />,
    },
};

export const treeRootConfig: TreeNodeData[] = [
    {
        ...treeItemsFlatten[ETreeRootIds.start],
        children: [
            {
                ...treeItemsFlatten[ETreeRootIds.incoming],
                children: [treeItemsFlatten[ETreeRootIds.sharedLinks], treeItemsFlatten[ETreeRootIds.sharedIncoming]],
            },
            treeItemsFlatten[ETreeRootIds.feed],
            treeItemsFlatten[ETreeRootIds.favorites],
            treeItemsFlatten[ETreeRootIds.gallery],
            treeItemsFlatten[ETreeRootIds.albums],
            treeItemsFlatten[ETreeRootIds.sharedAutodelete],
            treeItemsFlatten[ETreeRootIds.documents],
        ],
    },
    treeItemsFlatten[ETreeRootIds.home],
    treeItemsFlatten[ETreeRootIds.domain],
    treeItemsFlatten[ETreeRootIds.attaches],
    treeItemsFlatten[ETreeRootIds.recommend],
    treeItemsFlatten[ETreeRootIds.trashbin],
    treeItemsFlatten[ETreeRootIds.promocodes],
];

export const treeRootAllDocumentsConfig: TreeNodeData[] = [
    treeItemsFlatten[ETreeRootIds.alldocumentsDocument],
    treeItemsFlatten[ETreeRootIds.alldocumentsSpreadsheet],
    treeItemsFlatten[ETreeRootIds.alldocumentsPresentation],
    treeItemsFlatten[ETreeRootIds.alldocumentsPdf],
    treeItemsFlatten[ETreeRootIds.alldocumentsTrashbin],
];

export const treeRootConfigNew = (hasSafeSection = false): TreeNodeData[] => [
    {
        ...treeItemsFlatten[ETreeRootIds.home],
        hideIcon: true,
    },
    treeItemsFlatten[ETreeRootIds.domain],
    treeItemsFlatten[ETreeRootIds.gallery],
    treeItemsFlatten[ETreeRootIds.albums],
    ...(hasSafeSection
        ? [
              { ...treeItemsFlatten[ETreeRootIds.documents], divider: false },
              {
                  ...treeItemsFlatten[ETreeRootIds.safe],
                  divider: true,
              },
          ]
        : [
              {
                  ...treeItemsFlatten[ETreeRootIds.documents],
                  divider: true,
              },
          ]),
    {
        ...treeItemsFlatten[ETreeRootIds.feed],
        title: 'Недавние',
        icon: <Icon20HistoryBackwardOutline />,
    },
    {
        ...treeItemsFlatten[ETreeRootIds.sharedLinks],
        title: 'Совместные',
        folderCounter: 0,
        icon: <Icon20Chain />,
    },
    treeItemsFlatten[ETreeRootIds.favorites],
    {
        ...treeItemsFlatten[ETreeRootIds.attaches],
        divider: true,
        folderCounter: 0,
    },
    treeItemsFlatten[ETreeRootIds.trashbin],
];

export const treeRootConfigAllDocuments: TreeNodeData[] = [
    {
        ...treeItemsFlatten[ETreeRootIds.home],
        hideIcon: true,
    },
    treeItemsFlatten[ETreeRootIds.domain],
    treeItemsFlatten[ETreeRootIds.gallery],
    treeItemsFlatten[ETreeRootIds.albums],
    {
        ...treeItemsFlatten[ETreeRootIds.documents],
        divider: true,
    },
    {
        ...treeItemsFlatten[ETreeRootIds.feed],
        title: 'Недавние',
        icon: <Icon20HistoryBackwardOutline />,
    },
    {
        ...treeItemsFlatten[ETreeRootIds.sharedLinks],
        title: 'Совместные',
        folderCounter: 0,
        icon: <Icon20Chain />,
    },
    treeItemsFlatten[ETreeRootIds.favorites],
    {
        ...treeItemsFlatten[ETreeRootIds.trashbin],
        divider: true,
        folderCounter: 0,
    },
    {
        ...treeItemsFlatten[ETreeRootIds.alldocuments],
        divider: true,
        isBeta: true,
    },
    {
        ...treeItemsFlatten[ETreeRootIds.attaches],
        folderCounter: 0,
    },
];

export const treeRootConfigLM = (hasSafeSection = false) => {
    return IS_BIZ_USER ? treeRootConfig : treeRootConfigNew(hasSafeSection);
};

const documentsDomainTreeItemsFlatten: Record<EDocumentsDomainTreeRootIds, TreeNodeData> = {
    [EDocumentsDomainTreeRootIds.alldocumentsDomainDocument]: {
        id: EDocumentsDomainTreeRootIds.alldocumentsDomainDocument,
        title: 'Документы',
        gaId: 'alldocumentsDomainDocument',
        storage: EStorageType.alldocuments,
        icon: <Icon20DocumentTextOutline />,
        href: `${EDocumentsDomainTreeRootIds.alldocumentsDomainDocument}${myTeamParam}`,
    },
    [EDocumentsDomainTreeRootIds.alldocumentsDomainSpreadsheet]: {
        id: EDocumentsDomainTreeRootIds.alldocumentsDomainSpreadsheet,
        title: 'Таблицы',
        gaId: 'alldocumentsDomainSpreadsheet',
        storage: EStorageType.alldocuments,
        icon: <Icon20DocumentListOutline />,
        href: `${EDocumentsDomainTreeRootIds.alldocumentsDomainSpreadsheet}${myTeamParam}`,
    },
    [EDocumentsDomainTreeRootIds.alldocumentsDomainPresentation]: {
        id: EDocumentsDomainTreeRootIds.alldocumentsDomainPresentation,
        title: 'Презентации',
        gaId: 'alldocumentsDomainPresentation',
        storage: EStorageType.alldocuments,
        icon: <Icon20DocumentStatsOutline />,
        href: `${EDocumentsDomainTreeRootIds.alldocumentsDomainPresentation}${myTeamParam}`,
    },
    [EDocumentsDomainTreeRootIds.alldocumentsDomainPdf]: {
        id: EDocumentsDomainTreeRootIds.alldocumentsDomainPdf,
        title: 'PDF',
        gaId: 'alldocumentsDomainPdf',
        storage: EStorageType.alldocuments,
        icon: <Icon20DocumentOutline />,
        href: `${EDocumentsDomainTreeRootIds.alldocumentsDomainPdf}${myTeamParam}`,
    },
    [EDocumentsDomainTreeRootIds.alldocumentsDomainTrashbin]: {
        id: EDocumentsDomainTreeRootIds.alldocumentsDomainTrashbin,
        title: 'Корзина',
        gaId: 'alldocumentsDomainTrashbin',
        storage: EStorageType.alldocuments,
        icon: <Icon20DeleteOutline />,
        href: `${EDocumentsDomainTreeRootIds.alldocumentsDomainTrashbin}${myTeamParam}`,
    },
};

export const treeRootAllDocumentsDomainConfig: TreeNodeData[] = [
    documentsDomainTreeItemsFlatten[EDocumentsDomainTreeRootIds.alldocumentsDomainDocument],
    documentsDomainTreeItemsFlatten[EDocumentsDomainTreeRootIds.alldocumentsDomainSpreadsheet],
    documentsDomainTreeItemsFlatten[EDocumentsDomainTreeRootIds.alldocumentsDomainPresentation],
    documentsDomainTreeItemsFlatten[EDocumentsDomainTreeRootIds.alldocumentsDomainPdf],
    documentsDomainTreeItemsFlatten[EDocumentsDomainTreeRootIds.alldocumentsDomainTrashbin],
];
