import classNames from 'clsx';
import React, { FC, memo, useCallback, useRef, useState } from 'react';
import { useViewContext } from 'reactApp/hocs/withViewContext';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { Kind } from 'reactApp/types/Tree';
import { Avatar } from 'reactApp/ui/Avatar/Avatar';
import { logHandler } from 'reactApp/ui/Datalist/DataList.helpers';
import { IThumb } from 'reactApp/ui/DataListItem/DataListItem.helpers';
import { FileIcon } from 'reactApp/ui/FileIcon/FileIcon';
import { getIconType } from 'reactApp/ui/FileIcon/getIconType';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { ImageCancelable } from 'reactApp/ui/ImageCancelable/ImageCancelable';

import styles from './PicVK.css';

export type PicProps = {
    isFolder: boolean;
    thumbUrl?: IThumb;
    id?: string | undefined;
    ext?: string;
    isVirus?: boolean;
    isLarge?: boolean;
    author?: { name?: string; email: string };
    kind?: Kind;
    isAutoDelete?: boolean;
    isAuthorView?: boolean;
};

export const PicVk: FC<PicProps> = memo(function Pic(props) {
    const iconType = getIconType({
        isFolder: props.isFolder,
        kind: props.isFolder ? props.kind : undefined,
        ext: props.isFolder ? undefined : props.ext,
        isVirus: props.isFolder ? false : props.isVirus,
        isAutoDelete: props.isFolder ? props.isAutoDelete : false,
    });

    const viewContext = useViewContext();
    const view = viewContext.isVKEmbedded ? EViewMode.vkEmbeddedList : EViewMode.list;

    const [isLoaded, setLoaded] = useState<boolean>(false);

    const startTime = useRef(Date.now());

    const handleThumbLoad = useCallback(() => {
        setLoaded(true);

        logHandler({
            radarName: `list_load_success`,
            radars: [`all=${Date.now() - startTime.current}`],
        });
    }, []);

    const handleThumbError = useCallback(() => {
        logHandler({
            radarName: `list_load_fail`,
            radars: [`all=${Date.now() - startTime.current}`],
        });
    }, []);

    const { isAuthorView, author } = props;

    if (isAuthorView && author) {
        const { email, name } = author;
        return (
            <div className={styles.pic}>
                <Hint text={name || email}>
                    <Avatar size={26} email={email} name={name} />
                </Hint>
            </div>
        );
    }

    return (
        <div className={styles.pic}>
            {author && (
                <Hint text={author.name || author.email}>
                    <div className={styles.author}>
                        <Avatar size={16} email={author.email} name={author.name} />
                    </div>
                </Hint>
            )}
            <div className={styles.container}>
                {!isLoaded && (
                    <div className={classNames({ [styles.icon]: true, [styles.icon_large]: props.isLarge })}>
                        <FileIcon type={iconType} view={view} />
                    </div>
                )}
                {!props.isFolder && props.thumbUrl?.src && (
                    <ImageCancelable
                        className={classNames({
                            [styles.thumb]: true,
                            [styles.thumb_loaded]: isLoaded,
                        })}
                        src={props.thumbUrl?.src}
                        srcSet={props.thumbUrl?.srcSet ? `${props.thumbUrl?.srcSet} 2x` : ''}
                        alt=""
                        onError={handleThumbError}
                        onLoad={handleThumbLoad}
                    />
                )}
            </div>
        </div>
    );
});
