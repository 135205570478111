import { EUploadReasonSource } from 'reactApp/modules/uploading/serviceClasses/UploadingReason';

import { FileTooLargeError } from '../errors/FileTooLargeError';
import { InternalError } from '../errors/InternalError';
import { MalformedError } from '../errors/MalformedError';
import { UnknownError } from '../errors/UnknownError';
import { IllegalContentFail } from '../fails/IllegalContentFail';

export function parseBackendResponseTextOfPut(responseText: string | null) {
    if (!responseText) {
        return  null;
    }

    const isHash = hashPattern.test(responseText);
    let hash;
    let size;
    let error;

    if (isHash) {
        hash = responseText;
    } else {
        const source = EUploadReasonSource.SOURCE_BACKEND;
        const stack = new Error('MalformedError');

        error = new MalformedError(stack, source, responseText);
    }

    return new BackendResponseData(hash, size, error);
}

/**
 * Parse response text like:
 * "EC349C1F081BB8A80C682FD69098093CAF6C8726;2097152\r\n"
 * "-EC349C1F081BB8A80C682FD69098093CAF6C8726;2097152;illegal\r\n"
 */
export function parseBackendResponseTextOfPost(responseText: string | null) {
    if (!responseText) {
        return  null;
    }

    let result = parseSuccess(responseText);

    if (!result) {
        result = parseError(responseText);

        if (!result) {
            let hash;
            let size;
            const source = EUploadReasonSource.SOURCE_BACKEND;
            const stack = new Error('MalformedError');
            const error = new MalformedError(stack, source, responseText);

            result = new BackendResponseData(hash, size, error);
        }
    }

    return result;
}

/**
 * Matches response text like:
 * "EC349C1F081BB8A80C682FD69098093CAF6C8726"
 * @type {RegExp}
 */
const hashPattern = /^[0-9ABCDEF]{40}$/;

/**
 * Внутренняя ошибка сервера.
 * "-EC349C1F081BB8A80C682FD69098093CAF6C8726;2097152;internal\r\n"
 */
const ERROR_INTERNAL = 'internal';

/**
 * Слишком большой файл.
 * "-EC349C1F081BB8A80C682FD69098093CAF6C8726;2097152;too large\r\n"
 */
const ERROR_TOO_LARGE = 'too large';

/**
 * Заблокированный контент.
 * "-EC349C1F081BB8A80C682FD69098093CAF6C8726;2097152;illegal\r\n"
 */
const FAIL_ILLEGAL = 'illegal';

/**
 * Matches response text like:
 * "EC349C1F081BB8A80C682FD69098093CAF6C8726;2097152\r\n"
 */
const successPattern = /^([0-9ABCDEF]{40});(\d+)$/m;

/**
 * Matches response text like:
 * "-EC349C1F081BB8A80C682FD69098093CAF6C8726;2097152;illegal\r\n"
 */
const errorPattern = /^-([0-9ABCDEF]{40});(\d+);([-\w]+)$/m;

function createError(errorType) {
    const source = EUploadReasonSource.SOURCE_BACKEND;
    let stack;
    let error;

    switch (errorType) {
        case FAIL_ILLEGAL:
            stack = new Error('IllegalContentFail');
            error = new IllegalContentFail(stack, source);

            break;

        case ERROR_TOO_LARGE:
            stack = new Error('FileTooLargeError');
            error = new FileTooLargeError(stack, source);

            break;

        case ERROR_INTERNAL:
            stack = new Error('InternalError');
            error = new InternalError(stack, source);

            break;

        default:
            // Upload-сервер прислал ошибку в правильном формате,
            // но нам не известен такой тип ошибки.
            stack = new Error('UnknownError');
            error = new UnknownError(stack, source, errorType);
    }

    return error;
}

/**
 * Parse response text like:
 * "EC349C1F081BB8A80C682FD69098093CAF6C8726;2097152\r\n"
 */
function parseSuccess(responseText: string) {
    const pattern = successPattern;
    const matches = responseText.match(pattern);
    let result;

    if (Array.isArray(matches)) {
        const HASH = 1;
        const SIZE = 2;
        const hash = matches[HASH];
        const size = matches[SIZE];

        // eslint-disable-next-line radix
        result = new BackendResponseData(hash, parseInt(size, 10));
    } else {
        result = null;
    }

    return result;
}

/**
 * Parse response text like:
 * "-EC349C1F081BB8A80C682FD69098093CAF6C8726;2097152;illegal\r\n"
 */
function parseError(responseText: string) {
    const pattern = errorPattern;
    const matches = responseText.match(pattern);
    let result;

    if (Array.isArray(matches)) {
        const HASH = 1;
        const SIZE = 2;
        const ERROR = 3;
        const hash = matches[HASH];
        const size = matches[SIZE];
        const error = createError(matches[ERROR]);
        // eslint-disable-next-line radix
        result = new BackendResponseData(hash, parseInt(size, 10), error);
    } else {
        result = null;
    }

    return result;
}

export class BackendResponseData {
    hash: string;
    size: number;
    error: any;

    /**
     * @param {string} hash – облачный hash файла (SHA1 + salt).
     * @param {number} size – размер файла (в байтах).
     * @param {UploadReason|null} error
     */
    constructor(hash: string, size: number, error: any = null) {
        this.hash = hash || '';
        this.size = size || 0;
        this.error = error || null;
    }
}
