export function calculateTouchesDistance(events: TouchList): number {
    if (events.length !== 2) {
        return 0;
    }
    const { clientX: x0, clientY: y0 } = events[0] || {};
    const { clientX: x1, clientY: y1 } = events[1] || {};
    const xDiff = Math.trunc(x1) - Math.trunc(x0);
    const yDiff = Math.trunc(y1) - Math.trunc(y0);
    return Math.trunc(Math.hypot(xDiff, yDiff));
}
