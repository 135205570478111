import { getErrorMsg, logger } from 'lib/logger';
import { BillingStartEvent, EventStartAPICall } from 'reactApp/api/billing/EventStartAPICall';
import { IS_SOCIAL_USER } from 'reactApp/appHelpers/configHelpers';
import { trialTariffsList } from 'reactApp/appHelpers/featuresHelpers';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { CardActions } from 'reactApp/modules/creditCard/creditCard.module';
import { loadPaidInfoRequest } from 'reactApp/modules/paidInfo/paidInfo.module';
import { initProducts, initProductsForAnonymous } from 'reactApp/modules/products/products.module';
import { initPromoTariffs as initPromoTariffsAction } from 'reactApp/modules/promoTariffs/promoTariffs.module';
import {
    loadQuotaLandingFailure,
    loadQuotaLandingRequest,
    loadQuotaLandingSuccess,
    loadQuotaTariffsLandingRequest,
    sendShowLandingAnalyticsAction,
    showLandingTariffsAction,
} from 'reactApp/modules/quotaLanding/quotaLanding.module';
import { routeChangeSuccess } from 'reactApp/modules/router/router.module';
import { getQuotaLandingStorage } from 'reactApp/modules/router/router.selectors';
import { loadSocialUser } from 'reactApp/modules/socialUser/actions/loadSocialUser.action';
import { handleSubscriptionsRequest } from 'reactApp/modules/subscriptions/subscriptions.saga';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { loadUserQuotaSuccess } from 'reactApp/modules/userQuota/userQuota.module';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { sendQuotaGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { call, cancel, put, putResolve, select, take, takeEvery } from 'redux-saga/effects';

function* sendShowLandingAnalytics() {
    const { isLoaded } = yield select(UserQuotaSelectors.getLoadingState);

    if (!isLoaded) {
        yield take(loadUserQuotaSuccess);
    }

    yield call(sendQuotaGa, { action: 'landing', label: 'view' });
}

function* startLandingQuotaTariffs() {
    const storage = getQuotaLandingStorage();

    yield put(
        routeChangeSuccess({
            id: ROOT_FOLDER_ID,
            storage,
            params: {},
            __isFolder: true,
            url: '',
            __parent: '',
        })
    );
    try {
        if (trialTariffsList.length) {
            yield put(loadPaidInfoRequest());
        }
        yield put(initProducts());
        if (IS_SOCIAL_USER) {
            yield putResolve(yield call(loadSocialUser));
        }
        yield put(loadQuotaLandingSuccess());

        yield put(initPromoTariffsAction({}));
        yield put(CardActions.loadCard());
        yield call(handleSubscriptionsRequest, { skipFutureReq: true });
    } catch (error) {
        logger.error(getErrorMsg(error), error);
        yield put(loadQuotaLandingFailure());
        yield cancel();
    }
}

function* startLandingQuota() {
    const storage = getQuotaLandingStorage();

    yield put(
        routeChangeSuccess({
            id: ROOT_FOLDER_ID,
            storage,
            params: {},
            __isFolder: true,
            url: '',
            __parent: '',
        })
    );

    const isAnonymous = yield select(UserSelectors.isAnonymous);

    if (isAnonymous) {
        yield put(initProductsForAnonymous());
        yield put(loadQuotaLandingSuccess());
        return;
    }

    try {
        yield put(initProducts());

        yield put(loadQuotaLandingSuccess());
    } catch (error) {
        logger.error(getErrorMsg(error), error);
        yield put(loadQuotaLandingFailure());
        yield cancel();
    }
}

function* showLandingTariffs() {
    try {
        yield call(new EventStartAPICall().makeRequest, { event: BillingStartEvent.mailSpaceTariffs });
    } catch (error) {
        logger.error(error);
        yield cancel();
    }
}

export function* watchQuotaLanding() {
    yield takeEvery(loadQuotaLandingRequest.toString(), startLandingQuota);
    yield takeEvery(loadQuotaTariffsLandingRequest.toString(), startLandingQuotaTariffs);
    yield takeEvery(showLandingTariffsAction.toString(), showLandingTariffs);
    yield takeEvery(sendShowLandingAnalyticsAction.toString(), sendShowLandingAnalytics);
}
