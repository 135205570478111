import { clear } from 'idb-keyval';
import { MAX_PARALLEL_UPLOAD_COUNT } from 'reactApp/modules/features/features.helpers';
import { getNameById } from 'reactApp/modules/file/utils';
import { joinPath } from 'reactApp/modules/uploading/helpers/fs/fs.helpers';
import { UploadingCancel } from 'reactApp/modules/uploading/serviceClasses/UploadingCancel';
import { UploadingDescriptor } from 'reactApp/modules/uploading/serviceClasses/UploadingDescriptor';
import { UploadingPacketConfig } from 'reactApp/modules/uploading/serviceClasses/UploadingPacketConfig';
import { EUploadReasonSource, UploadingReason } from 'reactApp/modules/uploading/serviceClasses/UploadingReason';
import { DEFAULT_FILE_UPLOAD_SPEED } from 'reactApp/modules/uploading/uploading.constants';
import { EConflictResolution, EUploadingMakeAction, EUploadingState } from 'reactApp/modules/uploading/uploading.types';
import { sendXray } from 'reactApp/utils/ga';

enum EUploadingStatus {
    empty,
    // Получение списка дескрипторов и отображение их в UI
    processingQueue,
}

/*   Хранилище данных и загрузчик (не храним в сторе чтобы не нагружать его (тысячи файлов и ссылка на их контент))
 * uploadQueue - очередь на загрузку
 * uploadingQueue - список файлов текущей загрузки
 */
class UploadingService {
    private descriptors: Record<string, UploadingDescriptor> = {};
    private packets: Record<string, UploadingPacketConfig> = {};

    private folders: Record<string, UploadingDescriptor> = {};

    private canceledIds = new Set<string>();

    // Общая очередь, куда пихаются все дескрипторы
    public queueForUpload: string[] = [];
    // Эти дескрипторы находятся в процессе загрузки
    public uploadingQueue: string[] = [];

    public _isActive = false;
    public isUserCanceled = false;

    public uploadPacketSpeedMbytesSec = DEFAULT_FILE_UPLOAD_SPEED;

    startedProcessingIds: number[] = [];

    status: EUploadingStatus = EUploadingStatus.empty;
    private failed = 0;
    private uploaded = 0;
    private processed = 0;

    getDescriptorById = (descriptorId: string) => this.descriptors[descriptorId];

    public startProcessing(config: UploadingPacketConfig) {
        this.packets[config.packetId] = config;
        this.startedProcessingIds.push(config.packetId);
        this.status = EUploadingStatus.processingQueue;
        this.isUserCanceled = false;
    }

    // Stop - значит все дескрипторы созданы и пересланы в UI
    public stopProcessing(id: number) {
        this.startedProcessingIds = this.startedProcessingIds.filter((item) => item !== id);
        if (!this.packets[id]) {
            return;
        }
        this.packets[id].isProcessed = true;
    }

    conflictResolution: EConflictResolution | null = null;

    public addToQueue = (items: UploadingDescriptor | UploadingDescriptor[]) => {
        const itemsArray = Array.isArray(items) ? items : [items];
        itemsArray.forEach((descriptor) => {
            this.descriptors[descriptor.id] = descriptor;
            this.queueForUpload.push(descriptor.id);
        });
    };

    // Остановка загрузок и очистка всех очередей и списков
    public resetUploadingService = () => {
        this.queueForUpload = [];
        this.uploadingQueue = [];

        this.descriptors = {};
        this.packets = {};
        this.folders = {};

        this.canceledIds = new Set<string>();

        this.startedProcessingIds = [];
        this.status = EUploadingStatus.empty;
        this.uploaded = 0;
        this.failed = 0;
        this.processed = 0;

        this.conflictResolution = null;

        this.isUserCanceled = false;
    };

    public getDescriptorsForUpload = (): UploadingDescriptor[] => {
        const numOfFreeUploadSlots = MAX_PARALLEL_UPLOAD_COUNT - this.uploadingQueue.length;

        if (numOfFreeUploadSlots <= 0 || this.isUserCanceled) {
            return [];
        }

        const result: UploadingDescriptor[] = [];

        for (const descriptorId of this.queueForUpload) {
            const descriptor = this.getDescriptorById(descriptorId);
            if (descriptor.hasCanceledParent()) {
                descriptor.state = EUploadingState.STATE_CANCEL;
                this.canceledIds.add(descriptor.id);
                continue;
            }
            if (
                this.uploadingQueue.includes(descriptor.id) ||
                descriptor.uploadingPacketConfig.makeAction === EUploadingMakeAction.stopAndWaitUser ||
                descriptor.uploadingPacketConfig.makeAction === EUploadingMakeAction.cancel
            ) {
                continue;
            }
            if (descriptor.state === EUploadingState.STATE_PENDING || descriptor.state === EUploadingState.STATE_SUSPENDED) {
                result.push(descriptor);
            }

            if (result.length >= numOfFreeUploadSlots) {
                break;
            }
        }

        return result;
    };

    public getDescriptorsOverLimit = (size: number) => {
        const result: UploadingDescriptor[] = [];

        for (const descriptorId of this.queueForUpload) {
            const descriptor = this.getDescriptorById(descriptorId);
            if (descriptor.size > size) {
                result.push(descriptor);
            }
        }

        return result;
    };

    public getPacketDescriptors = (packetId: number) => {
        const res: UploadingDescriptor[] = [];
        Object.values(this.descriptors).forEach((descriptor) => {
            if (descriptor.uploadingPacketConfig.packetId === packetId) {
                res.push(descriptor);
            }
        });
        return res;
    };

    public stopUploading(descriptor: UploadingDescriptor) {
        this.uploadingQueue = this.uploadingQueue.filter((id) => descriptor.id !== id);

        if (descriptor.state === EUploadingState.STATE_DONE) {
            this.uploaded++;
        } else if (descriptor.state === EUploadingState.STATE_FAIL) {
            this.failed++;
        } else if (descriptor.state === EUploadingState.STATE_CANCEL) {
            this.canceledIds.add(descriptor.id);
        }

        if (descriptor.state !== EUploadingState.STATE_CANCEL) {
            this.processed++;
        }
    }

    private cancelDescriptorAndChildren = (descriptor: UploadingDescriptor, reason: UploadingReason) => {
        let canceled = 0;
        const hasCancelableState = descriptor.hasCancelableState();

        if (hasCancelableState) {
            this.cancelDescriptor(descriptor);
            canceled++;

            for (const child of descriptor.children) {
                canceled += this.cancelDescriptorAndChildren(child, reason);
            }
        }

        return canceled;
    };

    public cancelAll() {
        let canceled = 0;
        const source = EUploadReasonSource.SOURCE_WEB_CLIENT;
        const stack = new Error('UploadingCancel');
        const reason = new UploadingCancel(stack, source);

        reason.radarName = ''; // prevent radar sent

        this.isUserCanceled = true;

        for (const id of this.queueForUpload) {
            const descriptor = this.getDescriptorById(id);
            canceled += this.cancelDescriptorAndChildren(descriptor, reason);
        }

        /**
         * При отмене всех загрузок сразу чистим Idb хранилище
         *
         * Точно такая же реаизация есть в попапе продолжение
         * загрузки в экшене @{clearContinueUploadFilesAction}
         */
        clear().catch(() => {
            sendXray(['uploader', 'idb-keyval', 'clear', 'error']);
        });

        return {
            canceled,
        };
    }

    public cancel(descriptorId: string | string[]) {
        const descriptors = Array.isArray(descriptorId) ? descriptorId : [descriptorId];
        for (const id of descriptors) {
            const descriptor = this.getDescriptorById(id);
            const source = EUploadReasonSource.SOURCE_WEB_CLIENT;
            const stack = new Error('UploadingCancel');
            const reason = new UploadingCancel(stack, source);

            this.cancelDescriptor(descriptor, reason);
        }
    }

    public postpone(id) {
        const descriptor = this.getDescriptorById(id);
        if (descriptor) {
            descriptor.state = EUploadingState.STATE_PENDING;
            this.uploadingQueue = this.uploadingQueue.filter((id) => descriptor.id !== id);
        }
    }

    public cancelFolder(localName: string) {
        this.cancelDescriptor(this.folders[localName]);

        for (const id of this.uploadingQueue) {
            const descriptor = this.getDescriptorById(id);
            if (descriptor?.hasCanceledParent()) {
                this.cancelDescriptor(descriptor);
            }
        }
    }

    private cancelDescriptor(descriptor?: UploadingDescriptor, reason?: UploadingReason) {
        if (!descriptor) {
            return;
        }

        const hasCancelableState = descriptor.hasCancelableState();

        if (hasCancelableState) {
            const uploader = descriptor.uploader;

            if (uploader) {
                uploader.cancel();
            } else {
                if (reason === undefined) {
                    const stack = new Error('UploadingCancel');

                    reason = new UploadingCancel(stack, EUploadReasonSource.SOURCE_WEB_CLIENT);
                } else if (!(reason instanceof UploadingCancel)) {
                    throw new TypeError('reason not cancel');
                }

                descriptor.setErrorState(reason, EUploadingState.STATE_CANCEL);
            }

            if (descriptor.isFile && descriptor.state !== EUploadingState.STATE_UPLOADING) {
                this.canceledIds.add(descriptor.id);
            }
        }
    }

    public clearUploadedQueue = () => this.resetUploadingService();

    public getAllPackets = (skipUnprocessed = false) =>
        (Object.keys(this.packets).map((key) => this.packets[key]) as UploadingPacketConfig[]).filter(
            (cfg) => !skipUnprocessed || cfg.isProcessed
        ) ?? [];

    public addFolders(folders: UploadingDescriptor[]) {
        folders.forEach((folder) => {
            this.folders[folder.localName] = folder;
        });
    }

    public getRootFolders = (packetId: number) => {
        const folders = this.getPacketDescriptors(packetId).filter((descriptor) => descriptor.isDirectory);

        const set = new Set<UploadingDescriptor>();

        for (const folder of folders) {
            if (!folder.parent && !set.has(folder)) {
                set.add(folder);
                continue;
            }
            if (folder.parent && !set.has(folder.parent) && !folder.parent.parent) {
                if (!folder.parent.cloudPath) {
                    folder.parent.cloudPath = joinPath(folder.parent.uploadingPacketConfig.workingDirectory, folder.parent.localPath);
                    folder.parent.cloudName = getNameById(folder.parent.cloudPath);
                }

                set.add(folder.parent);
            }
        }

        return Array.from(set.values());
    };

    public getProgressStatus() {
        const total = this.queueForUpload.length;
        const finished = this.processed + this.canceledIds.size;
        const data = {
            progressTotalPercent: 0,
            inProgress: total - finished,
            finished,
            canceled: this.canceledIds.size,
            uploaded: this.uploaded,
            failed: this.failed,
            total,
        };

        data.finished = data.total - data.inProgress;
        data.progressTotalPercent = Math.round((data.finished * 100) / data.total);

        return data;
    }
}

export const uploadingService = new UploadingService();
