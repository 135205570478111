import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFallbackSearchUrl } from 'reactApp/hooks/useFallbackSearchUrl';
import { AttachesSelectors } from 'reactApp/modules/attaches/attaches.selectors';
import { getExtension, getItemNameWithoutExt, getNameById } from 'reactApp/modules/file/utils';
import { historyPush } from 'reactApp/modules/router/router.module';
import { getSearchRequestParams } from 'reactApp/modules/search/search.selectors';
import { EActionSearch } from 'reactApp/modules/search/search.types';
import { resetSelect } from 'reactApp/modules/selections/selections.actions';
import { setQueryParams, sidebarToggle } from 'reactApp/modules/settings/settings.module';
import { getIdByStorage, getStorage } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { RootState } from 'reactApp/store';
import { EHeaderColor } from 'reactApp/ui/Header/HeaderWrapper';
import { Logo } from 'reactApp/ui/Header/Logo/Logo';
import { NameComponent } from 'reactApp/ui/Name/Name';
import { ToolbarItem } from 'reactApp/ui/Toolbar/ToolbarItem/ToolbarItem';
import { ChevronLeftOutlineIcon, Icon20MenuOutline } from 'reactApp/ui/VKUIIcons';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import styles from './Header.css';
import { useShowBackItem } from './Header.hooks';

interface Props {
    isSearchExpanded: boolean;
    toggleSearch: (state: boolean) => void;
    storage: EStorageType;
    isResponsiveEnable: boolean;
    openedFileId?: string;
    hideLogo?: boolean;

    onBackClick?(): void;

    color?: EHeaderColor;
}

export const LeftSide = memo(
    ({ isSearchExpanded, toggleSearch, storage, isResponsiveEnable, openedFileId, onBackClick, color, hideLogo }: Props): ReactElement => {
        const dispatch = useDispatch();

        const { isAttaches, isSearch } = getStorage(storage);
        const showBackItem = useShowBackItem();
        const { closeSearch } = useFallbackSearchUrl();
        const searchRequestParams = useSelector(getSearchRequestParams);
        const attachesSearch = useSelector((state: RootState) => AttachesSelectors.getSearchState(state));

        const cancelSearch = useCallback(() => {
            dispatch(setQueryParams());
            let dwhData: {
                place: string;
                search_phrase?: string;
                page_id?: string;
                req_id?: string;
            } = { place: 'web' };
            if ((isAttaches && attachesSearch) || isSearch) {
                dwhData = {
                    ...dwhData,
                    search_phrase: attachesSearch ? attachesSearch.query : searchRequestParams.query,
                    page_id: searchRequestParams.xPageId,
                    req_id: searchRequestParams.xReqId,
                };
            }
            sendDwh({
                eventCategory: ECategoryGa.search,
                action: EActionSearch.searchClose,
                dwhData,
            });
            if (isAttaches) {
                dispatch(historyPush({ id: getIdByStorage(EStorageType.attaches) }));
            } else {
                closeSearch();
            }

            toggleSearch(false);
        }, [isAttaches, toggleSearch, dispatch, closeSearch, isSearch, searchRequestParams, attachesSearch]);

        const onSidebarTogglerClick = useCallback(() => {
            dispatch(sidebarToggle());
        }, [dispatch]);

        const handleBackClick = useCallback(() => {
            onBackClick?.();
            dispatch(resetSelect());
        }, [dispatch]);

        const content = useMemo(() => {
            if (openedFileId) {
                const fileName = getNameById(openedFileId);
                const extension = getExtension(fileName);
                const name = getItemNameWithoutExt(fileName, extension);

                return (
                    <>
                        <ToolbarItem
                            className={styles.backButton}
                            id="back"
                            text="Вернуться"
                            icon={<ChevronLeftOutlineIcon />}
                            onClick={onBackClick}
                            color={color}
                        />
                        <div className={styles.nameWrapper}>
                            <NameComponent className={styles.name} name={name} extension={extension} showTooltip />
                        </div>
                    </>
                );
            }

            if (showBackItem) {
                return (
                    <ToolbarItem
                        id="back"
                        text="Вернуться"
                        collapse={isResponsiveEnable}
                        icon={<ChevronLeftOutlineIcon />}
                        onClick={handleBackClick}
                    />
                );
            }

            if (isSearchExpanded) {
                return (
                    <ToolbarItem
                        id="closeSearch"
                        text="Сбросить поиск"
                        collapse={isResponsiveEnable}
                        icon={<ChevronLeftOutlineIcon />}
                        onClick={cancelSearch}
                    />
                );
            }

            if (isResponsiveEnable) {
                const isNotPublic = storage !== EStorageType.public;
                return (
                    (isNotPublic && <ToolbarItem id="sidebarToggler" icon={<Icon20MenuOutline />} onClick={onSidebarTogglerClick} />) ||
                    null
                );
            }

            if (hideLogo) {
                return null;
            }

            return <Logo white={color === EHeaderColor.DARK} />;
        }, [cancelSearch, isSearchExpanded, showBackItem, isResponsiveEnable, onSidebarTogglerClick, storage, handleBackClick, color]);

        return (
            <div
                className={classNames({
                    [styles.leftSide]: true,
                    [styles.leftSideMyTeam]: isResponsiveEnable,
                })}
            >
                {content}
            </div>
        );
    }
);

LeftSide.displayName = 'LeftSide';
