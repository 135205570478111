/* eslint-disable object-shorthand */
/* eslint-disable max-lines */
/* eslint-disable no-var */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
define(function () {
    const MicroEvent = require('mrg-microevent');
    const throttle = require('lodash.throttle');
    const { extInfo } = require('lib/extInfo');
    const { Uflr } = require('lib/uflr');
    const store = require('store');
    const escapeAdblock = require('lib/escapeAdblock').default;
    const { store: reduxStore } = require('reactApp/store');
    const { setTreeAction } = require('reactApp/modules/tree/tree.actions');
    const { updateItem } = require('reactApp/modules/modifying/modifying.actions');
    const { setSort: setSortAction } = require('reactApp/modules/sort/sort.module');
    const { EStorageType } = require('reactApp/modules/storage/storage.types');
    const { getCurrentStorage } = require('reactApp/modules/router/router.selectors');
    const { itemV4ToV2 } = require('reactApp/api/helpers/apiV4Helpers');
    const _ = require('Cloud/Application/_');
    const { getStorage } = require('reactApp/modules/storage/storage.helpers');
    const { getStorageItemById } = require('reactApp/modules/storage/storage.selectors');
    const { IS_PUBLIC, IS_PUBLIC_FOLDER, IS_REACT_PAGE } = require('reactApp/appHelpers/configHelpers');

    const { pathOr } = require('ramda');
    const { showcaseController } = require('reactApp/ui/Showcase/Showcase.controller');
    const { loadPublicFileSuccess } = require('reactApp/modules/public/public.actions');
    const { user } = require('Cloud/Application/User');
    const { isFeature } = require('Cloud/Application/FeaturesEs6');

    let app;

    const Tree = function (config, init_revision, api) {
        var data = {};

        var tree_revision = init_revision;
        var enableCache = config.settings.cache;
        var rootTitle = config.title;
        var supportRevisions = config.settings.revisions;
        var supportHierarchy = config.settings.hierarchy;
        var HAS_FOLDER_SIZE_INFO = void 0;
        var IS_TRASHBIN = config.id === 'trashbin';
        var IS_SEARCH = config.id === 'search';
        var IS_VIRUS_TEST = isFeature('virus-test');
        var IS_UFLR_TEST = isFeature('uflr-test');

        function _normalizeId(id) {
            return (id && id.replace(/\/{2,}/g, '/').replace(/\/$/, '')) || '/';
        }

        function renameCameraUploads({ folder, siblings }) {
            if (
                folder.name.toLowerCase() !== 'camera uploads' ||
                siblings.some((item) => item.name.toLowerCase() === 'фотографии с телефона')
            ) {
                return folder;
            }

            return {
                ...folder,
                name: 'Фотографии с телефона',
            };
        }

        var _getId = function (item) {
            return item.id;
        };
        var _name;
        var _escapeName;
        var _additionNormalize;
        var _additionSort;

        /* regenerate(
		 0x2028,// LINE SEPARATOR
		 0x2029,// PARAGRAPH SEPARATOR
		 //http://www.fileformat.info/info/unicode/category/Cf/list.htm :
		 0x200E, 0x200F, 0x202A, 0x202B, 0x202C, 0x202D, 0x202E, 0x2060, 0x2061,
		 0x2062, 0x2063, 0x2064, 0x2066, 0x2067, 0x2068, 0x2069, 0x206A, 0x206B, 0x206C, 0x206D, 0x206E, 0x206F,
		 0xFEFF, 0xFFF9, 0xFFFA, 0xFFFB, 0x110BD, 0x1BCA0, 0x1BCA1, 0x1BCA2, 0x1BCA3, 0x1D173, 0x1D174, 0x1D175,
		 0x1D176, 0x1D177, 0x1D178, 0x1D179, 0x1D17A

		 ).toString();
		 */
        var RE_FUC =
            /([\u200E\u200F\u2028-\u202E\u2064\u2066-\u206F\uFEFF\uFFF9-\uFFFB]|\uD804\uDCBD|\uD82F[\uDCA0-\uDCA3]|\uD834[\uDD73-\uDD7A])/gi;
        var RE_ONLY_TEXT_DIRECTION = /([\u202E\u202D])/gi;

        var _prev__normalizeId, _replaceRestrictedSymbols;
        if (IS_PUBLIC) {
            _replaceRestrictedSymbols = function (str) {
                // CLOUDWEB-4536
                return str.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
            };

            _escapeName = function (name) {
                name = name || '';
                return _replaceRestrictedSymbols(
                    name.replace(RE_FUC, function (str, character) {
                        // CLOUDWEB-2797
                        return '{' + character.charCodeAt(0) + '}';
                    })
                );
            };

            _prev__normalizeId = _normalizeId;
            _normalizeId = function (id) {
                return _replaceRestrictedSymbols(_prev__normalizeId(id));
            };
        } else {
            _escapeName = function (name) {
                name = name || '';
                return name.replace(RE_ONLY_TEXT_DIRECTION, function (str, character) {
                    // CLOUDWEB-4987
                    return '{' + character.charCodeAt(0) + '}';
                });
            };
        }

        _name = function (item) {
            const name = item.name || _normalizeId(item.id).split('/').pop();
            return escapeAdblock(_escapeName(name));
        };

        if (api.v2) {
            switch (config.id) {
                case 'static':
                case 'subscriptions':
                case 'family':
                    break;

                case EStorageType.home:
                case EStorageType.office:
                case EStorageType.r7:
                case EStorageType.myoffice:
                case EStorageType.myofficeAttaches:
                case EStorageType.favorites:
                case EStorageType.recommend:
                case EStorageType.feed:
                case EStorageType.alldocuments:
                case EStorageType.start:
                case EStorageType.gallery:
                case EStorageType.documents:
                case EStorageType.albums:
                case EStorageType.pdfEdit:
                case EStorageType.integration:
                case EStorageType.inlineIntegration:
                case EStorageType.r7wopi:
                    _getId = function (item) {
                        return item && (item.id || item.home);
                    };
                    _additionNormalize = function (item) {
                        if (item.id) {
                            if (!item.home) {
                                item.home = item.id;
                            }
                        } else {
                            item.id = item.home;
                        }
                    };
                    break;

                case 'search':
                    _getId = function (item) {
                        item = item == null ? {} : item;

                        return item.id || item.home || '';
                    };

                    _additionNormalize = function (item) {
                        item.absoluteFolderPath = buildAbsoluteFolderPath(item);

                        if (item && item.name_parts) {
                            item.nameParts = buildNameParts(item);
                            item.name_parts = null;
                        }
                    };

                    break;

                case 'trashbin':
                    _getId = function (item) {
                        return item.id || (item.name == '/' ? item.name : item.rev + ':' + item.deleted_from + item.name);
                    };

                    _additionNormalize = function () {};

                    break;

                case 'shared/links':
                case 'shared/autodelete':
                    _getId = function (item) {
                        return item.id || item.home;
                    };

                    _name = function (item) {
                        const name = _normalizeId(item.name || item.home)
                            .split('/')
                            .pop();
                        return escapeAdblock(name);
                    };

                    _additionNormalize = function (item) {
                        if (item.kind != 'storage') {
                            item.type = item.type || item.kind == 'shared' ? 'folder' : '__unknown';
                        }
                    };
                    break;

                case 'links':
                    _additionNormalize = function (item) {
                        if (item.count) {
                            // cloudweb-2760
                            item.count = {
                                folders: 0,
                                files: 0,
                            };
                        }
                    };
                    _getId = function (item) {
                        return item.id || item.weblink;
                    };
                    break;

                case 'public':
                case 'embedded':
                    _additionNormalize = function (item) {
                        if (item.weblink && item.home) {
                            // cloudweb-2760
                            item.own = true;
                        }

                        if (item.grev && !item.rev) {
                            item.rev = item.grev;
                        }
                    };
                    _getId = function (item) {
                        return item.id || item.weblink;
                    };
                    break;

                case 'files':
                    _getId = function (item) {
                        return item.id || item.files;
                    };
                    break;

                case 'stock':
                case 'attaches':
                    _getId = function (item) {
                        return item.stock || item.bundle || item.id;
                    };
                    break;

                case 'shared/incoming':
                    _getId = function (item) {
                        return item.id || item.tree;
                    };

                    _name = function (item) {
                        const name = _normalizeId(item.name).split('/').pop();
                        return escapeAdblock(name);
                    };

                    var ownerEmail = user.getEmail();

                    _additionNormalize = function (item) {
                        if (item.kind != 'storage') {
                            item.isOwner = item.owner && ownerEmail === item.owner.email;
                            item.type = 'folder';
                            item.kind = item.invite_token ? 'invites' : 'mounted';
                        }
                    };

                    _additionSort = function (itemA, itemB, result) {
                        var kindA = itemA.kind;
                        var kindB = itemB.kind;

                        if (kindA != kindB) {
                            result = kindA == 'invites' ? -1 : 1;
                        }

                        return result;
                    };
                    break;

                default:
                    throw new Error('Undefined storage "' + config.id + '"');
            }
        }

        function _dataId(item) {
            var id;

            if (typeof item == 'object' && item) {
                id = _getId(item);
            } else {
                id = item;
            }

            return (id || '').toLowerCase();
        }

        function buildAbsoluteFolderPath(item) {
            var lastIndex;
            var home;
            var name;

            if (!item || !item.name || !item.home) {
                return '';
            }

            home = item.home || '';
            name = item.name || '';

            // name = 'some_test_name.ext';
            // home = '/some-folder/some_test_name.ext';
            lastIndex = home.lastIndexOf('/' + name);
            lastIndex = lastIndex !== -1 ? lastIndex : 0;

            return home.slice(0, lastIndex) || '/';
        }

        function buildNameParts(item) {
            var nameParts;

            if (!item || !item.name_parts) {
                return [];
            }

            nameParts = Array.isArray(item.name_parts) ? item.name_parts : [];

            return nameParts.map(function (part) {
                return {
                    hl: part[1],
                    val: part[0],
                };
            });
        }

        function _createNewFolderItem(folder, parent) {
            folder = folder || {};

            var newFolderItem = {
                id: folder.id,
                name: _name(folder),
                __files: [],
                __folders: [],
                __allItems: [],
                count: {
                    files: 0,
                    folders: 0,
                },
                kind: folder.kind || 'folder',
                parent: parent,
            };

            if (folder.info) {
                newFolderItem.info = folder.info;
            }
            if (folder.home) {
                newFolderItem.home = folder.home;
            }
            if (folder.weblink) {
                newFolderItem.weblink = folder.weblink;
            }
            if (folder.type) {
                newFolderItem.type = folder.type;
            }
            if (HAS_FOLDER_SIZE_INFO) {
                if (folder.size !== void 0) {
                    newFolderItem.size = folder.size;
                } else {
                    newFolderItem.size = 0;
                }
            }
            return newFolderItem;
        }

        function _setItem(id, item) {
            data[id.toLowerCase()] = item;
        }

        function _getItem(item, checkCache) {
            if (item && item.__reduxTree) {
                item = item.id;
            }

            if (typeof item === 'object' && item) {
                if (checkCache) {
                    item = data[_dataId(item)];
                }
                return item;
            }

            if (typeof item === 'string') {
                return data[(item || '').toLowerCase()];
            }

            return void 0;
        }

        function _removeItem(id) {
            delete data[id.toLowerCase()];
        }

        function setRevisionToFolder(id, value, wholeData) {
            if (enableCache) {
                var storage = _getItem(id);

                if (!storage.__revisions) {
                    storage.__revisions = {
                        only_sub_folders: null,
                        whole_data: null,
                    };
                }

                storage.__revisions[wholeData ? 'whole_data' : 'only_sub_folders'] = supportRevisions ? value : true;
            }
        }

        var previousItem = enableCache ? true : void 0;

        function isCurrentFolderLoaded(item, wholeData, sort) {
            item = _getItem(item);

            if (!enableCache || !previousItem) {
                return item && previousItem && _getItem(previousItem) == item;
            } else {
                item = _getItem(item);

                // is folder loaded for revision
                if (item.__revisions === undefined) {
                    return false;
                }

                var revision = item.__revisions[wholeData ? 'whole_data' : 'only_sub_folders'];
                var result = false;

                switch (true) {
                    case !supportRevisions:
                        result = revision ? true : false;
                        break;

                    case api.v2:
                        result = revision === item.rev;
                        break;
                }

                if (result && sort) {
                    result = item.sort && sort.type == item.sort.type && sort.order == item.sort.order;
                }

                return result;
            }
        }

        function _getFolderId(item) {
            var cacheItem = _getItem(item, true);
            if (cacheItem) {
                return isFolder(cacheItem) ? cacheItem.id : cacheItem.parent;
            } else {
                var id;
                if (typeof item === 'object' && item) {
                    id = _getId(item);
                } else {
                    id = item;
                }

                if (!id) {
                    throw new Error('Wrong item');
                }

                if (id.charAt(id.length - 1) == '/') {
                    // is folder
                    return id;
                } else {
                    return getParentId(id);
                }
            }
        }

        function has(item, parent) {
            item = _getItem(item, true);

            if (parent) {
                parent = _getItem(parent);

                if (!item || !parent) {
                    return false;
                }

                var itemId = _getId(item);
                var parentId = _getId(parent);

                if (itemId === parentId) {
                    return item !== void 0;
                } else if (parent !== void 0) {
                    parentId = _dataId(_getFolderId(parent));

                    return data[parentId].__files.includes(itemId) || data[parentId].__folders.includes(itemId);
                } else {
                    return false;
                }
            }

            return item !== void 0;
        }

        function changeParentCount(ids, delta) {
            if (!ids) {
                return this;
            }

            if (!(ids instanceof Array)) {
                ids = [ids];
            }

            this.emit('beforeChangeCount', ids, delta);

            for (var i = 0, len = ids.length, _id; i < len; i++) {
                _id = ids[i];

                var parentItem = _getItem(getParentId(_id));
                var field = isFolder(_id) ? 'folders' : 'files';

                if (parentItem != null) {
                    parentItem.localRev++;
                    parentItem.count[field] += delta;
                }
            }

            this.emit('changeCount', ids);

            return this;
        }

        function getParentId(item) {
            var isId = typeof item === 'string';
            var parent;

            if (!supportHierarchy) {
                parent = '/';
            } else {
                var _item = isId && _getItem(item);
                if (_item) {
                    item = _item;
                    isId = false;
                }
                parent = !isId && _item && (item.parent || item.folder);
            }

            if (!parent) {
                var split = (isId ? item : _getId(item) || '').split(/\//);

                if (!split[0]) {
                    split.pop();
                    parent = split.join('/') || '/';
                } else {
                    if (split.length == 2) {
                        parent = '/';
                    } else {
                        split.pop();
                        parent = split.join('/');
                    }
                }
            }

            return _normalizeId(parent);
        }

        var RE_IS_FOLDER = /^(folder|storage|camera-upload|shared|mounted|invites|domain-folder)$/;

        function isFolder(item) {
            item = _getItem(item);
            return item && (typeof item.isFolder === 'boolean' ? item.isFolder : RE_IS_FOLDER.test(item.kind));
        }

        function isDomainFolder(item) {
            item = _getItem(item);
            return item && isFolder(item) && item.kind == 'domain-folder';
        }

        function isFile(item) {
            item = _getItem(item);
            return item && !isFolder(item);
        }

        function isVirus(item) {
            item = _getItem(item);
            return item && item.virus_scan === 'fail';
        }

        function isNotVirus(item) {
            item = _getItem(item);
            return item && item.virus_scan === 'pass';
        }

        function isVirusSkip(item) {
            item = _getItem(item);
            return item && item.virus_scan === 'skip';
        }

        function isCameraFolder(item) {
            item = _getItem(item);

            return has(item) && item.kind === 'camera-upload';
        }

        function isMounted(item) {
            item = _getItem(item);

            return item && item.kind === 'mounted';
        }

        function isShared(item) {
            item = _getItem(item);

            return item && item.kind === 'shared';
        }

        function isRoot(item) {
            item = _getItem(item);

            return item && item.id === '/' ? true : false;
        }

        var DEFAULT_ASC_SORT = {
            type: 'name',
            order: 'asc',
        };
        var DEFAULT_CA_SORT = {
            type: 'mtime',
            order: 'desc',
        };

        var DEFAULT_SORT = {
            type: null,
        };

        var itemsSort;

        if (IS_TRASHBIN || IS_SEARCH) {
            itemsSort = DEFAULT_SORT;
        } else {
            itemsSort = store.get(user.getEmail() + '|itemsSort') || {};
        }

        function setSort(item, sort) {
            const state = reduxStore.getState();
            const storage = getCurrentStorage(state);
            const reduxItem = getStorageItemById(state, storage, item.id);
            item = reduxItem || _getItem(_getFolderId(item));

            if (!item) {
                return;
            }

            var id = _getId(item);
            var defaultSort = isCameraFolder(item) ? DEFAULT_CA_SORT : IS_TRASHBIN || IS_SEARCH ? DEFAULT_SORT : DEFAULT_ASC_SORT;
            if (!sort || (sort.type == defaultSort.type && sort.order == defaultSort.order)) {
                previousItem = void 0;
                delete itemsSort[id];
            } else {
                itemsSort[id] = sort;
                if (!item.sort || item.sort.type !== sort.type || item.sort.order !== sort.order) {
                    item.sort = sort;
                    previousItem = void 0;
                }
            }

            reduxStore.dispatch(setSortAction({ sort, id }));

            store.set(user.getEmail() + '|itemsSort', itemsSort);
        }

        function getSort(item) {
            var sortFromUrl = app.settings.request.query_params.sort;

            if (sortFromUrl) {
                sortFromUrl = sortFromUrl.split('-');

                sortFromUrl = {
                    order: sortFromUrl[1],
                    type: sortFromUrl[0],
                };
            }

            item = _getItem(_getFolderId(item));

            return (
                sortFromUrl ||
                (item && itemsSort[_getId(item)]) ||
                (isCameraFolder(item) ? DEFAULT_CA_SORT : IS_TRASHBIN || IS_SEARCH ? DEFAULT_SORT : DEFAULT_ASC_SORT)
            );
        }

        function sortParentItems(parent) {
            parent = _getItem(parent);
            var sort = getSort(parent);
            var order = sort.order == 'desc' ? 1 : -1;

            var _sortItemsCallback = function (a, b) {
                var result;

                a = _dataId(a);
                b = _dataId(b);

                var itemA = data[a],
                    itemB = data[b];
                var propertyA, propertyB;
                var sortType = sort.type;

                if (sortType == 'size' && itemA.size == null) {
                    sortType = 'name';
                }

                switch (sortType) {
                    case 'mtime':
                        propertyA = itemA.mtime;
                        propertyB = itemB.mtime;

                        break;

                    case 'size':
                        propertyA = itemA.size;
                        propertyB = itemB.size;

                        break;

                    case 'name':
                    default:
                        propertyA = (itemA.name || '').toLowerCase();
                        propertyB = (itemB.name || '').toLowerCase();

                        break;
                }

                result = propertyA == propertyB ? 0 : (propertyA < propertyB ? 1 : -1) * order;

                if (_additionSort) {
                    result = _additionSort(itemA, itemB, result);
                }

                return result;
            };

            if (sort.type) {
                parent.__files = parent.__files && parent.__files.sort(_sortItemsCallback);
                parent.__folders = parent.__folders && parent.__folders.sort(_sortItemsCallback);
                parent.__allItems = parent.__allItems && parent.__allItems.sort(_sortItemsCallback);
            }
        }

        function initRoot() {
            data['/'] = {
                kind: 'storage',
                name: rootTitle,
                __files: [],
                __folders: [],
                __allItems: [],
                id: '/',
                sort: IS_TRASHBIN || IS_SEARCH ? DEFAULT_SORT : DEFAULT_ASC_SORT,
            };

            _normalize('/');
        }

        function getExtension(item) {
            var name = item.name;
            var index = name.lastIndexOf('.');

            return index !== -1 ? name.substring(index + 1) : 'unknown';
        }

        /**
         * Возвращает имя файла без расширения.
         * Имя файла без расширения часто использутеся в b-filename.
         * @private
         * @param {string} name – имя файла с расширением.
         * @param {string} ext – расширение файла без точки и в оригинальном регистре.
         * @returns {string} имя файла без расширения.
         */
        function _getItemNameWithoutExt(name, ext) {
            // TODO: сейчас верстка расценивает файл ".jpg",
            // как файл без имени с расширением jpg,
            // а сервер при переименовании файлов,
            // считает, что этот файл без расширения.
            var extLength = ext.length;

            if (extLength && name.lastIndexOf(ext) == name.length - extLength) {
                name = name.slice(0, -extLength - 1);
            }

            return name;
        }

        function setAuthors(list) {
            const email = user.getEmail();

            return list.reduce((authors, child) => {
                const authorEmail = pathOr(IS_PUBLIC ? null : email, ['attr', 'actor'], child);
                if (authorEmail && !authors.includes(authorEmail)) {
                    return [...authors, authorEmail];
                }
                return authors;
            }, []);
        }

        function overrideVirusStatusForTest(item) {
            if (item) {
                var name = item.name;

                if (typeof name == 'string') {
                    var prefix = name.substr(0, 10);

                    switch (prefix) {
                        case 'virus-pass':
                            item.virus_scan = 'pass';
                            break;

                        case 'virus-fail':
                            item.virus_scan = 'fail';
                            break;

                        case 'virus-nyet':
                            item.virus_scan = 'not_yet';
                            break;

                        case 'virus-skip':
                            item.virus_scan = 'skip';
                            break;
                    }
                }
            }
        }

        function overrideUflrStatusForTest(item) {
            if (item) {
                var name = item.name;

                if (typeof name == 'string') {
                    var prefix = name.substr(0, 9);

                    switch (prefix) {
                        case '!blocked-':
                            item.uflr = 'blocked';
                            break;

                        case '!illegal-':
                            item.uflr = 'illegal';
                            break;
                    }
                }
            }
        }

        function _normalize(item, force) {
            item = _getItem(item);

            if (item.__isItem && !force) {
                return item;
            }

            if (!item.id) {
                item.id = _normalizeId(_getId(item));
            }

            if (_additionNormalize) {
                _additionNormalize(item);
            }

            if (IS_VIRUS_TEST) {
                overrideVirusStatusForTest(item);
            }

            if (IS_UFLR_TEST) {
                overrideUflrStatusForTest(item);
            }

            item.isFolder = isFolder(item);
            item.isDomainFolder = isDomainFolder(item);
            if (item.favorite) {
                item.isInFavorites = true;
                delete item.favorite;
            }

            if (item.isFolder && item.list && item.list.length) {
                item.authors = setAuthors(item.list);
            }

            if (!item.isFolder && item.virus_scan !== 'fail') {
                item.virus_scan = 'skip';
            }

            item.virusStatus = isVirus(item) ? true : isVirusSkip(item) ? null : false;

            if (item.isFolder) {
                item.__files = item.__files || [];
                item.__folders = item.__folders || [];
                item.__allItems = item.__allItems || [];

                item.name = _name(item) || rootTitle; // корень как null приходит при апдейте
                item.nameWithoutExt = item.name;

                if (item.count === void 0) {
                    item.count = {
                        files: 0,
                        folders: 0,
                    };
                } else {
                    item.count.folders = item.count.folders || item.count.sub_folders || 0;
                }

                if (isVirus(item)) {
                    item.subKind = 'folder-virus';
                } else if (isMounted(item)) {
                    item.subKind = 'folder-mounted';
                } else if (isShared(item)) {
                    item.subKind = 'folder-shared';
                } else if (isDomainFolder(item)) {
                    item.subKind = 'folder-mounted';
                } else {
                    item.subKind = 'folder';
                }
            } else {
                item.name = _name(item);
                const extension = getExtension(item);
                item.ext2 = extension;
                item.ext = extension.toLowerCase();
                item.nameWithoutExt = _getItemNameWithoutExt(item.name, extension);

                item.absoluteFolderPath = buildAbsoluteFolderPath(item);

                {
                    // for b-filename
                    const filename = new String(item.name);

                    filename.name = _getItemNameWithoutExt(item.name, extension);
                    filename.extension = extension;

                    item.filename = filename;
                }

                var extData = extInfo.get(item.ext);
                item.subKind = extData.subKind;
                item.kind = extData.kind;

                if (isVirus(item)) {
                    item.subKind = 'virus';
                }

                if (item.ext === 'psd' || isVirus(item)) {
                    delete item.thumbnails;
                    item.kind = 'file';
                    //					item.ext = 'unknown';
                }

                delete item.__normalizeUrls;
            }

            if (!item.weblink && item.url && item.url.web) {
                item.weblink = decodeURIComponent(item.url.web.replace(/^\/public\//, ''));
                delete item.url.web;
            }

            item.__isItem = true;

            return item;
        }

        function __apiItem_isFolder(folder) {
            return folder && folder.list && folder.kind != 'file';
        }

        var tree = {
            init: function (folders) {
                folders = folders || [];

                this.emit('beforeInit', rootTitle, folders);

                initRoot();

                this.setTree(folders, tree_revision, true);

                this.emit('init', rootTitle, folders);

                return this;
            },

            setTree: function (folders, revision, wholeData) {
                if (folders.tree) {
                    if (HAS_FOLDER_SIZE_INFO === void 0 && folders.folder && folders.folder.size !== void 0) {
                        HAS_FOLDER_SIZE_INFO = true;
                    }

                    reduxStore.dispatch(setTreeAction({ tree: folders.tree }));

                    if (Array.isArray(folders.tree)) {
                        folders.tree.forEach(function (folder) {
                            if (__apiItem_isFolder(folder)) {
                                this.setFolder(folder, void 0, false);
                            }
                        }, this);
                    }
                } else if (Array.isArray(folders)) {
                    folders.forEach(function (folder) {
                        if (__apiItem_isFolder(folder)) {
                            this.setFolder(folder, revision, wholeData);
                        }
                    }, this);
                }

                if (__apiItem_isFolder(folders.folder)) {
                    const result = _.v4 && folders.folder ? itemV4ToV2(folders.folder, 'public') : folders.folder;
                    this.setFolder(result, void 0, true);
                }

                if (app.getParam('API_V4') && IS_PUBLIC && !IS_PUBLIC_FOLDER) {
                    const item = itemV4ToV2(folders);
                    reduxStore.dispatch(loadPublicFileSuccess(item));
                }
            },

            updateRevision: function (new_revision, changes) {
                this.emit('beforeUpdateRevision', new_revision, changes);

                if (Array.isArray(changes.visible_folders)) {
                    changes.visible_folders.forEach(
                        function (item) {
                            this.setFolder(item, new_revision, false);
                        }.bind(this)
                    );
                }

                if (changes.folder && changes.folder.id) {
                    this.setFolder(changes.folder, new_revision, true);
                }

                this.emit('updateRevision', new_revision, changes);

                tree_revision = new_revision;

                return this;
            },

            getRevision: function () {
                return tree_revision;
            },

            sortParentItems: function (ids) {
                if (!(ids instanceof Array)) {
                    ids = [ids];
                }

                this.emit('beforeSortParentItems', ids);

                var parents = {};

                ids.forEach(function (id) {
                    const item = _getItem(id);
                    if (item) {
                        parents[item.parent] = true;
                    }
                });

                Object.keys(parents).forEach(sortParentItems);

                this.emit('sortParentItems', ids);

                return this;
            },

            increaseParentCount: function (ids) {
                return changeParentCount.call(this, ids, 1);
            },

            decreaseParentCount: function (ids) {
                return changeParentCount.call(this, ids, -1);
            },

            getParentId: getParentId,

            setFolder: function (folder, folder_revision, wholeData, offset, dontSyncWithRedux = false) {
                folder.id = _normalizeId(_getId(folder));

                if (!folder.id) {
                    throw new Error('Bad folder');
                }

                delete folder.items; // Ненужное свойство, добавляется в fest/page/tree.js

                this.emit('beforeSetFolder', folder);

                var item = _getItem(folder.id);
                var prevFiles = item && item.__files;
                var prevFolders = item && item.__folders;
                var prevRev = item && item.rev;
                var needToNormalize = false;
                var parent;
                var parentItem;
                var newFolderItem;
                var details;
                var keysObj = {};
                var tree = this;

                if (!item) {
                    if (!showcaseController.isFolderForShowcase(item)) {
                        parent = tree.getParentId(folder);
                        parentItem = _getItem(parent);
                        newFolderItem = _createNewFolderItem(folder, parent);

                        if (parentItem) {
                            if (!folder.tree) {
                                folder.tree = parentItem.tree;
                            }
                            if (!folder.rev) {
                                folder.rev = parentItem.rev;
                            }

                            newFolderItem = renameCameraUploads({ folder: newFolderItem, siblings: app.folderItems(parentItem).items });
                        }

                        tree.setItem(newFolderItem, parent);
                        tree.updateAuthors(parent);

                        item = _getItem(folder.id);
                    }
                } else {
                    needToNormalize = false;

                    Object.keys(folder).forEach(function (key) {
                        if (!item.hasOwnProperty(key)) {
                            needToNormalize = true;
                            item[key] = folder[key];
                        }
                    });

                    if (needToNormalize) {
                        _normalize(item, true);
                    }
                }

                if (_additionNormalize) {
                    _additionNormalize(folder);
                }

                if (!item) {
                    const state = reduxStore.getState();
                    const storage = getCurrentStorage(state);
                    if (
                        [EStorageType.feed, EStorageType.gallery, EStorageType.favorites, EStorageType.start, EStorageType.public].includes(
                            storage
                        )
                    ) {
                        // забиваем на старое дерево тут, все данные в сторе.
                        // в хомяке при заходе в эту папку все подгрузится из апи.
                        return tree;
                    }
                }

                if (api.v2) {
                    item.rev = folder.rev || +new Date();
                    item.tree = folder.tree;
                    item.localRev = item.rev;
                }

                if (folder.rev || folder_revision || enableCache) {
                    setRevisionToFolder(folder.id, folder.rev || folder_revision, wholeData);
                }

                if (folder.count) {
                    item.count = {
                        folders: folder.count.folders || folder.count.sub_folders || 0,
                        files: folder.count.files,
                        uflr: 0,
                    };
                }

                details = {
                    addedItems: [],
                    removedItems: [],
                    updatedItems: [],
                    oldRev: prevRev,
                    newRev: item.rev,
                };

                if (folder.list) {
                    if (!offset) {
                        item.__allItems = [];
                        item.__files = [];

                        (prevFiles || []).forEach(function (prevFile) {
                            var _item = _getItem(prevFile);

                            keysObj[_dataId(_item)] = _item.hash + '|' + _item.virus_scan;
                        });

                        item.__folders = [];

                        (prevFolders || []).forEach(function (prevFolder) {
                            var _item = _getItem(prevFolder);

                            keysObj[_dataId(_item)] = _item.rev || true;
                        });
                    }

                    folder.list = (folder.list || []).filter((item) => !showcaseController.isFolderForShowcase(item));

                    (folder.list || []).forEach(function (_item) {
                        var oldItem = _getItem(_item, true);
                        var _key;

                        _item = renameCameraUploads({ folder: _item, siblings: folder.list });

                        if (oldItem) {
                            tree.updateItem(_item, false, dontSyncWithRedux);
                        } else {
                            tree.setItem(_item, folder.id);
                        }
                        _key = _dataId(_item);

                        if (keysObj[_key] !== void 0) {
                            if (_item.isFolder) {
                                if (_item.rev != keysObj[_key]) {
                                    details.updatedItems.push(_getId(_item));
                                }
                            } else {
                                if (_item.hash + '|' + _item.virus_scan != keysObj[_key]) {
                                    details.updatedItems.push(_getId(_item));
                                }
                            }

                            delete keysObj[_key];
                        } else {
                            details.addedItems.push(_getId(_item));
                        }

                        if (item.count && Uflr.is(_item)) {
                            // CLOUDWEB-7677: Не показываем на публичных страницах illegal и blocked файлы.
                            item.count.uflr++;
                        }
                    });
                    tree.updateAuthors(folder);

                    // delete item.list;
                    // delete item.items;
                    //
                    sortParentItems(folder.id);

                    // remove all items what not in new list from cache
                    Object.keys(keysObj).forEach(function (_item) {
                        details.removedItems.push(_getId(_getItem(_item)));
                        _removeItem(_item);
                    });
                }

                tree.emit('setFolder', folder, details);

                return tree;
            },

            updateAuthors: throttle(function (folder) {
                const folderItem = _getItem(folder);
                const itemIsRoot = isRoot(folderItem);
                if (itemIsRoot || !folderItem) {
                    return;
                }

                folderItem.authors = setAuthors(pathOr([], ['items'], app.folderItems(folderItem)));
            }, 250),

            setItem: function (item, parent) {
                const state = reduxStore.getState();
                const storage = getCurrentStorage(state);
                const { isPublic, isFeed, isFavorites } = getStorage(storage);

                item.id = _normalizeId(_getId(item));

                if (!item.id) {
                    throw new Error('Bad item');
                }

                delete item.items; // Ненужное свойство, добавляется в fest/page/tree.js
                item.nameParts = null; // CLOUDWEB-7324 (обнуляем при переименовании файла).

                var itemIsRoot = isRoot(item);
                var parentItem = _getItem(parent);

                if ((IS_REACT_PAGE || isFeed || isFavorites || isPublic) && (!parentItem || !parent.isFolder)) {
                    // для реакт страниц пока забиваем на поддержку старого дерева
                    return this;
                }
                if (!parentItem && !itemIsRoot) {
                    throw new Error('Bad parent ' + parent);
                }

                this.emit('beforeSetItem', item, parent);

                _setItem(item.id, item);

                _normalize(item, true);

                if (!itemIsRoot) {
                    item.parent = parentItem.id;

                    var itemsField = isFolder(item.id) ? '__folders' : '__files';

                    if (!parentItem[itemsField].includes(item.id)) {
                        parentItem[itemsField].push(item.id);
                        parentItem.__allItems.push(item.id);
                    }
                } else {
                    item.name = rootTitle;
                }

                this.emit('setItem', item, parent);

                return this;
            },

            updateItem: function (newItem, strict, dontSyncWithRedux = false) {
                newItem.id = _normalizeId(_getId(newItem));

                if (!newItem.id) {
                    throw new Error('Bad item');
                }

                if (!dontSyncWithRedux) {
                    reduxStore.dispatch(
                        updateItem({
                            count: {
                                ...newItem.count,
                            },
                            tree: newItem.tree,
                            name: newItem.name,
                            hash: newItem.hash,
                            grev: newItem.grev,
                            kind: newItem.kind,
                            rev: newItem.rev,
                            type: newItem.type,
                            home: newItem.home,
                            size: newItem.size,
                            mtime: newItem.mtime,
                        })
                    );
                }

                delete newItem.items; // Ненужное свойство, добавляется в fest/page/tree.js

                var item = _getItem(newItem, true),
                    parent;

                if (!item) {
                    // remove this after united cache arrived
                    parent = getParentId(newItem);
                    var itemClone = {
                        count: void 0,
                        __files: void 0,
                        __allItems: void 0,
                        __folders: void 0,
                        __isItem: void 0,
                        __normalizeUrls: void 0,
                        thumbnails: void 0,
                        parent: void 0,
                        folder: void 0,
                        items: void 0,
                        list: void 0,
                        ...newItem,
                    };
                    this.setItem(itemClone, parent);

                    return this;
                }

                if (item === newItem) {
                    _normalize(item, true);

                    return this;
                }

                parent = item.parent;

                this.emit('beforeSetItem', newItem, parent);

                var key;
                for (key in newItem) {
                    if (newItem.hasOwnProperty(key) && key !== 'id') {
                        var value = newItem[key];

                        if (value === void 0) {
                            delete item[key];
                        } else {
                            item[key] = value;
                        }
                    }
                }

                if (strict) {
                    for (key in item) {
                        if (item.hasOwnProperty(key) && key !== 'id' && !newItem.hasOwnProperty(key)) {
                            delete item[key];
                        }
                    }
                }

                _normalize(item, true);

                if (!isRoot(item)) {
                    item.parent = parent;

                    var itemsField = isFolder(item.id) ? '__folders' : '__files';

                    var parentItem = _getItem(parent);

                    if (!parentItem[itemsField].includes(item.id)) {
                        parentItem[itemsField].push(item.id);
                        parentItem.__allItems.push(item.id);
                    }
                } else {
                    item.name = rootTitle;
                }

                delete item.__normalizeUrls;

                this.emit('setItem', item, parent);

                return this;
            },

            remove: function (items) {
                if (!(items instanceof Array)) {
                    items = [items];
                }

                this.emit('beforeRemove', items);

                for (var i = 0, len = items.length; i < len; i++) {
                    var item = _getItem(items[i]);

                    if (item) {
                        var id = _getId(item);
                        var itemIsFolder = isFolder(item);

                        if (itemIsFolder) {
                            if (item.__folders.length) {
                                this.remove(item.__folders);
                            }
                            if (item.__files.length) {
                                this.remove(item.__files);
                            }
                        }

                        var parentItem = _getItem(getParentId(item));

                        if (parentItem) {
                            var parentItemsArray = itemIsFolder ? parentItem.__folders : parentItem.__files;
                            var parentAllItemsArray = parentItem.__allItems;

                            if (parentItemsArray === items) {
                                // clone array to safety remove item from it
                                items = items.slice();
                            }
                            var index = parentItemsArray.indexOf(id);
                            if (index !== -1) {
                                parentItemsArray.splice(index, 1);
                                parentAllItemsArray.splice(parentAllItemsArray.indexOf(id), 1);
                                this.updateAuthors(parentItem);
                            }
                        }

                        _removeItem(id);
                    }
                }

                this.emit('remove', items);

                return this;
            },

            isLoaded: function (id, wholeData) {
                var result = has(id) && (!isFolder(id) || isCurrentFolderLoaded(id, wholeData));
                if (result) {
                    previousItem = app.getState();
                    previousItem = previousItem && _getFolderId(previousItem.id);
                } else {
                    previousItem = enableCache ? true : void 0;
                }
                return result;
            },

            has: has,

            folder: _getFolderId,

            file: function (item) {
                item = _getItem(item);

                return isFolder(item) ? null : item;
            },

            items: function (item, from, to, normalizeItem, onlyFolders, onlyFiles, filter, returnId) {
                item = _getItem(item);

                if (item) {
                    var folder = _getItem(_getFolderId(item));
                    var getItem =
                        typeof normalizeItem === 'function'
                            ? function (item) {
                                  return normalizeItem(_getItem(item));
                              }
                            : _getItem;
                    var hasFilter = typeof filter === 'function';
                    returnId = !!returnId;

                    var allItems = onlyFiles && onlyFolders;

                    var subFoldersCount = !allItems && onlyFiles === true ? 0 : folder.__folders.length;
                    var subFilesCount = !allItems && onlyFolders === true ? 0 : folder.__files.length;

                    var allCount = folder.__allItems.length;

                    if (!allCount) {
                        return [];
                    }

                    from = +from || 0;
                    to = +to;
                    var hasTo = !isNaN(to) && to + 1 < allCount;
                    to = hasTo
                        ? to + 1 // included
                        : allCount;

                    var result = [];
                    var index = 0,
                        filteredItems = 0,
                        j,
                        len,
                        array,
                        subItemId,
                        subItem;
                    var theEnd = false;

                    var addItemsToResult = function (items, result) {
                        for (; j < len; j++) {
                            subItemId = items[j];
                            subItem = getItem(subItemId);

                            if (!hasFilter || filter(subItem)) {
                                if (returnId) {
                                    result[index] = subItemId;
                                } else {
                                    result[index] = subItem;
                                }

                                index++;
                            } else {
                                filteredItems++;
                            }
                        }
                    };

                    if (allItems) {
                        array = folder.__allItems;
                        j = from;
                        len = allCount;

                        if (hasTo && to <= len) {
                            len = to;
                        }

                        addItemsToResult(array, result);
                    } else {
                        array = folder.__folders;

                        j = from;
                        len = subFoldersCount;

                        if (hasTo && to <= len) {
                            len = to;
                            theEnd = true;
                        }

                        addItemsToResult(array, result);

                        if (!theEnd && subFilesCount > 0) {
                            j = from > len ? from - len : 0;

                            if (hasTo) {
                                to -= len;
                            }

                            array = folder.__files;
                            len = hasTo ? to : subFilesCount;

                            addItemsToResult(array, result);
                        }
                    }

                    if (hasFilter && filteredItems) {
                        result.length = index;
                    }

                    return result;
                } else {
                    return [];
                }
            },

            itemKeys: function (item) {
                item = _getItem(item);

                if (item) {
                    if (arguments.length === 1) {
                        var folder = _getItem(_getFolderId(item));
                        return folder.__folders.concat(folder.__files);
                    } else {
                        return this.items(item, arguments[1], arguments[2], arguments[3], arguments[4], arguments[5], arguments[6], true);
                    }
                } else {
                    return [];
                }
            },

            subFolders: function (item) {
                item = _getItem(item);

                if (item) {
                    var folder = _getItem(_getFolderId(item));

                    return folder.__folders.map(getItem);
                } else {
                    return [];
                }
            },

            getId: function _getId(item) {
                if (typeof item === 'string') {
                    return item;
                }
                return item.id;
            },

            get: function (item, checkCache) {
                item = _getItem(item, checkCache);

                return item ? _normalize(item) : void 0;
            },

            isVirus: isVirus,
            isNotVirus: isNotVirus,
            isVirusSkip: isVirusSkip,
            isFile: isFile,
            isFolder: isFolder,
            isDomainFolder: isDomainFolder,
            isCameraFolder: isCameraFolder,

            isMounted: isMounted,
            isShared: isShared,

            ancestorsOrSelf: function (item) {
                var dataId = _dataId(item);
                var id = _getId(item);

                var result = [];

                while ((item = data[dataId])) {
                    result.unshift(_getId(item));

                    // тут бывает бесконечный цикл
                    if (id === item.parent || dataId === item.parent) {
                        break;
                    }

                    id = item.parent;
                    dataId = _dataId(id);
                }

                return result;
            },

            clearCache: function () {
                this.emit('beforeClearCache');

                data = {};
                initRoot();

                this.emit('clearCache');
            },

            ancestors: function (item) {
                var result = this.ancestorsOrSelf(item);

                if (result[result.length - 1] === _getId(item)) {
                    result = result.slice(0, -1);
                }

                return result;
            },

            itemsCount: function (item) {
                item = _getItem(item);

                var count = 0;

                if (item) {
                    if (item.__files) {
                        count += item.__files.length;
                    }
                    if (item.__folders) {
                        count += item.__folders.length;
                    }
                }

                return count;
            },

            itemsCountInfo: function (item) {
                item = _getItem(item);

                var result = {
                    folders: 0,
                    files: 0,
                    all: 0,
                    loaded: 0,
                };

                if (item) {
                    result.folders = item.count.folders;
                    result.files = item.count.files;
                    result.all = result.folders + result.files;
                    result.loaded = this.itemsCount(item);
                }

                return result;
            },

            escapeName: function (name) {
                return _escapeName(name);
            },

            getSort: getSort,
            setSort: setSort,
        };

        // поддержка событий
        MicroEvent.mixin(tree);

        return tree;
    };

    return function (config, revision, api, _app) {
        app = _app;

        return new Tree(config, revision, api);
    };
});
