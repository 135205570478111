import 'video.js/dist/video-js.min.css';

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMinWidthBreakpoint } from 'reactApp/hooks/responsiveness/useMinWidthBreakpoint';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { Stub } from 'reactApp/ui/ReactViewer/Stub/Stub';
import videojs, { VideoJsPlayer } from 'video.js';
import contribQualityLevels from 'videojs-contrib-quality-levels';

import hlsQualitySelector from './hlsQualitySelector/plugin';
import loopPlugin from './LoopPlugin/plugin';
import { errorsMap, updateControlBarButtons, useVideoPlayer } from './useVideoPlayer';
import styles from './VideoPlayer.css';
import { IProps } from './VideoPlayer.types';

const RESPONSIVE_PLAYER_BUTTONS_BREAKPOINT = 560;

videojs.addLanguage('ru', {
    Play: 'Смотреть',
    Pause: 'Пауза',
    Quality: 'Качество',
    Mute: 'Отключение звука',
    Unmute: 'Включение звука',
    Auto: 'Авто',
    auto: 'авто',
    'Playback Rate': 'Скорость воспроизведения',
    'Picture-in-Picture': 'Мини-плеер',
    'Exit Picture-in-Picture': 'Мини-плеер',
    Fullscreen: 'Во весь экран',
    'Non-Fullscreen': 'Свернуть',
    Loop: 'Повтор',
    'Non-Loop': 'Повтор',
    ...errorsMap,
});

videojs.registerPlugin('contribQualityLevels', contribQualityLevels);
videojs.registerPlugin('hlsQualitySelector', hlsQualitySelector);
videojs.registerPlugin('loopPlugin', loopPlugin);

const VideoPlayerComponent = (props: IProps): ReactElement => {
    const {
        streamUrl,
        posterUrl,
        kind,
        ext,
        name,
        isVisible = true,
        autoPlay = false,
        nativeUrl,
        size,
        isArchive = false,
        isAttaches = false,
        onError,
        sendRadar,
    } = props;
    const [adm, setAdm] = useState<any | undefined>();
    const isVideo = kind === 'video';
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    const videoRef = useRef<HTMLVideoElement>(null);
    const playerRef = useRef<VideoJsPlayer>();

    const [isMediaQueryHit] = useMinWidthBreakpoint(RESPONSIVE_PLAYER_BUTTONS_BREAKPOINT);

    useVideoPlayer(videoRef, playerRef, {
        streamUrl,
        posterUrl,
        kind,
        autoPlay,
        nativeUrl,
        size,
        isArchive,
        isAttaches,
        isVideo,
        isPhone,
        onError,
        name,
        ext,
        isMediaQueryHit,
        setAdm,
        mediaBreakpoint: RESPONSIVE_PLAYER_BUTTONS_BREAKPOINT,
    });

    // При переходе на другой элемент viewer компонент не совершает unmount, ставим на паузу
    useEffect(() => {
        if (!isVisible) {
            playerRef.current?.pause();
            adm?.pause();
        }

        const handleOnPlay = () => {
            // Если поставить видео на паузу, перейти на другой элемент и там перейти в фуллскрин, то либа videojs продолжит играть этот невидимый фоновый элемент, потому стопаем принудительно
            if (!isVisible && document.fullscreenElement && !playerRef.current?.paused()) {
                playerRef.current?.pause();
                adm?.pause();
            }
        };

        playerRef.current?.on('play', handleOnPlay);

        return () => {
            playerRef.current?.off('play', handleOnPlay);
        };
    }, [isVisible, adm]);

    useEffect(() => {
        updateControlBarButtons(playerRef.current, isMediaQueryHit);
    }, [isMediaQueryHit]);

    return (
        <div className={`${styles.root} ${isPhone ? 'mobile' : ''}`}>
            <video ref={videoRef} className="video-js" />
            {!isVideo && (
                <div className={styles.audioIcon}>
                    <Stub ext={ext} kind={kind} isVirus={false} name={name} sendRadar={sendRadar} />
                </div>
            )}
        </div>
    );
};

// При переходе на другой элемент в архиве videojs делает dispose и удаляет <video из ДОМ,
// это создает проблему с videoRef при повторном переключении на этот же файл
// Поэтому сделана эта обертка с key, чтобы целиком удалять из ДОМ всё и заново рендерить вместе с новым <video
export const VideoPlayer = (props: IProps): ReactElement => {
    const { name, ...rest } = props;

    return <VideoPlayerComponent key={encodeURIComponent(name)} {...rest} name={name} />;
};
