import React, { memo } from 'react';

import styles from './PhotoCard.css';
import { IProps } from './PhotoCard.types';

export const PhotoCard = memo(function PhotoCard(props: IProps) {
    const { item } = props;
    const { thumbnails, path } = item || {};

    return (
        <div className={styles.root}>
            <div className={styles.photoWrapper}>
                <div className={styles.imageContainer} key={path}>
                    <img className={styles.photo} src={thumbnails?.['1000x1000']} />
                </div>
            </div>
        </div>
    );
});
