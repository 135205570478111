import { activitiesFeature } from 'reactApp/appHelpers/featuresHelpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { renderContextMenu } from 'reactApp/ui/ContextMenu/createContextMenuToolkit';

export const onContextMenuItem = ({ event, gaSuffix, storage }) => {
    if (storage === EStorageType.trashbin && !activitiesFeature.trashbin) {
        return;
    }

    event.preventDefault();
    event.stopPropagation();
    renderContextMenu({
        posX: event.pageX,
        posY: event.pageY,
        storage,
        gaSuffix: gaSuffix || storage,
    });
};

export const onContextMenu = ({ event, gaSuffix, storage, isPopup }) => {
    if (storage === EStorageType.trashbin || storage === EStorageType.quotaCleaner || isPopup) {
        return;
    }

    event.preventDefault();
    renderContextMenu({
        posX: event.pageX,
        posY: event.pageY,
        renderDefault: true,
        gaSuffix: gaSuffix || storage,
        storage,
    });
};
