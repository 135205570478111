/* eslint-disable complexity */
import { path, pathOr } from 'ramda';

const timeOut = 60000;

const sizeGroups = [
    {
        min: 0,
        max: 20 * 1024,
        name: '20kb',
    },
    {
        min: 20 * 1024,
        max: 50 * 1024,
        name: '50kb',
    },
    {
        min: 50 * 1024,
        max: 100 * 1024,
        name: '100kb',
    },
    {
        min: 100 * 1024,
        max: 500 * 1024,
        name: '500kb',
    },
    {
        min: 500 * 1024,
        max: 1024 * 1024,
        name: '1mb',
    },
    {
        min: 1024 * 1024,
        max: 2 * 1024 * 1024,
        name: '2mb',
    },
    {
        min: 2 * 1024 * 1024,
        max: 5 * 1024 * 1024,
        name: '5mb',
    },
    {
        min: 5 * 1024 * 1024,
        max: 10 * 1024 * 1024,
        name: '10mb',
    },
    {
        min: 10 * 1024 * 1024,
        max: 20 * 1024 * 1024,
        name: '20mb',
    },
    {
        min: 20 * 1024 * 1024,
        max: 50 * 1024 * 1024,
        name: '50mb',
    },
    {
        min: 50 * 1024 * 1024,
        max: Number.MAX_SAFE_INTEGER,
        name: '1gb',
    },
];

let currentItem = null;

const sendRadar = ({ viewerType, editItem, actionType }) => {
    actionType = actionType.replace(/\./, '').toLowerCase();

    if (!editItem) {
        return;
    }

    const radar = RADAR.group(`view-${viewerType.toLowerCase()}-${actionType}`);
    let time = Date.now() - editItem.startTime;

    if (time > timeOut) {
        time = timeOut;
    }

    let size = null;
    let sizeGroup = null;
    const itemSize = path(['item', 'size'], editItem);

    if (itemSize) {
        sizeGroup = sizeGroups.find((item) => itemSize >= item.min && itemSize < item.max);
        size = (sizeGroup && sizeGroup.name) || size;
    }

    if (actionType === 'apploaded' || actionType === 'fail') {
        const radarTime = RADAR.group(`edtr-${viewerType.toLowerCase()}-${actionType}`);

        const grName = (sizeGroup && sizeGroup.name) || 'unk';
        radarTime.add(`ext-${editItem.item.ext}-${grName}`, 1);

        radar.add('ext', editItem.item.ext);
        radar.add('size', size);

        if (time) {
            radarTime.add(`ext-${editItem.item.ext}-${grName}-time`, time);
        }

        radarTime.send();
    }

    radar.send();
};

const clearEditItem = () => {
    if (currentItem) {
        clearTimeout(currentItem.timeOutId);
        currentItem = null;
    }
};

const logEditorOpenError = (editorId, reason, _currentItem) => {
    const editItem = _currentItem || currentItem;
    sendRadar({
        viewerType: (editorId || (editItem && editItem.editorId)).toLowerCase(),
        editItem,
        actionType: `fail${reason ? `_${reason}` : ''}`,
    });

    if (!_currentItem) {
        clearEditItem();
    }
};

const addEditorMessageListener = (windowArg = window) =>
    windowArg.addEventListener?.('message', (event) => {
        let { data } = event;

        if (!data) {
            return;
        }

        if (data === 'ms-success') {
            sendRadar({ viewerType: 'office', editItem: currentItem, actionType: 'apploaded' });

            clearEditItem();
        } else if (data === 'fail') {
            logEditorOpenError('office');
        } else if (data.includes && data.includes('r7-placeholder')) {
            try {
                data = JSON.parse(data);
                let actionType = path(['event'], data);
                let mode;
                let clear = false;

                // список и параметры: https://helpcenter.r7-office.ru/api/editors/config/events.aspx
                if (actionType) {
                    switch (actionType) {
                        case 'onAppReady':
                            actionType = 'init';
                            break;
                        case 'onError':
                            actionType = 'fail';
                            clear = true;
                            break;
                        case 'onDocumentReady':
                            actionType = 'apploaded';
                            clear = true;
                            break;
                        case 'onInfo':
                            mode = pathOr('unk', ['data', 'mode'], data);
                            actionType = `mode-${mode}`;
                            break;
                        case 'onWarning':
                            actionType = 'warning';
                            break;
                        case 'onOutdatedVersion':
                            actionType = 'outdated';
                            break;
                        case 'onDownloadAs':
                            actionType = 'downloadas';
                            break;
                        case 'onCollaborativeChanges':
                            actionType = 'collabchg';
                            break;
                        default:
                            break;
                    }
                    sendRadar({ viewerType: 'r7', editItem: currentItem, actionType });
                    if (clear) {
                        clearEditItem();
                    }
                }
            } catch (err) {}
        } else if (data.protocol === 'frame-rpc' && data.method === 'changeDocument') {
            sendRadar({ viewerType: 'myoffice', editItem: currentItem, actionType: 'apploaded' });
            clearEditItem();
        }
    });

const startEditorSuccessWaiter = (item, editorId, waitForTimeout = true) => {
    const newCurrentItem = {
        startTime: Date.now(),
        item,
        editorId,
    };
    if (waitForTimeout) {
        newCurrentItem.timeOutId = setTimeout(() => logEditorOpenError(editorId, 'timeout', newCurrentItem), timeOut);
    }
    currentItem = newCurrentItem;
    sendRadar({ viewerType: editorId, editItem: currentItem, actionType: 'click' });
};

export { startEditorSuccessWaiter, addEditorMessageListener, logEditorOpenError, timeOut };
