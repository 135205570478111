import { Spaces } from '@mail/cross-sizes-utils';

export const emptyImageUrl = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==';
export const emptyImageTransparentUrl =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

export const ADS_TIMER_MS = 30000;
export const ADS_VIEWER_ATTACHES_WORM_TIMER_MS = 60000;
export const ADS_VIEWER_ATTACHES_WORM_FAST_TIMER_MS = 30000;

export const START_EVENT_TIMER_MS = 60000;

export const sizeGroups = {
    values: [
        0,
        Spaces.ONE_KB,
        Spaces.ONE_MB,
        10 * Spaces.ONE_MB,
        100 * Spaces.ONE_MB,
        Spaces.ONE_GB,
        10 * Spaces.ONE_GB,
        100 * Spaces.ONE_GB,
    ],
    labels: ['1kb', '1mb', '10mb', '100mb', '1gb', '10gb', '100gb', '1tb'],
};

export const VK_UI_DARK_THEME = 'vkui--cloud--dark';

export const TEN_MINUTES = 600000;

export const DEFAULT_MYOFFICE_FALLBACK_TIMEOUT = 30;
