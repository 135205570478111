import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { Icon16DownloadOutline, Icon20DownloadOutline } from '@vkontakte/icons';
import { downloadAsJpeg } from 'Cloud/Application/DownloadAsJpeg';
import { isNil } from 'ramda';
import React, { memo, ReactElement } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { DataListControl } from 'reactApp/ui/DataListControl/DataListControl';
import { DropdownItemAction } from 'reactApp/ui/DropdownItemAction/DropdownItemAction';
import { DropdownList, DropdownTheme, ListItem } from 'reactApp/ui/DropdownList/DropdownList';
import { useDropdownList } from 'reactApp/ui/DropdownList/DropdownList.hooks';
import { Hint } from 'reactApp/ui/Hint/Hint';

interface DownloadProps {
    onClick: React.MouseEventHandler<HTMLDivElement>;
    size?: number;
    ext?: string;
    id: string;
    storage?: string;
    show?: boolean;
    isLarge?: boolean;
    viewType: EViewMode;
    className?: string;
}

export const Download = memo<DownloadProps>(function Download({ onClick, ext, size, id, storage, viewType, className, isLarge = true }) {
    const dropdownList = useDropdownList<HTMLDivElement>();

    const sizeTitle = isNil(size) ? undefined : bytesToNDigits(size, 3).value;
    const downloadHint = `Скачать${ext ? ` .${ext.toLowerCase()}` : ''}${sizeTitle ? `${ext ? `, ${sizeTitle}` : ` ${sizeTitle}`}` : ''}`;

    const renderDropdownItem = (item: ListItem): ReactElement => {
        return <DropdownItemAction text={item.text} />;
    };

    const downloadAsJpegAvailable =
        ext && downloadAsJpeg.isAvailable({ ext, storage: storage as EStorageType }) && storage !== EStorageType.archive;

    const Icon = isLarge ? Icon20DownloadOutline : Icon16DownloadOutline;

    return (
        <>
            <Hint text={downloadHint}>
                <DataListControl.New
                    id="download-icon"
                    ref={dropdownList.controlRef}
                    type={downloadAsJpegAvailable ? 'downloadAsJpeg' : 'download'}
                    viewType={viewType}
                    onClick={onClick}
                    className={className}
                >
                    <Icon />
                </DataListControl.New>
            </Hint>
            {downloadAsJpegAvailable && dropdownList.isOpen && (
                <DropdownList
                    gaId="datalist-control"
                    list={downloadAsJpeg.getDropdownItems({ itemOrId: id, gaSuffix: 'datalist', ext, textPrefix: 'в', storage })}
                    close={dropdownList.close}
                    closeOnScroll
                    closeOnMouseLeave
                    posX={0}
                    posY={0}
                    parentRef={dropdownList.controlRef}
                    theme={DropdownTheme.jpeg}
                    title={'Скачать в'}
                    calcPosition={dropdownList.findDropdownPosition}
                    renderItem={renderDropdownItem}
                />
            )}
        </>
    );
});
