import classNames from 'clsx';
import React, { FC, memo } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';

import styles from './FileIcon.css';
import { FileIcon as FileIconNew } from './new/FileIcon';

interface FileIconProps {
    type?: string | null;
    view?: EViewMode;
    flat?: boolean;
    showIcon?: boolean;
    mode?: 'dark' | 'light';
    className?: string;
}

const FileIconComponent: FC<FileIconProps> = memo(function FileIcon({
    className,
    type = '',
    view = EViewMode.list,
    showIcon = true,
    flat = false,
    mode,
}) {
    if (!showIcon) {
        return null;
    }

    return (
        <div
            className={classNames(className, {
                [styles.root]: true,
                [styles[`root_view_${view}`]]: Boolean(view),
                [styles.root_flat]: !!flat,
                [styles[`root_${mode}`]]: Boolean(mode),
            })}
        >
            <div
                className={classNames({
                    [styles.icon]: true,
                    [styles[`icon_type_${type}`]]: type,
                })}
            />
        </div>
    );
});

export const FileIcon = Object.assign(FileIconComponent, { New: FileIconNew });
