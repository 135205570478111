import React, { VFC } from 'react';

import styles from './DownloadAppButton.css';

export const DownloadAppButtonComponent: VFC<{ onClick?: () => void; linkToDownload: string; showIcon?: boolean; text: string }> = ({
    onClick,
    linkToDownload,
    showIcon,
    text,
}) => (
    <a href={linkToDownload} onClick={onClick} className={styles.root}>
        {showIcon && <span className={styles.downloadAppIcon} />}
        {text}
    </a>
);
