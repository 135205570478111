import dateTime from 'lib/dateTime';
import { X_PAGE_ID } from 'reactApp/appHelpers/configHelpers';
import { UploadingReason } from 'reactApp/modules/uploading/serviceClasses/UploadingReason';
import { sendKaktamLog, sendXray } from 'reactApp/utils/ga';

import { ELogError, ELogType } from '../uploading.types';

const groupName = 'app_new-upload';
// На grepmaillog файл будет иметь имя cloud_NewUpload.log
const kaktamName = 'NewUpload';

const sendKaktam = (data) => {
    if (Object(data) !== data) {
        throw new TypeError('The data may only be an Object');
    }

    sendKaktamLog(data, kaktamName);
};

const sendCounter = (counterName, counterValue = 0) => {
    if (counterValue === undefined) {
        counterValue = 1; // prevent time sent
        // eslint-disable-next-line radix
    } else if (parseInt(counterValue.toString(), 10) !== counterValue) {
        throw new TypeError('The counter value may only be an integer or undefined');
    }

    sendXray([groupName, counterName, counterValue]);
};

export type TLogLevel = 0 | 1 | 2;

export class UploadingLogRecord {
    constructor(type: ELogType, data: any, level: TLogLevel) {
        if (type !== ELogType.TYPE_INFO && type !== ELogType.TYPE_FAIL && type !== ELogType.TYPE_ERROR) {
            throw new TypeError(ELogError.ERROR_NO_TYPE);
        }

        if (Object(data) !== data) {
            throw new TypeError(ELogError.ERROR_NO_DATA);
        }

        this.type = type;
        this.data = data;
        this.timeStamp = Date.now();

        if (level === undefined) {
            this.level = UploadingLogRecord[type];
        } else {
            this.level = level;
        }
    }

    radarName = '';
    radarValue = undefined;

    readonly type;
    data;
    timeStamp = -1;
    isUnsent = true;
    level = -1;

    timePattern = 'hh:ss:mm.SSS(z)';
    toStringPattern = '{TYPE} {DETAILS}';

    toString = () => {
        const data = this.data;
        let details;

        if (data instanceof UploadingReason) {
            details = data.toLogString();
        } else {
            details = this.getDetails();

            const time = dateTime.format(details.timeStamp, this.timePattern);

            details.timeStamp = undefined;
            details = `[${time}] ${JSON.stringify(details)}`;
        }

        return this.toStringPattern.replace('{TYPE}', this.type).replace('{DETAILS}', details);
    };

    toLogString = () => {
        return this.toString();
    };

    getDetails = () => {
        const data = this.data;
        let details;

        if (data instanceof UploadingReason) {
            details = data.getDetails();
        } else {
            details = Object.assign({}, data);
            delete details.radarName;
            delete details.radarValue;
        }

        if (!details.timeStamp) {
            details.timeStamp = this.timeStamp;
        }

        details.pageId = X_PAGE_ID;

        return details;
    };

    sendCounter = () => {
        const data = this.data;

        const counterName = data.radarName || this.radarName;

        if (counterName) {
            const counterValue = data.radarValue || this.radarValue;

            sendCounter(counterName, counterValue);
        }

        sendKaktam(this.getDetails());
    };
}
