import config from 'Cloud/config';
import settings from 'Cloud/settings';
import { HIDE_ADS, IS_AUTO_TEST_HIDE, IS_PHONE_BROWSER, IS_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { store } from 'reactApp/store';
import { sendGa } from 'reactApp/utils/ga';
import { put } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

const MOBILE_PUBLIC_BANNER_PROMO_ID = 'mobilePublicBanner';
const banner = config.get('MOBILE_PUBLIC_BANNER');

export function* initMobilePublicBannerPromo() {
    const isNewbie = yield* select(UserSelectors.isNewbie);

    if (!banner || IS_AUTO_TEST_HIDE || settings?.request?.action || !IS_PHONE_BROWSER || isNewbie || !IS_PUBLIC) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.mobilePublicBanner,
            promoId: MOBILE_PUBLIC_BANNER_PROMO_ID,
            onShow: () => store.dispatch(promoShown(EPromoType.mobilePublicBanner)),
            onClose: () => {
                store.dispatch(removePromo(EPromoType.mobilePublicBanner));
            },
        })
    );
}

export const renderMobileSearchNotification = (data, onClose?: Parameters<typeof openPopupHelper>[0]['onClose']) =>
    openPopupHelper({ popupName: popupNames.MOBILE_PUBLIC_BANNER, onClose, data });

export function* showMobilePublicBannerPromo() {
    const mobilePublicBannerPromo = yield* select(PromoSelectors.getPromo(EPromoType.mobilePublicBanner));

    if (!mobilePublicBannerPromo || !banner) {
        return;
    }

    sendGa('mobpub', 'show', HIDE_ADS ? 'noads' : 'ads');

    mobilePublicBannerPromo?.onShow();

    renderMobileSearchNotification({
        bannerData: banner,
        onClose: mobilePublicBannerPromo?.onClose,
        onShow: mobilePublicBannerPromo?.onShow,
    });
}
