/**
 * Экшены docs для отправки метрик.
 */
export enum EDocsAction {
    open = 'open',
}

/**
 * Енам с допустимыми типами документов (type_content).
 */
export enum EDocSubKind {
    text = 'document',
    pdf = 'pdf',
    spreadsheet = 'spreadsheet',
    presentation = 'presentation',
}
