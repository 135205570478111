import { updateFamilySaga } from 'reactApp/modules/family/sagas/updatеFamily.saga';
import { takeEvery } from 'redux-saga/effects';

import {
    createFamily,
    familyInviteAccept,
    familyInviteRequest,
    familyLoadRequest,
    familyUpdateRequest,
    initFamilyInviteModal,
    initFamilyOnboarding,
    inviteToFamilyOrCreate,
    leaveFamilyRequest,
    removeFamilyMember,
} from './family.actions';
import { createFamilyRequest } from './sagas/createFamily.saga';
import { initFamilyData } from './sagas/initFamilyData.saga';
import {
    handleFamilyInviteAccept,
    handleFamilyInviteRequest,
    handleInviteToFamilyOrCreate,
    handleOpenInviteModal,
} from './sagas/invite.saga';
import { handleLeaveFamilyRequest } from './sagas/leaveFamily.saga';
import { familyOnboarding } from './sagas/onboarding.saga';
import { removeFamilyMemberRequest } from './sagas/removeFamily.saga';

export function* watchFamily() {
    yield takeEvery(familyLoadRequest.toString(), initFamilyData);
    yield takeEvery(createFamily.toString(), createFamilyRequest);
    yield takeEvery(initFamilyOnboarding.toString(), familyOnboarding);
    yield takeEvery(removeFamilyMember.toString(), removeFamilyMemberRequest);
    yield takeEvery(familyInviteRequest.toString(), handleFamilyInviteRequest);
    yield takeEvery(familyInviteAccept.toString(), handleFamilyInviteAccept);
    yield takeEvery(initFamilyInviteModal.toString(), handleOpenInviteModal);
    yield takeEvery(inviteToFamilyOrCreate.toString(), handleInviteToFamilyOrCreate);
    yield takeEvery(leaveFamilyRequest.toString(), handleLeaveFamilyRequest);
    yield takeEvery(familyUpdateRequest.toString(), updateFamilySaga);
}
