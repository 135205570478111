import classNames from 'clsx';
import React, { FC } from 'react';
import { FileType } from 'reactApp/types/FileType';

import styles from './FileIcon.css';
import { isFolder as _isFolder } from './FileIcon.helpers';

interface FileIconProps {
    type: string /* TODO: нужно менять тип на FileType */;
    className?: string;
    size?: 'l' | 'm' | 's' | 'xs';
    mode?: 'light' | 'dark';
}

export const FileIcon: FC<FileIconProps> = ({ className, type, size = 's', mode = 'light' }) => {
    const isFolder = _isFolder(type as FileType /* TODO: нужно менять тип на FileType */);

    return (
        <div
            className={classNames(styles.root, className, {
                [styles[`size_${size}`]]: !!size,
                [styles.mode]: true,
                [styles[`folder_type_${type}`]]: isFolder,
                [styles[`file_type_${type}`]]: !isFolder,
                [styles['file_type']]: !isFolder,
                [styles.root_dark]: mode === 'dark',
            })}
        />
    );
};
