import { Method } from 'axios';
import { APICallV1 } from 'reactApp/api/APICall';

export type TProfileUserLa = {
    status: 'ACCEPTED' | 'NOT_ACCEPTED';
    la_version: string;
};

export class ProfileUserLaApiCall extends APICallV1<TProfileUserLa> {
    _method = 'profile/user_la';
    _type: Method = 'post';
    defaultData = this._defaultPayload;
}
