import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { store } from 'reactApp/store';

import { getCurrentStorage } from '../router/router.selectors';

const getCurrentStorageHelper = (storage) => getStorage(storage || getCurrentStorage(store.getState()));

export function initRouterMock(app) {
    app.isHome = (storage) => getCurrentStorageHelper(storage).isHome;

    app.isEditor = (storage) => getCurrentStorageHelper(storage).isEditor;

    app.isLinks = function (storage) {
        return storage === 'links';
    };

    app.isShared = function (storage) {
        return this.isSharedIncoming(storage) || this.isSharedLinks(storage);
    };

    app.isSharedIncoming = (storage) => getCurrentStorageHelper(storage).isSharedIncoming;

    app.isSharedLinks = (storage) => getCurrentStorageHelper(storage).isSharedLinks;

    app.isTrashBin = (storage) => getCurrentStorageHelper(storage).isTrashBin;

    app.isAttaches = (storage) => getCurrentStorageHelper(storage).isAttaches;

    app.isFavorites = (storage) => getCurrentStorageHelper(storage).isFavorites;

    app.isSearch = (storage) => getCurrentStorageHelper(storage).isSearch;

    app.isFeed = (storage) => getCurrentStorageHelper(storage).isFeed;

    // app.getState = function () {
    //     return _.history.currentState();
    // };
    //
    // app.getStorage = function () {
    //     return _.sid();
    // };
}
