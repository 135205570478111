/* tempexp_15344-full-file */
import darkCoverImage from 'img/aside-promo-modal/cloud-dark.jpg';
import lightCoverImage from 'img/aside-promo-modal/cloud-light.jpg';
import { HIDE_ADS } from 'reactApp/appHelpers/configHelpers';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { getFeatureRequiredSignUpWhenDownloading } from 'reactApp/modules/features/features.selectors';
import { TemplateVariantString } from 'reactApp/modules/features/features.types';
import { getCurrentPublicItem, getPublicItemById, isOwnPublic } from 'reactApp/modules/public/public.selectors';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { RootState, store as reduxStore } from 'reactApp/store';
import { openAsidePromoModal } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.helpers';
import { EAsidePromoModalTheme } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.types';
import { sendGa as _sendGa } from 'reactApp/utils/ga';
import { EQueryParams } from 'server/helpers/getRequestParams';

import { RequiredSignUpTouchSource, SUCCESS_AUTH_WHEN_DOWNLOAD } from './constants';
import { reDownloadController } from './reDownloadController';

type AsideModalGaAction = 'shw-aside-mdl' | 'cls-aside-mdl' | 'clck-aside-mdl';
type ReDownloadSnackGaAction = 'shw-redwnld-snack' | 'cls-redwnld-snack' | 'clck-redwnld-snack';
type ReDownloadTooltipGaAction = 'shw-redwnld-tltip' | 'cls-redwnld-tltip' | 'clck-redwnld-tltip';

const sendGa = (
    action: AsideModalGaAction | ReDownloadSnackGaAction | ReDownloadTooltipGaAction,
    variant?: TemplateVariantString,
    placement?: 'single' | 'folder'
): void => _sendGa('rqird-auth-whn-dwnld', action, variant, { placement });

let touchSource: RequiredSignUpTouchSource | null = null;
let touchSourceFileId: string | undefined;
let showDownloadTip = false;
const setRequiredSignUpTouchSource = (source: RequiredSignUpTouchSource, fileId?: string) => {
    touchSource = source;

    showDownloadTip = false;

    touchSourceFileId = fileId;
};
const setShowDownloadTip = (setShow = true) => {
    showDownloadTip = setShow;
};
const resetRequiredSignUpTouchSource = () => {
    touchSource = null;
    touchSourceFileId = undefined;
};
const getSuccessPage = () => {
    const url = new URL(window.location.href);
    url.searchParams.append(EQueryParams.action, SUCCESS_AUTH_WHEN_DOWNLOAD);

    if (touchSource) {
        url.searchParams.append(EQueryParams.requiredSignUpTouchSource, touchSource);
    }

    if (touchSourceFileId) {
        url.searchParams.append(EQueryParams.id, touchSourceFileId);
    }

    return url.toString();
};

const isSuccessAuthWhenDownload = (state: RootState) => {
    const queryParams = SettingsSelectors.getQueryParams(state);
    const queryAction = queryParams.action;

    const isPublicOwner = isOwnPublic(state);

    return (
        queryAction === SUCCESS_AUTH_WHEN_DOWNLOAD && !isPublicOwner /* Отсекаем от флоу юзеров, которые авторизовались в свой же паблик */
    );
};

const isTouchIdFolder = (state: RootState) => {
    const queryParams = SettingsSelectors.getQueryParams(state);
    const id = queryParams[EQueryParams.id] || touchSourceFileId;

    return getPublicItemById(state, id)?.isFolder || !id;
};

const isRequiredSingUpTouchSource = (state: RootState, source: RequiredSignUpTouchSource, id?: string) => {
    const queryParams = SettingsSelectors.getQueryParams(state);
    const sourceFromQuery = queryParams[EQueryParams.requiredSignUpTouchSource];
    const idFromQuery = queryParams[EQueryParams.id];

    const fromUrl =
        isSuccessAuthWhenDownload(state) &&
        sourceFromQuery === source &&
        (sourceFromQuery === RequiredSignUpTouchSource.PUBLIC_TOOLBAR || id === idFromQuery);
    const fromState =
        showDownloadTip && touchSource === source && (touchSource === RequiredSignUpTouchSource.PUBLIC_TOOLBAR || id === touchSourceFileId);

    return fromUrl || fromState;
};

const showAsideModal = () => {
    const state = reduxStore.getState();
    const requiredSignUpVariant = getFeatureRequiredSignUpWhenDownloading(state);

    if (requiredSignUpVariant === 'variant1' && !reDownloadController.isEmpty()) {
        const item = getCurrentPublicItem(state);

        const theme = !item?.isFolder ? EAsidePromoModalTheme.dark : undefined;
        const imageUrl = !item?.isFolder ? darkCoverImage : lightCoverImage;

        openAsidePromoModal({
            title: 'Храните в Облаке',
            text: 'Файл не будет занимать место на устройстве, а бесплатных гигабайтов хватит на сотни фото и видео',
            buttonText: 'Сохранить в Облако',
            imageUrl,
            theme,
            bottom: theme === EAsidePromoModalTheme.dark && !HIDE_ADS ? 110 : undefined,
            onClick: () => {
                if (item) {
                    toolbarActions.clone({
                        id: item.id,
                        destination: '',
                        source: 'toolbar',
                        fromReDownload: true,
                    });
                }

                sendGa('clck-aside-mdl', 'variant1');
            },
            onShow: () => {
                // Показываем только один раз за сессию
                reDownloadController.resetParams();
                sendGa('shw-aside-mdl', 'variant1');
            },
            onClose: () => sendGa('cls-aside-mdl', 'variant1'),
        });
    }
};

const isSuccessAuthVariant = (variant: TemplateVariantString) => (state: RootState) => {
    const requiredSignUpVariant = getFeatureRequiredSignUpWhenDownloading(state);

    return isSuccessAuthWhenDownload(state) && requiredSignUpVariant === variant;
};

export const requiredAuthorizationHelpers = {
    getSuccessPage,
    isSuccessAuthWhenDownload,
    showAsideModal,
    isSuccessAuthVariant1: isSuccessAuthVariant('variant1'),
    sendGa,
    isRequiredSingUpTouchSource,
    setRequiredSignUpTouchSource,
    resetRequiredSignUpTouchSource,
    setShowDownloadTip,
    isTouchIdFolder,
};
