import classNames from 'clsx';
import React, { ReactElement } from 'react';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { HEADER_ID } from 'reactApp/constants/magicIdentificators';

import styles from './Header.css';

export enum EHeaderColor {
    BLUE = 'blue',
    WHITE = 'white',
    CEPIA = 'cepia',
    DARK = 'dark',
}

interface IProps {
    children: React.ReactNode;
    color?: EHeaderColor;
    isPhone?: boolean;
    className?: string;
}

export const HeaderWrapper: React.FC<IProps> = ({ color = EHeaderColor.WHITE, children, isPhone, className }): ReactElement => (
    <div
        className={classNames(
            {
                [styles.root]: true,
                [styles[`root_color_${color}`]]: color,
                [styles.root_phone]: isPhone,
                [styles.root_desktop]: !isPhone,
                [styles.root_desktop_web_ui]: !isPhone,
                [styles.root_my_team]: ENABLE_FULL_RESPONSIVE,
            },
            className
        )}
        id={HEADER_ID}
    >
        {children}
    </div>
);
