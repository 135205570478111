import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { CardActions } from 'reactApp/modules/creditCard/creditCard.module';

interface IProps {
    onClose: () => void;
}

const buttons = [EButtonType.accept, EButtonType.cancel];

export const RemoveCardModal = memo<IProps>(({ onClose }) => {
    const dispatch = useDispatch();

    const renderHeader = useCallback(() => 'Удаление карты', []);
    const renderContent = useCallback(() => 'Вы действительно хотите отвязать вашу карту от Облака?', []);

    const handleConfirm = useCallback(() => {
        const queryParams = getQueryParams();
        const source = queryParams?.source;

        const params = {
            onSuccess: onClose,
            source,
        };

        dispatch(CardActions.removeCard(params));
        onClose();
    }, [dispatch, onClose]);

    return (
        <BaseConfirmDialog
            dataQAId="remove-card-dialog"
            renderHeader={renderHeader}
            successButtonText="Удалить"
            cancelButtonText="Отменить"
            buttons={buttons}
            onClose={onClose}
            onSuccess={handleConfirm}
            renderContent={renderContent}
            closeOnDimmerClick
        />
    );
});

RemoveCardModal.displayName = 'RemoveCardModal';
