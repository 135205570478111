export enum BizCategoryType {
    all = 'all',
    folder = 'folder',
    document = 'document',
    spreadsheet = 'spreadsheet',
    pdf = 'pdf',
    presentation = 'presentation',
    archive = 'archive',
    image = 'image',
    audio = 'audio',
    video = 'video',
    other = 'other',
}

export interface BizCategory {
    name: string;
    id: BizCategoryType;
    value: BizCategoryType;
}

export interface BizCategoryState {
    categories: BizCategory[];
    currentCategory?: BizCategoryType;
}
