export enum EFavoritesFetch {
    NAME = '@favorites/fetch',
    REQUEST = '@favorites/fetch/request',
    SUCCESS = '@favorites/fetch/success',
    FAILURE = '@favorites/fetch/failure',
}

export enum EFavoritesFetchCount {
    NAME = '@favorites/fetchcount',
    SUCCESS = '@favorites/fetchcount/success',
}
