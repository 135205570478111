import { UploadingDescriptor } from 'reactApp/modules/uploading/serviceClasses/UploadingDescriptor';
import { cancelUploading } from 'reactApp/modules/uploading/uploading.module';
import { ERetryErrorFileOptions } from 'reactApp/modules/uploading/uploading.types';
import { EFileError, EFileStatus } from 'reactApp/modules/uploadList/uploadList.model';
import { cancelAllUploadFileAction, updateUploadFilesAction } from 'reactApp/modules/uploadList/uploadList.module';
import { put } from 'redux-saga/effects';

export const VIOLATED_FILENAME_ERROR_EVENT = 'ViolatedFileNameErrorEvent';

export enum VIOLATED_FILENAME_ERROR_EVENT_DETAILS {
    SKIP,
    STOP,
}

export function* handleViolatedFileNameError(descriptor: UploadingDescriptor) {
    const retryResult: ERetryErrorFileOptions = ERetryErrorFileOptions.cancel;

    yield put(
        updateUploadFilesAction({
            descriptorId: descriptor.id,
            cloudPath: descriptor.cloudPath,
            ...{ status: EFileStatus.WARNING, error: EFileError.VIOLATED_FILENAME },
            hideError: false,
        })
    );

    const result = yield new Promise<VIOLATED_FILENAME_ERROR_EVENT_DETAILS>((resolve) => {
        const handleViolatedFileNameErrorEvent = (evt: CustomEvent) => {
            resolve(evt.detail);
            window.removeEventListener(VIOLATED_FILENAME_ERROR_EVENT, handleViolatedFileNameErrorEvent as EventListener);
        };
        window.addEventListener(VIOLATED_FILENAME_ERROR_EVENT, handleViolatedFileNameErrorEvent as EventListener);
    });

    yield put(
        updateUploadFilesAction({
            descriptorId: descriptor.id,
            cloudPath: descriptor.cloudPath,
            ...{ status: EFileStatus.ERROR, error: EFileError.VIOLATED_FILENAME },
            hideError: false,
        })
    );
    if (result === VIOLATED_FILENAME_ERROR_EVENT_DETAILS.STOP) {
        yield put(cancelAllUploadFileAction());
        yield put(cancelUploading());
    }

    return retryResult;
}
