import { downloadDocumentMail } from 'Cloud/Application/Editor/MyOffice/helpers/downloadDocument';
import { pathOr } from 'ramda';
import { getDocumentUrlByAttachId } from 'reactApp/api/axios.corsapi';
import { EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { isDocument, isPdf } from 'reactApp/modules/file/utils';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { CloudFile, CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { convertToPdf } from 'reactApp/utils/convertDocumentToPdfAndDownload';
import { downloadDocument, isBuffer } from 'reactApp/utils/downloadDocumentHelpers';

interface IFetchProps {
    item: CloudItem;
    storage: EStorageType;
}

export const fetchDocumentData = async ({ item, storage }: IFetchProps) => {
    if (item.isFolder) {
        throw new Error('Передана папка, а не файл');
    }
    const document = isDocument(item);
    const pdfFile = isPdf(item);
    if (!document && !pdfFile) {
        throw new Error('Данный файл не поддерживается');
    }

    const { isAttaches } = getStorage(storage);
    const isAttache = isAttaches && 'attachType' in item && item.attachType === EAttachTypes.attach;
    const isCloudStock = isAttaches && 'attachType' in item && item.attachType === EAttachTypes.cloudStock;

    let data;

    if (document) {
        data = await convertToPdf(item as CloudFile);
    } else if (isCloudStock) {
        const url = pathOr('', ['url', 'download'], item);
        data = await downloadDocumentMail(url);
    } else if (isAttache) {
        const url = getDocumentUrlByAttachId({ fileName: item.name, attachId: item.id });
        data = storage === EStorageType.stock ? await downloadDocument(url, true) : await downloadDocumentMail(url);
    } else {
        const viewUrl = pathOr('', ['url', 'view'], item);
        const getUrl = pathOr('', ['url', 'get'], item);
        data = await downloadDocument(getUrl || viewUrl, storage === EStorageType.stock || isCloudStock);
    }

    const { content, error } = data;

    if (content && isBuffer(content)) {
        return content;
    }
    if (error) {
        throw new Error(error);
    }

    throw new Error('Something went wrong');
};
