import { UploadFail } from 'reactApp/modules/uploading/fails/UploadFail';

export class ReadOnlyDirectoryFail extends UploadFail {
    static WEB_BACKEND_ERROR = 'read_only';

    radarName = 'fail_{SOURCE}_read-only-folder';
    message = [
        'Папка доступна только для чтения.',
        '<a href="https://help.mail.ru/cloud_web/folders/public" rel="noopener" target="_blank">Подробнее</a>',
    ].join(' ');
}
