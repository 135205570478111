// tempexp_SRCH-70119-full-file
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { store } from 'reactApp/store';
import {
    getSearchFilterTooltipType,
    getSearchTooltipType,
    getSessionTooltipVisibilityStatus,
    getTooltipAppearanceCount,
    getTooltipFlagName,
} from 'reactApp/ui/Header/SearchTooltip/SearchTooltip.helpers';
import { put, select } from 'redux-saga/effects';

export function* initSearchTooltipPromo() {
    const searchTooltipType = getSearchTooltipType();
    const searchFilterTooltipType = getSearchFilterTooltipType();
    const email = yield select(UserSelectors.getEmail);
    const storage = yield select(getCurrentStorage);

    if (storage === EStorageType.editor) {
        return;
    }

    if (searchTooltipType) {
        const searchTooltipFlagName = getTooltipFlagName(email, searchTooltipType);
        const shouldPreventSearchTooltipController =
            getTooltipAppearanceCount(searchTooltipFlagName) >= 1 || getSessionTooltipVisibilityStatus(searchTooltipFlagName, 'closed');

        if (!shouldPreventSearchTooltipController) {
            yield put(
                addPromoToShowQueue({
                    type: EPromoType.searchTooltip,
                    promoId: EPromoType.searchTooltip,
                    onShow: () => {
                        store.dispatch(promoShown(EPromoType.searchTooltip));
                    },
                    onClose: () => {
                        store.dispatch(removePromo(EPromoType.searchTooltip));
                    },
                })
            );
        }
    }

    if (searchFilterTooltipType) {
        const searchFilterTooltipFlagName = getTooltipFlagName(email, searchFilterTooltipType);
        const shouldPreventSearchTooltipFilterController =
            getTooltipAppearanceCount(searchFilterTooltipFlagName) >= 1 ||
            getSessionTooltipVisibilityStatus(searchFilterTooltipFlagName, 'closed');
        if (!shouldPreventSearchTooltipFilterController) {
            yield put(
                addPromoToShowQueue({
                    type: EPromoType.searchFilterTooltipPromo,
                    promoId: EPromoType.searchFilterTooltipPromo,
                    onShow: () => {
                        store.dispatch(promoShown(EPromoType.searchFilterTooltipPromo));
                    },
                    onClose: () => {
                        store.dispatch(removePromo(EPromoType.searchFilterTooltipPromo));
                    },
                })
            );
        }
    }
}
