import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'reactApp/store';

export const selectContacts = (state: RootState) => state.contacts;

const getContacts = createSelector(selectContacts, (contacts) => contacts.list);
const isLoaded = createSelector(selectContacts, (contacts) => contacts.isLoaded);
const getContactSuggestions = createSelector(selectContacts, (contacts) => contacts.suggest);

export const contactsSelectors = {
    isLoaded,
    getContacts,
    getContactSuggestions,
};
