import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import React, { memo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';

import styles from './AnimatedBell.css';
import animation from './animation.json';

const ANIMATION_REPEAT_COUNT = 2;
const ANIMATION_TIMEOUT = 3600;
const ANIMATION_STOP_TIMEOUT = 10000;
export const ANIMATED_BELL_SESSION_ID = 'animated-bell';

interface AnimatedBellProps {
    stopAnimation: () => void;
}

export const AnimatedBell = memo<AnimatedBellProps>(({ stopAnimation }) => {
    const lottieRef = useRef<LottieRefCurrentProps | null>(null);
    const email = useSelector(UserSelectors.getEmail);

    useEffect(() => {
        const animationTimeout = setTimeout(() => {
            lottieRef?.current?.play();
        }, ANIMATION_TIMEOUT);

        const animationStopTimeout = setTimeout(() => {
            stopAnimation();
            sessionStorage.setItem(`${email}|${ANIMATED_BELL_SESSION_ID}`, 'true');
        }, ANIMATION_STOP_TIMEOUT);

        return () => {
            clearTimeout(animationTimeout);
            clearTimeout(animationStopTimeout);
        };
    }, []);

    return (
        <div className={styles.root}>
            <Lottie animationData={animation} loop={ANIMATION_REPEAT_COUNT} autoplay={false} lottieRef={lottieRef} />
            <div className={styles.informer}>
                <div className={styles.dot} />
            </div>
        </div>
    );
});

AnimatedBell.displayName = 'AnimatedBell';
