import { Checkbox, Spacing, Text, Title } from '@vkontakte/vkui';
import modal1Cover from 'img/safe-fakedoor/modal1Cover.png';
import modal2Cover from 'img/safe-fakedoor/modal2Cover.png';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { IPropsWithPopup } from 'reactApp/modules/popup/popup.helpers';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { noop } from 'reactApp/utils/helpers';
import { ESafeFakedoorAnalytics, safeFakedoorAnalytics } from 'reactApp/utils/safeFakedoorGa';

import { SAFE_FAKEDOOR_STORE_ID } from './SafeFakedoorModal.constants';
import styles from './SafeFakedoorModal.css';

enum Step {
    FIRST = 'FIRST',
    SECOND = 'SECOND',
}

const PAYMENT_URL = `https://${window.location.host}/promo/quota?action=request-payment&productId=W128G1m_trial_2023_single_quota_cloud`;

interface SafeFakedoorModalProps extends IPropsWithPopup<{ data: { onShow: () => void } }> {}

export const SafeFakedoorModal: FC<SafeFakedoorModalProps> = ({ onClose, data }) => {
    const { onShow = noop } = data || {};
    const [step, setStep] = useState(Step.FIRST);
    const [hotkeyOn, setHotkeyOn] = useState(false);

    const onPrimaryHandler = useCallback(() => {
        if (step === Step.FIRST) {
            safeFakedoorAnalytics(ESafeFakedoorAnalytics.FAKE_PURCHAISE, { hot_key: hotkeyOn.toString() as 'true' | 'false' });

            setStep(Step.SECOND);

            storeHelper.setValue(SAFE_FAKEDOOR_STORE_ID, true);
        }

        if (step === Step.SECOND) {
            safeFakedoorAnalytics(ESafeFakedoorAnalytics.PURCHAISE);
        }
    }, [hotkeyOn, step]);

    const onCloseHandler = useCallback(() => {
        onClose();

        safeFakedoorAnalytics(ESafeFakedoorAnalytics.CLOSE, { screen: step === Step.FIRST ? 'first' : 'second' });
    }, [onClose, step]);

    const handleCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setHotkeyOn(e.target.checked);
    }, []);

    const content1 = useMemo(() => {
        return (
            <div>
                <Title className={styles.title} level="1" weight="2">
                    Храните самое важное под паролем с подпиской Mail Space — 1 ₽ за первый месяц
                </Title>
                <Spacing size={12} />
                <Text className={styles.text}>
                    В папке «Сейф» можно установить PIN-код и скрыть под ним любые файлы — их не будет видно в историях, галерее и поиске.
                    Папку тоже можно скрыть
                </Text>
                <Spacing size={16} />
                <div className={styles.checkboxWrapper}>
                    <Checkbox hasActive={false} value={hotkeyOn.toString()} onChange={handleCheckboxChange} className={styles.checkbox}>
                        Включить горячие клавиши <span className={styles.bolderText}>Ctrl + G</span> для скрытия сейфа
                    </Checkbox>
                </div>
                <Spacing size={28} />
            </div>
        );
    }, [handleCheckboxChange, hotkeyOn]);

    const content2 = useMemo(() => {
        return (
            <div>
                <Title className={styles.title} level="1" weight="2">
                    Спасибо за ваш интерес!
                </Title>
                <Spacing size={12} />
                <Text className={styles.text}>
                    Мы ещё работаем над сейфом и сообщим, когда функция появится. А пока попробуйте подписку бесплатно — дарим 128 ГБ на
                    первый месяц
                </Text>
                <Spacing size={36} />
            </div>
        );
    }, []);

    return (
        <WhatsNewDialog
            onShow={onShow}
            closable
            onClick={onPrimaryHandler}
            onSecondary={onCloseHandler}
            onClose={onCloseHandler}
            primaryButtonText={step === Step.FIRST ? 'Оформить подписку за 1 ₽' : 'Получить 128 ГБ'}
            secondaryButtonText={step === Step.FIRST ? 'Закрыть' : 'Отказаться'}
            content={step === Step.FIRST ? content1 : content2}
            imageUrl={step === Step.FIRST ? modal1Cover : modal2Cover}
            title=""
            dialogSize="large"
            {...(step === Step.SECOND
                ? {
                      primaryButtonHref: PAYMENT_URL,
                      footerText: (
                          <>
                              <Text className={styles.payDescription}>
                                  30 дней бесплатно, далее 149 ₽ в месяц. Подписку можно отменить в любое время.
                              </Text>
                              <Spacing size={12} /> {/* Снизу т.к. footerTextBelowButtons делает flex-direction: column-reverse */}
                          </>
                      ),
                      footerTextBelowButtons: true,
                  }
                : {})}
        />
    );
};
