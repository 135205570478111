import { getFeatureParam } from 'Cloud/Application/FeaturesEs6';
import { INVALID_NAME_CHARACTERS, INVALID_PATH_CHARACTERS, MAX_NAME_LENGTH } from 'reactApp/appHelpers/configHelpers';
import { InvalidCharactesFail } from 'reactApp/modules/uploading/fails/InvalidCharactesFail';
import { EUploadReasonSource, UploadingReason } from 'reactApp/modules/uploading/serviceClasses/UploadingReason';
import { escapeForRegExp } from 'reactApp/utils/helpers';

import { NameTooLongFail } from '../../fails/NameTooLongFail';
import { PathTooLongFail } from '../../fails/PathTooLongFail';
import { PublicFileSizeLimit } from '../../fails/PublicFileSizeLimit';
import { UserFileSizeLimitFail } from '../../fails/UserFileSizeLimitFail';

const MAX_PATH_LENGTH = 1024;

const invalidNameCharacters = new RegExp(`[${escapeForRegExp(INVALID_NAME_CHARACTERS)}]`);
const invalidNameCharactersGlobal = new RegExp(`[${escapeForRegExp(INVALID_NAME_CHARACTERS)}]`, 'g');
const invalidPathCharacters = new RegExp(`[${escapeForRegExp(INVALID_PATH_CHARACTERS)}]`);
const invalidPathCharactersGlobal = new RegExp(`[${escapeForRegExp(INVALID_PATH_CHARACTERS)}]`, 'g');

function getStringLengthInBytes(value: string) {
    let bytes;

    if (typeof TextEncoder !== 'undefined') {
        const uint8Array = new TextEncoder().encode(value);

        bytes = uint8Array.length;
    } else {
        bytes = unescape(encodeURIComponent(value)).length;
    }

    return bytes;
}

function hasInvalidNameLength(name: string) {
    let hasInvalidNameLength = MAX_NAME_LENGTH < name.length;

    if (!hasInvalidNameLength) {
        const bytes = getStringLengthInBytes(name);

        hasInvalidNameLength = MAX_NAME_LENGTH < bytes;
    }

    return hasInvalidNameLength;
}

function hasInvalidPathLength(path: string) {
    let hasInvalidPathLength = MAX_PATH_LENGTH < path.length;

    if (!hasInvalidPathLength) {
        const bytes = getStringLengthInBytes(path);

        hasInvalidPathLength = MAX_PATH_LENGTH < bytes;
    }

    return hasInvalidPathLength;
}

function hasInvalidPathCharacters(path: string) {
    return invalidPathCharacters.test(path);
}

function hasInvalidPattern(name: string) {
    // Имя файла или папки не может состоять
    // только из точки «.» или из двух точек «..»
    return name === '.' || name === '..';
}

export const validatePaths = ({ error, path, onlyPath = false }: { error: UploadingReason | null; path: string; onlyPath?: boolean }) => {
    let validation;

    if (!error && !onlyPath) {
        validation = validateName(path);

        // TODO: check it
        if (validation instanceof UploadingReason) {
            error = validation;
        }
    }

    if (!error) {
        validation = validatePath(path);

        if (validation instanceof UploadingReason) {
            error = validation;
        }
    }

    return error;
};

export const removeInvalidNameCharacters = (path: string) => {
    return path.replace(invalidNameCharactersGlobal, '_');
};

export const removeInvalidPathCharacters = (path: string) => {
    return path.replace(invalidPathCharactersGlobal, '_');
};

const hasInvalidNameCharacters = (path: string) => {
    return invalidNameCharacters.test(path);
};

const validateName = (name: string): boolean | UploadingReason => {
    const source = EUploadReasonSource.SOURCE_WEB_CLIENT;
    let stack;
    let isInvalidName = hasInvalidPattern(name);
    let result: boolean | UploadingReason = true;

    if (isInvalidName) {
        stack = new Error('InvalidCharactesFail');
        result = new InvalidCharactesFail(stack, source);
    } else {
        isInvalidName = hasInvalidNameCharacters(name);

        if (isInvalidName) {
            stack = new Error('InvalidCharactesFail');
            result = new InvalidCharactesFail(stack, source);
        } else {
            isInvalidName = hasInvalidNameLength(name);

            if (isInvalidName) {
                stack = new Error('NameTooLongFail');
                result = new NameTooLongFail(stack, source);
            }
        }
    }

    return result;
};

const validatePath = (path: string): boolean | UploadingReason => {
    const source = EUploadReasonSource.SOURCE_WEB_CLIENT;
    let stack;
    let isInvalidPath = hasInvalidPattern(path);
    let result: boolean | UploadingReason = true;

    if (isInvalidPath) {
        stack = new Error('InvalidCharactesFail');
        result = new InvalidCharactesFail(stack, source);
    } else {
        isInvalidPath = hasInvalidPathCharacters(path);

        if (isInvalidPath) {
            stack = new Error('InvalidCharactesFail');
            result = new InvalidCharactesFail(stack, source);
        } else {
            isInvalidPath = hasInvalidPathLength(path);

            if (isInvalidPath) {
                stack = new Error('PathTooLongFail');
                result = new PathTooLongFail(stack, source);
            }
        }
    }

    return result;
};

export function getPublicFileSizeLimit(publicUploadLimit) {
    return publicUploadLimit || getFeatureParam('public-upload', 'sizeLimit', 2147483648);
}

export function checkFileSizeLessThanUserLimit(size: number, userFileSizeLimit: number) {
    if (size > userFileSizeLimit) {
        const source = EUploadReasonSource.SOURCE_WEB_CLIENT;
        const stack = new Error('UserFileSizeLimitFail');
        throw new UserFileSizeLimitFail(stack, source, userFileSizeLimit);
    }

    return size;
}

export function checkFileSizeLessThanPublicLimit(size: number, publicUploadLimit: number) {
    const publicLimit = getPublicFileSizeLimit(publicUploadLimit);

    if (size < publicLimit) {
        return size;
    }
    const source = EUploadReasonSource.SOURCE_WEB_CLIENT;
    const stack = new Error('PublicFileSizeLimitFail');
    throw new PublicFileSizeLimit(stack, source, publicLimit);
}

export function checkFileSizeLessThanLimit(size: number, userFileSizeLimit: number) {
    return checkFileSizeLessThanUserLimit(size, userFileSizeLimit);
}
