/** tempexp_14729-full-file */
import classNames from 'clsx';
import React, { FC, useCallback, useRef } from 'react';
import { Kind } from 'reactApp/types/Tree';
import { logHandler } from 'reactApp/ui/Datalist/DataList.helpers';
import { IThumb } from 'reactApp/ui/DataListItem/DataListItem.helpers';
import { FileIcon } from 'reactApp/ui/FileIcon/FileIcon';
import { getIconType } from 'reactApp/ui/FileIcon/getIconType';
import { ImageCancelable } from 'reactApp/ui/ImageCancelable/ImageCancelable';

import styles from './Pic.css';

export interface PicFolderProps {
    kind: Kind;
    isAutoDelete?: boolean;
    isLoading?: boolean;
    isPublic: boolean;
}

export interface PicFileProps {
    thumbUrl?: IThumb;
    ext: string;
    isVirus: boolean;
}

type PicProps = {
    className?: string;
    id?: string | undefined;
    author?: { name?: string; email: string };
    isFolder: boolean;
    onLoad?: () => void;
    hasLoaded?: boolean;
} & PicFolderProps &
    PicFileProps;

export const Pic: FC<PicProps> = ({
    className,
    isFolder,
    kind,
    ext,
    isVirus,
    isAutoDelete,
    isLoading,
    thumbUrl,
    onLoad,
    hasLoaded = false,
    isPublic,
}) => {
    const iconType = getIconType({
        isFolder,
        kind: isFolder ? kind : undefined,
        ext: isFolder ? undefined : ext.toLowerCase(),
        isVirus: isFolder ? false : isVirus,
        isAutoDelete: isFolder ? isAutoDelete : false,
        isPublic,
    });

    const startTime = useRef(Date.now());
    const isLoadingFolder = isFolder ? isLoading : false;
    const handleThumbLoad = useCallback(() => {
        logHandler({
            radarName: `thumb_load_success`,
            radars: [`all=${Date.now() - startTime.current}`],
        });
        if (kind) {
            logHandler({
                radarName: `thumb_load_success`,
                radars: [`${kind}=${Date.now() - startTime.current}`],
            });
        }

        onLoad?.();
    }, [onLoad, kind]);

    const handleThumbError = useCallback(() => {
        logHandler({
            radarName: `thumb_load_fail`,
            radars: [`all=${Date.now() - startTime.current}`],
        });
        if (kind) {
            logHandler({
                radarName: `thumb_load_fail`,
                radars: [`${kind}=${Date.now() - startTime.current}`],
            });
        }
    }, [kind]);

    return (
        <div className={classNames(styles.root, className)}>
            {!hasLoaded && (
                <div
                    className={classNames(styles.icon, {
                        [styles.loading_folder]: isLoadingFolder,
                    })}
                >
                    <FileIcon.New type={iconType} size="l" />
                </div>
            )}
            {!isFolder && Boolean(thumbUrl?.src) && (
                <div className={styles.thumb_container}>
                    <ImageCancelable
                        className={classNames(styles.thumb, {
                            [styles.thumb_loaded]: hasLoaded,
                        })}
                        draggable={false}
                        src={thumbUrl?.src}
                        srcSet={thumbUrl?.srcSet ? `${thumbUrl?.srcSet} 2x` : ''}
                        alt=""
                        onError={handleThumbError}
                        onLoad={handleThumbLoad}
                        data-loaded={hasLoaded}
                    />
                </div>
            )}
        </div>
    );
};
