import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa, EPaymentGa } from 'reactApp/utils/paymentGa';

// https://docs.google.com/spreadsheets/u/2/d/1oxESW-0Ne7WtP7gKejV6CJYSv-Q9ssFDC8MY1_chDT0/edit

export const useSendSpaceMenuAnalytics = (isMobile: boolean) => {
    const isPaidUser = useSelector(UserSelectors.isPaidUser);
    const remaining = useSelector(UserQuotaSelectors.getRemaining);
    const total = useSelector(UserQuotaSelectors.getTotal);

    return useCallback(
        (action: string) => {
            sendDwh({
                eventCategory: ECategoryGa.payment,
                action: EPaymentGa.unionQuota,
                label: action,
                dwhData: {
                    source: isMobile ? 'touch' : 'desktop',
                    id_paid: isPaidUser,
                    free_quota: remaining.original,
                    size_quota: total?.original,
                },
            });
        },
        [isMobile, isPaidUser, remaining.original, total?.original]
    );
};
