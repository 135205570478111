import config from 'Cloud/config';
import { EPlaces } from 'reactApp/hooks/useDownloadEbook';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

const HOSTNAME = window?.location?.hostname;

export const getOpeningPlace = (itemStorage?: EStorageType | null): EPlaces => {
    if (itemStorage === EStorageType.attaches || itemStorage === EStorageType.viewerAttaches) {
        return EPlaces.attach;
    }

    if (itemStorage === EStorageType.public) {
        return EPlaces.public;
    }

    return EPlaces.cloud;
};

type IframeType = 'epub' | 'fb2' | 'djvu';

const iframeTypeMap: Record<IframeType, string> = {
    epub: 'ebook',
    fb2: 'fb2-book',
    djvu: 'djvu-book',
};

export const getIframeSource = (chanelId: string, type: string, storage?: EStorageType | null): string | undefined => {
    const buildUrls = config.get('BUILD_URLS');
    const ebookPath = buildUrls?.ebook;
    let base = '';
    if (!ebookPath?.startsWith('https')) {
        base = `https://${HOSTNAME}`;
    }

    return (
        ebookPath &&
        `${base}${ebookPath}${iframeTypeMap[type]}/index.html?chanelId=${chanelId}${
            storage ? `&storage=${storage}` : ''
        }&hostname=${HOSTNAME}`
    );
};
