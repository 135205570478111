import React, { memo } from 'react';

import styles from './Circle.css';

interface IProps {
    radius: number;
    stroke: number;
    progress: number;
    className?: string;
}

export const Circle = memo(function Circle({ radius, stroke, progress, className = '' }: IProps) {
    const normalizedRadius = radius - stroke;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (progress / 100) * circumference;

    const boxSize = radius * 2;

    return (
        <svg height={boxSize} width={boxSize} className={className}>
            <circle
                className={styles.circle}
                fill="transparent"
                strokeWidth={stroke}
                strokeDasharray={`${circumference} ${circumference}`}
                style={{ strokeDashoffset }}
                r={normalizedRadius}
                cx={radius}
                cy={radius}
            />
        </svg>
    );
});
