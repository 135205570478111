import { getErrorMsg, logger } from 'lib/logger';
import { UserFullQuotaAPICall } from 'reactApp/api/user/FullQuotaAPICall';
import {
    loadUserQuotaWithFamilyRequest,
    loadUserQuotaWithFamilySuccess,
} from 'reactApp/modules/userAndFamilyQuota/userAndFamilyQuota.module';
import { takeLatest } from 'redux-saga/effects';
import { call, cancel, put } from 'typed-redux-saga';

const callApi = () => new UserFullQuotaAPICall().makeRequest();

export function* loadQuota() {
    try {
        const { data } = yield* call(callApi);

        yield put(loadUserQuotaWithFamilySuccess(data.body));
    } catch (error) {
        logger.error(getErrorMsg(error), error);
        yield cancel();
    }
}

export function* watchUserAndFamilyQuota() {
    yield takeLatest(loadUserQuotaWithFamilyRequest.toString(), loadQuota);
}
