import classNames from 'clsx';
import React, { PureComponent, ReactElement } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import { ShowcaseItemData } from 'reactApp/ui/Showcase/ShowcaseItem/ShowcaseItem.types';
import { noop } from 'reactApp/utils/helpers';

import styles from './ShowcaseItem.css';

export interface ShowcaseItemProps extends ShowcaseItemData {
    isWindows: boolean;
    onClick?: (id: string) => void;
    onShow?: (id: string) => void;
}

export class ShowcaseItem extends PureComponent<ShowcaseItemProps> {
    public componentDidMount(): void {
        const { onShow = noop, id, show_pixel } = this.props;

        onShow(id);

        if (show_pixel) {
            new Image().src = show_pixel;
        }
    }

    private handleClick = (): void => {
        const { onClick = noop, id, click_pixel } = this.props;

        onClick(id);

        if (click_pixel) {
            new Image().src = click_pixel;
        }
    };

    public render(): ReactElement | null {
        const { title, description = '', iconUrl, footerText, buttonText, clickUrlOsx, clickUrlWin, isWindows } = this.props;

        return (
            <a
                className={styles.root}
                href={isWindows ? clickUrlWin : clickUrlOsx}
                target="_blank"
                rel="noopener noreferrer"
                onClick={this.handleClick}
            >
                <div className={styles.content}>
                    <div className={styles.block}>
                        <div className={styles.title}>{title}</div>
                        <div className={styles.text}>{description.replace(/\\n/g, '\n')}</div>
                    </div>
                    <div className={classNames(styles.block, styles.block_centerItems)}>
                        <img alt={title} className={styles.icon} src={iconUrl} />
                    </div>
                </div>
                <div className={styles.footer}>
                    <div className={styles.footerText}>{footerText}</div>
                    <Button theme="octavius" primary>
                        {buttonText}
                    </Button>
                </div>
            </a>
        );
    }
}
