import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { clearContinueUploadFilesAction, continueUploadAction } from 'reactApp/modules/uploadList/continueUpload/continueUpload.module';
import { selectShowContinueUpload } from 'reactApp/modules/uploadList/continueUpload/continueUpload.selectors';

import styles from './ContinueUpload.css';

const ContinueUpload = memo(() => {
    const dispatch = useDispatch();
    const showContinueUpload = useSelector(selectShowContinueUpload);
    const handleContinueUpload = useCallback(() => {
        dispatch(continueUploadAction());
    }, []);

    const handleContinueUploadCancel = useCallback(() => {
        dispatch(clearContinueUploadFilesAction());
    }, []);

    const renderHeader = useCallback(() => <div>Возобновить загрузку</div>, []);

    const renderContent = useCallback(() => {
        return (
            <div className={styles.root}>
                <div>Чтобы возобновить загрузку, подтвердите доступ к файлам, которые вы ранее загружали.</div>
            </div>
        );
    }, []);

    if (!showContinueUpload) {
        return null;
    }

    return (
        <BaseConfirmDialog
            dataQAId="continue-upload"
            renderHeader={renderHeader}
            successButtonText="Возобновить загрузку"
            cancelButtonText="Отменить"
            buttons={[EButtonType.accept, EButtonType.cancel]}
            onSuccess={handleContinueUpload}
            onClose={handleContinueUploadCancel}
            renderContent={renderContent}
        />
    );
});

ContinueUpload.displayName = 'ContinueUpload';

export default ContinueUpload;
