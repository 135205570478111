import classNames from 'clsx';
import { ReactComponent as ErrorIcon } from 'img/overquota-share/error.svg';
import React, { createRef, PureComponent, ReactElement } from 'react';
import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { Button } from 'reactApp/ui/Button/Button';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { DisabledFeature } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.types';
import { createGaSender } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import { scrollLock, scrollUnlock } from 'reactApp/utils/scrollLock';

import { MobileDialog } from '../Mobile/MobileDialog/MobileDialog';
import styles from './DisabledFeatureDialog.css';

const sendGa = createGaSender('overqouta');

const getTitle = (disabledFeature) => {
    if (disabledFeature === DisabledFeature.publish) {
        return 'открытия публичного доступа';
    }

    if (disabledFeature === DisabledFeature.newFile) {
        return 'создания новых файлов';
    }

    if (disabledFeature === DisabledFeature.createAlbum) {
        return 'создания альбома'
    }

    return 'создания новых папок';
};

export interface Props {
    disabledFeature: DisabledFeature;
    overquotaSize: string;
    onClose();
    onBuy();
    isBizUser: boolean;
}

export class DisabledFeatureDialog extends PureComponent<Props> {
    private contentRef = createRef<HTMLDivElement>();

    public static defaultProps = {
        onClose: noop,
        onBuy: noop,
    };

    public componentDidMount() {
        const { disabledFeature } = this.props;

        scrollLock(this.contentRef.current);

        sendGa(disabledFeature, 'show');
    }

    public componentWillUnmount(): void {
        scrollUnlock(this.contentRef.current);
    }

    private handleClose = () => {
        const { disabledFeature } = this.props;

        sendGa(disabledFeature, 'close-click', null, true);

        this.props.onClose();
    };

    private handleBuy = () => {
        const { disabledFeature } = this.props;

        sendGa(disabledFeature, 'buy-click', null, true);

        this.props.onBuy();
    };

    private renderFooter = () => {
        return (
            <div className={styles.footer}>
                <div className={styles.buttonWrapper}>
                    <Button theme="vk" primary middle data-name="action" onClick={this.handleBuy}>
                        Купить дополнительное место
                    </Button>
                </div>
            </div>
        );
    };

    private renderContent = () => {
        const { disabledFeature, overquotaSize, isBizUser } = this.props;

        return (
            <>
                <div className={classNames({ [styles.content]: true, [styles.content_scrollable]: true })} ref={this.contentRef}>
                    <div className={styles.iconWrapper}>
                        <ErrorIcon width={96} height={96} data-qa-id="errorIcon" />
                    </div>
                    <div className={styles.title}>Функция {getTitle(disabledFeature)} отключена</div>
                    <div className={styles.description}>
                        Объем вашего Облака превышен на&nbsp;<strong>{overquotaSize}.</strong>
                        <br />
                        В&nbsp;связи с&nbsp;этим доступ к&nbsp;вашим файлам ограничен.
                        <br />
                        Остается доступным скачивание и&nbsp;удаление.
                    </div>
                    <div className={styles.recommend}>
                        {isBizUser
                            ? 'Обратитесь к администратору домена или удалите '
                            : 'Рекомендуем вам выбрать подходящий тариф или удалить '}
                        лишние файлы, освободив место в&nbsp;Облаке.
                    </div>
                </div>
                {!isBizUser && this.renderFooter()}
            </>
        );
    };

    public render(): ReactElement | null {
        if (IS_PHONE_BROWSER) {
            return (
                <MobileDialog id="disabledFeature-dialog" onClose={this.handleClose} closeOnDimmerClick={true} mod="confirm" topmost>
                    <div className={styles.root_phone}>{this.renderContent()}</div>
                </MobileDialog>
            );
        }

        return (
            <Dialog open onCancel={this.handleClose} disableScrollOnBody={false} id="disabledFeature-dialog" className={styles.root}>
                <Content isModal isLoading={false} hasError={false} scrolling>
                    {this.renderContent()}
                </Content>
            </Dialog>
        );
    }
}
