/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { loadUser } from 'reactApp/modules/user/user.thunkActions';
import Wizard from 'reactApp/ui/Wizard/Wizard';
import { WizardStep } from 'reactApp/ui/Wizard/WizardStep';
import { createGaSender, sendXray } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';

import LicenseBlock from './Steps/LicenseBlock';
import { WelcomeStep } from './Steps/WelcomeStep';

let app;
try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    app = require('Cloud/Application/app'); // Чтобы сторибук не грохался
} catch (err) {}

const mapStateToProps = (state) => {
    const { isLoaded: isUserLoaded, hasError: isUserError } = UserSelectors.getLifeCycleState(state);

    return {
        isUserLoaded,
        isUserError,
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadUser: () => dispatch(loadUser()),
});

interface IMapToStateProps {
    isUserLoaded: boolean;
    isUserError: boolean;
}

interface IDispatchToProps {
    loadUser: () => void;
}

interface IProps extends IMapToStateProps, IDispatchToProps {
    onComplete: () => void;
    onClose: () => void;
    onShow?: () => void;
}

export class WelcomeDialog extends PureComponent<IProps> {
    static defaultProps = {
        onComplete: noop,
        onClose: noop,
        onShow: noop,
    };

    sendGa = createGaSender('welcome');

    componentDidMount() {
        const { isUserLoaded, isUserError, loadUser, onShow } = this.props;

        if (!isUserLoaded && !isUserError) {
            loadUser();
        }

        onShow?.();
    }

    sendGaStepShown = (stepIdx) => this.sendGa('show', `step_${stepIdx + 1}`);

    handleComplete = () => {
        this.props.onComplete?.();
    };

    handleClose = (stepIdx) => {
        this.sendGa('close', `step_${stepIdx + 1}`);

        sendXray(['ui_welcome_start']);
        this.props.onClose?.();
    };

    render() {
        const { isUserLoaded, isUserError } = this.props;

        return (
            <Wizard
                size="medium"
                minHeight={true}
                closeOnEscape={false}
                onClose={this.handleClose}
                onComplete={this.handleComplete}
                onShown={this.sendGaStepShown}
                isLoading={!isUserLoaded && !isUserError}
                isError={!isUserLoaded && isUserError}
            >
                <WizardStep
                    disableBack={true}
                    primaryNext={true}
                    closable={false}
                    footerComponent={<LicenseBlock buttonEnd />}
                    showPager={false}
                >
                    <WelcomeStep />
                </WizardStep>
            </Wizard>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeDialog);
