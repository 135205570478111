import { ReactElement } from 'react';

export enum FamilySettingDropdownListItemIDs {
    space = 'space',
    subscriptions = 'subscriptions',
    subscriptionsOwner = 'subscriptions-owner',
    deleteAll = 'delete-all',
    deleteMember = 'delete-member',
    more = 'more',
    leave = 'leave',
    clearSpace = 'clearSpace',
    clearSpaceOwner = 'clearSpaceOwner',
}

export interface IDropdownItem {
    id: FamilySettingDropdownListItemIDs;
    icon: ReactElement;
    iconMobile: ReactElement;
    text: string;
    onClick: () => void;
}
