import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { path } from 'ramda';
import {
    addToFavoritesSuccess,
    moveItemsSuccess,
    publishWeblink,
    removeFileSuccess,
    removeFromFavoritesSuccess,
    resetWeblinkCountDownloads,
    toggleWeblinkAccessRights,
    toggleWeblinkDownloadable,
    unPublishWeblink,
    updateWeblinkCountDownloads,
    updateWeblinkExpires,
} from 'reactApp/modules/modifying/modifying.actions';
import {
    EModifyReason,
    IAddToFavoritesSuccess,
    IRemoveFromFavoritesSuccess,
    MoveItemsSuccessAction,
    RemoveFileSuccessAction,
    ResetWeblinkCountDownloads,
    ToggleWeblinkAccessRights,
    ToggleWeblinkDownloadable,
    UpdateWeblinkCountDownloads,
    UpdateWeblinkExpires,
} from 'reactApp/modules/modifying/modifying.types';
import { SUPPORTED_STORY_TYPES } from 'reactApp/modules/stories/stories.constants';
import { deleteFilesFromStory, normalizeListData, normalizeStoryInfoData } from 'reactApp/modules/stories/stories.helpers';
import {
    IStoryContentElementFile,
    IStoryContentItem,
    IStoryInfoApiResponse,
    IStoryInfoRequest,
    IStoryListApiResponse,
    IStoryShowRequest,
    IStoryState,
} from 'reactApp/modules/stories/stories.types';

const initialState: IStoryState = {
    isLoading: false,
    isLoaded: false,
    error: '',
    cursor: '',
    story: null,
    summaries: {},
    files: {},
    summaryIdxs: [],
    unviewedCount: 0,
};

export const getStoryListRequest = createAction('stories/getStoryListRequest');
export const getStoryListSuccess = createAction<IStoryListApiResponse>('stories/getStoryListSuccess');
export const getStoryListFail = createAction<string>('stories/getStoryListFail');
export const markStoryAsViewed = createAction<{ storyId: string; lastViewedId?: string }>('stories/markStoryAsViewed');
export const getStoryInfoRequest = createAction<IStoryInfoRequest>('stories/getStoryInfoRequest');
export const getStoryInfoSuccess = createAction<IStoryInfoApiResponse>('stories/getStoryInfoSuccess');
export const getStoryInfoFail = createAction<string>('stories/getStoryInfoFail');
export const activateStory = createAction('stories/activateStory');
export const showStory = createAction<IStoryShowRequest>('stories/showStory');
export const getStoryListLoadMore = createAction('getStoryListLoadMore');
export const getStoryUnviewedCountRequest = createAction('stories/getStoryUnviewedCountRequest');
export const getStoryUnviewedCountSuccess = createAction<number>('stories/getStoryUnviewedCountSuccess');

export const storiesReducer = createReducer(initialState, {
    [getStoryListRequest.type]: (state) => {
        state.isLoading = true;
        state.isLoaded = false;
        state.error = '';
        state.summaries = {};
        state.cursor = '';
        state.summaryIdxs = [];
    },
    [getStoryListSuccess.type]: (state, action: PayloadAction<IStoryListApiResponse>) => {
        const { list, cursor } = action.payload;

        state.isLoading = false;
        state.isLoaded = true;
        state.cursor = cursor;

        const filteredList = list.filter((story) => SUPPORTED_STORY_TYPES.includes(story.type));

        const { summaries, files, summaryIdxs } = normalizeListData(filteredList);

        state.summaries = { ...state.summaries, ...summaries };
        state.files = { ...state.files, ...files };
        state.summaryIdxs = { ...state.summaryIdxs, ...summaryIdxs };
    },
    [getStoryListFail.type]: (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
        state.error = action.payload;
    },
    [getStoryInfoRequest.type]: (state, action: PayloadAction<IStoryInfoRequest>) => {
        const { id, type } = action.payload;

        state.story = {
            id,
            type,
            isLoading: true,
            isLoaded: false,
            error: '',
            header: null,
            blocks: null,
            cover: null,
            viewed: false,
        };
    },
    [getStoryInfoSuccess.type]: (state, action: PayloadAction<IStoryInfoApiResponse>) => {
        if (!state.story) {
            return;
        }

        const { story, files } = normalizeStoryInfoData(action.payload) || {};

        if (story) {
            state.story.isLoading = false;
            state.story.isLoaded = true;
            state.story.blocks = story.blocks;
            state.story.header = story.header;
            state.story.cover = story.cover;
            state.story.viewed = story.viewed;

            state.files = {
                ...state.files,
                ...files,
            };
        }
    },
    [getStoryInfoFail.type]: (state, action) => {
        if (!state.story) {
            return;
        }
        state.story.isLoading = false;
        state.story.isLoaded = true;
        state.story.error = action.error;
    },
    [markStoryAsViewed.type]: (state: IStoryState, action: ReturnType<typeof markStoryAsViewed>) => {
        const { storyId, lastViewedId } = action.payload;
        if (state.summaries[storyId]?.viewed) {
            // Состояние уже просмотренного сториса менять не надо
            return;
        }
        if (state.summaries[storyId]) {
            state.summaries[storyId].viewed = !lastViewedId;

            if (!('file' in state.summaries[storyId].cover)) {
                return;
            }
            const length = state.story?.header?.content?.length;
            if (!length) {
                return;
            }
            const cover = state.summaries[storyId].cover as IStoryContentElementFile;
            const currentIdx = state.story?.header?.content?.findIndex((content) => content?.file === cover.file) ?? -1;
            let idx = state.story?.header?.content?.findIndex((content) => content?.file === lastViewedId) ?? -1;
            if (currentIdx >= 0 && idx !== -1 && idx + 1 <= currentIdx) {
                // Новый элемент может быть только после текущего (если листали назад), поэтому ставим просмотренным следующий.
                idx = currentIdx;
            }
            if (idx >= length - 1) {
                // Если просмотрели последний элемент, то как превью теперь всегда используем первый элемент сториса
                idx = -1;
            }
            const newFile = path(['header', 'content', idx + 1, 'file'], state.story) as string;
            if (!newFile) {
                return;
            }

            state.summaries[storyId].last_viewed_element_id = path(['header', 'content', idx, 'element_id'], state.story) as string;

            cover.file = newFile;
        }
    },
    [removeFileSuccess.type]: (state, action: PayloadAction<RemoveFileSuccessAction>) => {
        const { ids } = action.payload;

        deleteFilesFromStory(state, ids);
    },
    [moveItemsSuccess.type]: (state, action: PayloadAction<MoveItemsSuccessAction>) => {
        const { oldIds } = action.payload;

        deleteFilesFromStory(state, oldIds);
    },
    [updateWeblinkExpires.type]: (state, action: PayloadAction<UpdateWeblinkExpires>): void => {
        const { id, expires } = action.payload;

        if (state.files[id]) {
            state.files[id].weblinkExpires = expires;
        }
    },
    [publishWeblink.type]: (state, action: ReturnType<typeof publishWeblink>) => {
        const { id, weblink } = action.payload;

        if (state.files[id]) {
            state.files[id].weblink = weblink;
        }
    },
    [toggleWeblinkAccessRights.type]: (state, action: PayloadAction<ToggleWeblinkAccessRights>): void => {
        const { id, type } = action.payload;
        if (state.files[id]) {
            state.files[id].weblinkAccessRights = type;
        }
    },
    [toggleWeblinkDownloadable.type]: (state, action: PayloadAction<ToggleWeblinkDownloadable>): void => {
        const { id, downloadable } = action.payload;

        if (state.files[id]) {
            state.files[id].weblinkDownloadable = downloadable;
        }
    },
    [updateWeblinkCountDownloads.type]: (state, action: PayloadAction<UpdateWeblinkCountDownloads>): void => {
        const { id, count_downloads } = action.payload;
        const item = state.files[id];
        if (item) {
            item.count_downloads_left = count_downloads;
            item.count_downloads_total = count_downloads;
        }
    },
    [resetWeblinkCountDownloads.type]: (state, action: PayloadAction<ResetWeblinkCountDownloads>): void => {
        const { id } = action.payload;

        if (state.files[id]) {
            state.files[id].count_downloads_total = undefined;
            state.files[id].count_downloads_left = undefined;
        }
    },
    [unPublishWeblink.type]: (state, action: ReturnType<typeof unPublishWeblink>) => {
        const { ids } = action.payload;

        ids?.forEach((id) => {
            if (state.files[id]) {
                // eslint-disable-next-line no-void
                state.files[id].weblink = void 0;
            }
        });
    },

    [removeFileSuccess.type]: (state, action: PayloadAction<RemoveFileSuccessAction>) => {
        const { ids, reason } = action.payload;

        if (reason === EModifyReason.move) {
            return;
        }

        ids.forEach((id) => {
            if (state.files[id]) {
                delete state.files[id];
            }
        });

        if (state.story?.header) {
            const content = state.story.header.content;
            // @ts-ignore
            state.story.header.content = (content as IStoryContentItem[]).filter<IStoryContentItem>(
                // @ts-ignore
                (item) => 'file' in item && !ids.includes(item.file)
            );
        }

        if (state.story?.header?.content?.length === 0) {
            delete state.summaries[state.story.id];
            state.story = null;
        }
    },
    [addToFavoritesSuccess.type]: (state, action: PayloadAction<IAddToFavoritesSuccess>): void => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.files[id];

            if (!item) {
                return;
            }

            item.isInFavorites = true;
        });
    },
    [removeFromFavoritesSuccess.type]: (state, action: PayloadAction<IRemoveFromFavoritesSuccess>): void => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.files[id];

            if (!item) {
                return;
            }

            item.isInFavorites = false;
        });
    },
    [getStoryUnviewedCountSuccess.type]: (state, action: ReturnType<typeof getStoryUnviewedCountSuccess>): void => {
        state.unviewedCount = action.payload;
    },
});
