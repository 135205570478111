import Logo from 'img/portal-menu/cloud-touch-logo.svg?url';
import { APP_LOGO, APP_TITLE } from 'reactApp/appHelpers/configHelpers';

export const getLogoSrc = ({ logoSrc, isBizUser, bizLogo }): string => {
    if (logoSrc) {
        return logoSrc;
    }

    if (isBizUser) {
        return bizLogo ?? APP_LOGO;
    }

    return Logo;
};

export const getAlt = (isBizUser?: boolean): string => {
    return isBizUser ? APP_TITLE : 'Облако Mail.ru';
};
