export interface ISpaceLabel {
    id: string;
    text: string;
    hintText: string;
    isOverquota: boolean;
    onClick?: (event) => void;
}

export type TSpaceInfo = ISpaceLabel & {
    percents: number;
};

export interface IUseSpace {
    isOverquota: boolean;
    spaceList: TSpaceInfo[];
    freeSpaceText: string;
    usedPercents: number;
}

export type TUseSpaceHook = () => IUseSpace;

export enum ESpaceLegendAlign {
    left = 'left',
}

export enum ESpaceLegendSize {
    big = 'big',
}

export interface Props {
    isMobile?: boolean;
    hideLabels?: boolean;
    redWhenOverquota?: boolean;
    quotaLink?: boolean;
    legendAlign?: ESpaceLegendAlign;
    legendSize?: ESpaceLegendSize;
    useSpaceHook?: TUseSpaceHook;
    isWrap?: boolean;
}
