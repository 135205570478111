import { Method } from 'axios';
import { APICallV3Post, APICallV4 } from 'reactApp/api/APICall';
import { IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';

const method = 'document/create';
export class CreateDocAPICallV3 extends APICallV3Post {
    _method = method;
}

export class CreateDocAPICallV4 extends APICallV4 {
    _method = method;
    _type: Method = 'post';
}

export const getCreateDocAPICall = () => (IS_ONPREMISE ? new CreateDocAPICallV4() : new CreateDocAPICallV3());
