import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { isDefaultRootContent } from 'reactApp/modules/home/home.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { State } from 'reactApp/modules/welcome/welcome.types';
import { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

const getWelcomeState = (state: RootState) => state.welcome as State;

export const isWelcomePromoShow = (state: RootState): boolean => getWelcomeState(state).isShow;

export const isWelcomePromo = createSelector(
    isDefaultRootContent,
    (state, storage: EStorageType) => storage,
    (state, storage: EStorageType, routeId: string) => routeId,
    (isDefault, storage, id) => id === ROOT_FOLDER_ID && storage === EStorageType.home && isDefault
);
