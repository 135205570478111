export const GALLERY_IMAGES_EXTENSIONS = [
    'apng',
    'arw',
    'dng',
    'jpe',
    'jpeg',
    'jpg',
    'nef',
    'nrw',
    'tiff',
    'webp',
    'heif',
    'heic',
    'tif',
    'mpo',
    'cr2',
    'sr2',
];

const GALLERY_VIDEO_EXTENSIONS = [
    'hevc', // Это расширение и все ниже - расширения video файлов
    'f4v',
    'mkv',
    '3gpp2',
    '3gpp',
    'qt',
    '3g2',
    '3gp',
    'asf',
    'avi',
    'flv',
    'm2ts',
    'm2v',
    'm4v',
    'matroska',
    'mk3d',
    'mov',
    'mp4',
    'mpeg',
    'mpg',
    'mxf',
    'ogv',
    'vob',
    'webm',
    'wmv',
    'ts',
    '264',
    '265',
    'avc',
    'divx',
];

export const GALLERY_EXTENSIONS = [...GALLERY_IMAGES_EXTENSIONS, ...GALLERY_VIDEO_EXTENSIONS];

export const GALLERY_B2B_IMAGES_EXTENSIONS = ['jpeg', 'jpg', 'png', 'gif'];

export const GALLERY_B2B_VIDEO_EXTENSIONS = ['mpeg', 'mp4', 'mpeg4', 'mpeg-4', 'mpegps', '3gpp', 'avi', 'mov', 'mkv'];

export const GALLERY_B2B_EXTENSIONS = [...GALLERY_B2B_IMAGES_EXTENSIONS, ...GALLERY_B2B_VIDEO_EXTENSIONS];

export const GALLERY_B2B_OTHER_EXTENSIONS = GALLERY_EXTENSIONS.filter((entry) => !GALLERY_B2B_EXTENSIONS.includes(entry));
