import { Icon20Like, Icon20LikeOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { memo, MouseEventHandler } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { DataListControl } from 'reactApp/ui/DataListControl/DataListControl';
import { Hint } from 'reactApp/ui/Hint/Hint';

import styles from './Favorite.css';

interface FavoriteProps {
    className?: string;
    isFavorite?: boolean;
    isAvailable?: boolean;
    onClick?: MouseEventHandler<HTMLDivElement>;
    viewType: EViewMode;
}

export const Favorite = memo<FavoriteProps>(function Favorite({ isFavorite = false, isAvailable = true, onClick, className, viewType }) {
    if (!isAvailable) {
        return null;
    }

    return (
        <Hint text={isFavorite ? 'Убрать из избранных' : 'Добавить в избранные'}>
            <DataListControl.New
                id="favorite-icon"
                type="favorite"
                viewType={viewType}
                className={classNames(styles.root, className, {
                    [styles.favorite]: isFavorite,
                })}
                onClick={onClick}
            >
                {isFavorite ? <Icon20Like /> : <Icon20LikeOutline />}
            </DataListControl.New>
        </Hint>
    );
});
