import { ExtraEmailCheckAPICall } from 'reactApp/api/ExtraEmailCheckAPICall';
import { checkEmailFailure, checkEmailRequest, checkEmailSuccess } from 'reactApp/modules/socialUser/socialUser.module';
import { CheckEmailResponse } from 'reactApp/modules/socialUser/socialUser.types';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

const api = (): Promise<{ data?: CheckEmailResponse }> => new ExtraEmailCheckAPICall().makeRequest({ did: 'Web' });

type CheckEmailActions = ReturnType<typeof checkEmailRequest> | ReturnType<typeof checkEmailSuccess> | ReturnType<typeof checkEmailFailure>;

const checkEmailThunk = (dispatch: ThunkDispatch<null, null, AnyAction>): Promise<CheckEmailActions> =>
    new Promise<CheckEmailActions>((resolve, reject): void => {
        dispatch(checkEmailRequest());

        api()
            .then((response: any): void => resolve(dispatch(checkEmailSuccess(response.data))))
            .catch((error: any): void => reject(dispatch(checkEmailFailure(error))));
    });

export const checkEmail = (): ((dispatch: ThunkDispatch<null, null, AnyAction>) => any) => checkEmailThunk;
