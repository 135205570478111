import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EPromoType, IPromoData, IPromoState } from './promo.types';

const initialState: IPromoState = {
    queue: [],
    currentPromo: undefined,
    lastPromoShownTimestamp: undefined,
};

export const initPromoController = createAction<{ disable: boolean } | undefined>('promo/initPromoController');
export const skipPromo = createAction('promo/skipPromo');
export const showMobileAppPromo = createAction<{ isDark?: boolean }>('promo/showMobileAppPromo');

export const promoSlice = createSlice({
    name: 'promo',
    initialState,
    reducers: {
        clearQueue: (state) => {
            state.queue = [];
        },
        addPromoToShowQueue: (state, { payload }: PayloadAction<IPromoData>) => {
            state.queue = [...state.queue, payload];
        },
        showPromo: (state, { payload }: PayloadAction<EPromoType>) => {
            let promo;
            if (payload) {
                promo = state.queue.find((item) => item.type === payload);
            }
            if (promo) {
                state.currentPromo = promo;
            }
        },
        promoShown: (state, { payload }: PayloadAction<EPromoType>) => {
            let promo;
            if (payload) {
                promo = state.queue.find((item) => item.type === payload);
            }
            if (promo) {
                state.currentPromo = promo;
                state.lastPromoShownTimestamp = new Date();
            }
        },
        removePromo: (state, { payload }: PayloadAction<EPromoType>) => {
            state.queue = state.queue.filter((item) => item.type !== payload);
            if (state.currentPromo?.type === payload) {
                state.currentPromo = undefined;
            }
        },
        clearCurrentPromo: (state) => {
            state.currentPromo = undefined;
        },
    },
});

export const { clearQueue, addPromoToShowQueue, showPromo, promoShown, removePromo, clearCurrentPromo } = promoSlice.actions;

export const promoReducer = promoSlice.reducer;
