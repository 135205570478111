import React, { ReactElement } from 'react';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { ConfirmEmail, Props } from 'reactApp/ui/ConfirmEmail/ConfirmEmail';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { Screen } from 'reactApp/ui/Screen/Screen';

export const ConfirmEmailScreen = (props: Props): ReactElement => {
    return (
        <Screen closable={false}>
            <ConfirmEmail onClose={props.onClose} />
        </Screen>
    );
};

export const ConfirmEmailDialog = (props: Props): ReactElement => {
    return (
        <Dialog
            open
            closeOnDimmerClick
            closeOnEscape
            id="confirmEmail"
            theme="octavius"
            showCloseIcon
            size="large"
            onCancel={props.onClose}
        >
            <ConfirmEmail onClose={props.onClose} isDialog />
        </Dialog>
    );
};

export const renderConfirmEmailScreen = ({ onClose }) => openPopupHelper({ popupName: popupNames.CONFIRM_EMAIL_SCREEN, onClose });
export const renderConfirmEmailDialog = ({ onClose }) => openPopupHelper({ popupName: popupNames.CONFIRM_EMAIL_DIALOG, onClose });
