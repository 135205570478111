import { ReactComponent as ShieldIcon } from 'img/icons/shield.svg';
import React, { memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { sendProtectedAnalytics } from 'reactApp/ui/ProtectedHint/ProtectedHint.analytics';

import styles from './ProtectedTooltip.css';

export const ProtectedTooltip = memo(() => {
    const storage = useSelector(getCurrentStorage);

    const handleClick = useCallback(() => {
        sendProtectedAnalytics({
            action: 'click',
            place: storage,
            platform: 'touch',
        });

        open('/promo/security', '_blank');
    }, [storage]);

    useEffect(() => {
        sendProtectedAnalytics({
            action: 'show',
            place: storage,
            platform: 'touch',
        });
    }, []);

    return (
        <>
            <div className={styles.root} onClick={handleClick}>
                <ShieldIcon />
            </div>
        </>
    );
});

ProtectedTooltip.displayName = 'ProtectedTooltip';
