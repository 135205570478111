import { GiftReceivedAPICall } from 'reactApp/api/billing/gift/GiftReceivedAPICall';
import { ReceivedGiftsFetch } from 'reactApp/modules/giftReceived/giftReceived.constants';

export const receivedGiftsRequest = () => ({
    type: ReceivedGiftsFetch.REQUEST,
});

export const receivedGiftsSuccess = (list) => ({
    type: ReceivedGiftsFetch.SUCCESS,
    list,
});

export const receivedGiftsFailure = (error) => ({
    type: ReceivedGiftsFetch.FAILURE,
    error,
});

export const loadReceivedGifts = () => (dispatch) => {
    dispatch(receivedGiftsRequest());

    return new Promise((resolve, reject) => {
        new GiftReceivedAPICall()
            .makeRequest()
            .then(({ data }) => {
                resolve(dispatch(receivedGiftsSuccess(data)));
            })
            .catch((error) => {
                reject(dispatch(receivedGiftsFailure(error)));
            });
    });
};
