import { Spacing, Text } from '@vkontakte/vkui';
import React, { FC } from 'react';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';

import styles from './QuotaSpacePromo.css';

const messages = {
    title: 'Хватит места на всё!',
    text: (space) => (
        <>
            Больше места для ваших файлов
            <br />
            {space} дополнительного места
            <br />в Почте и Облаке
        </>
    ),
    buttonText: (price) => `Купить за ${price} ₽ в месяц`,
};

interface QuotaSpacePromoProps {
    tariffPrice?: number;
    tariffValue?: string;
    onBuy: () => void;
}

export const QuotaSpacePromo: FC<QuotaSpacePromoProps> = ({ tariffPrice, tariffValue, onBuy }) => (
    <div className={styles.root}>
        <Text weight="2" style={{ fontSize: 20, lineHeight: '26px' }}>
            {messages.title}
        </Text>
        <Spacing size={4} />
        <span className={styles.subTitle}>{messages.text(tariffValue)}</span>
        <Spacing size={12} />
        <div className={styles.img} />
        <Button theme="octavius" primary sizeMode={ButtonSizeMode.extraSmall} onClick={onBuy}>
            <span className={styles.buttonText}>{messages.buttonText(tariffPrice)}</span>
        </Button>
    </div>
);
