export function processPhone(phone) {
    return phone.replace(/^\s*(\+?)(.+)$/g, function(a, b, c) {
        return '+' + c.replace(/\D/g, '');
    });
}

export function formatPhone(phone) {
    return processPhone(phone)
        .replace(/^\+(.{11}).*/, '+$1')
        .replace(/^\+(\d)(\d{3})(\d+)/, '+$1($2)$3')
        .replace(/^\+(\d)(\(\d{3}\))(\d{3})(\d{2})(\d{2})$/, '+$1$2$3-$4-$5');
}

export function isValidPhone(phone) {
    return /^((\+\d)+([0-9]){10})$/.test(phone);
}
