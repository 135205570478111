import _ from 'Cloud/Application/_';
import { DownloadZipV3APICall } from 'reactApp/api/DownloadZipV3APICall';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';

const callApiDownload = (data: any, urlSuffix): any => new DownloadZipV3APICall().makeRequest(data, urlSuffix);

function getStorageApiUrl(storage: string) {
    const { isStock, isPublic, isAlbums } = getStorage(storage);

    if (isStock) {
        return '/stock';
    }

    if (isAlbums) {
        return '/album';
    }

    return isPublic ? '/weblink' : '/home';
}

class FileController {
    public downloadFiles = (idxs: string[], zipFileName: string, storage: string): Promise<string> => {
        const apiParams = _.idParams(storage, idxs);

        return callApiDownload(
            {
                ...apiParams,
                name: zipFileName,
            },
            {
                url: getStorageApiUrl(storage),
            }
        ).then(
            ({ status, data: { key } }): Promise<string> => {
                if (status === 200 && Boolean(key)) {
                    return key;
                }
                return Promise.reject();
            }
        );
    };
}

export const fileController = new FileController();
