import { extInfo } from 'lib/extInfo';
import { getExtension, getItemNameWithoutExt, isFolder as getIsFolder, isVirusItem } from 'reactApp/modules/file/utils';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';
import { letterDefaultName } from 'reactApp/modules/userQuotaCleaner/helpers/constants';
import { QuotaCleanerGroup, QuotaCleanerYearConfig, QuotaCleanerYears } from 'reactApp/modules/userQuotaCleaner/userQuotaCleaner.types';
import { UserQuotaCleanerGroup, UserQuotaGroupType } from 'reactApp/types/QuotaCleaner';
import { normalizeMtime } from 'reactApp/utils/tree.helpers';

const urlBuilder = new UrlBuilder();

export function transformGroup(group: UserQuotaCleanerGroup): QuotaCleanerGroup {
    const { group_id: groupId, group_type: groupType, size, list, total_count: totalCount } = group;
    const storage = EStorageType.quotaCleaner;
    const childs: string[] = [];

    const transformedList = {};
    list.forEach((item) => {
        const {
            view_path: viewPath,
            size,
            name: rawName,
            hash,
            date,
            download_url: downloadUrl,
            delete_id: deleteId,
            correspondents,
            virus_scan: virusScan,
        } = item;
        const isLetter = groupType === UserQuotaGroupType.Letter;
        const name = String(rawName || (isLetter && letterDefaultName));

        const id = viewPath || name;
        childs.push(id);

        const isFolder = getIsFolder(item);
        const isVirus = isVirusItem(item);
        const ext = !isFolder && !isLetter ? getExtension(name) : '';
        const { kind, subKind } = extInfo.get(ext);
        const nameWithoutExt = getItemNameWithoutExt(name, ext);

        const urlBuilderProps = {
            ext,
            id,
            size,
            kind,
            name,
            weblink: '',
            path: viewPath,
            isPublic: false,
            isStock: false,
        };

        const thumbnails = urlBuilder.getThumb({
            ...urlBuilderProps,
            hash,
            dwl_token: null,
        });

        const url = urlBuilder.getUrls({
            ...urlBuilderProps,
            subKind,
        });

        transformedList[id] = {
            id,
            name,
            viewPath,
            isFolder,
            isVirus,
            ext,
            correspondents,
            size,
            nameWithoutExt,
            storage,
            thumbnails,
            date: normalizeMtime(date),
            virusScan,
            url: {
                ...url,
                ...(downloadUrl && { download: downloadUrl }),
            },
            kind,
            deleteId,
            subKind,
            parent: '',
            groupId,
            groupType,
        };
    });

    return {
        groupId,
        groupType,
        size,
        storage,
        list: transformedList,
        id: groupId,
        isLoading: false,
        isLoaded: true,
        childs,
        totalCount,
    };
}

export const transformList = (groups: UserQuotaCleanerGroup[]): QuotaCleanerGroup[] => groups.filter(Boolean).map(transformGroup);

export const transformYearGroupConfig = (groups: UserQuotaCleanerGroup[]): QuotaCleanerYearConfig =>
    groups.reduce((yearGroupsConfig, group) => {
        const { year_config: yearConfig, group_id: groupId, total_count: totalCount, size } = group;
        if (!yearConfig) {
            return yearGroupsConfig;
        }

        yearGroupsConfig[groupId] = {
            config: {},
            currentYear: QuotaCleanerYears.All,
            [QuotaCleanerYears.All]: {
                size,
                totalCount,
            }
        };

        yearConfig.forEach((config) => {
            const { year, total_count: totalCount, size } = config;
            if (size && totalCount) {
                yearGroupsConfig[groupId].config[year] = {
                    size,
                    totalCount,
                };
            }
        });

        return yearGroupsConfig;
    }, {});
