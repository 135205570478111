import { IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';

import { UploadFail } from './UploadFail';

export class OverQuotaFail extends UploadFail {
    static WEB_BACKEND_ERROR = 'overquota';

    radarName = 'fail_{SOURCE}_overquota';
    message = [
        'В Облаке недостаточно места.',
        `${!IS_MY_TEAM ? '<a href="https://help.mail.ru/cloud_web/size/increase" rel="noopener" target="_blank">Увеличить объем</a>' : ''}`,
    ].join(' ');
}
