import { captureException } from '@sentry/browser';
import { logger } from 'lib/logger';
import { BillingInfoAPICall } from 'reactApp/api/billing/BillingInfoAPICall';
import { loadPaidInfoSuccess } from 'reactApp/modules/paidInfo/paidInfo.module';
import { PaidInfoSelectors } from 'reactApp/modules/paidInfo/paidInfo.selectors';
import { ApiPaidInfoResponse } from 'reactApp/modules/paidInfo/paidInfo.types';
import { initProducts, updateProducts } from 'reactApp/modules/products/products.module';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { store } from 'reactApp/store';
import { Tariff } from 'reactApp/types/Billing';

class ProductsController {
    public getTariffById = (id) => {
        const state = store.getState();
        if (ProductsSelectors.getLifeCycleState(state).isLoaded) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return Promise.resolve(ProductsSelectors.getTariffById(state, id));
        }

        return this.loadProducts().then(() => {
            return Promise.resolve(ProductsSelectors.getTariffById(store.getState(), id));
        });
    };

    public isUserWithoutPayment = async (): Promise<boolean> => {
        const state = store.getState();

        try {
            if (!PaidInfoSelectors.getLoadingState(state).isLoaded) {
                const { data } = await new BillingInfoAPICall().makeRequest();

                store.dispatch(loadPaidInfoSuccess(data as ApiPaidInfoResponse));
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return PaidInfoSelectors.isUserWithoutPayment(store.getState());
        } catch (error) {
            logger.error(error);
            captureException(error);
            return true;
        }
    };

    public loadProducts = (): Promise<void> => {
        if (ProductsSelectors.getLifeCycleState(store.getState()).isLoaded) {
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            store.dispatch(
                initProducts({
                    onSuccess: resolve,
                    onError: reject,
                })
            );
        });
    };

    public updateProducts = (productId?: string): Promise<void> =>
        new Promise((resolve, reject) => {
            store.dispatch(
                updateProducts({
                    productId,
                    onSuccess: resolve,
                    onError: reject,
                })
            );
        });

    public getAvailableProducts = async (): Promise<Tariff[]> => {
        try {
            await this.loadProducts();

            return ProductsSelectors.getAvailableProducts(store.getState());
        } catch (error) {
            return [];
        }
    };
}

const productsController = new ProductsController();

export { productsController, ProductsController };
