import { FileType } from 'reactApp/types/FileType';

export const isFolder = (type: FileType): boolean => {
    return [
        FileType.FOLDER,
        FileType.FOLDER_CAMERA,
        FileType.FOLDER_MOUNTED,
        FileType.FOLDER_SHARED,
        FileType.FOLDER_AUTODELETE,
        FileType.FOLDER_AUTODELETE_MONTH,
        FileType.FOLDER_AUTODELETE_WEEK,
    ].includes(type);
};
