import classNames from 'clsx';
import { ReactComponent as IconClose } from 'img/icons/close_s__2.svg';
import React, { memo, useCallback, useMemo } from 'react';
import { IFaceItemProps } from 'reactApp/ui/FaceFilter/FaceFilter.types';
import { Hint } from 'reactApp/ui/Hint/Hint';

import styles from './FaceItem.css';

export const FaceItem = memo(function FaceItem({
    numOfPhotos,
    isSelected = false,
    onClick,
    imageUrl,
    id,
    small = false,
    isMobile = false,
}: IFaceItemProps) {
    const handleOnClick = useCallback(() => {
        onClick(isSelected ? null : id);
    }, [onClick, isSelected, id]);

    const content = useMemo(
        () => (
            <div
                className={classNames({
                    [styles.root]: true,
                    [styles.root_selected]: isSelected,
                    [styles.root_small]: small,
                    [styles.root_mobile]: isMobile,
                })}
                onClick={handleOnClick}
            >
                <img src={imageUrl} />
                <div className={styles.dropdownItemCounter}>{numOfPhotos}</div>
                <div className={styles.cancel}>
                    <IconClose width={13} height={13} />
                </div>
            </div>
        ),
        [handleOnClick, imageUrl, isMobile, isSelected, numOfPhotos, small]
    );

    return (
        <>
            {isMobile ? (
                content
            ) : (
                <Hint text="Показать все фотографии с этим человеком" showDelay>
                    {content}
                </Hint>
            )}
        </>
    );
});
