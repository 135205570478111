import { ETabsName } from 'reactApp/ui/TariffsSection/TariffSection.constants';
import { ITabs } from 'reactApp/ui/TariffsSection/TariffsSection.types';
import { createGaSender } from 'reactApp/utils/ga';

export const isProTab = (tabId) => tabId === ETabsName.pro || tabId === 'pro_ru';

export const isSpecialTab = (tabId) => tabId === ETabsName.special;

export const sendGa = createGaSender('tariffs');

export function getHash() {
    return window.location.hash.slice(1);
}

export function isHashAllowed(tabs: ITabs[], hash: string = getHash()): hash is ETabsName {
    return tabs.some(({ id }) => id === hash);
}

export function getActiveTab(tabs: ITabs[], isAction?: boolean): ETabsName {
    const hash = getHash();

    let defaultTab = isAction ? ETabsName.special : ETabsName.year;
    if (tabs.filter((tab) => tab.id === defaultTab).length === 0) {
        defaultTab = tabs[0].id;
    }

    return hash && isHashAllowed(tabs, hash) ? hash : defaultTab;
}
