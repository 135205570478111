import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getGalleryListCount } from 'reactApp/modules/gallery/gallery.selectors';
import { getSearchCount } from 'reactApp/modules/search/search.selectors';
import { SelectFromCloudContentMod } from 'reactApp/sections/AlbumsPage/SelectFromCloudPopup/components/Content/SelectFromCloudContent';
import { BreadCrumbs } from 'reactApp/ui/Breadcrumbs/Breadcrumbs';
import { BreadcrumbItem } from 'reactApp/ui/Breadcrumbs/Breadcrumbs.types';
import { filesPlural } from 'reactApp/utils/pluralHelpers';

import styles from './SelectFromCloudBreadcrumbs.css';
import { getSelectFromCloudBreadcrumbsById } from './SelectFromCloudBreadcrumbs.helpers';

interface SelectFromCloudBreadcrumbsProps {
    id: string;
    onClick: ({ id }: { id: string }) => void;
    mod: SelectFromCloudContentMod;
}

export const SelectFromCloudBreadcrumbs = memo<SelectFromCloudBreadcrumbsProps>(({ id, onClick, mod }) => {
    const list = getSelectFromCloudBreadcrumbsById(id, mod);
    const itemsGalleryCount = useSelector(getGalleryListCount);
    const itemsSearchCount = useSelector(getSearchCount);
    const itemsCount = mod === SelectFromCloudContentMod.tree ? itemsGalleryCount : itemsSearchCount;

    const handleClick = useCallback(
        (e: React.MouseEvent, breadcrumb: BreadcrumbItem) => {
            e.preventDefault();
            onClick({ id: breadcrumb.id });
        },
        [onClick]
    );

    return (
        <div className={styles.root}>
            <BreadCrumbs breadcrumbs={list} onClick={handleClick} breadcrumbsCount={3} />
            {Boolean(itemsCount) && <div className={styles.counter}>{`${itemsCount} ${filesPlural(itemsCount)}`}</div>}
        </div>
    );
});

SelectFromCloudBreadcrumbs.displayName = 'SelectFromCloudBreadcrumbs';
