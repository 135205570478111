import settings from 'Cloud/settings';
import { IS_PHONE_BROWSER, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { isPublicUploadEnabled } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { store } from 'reactApp/store';
import { put } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

const PUBLIC_UPLOAD_ID = 'public-upload-bubble';

export function* initPublicUploadPromo() {
    const storage = yield* select(getCurrentStorage);
    const { isPublic } = getStorage(storage);
    const isNewbie = yield* select(UserSelectors.isNewbie);
    const isUploadEnabled = yield* select(isPublicUploadEnabled);
    const isAlreadyShown = storeHelper.getValue(PUBLIC_UPLOAD_ID);

    if (
        settings?.request?.action ||
        IS_PHONE_BROWSER ||
        isNewbie ||
        IS_WEBVIEW ||
        !isPublic ||
        !isUploadEnabled ||
        isAlreadyShown
    ) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.publicUpload,
            promoId: PUBLIC_UPLOAD_ID,
            onShow: () => {
                storeHelper.markAsShown(PUBLIC_UPLOAD_ID);
                store.dispatch(promoShown(EPromoType.publicUpload));
            },
            onClose: () => {
                store.dispatch(removePromo(EPromoType.publicUpload));
            },
        })
    );
}
