import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IStorageState } from 'reactApp/modules/storage/storage.types';

const initialState: IStorageState = {
    selectedItem: '',
};

export const scrollToItemAction = createAction<string>('storage/scrollToItemAction');

export const storageReducer = createReducer(initialState, {
    [scrollToItemAction.type]: (state, action: PayloadAction<string>) => {
        const { payload } = action;

        state.selectedItem = payload;
    },
});
