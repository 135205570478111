import { createContext, useEffect, useState } from 'react';
import { IS_IOS_MOBILE } from 'reactApp/appHelpers/configHelpers';

export interface OrientationProps {
    isPortrait: boolean;
    enableRotate: () => void;
    disableRotate: () => void;
    rotateEnabled: boolean;
}

export const OrientationContext = createContext<OrientationProps>({
    isPortrait: false,
    enableRotate: () => null,
    disableRotate: () => null,
    rotateEnabled: false,
});
const checkIsPortrait = () => {
    if (IS_IOS_MOBILE) {
        const { matches } = window.matchMedia('(orientation: portrait)');
        return Boolean(matches);
    }

    // eslint-disable-next-line compat/compat
    const type = window.screen?.orientation?.type;
    if (type) {
        return type === 'portrait-primary' || type === 'portrait-secondary';
    }

    return window.innerHeight > window.innerWidth;
};
export const useOrientation = (): OrientationProps => {
    const [rotateEnabled, setRotateEnabled] = useState(false);
    const enableRotate = () => setRotateEnabled(true);
    const disableRotate = () => setRotateEnabled(false);

    const [isPortrait, setIsPortrait] = useState(checkIsPortrait());

    useEffect(() => {
        const handler = () => {
            setIsPortrait(checkIsPortrait());
        };

        window.addEventListener('resize', handler);
        return () => window.removeEventListener('resize', handler);
    }, []);

    return {
        isPortrait,
        enableRotate,
        disableRotate,
        rotateEnabled,
    };
};
