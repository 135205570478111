import config from 'Cloud/config';
import { extInfo } from 'lib/extInfo';
import { UflrType } from 'lib/uflr';
import escapeRegExp from 'lodash.escaperegexp';
import { path } from 'ramda';
import { IS_ONPREMISE, VIEWERS_CONFIG } from 'reactApp/appHelpers/configHelpers';
import { attachTextFormats, isWopiEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { ENABLED_KINDS_AS_PLAIN_TEXT, ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getSelectedFaceId } from 'reactApp/modules/faces/faces.selectors';
import { getFeatureGalleryViewMode, getFeaturePublicGalleryViewMode } from 'reactApp/modules/features/features.selectors';
import { CloudFile, CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { folderCloudPath } from 'reactApp/modules/uploadList/uploadList.helpers';
import { AccessRights, IPublicInfo } from 'reactApp/types/Tree';
import { promiseTimeout } from 'reactApp/utils/helpers';
import { getAvailableEditorsFor } from 'server/helpers/editors/getEditors';
import { ServerEditor } from 'server/helpers/editors/types';

export { getExtension } from './helpers/getExtention';
export { getFolderVerifiedByKaspersky, getIsItemVerifiedByKaspersky, getIsViewerFileVerifiedByKaspersky } from './kaspersky.utils';

const ENABLE_DOCUMENTS = config.get('ENABLE_DOCUMENTS');

export const ITEM_WITHOUT_HASH_MAX_SIZE = config.get('ITEM_WITHOUT_HASH_MAX_SIZE');
const RE_IS_FOLDER = /^(folder|storage|camera-upload|shared|mounted|invites|domain-folder)$/;

export function isFolder(item): boolean {
    return item && (typeof item.isFolder === 'boolean' ? item.isFolder : RE_IS_FOLDER.test(item.kind));
}

export const isMedia = (item) => item && (item.kind === 'image' || item.kind === 'video');

export const isVideo = (item) => item && item.kind === 'video';

export const isAudio = (item) => item && item.kind === 'audio';

export const isFileForPlayer = (item) => isVideo(item) || isAudio(item);

export const isStreamingUrl = (item) => item && !!item.url?.media?.includes('.m3u8');

export const isImage = (item) => item && item.kind === 'image';

export const isDocument = (item) => item && item.kind === 'document';

export const isPdf = (item) => item?.ext?.toLowerCase() === 'pdf';

export const isEBook = (item: CloudFile | CloudItem): boolean =>
    Boolean(item) && typeof item !== 'string' && 'subKind' in item && item?.subKind === 'ebook';

export const isTextDocument = (item: CloudFile | CloudItem): boolean => item && 'subKind' in item && item.subKind === 'text';

export const isPdfEditable = (item) => {
    if (!item || !isPdf(item)) {
        return false;
    }

    const { size = 0, ext } = item;

    const extParams = extInfo.get(ext);
    const { maxEditableSize } = extParams;

    if (maxEditableSize && size <= maxEditableSize) {
        return true;
    }

    return false;
};

export const isPlainSource = (item: CloudItem, storage?: EStorageType | null) => {
    if ('subKind' in item && ENABLED_KINDS_AS_PLAIN_TEXT.includes(item?.subKind)) {
        if (storage === EStorageType.attaches || storage === EStorageType.viewerAttaches) {
            return Array.isArray(attachTextFormats) && attachTextFormats.includes(item.ext?.toLowerCase());
        }
        return true;
    }

    if (!item || !('ext' in item)) {
        return false;
    }

    const ext = item.ext?.toLowerCase();

    if (!ext) {
        return false;
    }

    return ['txt', 'log'].includes(ext);
};

export const isMediaFolder = (folder) => folder?.flags?.isMedia;

export const isArchive = (item: CloudItem): item is CloudFile => item && 'subKind' in item && item.subKind === 'archive';

export const isMediaFolderAndGalleryEnabled = (state, folder, storage?: EStorageType) => {
    const isPhone = EnvironmentSelectors.isPhone();
    const isFaceFilterActive = getSelectedFaceId(state);

    if ((!isMediaFolder(folder) && !isFaceFilterActive) || isPhone) {
        return false;
    }

    if (storage === EStorageType.public) {
        return Boolean(getFeaturePublicGalleryViewMode(state));
    }

    // CLOUDWEB-14739: если в папке одно фото - не включаем режим галереи.
    if (storage === EStorageType.home && folder?.childs?.length === 1) {
        return false;
    }

    return Boolean(getFeatureGalleryViewMode(state));
};

export function isVirusItem(item): boolean {
    return !!item && (item.virusScan === 'fail' || item.virus_scan === 'fail');
}

export function isBlockedItem(item): boolean {
    return !!item && path(['uflr'], item) === UflrType.blocked;
}

export function getItemNameWithoutExt(name?: string, ext?: string): string {
    if (!name) {
        return '';
    }

    if (!ext) {
        return name;
    }
    // TODO: сейчас верстка расценивает файл ".jpg",
    // как файл без имени с расширением jpg,
    // а сервер при переименовании файлов,
    // считает, что этот файл без расширения.
    const extLength: number | undefined = path(['length'], ext);

    if (
        (extLength && name.toLowerCase().lastIndexOf(ext) === name.length - extLength && IS_ONPREMISE) ||
        (extLength && name.lastIndexOf(ext) === name.length - extLength)
    ) {
        name = name.slice(0, -extLength - 1);
    }

    return name;
}

export const isInlinePreviewable = (item) => {
    if (!item?.ext) {
        return false;
    }

    return extInfo.get(item.ext)?.inlinePreviewable;
};

const extCache = {};
// eslint-disable-next-line complexity
export const isPreviewable = (item: any, storage?: EStorageType, _viewers?: ServerEditor[]) => {
    if (!item) {
        return false;
    }
    /** TODO: CLOUDWEB-16228 сохранить возможность использовать просмотрщики
     *  по умолчанию на аттачах
     */
    const viewers = _viewers?.length ? _viewers : VIEWERS_CONFIG;

    const { size = 0 } = item;
    const ext = IS_ONPREMISE ? String(item.ext).toLowerCase() : item.ext;

    if (size <= ITEM_WITHOUT_HASH_MAX_SIZE) {
        // для файлов размером меньше 21 байта просмотр недоступен
        return false;
    }

    if (isVirusItem(item)) {
        return false;
    }

    let isAttach = storage === EStorageType.attaches || storage === EStorageType.viewerAttaches;

    if (isAttach) {
        const attachType = 'type' in item && item.type;
        const isAttachesCloudStock = isAttach && attachType === EAttachTypes.cloudStock;
        const isAttachesCloud = isAttach && attachType === EAttachTypes.cloud;

        if (isAttachesCloud || isAttachesCloudStock) {
            isAttach = false;
        }
    }

    // Проверяем документ в одну из первых очередей,
    // так как мы можем обновлять значение `maxPreviewableSize` расширений для редакторов
    if (isDocument(item)) {
        if (!ENABLE_DOCUMENTS || !ext) {
            return false;
        }

        if (!(ext in extCache)) {
            extCache[ext] = getAvailableEditorsFor({
                item,
                editors: viewers,
                isEdit: false,
                storage,
                isWopiEnabled,
            })[0];
        }

        return Boolean(extCache[ext]?.id);
    }

    const extParams = extInfo.get(ext, isAttach);
    const { maxPreviewableSize } = extParams;

    if (!maxPreviewableSize || size > maxPreviewableSize) {
        return false;
    }

    const { isStreamingSupported, isNativeSupported } = extParams;
    if (isVideo(item) || isAudio(item)) {
        let supported;
        if (isStreamingUrl(item)) {
            supported = isStreamingSupported || isNativeSupported;
        } else {
            supported = isNativeSupported;
        }

        return Boolean(supported);
    }

    return true;
};

export const normalizePublicApiInfo = (item): IPublicInfo | { weblink: '' } => {
    if (!item?.public && !item?.link) {
        return {
            weblink: '',
        };
    }

    const {
        id: weblink = '',
        expires,
        flags = {},
        ctime,
        count_downloads_left,
        count_downloads_total,
    } = item?.public ?? (item?.link || {});
    return {
        weblink,
        weblinkAccessRights: flags.WRITABLE ? AccessRights.rw : AccessRights.r,
        weblinkExpires: (expires || 0) * 1000,
        weblinkDomestic: flags.DOMESTIC,
        weblinkAutoDelete: flags.AUTODELETE,
        weblinkDownloadable: flags.DOWNLOADABLE,
        ctime,
        count_downloads_left,
        count_downloads_total,
    };
};

export const getUflrStatus = (item) => {
    if (item?.flags?.blocked) {
        return UflrType.blocked;
    }

    if (item?.flags?.restricted) {
        return UflrType.illegal;
    }
};

export function getFolderParams(folder, isPublic) {
    if (isPublic) {
        if (folder.weblink && folder.weblink !== '/') {
            return { weblink: folder.weblink };
        }
    } else if (folder.home) {
        return { home: folder.home };
    }
}

export function normalizeId(id) {
    return (id && id.replace(/\/{2,}/g, '/').replace(/\/$/, '')) || '/';
}

export const getPathParts = (path) => {
    if (!path) {
        return path;
    }

    return path.split('/');
};

export const getParent = (path: string): string => {
    const idSplit = getPathParts(path);

    if (idSplit.length === 2) {
        return '/';
    }

    idSplit.pop();
    return idSplit.join('/');
};

export const getNameById = (id: string): string => {
    const idSplit = getPathParts(id);

    return idSplit[idSplit.length - 1];
};

let iframe;
export function downloadByIframe(url) {
    // Важно. Promise:resolve выполнится только при условии, что в ответе вернется какой-то текстовой контент
    // (например html, json и т.д). В случае успешного начала скачивания файла обработчик не вызовется. Обработчики
    // добавлены чтобы хоть какие-то кейсы можно было закрыть со стороны консьюмера метода
    return new Promise<void>((resolve, reject) => {
        if (!iframe) {
            iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            document.body.appendChild(iframe);
        }

        iframe.onload = resolve;
        iframe.onerror = reject;
        iframe.src = url;
    });
}

export const downloadByLink = async (url, name) => {
    const link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('download', name);
    link.href = url;
    document.body.append(link);
    link.click();

    // Chrome requires the timeout
    await promiseTimeout(100);
    link.remove();
};

const timeOutForWindowFocus = 2000;

export default async function multiDownload(items: [{ url: string; name: string }]): Promise<void> {
    for (const item of items) {
        await promiseTimeout(1000);

        const onBlurPromise = new Promise<void>((resolve) => {
            const onBlur = () => {
                window.removeEventListener('blur', onBlur);

                const onFocus = () => {
                    window.removeEventListener('focus', onFocus);
                    resolve();
                };

                window.addEventListener('focus', onFocus);
                setTimeout(() => window.removeEventListener('focus', onFocus), timeOutForWindowFocus);
            };
            window.addEventListener('blur', onBlur);
            setTimeout(() => window.removeEventListener('blur', onBlur), timeOutForWindowFocus);
        });

        await Promise.race([promiseTimeout(timeOutForWindowFocus), onBlurPromise]);

        await downloadByLink(item.url, item.name);
    }
}

/* Some api returns home field in low case. Replace file name part of the home with item.name, which is in correct case.  */
export const fixItemHomeLowcaseBug = (home, name) => {
    return normalizeId(home?.replace(new RegExp(`${escapeRegExp(name?.toLowerCase())}$`), name));
};

export const PUBLIC_URL_REGEXP = '((\\w{4}/\\w{41})|(\\w{4}/\\w{9})).*';

export const getWeblinkFromPublicId = (id?: string) => id?.match(`^${PUBLIC_URL_REGEXP}`)?.[1];

export const removeTailSlash = (path) => {
    if (!path) {
        return path;
    }
    const hasSlash = path.slice(-1) === '/';
    return (hasSlash ? `${path?.slice(0, -1)}` : `${path}`) || '/';
};

export const getFolderPath = (pathname: string, isFolder = false) => {
    const path = getSlicedHome(removeTailSlash(pathname)) || ROOT_FOLDER_ID;

    return isFolder ? path : folderCloudPath(path);
};

/**
 * @example /home/lol => /lol
 * @example /integration/kek => /kek
 * @example /home => /home
 */
export const getSlicedHome = (pathname: string): string => {
    return pathname.replace(/^\/(home|integration|inline-integration)(\/+.*)/, '$2');
};

const searchPrefix = '/search';

export const getSlicedSearch = (pathname: string): string => {
    const searchStoragePrefix = pathname.match(`${searchPrefix}/+`)?.[0];

    return searchStoragePrefix ? pathname?.slice(searchPrefix.length) : pathname;
};
