import { WeblinkDownloadable } from 'reactApp/modules/public/public.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import {
    Correspondents,
    UserQuotaCleanerGroup,
    UserQuotaCleanerListParams,
    UserQuotaGroupId,
    UserQuotaGroupType,
} from 'reactApp/types/QuotaCleaner';
import { Kind, SubKind, VirusScan } from 'reactApp/types/Tree';

interface AdditionalProps extends WeblinkDownloadable {
    home?: string;
    isInFavorites?: boolean;
}

export type QuotaCleanerItem = Resolve<
    AdditionalProps & {
        id: string;
        isFolder: boolean;
        isVirus: boolean;
        ext: string;
        nameWithoutExt: string;
        thumbnails?: Record<string, string>;
        subKind: SubKind;
        storage: EStorageType;
        parent: string;
        groupId: UserQuotaGroupId;
        groupType: UserQuotaGroupType;
        deleteId: string;
        viewPath: string;
        correspondents?: Correspondents;
        size: number;
        name: string;
        kind: Kind;
        virusScan: VirusScan;
        weblinkDownloadable?: boolean;
        count_downloads_total?: number;
        count_downloads_left?: number;
        date: number;
    }
>;

export type PendingQuotaCleanerGroup = {
    totalCount: number | null;
    size: number | null;
    list: Record<string, QuotaCleanerItem>;
    childs: string[];
    isLoading: boolean;
    isLoaded: boolean;
    id: string;
    storage: EStorageType;
    groupId: UserQuotaGroupId;
    groupType: UserQuotaGroupType;
};

export type QuotaCleanerGroup = Omit<UserQuotaCleanerGroup, 'list' | 'group_id' | 'group_type' | 'total_count'> & {
    totalCount: number;
    list: Record<string, QuotaCleanerItem>;
    groupId: UserQuotaGroupId;
    groupType: UserQuotaGroupType;
    storage: EStorageType;
    childs: string[];
    id: string;
    isLoading: boolean;
    isLoaded: boolean;
    hasMoreToLoad?: boolean;
};

export enum QuotaCleanerYears {
    All = 'all',
}

type QuotaCleanerYearFields = {
    size: number;
    totalCount: number;
};

export type QuotaCleanerYearGroupConfig = {
    config: Record<string, QuotaCleanerYearFields>;
    currentYear: string | QuotaCleanerYears;
    [QuotaCleanerYears.All]: QuotaCleanerYearFields;
};

export type QuotaCleanerYearConfig = Partial<Record<UserQuotaGroupId, QuotaCleanerYearGroupConfig>>;

export type QuotaCleanerGroupParam = UserQuotaCleanerListParams & {
    offset?: number;
    limit?: number;
};

export interface State {
    groups: QuotaCleanerGroup[] | [];
    groupsParams: Record<UserQuotaGroupId, QuotaCleanerGroupParam> | null;
    currentGroup: QuotaCleanerGroup | PendingQuotaCleanerGroup | null;
    isGroupsLoading: boolean;
    isGroupsLoadingError: boolean;
    isGroupsLoaded: boolean;
    needGroupsReload: boolean;
    isMessagesRemoveNeeded: boolean;
    yearGroupsConfig: QuotaCleanerYearConfig;
    isToggledYearFilterOpen: boolean;
    isItemsDeleteProcess: boolean;
    isItemsDeleteError: boolean;
}

export type QuotaCleanerListParam = {
    groups: UserQuotaCleanerListParams[];
};
