import browser from 'Cloud/browser';
import config from 'Cloud/config';
import React, { ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_TABLET_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { renderBizPaidEditorDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog.helpers';
import { openCreateCopyOfNoneditableFileModal } from 'reactApp/components/CreateCopyOfNoneditableFileModal/helper';
import { SharingDropdown } from 'reactApp/components/SharingWindow/Dropdown/SharingDropdown';
import {
    ACCESS_FILE_RIGHTS_OPTIONS,
    ACCESS_FOLDER_RIGHTS_OPTIONS,
    QA_VALUE,
    TOOLTIP_OFFSET,
} from 'reactApp/components/SharingWindow/Sharing.constants';
import { sendGa } from 'reactApp/components/SharingWindow/Sharing.helpers';
import { AccessStatus } from 'reactApp/components/SharingWindow/Sharing.types';
import styles from 'reactApp/components/SharingWindow/Weblink/SharingWeblink.css';
import { getEditorsFor, getIsFileForCoEditing } from 'reactApp/modules/editor/editor.selectors';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFeatureEditingNoneditableFormats } from 'reactApp/modules/features/features.selectors';
import { toggleWeblinkEditableRequest, toggleWeblinkUploadRequest } from 'reactApp/modules/modifying/modifying.actions';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getItemById } from 'reactApp/modules/storage/storage.selectors';
import { RootState } from 'reactApp/store';
import { AccessRights } from 'reactApp/types/Tree';
import { Dropdown } from 'reactApp/ui/Dropdown/Dropdown';
import { DropdownMenu } from 'reactApp/ui/Dropdown/DropdownMenu';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';
import { noop } from 'reactApp/utils/helpers';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

const IS_FREE_B2B_BIZ_EDITORS_USER = config.get('FREE_B2B_BIZ_EDITORS_USER');

const getAccessRights = (isReadOnly, isFolder, isPublicReadWrite): AccessStatus => {
    return (isPublicReadWrite && !isReadOnly && (isFolder ? AccessStatus.Upload : AccessStatus.Edit)) || AccessStatus.ReadOnly;
};

// eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
export const useAccessRights = (item, isSharedOrMouted, onClose) => {
    const dispatch = useDispatch();
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    const isFolder = item?.isFolder;
    const storage = useSelector(getCurrentStorage);
    const editingNoneEditableFormatsFeature = useSelector((state: RootState) =>
        getFeatureEditingNoneditableFormats(state, { file: item, storage })
    );
    const convertExt = editingNoneEditableFormatsFeature?.ext || '';
    const convertedStorage = editingNoneEditableFormatsFeature?.storage;
    const isConvertFileForCoEditing = useSelector((state: RootState) => getIsFileForCoEditing(state, { ...item, ext: convertExt }));
    const isFileForCoEditing = useSelector((state: RootState) => getIsFileForCoEditing(state, item));
    const isEditable = !!useSelector((state: RootState) => getEditorsFor(state, convertExt ? { ...item, ext: convertExt } : item))?.length;
    const isDocumentForEdit =
        (item?.kind === 'document' && IS_FREE_B2B_BIZ_EDITORS_USER) ||
        isFileForCoEditing ||
        (isConvertFileForCoEditing && !(IS_TABLET_BROWSER || browser.isIpadOs()) && isEditable);
    const isPublic = Boolean(item?.weblink);
    const parent = useSelector((state: RootState) => getItemById(state, item?.parent));

    const isReadOnlyItem = item?.isReadOnly;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isReadOnlyParent = parent?.isReadOnly;

    const isReadOnly = !isReadOnlyItem && isSharedOrMouted.isMounted ? isReadOnlyParent : isReadOnlyItem;

    const options = isFolder ? ACCESS_FOLDER_RIGHTS_OPTIONS : ACCESS_FILE_RIGHTS_OPTIONS;
    const isRO = isFolder ? isReadOnly : isReadOnly || !isDocumentForEdit;
    const isPublicReadWrite = item?.weblinkAccessRights === AccessRights.rw;
    const accessRights = getAccessRights(isReadOnly, isFolder, isPublicReadWrite);
    const isHidden = isFolder ? isRO : !isEditable || isRO;

    const renderAccessRightsDropdown = useCallback(
        (onClick): ReactElement => {
            const list = Object.values(options).map((item) => ({
                id: item.id,
                text: item.text,
                check: item.id === accessRights,
                qaValue: item.id,
            }));

            const needOmitEdit = (!isDocumentForEdit && !isFolder) || isReadOnlyParent;
            if (needOmitEdit) {
                const editItemIdx = list.findIndex((item) => item.id === AccessStatus.Edit);
                list.splice(editItemIdx, 1);
            }

            return <DropdownMenu list={list} theme="octavius" onClick={onClick} className={styles.dropdownMenu} />;
        },
        [accessRights, isDocumentForEdit, isFolder, isReadOnlyParent, options]
    );

    const handleAccessRightsChange = useCallback(
        (id): void => {
            if (!isPublic || accessRights === id) {
                return;
            }

            sendGa(accessRights === AccessStatus.ReadOnly ? 'access-rights' : 'ta-access-rights');
            sendPaymentGa({
                eventCategory: ECategoryGa.public,
                action: 'edit-type-access',
                type_access: id,
                public_id: item?.weblink,
            });

            if (isDocumentForEdit) {
                if (isConvertFileForCoEditing && convertedStorage) {
                    onClose();
                    openCreateCopyOfNoneditableFileModal({
                        file: item,
                        editingNoneEditableFormats: { storage: convertedStorage, ext: convertExt },
                        fromSharePopup: true,
                    });
                    return;
                }
                if (IS_FREE_B2B_BIZ_EDITORS_USER && !isEditable && isReadOnly) {
                    renderBizPaidEditorDialog();
                    return;
                }

                dispatch(toggleWeblinkEditableRequest({ item }));
            } else {
                dispatch(toggleWeblinkUploadRequest({ item }));
            }
        },
        [accessRights, dispatch, isConvertFileForCoEditing, isDocumentForEdit, isEditable, isPublic, isReadOnly, item, onClose, storage]
    );

    const renderAccessRights = useCallback((): ReactElement => {
        const item = options[accessRights];

        return (
            <div className={styles.rightItem} data-qa-item={QA_VALUE.accessItem}>
                <span className={styles.rightTitle}>Пользователи могут</span>
                <div className={styles.dropdown}>
                    <Dropdown
                        withinDialog
                        theme="octavius"
                        qaValue={item?.id}
                        as={SharingDropdown}
                        value={isPhone ? item.text : item.text.toLowerCase()}
                        content={renderAccessRightsDropdown}
                        onClickItem={handleAccessRightsChange}
                        placement={tooltipPlacements.BOTTOM_LEFT}
                        qaValueContent={QA_VALUE.accessDropdownContent}
                        tooltipOffset={TOOLTIP_OFFSET}
                        animatedTooltip
                        showOnMount={isFileForCoEditing}
                        description="Пользователи могут"
                        tooltipClassName={isPhone ? styles.tooltip : undefined}
                        isPhone={isPhone}
                    />
                </div>
            </div>
        );
    }, [accessRights, handleAccessRightsChange, isFileForCoEditing, isPhone, options, renderAccessRightsDropdown]);

    return isHidden ? noop : renderAccessRights;
};
