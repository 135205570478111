import { Headline, Radio, RadioGroup, Spacing, Text, Title } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { VK_UI_DARK_THEME } from 'reactApp/constants';
import { sendQuotaGa } from 'reactApp/sections/QuotaLanding/QuotaLanding.helpers';
import { formatPrice, getMonthProductPrice } from 'reactApp/utils/priceHelpers';

import styles from './UpsaleMonthTariffDialog.css';
import { IProps } from './UpsaleMonthTariffDialog.types';

export const UpsaleMonthTariffDialog = memo(function UpsaleMonthTariffDialog(props: IProps): ReactElement {
    const { monthProduct, yearProduct, yearSavingDiscount, activePeriod, isMobile, resolve } = props;

    const [chosenPeriod, setChosenPeriod] = useState(activePeriod);

    const sendUpsaleDialogAnalytics = useCallback(
        (label: string) => {
            sendQuotaGa({
                action: 'modal-choose-tariff',
                label,
                selectedPeriod: chosenPeriod,
            });
        },
        [chosenPeriod]
    );

    const onClose = useCallback(
        (event) => {
            if (event.target.innerText === 'Отменить') {
                sendUpsaleDialogAnalytics('cancel');
            } else {
                sendUpsaleDialogAnalytics('close');
            }
            props.onClose();
        },
        [props, sendUpsaleDialogAnalytics]
    );

    useEffect(() => {
        sendUpsaleDialogAnalytics('show');
    }, [sendUpsaleDialogAnalytics]);

    const onConfirm = useCallback(() => {
        props.onClose();

        sendUpsaleDialogAnalytics('continue');

        if (chosenPeriod === 'month') {
            resolve(monthProduct.id, monthProduct.price);
        } else {
            resolve(yearProduct.id, yearProduct.price);
        }
    }, [props, chosenPeriod, monthProduct, yearProduct, resolve, sendUpsaleDialogAnalytics]);

    const renderHeader = useCallback(
        () => <Title className={classNames({ [styles.titleMobile]: isMobile })}>Выберите выгодный тариф</Title>,
        [isMobile]
    );

    const yearMonthProductPrice = useMemo(() => getMonthProductPrice(yearProduct), [yearProduct]);
    const monthProductPrice = useMemo(() => getMonthProductPrice(monthProduct), [monthProduct]);

    const switchPeriod = useCallback(
        (period) => {
            sendUpsaleDialogAnalytics('switch');
            setChosenPeriod(period);
        },
        [setChosenPeriod, sendUpsaleDialogAnalytics]
    );

    const renderContent = useCallback(() => {
        return (
            <>
                <RadioGroup className={classNames(styles.radioGroup, { [styles.contentMobile]: isMobile })}>
                    <Radio
                        className={classNames(styles.radio, {
                            [styles['radio_active']]: chosenPeriod === 'year',
                        })}
                        name="activePeriod"
                        value="year"
                        description={
                            <Text className={classNames(styles.radioDescription, styles.yearPeriodDescription)} weight="3">
                                При оплате за год вы сэкономите {formatPrice((monthProductPrice - yearMonthProductPrice) * 12)} ₽
                            </Text>
                        }
                        checked={chosenPeriod === 'year'}
                        onClick={() => switchPeriod('year')}
                    >
                        <Title className={styles.radioTitle} level="2">
                            {formatPrice(yearMonthProductPrice)} ₽ в месяц
                        </Title>
                        {yearSavingDiscount ? (
                            <div className={styles.saving}>
                                <Headline className={styles.savingText} weight="2">
                                    -{yearSavingDiscount}%
                                </Headline>
                            </div>
                        ) : null}
                    </Radio>

                    <Spacing size={isMobile ? 8 : 12} />

                    <Radio
                        className={classNames(styles.radio, {
                            [styles['radio_active']]: chosenPeriod === 'month',
                        })}
                        name="activePeriod"
                        value="month"
                        description={
                            <Text className={styles.radioDescription} weight="3">
                                Ежемесячная оплата
                            </Text>
                        }
                        checked={chosenPeriod === 'month'}
                        onClick={() => switchPeriod('month')}
                    >
                        <Title className={styles.radioTitle} level="2">
                            {formatPrice(monthProductPrice)} ₽ в месяц
                        </Title>
                    </Radio>
                </RadioGroup>
            </>
        );
    }, [chosenPeriod, monthProductPrice, yearMonthProductPrice, yearSavingDiscount, switchPeriod, isMobile]);

    const containsDarkTheme = document.body.classList.contains(VK_UI_DARK_THEME);

    return (
        <BaseConfirmDialog
            dataQAId="upsale-month-tariff"
            renderHeader={renderHeader}
            size="average"
            successButtonText={isMobile ? 'Выбрать' : 'Продолжить'}
            cancelButtonText="Отменить"
            buttons={isMobile ? [EButtonType.accept] : [EButtonType.accept, EButtonType.cancel]}
            onClose={onClose}
            onSuccess={onConfirm}
            renderContent={renderContent}
            closeOnDimmerClick
            isDarkTheme={containsDarkTheme}
        />
    );
});
