import { extInfo } from 'lib/extInfo';
import { IS_PUBLIC_ALBUM } from 'reactApp/appHelpers/configHelpers';
import { getFeaturePublicAlbumTemporaryUrl } from 'reactApp/modules/features/features.selectors';
import { getExtension, getItemNameWithoutExt, getUflrStatus, normalizePublicApiInfo } from 'reactApp/modules/file/utils';
import { MalwareStatus } from 'reactApp/modules/start/start.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';
import { store } from 'reactApp/store';
import { IPublicInfo } from 'reactApp/types/Tree';
import { normalizeMtime } from 'reactApp/utils/tree.helpers';

import { filterUflrFiles } from './public.helpers';
import { IAlbumApi, IAlbumItemApi } from './publicAlbum.types';

const urlBuilder = new UrlBuilder();

export const normalizeAlbumItem = (item, weblink: string): IAlbumItemApi => {
    const ext = getExtension(item);
    const name = item.name;
    const id = item.element_id;
    const size = item.size;
    const isVirus = item?.malware?.status === MalwareStatus.infected;
    const { kind, subKind } = extInfo.get(ext);
    const path = `${weblink}/${id}`;

    const urlBuilderParams = {
        id,
        ext,
        size,
        path,
        name,
        kind,
        weblink: path,
        isPublic: true,
        subKind,
    };

    return {
        name,
        type: item.type,
        mtime: normalizeMtime(item.mtime || item.time),
        size,
        id: path,
        uflr: getUflrStatus(item),
        storage: EStorageType.public,
        ext,
        nameWithoutExt: getItemNameWithoutExt(name, ext),
        parent: weblink,
        kind,
        subKind,
        isFolder: false,
        weblink: path,
        albumId: id,
        virusScan: isVirus ? 'fail' : 'pass',
        thumbnails: urlBuilder.getThumb(urlBuilderParams),
        url: urlBuilder.getUrls(urlBuilderParams),
    };
};

export const normalizeAlbum = (item): IAlbumApi => {
    const weblinkInfo = normalizePublicApiInfo(item) as IPublicInfo;
    const list = item.list?.map((el) => normalizeAlbumItem(el, weblinkInfo.weblink)) || [];
    const filteredItems = filterUflrFiles(list);

    return {
        ...weblinkInfo,
        cursor: item.cursor,
        id: weblinkInfo.weblink,
        kind: 'folder',
        name: item.title,
        childs: filteredItems.map((item) => item.weblink),
        isLoading: false,
        isLoaded: true,
        hasMoreToLoad: Boolean(item.cursor),
        parent: '',
        flags: { isMedia: true },
        count: {
            // Апи не присылает количество файлов
            files: filteredItems.length,
            folders: 0,
            all: filteredItems.length,
            loaded: filteredItems.length,
        },
        isFolder: true,
        albumId: item.album_id,
        storage: EStorageType.public,
        list: filteredItems,
        type: 'album',
    };
};

/**
 * Временно альбомы открываются по урлу /album/ вместо /publiс/
 * Это сделано для того, что урлы альбомов не открывались через диалинк в приложении
 * до тех пор пока мобилки не поддержат публичные альбомы
 */
export const isPublicAlbumTemporaryUrl = () => {
    if (!IS_PUBLIC_ALBUM) {
        return;
    }
    const isAlbumTemporaryUrlFeatureEnabled = getFeaturePublicAlbumTemporaryUrl(store.getState());
    const isAlbumLocation = location.pathname?.match(/^\/album/);

    return isAlbumTemporaryUrlFeatureEnabled && isAlbumLocation;
};
