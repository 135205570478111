import { WelcomeAttachesRepeating } from 'reactApp/components/WelcomeAttaches/WelcomeAttachesRepeating';
import { WelcomeDialogAllDocuments, WelcomeDialogAttachesNew } from 'reactApp/ui/WelcomeDialogNew/WelcomeDialogNew';
import { renderModalDialog } from 'reactApp/utils/createDialogToolkit';

import WelcomeDialogConnected from './WelcomeDialog';

export const renderWelcomeDialogHelper = renderModalDialog(WelcomeDialogConnected, 'WelcomeDialog');
export const renderWelcomeRepeatingAttachesDialogHelper = renderModalDialog(WelcomeAttachesRepeating, 'WelcomeAttachesRepeating');
export const renderWelcomeDialogNewAttaches = renderModalDialog(WelcomeDialogAttachesNew, 'WelcomeDialogAttachesNew');

export const renderWelcomeAllDocumentsDialog = renderModalDialog(WelcomeDialogAllDocuments, 'WelcomeAllDocumentsDialog');
