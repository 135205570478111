import browser from 'Cloud/browser';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SHOW_CASE } from 'reactApp/appHelpers/configHelpers';
import { checkUpdateLicenseRequest } from 'reactApp/modules/profile/profile.module';
import { BreadcrumbsContainer } from 'reactApp/ui/BreadcrumbsContainer/BreadcrumbsContainer';
import { Showcase } from 'reactApp/ui/Showcase/Showcase';

const { showcaseItemMockData } = require('reactApp/ui/Showcase/ShowcaseItem/ShowcaseItem.data');

const showCaseData = SHOW_CASE || { recommendItems: showcaseItemMockData, popularItems: [] };

export const MailRecommendPage = (): ReactElement => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(checkUpdateLicenseRequest());
    }, []);

    return (
        <div>
            <BreadcrumbsContainer />
            <Showcase items={showCaseData.recommendItems} itemsPopular={showCaseData.popularItems} isWindows={browser.isWindows()} />
        </div>
    );
};
