import React, { memo } from 'react';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { Link } from 'reactApp/ui/Link/Link';
import { ChevronLeftOutlineIcon } from 'reactApp/ui/VKUIIcons';

import styles from './BackToRoot.css';

const gotToRoot = () => {
    location.href = ROOT_FOLDER_ID;
};

export const BackToRoot = memo(function BannerOffer(): JSX.Element | null {
    return (
        <Link className={styles.root} handleGoTo={gotToRoot} noLink>
            <ChevronLeftOutlineIcon className={styles.icon} />
            <span className={styles.label}>Перейти в облако</span>
        </Link>
    );
});
