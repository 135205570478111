import { extInfo } from 'lib/extInfo';
import { Uflr } from 'lib/uflr';
import { getExtension, getItemNameWithoutExt, getPathParts } from 'reactApp/modules/file/utils';
import { normalizeFlags } from 'reactApp/modules/home/home.helpers';
import { isPublicApiResponseType, PublicItem } from 'reactApp/modules/public/public.types';
import { ESortOder, ESortTypes } from 'reactApp/modules/sort/sort.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';
import { Sort } from 'reactApp/types/Tree';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { normalizeMtime, renameCameraUploadFolder } from 'reactApp/utils/tree.helpers';
import { IPublicApiFolder, IPublicFolder, isPublicAlbum, isPublicFile } from 'server/types/context/IPublicFolder';

const urlBuilder = new UrlBuilder();

export const filterUflrFiles = (items) => Uflr.exclude(items);

const getInitialNormalizedItem = (item, parent) => {
    const id = item.weblink;

    let parentId = parent;

    if (!parentId || parentId === '/') {
        const idSplit = id.split('/');

        if (idSplit.length === 2) {
            parentId = '';
        } else {
            idSplit.pop();
            parentId = idSplit.join('/');
        }
    }

    return {
        id,
        parent: parentId,
        name: item.name,
        home: item.home,
        weblink: item.weblink,
        size: item.size,
        weblinkAccessRights: item.weblinkDownloadable ? undefined : item.weblinkAccessRights,
        weblinkDownloadable: item.weblinkDownloadable,
        count_downloads_total: item.count_downloads_total,
        count_downloads_left: item.count_downloads_left,
        weblinkExpires: item.weblinkExpires,
        weblinkAutoDelete: item.weblinkAutoDelete,
    };
};

export const normalizePublicFile = (item, parent?: string): PublicItem => {
    let author;
    const ext = getExtension(item);
    const nameWithoutExt = getItemNameWithoutExt(item.name, ext);
    const { kind, subKind } = extInfo.get(ext);
    if (item?.attr?.actor) {
        author = item.attr.actor;
    }

    const normalizedItem = getInitialNormalizedItem(item, parent);

    return {
        ...normalizedItem,
        name: renameCameraUploadFolder(item),
        storage: EStorageType.public,
        hash: item.hash,
        mtime: normalizeMtime(item.mtime),
        isFolder: false,
        kind,
        subKind,
        ext,
        author,
        nameWithoutExt,
        virusScan: item.virus_scan,
        __reduxTree: true,
        uflr: item.uflr,
        thumbnails: urlBuilder.getThumb({
            hash: item.hash,
            ext,
            id: normalizedItem.id,
            size: normalizedItem.size,
            kind,
            name: item.name,
            path: item.weblink,
            isPublic: true,
            isStock: false,
            dwl_token: null,
            weblink: normalizedItem.id,
        }),
        url: urlBuilder.getUrls({
            ext,
            isPublic: true,
            id: normalizedItem.id,
            weblink: normalizedItem.id,
            size: normalizedItem.size,
            kind,
            subKind,
            isStock: false,
            name: item.name,
            path: item.home,
        }),
    };
};

const getItemSizeWithoutRestricted = (item: IPublicFolder): number => {
    if (!item) {
        return 0;
    }

    return (
        item.list?.reduce((acc, listItem) => (isPublicFile(listItem) && listItem?.flags?.restricted ? acc : listItem?.size + acc), 0) ||
        item.size ||
        0
    );
};

export const normalize = ({
    list,
    item,
    loaded,
    isLoading,
    parent,
    isOwn,
    ownerEmail,
}: {
    list: Record<string, PublicItem>;
    item: IPublicApiFolder;
    loaded?: boolean;
    isLoading?: boolean;
    parent?: string;
    isOwn?: boolean;
    ownerEmail?: string;
}) => {
    if (isPublicAlbum(item)) {
        return;
    }

    if (isPublicFile(item)) {
        list[item.weblink] = normalizePublicFile(item, parent);
    } else {
        const normalizedItem = getInitialNormalizedItem(item, parent);
        const sizeWithoutRestricted = getItemSizeWithoutRestricted(item);
        const foldersCount = item?.count?.folders || 0;
        const filesCount = item?.count?.files || 0;
        const count = {
            folders: foldersCount,
            files: filesCount,
            all: foldersCount + filesCount,
            loaded: 0,
        };
        const childs: string[] = [];
        const authors: Record<string, number> = {};
        const sort: Sort = { type: ESortTypes.name, order: ESortOder.asc };

        if (isPublicApiResponseType(item)) {
            const listFiltered = filterUflrFiles(item?.list) || [];
            const ghostItemsFromSSRCount = item?.list?.filter((o) => 'ghostItemFromSSR' in o && o.ghostItemFromSSR)?.length || 0;
            const uflrCount = item?.list?.length - listFiltered.length;
            if (uflrCount > 0) {
                count.files -= uflrCount;
                count.all -= uflrCount;
            }

            count.loaded = (item?.list?.length || 0) - ghostItemsFromSSRCount;
            (listFiltered || []).forEach((child) => {
                childs.push(child.weblink);

                if (isPublicFile(child) && child?.attr?.actor) {
                    authors[child.attr.actor] = 1 + (authors[child.attr.actor] || 0);
                } else if (isPublicFile(child) && ownerEmail && isOwn) {
                    authors[ownerEmail] = 1 + (authors[ownerEmail] || 0);
                }

                const cachedItem = list[child.weblink];

                if (!cachedItem || !cachedItem.isFolder || !cachedItem.isLoaded) {
                    normalize({ list, item: child, parent: normalizedItem.id });
                }
            });
            item.list = listFiltered;
        }
        list[normalizedItem.id] = {
            ...normalizedItem,
            sizeWithoutRestricted,
            storage: EStorageType.public,
            __reduxTree: true,
            sort,
            count,
            kind: item.kind,
            isFolder: true,
            hasMoreToLoad: Boolean(loaded && count.loaded < count.all),
            rev: item.rev,
            // @ts-ignore
            tree: item.tree,
            isLoaded: !!loaded,
            isLoading: !!isLoading,
            authors,
            childs,
            flags: normalizeFlags(
                item.flags,
                !list[normalizedItem.id] || !(list[normalizedItem.id] as any)?.isLoaded,
                (list[normalizedItem.id] as any)?.flags
            ),
        };
    }
};

export const sendPublicDwh = ({ action, eventCategory = ECategoryGa.public, ...data }): void => {
    sendDwh({ eventCategory, action, dwhData: data });
};

export const getPublicItemId = (id: string): string => {
    const idSplit = getPathParts(id);

    return idSplit[idSplit.length - 1];
};

export const normalizePublicId = (id: string) => id.replace(/^\/*(public|stock|album)\/(.*)/, '$2').replace(/^\/|\/$/g, '');
