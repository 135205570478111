import { path } from 'ramda';
import {
    StoryActivateApiCall,
    StoryInfoApiCall,
    StoryListApiCall,
    StoryMarkViewApiCall,
    StoryUnviewedCountApiCall,
    StoryUnviewedResetApiCall,
} from 'reactApp/api/StoryApiCall';
import { isStoryUnviewedReset } from 'reactApp/appHelpers/featuresHelpers';
import { loggerSaga } from 'reactApp/modules/logger/logger.saga';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { STORIES_LIST_API_LIMIT } from 'reactApp/modules/stories/stories.constants';
import {
    activateStory,
    getStoryInfoFail,
    getStoryInfoRequest,
    getStoryInfoSuccess,
    getStoryListFail,
    getStoryListLoadMore,
    getStoryListRequest,
    getStoryListSuccess,
    getStoryUnviewedCountRequest,
    getStoryUnviewedCountSuccess,
    markStoryAsViewed,
    showStory,
} from 'reactApp/modules/stories/stories.module';
import { getCurrentCursor, getCurrentStory, getStoryForViewer, getStorySummaryById } from 'reactApp/modules/stories/stories.selectors';
import { closeViewer, openViewer, updateViewerData } from 'reactApp/modules/viewer/viewer.module';
import { call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

export const storyListApiCall = ({ limit = STORIES_LIST_API_LIMIT, cursor = null }: { limit?: number; cursor?: string | null } = {}) =>
    new StoryListApiCall().makeRequest({ limit, cursor });
export const storyMarkViewApiCall = (id: string, lastViewedId?: string) =>
    new StoryMarkViewApiCall().makeRequest({ story_id: id, is_fully_viewed: !lastViewedId, last_viewed_element_id: lastViewedId });
export const storyActivateApiCall = () => new StoryActivateApiCall().makeRequest();
export const storyInfoApiCall = (id: string) => new StoryInfoApiCall().makeRequest({ id });
// В порталке limit: 10, потому тут тоже фиксируем его, чтобы числа всегда совпадали с порталкой
export const storyUnviewedCountApiCall = () => new StoryUnviewedCountApiCall().makeRequest({ limit: 10 });
export const storyUnviewedResetApiCall = () => new StoryUnviewedResetApiCall().makeRequest();

function* handleGetStoryListRequest() {
    try {
        yield put(getStoryUnviewedCountRequest());

        const { data } = yield call(storyListApiCall);
        const { data: unviewedCount } = yield call(storyUnviewedCountApiCall);
        if (data.list.length && unviewedCount.count > 0 && isStoryUnviewedReset) {
            yield call(storyUnviewedResetApiCall);
        }

        yield put(getStoryListSuccess(data));
    } catch (error) {
        if (path(['status'], error) === 404) {
            yield put(activateStory());
        }
        yield loggerSaga({ error, action: getStoryListFail('exception') });
    }
}

function* handleLoadMore() {
    try {
        const cursor = yield select(getCurrentCursor);
        if (!cursor) {
            return;
        }

        const { data } = yield call(storyListApiCall, { cursor });

        yield put(getStoryListSuccess(data));
    } catch (error) {
        if (path(['status'], error) === 404) {
            yield put(activateStory());
        }
        yield loggerSaga({ error, action: getStoryListFail('exception') });
    }
}

function* handleMarkStoryAsViewed(action) {
    try {
        const { storyId, lastViewedId } = action.payload;

        if (!storyId) {
            return;
        }

        let elementId;

        if (lastViewedId) {
            const story = yield select(getCurrentStory);
            if (story) {
                elementId = story.header.content.find((item) => item.file === lastViewedId)?.element_id;
            }
        }

        yield call(storyMarkViewApiCall, storyId, elementId);

        yield put(getStoryUnviewedCountRequest());
    } catch (error) {
        yield loggerSaga({ error });
    }
}

function* handleGetStoryInfoRequest(action) {
    try {
        const { id } = action.payload;
        const { data } = yield call(storyInfoApiCall, id);

        yield put(getStoryInfoSuccess(data));
    } catch (error) {
        yield loggerSaga({ error, action: getStoryInfoFail('exception') });
    }
}

function* handleStoryActivate() {
    try {
        yield call(storyActivateApiCall);
    } catch (error) {
        yield loggerSaga({ error });
    }
}

function* handleShowStory(action) {
    try {
        const { storyId, type, item } = action.payload;

        if (!storyId || !type) {
            return;
        }

        const summary = yield select(getStorySummaryById, storyId);

        const data = {
            itemId: item ?? summary?.cover?.file,
            itemStorage: EStorageType.story,
            gaSuffix: '',
            itemIds: [],
        };
        yield put(openViewer(data));

        let story = yield select(getCurrentStory);

        if (!story || story.id !== storyId) {
            yield put(getStoryInfoRequest({ id: storyId, type }));

            yield take([getStoryInfoSuccess, getStoryInfoFail]);

            story = yield select(getCurrentStory);
        }

        const { content } = (yield select(getStoryForViewer, '')) ?? {};

        if (!story || !content) {
            yield put(closeViewer());
            return;
        }

        let itemId = summary?.cover?.file || content[0]?.file;
        if (item && content.some((contentItem) => contentItem?.file === item)) {
            itemId = item;
        }

        yield put(updateViewerData({ itemId, itemStorage: EStorageType.story, gaSuffix: 'story', itemIds: [] }));
    } catch (error) {
        yield loggerSaga({ error, action: closeViewer() });
    }
}

function* handleGetStoryUnviewedCount() {
    try {
        const { data } = yield call(storyUnviewedCountApiCall);

        yield put(getStoryUnviewedCountSuccess(data?.count ?? 0));
    } catch (error) {
        yield loggerSaga({ error });
    }
}

export function* watchStoriesRoot() {
    yield takeLatest(getStoryListRequest.toString(), handleGetStoryListRequest);
    yield takeLatest(getStoryInfoRequest.toString(), handleGetStoryInfoRequest);
    yield takeLatest(activateStory.toString(), handleStoryActivate);
    yield takeLatest(showStory.toString(), handleShowStory);
    yield takeLatest(getStoryListLoadMore.toString(), handleLoadMore);
    yield takeEvery(markStoryAsViewed.toString(), handleMarkStoryAsViewed);
    yield takeLatest(getStoryUnviewedCountRequest.toString(), handleGetStoryUnviewedCount);
}
