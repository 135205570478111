import classNames from 'clsx';
import React, { memo, useRef, VFC } from 'react';
import { FixedSizeList } from 'react-window';
import { IArchiveItem } from 'reactApp/modules/viewer/viewer.types';
import { Loader } from 'reactApp/ui/Loader/Loader';
import { MOBILE_ROW_HEIGHT } from 'reactApp/ui/Mobile/Datalist/Datalist.constants';
import { EmptyFolder } from 'reactApp/ui/Mobile/MobileViewer/MobileViewerArchive/EmptyFolder';
import { ItemRow } from 'reactApp/ui/Mobile/MobileViewer/MobileViewerArchive/ItemRow';
import styles from 'reactApp/ui/Mobile/MobileViewer/MobileViewerArchive/MobileViewerArchive.css';
import useResizeObserver from 'use-resize-observer/polyfilled';

interface IMobileViewerArchiveListProps {
    isLoaded?: boolean;
    items: IArchiveItem[];
    error?: string;
    disableDownload?: boolean;

    handleClick(item: IArchiveItem);

    handleDownload(item: IArchiveItem);
}

export const MobileViewerArchiveList: VFC<IMobileViewerArchiveListProps> = memo(
    ({ isLoaded = false, items, handleClick, handleDownload, error = '', disableDownload = false }) => {
        const ref = useRef<HTMLDivElement | null>(null);

        const { height } = useResizeObserver<HTMLDivElement>({ ref });

        return (
            <div
                className={classNames(styles.archiveContent, { [styles.archiveContent_scrolled]: isLoaded && items.length })}
                ref={ref}
                body-scroll-lock-ignore="true"
            >
                {isLoaded ? (
                    items.length ? (
                        <FixedSizeList height={height ?? 0} width="100%" itemCount={items.length} itemSize={MOBILE_ROW_HEIGHT}>
                            {({ index, style }) => (
                                <div style={style}>
                                    <ItemRow
                                        key={items[index].path}
                                        item={items[index]}
                                        onClick={handleClick}
                                        onDownload={handleDownload}
                                        disableDownload={disableDownload}
                                    />
                                </div>
                            )}
                        </FixedSizeList>
                    ) : (
                        <div className={styles.errorWrapper}>
                            <EmptyFolder error={error} />
                        </div>
                    )
                ) : (
                    <Loader centered isModal />
                )}
            </div>
        );
    }
);

MobileViewerArchiveList.displayName = 'MobileViewerArchiveList';
