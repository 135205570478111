import { type EditorItem } from 'reactApp/modules/editor/editor.types';

type Props = {
    url?: string;
    id: string;
    name?: string;
    container?: HTMLElement;
    iframe: HTMLIFrameElement;
    extraData?: Record<string, string>;
    item?: EditorItem;
};

type CreateIframeFn = (props: Props) => HTMLIFrameElement;

export const createIframe: CreateIframeFn = ({ url, id, name, container, iframe, extraData, item }) => {
    if (url) {
        iframe.src = url;
    }
    iframe.name = name || id;
    iframe.id = id;
    iframe.allowFullscreen = true;
    iframe.width = '100%';
    iframe.height = '100%';
    iframe.frameBorder = '0';

    if (extraData) {
        Object.entries(extraData).forEach(([key, value]) => {
            iframe.setAttribute(`data-${key}`, value);
        });
    }
    iframe.setAttribute('allowfullscreen', '');
    iframe.setAttribute('onmousewheel', '');
    iframe.setAttribute('allow', 'autoplay');

    // Если открываем файл расширения csv, то opacity выставляем сразу на 1, из-за особенностей МО AMR/SEK v.2.3
    if (item?.ext === 'csv') {
        iframe.style.opacity = '1';
    } else {
        iframe.style.opacity = url ? '0' : '1';
    }

    container?.appendChild(iframe);

    return iframe;
};
