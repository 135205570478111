import { useCallback, useEffect, useRef } from 'react';
import { CONTENT_LOAD_TIMEOUT } from 'reactApp/ui/ReactViewer/ReactViewer.constants';

export const useTimer = (url: string, onError: () => void) => {
    const ref = useRef<number | undefined>();

    const clearTimer = useCallback(() => {
        if (ref.current) {
            clearTimeout(ref.current);
            ref.current = undefined;
        }
    }, []);

    useEffect(() => {
        if (ref.current) {
            clearTimeout(ref.current);
        }

        ref.current = window.setTimeout(onError, CONTENT_LOAD_TIMEOUT);

        return () => {
            clearTimer();
        };
    }, [url, onError, clearTimer]);

    return { ref, clearTimer };
};
