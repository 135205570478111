import { IS_AUTO_TEST_HIDE } from 'reactApp/appHelpers/configHelpers';
import { isTouchSearchHistoryEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { store } from 'reactApp/store';
import { renderMobileSearchHistoryNotification } from 'reactApp/ui/Mobile/SearchHistoryNotification/helpers/SearchHistoryNotification.helpers';
import { call, put } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

import { SEARCH_BANNER_ID } from './mobileSearchBanner.saga';

const SEARCH_HISTORY_BANNER_ID = 'search-history-tooltip-touch-notification';

export function* initMobileSearchHistoryBanner() {
    const storage = yield* select(getCurrentStorage);
    const { isHome, isGallery } = getStorage(storage);
    const showHistory = isTouchSearchHistoryEnabled && !storeHelper.getValue(SEARCH_HISTORY_BANNER_ID);

    if ((!isHome && !isGallery) || IS_AUTO_TEST_HIDE || !showHistory) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.mobileSearchHistoryBanner,
            promoId: SEARCH_HISTORY_BANNER_ID,
            onShow: () => {
                store.dispatch(promoShown(EPromoType.mobileSearchHistoryBanner));
                storeHelper.markAsShown(SEARCH_BANNER_ID);
                storeHelper.markAsShown(SEARCH_HISTORY_BANNER_ID);
            },
            onClose: () => {
                store.dispatch(removePromo(EPromoType.mobileSearchHistoryBanner));
            },
        })
    );
}

export function* showMobileSearchHistoryBanner() {
    const mobileSearchHistoryBanner = yield* select(PromoSelectors.getPromo(EPromoType.mobileSearchHistoryBanner));

    if (!mobileSearchHistoryBanner) {
        return;
    }

    mobileSearchHistoryBanner.onShow();
    yield call(renderMobileSearchHistoryNotification, mobileSearchHistoryBanner.onClose());
}
