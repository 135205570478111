import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BuySubscriptionActions } from 'reactApp/modules/buySubscription/buySubscription.module';
import { BillingBuyTemplates } from 'reactApp/modules/buySubscription/buySubscription.types';
import { CardActions } from 'reactApp/modules/creditCard/creditCard.module';
import { CreditCardDmrLinkActions } from 'reactApp/modules/creditCardDmrLink/creditCardDmrLink.module';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { updateSubscriptionsRequest } from 'reactApp/modules/subscriptions/subscriptions.module';
import { ISubscription } from 'reactApp/modules/subscriptions/subscriptions.types';
import { UserQuotaSelectors } from 'reactApp/modules/userQuota/userQuota.selectors';
import { SUBSCRIPTION_ANALYTICS } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscription.analytics';
import { HardlyUsedThunkDispatch } from 'reactApp/types/app';
import { renderCardModal } from 'reactApp/ui/AddCardModal/AddCardModal.helpers';
import { renderCancelAutoRenewalModal } from 'reactApp/ui/Mobile/CancelAutoRenewalModal/CancelAutoRenewalModal.helpers';
import { sendDwh } from 'reactApp/utils/ga';

export const useHandlers = (subscription: ISubscription, onClose: () => void) => {
    const dispatch = useDispatch<HardlyUsedThunkDispatch>();
    const userQuota = useSelector(UserQuotaSelectors.getTotal);
    const userQuotaSpace = userQuota ? userQuota.original : 0;
    const { id, expires, productId, start, space, partner } = subscription;

    const availableSpace = bytesToNDigits(Math.abs(space.original - userQuotaSpace), 3).value;

    const dwhData = useMemo(
        () => ({
            product: productId,
            start_date: start,
            expired_date: expires,
            source: 'touch',
            plarform: String(EnvironmentSelectors.getMobileType()).toLowerCase(),
        }),
        [expires, productId, start]
    );

    const onSuccessAddCard = useCallback(() => {
        dispatch(CardActions.updateCard({ showNotifications: true }));
    }, [dispatch]);

    const onCloseAddCard = useCallback(() => {
        dispatch(CreditCardDmrLinkActions.resetLink());
    }, [dispatch]);

    const onUnlinkCard = useCallback(() => {
        dispatch(CardActions.updateCard({ isSilent: true }));
    }, [dispatch]);

    const handleUpdateCard = useCallback(() => {
        sendDwh({
            ...SUBSCRIPTION_ANALYTICS.MY_TARIFF_CHANGE_CARD,
            dwhData,
        });

        onClose();
        renderCardModal({
            onClose: onCloseAddCard,
            onSuccess: onSuccessAddCard,
            onUnlinkCard,
            size: 'tiny',
            isMobile: true,
            checkoutSkin: BillingBuyTemplates.CLOUD_B2C_MOBILE,
        });
    }, [dwhData, onClose, onCloseAddCard, onSuccessAddCard, onUnlinkCard]);

    const handleCancelNextPayment = useCallback(() => {
        sendDwh({
            ...SUBSCRIPTION_ANALYTICS.MY_TARIFF_CANCEL_AUTOPAYMENT,
            dwhData,
        });

        onClose();
        renderCancelAutoRenewalModal({ id, currentSpace: space.value, availableSpace, expires, dwhData });
    }, [availableSpace, dwhData, expires, id, onClose, space.value]);

    const handleRenewNextPayment = useCallback(() => {
        sendDwh({
            ...SUBSCRIPTION_ANALYTICS.MY_TARIFF_TURNON_AUTOPAYMENT,
            dwhData,
        });

        dispatch(BuySubscriptionActions.addAutorenew(id))
            .then(() => dispatch(updateSubscriptionsRequest()))
            .then(onClose)
            .then(() =>
                dispatch(
                    showSnackbarAction({
                        id: 'renew-subscription',
                        closable: true,
                        text: 'Подписка восстановлена',
                        type: SnackbarTypes.success,
                    })
                )
            )
            .catch(() =>
                dispatch(
                    showSnackbarAction({
                        id: 'renew-subscription',
                        closable: true,
                        text: 'Не удалось восстановить подписку. \nПопробуйте изменить способ оплаты',
                        type: SnackbarTypes.failure,
                    })
                )
            );
    }, [dispatch, dwhData, id, onClose]);

    const handleGoToSubscription = useCallback(() => {
        sendDwh({
            ...SUBSCRIPTION_ANALYTICS.MY_TARIFF_ADD_SPACE,
            dwhData,
        });

        onClose();
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [dwhData, onClose]);

    const handlePartnerClick = useCallback(() => {
        sendDwh({
            ...SUBSCRIPTION_ANALYTICS.MY_TARIFF_PARTNER_CLICK,
            dwhData: {
                parner_name: partner,
                source: 'touch',
            },
        });
    }, [partner]);

    return {
        handleUpdateCard,
        handleCancelNextPayment,
        handleGoToSubscription,
        handleRenewNextPayment,
        handlePartnerClick,
    };
};
