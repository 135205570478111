import { getFeature } from 'Cloud/Application/FeaturesEs6';

import { EMyOfficeMode } from './myOffice.types';

export const MY_OFFICE_IFRAME = getFeature('my-office-iframe') || 'https://o-myoffice.datacloudmail.ru/';

export const MY_OFFICE_MODE = {
    edit: EMyOfficeMode.DEFAULT,
    view: EMyOfficeMode.READONLY,
};

export const MY_OFFICE_FORMAT = {
    oxml: 'OXML',
    odt: 'ODT',
    ods: 'ODS',
    odf: 'ODF',
    pdf: 'PDF',
    pdfa: 'PDFA',
} as const;

export const SAVE_INTERVAL = 5000;
export const ACTIVE_SESSION_INTERVAL = (getFeature('edit-att-session-interval') || 30) * 1000;

export const RATELIMIT_UPDATE_INTERVAL = 2 * 60 * 1000;

export const MY_OFFICE_FRAME_ID = 'myofficeframe';
