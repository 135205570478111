import React, { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { resetAllSelectedItems } from 'reactApp/modules/albums/albums.actions';
import { resetGallery } from 'reactApp/modules/gallery/gallery.module';
import { searchReset } from 'reactApp/modules/search/search.module';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { SelectFromCloudToolbar } from 'reactApp/sections/AlbumsPage/SelectFromCloudPopup/components/SelectFromCloudToolbar/SelectFromCloudToolbar';
import { SelectFromCloudTree } from 'reactApp/sections/AlbumsPage/SelectFromCloudPopup/components/SelectFromCloudTree/SelectFromCloudTree';
import { ShowSelected } from 'reactApp/sections/AlbumsPage/SelectFromCloudPopup/components/ShowSelected/ShowSelected';
import { Button } from 'reactApp/ui/Button/Button';

import styles from './SelectFromCloudContent.css';

export enum SelectFromCloudContentMod {
    tree = 'tree',
    selected = 'selected',
    search = 'search',
}

interface SelectFromCloudContentProps {
    onClose(): void;
    onSuccess?(): void;
}

export const SelectFromCloudContent = memo<SelectFromCloudContentProps>(({ onClose, onSuccess }) => {
    const [mod, setMod] = useState(SelectFromCloudContentMod.tree);
    const isAnyIndexSelected = useSelector(SelectionsSelectors.isAnyIndexSelected);
    const [selectedId, setSelectedId] = useState<string>(ROOT_FOLDER_ID);

    const dispatch = useDispatch();

    const content = useMemo(() => {
        switch (mod) {
            case SelectFromCloudContentMod.tree:
                return <SelectFromCloudTree mod={mod} selectedId={selectedId} setSelectedId={setSelectedId} />;
            case SelectFromCloudContentMod.selected:
                return <ShowSelected setMod={setMod} />;
            default:
                return <SelectFromCloudTree mod={mod} selectedId={selectedId} setSelectedId={setSelectedId} />;
        }
    }, [mod, setMod, selectedId, setSelectedId]);

    const handleSuccess = useCallback(() => {
        onClose();
        onSuccess?.();
        dispatch(resetGallery());
        dispatch(searchReset());
        dispatch(resetAllSelectedItems());
    }, [onClose, onSuccess, dispatch]);

    const handleClose = useCallback(() => {
        onClose();
        dispatch(resetGallery());
        dispatch(searchReset());
        dispatch(resetAllSelectedItems());
    }, [onClose, dispatch]);

    return (
        <div className={styles.root}>
            <SelectFromCloudToolbar mod={mod} setMod={setMod} />
            {content}
            <div className={styles.buttonGroup}>
                <Button theme="vk" primary onClick={handleSuccess} className={styles.button} disabled={!isAnyIndexSelected}>
                    Добавить в альбом
                </Button>

                <Button theme="vk" onClick={handleClose} className={styles.button}>
                    Отменить
                </Button>
            </div>
        </div>
    );
});

SelectFromCloudContent.displayName = 'SelectFromCloudContent';
