import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { createGaSender } from 'reactApp/utils/ga';

import { IProps } from './NoWarningDialog.types';

export const useCallbacks = ({ onCheckBoxClick, onActionClick, id, onClose }: IProps) => {
    const dispatch = useDispatch();
    const isNoWarning = dispatch(UserStorageActions.get(id)) as unknown as number;
    const [isCheckboxSelected, setCheckboxSelected] = useState<boolean>(Boolean(isNoWarning));
    const sendGa = useMemo(() => createGaSender(id), [id]);

    const handleChecboxClick = useCallback(() => {
        setCheckboxSelected(!isCheckboxSelected);
        onCheckBoxClick?.();
        sendGa('checkbox');
    }, [isCheckboxSelected]);

    const handleAccept = useCallback(() => {
        dispatch(UserStorageActions.set(id, isCheckboxSelected ? 1 : 0));
        onActionClick?.();
        onClose?.();
        sendGa('accept');
    }, [isCheckboxSelected]);

    const handleClose = useCallback(() => {
        dispatch(UserStorageActions.set(id, isCheckboxSelected ? 1 : 0));
        onClose?.();
        sendGa('close');
    }, [isCheckboxSelected]);

    useEffect(() => {
        if (isNoWarning) {
            handleAccept();
        }
    }, [dispatch, handleAccept, handleClose, isNoWarning]);

    return { isCheckboxSelected, handleAccept, handleChecboxClick, handleClose };
};
