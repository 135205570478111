import { sendAgreement } from 'Cloud/Application/User';
import { ExtraEmailAddAPICall } from 'reactApp/api/ExtraEmailAddAPICall';
import { sendAuthDwhRequest } from 'reactApp/modules/dwh/dwh.module';
import { EAuthDwhAction } from 'reactApp/modules/dwh/dwh.types';
import { checkEmailAction } from 'reactApp/modules/socialUser/socialUser.actions';
import { addEmailFailure, addEmailRequest, addEmailSuccess } from 'reactApp/modules/socialUser/socialUser.module';
import { validateEmail } from 'reactApp/utils/helpers';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

const api = (email: string): any =>
    new ExtraEmailAddAPICall().makeRequest({
        did: 'Web',
        extra_email: email,
    });

type AddEmailActions = ReturnType<typeof addEmailRequest> | ReturnType<typeof addEmailSuccess> | ReturnType<typeof addEmailFailure>;

export const addEmail =
    (email: string, storage: string): ((dispatch: ThunkDispatch<null, null, AnyAction>) => any) =>
    (dispatch: ThunkDispatch<null, null, AnyAction>): Promise<AddEmailActions> =>
        new Promise<AddEmailActions>((resolve, reject): void => {
            dispatch(addEmailRequest());

            if (!email) {
                dispatch(addEmailFailure('Введите свой e-mail'));
                return;
            }

            if (!validateEmail(email)) {
                dispatch(addEmailFailure('Вы указали некорректный формат e-mail'));
                return;
            }

            dispatch(
                sendAuthDwhRequest({
                    action: EAuthDwhAction.licenseAccept,
                    source: 'social',
                    place: storage,
                })
            );

            sendAgreement('social');

            api(email)
                .then((): AnyAction => {
                    sendAgreement('social');
                    return dispatch(checkEmailAction());
                })
                .then((): void => {
                    resolve(dispatch(addEmailSuccess()));
                })
                .catch((): void => {
                    reject(dispatch(addEmailFailure('Вы указали некорректный формат e-mail')));
                });
        });
