define(function (require, exports, module) {
    'use strict';

    const apiError = require('Cloud/API/apiError');
    const config = require('Cloud/config');
    const { IS_ONPREMISE, HELP_URL } = require('reactApp/appHelpers/configHelpers');

    // eslint-disable-next-line unicorn/consistent-function-scoping
    function interpolate(message, replacements) {
        // TODO: srt tmpl
        return Object.keys(replacements).reduce(function (message, pattern) {
            if (!message.includes(pattern)) {
                return message;
            }

            return message.split(pattern).join(replacements[pattern]);
        }, message);
    }

    function ErrorList(errorList, replacements) {
        replacements = {
            ...errorList.defaultReplacements,
            ...replacements,
        };
        Object.keys(errorList).forEach(function (errorCode) {
            this[errorCode] = interpolate(errorList[errorCode], replacements);
        }, this);
    }

    const invalidCharacters = config.get('ITEM_NAME_INVALID_CHARACTERS');
    const href = IS_ONPREMISE ? HELP_URL : 'https://help.mail.ru/cloud_web/confines';
    ErrorList.defaultReplacements = {
        '{HELP_LINK_NAME_CONFINES}': `<a href="${href}" target="_blank" rel="noopener" title="Ограничения по файлам и папкам в Облаке">Подробнее…</a>`,
        '{INVALID_CHARACTERS}': (invalidCharacters || '').split('').join(' '),
    };

    ErrorList.prototype.getMessage = function (anything, replacements) {
        const message = this[apiError.getCode(anything)] || this.unknown;

        replacements = replacements ? { ...ErrorList.defaultReplacements, ...replacements } : ErrorList.defaultReplacements;

        return interpolate(message, replacements || ErrorList.defaultReplacements);
    };

    module.exports = {
        apiError,

        ErrorList,

        createErrorList(errorList, replacements) {
            return new ErrorList(errorList, replacements);
        },
    };
});
