import { decode } from 'html-entities';
import { fixAttachUrl, parseAttachStockId } from 'reactApp/modules/attaches/attaches.helpers';
import { EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { FileBuilder } from 'reactApp/modules/file/FileBuilder';
import { getWeblinkFromPublicId } from 'reactApp/modules/file/utils';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';
import { normalizeMtime } from 'reactApp/utils/tree.helpers';

const escapeHTML = (str: string): string => {
    return str.replace(/&amp;/g, '&').replace(/&#39;/g, "'");
};

const urlBuilder = new UrlBuilder();

export class AttachFileBuilder extends FileBuilder {
    public prepareFile(file, isTempAttach = false, isFromMessage = false) {
        file.name = escapeHTML(file.name);
        const { id, name, from, url: urlOriginal, href, time, storage } = file;
        let type = file.type;

        if (type === 'other' && 'cloud_stock' in file) {
            type = EAttachTypes.cloudStock;
        }

        let attachType = type || EAttachTypes.attach;

        /** attaches/search/clear возвращает только обычные и cloud_stock аттачи */
        if (!isFromMessage) {
            attachType = 'cloud_stock' in file ? EAttachTypes.cloudStock : EAttachTypes.attach;
        }

        if (isTempAttach) {
            attachType = EAttachTypes.temporary;
        }

        const result = super.prepareFile(file, true);
        const authorEmail = from?.[0]?.email || '';
        const authorName = from?.[0]?.name || authorEmail;
        const authorAvatar = from?.[0]?.avatars?.default;
        const isImage = result.kind === 'image';
        const isAudio = result.kind === 'audio';
        const isVideo = result.kind === 'video';
        const subject = decode(result.subject);

        let url = Object.assign({}, urlOriginal ?? href ?? result.url);

        if (url) {
            if (!url.view && url.download) {
                url.view = url.download.replace('&notype=1', '');
            }
            if (isAudio) {
                url.media = url.view || url.download;
            }

            if (isTempAttach) {
                url.downloadAPI = url.download;
            }

            Object.keys(url).forEach((key) => {
                if (key !== 'downloadAPI') {
                    url[key] = fixAttachUrl(url[key], key === 'download');
                }
            });

            const stockId = parseAttachStockId(id)?.partId1;
            // eslint-disable-next-line no-useless-escape
            const dwl_token = url.download?.match(new RegExp(`.*\/(${stockId}.+)\/.*`))?.[1];

            if (type === EAttachTypes.cloudStock && (isAudio || isVideo)) {
                // Для работы со стоками, в стоках бек присылает dwl_token для каждого файла для доступа по нему.
                // Здесь, в стоках аттачах, апи аттачей отдает уже готовые урлы со вшитыми токенами.
                // Так что берем токен оттуда и строим урл плейлистов.
                if (dwl_token) {
                    url.media = urlBuilder.builderPlaylist({
                        id: '',
                        isStock: true,
                        isAudio,
                        isPublic: false,
                        dwl_token,
                    });
                } else {
                    url.media = '';
                }
            }
            if (type === EAttachTypes.cloud && url.download) {
                const weblink = getWeblinkFromPublicId(decodeURIComponent(url.download.replace(/\/public\//, '')));
                result.thumbnails = urlBuilder.getThumb({
                    ext: result.ext,
                    id: weblink,
                    size: result.size,
                    kind: result.kind,
                    name: result.name,
                    path: weblink,
                    isPublic: true,
                    isStock: false,
                    dwl_token: null,
                    weblink: id,
                });
                url = urlBuilder.getUrls({
                    ext: result.ext,
                    isPublic: true,
                    id: weblink,
                    weblink,
                    size: result.size,
                    kind: result.kind,
                    subKind: result.subKind,
                    isStock: false,
                    name: file.name,
                });
            }

            if (type === EAttachTypes.cloudStock && isImage) {
                const { ext, size, kind, subKind, name } = result;

                const inputParams = {
                    ext,
                    dwl_token,
                    kind,
                    isStock: true,
                };

                result.thumbnails = urlBuilder.getThumb({
                    ...inputParams,
                    size,
                    name,
                    isPublic: true,
                });

                url = urlBuilder.getUrls({
                    ...inputParams,
                    isPublic: false,
                    weblink: '',
                    subKind,
                    name: file.name,
                });
            }
        }

        delete result.href;

        return {
            ...result,
            attachType,
            type,
            id,
            parent: '/',
            mtime: normalizeMtime(time),
            name: name || subject,
            subject,
            authorName,
            authorEmail,
            authorAvatar,
            favoritesEnabled: false,
            downloadText: `Скачать ${result.size.value}`,
            folderId: file.folder_id,
            storage: storage || EStorageType.attaches,
            isFolder: false,
            url,
        };
    }

    public buildFolderItem = (item) => ({ ...item, name: escapeHTML(item.name) });
}
