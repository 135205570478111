import classNames from 'clsx';
import { ReactComponent as CheckIcon } from 'img/icons/checkSquare.svg';
import React, { ReactElement } from 'react';

import styles from './DataListCheckbox.css';

interface Props {
    checked?: boolean;
    onClick?: () => void;
}

export function DataListCheckbox({ checked, onClick }: Props): ReactElement {
    const handleOnClick = function (): void {
        if (onClick) {
            onClick();
        }
    };

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles.root_checked]: checked,
            })}
            onClick={handleOnClick}
            data-qa-id="checkbox"
            data-qa-checked={checked}
        >
            <div className={styles.icon}>
                <CheckIcon width={14} height={14} viewBox="0 0 16 16" />
            </div>
        </div>
    );
}
