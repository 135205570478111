export const upperFirstLetter = (str) => (str ? str[0].toUpperCase() + str.slice(1) : null);

export const getTrimmedText = (str: string | undefined, maxlength: number, noDots = false) =>
    str && str.length > maxlength ? `${str.slice(0, maxlength - 1)}${noDots ? '' : '…'}` : str;

export const truncateTextMiddle = (str: string, maxlength: number) => {
    const partLen = maxlength / 2;
    return str && str.length > maxlength ? `${str.slice(0, partLen)}...${str.slice(-partLen)}` : str;
};

export const getFileExtLimit = (ext: string | undefined) => getTrimmedText(ext, 8, true) || '';
