import React, { PureComponent } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { noop } from 'reactApp/utils/helpers';

import styles from './Alert.css';

interface IProps {
    onClose: () => void;
    onActionClick: () => void;
}

export class AlertComponent extends PureComponent<IProps> {
    public static defaultProps = {
        onActionClick: () => Promise.resolve(''),
        onClose: noop,
    };

    private handleClose = () => {
        const { onClose } = this.props;
        onClose();
    };

    private handleAccept = () => {
        const { onActionClick, onClose } = this.props;
        onActionClick();
        onClose();
    };

    public render() {
        return (
            <Dialog
                open
                closeOnDimmerClick={false}
                onCancel={this.handleClose}
                disableScrollOnBody={true}
                id="alert-cancel-upload-dialog"
                className={styles.root}
                size="small"
            >
                <Content isModal isLoading={false} hasError={false} scrolling>
                    <div className={styles.wrapper}>
                        <div className={styles.title}>Вы действительно хотите отменить все загрузки?</div>
                        <div className={styles.description}>После закрытия окна все активные загрузки будут отменены</div>

                        <div className={styles.wrapper_button}>
                            <Button
                                className={styles.button}
                                theme="octavius"
                                size="big"
                                primary
                                onClick={this.handleAccept}
                                fluid
                                data-name="action"
                            >
                                Отменить загрузку
                            </Button>
                            <Button
                                className={styles.button}
                                theme="octavius"
                                size="big"
                                onClick={this.handleClose}
                                fluid
                                data-name="cancel"
                            >
                                Не отменять
                            </Button>
                        </div>
                    </div>
                </Content>
            </Dialog>
        );
    }
}
