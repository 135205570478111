import videojs from 'video.js';

import { addAnimatedBackground } from './components/animatedBG';
import { addCentralNavigation } from './components/centralNavigation';
import { addControlBarShadow, addControlBarSpacer, addControlBarWrapper } from './components/nativeNavigation/nativeNavigation';
import { addRewindScreens } from './components/rewindScreen';
import { ESides } from './constants';
import { goBackward, goForward, hideNav, stimulateNav, toggleNav, togglePlay } from './helpers';
import styles from './styles.css';

interface IParams {
    player: videojs.Player;
    side: ESides;
    count: number;
    showLeft?: (count: number) => void;
    showRight?: (count: number) => void;
    clearCounter?: () => void;
}

function mobileNavigationLogic({ player, side, count, showLeft, showRight, clearCounter }: IParams) {
    if (side === ESides.LEFT) {
        const works = goBackward(player);
        if (works) {
            showLeft?.(count);
            hideNav(player);
        } else {
            clearCounter?.();
        }
    } else if (side === ESides.RIGHT) {
        const works = goForward(player);
        if (works) {
            showRight?.(count);
            hideNav(player);
        } else {
            clearCounter?.();
        }
    } else if (side === ESides.CENTER) {
        hideNav(player);
    }
}

interface CustomNavProps {
    player: videojs.Player;
    isPhone?: boolean;
    isVideo?: boolean;
}

export function addCustomNavigation({ player, isPhone, isVideo }: CustomNavProps) {
    addControlBarWrapper(player);
    addControlBarShadow(player);

    let showRight, showLeft;
    if (isPhone) {
        addControlBarSpacer(player);
        const animation = addAnimatedBackground(player);
        showRight = animation.showRight;
        showLeft = animation.showLeft;
    }
    if (isVideo) {
        const { showPlayButton, showPauseButton, showReplayButton } = addCentralNavigation({
            player,
            withSeeking: !isPhone,
            className: styles.playerCentredNavigation,
            togglePlay: () => {
                stimulateNav(player);
                togglePlay(player);
            },
            seekingForward: () => {
                stimulateNav(player);
                goForward(player);
            },
            seekingBackward: () => {
                stimulateNav(player);
                goBackward(player);
            },
        });

        player.on('ended', showReplayButton);
        player.on('pause', showPlayButton);
        player.on('play', showPauseButton);
        player.on('seeked', () => {
            if (player.ended()) {
                showReplayButton();
            } else if (player.paused()) {
                showPlayButton();
            } else {
                showPauseButton();
            }
        });

        addRewindScreens(
            player,
            () => {
                toggleNav(player);
            },
            (side, count, clearCounter) => {
                if (isPhone) {
                    mobileNavigationLogic({ player, side, count, showLeft, showRight, clearCounter });
                } else {
                    toggleNav(player);
                }
            }
        );
    } else {
        let skipTogglePlay;

        addRewindScreens(
            player,
            () => {
                if (player.userActive() || player.paused()) {
                    togglePlay(player);
                    skipTogglePlay = false;
                } else {
                    stimulateNav(player);
                    skipTogglePlay = true;
                }
            },
            (side, count, clearCounter) => {
                if (count === 1 && !skipTogglePlay) {
                    togglePlay(player);
                }
                if (isPhone) {
                    mobileNavigationLogic({ player, side, count, showLeft, showRight, clearCounter });
                }
            }
        );
    }
}
