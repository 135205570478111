export const defaultMillisecondsToLive = 10000;
export const INFINITE_CACHE_TIME = -1;

export type TCacheType = number | typeof INFINITE_CACHE_TIME;

export type fetchFunctionType = () => Promise<any>;

export interface IMemoryCache {
    add(key: string, fetchFunction: fetchFunctionType, millisecondsToLive: number | null): void;
    delete(key: string): void;
    get(key: string): Promise<any>;
    getOrAdd(key: string, fetchFunction: fetchFunctionType, millisecondsToLive: number | null): Promise<any>;
    resetCache(): void;
}
