import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { extInfo } from 'lib/extInfo';
import { attachesFetchSuccess } from 'reactApp/modules/attaches/attaches.actions';
import { docExtensionsSupported } from 'reactApp/modules/attaches/attaches.constants';
import { getExtension, getItemNameWithoutExt, isAudio, isInlinePreviewable, isPreviewable, isVideo } from 'reactApp/modules/file/utils';
import {
    addToFavoritesSuccess,
    moveItemsSuccess,
    publishWeblink,
    removeFileSuccess,
    removeFromFavoritesSuccess,
    unPublishWeblink,
} from 'reactApp/modules/modifying/modifying.actions';
import {
    EModifyReason,
    IAddToFavoritesSuccess,
    IRemoveFromFavoritesSuccess,
    MoveItemsSuccessAction,
    RemoveFileSuccessAction,
    UnpublishWeblinkAction,
    UpdateWeblinkAction,
} from 'reactApp/modules/modifying/modifying.types';
import { CloudItem, EStorageType } from 'reactApp/modules/storage/storage.types';
import { mapArchiveExtToType } from 'reactApp/modules/viewer/viewer.constants';
import {
    EArchiveType,
    IArchiveInfoApiResponse,
    IArchiveInfoApiResponseItem,
    IArchiveInfoRequestData,
    IArchiveItem,
    IArchiveItemInfoData,
    IArchiveItemInfoRequestData,
    SelectItemIndexInArrayAction,
    SetSelectedItemData,
    SetViewerItemIdAction,
    SetViewerItemsData,
    SetViewerStorage,
    ViewerActionData,
    ViewerState,
} from 'reactApp/modules/viewer/viewer.types';
import { AccessRights, Count } from 'reactApp/types/Tree';

const initialState: ViewerState = {
    itemId: '',
    isActive: false,
    itemIds: [],
    itemStorage: null,
    item: null,
    itemIdxInArray: null,
    gaSuffix: '',
    isOpenAction: false,
    totalCount: 0,
    hasMoreToLoad: false,
    archive: null,
    isOpening: false,
    ovidiusZoom: {},
};

export const setViewerItemId = createAction<SetViewerItemIdAction>('viewer/setItem');
export const openViewer = createAction<ViewerActionData>('viewer/openViewer');
export const openMobileViewer = createAction<{ id: string; storage?: EStorageType }>('viewer/openMobileViewer');
export const updateViewerData = createAction<ViewerActionData>('viewer/updateViewerData');
export const closeViewer = createAction<string | undefined>('viewer/closeViewer');
export const setViewerActive = createAction<boolean>('viewer/setViewerActive');
export const setViewerStorage = createAction<SetViewerStorage>('viewer/setViewerStorage');
export const selectItemIndexInArray = createAction<SelectItemIndexInArrayAction>('viewer/selectItemId');
export const setSelectedItem = createAction<SetSelectedItemData>('viewer/setSelectedItem');
export const setIdxs = createAction<string[]>('viewer/setIdxs');
export const setViewerItems = createAction<SetViewerItemsData>('viewer/setViewerItems');
export const requestArchiveInfo = createAction<IArchiveInfoRequestData>('viewer/requestArchiveInfo');
export const archiveInfoSuccess = createAction<IArchiveInfoApiResponse>('viewer/archiveInfoSuccess');
export const archiveInfoFail = createAction<IArchiveInfoApiResponse>('viewer/archiveInfoFail');
export const startArchiveProlongTimer = createAction<{ prolongLink: string; ttl: number }>('viewer/startArchiveProlongTimer');
export const stopArchiveProlongTimer = createAction('viewer/stopArchiveProlongTimer');
export const getArchiveItemPreviewRequest = createAction<IArchiveItemInfoRequestData>('viewer/getArchiveItemPreviewRequest');
export const getArchiveItemPreviewSuccess = createAction<IArchiveItemInfoData>('viewer/getArchiveItemPreviewSuccess');
export const setArchiveCurrentFolder = createAction<string | null>('viewer/setArchiveCurrentFolder');
export const setArchiveItemActive = createAction<string | null>('viewer/setArchiveItemActive');
export const downloadArchiveItemRequest = createAction<IArchiveItemInfoRequestData>('viewer/downloadArchiveItemRequest');
export const publishItem = createAction<{ item: CloudItem | null; itemStorage: EStorageType; isPublic: boolean; isStock: boolean }>(
    'viewer/publishItem'
);
export const setIsOpening = createAction<boolean>('viewer/setIsOpening');
export const checkViewerAtIdChange = createAction<{ id: string; storage: EStorageType }>('viewer/checkViewerAtIdChange');
export const openPdfEditor = createAction<{ id: string; storage: EStorageType }>('viewer/openPdfEditor');
export const setOvidiusZoom = createAction<{ id: string; value: number }>('viewer/ovidiusZoom');

const normalizeArchiveItem = (archiveItems, item: IArchiveInfoApiResponseItem, count?: Count): IArchiveItem => {
    const ext = getExtension(item);
    const { kind, subKind } = extInfo.get(ext) ?? {};
    const nameWithoutExt = getItemNameWithoutExt(item.name, ext);

    const isFolder = item.kind === 'folder';
    const newItem: IArchiveItem = {
        path: item.path,
        name: item.name,
        size: item.size,
        isFolder,
        isEncrypted: item.is_encrypted,
        list: [],
        ext,
        kind,
        subKind,
        // Mail zipapi returns links
        previewUrl: (docExtensionsSupported.includes(ext?.toLowerCase()) ? item.viewersUrl : item.previewUrl) ?? '',
        downloadUrl: item.downloadUrl ?? '',
        nameWithoutExt,
    };

    if (!isPreviewable(newItem) || !(isInlinePreviewable(newItem) || isAudio(newItem) || isVideo(newItem))) {
        newItem.previewUrl = null;
    }

    if (count) {
        if (newItem.isFolder) {
            count.folders++;
        } else {
            count.files++;
        }

        count.loaded++;
        count.all++;
    }

    archiveItems[item.path] = newItem;

    if (Array.isArray(item.list)) {
        const countCurrent: Count = { folders: 0, files: 0, all: 0, loaded: 0 };

        newItem.list = item.list.map((item) => {
            const childItem = normalizeArchiveItem(archiveItems, item);
            if (childItem.isFolder) {
                countCurrent.folders++;
            } else {
                countCurrent.files++;
            }
            return item.path;
        });

        newItem.count = countCurrent;
    }

    return newItem;
};

export const viewerReducer = createReducer(initialState, {
    [openViewer.type]: (state, action: PayloadAction<ViewerActionData>) => {
        const { payload } = action;
        return {
            ...initialState,
            itemIds: payload.itemIds,
            totalCount: payload.itemIds?.length ?? 0,
            itemId: payload.itemId,
            itemStorage: payload.itemStorage,
            gaSuffix: payload.gaSuffix,
            isOpening: true,
            isFirstClosed: state.isFirstClosed,
        };
    },
    [updateViewerData.type]: (state, action: PayloadAction<ViewerActionData>) => {
        const { payload } = action;
        const keepPreviousData = payload.keepPreviousData;
        const itemIds = keepPreviousData ? state.itemIds : payload.itemIds;
        const itemIdxInArray = keepPreviousData ? state.itemIdxInArray : initialState.itemIdxInArray;
        return {
            ...state,
            item: state.item,
            itemIdxInArray,
            itemIds,
            totalCount: itemIds.length ?? 0,
            itemId: payload.itemId,
            itemStorage: payload.itemStorage,
            gaSuffix: payload.gaSuffix,
            isOpening: true,
            fromCloud: payload.fromCloud,
            isFirstClosed: state.isFirstClosed,
        };
    },
    [closeViewer.type]: () => {
        return { ...initialState, isFirstClosed: true };
    },
    [setViewerActive.type]: (state, action: PayloadAction<boolean>) => {
        state.isActive = action.payload;
        state.isOpening = action.payload;
    },
    [setViewerStorage.type]: (state, action: PayloadAction<SetViewerStorage>) => {
        state.itemStorage = action.payload.itemStorage;
    },
    [setSelectedItem.type]: (state, action: PayloadAction<SetSelectedItemData>) => {
        return {
            ...state,
            item: action.payload.item,
            itemId: action.payload.itemId,
            itemIdxInArray: action.payload.itemIdxInArray,
            isOpenAction: action.payload.isOpenAction,
            archive: state.archive?.archiveId === action.payload.item?.id ? state.archive : null,
        };
    },
    [setViewerItemId.type]: (state, action: PayloadAction<SetViewerItemIdAction>) => {
        state.itemId = action.payload.id;
    },
    [setViewerItems.type]: (state, action: PayloadAction<SetViewerItemsData>) => {
        state.itemIds = action.payload.itemIdxs;
        state.totalCount = action.payload.filesCount ?? action.payload?.itemIdxs.length ?? 0;
        state.hasMoreToLoad = action.payload.hasMoreToLoad;

        const idx = action.payload.itemIdxs.findIndex((id) => id === state.itemId);
        state.itemIdxInArray = idx === -1 ? null : idx;
    },
    [requestArchiveInfo.type]: (state, action: PayloadAction<IArchiveInfoRequestData>) => {
        const type = mapArchiveExtToType[action.payload.ext.toLowerCase()] ?? EArchiveType.zip;

        state.archive = {
            list: [],
            archiveItems: {},
            isLoaded: false,
            error: '',
            archiveId: action.payload.id,
            currentFolderId: '',
            currentActiveId: null,
            type,
        };
    },
    [archiveInfoSuccess.type]: (state, action: PayloadAction<IArchiveInfoApiResponse>) => {
        if (state.archive?.archiveId !== action.payload.archiveId) {
            return;
        }

        const items = action.payload.list || [];
        const count = {
            files: 0,
            folders: 0,
            loaded: 0,
            all: 0,
        };

        const archiveItems = {};

        const list = items.map((item) => normalizeArchiveItem(archiveItems, item, count).path);

        state.archive = {
            isLoaded: true,
            archiveItems,
            list,
            count,
            archiveId: action.payload.archiveId,
            currentFolderId: '',
            currentActiveId: null,
            type: state.archive.type,
        };
    },
    [archiveInfoFail.type]: (state, action: PayloadAction<IArchiveInfoApiResponse>) => {
        if (state.archive?.archiveId !== action.payload.archiveId) {
            return;
        }

        state.archive = {
            list: [],
            archiveItems: {},
            error: action.payload.error,
            isLoaded: true,
            archiveId: action.payload.archiveId,
            currentFolderId: '',
            currentActiveId: null,
            type: state.archive.type,
        };
    },
    [getArchiveItemPreviewSuccess.type]: (state, action: PayloadAction<IArchiveItemInfoData>) => {
        const archiveItems = state.archive?.archiveItems;
        if (!archiveItems) {
            return;
        }
        archiveItems[action.payload.itemId].previewUrl = action.payload.previewUrl;
    },
    [setArchiveCurrentFolder.type]: (state, action: PayloadAction<string | null>) => {
        if (!state.archive) {
            return;
        }
        state.archive.currentFolderId = action.payload;
    },
    [setArchiveItemActive.type]: (state, action: PayloadAction<string | null>) => {
        if (!state.archive) {
            return;
        }
        state.archive.currentActiveId = action.payload;
    },
    [startArchiveProlongTimer.type]: (state, action: ReturnType<typeof startArchiveProlongTimer>) => {
        if (state.archive) {
            state.archive.prolongLink = action.payload?.prolongLink;
            state.archive.prolongTtl = action.payload?.ttl;
        }
    },
    [stopArchiveProlongTimer.type]: (state) => {
        if (state.archive) {
            state.archive.prolongLink = '';
            state.archive.prolongTtl = null;
        }
    },
    [publishWeblink.type]: (state, action: PayloadAction<UpdateWeblinkAction>): void => {
        const { weblink, id } = action.payload;

        if (state.item && state.item.id === id && 'weblink' in state.item) {
            state.item.weblink = weblink;
        }
    },
    [unPublishWeblink.type]: (state, action: PayloadAction<UnpublishWeblinkAction>): void => {
        const { ids } = action.payload;
        const id = ids?.[0];

        const newItem = state.item as {
            id;
            weblink;
            weblinkAccessRights;
            weblinkDomestic;
            weblinkExpires;
        };

        if (!newItem || newItem.id !== id) {
            return;
        }

        newItem.weblink = undefined;
        newItem.weblinkAccessRights = AccessRights.r;
        newItem.weblinkDomestic = false;
        newItem.weblinkExpires = 0;
    },
    [addToFavoritesSuccess.type]: (state, action: PayloadAction<IAddToFavoritesSuccess>): void => {
        const { ids } = action.payload;
        const id = ids[0];
        const item = state.item;

        if (!item || item.id !== id) {
            return;
        }

        // @ts-ignore
        item.isInFavorites = true;
    },
    [removeFromFavoritesSuccess.type]: (state, action: PayloadAction<IRemoveFromFavoritesSuccess>) => {
        const { ids } = action.payload;
        const id = ids?.[0];

        const { item, itemStorage } = state;

        if (!item || item.id !== id || !('isInFavorites' in item)) {
            return;
        }

        if (itemStorage === EStorageType.favorites) {
            let { itemIdxInArray, itemIds } = state;

            itemIds = itemIds.filter((itemId) => itemId !== id);

            if (itemIdxInArray !== null) {
                itemIdxInArray = itemIdxInArray < itemIds.length ? itemIdxInArray : itemIds.length - 1;

                let itemId = '';
                if (itemIdxInArray < 0) {
                    itemIdxInArray = null;
                } else {
                    itemId = itemIds[itemIdxInArray];
                }

                return {
                    ...state,
                    item: {
                        ...item,
                        isInFavorites: false,
                    },
                    itemId,
                    itemIds,
                    itemIdxInArray,
                };
            }
        }

        return {
            ...state,
            item: {
                ...item,
                isInFavorites: false,
            },
        };
    },

    [moveItemsSuccess.type]: (state, action: PayloadAction<MoveItemsSuccessAction>) => {
        const { newItems, oldIds, storage } = action.payload;

        /**
         * При перемещении файла в просмотрщике в хомяке - убираем перемещаемый файл из списка + обновляем данные
         * (логика как при удалении)
         */
        if (storage === EStorageType.home) {
            const { itemIds } = state;
            let { itemIdxInArray, itemId } = state;
            const newItemIds = itemIds.filter((id) => !oldIds.includes(id));
            const totalCount = state.totalCount - (itemIds.length - newItemIds.length);

            if (itemIdxInArray) {
                itemIdxInArray = itemIdxInArray < newItemIds.length ? itemIdxInArray : newItemIds.length - 1;

                if (itemIdxInArray < 0) {
                    itemIdxInArray = null;
                    itemId = '';
                } else {
                    itemId = newItemIds[itemIdxInArray];
                }
            }

            return {
                ...state,
                totalCount,
                itemIds: newItemIds,
                itemIdxInArray,
                itemId,
                isActive: Boolean(itemId),
            };
        }

        // При перемещении файла в просмотрщике не в хомяке (favorites, gallery, feed и тд) -  обновляем id.
        newItems.forEach((newItem, index) => {
            const file = newItem;
            const oldItemId = oldIds[index];

            if (oldItemId === state.itemId) {
                state.itemId = file.id;
                state.item = file;
                state.itemIds[state.itemIdxInArray || 0] = file.id;
            }
        });
    },

    [removeFileSuccess.type]: (state, action: PayloadAction<RemoveFileSuccessAction>) => {
        const { ids, reason } = action.payload;

        if (reason === EModifyReason.move) {
            return state;
        }

        let { itemIdxInArray, itemId } = state;
        const { itemIds } = state;

        const newItemIds = itemIds.filter((id) => !ids.includes(id));

        const totalCount = state.totalCount - (itemIds.length - newItemIds.length);

        if (itemIdxInArray !== null) {
            itemIdxInArray = itemIdxInArray < newItemIds.length ? itemIdxInArray : newItemIds.length - 1;

            if (itemIdxInArray < 0) {
                itemIdxInArray = null;
                itemId = '';
            } else {
                itemId = newItemIds[itemIdxInArray];
            }
        }

        return {
            ...state,
            totalCount,
            itemIds: newItemIds,
            itemIdxInArray,
            itemId,
            isActive: Boolean(itemId),
        };
    },

    [setIdxs.type]: (state, action: PayloadAction<string[]>) => {
        const itemIds = action.payload;

        return { ...state, itemIds };
    },

    [attachesFetchSuccess.type]: (state, action: ReturnType<typeof attachesFetchSuccess>) => {
        const id = state.item?.id;
        if (!id || !action.payload?.attaches) {
            return;
        }

        const item = action.payload.attaches[id];
        if (item && state.item) {
            let thumbnails = 'thumbnails' in state.item ? { ...state.item.thumbnails } : {};
            if ('thumbnails' in item) {
                thumbnails = { ...thumbnails, ...item.thumbnails };
            }
            state.item = {
                ...state.item,
                ...item,
                type: 'type' in state.item ? state.item.type : item.type,
                url: 'url' in state.item ? state.item?.url : item.url, // урлы те же должны быть, но они отличаются из разных апи, потому картинка моргает
                thumbnails,
            };
        }
    },

    [setIsOpening.type]: (state, action: PayloadAction<boolean>) => {
        state.isOpening = action.payload;
    },

    [setOvidiusZoom.type]: (state, action: ReturnType<typeof setOvidiusZoom>) => {
        const { value, id } = action.payload;
        state.ovidiusZoom[id] = value;
    },
});
