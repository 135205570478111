import React, { memo, ReactElement, RefObject, useEffect, useMemo, useRef } from 'react';
import { Portal } from 'react-portal';

import styles from './DropNotify.css';

interface IProps {
    dragOverName: string;
    isAllowedToDrop: boolean;
    target: RefObject<HTMLElement> | null;
    specialText?: string;
}

const OFFSET = 4;

export const DropNotify = memo(function DropNotify({ dragOverName, isAllowedToDrop, target, specialText }: IProps): ReactElement | null {
    const elementRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (elementRef?.current) {
            const elementRect = elementRef.current.getBoundingClientRect();
            const targetRect = target?.current?.getBoundingClientRect();

            if (targetRect) {
                let { left } = targetRect;
                const { top, height } = targetRect;

                const windowWidth = Math.min(window.innerWidth, window.document.body.clientWidth);

                if (elementRect.width + left >= windowWidth) {
                    left = windowWidth - elementRect.width;
                }

                elementRef.current.style.left = `${left}px`;
                elementRef.current.style.top = `${top + height + OFFSET}px`;
            }
        }
    }, [target]);

    const text = useMemo(() => {
        if (specialText) {
            return specialText;
        }

        if (!isAllowedToDrop) {
            return 'Перемещение в этот раздел невозможно';
        }

        return `Переместить в «${dragOverName}»`;
    }, [dragOverName, isAllowedToDrop, specialText]);

    return (
        <Portal>
            <div className={styles.root} ref={elementRef}>
                <div className={styles.content}>{text}</div>
            </div>
        </Portal>
    );
});
