import { EditorID } from 'Cloud/Application/Editor/types';

import OfficeOnline from './OfficeOnline';

/**
 * Редактор документов
 */
export const OFFICE_EDITOR = {
    [EditorID.OFFICE]: OfficeOnline,
    [EditorID.R7]: OfficeOnline,
    [EditorID.MYOFFICE]: OfficeOnline,
    [EditorID.R7_WOPI]: OfficeOnline,
} as const;

export const newDocNamesByExt = {
    docx: 'Новый документ',
    xlsx: 'Новая таблица',
    pptx: 'Новая презентация',
};

export const BUBBLE_ID = 'document-bubble';

export const X_IN_SHARED_FOLDER = 'x-in-shared-folder';
