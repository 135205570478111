/* tempexp_15344-full-file */
import { parse, stringify } from 'qs';
import React, { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_CHOSEN_PUBLIC_FILE, IS_MOBILE_BROWSER, IS_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import { abRequiredSignUpWhenDownloadingTouchSelector } from 'reactApp/appHelpers/featuresHelpers';
import { chooseVariant } from 'reactApp/appHelpers/featuresHelpers/utils';
import { requiredAuthorizationHelpers } from 'reactApp/modules/requiredAuthorization/helpers';
import { reDownloadController } from 'reactApp/modules/requiredAuthorization/reDownloadController';
import { setQueryParams } from 'reactApp/modules/settings/settings.module';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipAppearance, ETooltipPlacement, ETooltipSize } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';
import { noopVoid } from 'reactApp/utils/helpers';
import { EQueryParams } from 'server/helpers/getRequestParams';

interface Props {
    btnText: string;
    isSinglePublic?: boolean;
    isWhite?: boolean;
    downloadRef: HTMLDivElement | null;
    closeOnOutsideClick?: boolean;
    smallArrow?: boolean;
    placement?: ETooltipPlacement;

    onShow?(): void;

    onClose?(): void;

    onClick?(): void;
}

export const DownloadTooltip: FC<Props> = memo(
    ({
        isSinglePublic,
        isWhite = false,
        downloadRef,
        closeOnOutsideClick = false,
        smallArrow = false,
        placement,
        onShow = noopVoid,
        onClose = noopVoid,
        onClick = noopVoid,
    }) => {
        const dispatch = useDispatch();
        const isNewbie = useSelector(UserSelectors.isNewbie);
        const isSuccessAuthWhenDownloadVariant1 = useSelector(requiredAuthorizationHelpers.isSuccessAuthVariant1);
        const isSuccessAuthWhenDownload = useSelector(requiredAuthorizationHelpers.isSuccessAuthWhenDownload);

        const isFolder = useSelector(requiredAuthorizationHelpers.isTouchIdFolder);

        const enabledOnDesktop = isSinglePublic
            ? isSuccessAuthWhenDownloadVariant1 && !reDownloadController.isDownloadHappened()
            : isSuccessAuthWhenDownload;

        const enabledOnTouch = chooseVariant(() => abRequiredSignUpWhenDownloadingTouchSelector(false), {
            control: false,
            variant1: IS_MOBILE_BROWSER,
            variant2: IS_MOBILE_BROWSER,
        });

        const showTooltip = downloadRef && IS_PUBLIC && (IS_CHOSEN_PUBLIC_FILE || !isNewbie) && (enabledOnDesktop || enabledOnTouch);

        const handleClose = useCallback(() => {
            onClose();

            let search;
            let url;
            if (showTooltip) {
                search = parse(window.location.search, { ignoreQueryPrefix: true }) || {};
                delete search[EQueryParams.requiredSignUpTouchSource];

                url = window.location.pathname + stringify(search, { addQueryPrefix: true });

                window.history.replaceState(null, '', url);

                dispatch(setQueryParams(search));
                requiredAuthorizationHelpers.setShowDownloadTip(false);
            }
        }, [onClose, showTooltip]);

        if (!showTooltip) {
            return null;
        }

        return (
            <FloatingTooltip
                target={downloadRef}
                title=""
                text={chooseVariant(abRequiredSignUpWhenDownloadingTouchSelector, {
                    control: <>Теперь {isFolder ? 'папку' : 'файл'} можно скачать</>,
                    variant1: (
                        <>
                            Теперь{isFolder ? ' папку' : ' файл'}
                            <br />
                            можно скачать
                        </>
                    ),
                    variant2: (
                        <>
                            Теперь{isFolder ? ' папку' : ' файл'}
                            <br />
                            можно скачать
                        </>
                    ),
                })}
                size={ETooltipSize.fit}
                placement={placement || ETooltipPlacement.bottom}
                qaId="required-auth-when-download"
                appearance={isWhite ? ETooltipAppearance.white : ETooltipAppearance.dark}
                onShow={onShow}
                onClick={onClick}
                onClose={handleClose}
                closeOnOutsideClick={closeOnOutsideClick}
                smallArrow={smallArrow}
                smallClose
            />
        );
    }
);

DownloadTooltip.displayName = 'DownloadTooltip';
