import { logger } from '@mail-core/logger';
import config from 'Cloud/config';
import { getDomainFoldersApiCall } from 'reactApp/api/DomainFoldersApiCall';
import { itemV4ToV2 } from 'reactApp/api/helpers/apiV4Helpers';
import { IS_B2B_BIZ_USER, IS_BLOCKED, IS_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import { loadDomainFoldersStartRequest, loadDomainFoldersSuccess } from 'reactApp/modules/domainFolders/domainFolders.actions';
import { loggerSaga } from 'reactApp/modules/logger/logger.saga';
import { addFilesSuccess } from 'reactApp/modules/modifying/modifying.actions';
import { routeStatusPage } from 'reactApp/modules/router/router.module';
import { EStatus } from 'reactApp/sections/ErrorPage/ErrorPage.types';
import { call, put, takeEvery } from 'redux-saga/effects';

export const domainFoldersApiCall = () => getDomainFoldersApiCall().makeRequest();

export function* getDomainFoldersList() {
    const { data } = yield call(domainFoldersApiCall);

    try {
        return itemV4ToV2({ list: data?.body });
    } catch (error) {
        yield call(loggerSaga, { error, action: routeStatusPage({ status: EStatus.NOT_FOUND }) });
    }
}

function* loadDomainFolders() {
    try {
        if (!IS_B2B_BIZ_USER || IS_PUBLIC || config.get('STATIC') || IS_BLOCKED) {
            return;
        }

        const { list } = yield call(getDomainFoldersList);
        yield put(loadDomainFoldersSuccess(list));
    } catch (error) {
        logger.error(error);
    }
}

export function* watchDomainFolders() {
    yield takeEvery(addFilesSuccess.toString(), loadDomainFolders);
    yield takeEvery(loadDomainFoldersStartRequest.toString(), loadDomainFolders);
}
