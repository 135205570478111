import { useCallback, useEffect, useState } from 'react';
import { o2AuthClient } from 'reactApp/api/O2AuthClient';

export const useO2AuthClient = () => {
    const [token, setToken] = useState<string | null>(null);

    const refreshToken = useCallback(
        () =>
            o2AuthClient.refreshToken().then((token) => {
                setToken(token);
            }),
        []
    );

    useEffect(() => {
        o2AuthClient.getToken().then((token) => {
            setToken(token);
        });
    }, []);

    return {
        token,
        refreshToken,
    };
};
