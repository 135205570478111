(function () {
    function arrayBufferPolyfill() {
        // this: File or Blob
        return new Promise((resolve) => {
            const fr = new FileReader();
            fr.onload = () => {
                resolve(fr.result);
            };
            fr.readAsArrayBuffer(this);
        });
    }

    File.prototype.arrayBuffer = File.prototype.arrayBuffer || arrayBufferPolyfill;
    Blob.prototype.arrayBuffer = Blob.prototype.arrayBuffer || arrayBufferPolyfill;
})();
