import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

export enum ECloudNewFeaturesActions {
    show = 'show',
    showNews = 'show-news',
    click = 'click',
    showTool = 'show-tool',
    clickTool = 'click-tool',
    clickViewAll = 'click-view-all',
}

interface ICloudNewFeaturesAnalytics {
    action: ECloudNewFeaturesActions;
    place?: string;
}

export const sendCloudNewFeaturesAnalytics = ({ action, ...rest }: ICloudNewFeaturesAnalytics) => {
    sendDwh({
        eventCategory: ECategoryGa.bell,
        action,
        dwhData: {
            source: IS_PHONE_BROWSER ? 'touch' : 'web',
            ...rest,
        },
    });
};
