import React, { ClipboardEvent, FC, useEffect, useRef, useState } from 'react';
import { KeyCodes } from 'reactApp/constants/KeyCodes';
import { MAX_FILENAME_LENGTH } from 'reactApp/ui/EditorHeader/WhiteEditorHeader/components/HeaderLeftGroup/components/HeaderFileName/HeaderFileName';
import { execCommand, getClipboadText } from 'reactApp/utils/helpers';
import { truncateTextMiddle } from 'reactApp/utils/textHelpers';

import styles from './FileNameInput.css';

interface Props {
    value: string;
    onEdit: (value: { value: string }) => Promise<string | void>;
    onClick: () => void;
    setIsEdit: (value: boolean) => void;
}

export const FileNameInput: FC<Props> = ({ value = '', onEdit, onClick, setIsEdit }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [maskValue, setMaskValue] = useState('');

    useEffect(() => {
        ref.current?.focus();
    }, []);

    useEffect(() => {
        setMaskValue(truncateTextMiddle(value, MAX_FILENAME_LENGTH));
    }, [value]);

    const onFocus = () => {
        if (maskValue !== value) {
            setMaskValue(value);
        }

        setIsEdit(true);
    };

    const emitChange = () => {
        const currentValue = ref.current?.textContent || '';

        if (currentValue !== value) {
            onEdit({ value: currentValue }).catch(() => {
                if (ref.current) {
                    ref.current.textContent = value;
                }
            });
        }

        setMaskValue(truncateTextMiddle(currentValue, MAX_FILENAME_LENGTH));
        setIsEdit(false);
    };

    const handleKeyDown = (event) => {
        const keyCode = event.keyCode || event.which;

        if (keyCode === KeyCodes.DOM_VK_ENTER || keyCode === KeyCodes.DOM_VK_RETURN) {
            event.returnValue = false;
            if (event.preventDefault) {
                event.preventDefault();
                event.target?.blur();
            }
        }

        if (keyCode === KeyCodes.DOM_VK_ESCAPE) {
            event.returnValue = false;
            if (event.preventDefault && ref.current) {
                event.preventDefault();
                ref.current.textContent = value;
                event.target.blur();
            }
        }
    };

    const handlePaste = (event: ClipboardEvent<HTMLDivElement>) => {
        event.preventDefault();
        const text = getClipboadText(event);

        if (!text) {
            return;
        }

        execCommand('insertText', text.replace(/[\r\n]+/gm, ''));
    };

    return (
        <div
            className={styles.fileNameInput}
            ref={ref}
            onBlur={emitChange}
            onPaste={handlePaste}
            contentEditable
            onKeyDown={handleKeyDown}
            onDoubleClick={onClick}
            onFocus={onFocus}
            suppressContentEditableWarning
        >
            {maskValue}
        </div>
    );
};
