import { EArchiveType } from 'reactApp/modules/viewer/viewer.types';

export enum EHashLibType {
    js = 'js',
    wasm = 'wasm',
    cryptoApi = 'cryptoApi',
}

export interface IHashCalculator {
    addData(data, hashLibType: EHashLibType): Promise<{ error: string; buffer: null | Uint8Array }>;

    canUseTransferable(data: ArrayBuffer): boolean;

    getHash(): Promise<string>;
}

export type UnpackOptions = { name: string; fileUrl: string; directDownload?: boolean; type: EArchiveType; withCredentials?: boolean };

export interface IUnpackOnClientWorker {
    init(path: string): void;

    unpack(options: UnpackOptions): void;

    getFileContentBlobUrl(path: string): Promise<string>;

    clearCache(): void;
}
