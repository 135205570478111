export enum EmailStatus {
    CONFIRMED = 'CONFIRMED',
    CONFIRMING = 'CONFIRMING',
    ABSENT = 'ABSENT',
}

type EmailStatusType = EmailStatus.ABSENT | EmailStatus.CONFIRMING | EmailStatus.CONFIRMED | '';

export interface CheckEmailResponse {
    state: EmailStatusType;
    email: string;
    code: string;
}

export interface State {
    email: string;
    status: EmailStatusType;
    error: string;
    isLoading: boolean;
    isLoaded: boolean;
}
