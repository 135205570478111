import React, { Dispatch, memo, SetStateAction, useCallback, useRef } from 'react';
import { SelectFromCloudContentMod } from 'reactApp/sections/AlbumsPage/SelectFromCloudPopup/components/Content/SelectFromCloudContent';
import { ClearButton } from 'reactApp/ui/Header/WebSearch/ClearButton/ClearButton';
import { SearchButton } from 'reactApp/ui/Header/WebSearch/SearchButton/SearchButton';
import { SearchInput } from 'reactApp/ui/Header/WebSearch/SearchInput/SearchInput';
import { TransitionWrapper } from 'reactApp/ui/Header/WebSearch/TransitionWrapper/TransitionWrapper';

import { CategoryFilters } from './components/CategoryFilters/CategoryFilters';
import { TypeSelectButton } from './components/TypeSelectButton/TypeSelectButton';
import { useSelectFromCloudSearchHandlers } from './SelectFromCloudSearch.hooks';

const inputBorderRadius = {
    '--vkui--size_border_radius--regular': '8px 0 0 8px',
} as React.CSSProperties;

interface SelectFromCloudSearchProps {
    isSearchExpanded: boolean;
    setSearchExpanded: Dispatch<SetStateAction<boolean>>;
    setMod: Dispatch<SetStateAction<SelectFromCloudContentMod>>;
}

export const SelectFromCloudSearch = memo<SelectFromCloudSearchProps>(({ isSearchExpanded, setSearchExpanded, setMod }) => {
    const rootRef = useRef<HTMLDivElement>(null);
    const searchRootRef = useRef<HTMLDivElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const categoryFilersRef = useRef<HTMLButtonElement>(null);

    const toggleSearch = useCallback(
        (toggleState: boolean) => {
            setSearchExpanded(toggleState);
        },
        [setSearchExpanded]
    );

    const {
        value,
        searchOption,
        isOpen,
        onInputClick,
        onInputChange,
        onInputBlur,
        onKeyUp,
        onInputClear,
        onToggleFilters,
        onSearchClick,
        chooseSearchType,
        removeSearchType,
        onClickAway,
        setActiveInput,
    } = useSelectFromCloudSearchHandlers({
        isSearchExpanded,
        toggleSearch,
        setMod,
    });

    const onTransitionEntered = useCallback(() => {
        setActiveInput();
    }, [setActiveInput]);

    return (
        <TransitionWrapper ref={rootRef} isExpanded={isSearchExpanded} onEntered={onTransitionEntered}>
            <SearchInput
                placeholder={isSearchExpanded ? 'Поиск фото и видео' : 'Поиск по облаку'}
                onClick={onInputClick}
                onChange={onInputChange}
                onBlur={onInputBlur}
                value={value}
                onKeyUp={onKeyUp}
                getRef={searchInputRef}
                getRootRef={searchRootRef}
                after={isSearchExpanded && <ClearButton onClick={onInputClear} />}
                style={isSearchExpanded ? inputBorderRadius : undefined} // TODO убрать инлайн-стиль после перехода на 5-ю версию VKUI
            />
            {isSearchExpanded && (
                <>
                    <TypeSelectButton searchOption={searchOption} onClick={onToggleFilters} ref={categoryFilersRef} isOpen={isOpen} />
                    {isOpen && (
                        <CategoryFilters
                            searchOption={searchOption}
                            targetRef={categoryFilersRef}
                            rootRef={rootRef}
                            onSelect={chooseSearchType}
                            onReset={removeSearchType}
                            onClickAway={onClickAway}
                        />
                    )}
                    <SearchButton onClick={onSearchClick} />
                </>
            )}
        </TransitionWrapper>
    );
});

SelectFromCloudSearch.displayName = 'SelectFromCloudSearch';
