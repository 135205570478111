import React, { memo, ReactElement, useCallback, useState } from 'react';
import { isVirusItem } from 'reactApp/modules/file/utils';
import { CloudItem } from 'reactApp/modules/storage/storage.types';
import { IThumb } from 'reactApp/ui/DataListItem/DataListItem.helpers';
import { FileIcon } from 'reactApp/ui/FileIcon/FileIcon';
import { getIconType } from 'reactApp/ui/FileIcon/getIconType';
import { ImageCancelable } from 'reactApp/ui/ImageCancelable/ImageCancelable';

import styles from './DataListDragItem.css';

interface Props {
    dragPosX: number;
    dragPosY: number;
    selectedCount: number;
    item: CloudItem;
    thumb?: IThumb;
}

const cursorMargin = 65;

export const DataListDragItem = memo(({ dragPosX, dragPosY, thumb, selectedCount = 0, item }: Props): ReactElement => {
    const iconType = getIconType({
        isVirus: isVirusItem(item),
        ...item,
    });

    const [isLoaded, setLoaded] = useState<boolean>(false);

    const onLoad = useCallback(() => {
        setLoaded(true);
    }, []);

    return (
        <div className={styles.dragging} style={{ top: dragPosY - cursorMargin, left: dragPosX - cursorMargin }}>
            {selectedCount > 1 && <div className={styles.counter}>{selectedCount}</div>}
            <div className={styles.draggingItem}>
                <div className={styles.container}>
                    {!isLoaded && (
                        <div className={styles.icon}>
                            <FileIcon.New type={iconType} size="m" />
                        </div>
                    )}
                    {!item?.isFolder && thumb?.src && (
                        <ImageCancelable
                            className={styles.thumb}
                            src={thumb?.src}
                            srcSet={thumb?.srcSet ? `${thumb?.srcSet} 2x` : ''}
                            alt=""
                            onLoad={onLoad}
                        />
                    )}
                </div>
            </div>
        </div>
    );
});

DataListDragItem.displayName = 'DataListDragItem';
