import {
    Icon20ArticlesOutline,
    Icon20DeleteOutline,
    Icon20LightbulbOutline,
    Icon20LikeOutline,
    Icon20Sync,
    Icon20WriteOutline,
} from '@vkontakte/icons';
import React from 'react';
import { ActionCategory, MessageCategory, Messages, SelectType } from 'reactApp/ui/EditorLLM/EditorLLMDialog/types/EditorLlmDialog.types';
import { getActionSelectOptions, getScenarioSelectOptions } from 'reactApp/ui/EditorLLM/EditorLLMDialog/utils/EditorLlmDialog.utils';
import { llmFeature } from 'reactApp/ui/EditorLLM/helpers/EditorLlmHelpers';

export const DEFAULT_MESSAGES: Messages = [
    {
        message: llmFeature.welcomeMessage,
        isOwn: false,
    },
];

export const SCENARIO_SELECT_ICON_MAP = {
    [MessageCategory.TEXT_BY_THEME]: <Icon20WriteOutline />,
    [MessageCategory.BRAINSTORM]: <Icon20LightbulbOutline />,
    [MessageCategory.POST]: <Icon20LikeOutline />,
};

export const ACTIONS_SELECT_ICON_MAP = {
    [ActionCategory.CONTINUE]: <Icon20ArticlesOutline />,
    [ActionCategory.REWRITE]: <Icon20Sync />,
    [ActionCategory.DELETE]: <Icon20DeleteOutline />,
};

export const SCENARIO_SELECT_OPTIONS = getScenarioSelectOptions();

export const ACTIONS_SELECT_OPTIONS = getActionSelectOptions();

export const DEFAULT_SELECT_TYPE: SelectType = { value: SCENARIO_SELECT_OPTIONS[0]?.value, label: SCENARIO_SELECT_OPTIONS[0]?.label };
