import { STORAGE_CONFIG } from 'reactApp/modules/storage/storage.config.new';
import { EActions, EStorageType } from 'reactApp/modules/storage/storage.types';

export const TOOLBAR_CONFIG = {
    [EStorageType.home]: {
        ...STORAGE_CONFIG[EStorageType.home],
        [EActions.download]: 5,
        [EActions.remove]: 4,
        [EActions.publish]: 3,
        [EActions.publishAlbum]: 3,
        [EActions.unpublish]: 3,
        [EActions.share]: 2,
        [EActions.addToFavorites]: 1,
        [EActions.removeFromFavorites]: 1,
        [EActions.addToAlbum]: 1,
        [EActions.authorSort]: true,
        [EActions.newFeatures]: true,
    },
    [EStorageType.trashbin]: {
        ...STORAGE_CONFIG[EStorageType.trashbin],
        [EActions.newFeatures]: true,
    },
    [EStorageType.recommend]: {
        ...STORAGE_CONFIG[EStorageType.recommend],
    },
    [EStorageType.attaches]: {
        ...STORAGE_CONFIG[EStorageType.attaches],
        [EActions.sendViaMail]: true,
    },
    [EStorageType.favorites]: {
        ...STORAGE_CONFIG[EStorageType.favorites],
        [EActions.download]: 5,
        [EActions.remove]: 3,
        [EActions.publish]: 1,
        [EActions.publishAlbum]: 1,
        [EActions.unpublish]: 1,
        [EActions.share]: 2,
        [EActions.addToFavorites]: 3,
        [EActions.removeFromFavorites]: 3,
        [EActions.addToAlbum]: 1,
        [EActions.newFeatures]: true,
    },
    [EStorageType.sharedIncoming]: {
        ...STORAGE_CONFIG[EStorageType.sharedIncoming],
        [EActions.newFeatures]: true,
    },
    [EStorageType.sharedLinks]: {
        ...STORAGE_CONFIG[EStorageType.sharedLinks],
        [EActions.download]: 1,
        [EActions.publish]: 2,
        [EActions.publishAlbum]: 2,
        [EActions.unpublish]: 2,
        [EActions.share]: 3,
        [EActions.addToAlbum]: 1,
        [EActions.newFeatures]: true,
    },
    [EStorageType.sharedAutodelete]: {
        ...STORAGE_CONFIG[EStorageType.sharedAutodelete],
        [EActions.download]: 1,
        [EActions.publish]: 2,
        [EActions.unpublish]: 2,
        [EActions.newFeatures]: true,
    },
    [EStorageType.search]: {
        ...STORAGE_CONFIG[EStorageType.search],
        [EActions.download]: 4,
        [EActions.publish]: 3,
        [EActions.unpublish]: 3,
        [EActions.share]: 2,
        [EActions.remove]: 2,
        [EActions.addToFavorites]: 1,
        [EActions.removeFromFavorites]: 1,
        [EActions.newFeatures]: true,
    },
    [EStorageType.feed]: {
        ...STORAGE_CONFIG[EStorageType.feed],
        [EActions.download]: 3,
        [EActions.publish]: 1,
        [EActions.publishAlbum]: 1,
        [EActions.unpublish]: 1,
        [EActions.remove]: 2,
        [EActions.addToAlbum]: 1,
        [EActions.newFeatures]: true,
    },
    [EStorageType.gallery]: {
        ...STORAGE_CONFIG[EStorageType.gallery],
        [EActions.addToFavorites]: 1,
        [EActions.removeFromFavorites]: 1,
        [EActions.publish]: 2,
        [EActions.publishAlbum]: 2,
        [EActions.unpublish]: 2,
        [EActions.addToAlbum]: 1,
        [EActions.newFeatures]: true,
    },
    [EStorageType.alldocuments]: {
        ...STORAGE_CONFIG[EStorageType.feed],
        [EActions.download]: 3,
        [EActions.publish]: 1,
        [EActions.publishAlbum]: 1,
        [EActions.unpublish]: 1,
        [EActions.remove]: 2,
        [EActions.addToAlbum]: 1,
    },
    [EStorageType.documents]: {
        ...STORAGE_CONFIG[EStorageType.documents],
        [EActions.download]: 3,
        [EActions.publish]: 1,
        [EActions.publishAlbum]: 1,
        [EActions.unpublish]: 1,
        [EActions.addToFavorites]: 2,
        [EActions.removeFromFavorites]: 2,
        [EActions.addToAlbum]: 1,
        [EActions.newFeatures]: true,
    },
    [EStorageType.public]: {
        ...STORAGE_CONFIG[EStorageType.public],
        [EActions.remove]: 1,
        [EActions.download]: 2,
        [EActions.clone]: 3,
        [EActions.add]: false,
        [EActions.authorSort]: true,
    },
    [EStorageType.stock]: {
        ...STORAGE_CONFIG[EStorageType.stock],
        [EActions.remove]: 1,
        [EActions.download]: 2,
        [EActions.clone]: 3,
    },
    [EStorageType.quotaCleaner]: {
        ...STORAGE_CONFIG[EStorageType.quotaCleaner],
        delete: true,
        [EActions.selectAll]: true,
        [EActions.back]: true,
        [EActions.yearFilter]: true,
    },
    [EStorageType.albums]: {
        ...STORAGE_CONFIG[EStorageType.albums],
        [EActions.remove]: 3,
        [EActions.publish]: 2,
        [EActions.unpublish]: 2,
        [EActions.download]: 1,
        [EActions.addToFavorites]: 2,
        [EActions.removeFromFavorites]: 2,
        [EActions.addToAlbum]: 2,
        [EActions.selectAll]: true,
        [EActions.back]: true,
        [EActions.createAlbum]: true,
    },
    [EStorageType.start]: {
        ...STORAGE_CONFIG[EStorageType.start],
    },
    [EStorageType.integration]: STORAGE_CONFIG[EStorageType.integration],
    [EStorageType.inlineIntegration]: STORAGE_CONFIG[EStorageType.inlineIntegration],
};

export const moreListOrder = [
    EActions.addToFavorites,
    EActions.removeFromFavorites,
    EActions.copy,
    EActions.move,
    EActions.rename,
    EActions.history,
    EActions.remove,
];
export const actionsOrder = [
    EActions.back,
    EActions.selectAll,
    EActions.restoreAll,
    EActions.restore,
    EActions.clear,
    EActions.clone,
    EActions.sendViaMail,
    EActions.publish,
    EActions.publishAlbum,
    EActions.unpublish,
    EActions.share,
    EActions.addToFavorites,
    EActions.removeFromFavorites,
    EActions.mount,
    EActions.createAlbum,
    EActions.addToAlbum,
    EActions.download,
];
export const actionsOrder_PdfEdit = [
    EActions.back,
    EActions.addToFavorites,
    EActions.removeFromFavorites,
    EActions.download,
    EActions.publish,
    EActions.unpublish,
];

export const actionsOrder_EbookReader = [
    EActions.back,
    EActions.publish,
    EActions.addToFavorites,
    EActions.removeFromFavorites,
    EActions.download,
    EActions.unpublish,
];

export type TToolbarItemsKeys =
    | EActions.back
    | EActions.upload
    | EActions.create
    | EActions.selectAll
    | EActions.download
    | EActions.publish
    | EActions.unpublish
    | EActions.share
    | EActions.removeFromFavorites
    | EActions.view
    | EActions.sort
    | EActions.sendViaMail
    | EActions.more
    | EActions.restore
    | EActions.restoreAll
    | EActions.clear
    | EActions.mount
    | EActions.clone
    | EActions.publishAlbum
    | EActions.addToAlbum
    | EActions.createAlbum;

export type TToolbarDropdownItemsKeys =
    | EActions.copy
    | EActions.uploadFile
    | EActions.uploadFolder
    | EActions.history
    | EActions.move
    | EActions.rename
    | EActions.viewThumbs
    | EActions.viewList
    | EActions.viewGallery
    | EActions.sortDate
    | EActions.sortSize
    | EActions.sortName
    | EActions.createCell
    | EActions.createDoc
    | EActions.createPpt
    | EActions.createFolder
    | EActions.remove
    | EActions.addToFavorites
    | EActions.removeFromFavorites;
