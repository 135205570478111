define(function(require, exports, module) {
	'use strict';

	if (process.env.SSR) {
        return;
    }

	var $ = require('jquery');
	var config = require('Cloud/config');

	var settings;
	var mocks = Object.create(null);

	function Mock(options, responses){
		var mock = Object.create(null);

		var currentMock = null;

		var makeMock = function(name, reset, once){
			var resetFn = this.reset.bind(this);

			if (reset !== false) {
				resetFn();
			}

			// mock
			var mock = responses[name];

			currentMock = $.mockjax({
				url: options.url,
				status: mock.status,
				response: function(req){
					this.responseText = {
						status: mock.status,
						body: mock.body,
						email: settings.email,
						time: new Date().getTime()
					};

					if (once) {
						resetFn();
					}
				}
			});
		}.bind(mock);

		Object.keys(responses).forEach(function(responseCase){
			var name = responseCase.replace(/_\S/ig, function(a,b,c){return a[1].toUpperCase();});

			this[name] = function(reset){
				return makeMock(responseCase, reset);
			};

			this[name].once = function(reset){
				return makeMock(responseCase, reset, true);
			};
		}.bind(mock));

		mock.reset = function(){
			$.mockjax.clear(currentMock);
			currentMock = null;
		};

		return mock;
	}

	var started = false;

	function setMocks(){
		var options = settings;

		Object.keys(options.methods).forEach(function(methodName){
			var mock = new Mock({url: options.url + methodName}, options.methods[methodName]);

			methodName = methodName.split('/');

			var method = mocks;

			methodName.forEach(function(namePart, i, name){
				if (!method[namePart]){
					method = method[namePart] = i == name.length - 1 ? mock : Object.create(null);
				} else {
					method = method[namePart];
				}

			});
		});

		mocks.reset = function(){
			$.mockjax.clear();
		};

		started = true;

		return mocks;
	}

	mocks.load = function(){
		if (started){
			return mocks;
		}

		return $.getScript(config.get('API_MOCKS'), function(){
			delete mocks.load;
			setMocks(settings);
			console && console.log && console.log('MOCKS READY');
		}).then(function () {
			return mocks;
		});

	};

	module.exports = function(options){
		settings = options;
		return mocks;
	};
});
