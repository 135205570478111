export default ({ request, check, count = 20, finishTime = +new Date() * 1000 * 60 * 20 }) =>
    new Promise((resolve, reject) => {
        let i = 1;
        let delay = 1000;
        let id = setTimeout(function tick() {
            request()
                .then(data => {
                    if (check(data)) {
                        resolve(data);

                        clearTimeout(id);
                    } else {
                        delay = 1000 * i * i;

                        if (i < count && +new Date() < finishTime) {
                            i++;
                            id = setTimeout(tick, delay);
                        } else {
                            clearTimeout(id);
                            reject();
                        }
                    }
                })
                .catch(error => reject(error));
        }, delay);
    });
