import classNames from 'clsx';
import React, { memo, ReactElement } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import Checkbox from 'reactApp/ui/CheckBox/CheckBox';

import styles from './NoWarningDialog.css';
import { IProps as IBaseProps } from './NoWarningDialog.types';

interface IProps extends IBaseProps {
    isCheckboxSelected: boolean;
    handleChecboxClick(): void;
    handleAccept(): void;
    handleClose(): void;
    isPhone: boolean;
}

export const NoWarningDialogContent = memo(function NoWarningDialogContent(props: IProps): ReactElement {
    const noWarningText = props.noWarningText || 'Больше не предупреждать';
    const buttonTheme = props.isPhone ? 'vk' : 'octavius';

    return (
        <div className={classNames(styles.content, { [styles.content_phone]: props.isPhone })}>
            <div className={styles.title}>{props.title}</div>
            <div className={styles.description}>{props.description}</div>
            {!props.hideCheckbox && (
                <div className={styles.noWarning} onClick={props.handleChecboxClick} data-name="askAgain">
                    <Checkbox selected={props.isCheckboxSelected} mod="blue" />
                    <div className={styles.noWarningText}>{noWarningText}</div>
                </div>
            )}
            <div className={styles.wrapper_button}>
                <Button className={styles.button} theme={buttonTheme} primary onClick={props.handleAccept} fluid data-name="action">
                    {props.buttonAcceptText}
                </Button>
                <Button className={styles.button} theme={buttonTheme} onClick={props.handleClose} fluid data-name="cancel">
                    {props.buttonRejectText}
                </Button>
            </div>
        </div>
    );
});
