import React, { Fragment, ReactNode, useEffect, useState } from 'react';

import { checkTouchEventsSupport } from './Gestures.helpers';
import { GesturesProps, GestureTypes } from './Gestures.types';
import { GesturesZoom } from './GesturesZoom';

export function GesturesEmpty({ children }: { children?: ReactNode }) {
    return <>{children}</>;
}

export function Gestures({ type, children, ...props }: GesturesProps) {
    const [hasTouchEvents, setHasTouchEvents] = useState<boolean>(false);

    useEffect(() => {
        setHasTouchEvents(checkTouchEventsSupport());
    }, []);

    if (!hasTouchEvents) {
        return <>{children}</>;
    }

    const Wrapper =
        {
            [GestureTypes.zoom]: GesturesZoom,
        }[type] || GesturesEmpty;

    return <Wrapper {...props}>{children}</Wrapper>;
}
