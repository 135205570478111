import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';

import { IProps } from './NoWarningDialog.types';

export const renderUploadFromClipboardDialog = ({ onClose, onActionClick, files }) => {
    const title = `Загрузка ${files.length === 1 ? 'файла' : 'файлов'}`;
    const description = files.length === 1 ? `Подтвердите загрузку «${files[0].name}»` : `Подтвердите загрузку ${files.length} файлов`;

    openPopupHelper<IProps>({
        popupName: popupNames.NO_WARNING,
        data: {
            id: 'upload-clipboard',
            title,
            noWarningText: 'Больше не спрашивать при загрузке',
            buttonAcceptText: 'Загрузить',
            buttonRejectText: 'Отменить',
            onActionClick,
            description,
            onClose,
        },
    });
};

export const renderAlertToPublicDialog = ({ title, subjectTitle, actionPart, onActionClick }) => {
    const description = `После ${actionPart} файлы будут доступны всем, у кого есть доступ к этой папке`;

    openPopupHelper<IProps>({
        popupName: popupNames.NO_WARNING,
        data: {
            id: 'noWarningToPublicAlert',
            title: `${title} ${subjectTitle} в папку общего доступа?`,
            buttonAcceptText: title,
            buttonRejectText: 'Отмена',
            onActionClick,
            description,
        },
    });
};
