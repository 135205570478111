import classNames from 'clsx';
import comboIcon from 'img/icons/vk_combo.svg?url';
import React, { PureComponent, ReactElement } from 'react';

import styles from './ComboPromo.css';

export interface Props {
    href: string;
    under?: boolean;
    text?: string;
    inverse?: boolean;
    description: string;
}

export class ComboPromo extends PureComponent<Props> {
    public static defaultProps = {
        under: false,
    };

    private handleClick = (event) => {
        event.stopPropagation();
    };

    public render(): ReactElement | null {
        const { text, href, under, description, inverse } = this.props;

        if (!href || !description) {
            return null;
        }

        return (
            <div
                className={classNames({
                    [styles.root]: true,
                    [styles.root_under]: under,
                    [styles.root_inverse]: inverse,
                })}
            >
                {text && <div className={styles.text}>{text}</div>}
                <a className={styles.tariff} href={href} target="_blank" rel="noopener noreferrer" onClick={this.handleClick}>
                    <span className={styles.description}>{description}</span>
                    <img className={styles.icon} src={comboIcon} alt="combo" />
                </a>
            </div>
        );
    }
}
