import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { setPhListnerAction, setPrevEmail } from './ph.actions';
import { IState, SetPhListnerAction, SetPrevEmail } from './ph.types';

const initialState: IState = {
    phListner: false,
    prevEmail: null,
};

export const phReducer = createReducer(initialState, {
    [setPhListnerAction.type]: (state: IState, action: PayloadAction<SetPhListnerAction>) => {
        const value = action.payload.value;

        state.phListner = value;
    },
    [setPrevEmail.type]: (state: IState, action: PayloadAction<SetPrevEmail>) => {
        const prevEmail = action.payload.prevEmail;

        state.prevEmail = prevEmail;
    },
});
