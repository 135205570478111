export const loadingReducer = (state = {}, action) => {
    const { type } = action;
    const matches = /(.*)\/(request|success|failure)/.exec(type);

    if (!matches) {
        return state;
    }

    const [, requestName, requestState] = matches;
    return {
        ...state,
        // Store whether a request is happening at the moment or not
        // e.g. will be true when receiving @gift/fetch/request
        // and false when receiving @gift/fetch/success @gift/fetch/failure
        [requestName]: requestState === 'request',
    };
};
