import { IncomingFolder, IncomingState, IncomingStorage } from 'reactApp/modules/incoming/incoming.types';
import { RootState } from 'reactApp/store';
import { LoadingState } from 'reactApp/types/commonStates';
import { createSelector } from 'reselect';

export const getIncoming = (state: RootState): IncomingState => state.incoming;

export const getIncomingStorage = createSelector(getIncoming, (incoming): IncomingStorage => incoming['/']);
export const getIncomingItemById = createSelector(
    getIncoming,
    (state, id): string => id,
    (incoming, id): IncomingFolder | undefined => incoming.list[id]
);
export const getIncomingIds = createSelector(getIncoming, (incoming): string[] => incoming['/'].childs);

export const getIncomingItems = createSelector(
    getIncomingIds,
    (state) => state,
    (idxs, state) => idxs.map((id) => getIncomingItemById(state, id))
);
export const getAcceptedIncomingItems = createSelector(getIncomingItems, (items) => items.filter((item) => item?.kind === 'mounted'));
export const getWaitingAccceptIncomingItems = createSelector(getIncomingItems, (items) => items.filter((item) => item?.kind === 'invites'));
export const getWaitingAccceptIncomingItemByInviteToken = createSelector(
    getIncomingItems,
    (state: RootState, inviteToken: string): string => inviteToken,
    (items, inviteToken) => items.find((item) => item?.kind === 'invites' && item?.inviteToken === inviteToken)
);

export const getIncomingLoadingState = createSelector(getIncomingStorage, (shared) => {
    return {
        isLoading: shared.isLoading,
        isLoaded: shared.isLoaded,
    } as LoadingState;
});
