import { sendMyofficeGa } from 'Cloud/Application/Editor/MyOffice/helpers/sendMyofficeGa';
import { IInitMyoffice, MYOFFICE_VARIANT_NAMES, MYOFFICE_VARIANTS } from 'Cloud/Application/Editor/MyOffice/myOffice.types';
import { createForm } from 'Cloud/Application/Editor/utils/createForm';
import { setMyOfficeType } from 'reactApp/modules/editor/editor.module';
import { store as reduxStore } from 'reactApp/store';
import { MyOfficeBusinessMetric, sendMyOfficeMetric } from 'reactApp/ui/ReactViewer/helpers/sendMyOfficeMetric';
import { noop } from 'reactApp/utils/helpers';

export const initAndLoadWopi = ({
    container,
    iframeWopi,
    iframeId,
    listeners: { onLoad = noop },
    isMyOfficeForMail,
    wopiParams,
    isEditAttaches,
    metrics,
    documentMode,
}: IInitMyoffice & { iframeId: string }): (() => void) => {
    const { accessParams: inputs, postContentType, url: action } = wopiParams || {};
    reduxStore.dispatch(setMyOfficeType(MYOFFICE_VARIANTS.wopi));

    const form = createForm({
        container,
        action,
        inputs,
        target: iframeId,
        postContentType,
    });

    iframeWopi?.addEventListener?.('load', () => {
        sendMyofficeGa(`wopi-iframe-load${isMyOfficeForMail ? '-attach' : ''}${isEditAttaches ? '-edit' : '-view'}`);
    });

    form.submit();
    onLoad();

    sendMyOfficeMetric(MyOfficeBusinessMetric.request, {
        t: [documentMode, MYOFFICE_VARIANT_NAMES.ces, metrics?.ext],
        i: { [metrics?.size || '']: 1 },
    });

    return onLoad;
};
