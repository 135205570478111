import { ECategoryGa, EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

interface ICloneAnalytics {
    type_public: 'folder' | 'file';
    extension: string;
    id_public?: string;
    place: string;
    source: string;
}

/**
 * https://docs.google.com/spreadsheets/d/1oxESW-0Ne7WtP7gKejV6CJYSv-Q9ssFDC8MY1_chDT0/edit#gid=1008637332
 */
export const sendCloneAnalytics = (data: ICloneAnalytics) => {
    sendPaymentGa({
        eventCategory: ECategoryGa.public,
        action: EPaymentGa.saveInCloud,
        ...data,
    });
};
