import { xray } from 'lib/xray';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement, ETooltipSize } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

interface QuotaCleanerYearFilterPromoProps {
    target: Element | null;
    targetListeners: (Element | null)[];
    storeValue: number | null;
    onHide: () => void;
    isMobile?: boolean;
}

// TODO: Этот компонент нужно переписать на <FloatingTooltip/> и разместить в самом фильтре по годам
export const QuotaCleanerYearFilterPromo: FC<QuotaCleanerYearFilterPromoProps> = ({
    target,
    targetListeners,
    storeValue,
    onHide,
    isMobile,
}) => {
    const [isClosed, setIsClosed] = useState(!!storeValue);
    const [isShown, setIsShown] = useState(false);
    const text = isMobile
        ? 'Выберите нужный период, чтобы посмотреть\nдругие письма'
        : 'Выберите нужный период, чтобы\nпосмотреть другие письма';

    const canShow = useMemo(() => target && !isClosed, [target, isClosed]);

    const handleHide = useCallback(() => {
        setIsClosed(true);
        onHide();
    }, [onHide, setIsClosed]);

    const onClose = useCallback(() => {
        handleHide();
        xray.send('quota-cln-year-flt-pr-close');
    }, [handleHide]);

    const onTargetClick = useCallback(() => {
        handleHide();
        xray.send('quota-cln-year-flt-pr-submit');
    }, [handleHide]);

    useEffect(() => {
        const targets = [target, ...targetListeners];
        targets.forEach((target) => target?.addEventListener('click', onTargetClick));
        return () => {
            targets.forEach((target) => target?.removeEventListener('click', onTargetClick));
        };
    }, [target, targetListeners]);

    useEffect(() => {
        if (canShow) {
            setIsShown(true);
        }
    }, [canShow, setIsShown]);

    useEffect(() => {
        if (isShown) {
            xray.send('quota-cln-year-flt-pr-show');
        }
    }, [isShown]);

    if (!canShow || !target) {
        return null;
    }

    return (
        <FloatingTooltip
            target={target}
            title="Фильтр по дате"
            text={text}
            placement={ETooltipPlacement.bottomStart}
            closeOnOutsideClick={false}
            qaId="quota-cleaner-year-filter-promo"
            onClose={onClose}
            size={isMobile ? ETooltipSize.medium : undefined}
        />
    );
};
