/* eslint-disable max-lines-per-function */
import classNames from 'clsx';
import React, { memo, ReactElement } from 'react';
import { EHeaderColor } from 'reactApp/ui/Header/HeaderWrapper';

import styles from './ToolbarItem.css';

interface Props {
    color?: EHeaderColor;
}

export const DelimiterItem = memo(({ color }: Props): ReactElement => {
    return (
        <div
            className={classNames(styles.root, styles.delimiter, { [styles.delimiter_dark]: color === EHeaderColor.DARK })}
            data-name="delimiter"
        />
    );
});

DelimiterItem.displayName = 'DelimiterItem';
