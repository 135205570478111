import classNames from 'clsx';
import React from 'react';
import { Button, ButtonVariant } from 'reactApp/ui/Button/Button';

import styles from './CloseSharing.css';

interface Props {
    showSharing?: boolean;
    isOwner?: boolean;
    onClick(): void;
}

export const CloseSharing: React.FC<Props> = ({ showSharing, onClick, isOwner }) => {
    return (
        <Button
            className={classNames(styles.root, { [styles.root_not_owner]: !isOwner })}
            variant={ButtonVariant.contrast}
            onClick={onClick}
        >
            {showSharing && isOwner ? 'Закрыть доступ для всех' : 'Закрыть доступ'}
        </Button>
    );
};
