export interface IProps {
    value?: string;
    qaValue?: string;
    disabled?: boolean;
    onClick?: (MouseEvent) => void;
    theme?: string;
    color?: string;
    isActive: boolean;
    inInput?: boolean;
    isPhone?: boolean;
    isUserDropdown?: boolean;
}

export enum EDropdownColors {
    BLUE = 'blue',
    GRAY = 'gray',
    RED = 'red',
}
