import { IS_MOBILE_BROWSER, IS_PUBLIC_ALBUM } from 'reactApp/appHelpers/configHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { checkUpdateLicenseCompleted } from 'reactApp/modules/profile/profile.module';
import { getIsCheckUpdateLicenseCompleted } from 'reactApp/modules/profile/profile.selectors';
import { isOwnPublic, isPublicUploadEnabled } from 'reactApp/modules/public/public.selectors';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { isEmailAbsent } from 'reactApp/modules/socialUser/socialUser.selectors';
import { getStorageConfig } from 'reactApp/modules/storage/storage.config';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { put, select, take } from 'redux-saga/effects';

export function* checkCanUpload() {
    const isNewbie = yield select(UserSelectors.isNewbie);
    const isAnonymous = yield select(UserSelectors.isAnonymous);
    const isSocial = yield select(UserSelectors.isSocialUser);
    const isBizUser = yield select(UserSelectors.isBizUser);
    const storage = yield select(getCurrentStorage);
    const isOwnFolder = yield select(isOwnPublic);
    const isEmailAbsentVal = yield select(isEmailAbsent);
    const isPublicUploadAllowed = yield select(isPublicUploadEnabled);
    const isViewerActive = yield select(ViewerSelectors.isViewerActive);
    let isCheckUpdateLicenseCompleted = yield select(getIsCheckUpdateLicenseCompleted);
    const isPhone = yield select(EnvironmentSelectors.isPhone);

    const { isStart, isDocuments, isPublic, isHome, isGallery, isFeed, isFavorites, isAlbums, isIntegration, isInlineIntegration } =
        getStorage(storage);
    const isStorageSupportsUpload =
        isStart ||
        isDocuments ||
        isHome ||
        isIntegration ||
        isGallery ||
        isFeed ||
        isFavorites ||
        isAlbums ||
        isInlineIntegration ||
        getStorageConfig(storage).add;
    const isOwn = isPublic ? isOwnFolder : false;
    const canUploadToPublic = isPublic ? isPublicUploadAllowed || isOwn : false;
    const isInIntegrationUpload = isIntegration || isInlineIntegration;

    let result = true;

    if ((isSocial && isEmailAbsentVal && !isInIntegrationUpload && !isPublic) || isViewerActive || IS_PUBLIC_ALBUM) {
        result = false;
    }

    if (!isPublic && !isNewbie && !isPhone && !isBizUser && !isCheckUpdateLicenseCompleted && !isInIntegrationUpload) {
        yield take(checkUpdateLicenseCompleted);

        isCheckUpdateLicenseCompleted = true;
    }

    result =
        result &&
        (isPublic || (!isNewbie && (isCheckUpdateLicenseCompleted || IS_MOBILE_BROWSER)) || isBizUser || isInIntegrationUpload) &&
        !isAnonymous &&
        isStorageSupportsUpload &&
        (isPublic ? canUploadToPublic : true);

    if (!result && isPublic) {
        let text = 'Вы можете только просматривать содержимое папки, загружать в нее нельзя';
        if (isAnonymous && isPublicUploadAllowed) {
            text = 'Только зарегистрированные пользователи могут загружать файлы в публичные папки.';
        }

        yield put(showSnackbarAction({ closable: true, id: 'uploaderror', type: SnackbarTypes.failure, text }));
    }

    return result;
}
