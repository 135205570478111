import { useSelector } from 'react-redux';
import { UserAndFamilyQuotaSelectors } from 'reactApp/modules/userAndFamilyQuota/userAndFamilyQuota.selectors';
import { TSpaceInfo, TUseSpaceHook } from 'reactApp/ui/Space/SingleSpace/SingleSpaceProgress/SingleSpaceProgress.types';

export const useUserAndFamilySpace: TUseSpaceHook = () => {
    const { family: familyUsed, user: userUsed } = useSelector(UserAndFamilyQuotaSelectors.getUsed);
    const isAlmostFull = useSelector(UserAndFamilyQuotaSelectors.isAlmostFull);
    const isFull = useSelector(UserAndFamilyQuotaSelectors.isFull);
    const remaining = useSelector(UserAndFamilyQuotaSelectors.getRemaining);
    const { used: usedPercents, user: userPercents } = useSelector(UserAndFamilyQuotaSelectors.getFilledPercents);

    const isOverquota = isAlmostFull || isFull;
    const isUserOverquota = isOverquota && (userUsed?.original || 0) >= (familyUsed?.original || 0);

    const spaceList: TSpaceInfo[] = [
        {
            id: 'files',
            text: `Мои файлы ${userUsed?.value}`,
            hintText: 'Письма и файлы из Облака',
            percents: userPercents,
            isOverquota: isUserOverquota,
        },
        {
            id: 'family',
            text: `Общее ${familyUsed?.value}`,
            hintText: 'Участники подписки',
            percents: usedPercents,
            isOverquota: isOverquota && !isUserOverquota,
        },
    ];

    return {
        spaceList,
        isOverquota,
        freeSpaceText: `Свободно ${remaining?.value}`,
        usedPercents,
    };
};
