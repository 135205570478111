import throttle from 'lodash.throttle';
import React, { useEffect, useState } from 'react';

import type { GesturesZoomProps } from './Gestures.types';
import { calculateTouchesDistance } from './Gestures.utils';

const EVENT_DELAY = 10;

export function GesturesZoom({ children, zoom, zoomMax = 100, zoomMin = 0, onChangeZoom }: GesturesZoomProps) {
    const [canUpdateZoom, setCanUpdateZoom] = useState<boolean>(true);
    const [currentDistance, setCurrentDistance] = useState<number>(0);
    const [initialDistance, setInitialDistance] = useState<number>(0);
    const [initialZoom, setInitialZoom] = useState<number>(0);

    useEffect(() => {
        const handleTouchesStart = (e: TouchEvent) => {
            setInitialDistance(calculateTouchesDistance(e.touches));
            setCanUpdateZoom(false);
        };
        const handleTouchMove = throttle(
            (e: TouchEvent) => {
                if (e.touches.length !== 2) {
                    return;
                }
                setCurrentDistance(calculateTouchesDistance(e.touches));
            },
            EVENT_DELAY,
            { leading: true, trailing: true }
        );
        const handleTouchesEnd = () => {
            setInitialDistance(0);
            setCurrentDistance(0);
            setCanUpdateZoom(true);
        };

        window.addEventListener?.('touchstart', handleTouchesStart);
        window.addEventListener?.('touchmove', handleTouchMove);
        window.addEventListener?.('touchend', handleTouchesEnd);
        window.addEventListener?.('touchcancel', handleTouchesEnd);
        return () => {
            window.removeEventListener?.('touchstart', handleTouchesStart);
            window.removeEventListener?.('touchmove', handleTouchMove);
            window.removeEventListener?.('touchend', handleTouchesEnd);
            window.removeEventListener?.('touchcancel', handleTouchesEnd);
        };
    }, []);

    useEffect(() => {
        if (canUpdateZoom) {
            setInitialZoom(zoom || 0);
        }
    }, [canUpdateZoom, zoom]);

    useEffect(() => {
        if (initialDistance === 0 || currentDistance === 0 || currentDistance === initialDistance) {
            return;
        }
        if (typeof onChangeZoom === 'function') {
            const zoomCurrent = Math.round(initialZoom * (currentDistance / initialDistance));
            onChangeZoom(Math.min(zoomMax, Math.max(zoomMin, zoomCurrent)));
        }
    }, [currentDistance, initialDistance, initialZoom, zoomMax, zoomMin, onChangeZoom]);

    return <>{children}</>;
}
