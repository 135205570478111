import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import {
    accessControlListLoadStart,
    accessControlListReset,
    deleteAllUsers,
    setAccessControlListError,
    setInvitesSuccess,
} from './folderAccessControlList.actions';
import { convertInvitedToACL, getOwner } from './folderAccessControlList.helpers';
import {
    IAccessControlListError,
    IAccessControlListLoadStart,
    IFolderAccessControlListState,
    ISetInvitesSuccess,
} from './folderAccessControlList.types';

const initialState: IFolderAccessControlListState = {
    isLoading: true,
    owner: undefined,
    acList: [],
    error: '',
};

export const folderAccessControlListReduser = createReducer(initialState, {
    [accessControlListLoadStart.type]: (state, _action: PayloadAction<IAccessControlListLoadStart>) => {
        state.isLoading = true;
    },
    [setAccessControlListError.type]: (state, action: PayloadAction<IAccessControlListError>) => {
        const { error } = action.payload;
        state.error = error;
        state.isLoading = false;
    },
    [accessControlListReset.type]: () => {
        return { ...initialState };
    },
    [setInvitesSuccess.type]: (state, action: PayloadAction<ISetInvitesSuccess>) => {
        const { owner, invited } = action.payload;

        if (owner) {
            state.owner = getOwner(owner);
        }

        state.acList = convertInvitedToACL(invited);
        state.isLoading = false;
        state.error = '';
    },
    [deleteAllUsers.type]: (state) => {
        state.isLoading = true;
    },
});
