import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { ZoomState } from 'reactApp/ui/ReactViewer/ReactViewer.types';

export const USED_THUMB_SIZE = '1200x1200'; // TODO: use original?
export const TIMEOUT_TO_INACTIVE = 2000;
export const NUMBER_TO_PRELOAD = 10;
export const MAX_ZOOM_STATE = 400;
export const AVAILABLE_ZOOM_STATES: ZoomState[] = [10, 25, 50, 100, 200, MAX_ZOOM_STATE];
export const PADDING_FOR_TOOLBARS = 86;
export const hotKeysScope = 'reactviewer';
export const hotKeysArchiveScope = 'reactviewerarchive';
export const CONTENT_LOAD_TIMEOUT = getFeature('documents-content-load-timeout') || 30000;
export const supportedImages = {
    png: true,
    gif: true,
    webp: undefined,
    apng: undefined,
    jpx: undefined,
    jpm: undefined,
    jxr: undefined,
    jp2: undefined,
};
export const FILEVIEW_EMBEDDED_ORIGIN = getFeature('fileview-embedded-origin') || undefined;
export const CONVERT_NONE_EDITABLE_FORMATS_SNACK_ID = 'convertNoneEditableFormatsSnack';
