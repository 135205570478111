define(function (require, exports, module) {
	'use strict';

	module.exports = function () {

		return {
			"billing/rates": {
				"free": {
					"status": 200,
					"body": {
						"rates": [
							{
								"icon": "cloud",
								"active": true,
								"id": "ZERO",
								"available": true,
								"size": 107374182400
							},
							{
								"cost": [
									{
										"cost": 499,
										"notice": "",
										"currency": "RUR",
										"id": "hole1y",
										"duration": "P12M",
										"special_cost": 499,
										"special_duration": "P12M0D"
									}
								],
								"icon": "aquarium",
								"name": "PRO",
								"active": false,
								"size": 0,
								"notice": "",
								"id": "hole",
								"available": false
							},
							{
								"cost": [
									{
										"cost": 69,
										"notice": "",
										"currency": "RUR",
										"id": "64G1m",
										"duration": "P1M",
										"special_cost": 69,
										"special_duration": "P1M0D"
									},
									{
										"cost": 690,
										"notice": "",
										"currency": "RUR",
										"id": "64G1y",
										"duration": "P12M",
										"special_cost": 690,
										"special_duration": "P12M0D"
									}
								],
								"icon": "greensd",
								"name": "64 Гб",
								"active": false,
								"size": 68719476736,
								"notice": "",
								"id": "64G",
								"available": true
							},
							{
								"icon": "cloudpro",
								"active": false,
								"size": 0,
								"id": "pro",
								"available": true,
								"notice": "",
								"name": "Turbo",
								"cost": [
									{
										"special_duration": "P1M0D",
										"duration": "P1M",
										"id": "pro1m",
										"currency": "RUR",
										"special_cost": 379,
										"notice": "",
										"cost": 379
									}, {
										"special_duration": "P12M0D",
										"duration": "P12M",
										"id": "pro1y",
										"currency": "RUR",
										"special_cost": 3790,
										"notice": "",
										"cost": 3790
									}
								]
							},
							{
								"cost": [
									{
										"cost": 99,
										"notice": "",
										"currency": "RUR",
										"id": "100G1m",
										"duration": "P1M",
										"special_cost": 99,
										"special_duration": "P1M0D"
									},
									{
										"cost": 990,
										"notice": "",
										"currency": "RUR",
										"id": "100G1y",
										"duration": "P12M",
										"special_cost": 990,
										"special_duration": "P12M0D"
									}
								],
								"icon": "aquarium",
								"name": "100 Гб",
								"active": false,
								"size": 107374182400,
								"notice": "",
								"id": "100G",
								"available": false
							},
							{
								"cost": [
									{
										"cost": 149,
										"notice": "",
										"currency": "RUR",
										"id": "128G1m",
										"duration": "P1M",
										"special_cost": 149,
										"special_duration": "P1M0D"
									},
									{
										"cost": 1490,
										"notice": "",
										"currency": "RUR",
										"id": "128G1y",
										"duration": "P12M",
										"special_cost": 1490,
										"special_duration": "P12M0D"
									}
								],
								"icon": "purplesd",
								"name": "128 Гб",
								"active": false,
								"size": 137438953472,
								"notice": "",
								"id": "128G",
								"available": true
							},
							{
								"cost": [
									{
										"cost": 229,
										"notice": "",
										"currency": "RUR",
										"id": "256G1m",
										"duration": "P1M",
										"special_cost": 229,
										"special_duration": "P1M0D"
									},
									{
										"cost": 2290,
										"notice": "",
										"currency": "RUR",
										"id": "256G1y",
										"duration": "P12M",
										"special_cost": 2290,
										"special_duration": "P12M0D"
									}
								],
								"icon": "bluesd",
								"name": "256 Гб",
								"active": false,
								"size": 274877906944,
								"notice": "",
								"id": "256G",
								"available": true
							},
							{
								"cost": [
									{
										"cost": 379,
										"notice": "",
										"currency": "RUR",
										"id": "512G1m",
										"duration": "P1M",
										"special_cost": 379,
										"special_duration": "P1M0D"
									},
									{
										"cost": 3790,
										"notice": "",
										"currency": "RUR",
										"id": "512G1y",
										"duration": "P12M",
										"special_cost": 3790,
										"special_duration": "P12M0D"
									}
								],
								"icon": "bluehdd",
								"name": "512 Гб",
								"active": false,
								"size": 549755813888,
								"notice": "",
								"id": "512G",
								"available": true
							},
							{
								"cost": [
									{
										"cost": 699,
										"notice": "",
										"currency": "RUR",
										"id": "1T.v2_1m",
										"duration": "P1M",
										"special_cost": 699,
										"special_duration": "P1M0D"
									},
									{
										"cost": 6990,
										"notice": "",
										"currency": "RUR",
										"id": "1T.v2_1y",
										"duration": "P12M",
										"special_cost": 6990,
										"special_duration": "P12M0D"
									}
								],
								"icon": "yellowhdd",
								"name": "1 Тб",
								"active": false,
								"size": 1099511627776,
								"notice": "",
								"id": "1T.v2",
								"available": true
							},
							{
								"cost": [
									{
										"cost": 499,
										"notice": "",
										"currency": "RUR",
										"id": "1T1m",
										"duration": "P1M",
										"special_cost": 499,
										"special_duration": "P1M0D"
									},
									{
										"cost": 4990,
										"notice": "",
										"currency": "RUR",
										"id": "1T1y",
										"duration": "P12M",
										"special_cost": 4990,
										"special_duration": "P12M0D"
									}
								],
								"icon": "cat",
								"name": "1 Тб",
								"active": false,
								"size": 1099511627776,
								"notice": "",
								"id": "1T",
								"available": false
							},
							{
								"cost": [
									{
										"cost": 1390,
										"notice": "",
										"currency": "RUR",
										"id": "2T.v2_1m",
										"duration": "P1M",
										"special_cost": 1390,
										"special_duration": "P1M0D"
									},
									{
										"cost": 13900,
										"notice": "",
										"currency": "RUR",
										"id": "2T.v2_1y",
										"duration": "P12M",
										"special_cost": 13900,
										"special_duration": "P12M0D"
									}
								],
								"icon": "greenhdd",
								"name": "2 Тб",
								"active": false,
								"size": 2199023255552,
								"notice": "",
								"id": "2T.v2",
								"available": true
							},
							{
								"cost": [
									{
										"cost": 990,
										"notice": "",
										"currency": "RUR",
										"id": "2T1m",
										"duration": "P1M",
										"special_cost": 990,
										"special_duration": "P1M0D"
									},
									{
										"cost": 9990,
										"notice": "",
										"currency": "RUR",
										"id": "2T1y",
										"duration": "P12M",
										"special_cost": 9990,
										"special_duration": "P12M0D"
									}
								],
								"icon": "elephant",
								"name": "2 Тб",
								"active": false,
								"size": 2199023255552,
								"notice": "",
								"id": "2T",
								"available": false
							},
							{
								"cost": [
									{
										"cost": 2790,
										"notice": "",
										"currency": "RUR",
										"id": "4T.v2_1m",
										"duration": "P1M",
										"special_cost": 2790,
										"special_duration": "P1M0D"
									},
									{
										"cost": 27900,
										"notice": "",
										"currency": "RUR",
										"id": "4T.v2_1y",
										"duration": "P12M",
										"special_cost": 27900,
										"special_duration": "P12M0D"
									}
								],
								"icon": "purplehdd",
								"name": "4 Тб",
								"active": false,
								"size": 4398046511104,
								"notice": "",
								"id": "4T.v2",
								"available": true
							},
							{
								"cost": [
									{
										"cost": 1990,
										"notice": "",
										"currency": "RUR",
										"id": "4T1m",
										"duration": "P1M",
										"special_cost": 1990,
										"special_duration": "P1M0D"
									},
									{
										"cost": 19990,
										"notice": "",
										"currency": "RUR",
										"id": "4T1y",
										"duration": "P12M",
										"special_cost": 19990,
										"special_duration": "P12M0D"
									}
								],
								"icon": "whale",
								"name": "4 Тб",
								"active": false,
								"size": 4398046511104,
								"notice": "",
								"id": "4T",
								"available": false
							}
						]
					}
				},
				"bluesd": {
					"status": 200,
					"body": {
						"rates": [
							{
								"icon": "cloud",
								"active": false,
								"id": "ZERO",
								"available": false,
								"size": 107374182400
							},
							{
								"cost": [
									{
										"cost": 499,
										"notice": "",
										"currency": "RUR",
										"id": "hole1y",
										"duration": "P12M",
										"special_cost": 499,
										"special_duration": "P12M0D"
									}
								],
								"icon": "aquarium",
								"name": "PRO",
								"active": false,
								"size": 0,
								"notice": "",
								"id": "hole",
								"available": false
							},
							{
								"cost": [
									{
										"cost": 69,
										"notice": "",
										"currency": "RUR",
										"id": "64G1m",
										"duration": "P1M",
										"special_cost": 69,
										"special_duration": "P1M0D"
									},
									{
										"cost": 690,
										"notice": "",
										"currency": "RUR",
										"id": "64G1y",
										"duration": "P12M",
										"special_cost": 690,
										"special_duration": "P12M0D"
									}
								],
								"icon": "greensd",
								"name": "64 Гб",
								"active": false,
								"size": 68719476736,
								"notice": "",
								"id": "64G",
								"available": true
							},
							{
								"icon": "cloudpro",
								"active": false,
								"size": 0,
								"id": "pro",
								"available": true,
								"notice": "",
								"name": "Turbo",
								"cost": [
									{
										"special_duration": "P1M0D",
										"duration": "P1M",
										"id": "pro1m",
										"currency": "RUR",
										"special_cost": 379,
										"notice": "",
										"cost": 379
									}, {
										"special_duration": "P12M0D",
										"duration": "P12M",
										"id": "pro1y",
										"currency": "RUR",
										"special_cost": 3790,
										"notice": "",
										"cost": 3790
									}
								]
							},
							{
								"cost": [
									{
										"cost": 99,
										"notice": "",
										"currency": "RUR",
										"id": "100G1m",
										"duration": "P1M",
										"special_cost": 99,
										"special_duration": "P1M0D"
									},
									{
										"cost": 990,
										"notice": "",
										"currency": "RUR",
										"id": "100G1y",
										"duration": "P12M",
										"special_cost": 990,
										"special_duration": "P12M0D"
									}
								],
								"icon": "aquarium",
								"name": "100 Гб",
								"active": false,
								"size": 107374182400,
								"notice": "",
								"id": "100G",
								"available": false
							},
							{
								"cost": [
									{
										"cost": 149,
										"notice": "",
										"currency": "RUR",
										"id": "128G1m",
										"duration": "P1M",
										"special_cost": 149,
										"special_duration": "P1M0D"
									},
									{
										"cost": 1490,
										"notice": "",
										"currency": "RUR",
										"id": "128G1y",
										"duration": "P12M",
										"special_cost": 1490,
										"special_duration": "P12M0D"
									}
								],
								"icon": "purplesd",
								"name": "128 Гб",
								"active": false,
								"size": 137438953472,
								"notice": "",
								"id": "128G",
								"available": true
							},
							{
								"cost": [
									{
										"cost": 229,
										"notice": "",
										"currency": "RUR",
										"id": "256G1m",
										"duration": "P1M",
										"special_cost": 229,
										"special_duration": "P1M0D"
									},
									{
										"cost": 2290,
										"notice": "",
										"currency": "RUR",
										"id": "256G1y",
										"duration": "P12M",
										"special_cost": 2290,
										"special_duration": "P12M0D"
									}
								],
								"icon": "bluesd",
								"name": "256 Гб",
								"active": true,
								"size": 274877906944,
								"notice": "",
								"id": "256G",
								"available": true
							},
							{
								"cost": [
									{
										"cost": 379,
										"notice": "",
										"currency": "RUR",
										"id": "512G1m",
										"duration": "P1M",
										"special_cost": 379,
										"special_duration": "P1M0D"
									},
									{
										"cost": 3790,
										"notice": "",
										"currency": "RUR",
										"id": "512G1y",
										"duration": "P12M",
										"special_cost": 3790,
										"special_duration": "P12M0D"
									}
								],
								"icon": "bluehdd",
								"name": "512 Гб",
								"active": false,
								"size": 549755813888,
								"notice": "",
								"id": "512G",
								"available": true
							},
							{
								"cost": [
									{
										"cost": 699,
										"notice": "",
										"currency": "RUR",
										"id": "1T.v2_1m",
										"duration": "P1M",
										"special_cost": 699,
										"special_duration": "P1M0D"
									},
									{
										"cost": 6990,
										"notice": "",
										"currency": "RUR",
										"id": "1T.v2_1y",
										"duration": "P12M",
										"special_cost": 6990,
										"special_duration": "P12M0D"
									}
								],
								"icon": "yellowhdd",
								"name": "1 Тб",
								"active": false,
								"size": 1099511627776,
								"notice": "",
								"id": "1T.v2",
								"available": true
							},
							{
								"cost": [
									{
										"cost": 499,
										"notice": "",
										"currency": "RUR",
										"id": "1T1m",
										"duration": "P1M",
										"special_cost": 499,
										"special_duration": "P1M0D"
									},
									{
										"cost": 4990,
										"notice": "",
										"currency": "RUR",
										"id": "1T1y",
										"duration": "P12M",
										"special_cost": 4990,
										"special_duration": "P12M0D"
									}
								],
								"icon": "cat",
								"name": "1 Тб",
								"active": false,
								"size": 1099511627776,
								"notice": "",
								"id": "1T",
								"available": false
							},
							{
								"cost": [
									{
										"cost": 1390,
										"notice": "",
										"currency": "RUR",
										"id": "2T.v2_1m",
										"duration": "P1M",
										"special_cost": 1390,
										"special_duration": "P1M0D"
									},
									{
										"cost": 13900,
										"notice": "",
										"currency": "RUR",
										"id": "2T.v2_1y",
										"duration": "P12M",
										"special_cost": 13900,
										"special_duration": "P12M0D"
									}
								],
								"icon": "greenhdd",
								"name": "2 Тб",
								"active": false,
								"size": 2199023255552,
								"notice": "",
								"id": "2T.v2",
								"available": true
							},
							{
								"cost": [
									{
										"cost": 990,
										"notice": "",
										"currency": "RUR",
										"id": "2T1m",
										"duration": "P1M",
										"special_cost": 990,
										"special_duration": "P1M0D"
									},
									{
										"cost": 9990,
										"notice": "",
										"currency": "RUR",
										"id": "2T1y",
										"duration": "P12M",
										"special_cost": 9990,
										"special_duration": "P12M0D"
									}
								],
								"icon": "elephant",
								"name": "2 Тб",
								"active": false,
								"size": 2199023255552,
								"notice": "",
								"id": "2T",
								"available": false
							},
							{
								"cost": [
									{
										"cost": 2790,
										"notice": "",
										"currency": "RUR",
										"id": "4T.v2_1m",
										"duration": "P1M",
										"special_cost": 2790,
										"special_duration": "P1M0D"
									},
									{
										"cost": 27900,
										"notice": "",
										"currency": "RUR",
										"id": "4T.v2_1y",
										"duration": "P12M",
										"special_cost": 27900,
										"special_duration": "P12M0D"
									}
								],
								"icon": "purplehdd",
								"name": "4 Тб",
								"active": false,
								"size": 4398046511104,
								"notice": "",
								"id": "4T.v2",
								"available": true
							},
							{
								"cost": [
									{
										"cost": 1990,
										"notice": "",
										"currency": "RUR",
										"id": "4T1m",
										"duration": "P1M",
										"special_cost": 1990,
										"special_duration": "P1M0D"
									},
									{
										"cost": 19990,
										"notice": "",
										"currency": "RUR",
										"id": "4T1y",
										"duration": "P12M",
										"special_cost": 19990,
										"special_duration": "P12M0D"
									}
								],
								"icon": "whale",
								"name": "4 Тб",
								"active": false,
								"size": 4398046511104,
								"notice": "",
								"id": "4T",
								"available": false
							}
						]
					}
				},
				"turbo": {
					"status": 200,
					"body": {
						"rates": [
							{
								"icon": "cloud",
								"active": false,
								"id": "ZERO",
								"available": false,
								"size": 107374182400
							},
							{
								"cost": [
									{
										"cost": 499,
										"notice": "",
										"currency": "RUR",
										"id": "hole1y",
										"duration": "P12M",
										"special_cost": 499,
										"special_duration": "P12M0D"
									}
								],
								"icon": "aquarium",
								"name": "PRO",
								"active": false,
								"size": 0,
								"notice": "",
								"id": "hole",
								"available": false
							},
							{
								"cost": [
									{
										"cost": 69,
										"notice": "",
										"currency": "RUR",
										"id": "64G1m",
										"duration": "P1M",
										"special_cost": 69,
										"special_duration": "P1M0D"
									},
									{
										"cost": 690,
										"notice": "",
										"currency": "RUR",
										"id": "64G1y",
										"duration": "P12M",
										"special_cost": 690,
										"special_duration": "P12M0D"
									}
								],
								"icon": "greensd",
								"name": "64 Гб",
								"active": false,
								"size": 68719476736,
								"notice": "",
								"id": "64G",
								"available": true
							},
							{
								"icon": "cloudpro",
								"active": true,
								"size": 0,
								"id": "pro",
								"available": true,
								"notice": "",
								"name": "Turbo",
								"cost": [
									{
										"special_duration": "P1M0D",
										"duration": "P1M",
										"id": "pro1m",
										"currency": "RUR",
										"special_cost": 379,
										"notice": "",
										"cost": 379
									}, {
										"special_duration": "P12M0D",
										"duration": "P12M",
										"id": "pro1y",
										"currency": "RUR",
										"special_cost": 3790,
										"notice": "",
										"cost": 3790
									}
								]
							},
							{
								"cost": [
									{
										"cost": 99,
										"notice": "",
										"currency": "RUR",
										"id": "100G1m",
										"duration": "P1M",
										"special_cost": 99,
										"special_duration": "P1M0D"
									},
									{
										"cost": 990,
										"notice": "",
										"currency": "RUR",
										"id": "100G1y",
										"duration": "P12M",
										"special_cost": 990,
										"special_duration": "P12M0D"
									}
								],
								"icon": "aquarium",
								"name": "100 Гб",
								"active": false,
								"size": 107374182400,
								"notice": "",
								"id": "100G",
								"available": false
							},
							{
								"cost": [
									{
										"cost": 149,
										"notice": "",
										"currency": "RUR",
										"id": "128G1m",
										"duration": "P1M",
										"special_cost": 149,
										"special_duration": "P1M0D"
									},
									{
										"cost": 1490,
										"notice": "",
										"currency": "RUR",
										"id": "128G1y",
										"duration": "P12M",
										"special_cost": 1490,
										"special_duration": "P12M0D"
									}
								],
								"icon": "purplesd",
								"name": "128 Гб",
								"active": false,
								"size": 137438953472,
								"notice": "",
								"id": "128G",
								"available": true
							},
							{
								"cost": [
									{
										"cost": 229,
										"notice": "",
										"currency": "RUR",
										"id": "256G1m",
										"duration": "P1M",
										"special_cost": 229,
										"special_duration": "P1M0D"
									},
									{
										"cost": 2290,
										"notice": "",
										"currency": "RUR",
										"id": "256G1y",
										"duration": "P12M",
										"special_cost": 2290,
										"special_duration": "P12M0D"
									}
								],
								"icon": "bluesd",
								"name": "256 Гб",
								"active": false,
								"size": 274877906944,
								"notice": "",
								"id": "256G",
								"available": true
							},
							{
								"cost": [
									{
										"cost": 379,
										"notice": "",
										"currency": "RUR",
										"id": "512G1m",
										"duration": "P1M",
										"special_cost": 379,
										"special_duration": "P1M0D"
									},
									{
										"cost": 3790,
										"notice": "",
										"currency": "RUR",
										"id": "512G1y",
										"duration": "P12M",
										"special_cost": 3790,
										"special_duration": "P12M0D"
									}
								],
								"icon": "bluehdd",
								"name": "512 Гб",
								"active": false,
								"size": 549755813888,
								"notice": "",
								"id": "512G",
								"available": true
							},
							{
								"cost": [
									{
										"cost": 699,
										"notice": "",
										"currency": "RUR",
										"id": "1T.v2_1m",
										"duration": "P1M",
										"special_cost": 699,
										"special_duration": "P1M0D"
									},
									{
										"cost": 6990,
										"notice": "",
										"currency": "RUR",
										"id": "1T.v2_1y",
										"duration": "P12M",
										"special_cost": 6990,
										"special_duration": "P12M0D"
									}
								],
								"icon": "yellowhdd",
								"name": "1 Тб",
								"active": false,
								"size": 1099511627776,
								"notice": "",
								"id": "1T.v2",
								"available": true
							},
							{
								"cost": [
									{
										"cost": 499,
										"notice": "",
										"currency": "RUR",
										"id": "1T1m",
										"duration": "P1M",
										"special_cost": 499,
										"special_duration": "P1M0D"
									},
									{
										"cost": 4990,
										"notice": "",
										"currency": "RUR",
										"id": "1T1y",
										"duration": "P12M",
										"special_cost": 4990,
										"special_duration": "P12M0D"
									}
								],
								"icon": "cat",
								"name": "1 Тб",
								"active": false,
								"size": 1099511627776,
								"notice": "",
								"id": "1T",
								"available": false
							},
							{
								"cost": [
									{
										"cost": 1390,
										"notice": "",
										"currency": "RUR",
										"id": "2T.v2_1m",
										"duration": "P1M",
										"special_cost": 1390,
										"special_duration": "P1M0D"
									},
									{
										"cost": 13900,
										"notice": "",
										"currency": "RUR",
										"id": "2T.v2_1y",
										"duration": "P12M",
										"special_cost": 13900,
										"special_duration": "P12M0D"
									}
								],
								"icon": "greenhdd",
								"name": "2 Тб",
								"active": false,
								"size": 2199023255552,
								"notice": "",
								"id": "2T.v2",
								"available": true
							},
							{
								"cost": [
									{
										"cost": 990,
										"notice": "",
										"currency": "RUR",
										"id": "2T1m",
										"duration": "P1M",
										"special_cost": 990,
										"special_duration": "P1M0D"
									},
									{
										"cost": 9990,
										"notice": "",
										"currency": "RUR",
										"id": "2T1y",
										"duration": "P12M",
										"special_cost": 9990,
										"special_duration": "P12M0D"
									}
								],
								"icon": "elephant",
								"name": "2 Тб",
								"active": false,
								"size": 2199023255552,
								"notice": "",
								"id": "2T",
								"available": false
							},
							{
								"cost": [
									{
										"cost": 2790,
										"notice": "",
										"currency": "RUR",
										"id": "4T.v2_1m",
										"duration": "P1M",
										"special_cost": 2790,
										"special_duration": "P1M0D"
									},
									{
										"cost": 27900,
										"notice": "",
										"currency": "RUR",
										"id": "4T.v2_1y",
										"duration": "P12M",
										"special_cost": 27900,
										"special_duration": "P12M0D"
									}
								],
								"icon": "purplehdd",
								"name": "4 Тб",
								"active": false,
								"size": 4398046511104,
								"notice": "",
								"id": "4T.v2",
								"available": true
							},
							{
								"cost": [
									{
										"cost": 1990,
										"notice": "",
										"currency": "RUR",
										"id": "4T1m",
										"duration": "P1M",
										"special_cost": 1990,
										"special_duration": "P1M0D"
									},
									{
										"cost": 19990,
										"notice": "",
										"currency": "RUR",
										"id": "4T1y",
										"duration": "P12M",
										"special_cost": 19990,
										"special_duration": "P12M0D"
									}
								],
								"icon": "whale",
								"name": "4 Тб",
								"active": false,
								"size": 4398046511104,
								"notice": "",
								"id": "4T",
								"available": false
							}
						]
					}
				}
			}
		};
	};
});
