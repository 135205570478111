import { useSelector } from 'react-redux';
import { isQuotaCleanerMobLetterDownloadAvailable } from 'reactApp/appHelpers/featuresHelpers';
import { useActionsConfig } from 'reactApp/hooks/useActionsConfig';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EActions, EStorageType } from 'reactApp/modules/storage/storage.types';
import { actionItems } from 'reactApp/ui/Mobile/ActionsBar/ActionsBar.data';

export const useItems = () => {
    const storage = useSelector(getCurrentStorage);

    const actions = useActionsConfig({
        showDisabled: storage === EStorageType.home || (storage === EStorageType.quotaCleaner && isQuotaCleanerMobLetterDownloadAvailable),
        isMobile: true,
    });

    if (actions[EActions.publishAlbum]) {
        delete actions[EActions.publish];
    }

    return Object.entries(actions)
        .map(([id, enable]) => actionItems[id]?.(!enable))
        .filter((action) => action);
};
