import { Icon20Chain } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { memo, MouseEventHandler } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { DataListControl } from 'reactApp/ui/DataListControl/DataListControl';
import { Hint } from 'reactApp/ui/Hint/Hint';

import styles from './Publish.css';

interface PublishProps {
    isShared?: boolean;
    onClick?: MouseEventHandler<HTMLDivElement>;
    viewType: EViewMode;
    className?: string;
}

export const Publish = memo<PublishProps>(function Publish({ isShared = false, onClick, viewType, className }) {
    return (
        <Hint text={isShared ? 'Настроить доступ' : 'Поделиться ссылкой'}>
            <DataListControl.New
                id="publish-icon"
                type="publish"
                viewType={viewType}
                className={classNames(className, {
                    [styles.shared]: isShared,
                })}
                onClick={onClick}
            >
                <Icon20Chain />
            </DataListControl.New>
        </Hint>
    );
});
