import { createGaSender } from 'reactApp/utils/ga';

import { IShoveBanner } from '../../../../server/helpers/public/public.types';

const backgrounds = [
    'background_no-repeat_bottom_right',
    'background_no-repeat_bottom_left',
    'background_no-repeat_top_right',
    'background_no-repeat_top_left',
    'background_no-repeat_left_center',
    'background_no-repeat_right_center',
    'background_no-repeat_center_bottom',
    'background_no-repeat_center_top',
    'background_repeat-y_left_center',
    'background_repeat-y_right_center',
    'background_repeat-x_center_bottom',
    'background_repeat-x_center_top',
    'background_repeat_center_center',
];

export const getShoveBackground = (shove: IShoveBanner) => {
    const shoveBackgroundStyle: string[] = [];

    backgrounds.forEach((background: string) => {
        const url = shove[background];

        if (url) {
            const [, repeat, x, y] = background.split('_');
            shoveBackgroundStyle.push(`url(${url}) ${repeat} ${x} ${y}`);
        }
    }, []);

    if (shove.background_gradients) {
        shoveBackgroundStyle.push(shove.background_gradients);
    }

    return shoveBackgroundStyle.join(', ');
};

export const sendShoveGa = createGaSender('shove');
