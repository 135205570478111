import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import {
    ACTION_PROMO,
    APP_LOGO,
    APP_TITLE,
    APP_WHITE_LOGO,
    IS_B2B_BIZ_USER,
    IS_CHOSEN_PUBLIC_FILE,
    IS_REACT_PAGE,
    LOGO_SRC,
} from 'reactApp/appHelpers/configHelpers';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { useHref } from 'reactApp/ui/Header/Logo/Logo.hooks';
import { ELogoSize, LogoComponent } from 'reactApp/ui/Header/Logo/LogoComponent';

export const Logo = memo(({ white = false }: { white?: boolean }) => {
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    const size = IS_CHOSEN_PUBLIC_FILE ? ELogoSize.s : ELogoSize.m;
    const storage = useSelector(getCurrentStorage);
    const { isAllDocuments, isPublic, isSubscription } = getStorage(storage);
    const useRouterLink = IS_REACT_PAGE && !(isPhone && isPublic);
    const isBiz = IS_B2B_BIZ_USER;
    const bizLogo = LOGO_SRC || ((isSubscription && Boolean(ACTION_PROMO)) || white || isPhone ? APP_WHITE_LOGO : APP_LOGO);
    const href = useHref(storage, useRouterLink, isBiz);

    return (
        <LogoComponent
            isPhone={isPhone}
            white={(isSubscription && Boolean(ACTION_PROMO)) || white}
            bizLogo={bizLogo}
            bizBrandName={APP_TITLE}
            isBiz={isBiz}
            size={size}
            useRouterLink={useRouterLink}
            isDocuments={isAllDocuments}
            href={href}
        />
    );
});

Logo.displayName = 'Logo';
