import { Method } from 'axios';
import api from 'Cloud/Application/api';
import { APICallV4 } from 'reactApp/api/APICall';
import { apiMigrateWeblinksV2ToV4 } from 'reactApp/appHelpers/featuresHelpers';
import { promisifyDeferredCall } from 'reactApp/utils/helpers';

const method = 'weblinks/expires';

class ExpiresWeblinksAPIV4Call extends APICallV4 {
    _method = method;
    _type: Method = 'post';
}

export const expiresWeblinksApiCall = (params: Record<string, any>): Promise<unknown> => {
    if (apiMigrateWeblinksV2ToV4.expires) {
        return new ExpiresWeblinksAPIV4Call().makeRequest({
            weblink_id: params.weblink,
            timestamp: params.expires,
        });
    }

    return promisifyDeferredCall(api.weblinks.expires, params);
};
