import settings from 'Cloud/settings';
import { IS_AUTO_TEST_HIDE, IS_MY_TEAM, IS_PHONE_BROWSER, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { isViewContentOnly } from 'reactApp/appHelpers/settingsHelpers';
import { getFeatureAbEbookPromo } from 'reactApp/modules/features/features.selectors';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { store } from 'reactApp/store';
import { put, select } from 'redux-saga/effects';

export const PROMO_ID = 'epubTip';

export enum EpubPromoType {
    first = 'first',
    downloadList = 'downloadList',
    download = 'download',
    continueRead = 'continueRead',
}

const getKey = (type: EpubPromoType) => `${PROMO_ID}|${type}`;

export function* initEpubPromo() {
    const isEbookPromo = yield select(getFeatureAbEbookPromo);

    if (
        !isEbookPromo ||
        settings?.request?.action ||
        IS_PHONE_BROWSER ||
        isViewContentOnly ||
        IS_WEBVIEW ||
        IS_MY_TEAM ||
        IS_AUTO_TEST_HIDE
    ) {
        return;
    }

    const shownPromoFirst = storeHelper.getValue(getKey(EpubPromoType.first)) as EpubPromoType | undefined;
    const shownPromoDownload = storeHelper.getValue(getKey(EpubPromoType.download)) as EpubPromoType | undefined;
    const shownPromoDownloadList = storeHelper.getValue(getKey(EpubPromoType.downloadList)) as EpubPromoType | undefined;
    const shownPromoContinue = storeHelper.getValue(getKey(EpubPromoType.continueRead)) as EpubPromoType | undefined;

    const showTips: EpubPromoType[] = [];

    if (!shownPromoFirst) {
        showTips.push(EpubPromoType.first);
    }
    if (!shownPromoDownload) {
        showTips.push(EpubPromoType.download);
    }
    if (!shownPromoDownloadList) {
        showTips.push(EpubPromoType.downloadList);
    }
    if (!shownPromoContinue) {
        showTips.push(EpubPromoType.continueRead);
    }

    if (showTips.length <= 0) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.epubPromo,
            promoId: PROMO_ID,
            tipType: showTips,
            onShow: () => store.dispatch(promoShown(EPromoType.epubPromo)),
            onMarkShown: (type: EpubPromoType) => storeHelper.markAsShown(getKey(type)),
            onClose: () => store.dispatch(removePromo(EPromoType.epubPromo)),
        })
    );
}
