export const firebaseConfig = {
    apiKey: 'AIzaSyBlCbLDNL3uP_1oNrvkoGbX_21Bh2mrII0',
    authDomain: 'cloud-mail-ru.firebaseapp.com',
    databaseURL: 'https://cloud-mail-ru.firebaseio.com',
    projectId: 'cloud-mail-ru',
    storageBucket: 'cloud-mail-ru.appspot.com',
    messagingSenderId: '179932934914',
    appId: '1:179932934914:web:971835bb3a6ef5a146881b',
    measurementId: 'G-3354K9X74X',
};

export const vapidKey = 'BHm9Sh_BOyluAkh1fKq5s2HWUJA_0S8_NEHY5PITjCLnL6VYgSan-900cRPkjSaU5P46E17_SxUwal7rvnCNr3g';
