import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { getNameById } from 'reactApp/modules/file/utils';
import {
    addToFavoritesSuccess,
    moveItemsSuccess,
    publishWeblink,
    removeFileSuccess,
    removeFromFavoritesSuccess,
    toggleWeblinkAccessRights,
    toggleWeblinkDomestic,
    unPublishWeblink,
    updateWeblinkExpires,
} from 'reactApp/modules/modifying/modifying.actions';
import {
    AddFile,
    AddItem,
    IAddToFavoritesSuccess,
    IRemoveFromFavoritesSuccess,
    MoveItemsSuccessAction,
    RemoveFileSuccessAction,
    ToggleWeblinkAccessRights,
    ToggleWeblinkDomestic,
    UnpublishWeblinkAction,
    UpdateWeblinkExpires,
} from 'reactApp/modules/modifying/modifying.types';
import { DOCUMENT_ID, EDocumentType, MAP_DOCUMENT_ID } from 'reactApp/sections/PersonalDocuments/PersonalDocuments.constants';

import { failureState, initialState, loadingState, successState } from './personalDocuments.constants';
import { getDocumentId, normalizeItem } from './personalDocuments.helpers';
import {
    ApiDocumentResponse,
    DocumentsItem,
    ECategory,
    ERecognitionStatus,
    IApiPersonalDocumentsResponse,
    IDocumentSection,
    IStartUploadDocuments,
    TDocumentType,
    TRecognitionStatus,
} from './personalDocuments.types';

export const loadDocumentsSectionRequest = createAction('personalDocuments/loadRequest');
export const loadDocumentsSectionSuccess = createAction<IApiPersonalDocumentsResponse>('personalDocuments/loadSuccess');
export const loadDocumentsSectionFailure = createAction('personalDocuments/loadFail');
export const loadDocumentRequest = createAction<TDocumentType>('personalDocuments/loadDocumentRequest');
export const loadDocumentSuccess = createAction<ApiDocumentResponse>('personalDocuments/loadDocumentSuccess');
export const loadDocumentFailure = createAction<TDocumentType>('personalDocuments/loadDocumentFail');
export const loadDocumentRecognitionStatusSuccess = createAction<TRecognitionStatus>('personalDocuments/documentRecognitionStatus');
export const enableDocumentsRecognition = createAction('personalDocuments/enableDocumentsRecognition');
export const disableDocumentsRecognition = createAction('personalDocuments/disableDocumentsRecognition');
export const enableDocumentsRecognitionSuccess = createAction('personalDocuments/enableDocumentsRecognitionSuccess');
export const disableDocumentsRecognitionSuccess = createAction('personalDocuments/disableDocumentsRecognitionSuccess');
export const loadDocumentsMoreRequest = createAction('personalDocuments/load');
export const addFileToDocument = createAction<{ files: AddItem[] } & TDocumentType>('personalDocuments/addFileToDocument');
export const startUploadDocuments = createAction<IStartUploadDocuments>('personal/startUploadDocuments');

export const documentsReducer = createReducer(initialState, {
    [loadDocumentsSectionRequest.type]: (state) => {
        return {
            ...state,
            ...loadingState,
        };
    },
    [loadDocumentsSectionSuccess.type]: (state, action: PayloadAction<IApiPersonalDocumentsResponse>) => {
        const list = { ...state.list };

        action.payload?.list?.forEach((item) => {
            const { avatar, ...rest } = item;
            const id = getDocumentId(MAP_DOCUMENT_ID[item.id]);
            const file = avatar ? normalizeItem(avatar, id) : undefined;

            list[id] = {
                ...rest,
                id: String(rest.id),
                name: rest.title,
                avatar: file?.id,
                category: ECategory.DOCUMENT,
                isLoaded: true,
                isLoading: false,
                isFolder: true,
                childs: [],
                count: {
                    folders: 0,
                    all: rest.count,
                    files: rest.count,
                    loaded: rest.count,
                },
            };

            if (file) {
                list[file.id] = file;
            }
        });

        return {
            ...state,
            ...successState,
            currentDocument: undefined,
            list,
        };
    },
    [loadDocumentsSectionFailure.type]: (state) => {
        return {
            ...state,
            ...failureState,
        };
    },
    [loadDocumentRequest.type]: (state, action: PayloadAction<TDocumentType>) => {
        const { documentType } = action.payload;
        const id = getDocumentId(documentType);
        const item = state.list[id] || {};

        return {
            ...state,
            list: {
                ...state.list,
                [id]: {
                    ...item,
                    ...loadingState,
                },
            },
        };
    },
    [loadDocumentSuccess.type]: (state, action: PayloadAction<ApiDocumentResponse>) => {
        const { cursor, list: documents, documentType } = action.payload;
        const list = { ...state.list };
        const id = getDocumentId(documentType);
        const childs: string[] = [];
        const item = state.list[id] || {};

        documents.forEach((document) => {
            const item = normalizeItem(document, id);
            childs.push(item.id);

            list[item.id] = item;
        });

        list[id] = {
            ...item,
            ...successState,
            id: String(DOCUMENT_ID[documentType]),
            name: documentType,
            hasMoreToLoad: Boolean(cursor),
            category: ECategory.DOCUMENT,
            cursor,
            childs,
            isFolder: true,
            count: {
                all: childs.length,
                folders: 0,
                files: childs.length,
                loaded: childs.length,
            },
        };

        return {
            ...state,
            list,
        };
    },
    [loadDocumentFailure.type]: (state, action: PayloadAction<TDocumentType>) => {
        const { documentType } = action.payload;
        const id = getDocumentId(documentType);
        const item = state.list[id] || {};

        return {
            ...state,
            list: {
                ...state.list,
                [id]: {
                    ...item,
                    ...failureState,
                },
            },
        };
    },
    [loadDocumentRecognitionStatusSuccess.type]: (state, action: PayloadAction<TRecognitionStatus>) => {
        const recognitionStatus = action.payload.recognitionStatus;

        return {
            ...state,
            recognitionStatus,
        };
    },
    [enableDocumentsRecognitionSuccess.type]: (state) => {
        return {
            ...state,
            recognitionStatus: ERecognitionStatus.PROCESSING,
        };
    },
    [disableDocumentsRecognitionSuccess.type]: () => {
        return {
            ...initialState,
            ...successState,
        };
    },
    [toggleWeblinkDomestic.type]: (state, action: PayloadAction<ToggleWeblinkDomestic>): void => {
        const { id, domestic } = action.payload;
        const item = state.list[id];

        if (!item || item?.category === ECategory.DOCUMENT) {
            return;
        }

        item.weblinkDomestic = domestic;
    },
    [updateWeblinkExpires.type]: (state, action: PayloadAction<UpdateWeblinkExpires>): void => {
        const { id, expires } = action.payload;
        const item = state.list[id];

        if (!item || item?.category === ECategory.DOCUMENT) {
            return;
        }

        item.weblinkExpires = expires;
    },
    [publishWeblink.type]: (state, action: PayloadAction<{ weblink: string; id: string }>) => {
        const { id, weblink } = action.payload;
        const item = state.list[id];

        if (!item || item?.category === ECategory.DOCUMENT) {
            return;
        }

        item.weblink = weblink;
    },
    [toggleWeblinkAccessRights.type]: (state, action: PayloadAction<ToggleWeblinkAccessRights>): void => {
        const { id, type } = action.payload;
        const item = state.list[id];

        if (!item || item?.category === ECategory.DOCUMENT) {
            return;
        }

        item.weblinkAccessRights = type;
    },
    [unPublishWeblink.type]: (state, action: PayloadAction<UnpublishWeblinkAction>) => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.list[id];

            if (!item || item?.category === ECategory.DOCUMENT) {
                return;
            }

            item.weblink = undefined;
        });
    },
    [removeFileSuccess.type]: (state, action: PayloadAction<RemoveFileSuccessAction>) => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.list[id];

            if (item?.category === ECategory.DOCUMENT) {
                return;
            }

            const parent = state.list[item?.documentId || ''] as IDocumentSection;

            if (!item || !parent) {
                return;
            }

            parent.childs = parent.childs?.filter((child) => child !== id) || [];
            delete state.list[id];
        });
    },
    [moveItemsSuccess.type]: (state, action: PayloadAction<MoveItemsSuccessAction>) => {
        const { newItems, oldIds } = action.payload;

        newItems.forEach((itemNew, index) => {
            const file = itemNew as DocumentsItem;
            const id = file?.home;
            const documentId = file?.documentId || '';
            const parent = state.list[documentId] as IDocumentSection;
            const oldItemId = oldIds[index];

            if (!id || !parent) {
                return;
            }

            const oldIdx = parent.childs?.indexOf(oldItemId);

            if (oldIdx !== -1) {
                parent.childs[oldIdx] = id;
            } else {
                parent.childs = [id, ...parent.childs];
            }

            const item = { ...file, flags: { favorite: file.isInFavorites, mounted: file.isMounted }, path: id };
            state.list[id] = normalizeItem(item, documentId);
            delete state.list[oldItemId];
        });
    },
    [addToFavoritesSuccess.type]: (state, action: PayloadAction<IAddToFavoritesSuccess>): void => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.list[id];

            if (!item || item?.category === ECategory.DOCUMENT) {
                return;
            }

            item.isInFavorites = true;
        });
    },
    [removeFromFavoritesSuccess.type]: (state, action: PayloadAction<IRemoveFromFavoritesSuccess>): void => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.list[id];

            if (!item || item?.category === ECategory.DOCUMENT) {
                return;
            }

            item.isInFavorites = false;
        });
    },
    [addFileToDocument.type]: (state, action: PayloadAction<{ files: AddFile[]; documentType: EDocumentType }>) => {
        const { files, documentType } = action.payload;
        const documentPath = getDocumentId(documentType);
        const parent = state.list[documentPath] as IDocumentSection;

        if (!parent) {
            return;
        }

        const childs = [...parent.childs];
        const count = (parent.count.all || 0) + files.length;
        let avatar = parent.avatar;

        files.forEach((file) => {
            const name = getNameById(file.home);
            const path = file.home || '';

            const item = { ...file, name, flags: { favorite: false, mounted: false }, path };

            if (!avatar) {
                avatar = path;
            }

            state.list[path] = normalizeItem(item, documentPath);

            if (!childs.includes(path)) {
                childs.unshift(path);
            }
        });

        state.list[documentPath] = {
            ...parent,
            avatar,
            childs,
            count: {
                all: count,
                loaded: count,
                files: count,
                folders: 0,
            },
        };
    },
});
