import _ from 'Cloud/Application/_';
import app from 'Cloud/Application/app';
import browser from 'Cloud/Application/Browser';
import build from 'Cloud/Application/Build';
import download from 'Cloud/Application/Download';
import social from 'Cloud/Application/Social';
import user from 'Cloud/Application/User';
import whatsUp from 'Cloud/Application/WhatsUp';

import { initRouterMock } from './RouterMock';

const urlBuilder = require('Cloud/Application/UrlBuilder');
const tree = require('Cloud/Application/Tree');

export const initializeCloudAppCode = () => {
    initRouterMock(app);

    browser(app);
    user();
    urlBuilder(app, _);
    tree(app, _);
    download(app, _);
    social(app);
    build();
    whatsUp();
};
