import { Button, ButtonProps, SizeType } from '@vkontakte/vkui';
import React from 'react';

import styles from './SearchButton.css';

export const SearchButton: React.FC<ButtonProps> = ({ onClick, disabled, ...rest }) => (
    <Button
        className={styles.button}
        size="l"
        appearance="neutral"
        sizeY={SizeType.COMPACT}
        onClick={onClick}
        disabled={disabled}
        {...rest}
    >
        Найти
    </Button>
);

SearchButton.displayName = 'SearchButton';
