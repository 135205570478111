import safetyCoverImage from 'img/aside-promo-modal/safety-cover.png';
import { IS_AUTO_TEST_HIDE, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { isViewContentOnly } from 'reactApp/appHelpers/settingsHelpers';
import { getFeatureSafetyBannerPromo } from 'reactApp/modules/features/features.selectors';
import { storeHelper } from 'reactApp/modules/promo/promo.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { openAsidePromoModal } from 'reactApp/ui/AsidePromoModal/AsidePromoModal.helpers';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { EPromoAction, sendSplashscreenAnalytics } from 'reactApp/utils/splashscreenAlertGa';
import { channel } from 'redux-saga';
import { put, take } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';

const SAFETY_PROMO_ID = 'security';

const completePromoChannel = channel();

interface Props {
    action: EPromoAction;
    source: EStorageType;
    name_button?: string;
}

const sendProtectedAnalytics = ({ action, source, name_button }: Props): void => {
    sendDwh({
        eventCategory: ECategoryGa.security,
        action,
        dwhData: { name: 'security', source, name_button },
        sendImmediately: true,
    });
};

export function* initSafetyAsidePromoModal() {
    const storage = yield* select(getCurrentStorage);
    const alreadyClosed = storeHelper.getValue(SAFETY_PROMO_ID);
    const enabled = yield* select(getFeatureSafetyBannerPromo);

    if (IS_AUTO_TEST_HIDE || storage !== EStorageType.home || alreadyClosed || IS_WEBVIEW || isViewContentOnly || !enabled) {
        return;
    }

    yield put(
        addPromoToShowQueue({
            type: EPromoType.safetyAsidePromo,
            promoId: SAFETY_PROMO_ID,
            onShow: () => {
                sendSplashscreenAnalytics({
                    action: EPromoAction.SHOW,
                    name: SAFETY_PROMO_ID,
                    source: storage,
                });
                sendProtectedAnalytics({
                    action: EPromoAction.VIEW,
                    source: storage,
                });

                storeHelper.markAsShown(SAFETY_PROMO_ID);
            },
            onClick: () => {
                sendSplashscreenAnalytics({
                    action: EPromoAction.CLICK,
                    name: SAFETY_PROMO_ID,
                    source: storage,
                    name_button: 'disable-adv',
                });
                sendProtectedAnalytics({
                    action: EPromoAction.CLICK,
                    source: storage,
                    name_button: 'disable-adv',
                });

                open('/promo/security/#antivirus', '_blank');

                completePromoChannel.put(true);
            },
            onClose: () => {
                sendSplashscreenAnalytics({
                    action: EPromoAction.CLOSE,
                    name: SAFETY_PROMO_ID,
                    source: storage,
                });
                sendProtectedAnalytics({
                    action: EPromoAction.CLOSE,
                    source: storage,
                });

                completePromoChannel.put(true);
            },
        })
    );
}

export function* showSafetyAsidePromoModal() {
    const promo = yield* select(PromoSelectors.getPromo(EPromoType.safetyAsidePromo));

    if (promo) {
        const { onShow, onClick, onClose } = promo;

        yield openAsidePromoModal({
            title: 'Доверьте свои файлы Облаку',
            text: 'Они будут надёжно защищены от посторонних глаз, а у вас всегда будет доступ к ним',
            buttonText: 'Подробнее',
            imageUrl: safetyCoverImage,
            onShow,
            onClick,
            onClose,
        });
        yield put(promoShown(EPromoType.safetyAsidePromo));

        while (true) {
            yield take(completePromoChannel);

            yield put(removePromo(EPromoType.safetyAsidePromo));
            storeHelper.markAsShown(SAFETY_PROMO_ID);

            yield completePromoChannel.close();
        }
    }
}
