import { Button } from '@vkontakte/vkui';
import React, { memo } from 'react';

import styles from './SpecialBanner.css';
import { IProps } from './SpecialBanner.types';

export const SpecialBanner = memo(function SpecialBanner(props: IProps) {
    const { title, text, onButtonClick, buttonText } = props;

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                {title && <div className={styles.title}>{title}</div>}
                <div className={styles.text}>{text}</div>
            </div>

            <div className={styles.button}>
                <Button onClick={onButtonClick} appearance="overlay">
                    {buttonText}
                </Button>
            </div>
        </div>
    );
});
