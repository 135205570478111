/* eslint-disable complexity */
import { bytesToNDigits } from '@mail/cross-sizes-utils';
import React, { memo, ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { MAIL_ATTACHES_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { downloadMobileItem } from 'reactApp/modules/modifying/modifying.actions';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { getStorageItemById } from 'reactApp/modules/storage/storage.selectors';
import { CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { RootState } from 'reactApp/store';
import { Button } from 'reactApp/ui/Button/Button';
import { DownloadIcon, UploadIcon } from 'reactApp/ui/VKUIIcons';
import { canCloneWeblink } from 'reactApp/utils/cloneHelpers';
import { noop } from 'reactApp/utils/helpers';

import styles from './MobileViewerBottomToolbar.css';

interface Props {
    id: string;
    storage: EStorageType;
    sendAnalytics?: (data?: any) => void;
}

export const MobileViewerBottomToolbar = memo<Props>(({ sendAnalytics = noop, id, storage }): ReactElement | null => {
    const { isAttaches, isPublic } = getStorage(storage);
    const item = useSelector((state: RootState) => getStorageItemById(state, storage, id)) as CloudFile;
    const isAuthorized = !useSelector(UserSelectors.isAnonymous);
    const dispatch = useDispatch();
    let isTempOrCloudAttachType = false;
    if (item && 'attachType' in item) {
        isTempOrCloudAttachType = item.attachType === EAttachTypes.temporary || item.attachType === EAttachTypes.cloud;
    }
    const showClone = Boolean((isPublic || isAttaches) && canCloneWeblink(isAuthorized, storage)) && !isTempOrCloudAttachType;

    const handleClone = useCallback(() => {
        toolbarActions.clone({
            id,
            destination: isAttaches ? MAIL_ATTACHES_FOLDER_ID : '',
            source: isAttaches ? 'viewer-attaches' : 'viewer',
            storage: storage ?? undefined,
        });
    }, [id, isAttaches, storage]);

    const handleDownload = useCallback(() => {
        sendAnalytics?.({ action: 'download-content' });

        dispatch(downloadMobileItem({ id, storage }));
    }, [id, sendAnalytics, storage]);

    return (
        <div className={styles.root}>
            <Button theme="octavius" primary fluid onClick={handleDownload}>
                <DownloadIcon width={24} height={24} viewBox="0 0 18 18" />
                <span className={styles.bttnText}>Скачать файл ({item ? bytesToNDigits(item.size, 3).value : ''})</span>
            </Button>
            {showClone && (
                <Button theme="octavius" className={styles.iconButton} onClick={handleClone}>
                    <UploadIcon width={24} height={24} />
                </Button>
            )}
        </div>
    );
});

MobileViewerBottomToolbar.displayName = 'MobileViewerBottomToolbar';
