import { Icon20CheckCircleOutline, Icon20ChevronLeftOutline } from '@vkontakte/icons';
import { Title } from '@vkontakte/vkui';
import { ReactComponent as Icon20CheckCircleOutlineOn } from 'img/icons/vkui/check_circle_outline_on_20.svg';
import React, { Dispatch, memo, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetAllSelectedItems, selectAllItems } from 'reactApp/modules/albums/albums.actions';
import { getGalleryList } from 'reactApp/modules/gallery/gallery.selectors';
import { searchReset } from 'reactApp/modules/search/search.module';
import { toggleAll } from 'reactApp/modules/selections/selections.actions';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { getIds } from 'reactApp/modules/storage/storage.selectors';
import { EActions, EStorageType } from 'reactApp/modules/storage/storage.types';
import { SelectFromCloudContentMod } from 'reactApp/sections/AlbumsPage/SelectFromCloudPopup/components/Content/SelectFromCloudContent';
import { SelectFromCloudSearch } from 'reactApp/sections/AlbumsPage/SelectFromCloudPopup/components/SelectFromCloudSearch/SelectFromCloudSearch';
import { RootState } from 'reactApp/store';
import { Button } from 'reactApp/ui/Button/Button';
import { ToolbarItem } from 'reactApp/ui/Toolbar/ToolbarItem/ToolbarItem';

import styles from './SelectFromCloudToolbar.css';

interface SelectFromCloudToolbarProps {
    mod: SelectFromCloudContentMod;
    setMod: Dispatch<SetStateAction<SelectFromCloudContentMod>>;
}

export const SelectFromCloudToolbar = memo<SelectFromCloudToolbarProps>(({ mod, setMod }) => {
    const dispatch = useDispatch();
    const isAnyIndexSelected = useSelector(SelectionsSelectors.isAnyIndexSelected);
    const selectedIdxs = useSelector(SelectionsSelectors.getSelectedIdxs);
    const galleryItemsIdxs = useSelector((state: RootState) => getIds(state, EStorageType.gallery));
    const galleryItems = useSelector(getGalleryList);

    const [isSearchExpanded, setSearchExpanded] = useState<boolean>(false);

    const handleToogleSelectClick = useCallback(() => {
        if (!isAnyIndexSelected) {
            dispatch(toggleAll({ allIdxs: galleryItemsIdxs, storage: EStorageType.gallery }));
            dispatch(selectAllItems({ allItems: galleryItems }));
        } else {
            dispatch(toggleAll({ allIdxs: selectedIdxs, storage: EStorageType.gallery }));
            dispatch(resetAllSelectedItems());
        }
    }, [isAnyIndexSelected, galleryItemsIdxs, selectedIdxs, dispatch, galleryItems]);

    const handleClickShowSelected = useCallback(() => {
        setMod(SelectFromCloudContentMod.selected);
    }, [setMod]);

    const handleClickReturn = useCallback(() => {
        setMod(SelectFromCloudContentMod.tree);
    }, [setMod]);

    const handleCloseSearch = useCallback(() => {
        dispatch(searchReset());
        setMod(SelectFromCloudContentMod.tree);
        setSearchExpanded(false);
    }, [setMod, setSearchExpanded, dispatch]);

    const leftColContent = useMemo(() => {
        if (mod === SelectFromCloudContentMod.selected) {
            return (
                <Button theme="vk" className={styles.button} onClick={handleClickReturn} qaId="return-to-select">
                    <Icon20ChevronLeftOutline />
                    <span>Вернуться</span>
                </Button>
            );
        }

        if (mod === SelectFromCloudContentMod.search || isSearchExpanded) {
            return (
                <Button theme="vk" className={styles.button} onClick={handleCloseSearch} qaId="search-reset">
                    <Icon20ChevronLeftOutline />
                    <span>Сбросить поиск</span>
                </Button>
            );
        }

        return (
            <Title level="1" weight="2" className={styles.title}>
                Добавить в альбом
            </Title>
        );
    }, [mod, handleClickReturn, handleCloseSearch, isSearchExpanded]);

    return (
        <div className={styles.root}>
            <div className={styles.leftCol}>{leftColContent}</div>
            <div className={styles.rightCol}>
                {!isSearchExpanded && (
                    <ToolbarItem
                        id={EActions.selectAll}
                        text={
                            !isAnyIndexSelected ? (
                                'Выделить все'
                            ) : (
                                <>
                                    <span className={styles.count}>{selectedIdxs.length}</span> Снять выделение
                                </>
                            )
                        }
                        icon={
                            !isAnyIndexSelected ? (
                                <Icon20CheckCircleOutline data-qa-id="selectAll" />
                            ) : (
                                <Icon20CheckCircleOutlineOn data-qa-id="selectAll" color="var(--vkui--color_icon_accent)" />
                            )
                        }
                        onClick={handleToogleSelectClick}
                    />
                )}
                {isAnyIndexSelected && mod === SelectFromCloudContentMod.tree && !isSearchExpanded && (
                    <ToolbarItem id={EActions.showSelected} text="Посмотреть выбранные" onClick={handleClickShowSelected} />
                )}
                {[SelectFromCloudContentMod.tree, SelectFromCloudContentMod.search].includes(mod) && (
                    <SelectFromCloudSearch isSearchExpanded={isSearchExpanded} setSearchExpanded={setSearchExpanded} setMod={setMod} />
                )}
            </div>
        </div>
    );
});

SelectFromCloudToolbar.displayName = 'SelectFromCloudToolbar';
