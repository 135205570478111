import classNames from 'clsx';
import Emitter from 'Emitter';
import { logger } from 'lib/logger';
import { EDMRActions, EDMRTypes } from 'reactApp/ui/DMR/DMRTypes';

import styles from './DMR.css';

export default class DMR extends Emitter {
    EVENT_LOADED = 'loaded';
    EVENT_LOADING = 'loading';
    EVENT_LOAD_ERROR = 'load-error';
    EVENT_LOAD_ERROR_TIMEOUT = 'load-error-timeout';
    EVENT_RETRY = 'retry';
    EVENT_PAYMENT_SUCCESS = 'payment-success';
    EVENT_PAYMENT_CANCEL = 'payment-cancel';
    EVENT_PAYMENT_WAITING = 'payment-waiting';
    EVENT_PAYMENT_FAIL = 'payment-fail';
    EVENT_CLOSE = 'close';
    EVENT_MESSAGE = 'message';
    EVENT_FORM_SEND = 'form-send';
    EVENT_RESIZE = 'resize-frame';
    EVENT_3DS_START = '3ds-start';
    EVENT_3DS_FINISH = '3ds-finish';
    EVENT_AVAILABLE_METHODS = 'available-methods';
    EVENT_REMOVE_ADDED_CARD = 'remove-added-card';
    FRAME_FIMEOUT = 60000;
    isPaySuccessSent = false;

    constructor(settings = {}) {
        super(settings);

        this.settings = settings;
        const { el, className = '', payMethod = '', isMidas = false } = this.settings;

        this._el = el;
        this._className = className;
        this._frame = null;
        this._loaded = false;
        // после события payStart происходит еще несоклько ресайзов,
        // поэтому при оплате картой (по умолчанию) дожидаемся события pageLoad,
        // при любом другом способе оплаты - вешаемся на событие payStart
        this._loadEvent = isMidas ? EDMRActions.formLoad : payMethod ? EDMRActions.payStart : EDMRActions.pageLoad;
    }

    load = (url) =>
        new Promise((resolve, reject) => {
            this._loadResolver = resolve;
            this._loadRejector = reject;

            this._frame = document.createElement('iframe');

            this._frame.className = classNames({
                [styles.iframe]: true,
                [this._className]: Boolean(this._className),
            });

            this._frame.src = url;

            this._frame.onload = this._onFrameLoad.bind(this);
            this._frame.onerror = this._onFrameError.bind(this);

            this._frameTimeout = setTimeout(this._onFrameTimeout.bind(this), this.FRAME_FIMEOUT);

            DMR.heap.add(this);

            this._el.appendChild(this._frame);
        });

    _onMessage = (evt) => {
        logger.verbose(evt);

        let event = evt.data;

        try {
            event = JSON.parse(event);
        } catch (error) {
            event = {};
        }

        if (!event.type || !event.action) {
            return;
        }

        const { type, action, action_params: actionParams } = event;

        if (!this._loaded) {
            this.emit(this.EVENT_LOADING);

            if (type === EDMRTypes.billing && action === EDMRActions.payError) {
                this.emit(this.EVENT_LOAD_ERROR, {
                    type: 'pay-error',
                });
                this._loadRejector(this.EVENT_LOAD_ERROR);
            } else if ((type === EDMRTypes.billing || type === EDMRTypes.payment) && action === this._loadEvent) {
                this._loaded = true;
                clearTimeout(this._frameTimeout);
                this.emit(this.EVENT_LOADED);
                this._loadResolver(this.EVENT_LOADED);
            }
        }

        if (type === EDMRTypes.payment) {
            if (action === EDMRActions.refreshWindow) {
                this.emit(this.EVENT_RETRY);
            } else if (action === EDMRActions.closeWindow) {
                this.emit(this.EVENT_CLOSE);
            }
        } else if (type === EDMRTypes.billing) {
            if (action === EDMRActions.paySuccess && !this.isPaySuccessSent) {
                this.isPaySuccessSent = true;
                this.emit(this.EVENT_PAYMENT_SUCCESS);
            } else if (action === EDMRActions.payCancel) {
                this.emit(this.EVENT_PAYMENT_CANCEL);
            } else if (action === EDMRActions.payError || action === EDMRActions.payFail) {
                this.emit(this.EVENT_PAYMENT_FAIL);
            } else if (action === EDMRActions.paySubmit || (action === EDMRActions.putPixel && actionParams.alias === 'form_send')) {
                this.emit(this.EVENT_FORM_SEND);
            } else if (action === EDMRActions.closeWindow) {
                this.emit(this.EVENT_CLOSE);
            } else if (action === EDMRActions.refreshWindow) {
                this.emit(this.EVENT_RETRY);
            } else if (action === EDMRActions.resizeFrame) {
                this.emit(this.EVENT_RESIZE, actionParams);
            } else if (action === EDMRActions.threeDSPage) {
                this.emit(this.EVENT_3DS_START);
            } else if (action === EDMRActions.threeDSFinish) {
                this.emit(this.EVENT_3DS_FINISH);
            } else if (action === EDMRActions.availableMethods) {
                this.emit(this.EVENT_AVAILABLE_METHODS, actionParams);
            } else if (action === EDMRActions.removeAddedCard) {
                this.emit(this.EVENT_REMOVE_ADDED_CARD);
            }
        }

        this.emit(this.EVENT_MESSAGE, {
            type: event.type,
            action: event.action,
        });
    };

    _onFrameTimeout = () => {
        this._loadRejector(this.EVENT_LOAD_ERROR_TIMEOUT);

        this.emit(this.EVENT_LOAD_ERROR_TIMEOUT);
        this.emit(this.EVENT_LOAD_ERROR, {
            type: 'timeout',
        });
    };

    _onFrameLoad = () => {
        //
    };

    _onFrameError = () => {
        clearTimeout(this._frameTimeout);

        this._loadRejector(this.EVENT_LOAD_ERROR);

        this.emit(this.EVENT_LOAD_ERROR, {
            type: 'load-error',
        });
    };

    _destroyFrame = () => {
        clearTimeout(this._frameTimeout);
        this._frame.src = 'about::blank';
        this._frame.onload = null;
        this._frame.onerror = null;
        this._frame.parentNode.removeChild(this._frame);

        this._frame = null;
        this._loadResolver = null;
        this._loadRejector = null;
        this._loaded = false;

        DMR.heap.remove(this);
    };

    destroy = () => {
        if (this._frame) {
            this._destroyFrame();
        }

        this.off();
    };

    static heap = {
        _heap: [],

        findIndexByView: (view) => {
            const heap = DMR.heap._heap;
            let i = heap.length;

            while (i--) {
                if (heap[i] === view) {
                    return i;
                }
            }

            return -1;
        },

        findIndexByWindow: (frameWindow) => {
            const heap = DMR.heap._heap;
            let i = heap.length;

            while (i--) {
                const documentView = heap[i];
                const frame = documentView._frame;

                if (frame && frame.contentWindow === frameWindow) {
                    return i;
                }
            }

            return -1;
        },

        getByWindow: (frameWindow) => {
            const index = DMR.heap.findIndexByWindow(frameWindow);

            if (index !== -1) {
                return DMR.heap._heap[index];
            }

            return null;
        },

        add: (documentView) => {
            const index = DMR.heap.findIndexByView(documentView);

            if (index === -1) {
                DMR.heap._heap.push(documentView);
            }
        },

        remove: (documentView) => {
            const index = DMR.heap.findIndexByView(documentView);

            if (index !== -1) {
                DMR.heap._heap.splice(index, 1);
            }
        },
    };
}

window.addEventListener('message', function (event) {
    if (
        !event.origin.match(/https:\/\/[^/]+\.(devmail|mail|mini-mail)\.ru(:\d+)?$/i) &&
        !event.origin.match(/https:\/\/(checkout\.vkpay\.io)$/i) &&
        !event.origin.match(/https:\/\/(co\.vkpay\.io)$/i)
    ) {
        return;
    }

    const frameWindow = event.source;
    const DMRView = DMR.heap.getByWindow(frameWindow);

    if (DMRView) {
        DMRView._onMessage(event);
    }
});
