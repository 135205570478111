import { isItemPreviewableImageWithThumbs, isItemPreviewableVideoWithThumbs } from 'reactApp/modules/file/file.helpers';
import { RootState } from 'reactApp/store';
import { LoadingState } from 'reactApp/types/commonStates';
import { Count } from 'reactApp/types/Tree';
import { createSelector } from 'reselect';

import { FavoriteItem, State } from './favorites.types';

const getFavoritesStore = (state: RootState): State => state.favorites;
const getFavorites = (state: RootState): Record<string, FavoriteItem> => getFavoritesStore(state).list;
const getOffset = (state: RootState): number => getFavoritesStore(state).offset;
const hasMoreToLoad = (state: RootState): boolean => getFavoritesStore(state).hasMoreToLoad;
const getFavoritesStatistics = (state: RootState): Count => getFavoritesStore(state).count;

const getFavoritesList = createSelector(getFavoritesStore, (state: State): string[] => state.childs);
const getFavoriteById = createSelector(
    getFavorites,
    (state: RootState, id: string): string => id,
    (favorites, id): FavoriteItem => favorites[id]
);

const getRandomFavoritesMedia = createSelector(
    getFavorites,
    (state, number): number => number,
    (favorites, number): any[] => {
        const mediaFiles = Object.values(favorites).filter(
            (item) => isItemPreviewableImageWithThumbs(item) || isItemPreviewableVideoWithThumbs(item)
        );
        const shuffled = mediaFiles.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, number);
    }
);

const getLoadingState = createSelector(
    getFavoritesStore,
    (state): LoadingState => ({
        isLoading: state.isLoading,
        isLoaded: state.isLoaded,
    })
);

export const FavoritesSelectors = {
    getFavorites,
    getFavoritesList,
    getFavoriteById,
    getFavoritesStore,
    getOffset,
    hasMoreToLoad,
    getRandomFavoritesMedia,
    getLoadingState,
    getFavoritesStatistics,
};
