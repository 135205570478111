import { Icon20DocumentOutline, Icon20PictureOutline, Icon20VideoSquareOutline } from '@vkontakte/icons';
import React from 'react';

import styles from './SelectFromCloudSearch.css';
import { SearchFileTypeOption, SearchOptionType } from './SelectFromCloudSearch.types';

export const searchFileTypeOptions: Record<SearchOptionType, SearchFileTypeOption> = {
    [SearchOptionType.media]: {
        id: SearchOptionType.media,
        text: 'Фильтр',
        icon: <Icon20DocumentOutline className={styles.icon} />,
    },
    [SearchOptionType.image]: {
        id: SearchOptionType.image,
        text: 'Изображения',
        icon: <Icon20PictureOutline className={styles.icon} />,
    },
    [SearchOptionType.video]: {
        id: SearchOptionType.video,
        text: 'Видео',
        icon: <Icon20VideoSquareOutline className={styles.icon} />,
    },
};

export const searchFileTypeOptionsList = Object.values(searchFileTypeOptions).filter((item) => item.id !== SearchOptionType.media);
