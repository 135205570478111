import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setActionPanelHeader, toggleActionPanel } from 'reactApp/modules/actionpanel/actionpanel.module';

export function useActionPanel() {
    const dispatch = useDispatch();
    const closeCallback = useCallback(() => dispatch(toggleActionPanel({ open: false })), [dispatch]);
    const setHeaderCallback = useCallback((value) => dispatch(setActionPanelHeader(value)), [dispatch]);

    return {
        close: closeCallback,
        setHeader: setHeaderCallback,
    };
}
