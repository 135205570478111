import React, { memo, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { usePortal } from 'reactApp/hooks/usePortal';
import { historyPush } from 'reactApp/modules/router/router.module';
import { IHistoryPush } from 'reactApp/modules/router/router.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { getViewerItem } from 'reactApp/modules/viewer/viewer.selectors';
import { noop } from 'reactApp/utils/helpers';
import { closeViewerUrl } from 'reactApp/utils/urlHelper';

const ReactViewerConnected = React.lazy(() => import(/* webpackChunkName: "reactViewer" */ '../../../ui/ReactViewer/ReactViewer'));

export const ViewerRender = memo<{ selectedViewItemId: string }>(({ selectedViewItemId }) => {
    const reactViewerTarget = usePortal('ReactViewer');
    const dispatch = useDispatch();

    const item = useSelector(getViewerItem);

    const onViewerClose = useCallback(() => {
        const closeUrl = item?.storage === EStorageType.stock ? `/stock/${item.parent}` : closeViewerUrl(location);
        const historyPushParams: IHistoryPush = { id: closeUrl };
        if (closeUrl.startsWith('/search') || location.search.includes('dialog=')) {
            historyPushParams.search = location.search;
        }
        // TODO: routing
        dispatch(historyPush(historyPushParams));
    }, [item?.storage, item?.parent]);

    // Для печати просмотрщика скрываем страницу, а его рендерим в корень
    return createPortal(
        <ReactViewerConnected
            file={{ id: selectedViewItemId }}
            isMountedFolder={false}
            showEditorHeader
            showNotify={noop}
            gaCategory="fv"
            onClose={onViewerClose}
            isViewer
            enablePreload
        />,
        reactViewerTarget
    );
});

ViewerRender.displayName = 'ViewerRender';
