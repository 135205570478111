import classNames from 'clsx';
import React, { memo, ReactElement, ReactNode, useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { noop } from 'reactApp/utils/helpers';

import styles from './SwipingDownComponent.css';

interface Props {
    children: ReactNode;
    onClose?: (e: any) => void;
    lock?: boolean;
    ignoreOpacity?: boolean;
    swipeSizePercent?: number;
    wrapClass?: string;
}

export const SwipingDownComponent = memo(
    ({ children, onClose = noop, lock = false, ignoreOpacity = false, swipeSizePercent = 50, wrapClass }: Props): ReactElement => {
        const rootRef = useRef<null | any>(null);

        const [{ y, opacity }, set] = useSpring(() => ({ y: 0, opacity: 1, config: { mass: 1, tension: 190, friction: 26 } }));

        const bind = useDrag(
            (state) => {
                const {
                    down,
                    movement: [_mx, my],
                    swipe: [_swipeX, swipeY],
                    touches,
                } = state;

                if (lock) {
                    return;
                }

                // если свайп более чем одним пальцем, то не учитываем свайп
                if (!rootRef.current || touches > 1) {
                    return;
                }

                const { height } = rootRef.current.getBoundingClientRect();

                // если сделан резкий свайп по вертикали вниз или свайп на n-процентов экрана , то скрываем компонент
                if (swipeY === 1 || my > height * (swipeSizePercent / 100)) {
                    set({
                        y: height,
                        ...(!ignoreOpacity && { opacity: 0 }),
                    });
                    setTimeout(onClose, 300);
                } else {
                    set({
                        y: down && my > 0 ? my : 0,
                        ...(!ignoreOpacity && { opacity: down && my > 0 ? 1 - my / height : 1 }),
                    });
                }
            },
            { axis: 'y', swipeDistance: 50 }
        );

        return (
            <animated.div
                className={classNames(styles.root, wrapClass)}
                ref={rootRef}
                {...bind()}
                style={{ transform: y.to((y) => `translateY(${y}px)`), opacity, touchAction: 'none' }}
            >
                {children}
            </animated.div>
        );
    }
);

SwipingDownComponent.displayName = 'SwipingDownComponent';
