import classNames from 'clsx';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetSelect, selectOne } from 'reactApp/modules/selections/selections.actions';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { renderContextMenu } from 'reactApp/ui/ContextMenu/createContextMenuToolkit';
import { MoreHorizontalIcon } from 'reactApp/ui/VKUIIcons';

import { Label } from '../Label/Label';
import styles from './Album.css';

const CONTEXT_MENU_OFFSET = 8;

export const Options = memo(function Options({ id }: { id: string }) {
    const [isShow, setShow] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();

    const onDocumentClick = useCallback(() => {
        if (!isShow) {
            return;
        }
        setShow(false);
        dispatch(resetSelect());
    }, [isShow]);

    useEffect(() => {
        document.addEventListener('click', onDocumentClick);
        return () => document.removeEventListener('click', onDocumentClick);
    }, []);

    const handleOnClick = useCallback((event) => {
        if (!ref.current) {
            return;
        }

        event.stopPropagation();
        event.preventDefault();

        setShow(true);
        dispatch(selectOne({ selectedIdx: id, storage: EStorageType.albums }));

        const { left, bottom } = ref.current.getBoundingClientRect();

        renderContextMenu({
            posX: left + window.scrollX,
            posY: bottom + window.scrollY + CONTEXT_MENU_OFFSET,
            gaSuffix: 'albums',
        });
    }, []);

    return (
        <Label
            className={classNames(styles.options, { [styles.options_visible]: isShow })}
            ref={ref}
            onClick={handleOnClick}
            qaLabel="options"
        >
            <MoreHorizontalIcon />
        </Label>
    );
});
