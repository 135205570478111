define(function (require, exports, module) {
	'use strict';

	module.exports = function () {

		return {
			"trashbin": {
				"bad_request": {
					"status": 400,
					"body": {
						"error": "bad_chunk"
					}
				}
			},
			"trashbin/restore": {
				"not_exists": {
					"status": 400,
					"body": {
						"error": "not_exists"
					}
				},
				"file_exists": {
					"status": 400,
					"body": {
						"error": "file_exists"
					}
				},
				"not_a_directory": {
					"status": 400,
					"body": {
						"error": "not_a_directory"
					}
				},
				"name_too_long": {
					"status": 400,
					"body": {
						"error": "name_too_long"
					}
				},
				"bad_name": {
					"status": 400,
					"body": {
						"error": "bad_name"
					}
				},
				"read_only": {
					"status": 507,
					"body": {
						"error": "read_only"
					}
				},
				"overquota": {
					"status": 507,
					"body": {
						"error": "overquota"
					}
				}
			}
		};
	};
});
