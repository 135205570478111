import { Icon20ArrowUturnLeftOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { memo } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { DataListControl } from 'reactApp/ui/DataListControl/DataListControl';
import { Hint } from 'reactApp/ui/Hint/Hint';

interface RestoreProps {
    className?: string;
    onClick: React.MouseEventHandler<HTMLDivElement>;
}

export const Restore = memo<RestoreProps>(function Restore({ className, onClick }) {
    return (
        <Hint text="Восстановить">
            <DataListControl.New className={classNames(className)} type="restore" id="restore" viewType={EViewMode.list} onClick={onClick}>
                <Icon20ArrowUturnLeftOutline />
            </DataListControl.New>
        </Hint>
    );
});
