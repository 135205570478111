import type { ExtensionRecord, SharedExtensionsRecord } from 'reactApp/modules/editor/editor.types';
import type { ServerEditor } from 'server/helpers/editors/types';

type ComposeSharedExtensionFn = (editors: ServerEditor[], extensions: ExtensionRecord) => SharedExtensionsRecord;

export const composeSharedExtensions: ComposeSharedExtensionFn = (editors, extensions) =>
    editors.reduce((acc, editor) => {
        if ('x-in-shared-folder' in editor) {
            const editExtension = Object.keys(extensions).reduce((acc, ext) => ({ ...acc, [ext]: true }), {});

            return { ...acc, ...editExtension };
        }

        return acc;
    }, {} as SharedExtensionsRecord);
