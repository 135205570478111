import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';

export const renderDeleteUsersDialog = ({ onActionClick }) => {
    openPopupHelper({
        popupName: popupNames.NO_WARNING,
        data: {
            id: 'sharing-warning-dialog',
            onActionClick,
            title: 'Вы действительно хотите отключить доступ для пользователей Облака?',
            description: 'После отключения эта папка перестанет быть доступной для приглашённых вами пользователей Облака',
            buttonAcceptText: 'Отключить',
            buttonRejectText: 'Не отключать',
        },
    });
};
