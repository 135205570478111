/**
 * Объединение двух списков
 * @param oldList - старый список
 * @param newList - новый список
 * @param offset - сдвиг, с которого работает новый список
 * @param limit - число элементов нового списка, которые нужно захватить в итоговый список
 * @returns объединенный список
 */
export const mergeItemsList = (oldList: string[], newList: string[], { offset, limit }: { offset: number; limit: number }): string[] => {
    // начало старого списка без обновлений
    const startOldList = oldList.slice(0, offset);
    // конец старого списка без обновлений
    const endOldList = oldList.slice(offset + limit, oldList.length);
    const res = [...startOldList, ...newList, ...endOldList];

    // создаем уникальный список
    return Array.from(new Set(res));
};
