import classNames from 'clsx';
import React, { memo, ReactElement, useMemo } from 'react';
import { areEqual, ListChildComponentProps } from 'react-window';
import { AdvBanner } from 'reactApp/ui/Mobile/Datalist/AdvBanner/AdvBanner';

import styles from './VirtualList.css';

export const VirtualListGridRow = memo(function VirtualListGridRow(props: ListChildComponentProps): ReactElement | null {
    const { index, style, data } = props;
    const { list, width, margin, height, itemActiveIndex, indexes, dividerSize } = data;

    const startIndex = indexes[index].start;
    const lastIndex = Math.min(indexes[index].end, list.length);
    const handleRef = index === itemActiveIndex ? data.handleRef : null;
    const isDivider = list[startIndex].divider;

    const content = useMemo(() => {
        const output: ReactElement[] = [];

        for (let i = startIndex; i <= lastIndex; i++) {
            const skipRenderItem = list[i]?.divider;

            if (skipRenderItem) {
                continue;
            }

            const key = String(typeof list[i] === 'string' ? list[i] : list[i].id);
            const itemStyle = {
                width: `${width}px`,
                marginRight: i === lastIndex ? 0 : `${margin}px`,
                height: `${height}px`,
            };

            output.push(
                <div key={key + i} style={itemStyle}>
                    <div className={styles.colItem}>{data.renderItem(list[i], { index: i })}</div>
                </div>
            );
        }

        return output;
    }, [startIndex, lastIndex, height, list, data, margin, width]);

    if (isDivider) {
        return (
            <div
                key={index}
                className={classNames({
                    [styles.dividerContainer]: true,
                    [styles.dividerGrid]: true,
                    [styles.dividerAdv]: list[startIndex].showAdv,
                })}
                style={style}
                data-qa-divider={list[startIndex].text}
            >
                <div
                    className={classNames({
                        [styles.divider]: true,
                        [styles[`divider_${dividerSize}`]]: !!dividerSize,
                    })}
                >
                    {list[startIndex].text}
                    {list[startIndex].showAdv && <AdvBanner />}
                </div>
            </div>
        );
    }

    return (
        <div style={style} className={styles.gridRow} ref={handleRef}>
            {content}
        </div>
    );
}, areEqual);
