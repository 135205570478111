import classNames from 'clsx';
import React, { useCallback } from 'react';
import { IS_ONPREMISE, IS_TARM } from 'reactApp/appHelpers/configHelpers';
import { AttachesItem } from 'reactApp/modules/attaches/attaches.types';
import { CloudFile } from 'reactApp/modules/storage/storage.types';
import { IStoryFileItem } from 'reactApp/modules/stories/stories.types';
import styles from 'reactApp/ui/ReactViewer/ViewerHeader/BizInlineViewerToolbar/BizInlineViewerToolbar.css';

type BizInlineViewerToolbarProps = {
    isPublic: boolean;
    item: CloudFile | AttachesItem | IStoryFileItem | null;
};

export const BizInlineViewerToolbar: React.FC<BizInlineViewerToolbarProps> = ({ isPublic, item }) => {
    const backLink = isPublic ? '/home' : `/home${item?.parent}`;

    const handleBack = useCallback(() => {
        window.location.href = backLink;
        window.opener = null;
    }, [backLink]);

    return (
        <div className={styles.root}>
            <div className={styles.leftGroup}>
                {IS_ONPREMISE && (
                    <div
                        className={classNames({
                            [styles.logo]: true,
                            [styles.logo__tarm]: IS_TARM,
                            [styles.logo__workdisk]: !IS_TARM,
                        })}
                        onClick={handleBack}
                        data-ext={String(item?.ext).toLowerCase()}
                    />
                )}
            </div>
        </div>
    );
};
