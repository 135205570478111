import {
    differenceInCalendarWeeks,
    differenceInCalendarYears,
    getMonth,
    getYear,
    isThisWeek,
    isThisYear,
    isToday,
    isYesterday,
} from 'date-fns';

enum EDividerPeriod {
    today = 'Сегодня',
    yesterday = 'Вчера',
    thisWeek = 'Ранее на этой неделе',
    pastWeek = 'На прошлой неделе',
}

const MONTH = {
    0: 'Январь',
    1: 'Февраль',
    2: 'Март',
    3: 'Апрель',
    4: 'Май',
    5: 'Июнь',
    6: 'Июль',
    7: 'Август',
    8: 'Сентябрь',
    9: 'Октябрь',
    10: 'Ноябрь',
    11: 'Декабрь',
};

export const getPeriodNameByDate = (date: number) => {
    const now = Date.now();

    const pastWeek = differenceInCalendarWeeks(now, date, { weekStartsOn: 1 }) === 1;
    const pastYear = differenceInCalendarYears(now, date) === 1;

    if (isToday(date)) {
        return 'today';
    } else if (isYesterday(date)) {
        return 'yesterday';
    } else if (isThisWeek(date, { weekStartsOn: 1 })) {
        return 'thisWeek';
    } else if (pastWeek) {
        return 'pastWeek';
    } else if (isThisYear(date)) {
        return `month-${getMonth(date)}`;
    } else if (pastYear) {
        return `month-${getMonth(date)}-year-${getYear(date)}`;
    }

    return `year-${getYear(date)}`;
};

export const getDividerPeriodName = (period: string) => {
    const name = EDividerPeriod[period];

    if (name) {
        return name;
    }

    const month = period.match(/month-(\d*)-?/)?.[1];
    const year = period.match(/year-(\d*)-?/)?.[1];

    return `${month ? MONTH[month] : ''}${month && year ? ' ' : ''}${year ?? ''}`;
};
