import styles from './MobileViewer.css';

export const GA_CATEGORY = 'mobile-viewer';

export const SWIPER_SPEED = 350;

export const virtualOptions = {
    addSlidesBefore: 3,
    addSlidesAfter: 3,
};

export const animationClasses = {
    exit: styles.toolbar_exit,
    exitActive: styles.toolbar_exit_active,
    enter: styles.toolbar_enter,
    enterActive: styles.toolbar_enter_active,
};
