import throttle from 'lodash.throttle';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isDomainFolder, moveItemsDialog } from 'reactApp/appHelpers/appHelpers';
import { setDragging } from 'reactApp/modules/selections/selections.actions';
import { getSelectedItems } from 'reactApp/modules/selections/selections.items.selectors';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { getItemById } from 'reactApp/modules/storage/storage.selectors';
import { RootState } from 'reactApp/store';

export const useDragAndDrop = (id: string, isActiveFolder = false) => {
    const dispatch = useDispatch();

    const [isDraggingOver, setDraggingOver] = useState(false);

    const isDragging = useSelector(SelectionsSelectors.isDragging);
    const itemsToDrop = useSelector(getSelectedItems);
    const dragOverCloudItem = useSelector((state: RootState) => getItemById(state, id));

    const isAllowedToDrop = !itemsToDrop.some(isDomainFolder) && !isActiveFolder;

    const onMouseMove = useCallback(() => {
        if (!isDraggingOver && isDragging) {
            setDraggingOver(true);
        }
    }, [isDragging, isDraggingOver]);

    const handleMouseLeave = useCallback(() => setDraggingOver(false), []);

    const handleStopDrag = useCallback(() => {
        if (!isDragging) {
            return;
        }

        dispatch(setDragging(false));

        if (!isDraggingOver || !isAllowedToDrop) {
            dispatch(
                showSnackbarAction({
                    text: 'Невозможно переместить',
                    type: SnackbarTypes.failure,
                    id: 'drop-error',
                    closable: true,
                })
            );
            return;
        }

        moveItemsDialog(itemsToDrop, dragOverCloudItem);
    }, [dispatch, itemsToDrop, isDragging, isDraggingOver, dragOverCloudItem, isAllowedToDrop]);

    const handleMouseMove = useMemo(() => throttle(onMouseMove, 300), [onMouseMove]);

    return { isDraggingOver, handleMouseMove, handleMouseLeave, handleStopDrag, isAllowedToDrop };
};
