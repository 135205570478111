import { Method } from 'axios';
import api from 'Cloud/Application/api';
import { APICallV4 } from 'reactApp/api/APICall';
import { apiMigrateWeblinksV2ToV4 } from 'reactApp/appHelpers/featuresHelpers';
import { promisifyDeferredCall } from 'reactApp/utils/helpers';

const method = 'weblinks/editable';

class WeblinksWritableAPIV4Call extends APICallV4 {
    _method = method;
    _type: Method = 'post';
}

export const weblinksWritableApiCall = (params: Record<string, any>): Promise<unknown> => {
    if (apiMigrateWeblinksV2ToV4.editable) {
        return new WeblinksWritableAPIV4Call().makeRequest({
            weblink_id: params.weblink,
            enable: true,
        });
    }

    return promisifyDeferredCall(api.weblinks.writable, params);
};
