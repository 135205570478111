import { useCallback } from 'react';
import { onDraggingOver, onStartDrag, onStopDrag } from 'reactApp/ui/Datalist/UserActions/onDragAndDropAction';

export const useHandleDragAndDropCallback = ({
    dragParams,
    selectedIdxs,
    dispatch,
    storage,
    setDragOverItem,
    setDragPosX,
    setDragPosY,
    prevSelectHandler,
    dragOverItem,
    selectedItems,
    isDragging,
}) => {
    const handleStartDrag = () => {
        onStartDrag({ dragParams, selectedIdxs, dispatch, storage, prevSelectHandler });
    };

    const handleStopDrag = () => {
        onStopDrag({
            setDragOverItem,
            setDragPosX,
            setDragPosY,
            prevSelectHandler,
            dragParams,
            dragOverItem,
            selectedItems,
            isDragging,
            dispatch,
        });
    };

    const handleDraggingOver = useCallback(
        ({ item }) => {
            onDraggingOver({ isDragging, item, dragOverItem, setDragOverItem });
        },
        [isDragging, dragOverItem]
    );

    return { handleStartDrag, handleStopDrag, handleDraggingOver };
};
