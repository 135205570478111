import clsssNames from 'clsx';
import React, { memo, useCallback } from 'react';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { noopVoid } from 'reactApp/utils/helpers';

import styles from './ViolatedFilename.css';

export interface IProps {
    onClose: typeof noopVoid;
    fileName: string;
    skip: (value?: unknown) => void;
    stop: typeof noopVoid;
    uploadQueueLenght: number;
    folderName?: string;
}

export const ViolatedFilenameDialog = memo<IProps>(({ onClose, fileName, skip, stop, uploadQueueLenght, folderName }) => {
    const renderHeader = useCallback(() => 'Ошибка при загрузке файла', []);
    const renderContent = useCallback(() => {
        const baseText = (
            <span>
                не&nbsp;может быть загружен в&nbsp;хранилище в&nbsp;соответствии с&nbsp;правилами безопасности. Обратитесь
                к&nbsp;администратору
            </span>
        );

        if (folderName) {
            return (
                <div>
                    Файл <b>{fileName}</b> из&nbsp;<b>{folderName.split('/')[1]}</b>&nbsp;{baseText}
                </div>
            );
        }

        return (
            <div>
                Файл <b>{fileName}</b> {baseText}
            </div>
        );
    }, [fileName, folderName]);

    const onSuccess = () => {
        skip();
        onClose();
    };

    const onCloseHandler = () => {
        stop();
        onClose();
    };

    const dialogProps =
        uploadQueueLenght > 1
            ? {
                  buttons: [EButtonType.accept, EButtonType.cancel],
                  successButtonText: 'Продолжить',
                  cancelButtonText: 'Остановить загрузку',
              }
            : {
                  buttons: [EButtonType.accept],
                  successButtonText: 'Закрыть',
              };

    return (
        <BaseConfirmDialog
            dataQAId="violated-filename-dialog"
            renderHeader={renderHeader}
            onClose={onCloseHandler}
            onSuccess={onSuccess}
            renderContent={renderContent}
            closeOnDimmerClick
            className={clsssNames(styles.root, {
                [styles.rootResponsive]: ENABLE_FULL_RESPONSIVE,
            })}
            {...dialogProps}
        />
    );
});

ViolatedFilenameDialog.displayName = 'ViolatedFilenameDialog';
