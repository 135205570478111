import { Icon20BookmarkOutline } from '@vkontakte/icons';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getIsActiveBookmark } from 'reactApp/modules/ebook/ebook.selectors';
import { EActions } from 'reactApp/modules/storage/storage.types';
import { ToolbarItem } from 'reactApp/ui/Toolbar/ToolbarItem/ToolbarItem';
import { Icon20BookmarkFill } from 'reactApp/ui/VKUIIcons';

interface Props {
    hook: () => {
        onClick: (isActiveBookmark: boolean) => void;
    };
}

export const BookmarkButton: FC<Props> = ({ hook, ...rest }) => {
    const { onClick } = hook();

    const isActiveBookmark = useSelector(getIsActiveBookmark);

    return (
        <ToolbarItem
            onClick={() => onClick(isActiveBookmark)}
            icon={isActiveBookmark ? <Icon20BookmarkFill width={20} height={20} /> : <Icon20BookmarkOutline width={20} height={20} />}
            id={EActions.bookmark}
            hint={isActiveBookmark ? 'Удалить закладку' : 'Добавить закладку'}
            text={isActiveBookmark ? 'Удалить закладку' : 'Добавить закладку'}
            collapse
            {...rest}
        />
    );
};
