import { checkApiResponseDataEquals } from 'reactApp/api/apiHelpers';
import { PurchasedGiftsAPICall } from 'reactApp/api/PurchasedGiftsAPICall';
import { PurchasedGiftsUpdate } from 'reactApp/modules/purchasedGifts/purchasedGifts.constants';
import ping from 'reactApp/utils/ping';

const request = () => ({
    type: PurchasedGiftsUpdate.REQUEST,
});

const success = list => ({
    type: PurchasedGiftsUpdate.SUCCESS,
    list,
});

const failure = error => ({
    type: PurchasedGiftsUpdate.FAILURE,
    error,
});

const callApi = () => new PurchasedGiftsAPICall().makeRequest();

export const updatePurchasedGifts = ({ count } = {}) => (dispatch, getState) => {
    dispatch(request());
    const initialState = getState().purchasedGifts.rawList;

    return new Promise((resolve, reject) => {
        ping({ count, request: callApi, initialState, check: checkApiResponseDataEquals(initialState) })
            .then(({ data }) => {
                resolve(dispatch(success(data)));
            })
            .catch(error => reject(dispatch(failure(error))));
    });
};
