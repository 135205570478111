import React, { useEffect } from 'react';

export function useOutsideClick(ref: React.RefObject<HTMLElement>, handler: (e: KeyboardEvent | MouseEvent | TouchEvent) => void) {
    useEffect(() => {
        const listener = (event: KeyboardEvent | MouseEvent | TouchEvent) => {
            if (!ref.current || ref?.current.contains(event.target as Node)) {
                return;
            }

            handler(event);
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
}
