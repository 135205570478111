import { Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import { ReactComponent as Icon24AddOutline } from 'img/icons/vkui/add_outline_24.svg';
import { ReactComponent as Icon16AddSmall } from 'img/icons/vkui/add_small_16.svg';
import React, { FC, useCallback, useMemo } from 'react';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

import styles from './DataListItemCreateNew.css';
import { CREATE_ITEM_DATA, CreateNewMod } from './DataListItemCreateNew.data';

const NEW_ITEM_CAPTION = IS_BIZ_USER ? 'Создать' : 'Создать документ\nили папку';

interface DataListItemCreateNewProps {
    className?: string;
    isThumb: boolean;
    isSharedFolderOnly?: boolean;
    onClick?: (e: React.MouseEvent) => void;
    onContextMenu?: (e: React.MouseEvent) => void;
    mod?: CreateNewMod;
    rootRef?: React.Ref<HTMLDivElement>;
    storage?: EStorageType;
}

export const DataListItemCreateNew: FC<DataListItemCreateNewProps> = ({
    className,
    onClick,
    onContextMenu,
    mod,
    isThumb,
    isSharedFolderOnly,
    storage,
}) => {
    const handleOnClick = useCallback(
        (e) => {
            const itemData = mod && CREATE_ITEM_DATA[mod];

            if (itemData?.onClick) {
                return itemData.onClick(e);
            }

            onClick?.(e);
        },
        [mod, onClick]
    );

    const handleOnContextMenu = useCallback(
        (e) => {
            const itemData = mod && CREATE_ITEM_DATA[mod];

            if (itemData?.onContextMenu) {
                itemData.onContextMenu(e);
                return;
            }

            onContextMenu?.(e);
        },
        [mod, onContextMenu]
    );

    const caption = useMemo(() => {
        const itemData = mod && CREATE_ITEM_DATA[mod];

        if (itemData?.text) {
            return itemData?.text;
        }

        if (isSharedFolderOnly) {
            return 'Создать общую папку';
        }

        if (storage === EStorageType.sharedAutodelete) {
            return IS_BIZ_USER ? 'Создать' : 'Создать папку';
        }

        return isThumb ? NEW_ITEM_CAPTION : NEW_ITEM_CAPTION.replace('\n', ' ');
    }, [isSharedFolderOnly, isThumb, mod, storage]);

    const itemData = mod && CREATE_ITEM_DATA[mod];
    const icon = itemData?.icon || isThumb ? <Icon24AddOutline /> : <Icon16AddSmall />;

    return (
        <button
            className={classNames(styles.root, className, {
                [styles[`mod_default`]]: !mod,
                [styles['root_thumb']]: isThumb,
            })}
            onClick={handleOnClick}
            onContextMenu={handleOnContextMenu}
        >
            <div className={styles.icon}>{icon}</div>
            <Text className={styles.text}>{caption}</Text>
        </button>
    );
};
