import classNames from 'clsx';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { noop } from 'reactApp/utils/helpers';
import { Ref } from 'semantic-ui-react';

import styles from './index.css';

export const withValidationMessageComponent = (BaseComponent) => {
    class Hoc extends Component {
        static propTypes = {
            fixForSemantic: PropTypes.bool,
            getReference: PropTypes.func,
            validationErrorMessage: PropTypes.string,
            error: PropTypes.bool,
            className: PropTypes.string,
        };

        static defaultProps = {
            fixForSemantic: true,
            getReference: noop,
        };

        innerRefHandler = (getReference) => (reference) => {
            const inputElement = reference && reference.querySelector('input');

            if (inputElement && typeof getReference === 'function') {
                return getReference(inputElement);
            }
        };

        render() {
            const { validationErrorMessage, getReference, fixForSemantic, className, error, ...props } = this.props;

            const errorClass = error ? styles.error_input : '';

            return (
                <div className={classNames(styles.wrapper, className)}>
                    <Ref innerRef={this.innerRefHandler(getReference)}>
                        <BaseComponent {...props} error={error} className={errorClass} />
                    </Ref>
                    {!!validationErrorMessage && (
                        <div
                            className={classNames({ [styles.error]: true, [styles.error_fix]: fixForSemantic })}
                            dangerouslySetInnerHTML={{ __html: this.props.validationErrorMessage }}
                        />
                    )}
                </div>
            );
        }
    }

    return Hoc;
};

export default withValidationMessageComponent;
