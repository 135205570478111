import { APICallV3Post } from 'reactApp/api/APICall';

/**
 * Общий тип ответа от API
 */
type CommonAPIResponceField = {
    size: number;
    name: string;
};

/**
 * Ответ от API
 */
export type APIResponse = CommonAPIResponceField & {
    home: string;
};

/**
 * Класс, конвертирующий документ с определенными расширения в doc-подобный или xls-подобный формат
 */
export class OvidiusCreateCopyOfNoneditableFileAPICall extends APICallV3Post<APIResponse> {
    _method = 'ovidius/xml/convert';
    _withCredentials = true;
}
