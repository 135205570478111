import axios, { AxiosInstance, AxiosResponse, CancelTokenSource, Method, ResponseType } from 'axios';
import { O2AuthClient, o2AuthClient } from 'reactApp/api/O2AuthClient';
import { UserNoAuthError } from 'reactApp/errors/serverErrors/UserNoAuthError';
import { noop } from 'reactApp/utils/helpers';

const CancelToken = axios.CancelToken;

type PromiseHandler = (value: any) => any;

interface IApiCallOptions {
    url?: string;
    onUploadProgress?(): void;
    headers?: Record<string, string> | null;
    signal?: AbortSignal;
}

type FulfilledHandler<TResult> = (value: AxiosResponse<TResult>) => AxiosResponse<TResult> | PromiseLike<AxiosResponse<TResult>>;

type RejectedHandler<TResult> = (reason: any) => TResult | PromiseLike<TResult>;

interface AbstractAPICall<T = any> {
    makeRequest: (
        params?: Record<string, any> | null,
        options?: IApiCallOptions | null,
        subQueryParams?: Record<string, any>
    ) => Promise<AxiosResponse<T>>;
}

class AbstractAPICall<T> implements AbstractAPICall<T> {
    _method: null | string = null;
    _baseUrl: string | undefined;
    _headers: Record<string, string> | null = null;
    _type: Method = 'get';

    _calcelToken: null | CancelTokenSource = null;
    _requestPromiseResolver: PromiseHandler = noop;
    _requestPromiseRejector: PromiseHandler = noop;
    _requestPromise: Promise<AxiosResponse<T>>;

    defaultParams: Record<string, any> = {};
    defaultData = {};

    _params: Record<string, any> | null = {};
    _options: IApiCallOptions | null = null;

    _responseType: ResponseType | undefined;

    version = 0;
    _retriesCount;

    _withCredentials = false;

    _errors: { tries: number }[] = [];

    _errorHandlers: ((error: any) => Promise<any>)[] = [];

    constructor() {
        this._calcelToken = CancelToken.source();

        this._requestPromise = new Promise((resolve, reject) => {
            this._requestPromiseResolver = resolve;
            this._requestPromiseRejector = reject;
        });
    }

    makeRequest = (...args: Parameters<AbstractAPICall['makeRequest']>) => {
        this._makeRequest(...args)
            .then(this._requestPromiseResolver)
            .catch(this._requestPromiseRejector);

        return this._requestPromise;
    };

    private _makeRequest = async (
        params = this._params,
        options: IApiCallOptions | null = this._options,
        subQueryParams: Record<string, any> = {}
    ) => {
        let queryParams: Record<string, any> = {};
        let body: Record<string, any> | null = null;
        let url: string | null = this._method;
        const signal = options?.signal;
        const onUploadProgress = options?.onUploadProgress;
        const optionHeaders = options?.headers ?? {};
        const headers = {
            ...this._headers,
            ...optionHeaders,
            ...O2AuthClient.prepareAuthHeader(await o2AuthClient.getToken()),
        };

        this._params = params;
        this._options = options;

        if (this._type === 'get') {
            queryParams = {
                ...this.defaultParams,
                ...params,
            };
        } else {
            queryParams = {
                ...this.defaultParams,
                ...subQueryParams,
            };

            if (
                params instanceof FormData ||
                params instanceof File ||
                params instanceof Blob ||
                params instanceof ReadableStream ||
                params instanceof ArrayBuffer
            ) {
                body = params;
            } else {
                body = params
                    ? {
                          ...this.defaultData,
                          ...params,
                      }
                    : null;
            }
        }

        if (options?.url) {
            url += options.url;
        }

        const instance = this._abstractGetAxiosInstance();

        if (instance === null) {
            throw new Error('AxiosInstance not found');
        }

        return instance
            .request<T>({
                baseURL: this._baseUrl,
                params: queryParams,
                data: body,
                headers,
                method: this._type,
                url: url?.toString(),
                cancelToken: this._calcelToken?.token,
                withCredentials: this._withCredentials,
                responseType: this._responseType,
                onUploadProgress,
                signal,
            })
            .catch(this.errorHandlersRunner);
    };

    private errorHandlersRunner = (error: any) => {
        let result = Promise.reject<any>(error);

        this._errorHandlers.forEach((errorHandler) => {
            result = result.catch((error) => {
                return errorHandler.call(this, error).then(
                    () => {
                        throw error;
                    },
                    () => {
                        throw error;
                    }
                );
            });
        });

        return result.catch((error) => {
            return this.retry(error);
        });
    };

    private getErrorInfo(error): { tries } {
        return this._errors.filter((errorInfo) => {
            return errorInfo.constructor === error.constructor;
        })[0];
    }

    private setErrorTries(error: any, tries: number) {
        let errorInfo = this.getErrorInfo(error);
        let availableTries;

        if (error.isRetryable) {
            availableTries = Math.min(tries, error.maxRetries);
        } else {
            availableTries = 0;
        }

        if (!errorInfo) {
            errorInfo = {
                // @ts-ignore
                constructor: error.constructor,
                tries: availableTries,
            };

            this._errors.push(errorInfo);
        } else {
            errorInfo.tries = availableTries;
        }

        return errorInfo.tries;
    }

    private getAvailableTries(error: any) {
        const errorInfo = this.getErrorInfo(error);

        if (typeof this._retriesCount !== 'undefined') {
            return this._retriesCount;
        }

        if (errorInfo) {
            return errorInfo.tries;
        }
        const tries = error.maxRetries;

        return this.setErrorTries(error, tries);
    }

    private retry(error: any) {
        const availableTries = this.getAvailableTries(error);
        let res;

        if (availableTries && (this._type === 'get' || error instanceof UserNoAuthError)) {
            this.setErrorTries(error, availableTries - 1);

            res = new Promise((resolve, reject) => {
                o2AuthClient.refreshToken();
                if (error.retryTimeout) {
                    setTimeout(() => {
                        this._makeRequest().then(resolve, reject);
                    }, error.retryTimeout);
                } else {
                    this._makeRequest().then(resolve, reject);
                }
            });
        } else {
            res = Promise.reject(error);
        }

        return res;
    }

    then = (onsuccess?: FulfilledHandler<T>, onfail?: RejectedHandler<T>) => {
        return this._requestPromise?.then(onsuccess, onfail);
    };

    catch = (onfail: RejectedHandler<T>) => {
        return this._requestPromise?.catch(onfail);
    };

    cancel = () => {
        this._calcelToken?.cancel('API Request canceled');
    };

    _abstractGetAxiosInstance(): AxiosInstance | null {
        return null;
    }
}

export { AbstractAPICall };
