import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import {
    familyDelete,
    familyLoadFailure,
    familyLoadRequest,
    familyLoadSuccess,
    familyUpdate,
    familyUpdateFailure,
    familyUpdateRequest,
    leaveFamily,
    loadingInviteLink,
    loadInviteLinkFailure,
    setFamilyPlan,
    setLoading,
    updateFamilyInviteLink,
} from './family.actions';
import { FamilyItem, FamilyLinkUpdatePayload, FamilyPlan, FamilyState, getStateWithErrorLoading } from './family.types';

const initialState: FamilyState = {
    list: {},
    isLoading: false,
    isLoaded: false,
    hasError: false,
    familyPlan: undefined,
    loadingLinkState: {
        isLoading: false,
        isLoaded: false,
        hasError: false,
    },
};

const familyUpdateState = (state: FamilyState) => {
    state.isLoading = true;
    state.isLoaded = false;
    state.hasError = false;
};

export const familyReducer = createReducer(initialState, {
    [familyLoadRequest.type]: familyUpdateState,
    [familyUpdateRequest.type]: familyUpdateState,
    [familyLoadSuccess.type]: (state, action: PayloadAction<FamilyItem[]>) => {
        const familyList = action.payload;

        familyList.forEach((item) => {
            state.list[item.family] = item;
        });

        state.isLoading = false;
        state.isLoaded = true;
        state.hasError = false;
    },
    [setFamilyPlan.type]: (state, action: PayloadAction<FamilyPlan>) => {
        state.familyPlan = action.payload;
        state.isLoading = false;
        state.isLoaded = true;
    },
    [familyLoadFailure.type]: getStateWithErrorLoading,
    [familyUpdateFailure.type]: getStateWithErrorLoading,
    [familyUpdate.type]: (state, action: PayloadAction<FamilyItem>) => {
        const familyItem = action.payload;

        state.list[familyItem.family] = Object.assign({}, familyItem);

        state.isLoading = false;
        state.isLoaded = true;
        state.hasError = false;

        state.loadingLinkState = {
            isLoading: false,
            isLoaded: true,
            hasError: false,
        };
    },

    [familyDelete.type]: (state) => {
        state.list = {};
    },
    [updateFamilyInviteLink.type]: (state, action: PayloadAction<FamilyLinkUpdatePayload>) => {
        const { link, family } = action.payload;

        state.list[family]['invite_link'] = link;
        state.loadingLinkState = {
            isLoading: false,
            isLoaded: true,
            hasError: false,
        };
    },
    [leaveFamily.type]: (state) => {
        state.list = {};
    },
    [loadingInviteLink.type]: (state) => {
        state.loadingLinkState = {
            isLoading: true,
            isLoaded: false,
            hasError: false,
        };
    },
    [loadInviteLinkFailure.type]: (state) => {
        state.loadingLinkState = {
            isLoading: false,
            isLoaded: true,
            hasError: true,
        };
    },
    [setLoading.type]: familyUpdateState,
});
