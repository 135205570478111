import { EmailStatus, State } from 'reactApp/modules/socialUser/socialUser.types';
import { createSelector } from 'reselect';

const getSocialUserStore = (state: any): State => state.socialUser;

export const getError = (state: any): string => getSocialUserStore(state).error;

export const getEmail = (state: any): string => getSocialUserStore(state).email;

export const getEmailStatus = (state: any): string => getSocialUserStore(state).status;

export const isEmailAbsent = createSelector(getEmailStatus, getEmail, (status, email) => status === EmailStatus.ABSENT || !email);

export const isEmailConfirming = createSelector(getEmailStatus, (status) => status === EmailStatus.CONFIRMING);

export const isEmailConfirmed = createSelector(getEmailStatus, (status) => status === EmailStatus.CONFIRMED);

export const getSocialLifeCycleState = createSelector(getSocialUserStore, (state) => ({
    isLoaded: state.isLoaded,
    isLoading: state.isLoading,
}));
