import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getListFullOrigin } from 'reactApp/modules/search/search.selectors';
import { SearchSourceTitle } from 'reactApp/modules/search/search.types';
import { ESearchOptionSource } from 'reactApp/ui/WebSearch/WebSearch.data';

import { ISearchSections } from '../MobileSearch.data';

export const useMobileSearchSections = (): ISearchSections => {
    const fullListOrigin = useSelector(getListFullOrigin);

    const sections = useMemo(() => {
        return [
            {
                title: SearchSourceTitle.filename,
                srch_src: ESearchOptionSource.name,
                items: fullListOrigin.filename,
                items_in_section: fullListOrigin.filename.slice(0, 5),
            },
            {
                title: SearchSourceTitle.text_content,
                srch_src: ESearchOptionSource.content,
                items: fullListOrigin.text_content,
                items_in_section: fullListOrigin.text_content.slice(0, 5),
            },
        ];
    }, [fullListOrigin]);

    const byOneSource = useMemo(() => {
        if (!fullListOrigin.text_content.length && fullListOrigin.filename.length) {
            return SearchSourceTitle.filename;
        }
        if (fullListOrigin.text_content.length && !fullListOrigin.filename.length) {
            return SearchSourceTitle.text_content;
        }
        return null;
    }, [fullListOrigin]);

    const noResults = useMemo(() => {
        return !fullListOrigin.filename.length && !fullListOrigin.text_content.length;
    }, [fullListOrigin]);

    return { byOneSource, noResults, sections };
};
