import image from 'img/plus32-from-spherum.png';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { renderBindingSpherum } from 'reactApp/components/BindingSpherum/BindingSpherum.helpers';
import { addPromoToShowQueue, promoShown, removePromo } from 'reactApp/modules/promo/promo.module';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { store } from 'reactApp/store';
import { put, select } from 'redux-saga/effects';
import { EQueryParams } from 'server/helpers/getRequestParams';

const PLUS_32_FROM_BIND_SPHERUM = 'plus-32-from-bind-spherum';

export function* initBindingSpherumPromoModal() {
    const params = getQueryParams();
    if (params && params?.bindSpherum === EQueryParams.bindSpherum) {
        yield put(
            addPromoToShowQueue({
                type: EPromoType.bindingSpherum,
                promoId: PLUS_32_FROM_BIND_SPHERUM,
                onShow: () => {
                    store.dispatch(promoShown(EPromoType.bindingSpherum));

                    const url = new URL(document.location.toString());
                    url.searchParams.delete(EQueryParams.bindSpherum);
                    window.history.replaceState({}, '', url.toString());
                },
                onClose: () => {
                    store.dispatch(removePromo(EPromoType.bindingSpherum));
                },
            })
        );
    }
}

export function* showBindingSpherumPromoModal() {
    const promo = PromoSelectors.getPromo(EPromoType.bindingSpherum)(yield select());

    if (promo) {
        const { onShow, onClose } = promo;

        yield renderBindingSpherum({
            title: 'Поздравляем! У вас +32 ГБ',
            text: 'Скоро мы активируем подарочные гигабайты, и вы сможете их использовать.',
            primaryButtonText: 'Хорошо',
            closeByPrimaryButton: true,
            imageUrl: image,
            onShow,
            onClose,
        });
    }
}
