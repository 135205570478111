import { Icon20Cancel } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { memo, ReactElement, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { resetAllSelectedItems } from 'reactApp/modules/albums/albums.actions';
import { resetGallery } from 'reactApp/modules/gallery/gallery.module';
import { searchReset } from 'reactApp/modules/search/search.module';
import { EmptyContent } from 'reactApp/sections/AlbumsPage/SelectFromCloudPopup/components/EmptyContent/EmptyContent';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';

import { SelectFromCloudContent } from './components/Content/SelectFromCloudContent';
import styles from './SelectFromCloudPopup.css';
import { IProps } from './SelectFromCloudPopup.types';

export const SelectFromCloudPopup = memo(function SelectFromCloudPopup(props: IProps): ReactElement | null {
    const { onClose, isEmpty } = props;
    const dispatch = useDispatch();

    const handleCancel = useCallback(() => {
        onClose();
        dispatch(resetGallery());
        dispatch(searchReset());
        dispatch(resetAllSelectedItems());
    }, [onClose, dispatch]);

    return (
        <Dialog open id="select-from-cloud" onCancel={handleCancel} className={styles.modal} closable={false}>
            <Content isModal qaId="select-from-cloud-content">
                <div
                    className={classNames(styles.root, {
                        [styles.rootEmpty]: isEmpty,
                    })}
                >
                    {isEmpty ? <EmptyContent onClose={onClose} /> : <SelectFromCloudContent {...props} />}
                    {isEmpty && (
                        <div className={styles.close} onClick={onClose}>
                            <Icon20Cancel />
                        </div>
                    )}
                </div>
            </Content>
        </Dialog>
    );
});
