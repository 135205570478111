import app from 'Cloud/Application/app';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { settingsController } from 'reactApp/modules/settings/settings.controller';
import { store } from 'reactApp/store';
import { sendGa } from 'reactApp/utils/ga';

const datalist = 'new';

export const sendDatalistGa = (action = '', label = '') => {
    let isHome;
    if (typeof app.isHome === 'function') {
        isHome = app.isHome();
    } else {
        isHome = getCurrentStorage(store.getState());
    }
    if (isHome) {
        sendGa(`datalist-${datalist}-${settingsController.getViewMode()}`, action, label);
    }
};
