import classNames from 'clsx';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendGa } from 'reactApp/components/SharingWindow/Sharing.helpers';
import { Contact, contactsSlice } from 'reactApp/modules/contacts/contacts.module';
import { contactsSelectors } from 'reactApp/modules/contacts/contacts.selectors';
import { setAccessRight } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.actions';
import { getError, isAccessControlListLoading } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.selectors';
import { Avatar } from 'reactApp/ui/Avatar/Avatar';
import { Button } from 'reactApp/ui/Button/Button';
import { InputSuggest } from 'reactApp/ui/InputSuggest/InputSuggest';
import { EInputSuggestTheme } from 'reactApp/ui/InputSuggest/InputSuggest.types';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './SharingNewAddUser.css';
import { useAccessRights } from './SharingNewAddUser.hooks';
import { IProps } from './SharingNewAddUser.types';

export const SharingNewAddUser = React.memo(function SharingNewAddUser(props: IProps): ReactElement {
    const { folderId = '', publicId, isPhone, isMounted } = props;
    const dispatch = useDispatch();
    const errorMessage = useSelector(getError);
    const contactSuggestions = useSelector(contactsSelectors.getContactSuggestions);
    const loading = useSelector(isAccessControlListLoading);
    const isInputDisabled = loading || isMounted;
    const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);
    const [currentEmail, setCurrentEmail] = useState<string>('');

    const [accessRight, renderDropdown] = useAccessRights(isInputDisabled);

    const handleLoginChange = useCallback((e, { value }) => {
        setCurrentEmail(value);
        setButtonDisabled(!value.length);
    }, []);

    const renderItem = useCallback((user: Contact, width = 0): ReactElement | null => {
        if (!user) {
            return null;
        }

        return (
            <div className={styles.user} data-qa-email={user.email} style={{ width }}>
                <div className={styles.avatar}>
                    <Avatar email={user.email} size={36} />
                </div>
                <div className={styles.info}>
                    {user.name && <div className={styles.name}>{user.name}</div>}
                    <div className={styles.email}>{user.email}</div>
                </div>
            </div>
        );
    }, []);

    const handleAddUser = useCallback(
        (event) => {
            dispatch(setAccessRight({ accessRight, folderId, email: currentEmail }));
            dispatch(contactsSlice.actions.listRequest());
            setCurrentEmail('');

            sendGa('add-user');
            sendPaymentGa({
                eventCategory: publicId ? ECategoryGa.public : ECategoryGa.sharedFolder,
                action: 'created-sharing',
                public_id: publicId,
            });

            event.preventDefault();
        },
        [dispatch, accessRight, folderId, currentEmail, publicId]
    );

    const handleGetSuggestions = useCallback(
        (filter) => {
            dispatch(contactsSlice.actions.suggestRequest(filter));
        },
        [dispatch]
    );

    const action = useMemo(() => <div className={styles.dropdown}>{renderDropdown()}</div>, [renderDropdown]);

    return (
        <form className={classNames(styles.root, { [styles.root_phone]: isPhone })}>
            <div className={styles.inputRow}>
                <div className={styles.inputField}>
                    <InputSuggest
                        value={currentEmail}
                        placeholder="Введите email"
                        hasError={Boolean(errorMessage)}
                        suggestions={contactSuggestions}
                        getSuggestionItemId={(item) => item.email}
                        onChange={handleLoginChange}
                        onRequestSuggestions={handleGetSuggestions}
                        disabled={isInputDisabled}
                        focus={false}
                        action={isPhone ? undefined : action}
                        theme={isPhone ? EInputSuggestTheme.vk : undefined}
                        renderSuggestionItem={renderItem}
                    />
                </div>
                <div className={styles.buttonWrapper}>
                    <div className={styles.action}>{isPhone ? action : undefined}</div>
                    <Button
                        theme={isPhone ? 'vk' : 'octavius'}
                        primary
                        middle
                        disabled={isInputDisabled || isButtonDisabled}
                        onClick={handleAddUser}
                    >
                        Добавить
                    </Button>
                </div>
            </div>
            {Boolean(errorMessage) && <div className={styles.errorMessage} dangerouslySetInnerHTML={{ __html: errorMessage }} />}
        </form>
    );
});
