import { Icon16ArrowLeftOutline, Icon16MoreVertical, Icon20Cancel } from '@vkontakte/icons';
import classNames from 'clsx';
import { ReactComponent as ShapeIcon } from 'img/icons/shape.svg';
import React, { memo, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { abRequiredSignUpWhenDownloadingTouchSelector } from 'reactApp/appHelpers/featuresHelpers';
import { FeatureSelector } from 'reactApp/appHelpers/featuresHelpers/FeatureSelector';
import { getFacesFileId } from 'reactApp/modules/faces/faces.helpers';
import { getIsViewerFileVerifiedByKaspersky } from 'reactApp/modules/file/utils';
import { RequiredSignUpTouchSource } from 'reactApp/modules/requiredAuthorization/constants';
import { requiredAuthorizationHelpers } from 'reactApp/modules/requiredAuthorization/helpers';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { RootState } from 'reactApp/store';
import { renderFilterFacesModal } from 'reactApp/ui/Mobile/FilterFacesModal/FilterFacesModal.helpers';
import { ProtectedTooltip } from 'reactApp/ui/Mobile/ProtectedTooltip/ProtectedTooltip';
import { DownloadTooltip } from 'reactApp/ui/ReactViewer/ViewerHeader/DownloadTooltip';

import { FacesTooltip } from './FacesTooltip/FacesTooltip';
import styles from './ViewerToolbar.css';

interface IViewerToolbarProps {
    onBack: (place) => void;
    ext: string;
    subTitle: string;
    nameWithoutExt: string;
    itemId?: string;
    faces?: string[];
    onClickOption: () => void;
    noReturn?: boolean;
}

export const ViewerToolbar = memo<IViewerToolbarProps>(
    ({ onBack, subTitle, ext, nameWithoutExt, itemId = '', faces = [], onClickOption, noReturn = false }): ReactElement => {
        const [showTootip, setShowTooltip] = useState(false);
        const counterText = faces && faces.length <= 9 ? faces.length : '9+';
        const facesButtonsRef = useRef<HTMLDivElement | null>(null);
        const storage = useSelector(getCurrentStorage);
        const isViewerFileVerifiedByKaspersky = useSelector(getIsViewerFileVerifiedByKaspersky);
        const [moreBtnRef, setMoreBtnRef] = useState<HTMLDivElement | null>(null);
        const isRequiredSingUpTouchSource = useSelector((state: RootState) =>
            requiredAuthorizationHelpers.isRequiredSingUpTouchSource(state, RequiredSignUpTouchSource.VIEWER_MORE_BTN, itemId)
        );

        const handleBack = useCallback(() => {
            onBack('click');
        }, [onBack]);

        const openFacesModal = useCallback(() => {
            const fileId = getFacesFileId(itemId);
            renderFilterFacesModal({ fileId });
        }, [itemId]);

        useEffect(() => {
            if (faces.length && facesButtonsRef?.current) {
                setShowTooltip(true);
            }
        }, [facesButtonsRef?.current, setShowTooltip, faces.length]);

        const requiredSingUpTooltip = <DownloadTooltip btnText="Скачать" downloadRef={moreBtnRef} closeOnOutsideClick />;

        return (
            <div className={styles.root}>
                <div className={styles.leftGroup}>
                    {!noReturn && (
                        <div className={classNames(styles.button, styles.button_back)} onClick={handleBack} data-qa-button={'back'}>
                            <Icon16ArrowLeftOutline width={16} height={20} fill="currentColor" />
                        </div>
                    )}
                    <div className={styles.info}>
                        <div className={styles.nameBlock}>
                            <div className={styles.name}>{nameWithoutExt}</div>
                            <div>.{ext}</div>
                            {storage === EStorageType.attaches && isViewerFileVerifiedByKaspersky && <ProtectedTooltip />}
                        </div>
                        <div className={styles.count}>{subTitle}</div>
                    </div>
                </div>
                <div className={styles.buttons}>
                    {Boolean(faces.length) && (
                        <>
                            <div
                                className={classNames(styles.button, styles.buttonFaces)}
                                onClick={openFacesModal}
                                data-qa-button={'faces'}
                                ref={facesButtonsRef}
                            >
                                <ShapeIcon width={20} height={20} />
                                <div className={styles.facesCount}>{counterText}</div>
                            </div>
                            {showTootip && <FacesTooltip onApply={openFacesModal} targetRef={facesButtonsRef} />}
                        </>
                    )}
                    {storage !== EStorageType.quotaCleaner && (
                        <>
                            <div ref={setMoreBtnRef} className={styles.button} onClick={onClickOption} data-qa-button={'options'}>
                                <Icon16MoreVertical width={20} height={20} fill="currentColor" />
                            </div>
                            {moreBtnRef && isRequiredSingUpTouchSource && (
                                <FeatureSelector
                                    selector={() => abRequiredSignUpWhenDownloadingTouchSelector(false)}
                                    control={<></>}
                                    variant1={requiredSingUpTooltip}
                                    variant2={requiredSingUpTooltip}
                                />
                            )}
                        </>
                    )}
                    {storage === EStorageType.quotaCleaner && (
                        <div className={styles.button_cancel} onClick={handleBack} data-qa-button={'back'}>
                            <Icon20Cancel fill="currentColor" />
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

ViewerToolbar.displayName = 'ViewerToolbar';
