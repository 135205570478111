import { xray } from 'lib/xray';
import React, { FC, useCallback, useEffect } from 'react';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { showAuth } from 'reactApp/utils/auth';
import { getSignUpUrl } from 'reactApp/utils/urlHelper';

import styles from './AuthDialog.css';
interface AuthDialogProps {
    onClose: () => void;
}

const radarName = 'auth_dlg';

export const AuthDialog: FC<AuthDialogProps> = ({ onClose }) => {
    const onSignIn = useCallback(() => {
        showAuth({});
        xray.send(`${radarName}_sign_in`);
    }, []);

    const onSignUp = useCallback(() => {
        window.open(getSignUpUrl(), '_self');
        xray.send(`${radarName}_sign_up`);
    }, []);

    const onCloseHandler = useCallback(() => {
        xray.send(`${radarName}_close`);
        onClose?.();
    }, [onClose]);

    useEffect(() => xray.send(`${radarName}_show`), []);

    return (
        <Dialog id="auth-dialog" size="small" open closable onCancel={onCloseHandler}>
            <div className={styles.root}>
                <div className={styles.title}>Войдите в Облако</div>
                <div className={styles.text}>Чтобы редактировать документы, войдите в Облако&nbsp;Mail.Ru или зарегистрируйтесь</div>
                <div className={styles.buttonWrapper}>
                    <Button theme="octavius" primary sizeMode={ButtonSizeMode.small} onClick={onSignIn}>
                        <span className={styles.button}>Войти</span>
                    </Button>
                    <div className={styles.space} />
                    <Button theme="octavius" sizeMode={ButtonSizeMode.small} onClick={onSignUp}>
                        <span className={styles.button}>Зарегистрироваться</span>
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};
