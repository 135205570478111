import api from 'Cloud/Application/api';
import { RestoreAllTrashbinAPICall } from 'reactApp/api/RestoreAllTrashbinAPICall';
import { RestoreStatusCheckAPICall } from 'reactApp/api/RestoreStatusCheckAPICall';
import { resetSelected } from 'reactApp/modules/selections/selection.saga';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { getLoadMoreLimit } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import {
    binClearRequest,
    binClearSuccess,
    toggleBin,
    trashbinLoad,
    trashbinLoadMoreRequest,
    trashbinLoadMoreSuccess,
    trashbinLoadSuccess,
    trashbinRestoreAllRequest,
    trashbinRestoreAllStart,
    trashbinRestoreAllUpdate,
    trashbinRestoreRequest,
    updateBinByIdSuccess,
} from 'reactApp/modules/trashbin/trashbin.module';
import { getBinById, getBinItemById, getRemovingState } from 'reactApp/modules/trashbin/trashbin.selectors';
import { LoadTrashbinSuccessAction, TrashbinApiResponse } from 'reactApp/modules/trashbin/trashbin.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { updateUser } from 'reactApp/modules/user/user.thunkActions';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';
import ping from 'reactApp/utils/ping';
import { all, call, cancel, put, select, takeEvery } from 'redux-saga/effects';

import { restoreFiles } from './trashbin.helpers';

const LOAD_LIMIT = getLoadMoreLimit(EStorageType.trashbin);
const restoreAllApiCall = new RestoreAllTrashbinAPICall().makeRequest;
const checkApiCall = () => new RestoreStatusCheckAPICall().makeRequest();

function* updateBin(id, limit, tree) {
    try {
        const trashbin = yield Promise.resolve(api.trashbin({ tree, limit }));
        yield put(
            updateBinByIdSuccess({
                id,
                bin: trashbin,
            })
        );
    } catch (error) {
        yield cancel();
    }
}

function* loadTrashbin() {
    try {
        const isBizUser = yield select(UserSelectors.isBizUser);
        const result: LoadTrashbinSuccessAction = [];
        const bins: {
            tree?: string;
            name: string;
            home: string;
            isDomain?: boolean;
        }[] = [
            {
                name: isBizUser ? 'Личные папки' : 'Облако',
                home: '/',
            },
        ];

        if (isBizUser) {
            const domainFolders = yield Promise.resolve(api.domain.folders());
            domainFolders
                .filter((folder) => !folder.mode || folder.mode === 'rw')
                .forEach((folder) => {
                    bins.push({
                        name: folder.name || folder.home.split('/').pop(),
                        home: folder.home,
                        tree: folder.tree,
                        isDomain: true,
                    });
                });
        }

        yield bins.reduce((promise, bin) => {
            return promise
                .then(() => api.trashbin({ tree: bin.tree, limit: LOAD_LIMIT }))
                .then((files: TrashbinApiResponse) => {
                    result.push({
                        ...files,
                        id: bin.home,
                        name: bin.name,
                        tree: bin.tree,
                        isDomain: !!bin.isDomain,
                    });
                });
        }, Promise.resolve());

        yield put(trashbinLoadSuccess(result));
    } catch (error) {
        yield cancel();
    }
}

function* restore(action) {
    const { ids, from } = action.payload;

    if (!ids?.length) {
        return;
    }

    try {
        const items = yield all(ids.map((id) => select(getBinItemById, id)));
        const bin = yield select(getBinById, items[0].parent);
        sendPaymentGa({
            eventCategory: ECategoryGa.basket,
            action: 'return',
            type_for: from,
            type_object: items.length === bin.count.all ? 'all' : (items.length === 1 ? 'one' : 'some'),
        });

        yield call(restoreFiles, { items, tree: bin.tree, isDomainBin: bin.isDomain });

        yield updateBin(bin.id, bin.childs.length, bin.tree);
        yield resetSelected();

        // @ts-ignore
        yield put(updateUser());
    } catch (error) {
        yield cancel();
    }
}

function* clearBin(action) {
    const { tree, id } = action.payload;
    const bin = yield select(getBinById, id);
    sendPaymentGa({ eventCategory: ECategoryGa.basket, action: 'clean_all', count_files: bin.count.all });

    try {
        yield Promise.resolve(api.trashbin.empty({ tree }));
        yield put(binClearSuccess({ id }));
        yield resetSelected();
    } catch (error) {
        yield put(
            showSnackbarAction({
                type: SnackbarTypes.failure,
                id: 'empty-trashbin',
                text: `Не удалось очистить Корзину`,
                closable: true,
            })
        );
        yield cancel();
    }
}

function* loadMore(action) {
    const { nextChunkRev, id, tree } = action.payload;

    try {
        const bin: TrashbinApiResponse = yield Promise.resolve(
            api.trashbin({
                tree,
                offset_revision: nextChunkRev,
                limit: LOAD_LIMIT,
            })
        );
        yield put(
            trashbinLoadMoreSuccess({
                id,
                bin,
            })
        );
    } catch (error) {
        yield cancel();
    }
}

function* checkRestoreStatus() {
    try {
        const initialState = yield select(getRemovingState);

        const { data } = yield ping({
            request: checkApiCall,
            check: ({ data }) => data && data.processing !== initialState,
        });

        if (data && data.code === 'OK') {
            yield put(
                trashbinRestoreAllUpdate({
                    status: Boolean(data && data.processing),
                })
            );
        }
    } catch (error) {
        yield cancel();
    }
}

function* restoreAll() {
    try {
        sendPaymentGa({ eventCategory: ECategoryGa.basket, action: 'return-all' });
        const { data } = yield call(restoreAllApiCall);
        if (data && data.code === 'OK') {
            yield put(trashbinRestoreAllStart());

            yield put(
                showSnackbarAction({
                    id: 'trashbin-restoring-all',
                    text: 'Файлы восстанавливаются. Мы уведомим, когда все восстановим',
                    closable: true,
                })
            );

            yield checkRestoreStatus();
            yield put(
                showSnackbarAction({
                    type: SnackbarTypes.success,
                    id: 'trashbin-restore-all',
                    text: 'Все файлы восстановлены',
                    closable: true,
                })
            );
        }
    } catch (error) {
        yield cancel();
    }
}

export function* watchTrashbin() {
    yield takeEvery(trashbinLoad.toString(), loadTrashbin);
    yield takeEvery(toggleBin.toString(), resetSelected);
    yield takeEvery(trashbinRestoreRequest.toString(), restore);
    yield takeEvery(binClearRequest.toString(), clearBin);
    yield takeEvery(trashbinLoadMoreRequest.toString(), loadMore);
    yield takeEvery(trashbinRestoreAllRequest.toString(), restoreAll);
}
