import React from 'react';
import { useIsWindowIntegrationSmall } from 'reactApp/hooks/useIsWindowIntegrationSmall';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import ContinueUpload from 'reactApp/ui/UploadBlock/ContinueUpload';
import { IUploadDropAreaProps, UploadDropArea } from 'reactApp/ui/UploadDropArea/UploadDropArea';
import { UploadListContainer } from 'reactApp/ui/UploadList/UploadList';

const newUploaderFeature = !EnvironmentSelectors.isPhone();

interface IProps extends IUploadDropAreaProps {
    showDropArea: boolean;
    allowDnd: boolean;
    containerElement?: HTMLDivElement;
}

export const UploadBlock = ({ showDropArea = true, allowDnd = true, ...restProps }: IProps) => {
    const isWindowIntegrationSmall = useIsWindowIntegrationSmall();

    return (
        <>
            {newUploaderFeature && <UploadListContainer allowUpload={true} isWindowIntegrationSmall={isWindowIntegrationSmall} />}
            {allowDnd && <UploadDropArea showDropArea={showDropArea} {...restProps} />}
            <ContinueUpload />
        </>
    );
};
