import { requestPayment } from 'Cloud/Application/Payment';
import { logger } from 'lib/logger';
import { EmergencySwaAPICall } from 'reactApp/api/EmergencySwaAPICall';
import { BILLING_ENABLED } from 'reactApp/appHelpers/configHelpers';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { renderEmergencySwaPopup, renderPromocodePopup } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog.helpers';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { updateUser } from 'reactApp/modules/user/user.thunkActions';
import { getQuotaFromTariff } from 'reactApp/utils/urlHelper';
import { channel } from 'redux-saga';
import { call, cancel, put, select, take } from 'redux-saga/effects';

export function* showEmergencySwaPopup() {
    let link = '';

    try {
        const { data } = yield call(new EmergencySwaAPICall().makeRequest);
        logger.verbose('EmergencySwa:', data);
        link = data.link;
    } catch (error) {
        logger.error(error);
        yield cancel();
    }

    const popupChannel = channel();

    yield renderEmergencySwaPopup({
        link,
        onClose: () => popupChannel.put(true),
    });

    yield take(popupChannel);
    yield popupChannel.close();

    yield closePopupHelper(popupNames.EMERGENCY_SWA);
}

export function* showPromocodePopup() {
    const promocodeChannel = channel();

    yield renderPromocodePopup({
        onSuccess: () => promocodeChannel.put(true),
        onClose: () => promocodeChannel.put(true),
    });

    yield take(promocodeChannel);
    yield promocodeChannel.close();

    yield closePopupHelper(popupNames.WHATS_NEW_DIALOG);

    // @ts-ignore
    yield put(updateUser());
}

export function* showRequestPayment() {
    const isB2BUser = yield select(UserSelectors.isB2BUser);
    const isFrozen = yield select(UserSelectors.isFrozen);
    const queryParams = getQueryParams() || {};
    const { action, tariff, tariffId, productId } = queryParams;
    let { quota } = queryParams;

    if (isB2BUser || isFrozen || !BILLING_ENABLED || action !== 'request-payment') {
        return;
    }

    if (tariff) {
        quota = getQuotaFromTariff(tariff);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield call(requestPayment, { tariff: tariffId || quota, productId });
}

