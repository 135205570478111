import api from 'Cloud/Application/api';
import { TreeFetch } from 'reactApp/modules/tree/tree.constants';

const request = () => ({
    type: TreeFetch.REQUEST,
});

const success = payload => ({
    type: TreeFetch.SUCCESS,
    payload,
});

const failure = error => ({
    type: TreeFetch.FAILURE,
    error,
});

export const loadTree = ({ weblink, home }) => dispatch => {
    dispatch(request());

    const params: any = {
        limit: 2000,
    };

    if (weblink) {
        params.weblink = weblink;
    } else {
        params.home = home;
    }

    return new Promise((resolve, reject) => {
        Promise.resolve(api.folder.tree(params))
            .then(payload => resolve(dispatch(success(payload))))
            .catch(error => reject(dispatch(failure(error))));
    });
};
