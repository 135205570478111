import React, { memo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closePopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { setUploadFromAction } from 'reactApp/modules/upload/upload.module';
import { getAllowedMimeTypes } from 'reactApp/modules/upload/upload.selectors';
import { useUploadInputHandlers } from 'reactApp/modules/uploading/hooks/useUploadInputHandlers';
import { setIsOpening } from 'reactApp/modules/viewer/viewer.module';

import styles from './UploadInput.css';

export const UploadInput = memo(({ inputName, onClick }: { inputName: string; onClick?(): void }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const allowedMimeTypes = useSelector(getAllowedMimeTypes)?.join(',');

    useUploadInputHandlers({ input: inputRef });

    const handleClick = useCallback(() => {
        dispatch(setUploadFromAction(inputName));
        onClick?.();

        if (inputRef.current) {
            // Надо сбросить, так как если предыдущий файл был такой же, то input не бросит событие для старта загрузки
            inputRef.current.value = '';
        }
    }, [dispatch, inputName, onClick]);

    const onChange = useCallback(() => {
        dispatch(setIsOpening(false));
        closePopupHelper(popupNames.MOBILE_OPTIONS);
    }, [dispatch]);

    return (
        <div className={styles.root} onClick={handleClick}>
            <input type="file" accept={allowedMimeTypes} multiple className={styles.input} ref={inputRef} onChange={onChange} />
        </div>
    );
});

UploadInput.displayName = 'UploadInput';
