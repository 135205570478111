import { xray } from 'lib/xray';
import { PDFConvertAttachAPICall } from 'reactApp/api/PDFConvertAttachAPICall';
import { PDFConverterAPICall } from 'reactApp/api/PDFConverterAPICall';
import { getExtension } from 'reactApp/modules/file/utils';
import type { OvidiusApiError } from 'reactApp/modules/ovidius/types';
import { CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import { downloadAttach, getAttachType } from 'reactApp/utils/downloadDocumentHelpers';

/**
 * Показывает докукмент через Ovidius
 * Поддерживает аттачи + облачные файлы
 */
export const convertToPdf = async (file: CloudFile): Promise<{ content?: ArrayBuffer | null; error?: OvidiusApiError }> => {
    const { ext, name } = file;
    const extension = ext || getExtension(name);
    const storage = file.storage === EStorageType.public ? (file.storage as EStorageType) : EStorageType.home;
    const prefix = storage?.replace(/\//g, '_');
    const attachType = getAttachType(file);

    xray.send(`ovidius_convert_${attachType ? attachType : prefix}_${extension}`);

    try {
        if (attachType) {
            const { content, error } = await downloadAttach(file);
            if (error || !content) {
                throw error;
            }

            const { data } = await new PDFConvertAttachAPICall().makeRequest(content, null, { filename: file.name });
            xray.send(`ovidius_convert_success_${attachType}_${extension}`);

            return { content: data };
        }

        const { data: content } = await new PDFConverterAPICall().makeRequest({ [storage]: file.id });
        xray.send(`ovidius_convert_success_${prefix}_${extension}`);

        return { content };
    } catch (error: any) {
        xray.send(`ovidius_convert_error_${attachType ? attachType : prefix}_${extension}`);

        return { error };
    }
};
