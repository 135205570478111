import { Caption, Spacing, Text, Title } from '@vkontakte/vkui';
import trialMonthCover from 'img/subscriptions/trial-month-mobile.jpg';
import trialMonthCoverDark from 'img/subscriptions/trial-month-mobile-dark.jpg';
import trialTwoMonthsCover from 'img/subscriptions/trial-two-months-mobile.jpg';
import trialTwoMonthsCoverDark from 'img/subscriptions/trial-two-months-mobile-dark.jpg';
import React, { memo, ReactElement, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { cancelRenewTrialPeriod } from 'reactApp/appHelpers/featuresHelpers';
import { VK_UI_DARK_THEME } from 'reactApp/constants';
import { BuySubscriptionActions } from 'reactApp/modules/buySubscription/buySubscription.module';
import {
    ESubscriptionModalAction,
    useSendSubscriptionModalAnalytics,
} from 'reactApp/sections/SubscriptionsPage/ui/SubscriptionModal/SubscriptionModal.analytics';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { getPeriodName } from 'reactApp/utils/Period';

import styles from './CancelAutoRenewTrialModalMobile.css';
import { CancelAutoRenewTrialModalMobileProps } from './CancelAutoRenewTrialModalMobile.types';

export const CancelAutoRenewTrialModalMobile = memo<CancelAutoRenewTrialModalMobileProps>(({ onClose, subscription }): ReactElement => {
    const dispatch = useDispatch();
    const scrollRef = useRef<HTMLDivElement>(null);
    const sendAnalytics = useSendSubscriptionModalAnalytics(subscription);
    const isDarkMode = document.body.classList.contains(VK_UI_DARK_THEME);
    const oneMonthImage = isDarkMode ? trialMonthCoverDark : trialMonthCover;
    const twoMonthsImage = isDarkMode ? trialTwoMonthsCoverDark : trialTwoMonthsCover;
    const src = cancelRenewTrialPeriod === '1m' ? oneMonthImage : twoMonthsImage;

    useEffect(() => {
        sendAnalytics(ESubscriptionModalAction.showTrialSubs);
    }, []);

    const handleSaveSubscriptionTrial = useCallback(() => {
        sendAnalytics(ESubscriptionModalAction.trialCancelSubsSave);
        dispatch(BuySubscriptionActions.cancelAutorenewAddTrial({ subscriptionId: subscription.id, period: cancelRenewTrialPeriod }));
    }, [dispatch, subscription, sendAnalytics]);

    const handleCancelAutorenew = useCallback(() => {
        sendAnalytics(ESubscriptionModalAction.trialCancelSubsCancel);
        dispatch(BuySubscriptionActions.cancelAutorenewSubscription({ subscriptionId: subscription.id }));
    }, [dispatch, subscription, sendAnalytics]);

    const handleClose = useCallback(
        (e) => {
            onClose?.(e);
        },
        [onClose]
    );

    return (
        <MobileDialog
            id="mobile-cancel-auto-renewal-trial-modal"
            onClose={handleClose}
            closeOnDimmerClick
            topmost
            scrollRef={scrollRef}
            mod="base"
            contextPadding="zero"
        >
            <div ref={scrollRef} className={styles.root}>
                <img src={src} alt={`${getPeriodName(cancelRenewTrialPeriod)} подписки — бесплатно`} className={styles.image} />
                <Spacing size={24} />
                <Title level="1" className={styles.title}>
                    {getPeriodName(cancelRenewTrialPeriod)} подписки — бесплатно
                </Title>
                <Spacing size={8} />
                <Text className={styles.text}>
                    Сохраните {subscription.space.value} и все преимущества Mail Space на {getPeriodName(cancelRenewTrialPeriod)} бесплатно
                </Text>
                <Spacing size={8} />
                <Caption className={styles.caption}>После бесплатного периода подписка продлится автоматически</Caption>
                <Spacing size={24} />
                <div className={styles.butttonWrapper}>
                    <Button
                        theme="vk"
                        primary
                        onClick={handleSaveSubscriptionTrial}
                        sizeMode={ButtonSizeMode.big}
                        className={styles.button}
                    >
                        Сохранить {subscription.space.value}
                    </Button>
                    <Spacing size={12} />
                    <Button theme="vk" onClick={handleCancelAutorenew} sizeMode={ButtonSizeMode.big} className={styles.button}>
                        Отключить
                    </Button>
                </div>
            </div>
        </MobileDialog>
    );
});

CancelAutoRenewTrialModalMobile.displayName = 'CancelAutoRenewTrialModalMobile';
