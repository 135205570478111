import classNames from 'clsx';
import { ReactComponent as ShiftIcon } from 'img/icons/shiftGlyph.svg';
import React, { Fragment, memo, ReactElement } from 'react';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';

import styles from './HotKeyTip.css';

const isMacVal = EnvironmentSelectors.isMac();

const glyphRender = (item, isMac) =>
    item === 'Shift' && isMac ? (
        <div className={styles.glyph}>
            <ShiftIcon />
        </div>
    ) : (
        <span>{item}</span>
    );

export const HotKeyTip = memo(
    ({ text, isMac = isMacVal }: { text: string; isMac?: boolean }): ReactElement => {
    const array = text.split('+');

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles.root_win]: !isMac,
            })}
        >
            {array.map((item, idx) => (
                <Fragment key={`tip-${idx}`}>
                    {glyphRender(item, isMac)}
                    {!isMac && idx < array.length - 1 && <span className={styles.plus}>+</span>}
                </Fragment>
            ))}
        </div>
    );
    }
);

HotKeyTip.displayName = 'HotKeyTip';
