export enum EDialogsType {
    paid = 'paid',
    rewrite = 'rewrite',
    copy = 'copy',
}

export interface IPaidFeatureProps {
    onClose: () => void;
    onOpenTariffs: () => void;
}

export interface IRecoveryProps {
    onClose: () => void;
    type: EDialogsType;
    fileName: string;
    onSuccess: (isRewrite: boolean) => void;
}

export interface IFileHistoryItemProps {
    id: string;
    onPaidInfo: () => void;
}

export interface IFileHistoryListProps {
    itemId: string;
    onPaidInfo: () => void;
}

export interface IFileHistoryProps {
    itemId: string;
    onClose: () => void;
}
