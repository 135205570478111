import React, { FC, ReactNode, useCallback, useContext, useRef } from 'react';
import { generateUuidV4, noop } from 'reactApp/utils/helpers';

import { IProps as TooltipProps } from './Tooltip';
import { tooltipPlacements } from './Tooltip.constants';
import { TooltipContext } from './Tooltip.context';
import { ID } from './Tooltip.types';

type ChildrenWithExtraProps<P> = P & { children?: ReactNode | FC<{ onClose: () => void }> };

interface UseTooltipProps {
    /** Идентификатор конкретного вида тултипов(если задан, то одновременно будет отображаться один тултип с данным ID) */
    id?: ID;
    /** Пропсы компонента */
    defaultProps: Partial<ChildrenWithExtraProps<TooltipProps>>;
}

export const useTooltip = ({ id: controlledId, defaultProps = {} }: UseTooltipProps) => {
    const tooltipContext = useContext(TooltipContext);

    const uid = useRef(generateUuidV4());

    const handleClose = useCallback(() => {
        tooltipContext.close(uid.current);
    }, [tooltipContext]);

    const handleOpen = useCallback(() => {
        let children = defaultProps.children;

        if (!React.isValidElement(defaultProps.children) && typeof children === 'function') {
            /** Компонент Tooltip хочет children как ReactNode */
            children = children({
                onClose: handleClose,
            });
        }

        const initialProps = {
            children,
            placement: tooltipPlacements.BOTTOM,
            onClose: noop,
            target: null,
            smartPlacement: true,
        };

        const fullProps = Object.assign(initialProps, defaultProps, {
            children,
        });

        tooltipContext.open(uid.current, fullProps, controlledId);
    }, [controlledId, defaultProps, handleClose, tooltipContext]);

    return {
        open: handleOpen,
        close: handleClose,
    };
};
