import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { chooseVariant } from 'reactApp/modules/features/features.helpers';
import { getFeatureNewContentTable } from 'reactApp/modules/features/features.selectors';
import { FileType } from 'reactApp/types/FileType';

interface Params {
    isFolder?: boolean;
    ext?: string /* TODO: нужно менять тип на FileType */;
    kind?: string;
    isVirus?: boolean;
    isAutoDelete?: boolean;
    isPublic?: boolean;
    ignoreFolderType?: boolean;
    publicExpires?: number;
}

export const getIconType = ({
    isFolder,
    ext,
    kind,
    isVirus,
    isAutoDelete,
    isPublic,
    ignoreFolderType,
    publicExpires,
}: Params): FileType => {
    if (isVirus) {
        return FileType.VIRUS;
    }

    if (isFolder) {
        if (ignoreFolderType) {
            return FileType.FOLDER;
        }

        if (isAutoDelete) {
            if (typeof publicExpires === 'number') {
                return FileType.FOLDER_AUTODELETE_WEEK;
            }

            return FileType.FOLDER_AUTODELETE;
        }

        if (
            /* tempexp_14729-next-line */
            chooseVariant(getFeatureNewContentTable, {
                control: kind === 'mounted' || (IS_BIZ_USER && kind === 'domain-folder') || (kind === 'shared' && !isPublic),
                variant1: kind === 'mounted' || kind === 'domain-folder' || (kind === 'shared' && !isPublic),
            })()
        ) {
            return FileType.FOLDER_MOUNTED;
        }

        if (
            /* tempexp_14729-next-line */
            chooseVariant(getFeatureNewContentTable, {
                control: kind === 'shared',
                variant1: false,
            })()
        ) {
            return FileType.FOLDER_SHARED;
        }

        if (kind === 'camera-upload') {
            return FileType.FOLDER_CAMERA;
        }

        return FileType.FOLDER;
    }

    return (ext?.toLocaleLowerCase() as FileType) /* TODO: нужно менять тип на FileType */ || FileType.UNKNOWN;
};
