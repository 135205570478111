import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';

interface IContent {
    header: string;
    description: ReactElement;
}

export const useContent = (item): IContent => {
    const isProUser = useSelector(UserSelectors.isProUser);
    const isCorpUser = useSelector(UserSelectors.isCorpUser);

    const header = `История файла «${item?.name}»`;

    const description = (
        <>
            Вы можете восстановить любую из представленных ниже версий файла.
            <br />
            Доступны версии файла за последние {isCorpUser || isProUser ? 60 : 30} дней
        </>
    );

    return { header, description };
};
