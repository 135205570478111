/* eslint-disable max-lines-per-function */
import { ReactComponent as LinkIcon } from '@vkontakte/icons/src/svg/16/linked_16.svg';
import config from 'Cloud/config';
import React, { ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_FREE_BIZ_SAAS_USER } from 'reactApp/appHelpers/configHelpers';
import { renderBizPaidEditorDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog.helpers';
import { SharingNewDropdown } from 'reactApp/components/SharingNewBiz/Dropdown/SharingNewDropdown';
import {
    ACCESS_FILE_RIGHTS_OPTIONS,
    ACCESS_FOLDER_RIGHTS_OPTIONS,
    QA_VALUE,
    TOOLTIP_OFFSET,
} from 'reactApp/components/SharingNewBiz/SharingNew.constants';
import { sendGa } from 'reactApp/components/SharingNewBiz/SharingNew.helpers';
import { AccessStatus } from 'reactApp/components/SharingNewBiz/SharingNew.types';
import styles from 'reactApp/components/SharingNewBiz/Weblink/SharingNewWeblink.css';
import { getAvailableConfiguredEditorsFor, getEditorsFor, getIsFileForCoEditing } from 'reactApp/modules/editor/editor.selectors';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import {
    resetWeblinkCountDownloads,
    toggleWeblinkDownloadable,
    toggleWeblinkDownloadableRequest,
    toggleWeblinkEditableRequest,
} from 'reactApp/modules/modifying/modifying.actions';
import { PublishItem } from 'reactApp/modules/modifying/modifying.types';
import { getItemById, isMountedOrSharedFolder } from 'reactApp/modules/storage/storage.selectors';
import { RootState } from 'reactApp/store';
import { AccessRights } from 'reactApp/types/Tree';
import { Dropdown } from 'reactApp/ui/Dropdown/Dropdown';
import { DropdownMenu } from 'reactApp/ui/Dropdown/DropdownMenu';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

const IS_FREE_B2B_BIZ_EDITORS_USER = config.get('FREE_B2B_BIZ_EDITORS_USER');

const getAccessRights = (isReadOnly, isFolder, isPublicReadWrite, isDownloadable): AccessStatus => {
    return isDownloadable
        ? AccessStatus.Download
        : (isPublicReadWrite && !isReadOnly && (isFolder ? AccessStatus.Upload : AccessStatus.Edit)) || AccessStatus.ReadOnly;
};

interface Props {
    item?: PublishItem;
    isSharedOrMouted: ReturnType<typeof isMountedOrSharedFolder>;
}

export const AccessRightsDropdown: React.FC<Props> = ({ item, isSharedOrMouted }) => {
    const dispatch = useDispatch();
    const isPhone = EnvironmentSelectors.isPhone();
    const isFolder = item?.isFolder;
    const isDownloadable = item?.weblinkDownloadable;
    const isFileForCoEditing = useSelector((state: RootState) => getIsFileForCoEditing(state, item));
    const isDocumentForEdit = item?.kind === 'document' && IS_FREE_B2B_BIZ_EDITORS_USER ? true : isFileForCoEditing;
    const isPublic = Boolean(item?.weblink);
    const parent = useSelector((state: RootState) => getItemById(state, item?.parent || '/'));
    const isReadOnlyItem = item?.isReadOnly;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isReadOnlyParent = parent?.isReadOnly;
    const isReadOnly = !isReadOnlyItem && isSharedOrMouted.isMounted ? isReadOnlyParent : isReadOnlyItem;

    const readOnlyOption = { [AccessStatus.ReadOnly]: ACCESS_FILE_RIGHTS_OPTIONS.ReadOnly };
    const folderOptions = ACCESS_FOLDER_RIGHTS_OPTIONS;
    const fileOptions = IS_FREE_BIZ_SAAS_USER ? readOnlyOption : ACCESS_FILE_RIGHTS_OPTIONS;

    const options = isFolder ? folderOptions : fileOptions;

    const isRO = isFolder && isReadOnly;
    const isPublicReadWrite = item?.weblinkAccessRights === AccessRights.rw;
    const accessRights = getAccessRights(isReadOnly, isFolder, isPublicReadWrite, isDownloadable);

    const isEditable = useSelector((state: RootState) => getEditorsFor(state, item))?.length > 0;
    const isItemHaveConfiguredEditors = useSelector((state: RootState) => item && getAvailableConfiguredEditorsFor(state, item).length);

    const mapId2QaValue = useCallback(
        (id) => {
            let qaValue = id;
            if (isFolder && id === AccessStatus.Upload) {
                qaValue = AccessStatus.Edit;
            } else if (isFolder && id === AccessStatus.Download) {
                qaValue = AccessStatus.Upload;
            }
            return qaValue;
        },
        [isFolder]
    );

    const renderAccessRightsDropdown = useCallback(
        (onClick: void): ReactElement => {
            const list = Object.values(options).map((item) => {
                const qaValue = mapId2QaValue(item.id);
                return {
                    id: item.id,
                    text: item.text,
                    check: item.id === accessRights,
                    qaValue,
                };
            });

            const needOmitEdit = ((!isDocumentForEdit || !isItemHaveConfiguredEditors) && !isFolder) || isReadOnlyParent;
            if (needOmitEdit) {
                const editItemIdx = list.findIndex((item) => item.id === AccessStatus.Edit);
                list.splice(editItemIdx, 1);
            }

            return <DropdownMenu list={list} theme="octavius" onClick={onClick} className={styles.dropdownMenu} />;
        },
        [options, isDocumentForEdit, isItemHaveConfiguredEditors, isFolder, isReadOnlyParent, mapId2QaValue, accessRights]
    );

    const handleAccessRightsChange = useCallback(
        (id: AccessStatus): void => {
            if (!isPublic || accessRights === id) {
                return;
            }

            if (item) {
                sendGa('access-rights');
                sendPaymentGa({
                    eventCategory: ECategoryGa.public,
                    action: 'edit-type-access',
                    type_access: id,
                    public_id: item?.weblink,
                });

                const changeDownloadableState = () => {
                    if (item.weblinkDownloadable) {
                        dispatch(toggleWeblinkDownloadable({ id: item.id }));
                    }

                    if (item.count_downloads_total) {
                        dispatch(resetWeblinkCountDownloads({ id: item.id }));
                    }
                };

                if (IS_FREE_B2B_BIZ_EDITORS_USER && !isEditable && isReadOnly) {
                    renderBizPaidEditorDialog();
                    return;
                }

                if (id === AccessStatus.Download) {
                    dispatch(toggleWeblinkDownloadableRequest({ item }));
                    return;
                }

                dispatch(toggleWeblinkEditableRequest({ item, id }));
                changeDownloadableState();
            }
        },
        [accessRights, dispatch, isEditable, isPublic, isReadOnly, item]
    );

    const renderAccessRights = useCallback((): ReactElement => {
        const item = options[accessRights];
        const qaValue = mapId2QaValue(item?.id);
        return (
            <div className={styles.rightsItem} data-qa-item={QA_VALUE.accessItem}>
                <div className={styles.titleWrapper}>
                    <span className={styles.icon}>
                        <LinkIcon />
                    </span>
                    <span className={styles.rightsTitle}>Права пользователей по ссылке</span>
                </div>
                <div className={styles.dropdown}>
                    <Dropdown
                        withinDialog
                        theme="octavius"
                        qaValue={qaValue}
                        as={SharingNewDropdown}
                        value={item?.text}
                        content={renderAccessRightsDropdown}
                        onClickItem={handleAccessRightsChange}
                        placement={tooltipPlacements.BOTTOM_LEFT}
                        qaValueContent={QA_VALUE.accessDropdownContent}
                        tooltipOffset={TOOLTIP_OFFSET}
                        animatedTooltip
                        description="Права пользователей по ссылке"
                        tooltipClassName={isPhone ? styles.tooltip : undefined}
                        isPhone={isPhone}
                    />
                </div>
            </div>
        );
    }, [accessRights, handleAccessRightsChange, isPhone, options, renderAccessRightsDropdown]);

    if (isRO) {
        return null;
    }

    return renderAccessRights();
};
