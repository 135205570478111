import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import {
    createDocumentByLinkDialogAnalytics,
    sendCreateDocumentByLinkDialogRadar,
} from 'reactApp/components/CreateDocumentByLinkDialog/createDocumentByLinkDialog.analytics';
import {
    CreateDocumentQueryAction,
    isCreateDocumentQueryAction,
} from 'reactApp/components/CreateDocumentByLinkDialog/createDocumentByLinkDialog.type';
import { CREATE_DOCUMENT_BY_LINK } from 'reactApp/modules/features/features.helpers';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { store as reduxStore } from 'reactApp/store';
import { openDisabledFeaturePopupHelper } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.helpers';
import { DisabledFeature } from 'reactApp/ui/DisabledFeatureDialog/DisabledFeatureDialog.types';

const documentFormatByAction = new Map<CreateDocumentQueryAction, string>()
    .set(CreateDocumentQueryAction.CREATE_DOCX, 'docx')
    .set(CreateDocumentQueryAction.CREATE_XLSX, 'xlsx');

export const createDocumentByLinkFeature = {
    header: 'Создать документ?',
    content: 'В вашем Облаке откроется новый документ',
    ...CREATE_DOCUMENT_BY_LINK,
};

const createDocumentByQueryAction = (format: string) => toolbarActions.create(format, 'external-link', false);

export const renderCreateDocumentByQueryActionDialog = () => {
    const state = reduxStore.getState();
    const { action: queryAction, isFromExternal } = SettingsSelectors.getQueryParams(state);
    const { isFull: isOverQuota } = UserSelectors.getCloudSpace(state);
    const format = isCreateDocumentQueryAction(queryAction) ? documentFormatByAction.get(queryAction) : undefined;

    /* CLOUDWEB-14757: после принятия лицензии нужно открыть новый документ */
    if (format) {
        if (isOverQuota) {
            return openDisabledFeaturePopupHelper({ disabledFeature: DisabledFeature.newFile });
        }

        openPopupHelper({
            popupName: popupNames.CREATE_NEW_DOCUMENT_BY_LINK,
            data: {
                onSuccess: () => {
                    createDocumentByQueryAction(format);
                    sendCreateDocumentByLinkDialogRadar({
                        i: createDocumentByLinkDialogAnalytics.LINK_CREATE_DOC,
                        isLlmOpen: Boolean(isFromExternal),
                    });
                },
                isFromExternal,
            },
        });
    }
};
