import hotkeys from 'hotkeys-js';
import { useEffect } from 'react';

/**
 * Хук для управления hotkeys.setScope в момент когда scope актуален
 * Должен совпадать с жизненным циклом компонента в котором используется scope
 * @param scopeName
 */
export const useHotKeyScope = (scopeName: string) => {
    useEffect(() => {
        const previousScope = hotkeys.getScope();

        hotkeys.setScope(scopeName);
        return () => {
            const scopeNotChanged = scopeName === hotkeys.getScope();

            hotkeys.deleteScope(scopeName);

            if (previousScope && scopeNotChanged) {
                hotkeys.setScope(previousScope);
            }
        };
    }, [scopeName]);
};
