import { PayloadAction } from '@reduxjs/toolkit';
import { IS_PHONE_BROWSER, IS_PUBLIC_ALBUM } from 'reactApp/appHelpers/configHelpers';
import { normalizePublicId } from 'reactApp/modules/public/public.helpers';
import { changeHistory, historyPush, setHistoryReplace } from 'reactApp/modules/router/router.module';
import { getCurrentStorage, selectHistoryPath } from 'reactApp/modules/router/router.selectors';
import { IHistoryPush } from 'reactApp/modules/router/router.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { put, select, takeEvery } from 'redux-saga/effects';

function* historyPushSaga(action: PayloadAction<IHistoryPush>) {
    const { id, search = '', hash = '', replace = false } = action.payload;

    const storage = yield select(getCurrentStorage);
    const path = yield select(selectHistoryPath);

    if (storage === EStorageType.public || storage === EStorageType.stock) {
        const storageNew = IS_PUBLIC_ALBUM && !IS_PHONE_BROWSER ? '/album/' : `/${storage}/`;

        const newId = `${storageNew}${normalizePublicId(id)}${search}${hash && `#${hash}`}`;

        if (newId === path) {
            return;
        }

        yield put(changeHistory(newId));
        return;
    }

    yield put(setHistoryReplace(replace));
    yield put(changeHistory(id + search + (hash && `#${hash}`)));
}

export function* watchRouterRoot() {
    yield takeEvery(historyPush.toString(), historyPushSaga);
}
