export enum EPromocodeApiErrorCodes {
    invalid = 'invalid',
    finished = 'finished',
    alien = 'alien',
    exists = 'exists',
    activated = 'activated',
    ratelimit = 'ratelimit',
    system = 'system',
    required = 'required',
    platform = 'platform',
    internal = 'internal',
    not_new_user = 'not_new_user',
}

export const errors = {
    [EPromocodeApiErrorCodes.invalid]: 'Промокод невалидный или промоакция еще не началась.',
    [EPromocodeApiErrorCodes.finished]: 'Промоакция уже завершилась.',
    [EPromocodeApiErrorCodes.alien]: 'Промокод активирован другим пользователем.',
    [EPromocodeApiErrorCodes.exists]: 'Данный юзер уже принимает участие в акции.',
    [EPromocodeApiErrorCodes.activated]: 'Промокод уже активирован вами.',
    [EPromocodeApiErrorCodes.ratelimit]: 'Слишком много неудачных попыток активации, попробуйте позже.',
    [EPromocodeApiErrorCodes.internal]: 'Ошибка сервера, попробуйте позже.',
    [EPromocodeApiErrorCodes.system]: 'Активация промокодов временно недоступна, попробуйте позже.',
    [EPromocodeApiErrorCodes.required]: 'Введите промокод.',
    [EPromocodeApiErrorCodes.platform]: 'Нельзя активировать промокод на данной платформе.',
    [EPromocodeApiErrorCodes.not_new_user]: 'Акция недоступна.',
};

export const errorDescriptions = {
    [EPromocodeApiErrorCodes.invalid]: 'Возможно, вы неправильно ввели его, или данная акция ещё не началась.',
    [EPromocodeApiErrorCodes.finished]: 'Но впереди много новых! А пока вы можете найти что-то подходящее среди наших тарифов.',
    [EPromocodeApiErrorCodes.alien]: 'Попробуйте применить другой!',
    [EPromocodeApiErrorCodes.exists]: 'Хотите посмотреть другие предложения?',
    [EPromocodeApiErrorCodes.activated]: 'Вы уже получали скидку по этому промокоду. Второй раз не получится!',
    [EPromocodeApiErrorCodes.ratelimit]: 'Слишком много неудачных попыток активации промокода.',
    [EPromocodeApiErrorCodes.internal]: 'Мы уже разбираемся с проблемой. Попробуйте активировать промокод чуть позже.',
    [EPromocodeApiErrorCodes.system]: 'Мы уже разбираемся с проблемой. Попробуйте активировать промокод чуть позже.',
    [EPromocodeApiErrorCodes.required]: 'Пожалуйста, введите промокод и попробуйте ещё раз!',
    [EPromocodeApiErrorCodes.platform]: 'К сожалению, применить его в веб-версии Облака невозможно.',
    [EPromocodeApiErrorCodes.not_new_user]:
        'К сожалению, вы не можете активировать данный промокод, так как ранее уже применяли промокоды или покупали тарифы в Облаке.',
};

export const errorTitles = {
    [EPromocodeApiErrorCodes.invalid]: 'Промокод не работает',
    [EPromocodeApiErrorCodes.finished]: 'Акция уже закончилась',
    [EPromocodeApiErrorCodes.alien]: 'Промокод уже кем-то активирован',
    [EPromocodeApiErrorCodes.exists]: 'Вы уже участвуете в этой акции',
    [EPromocodeApiErrorCodes.activated]: 'Промокод уже активирован',
    [EPromocodeApiErrorCodes.ratelimit]: 'Попробуйте чуть позже',
    [EPromocodeApiErrorCodes.system]: 'Что-то пошло не так',
    [EPromocodeApiErrorCodes.internal]: 'Что-то пошло не так',
    [EPromocodeApiErrorCodes.required]: 'Промокод не введен',
    [EPromocodeApiErrorCodes.platform]: 'Активируйте промокод в приложении',
    [EPromocodeApiErrorCodes.not_new_user]: 'Активация недоступна',
};

export const errorButtons = {
    [EPromocodeApiErrorCodes.invalid]: 'Понятно',
    [EPromocodeApiErrorCodes.finished]: 'Посмотреть тарифы',
    [EPromocodeApiErrorCodes.alien]: 'Попробовать',
    [EPromocodeApiErrorCodes.exists]: 'Посмотреть тарифы',
    [EPromocodeApiErrorCodes.activated]: 'Понятно',
    [EPromocodeApiErrorCodes.ratelimit]: 'Понятно',
    [EPromocodeApiErrorCodes.system]: 'Попробовать ещё раз',
    [EPromocodeApiErrorCodes.internal]: 'Попробовать ещё раз',
    [EPromocodeApiErrorCodes.required]: 'Ввести',
    [EPromocodeApiErrorCodes.platform]: 'Понятно',
    [EPromocodeApiErrorCodes.not_new_user]: 'Понятно',
};

export const featuesText = [
    {
        id: 'paid_upload',
        text: 'загрузка файлов до 100 ГБ',
    },
    {
        id: 'file_history',
        text: 'версионирование файлов',
    },
];

export const QUOTAS = {
    8589934592: '8 ГБ',
    17179869184: '16 ГБ',
    34359738368: '32 ГБ',
    68719476736: '64 ГБ',
    137438953472: '128 ГБ',
    274877906944: '256 ГБ',
    549755813888: '512 ГБ',
    1099511627776: '1 ТБ',
    2199023255552: '2 ТБ',
    4398046511104: '4 ТБ',
};

export const MONTHS_PLURAL_FORMS = ['месяц', 'месяца', 'месяцев'];

export const GENETIVE_MONTHS_PLURAL_FORMS = ['месяца', 'месяцев', 'месяцев'];

export type ActivateError = { error: EPromocodeApiErrorCodes };

export const isActivateError = (error: any): error is ActivateError =>
    typeof error === 'object' && 'error' in error && Object.values(EPromocodeApiErrorCodes).includes(error.error);
