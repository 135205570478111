import { splitEvery } from 'ramda';
import { sendGaUploaderNew } from 'reactApp/modules/uploading/helpers/uploading.helpers';
import { TLogLevel, UploadingLogRecord } from 'reactApp/modules/uploading/serviceClasses/UploadingLogRecord';
import { ELogType } from 'reactApp/modules/uploading/uploading.types';
import { ActionsBatcher } from 'reactApp/utils/reduxHelpers';

const RADAR_CHUNK_TITEMOUT = 100;
const MAX_FILES_COUNT_LOG_INFO = 1000;
let currentFilesLogCounter = 0;

export const resetFilesLogCounter = () => currentFilesLogCounter;

const actionsBatcher = new ActionsBatcher((items) => {
    const chunks = splitEvery<UploadingLogRecord>(500, items);

    const sendTimersChunk = () => {
        const chunk = chunks.pop();
        chunk?.forEach((record) => {
            // Юзер шлет тысячи файлов в одной пачке, тогда шлем только ошибки
            if (currentFilesLogCounter > MAX_FILES_COUNT_LOG_INFO && !(record.type === 'fail' || record.type === 'error')) {
                return;
            }
            record.sendCounter();
            currentFilesLogCounter++;
        });

        if (chunks.length) {
            // Радары шлем небольшими пачками, иначе фриз интерфейса на большой пачке файлов
            setTimeout(sendTimersChunk, RADAR_CHUNK_TITEMOUT);
        }
    };

    sendTimersChunk();
}, 1000);

class UploadingLog {
    log = (type: ELogType, data: Record<string, any>, level: TLogLevel = 0) => {
        const record = new UploadingLogRecord(type, data, level);

        // Иначе сильно тормозит на куче файлов
        actionsBatcher.push(record);

        if (type === 'fail' || type === 'error') {
            sendGaUploaderNew(data.radarName);
        }
    };

    info = (data: Record<string, any>, level: TLogLevel = 0) => {
        this.log(ELogType.TYPE_INFO, data, level);
    };

    fail = (data: Record<string, any>, level: TLogLevel = 0) => {
        this.log(ELogType.TYPE_FAIL, data, level);
    };

    error = (data: Record<string, any>, level: TLogLevel = 0) => {
        this.log(ELogType.TYPE_ERROR, data, level);
    };
}

export const uploadingLog = new UploadingLog();
