import { uploadingService } from 'reactApp/modules/uploading/serviceClasses/UploadingService';
import { checkProgressStatus } from 'reactApp/modules/uploadList/sagas/progress.saga';
import { call } from 'redux-saga/effects';

import { cancelDescriptor } from '../uploading.module';

export function* handleCancelDescriptor(action: ReturnType<typeof cancelDescriptor>) {

    uploadingService.cancelFolder(action.payload);

    yield call(checkProgressStatus, true);
}
