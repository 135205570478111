import { xray } from 'lib/xray';
import { Platforms } from 'reactApp/modules/environment/constants';
import { openPayInBrowserDialog } from 'reactApp/sections/MobileSubscriptionsPage/PayInBrowser/PayInBrowser.helpers';

export const revealPlatformPopup = (id: string, isIOSPromo: boolean, isAndroidPromo: boolean) => {
    const platform = isIOSPromo ? Platforms.IOS : Platforms.Android;

    const onOpen = () => {
        xray.send('pay_browser_popup_open', { i: platform });
    };

    const onClose = () => {
        xray.send('pay_browser_popup_close', { i: platform });
    };

    const onCopy = () => {
        xray.send('pay_browser_popup_copy', { i: platform });
    };

    openPayInBrowserDialog({ onOpen, onCopy, onClose, id, isAndroidPromo, isIOSPromo });
};
