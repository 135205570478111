import { EStorageType } from 'reactApp/modules/storage/storage.types';

export enum FamilyError {
    linkExpired = 'link-expired',
    linkLimit = 'link-limit',
    linkError = 'link-error',
    linkRemoved = 'link-removed',
    wrongAccount = 'wrong-account',
    wrongAccountEnter = 'wrong-account-enter',
    alredyInFamily = 'alredy-in-family',
    ownerSubscriptionOrLinkExpired = 'owner-subscription-or-link-expired',
    familyMembersLimit = 'family-members-limit',
    familyOverquota = 'family-overquota',
    tooManyFamilies = 'too-many-families',
    touchIsUnavailable = 'touch-is-unavailable',
}

export enum FamilyAcceptError {
    tooEarly = 'too early',
    overquota = 'overquota',
    tooManyPerYear = 'too many per year',
}

export interface FamilyErrorDialogProps {
    onClose?: () => void;
    errorType: FamilyError;
    storage?: EStorageType;
    isMobile?: boolean;
}

export enum FamilyCreateError {
    tooManyFamilies = 'too many families',
}
