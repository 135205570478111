import { ReactComponent as ReloadIcon } from '@vkontakte/icons/src/svg/20/refresh_outline_20.svg';
import clsx from 'clsx';
import React from 'react';
import { Button } from 'reactApp/ui/Button/Button';

import styles from './Stub.css';

type StubProps = {
    reloadHandler: () => void;
    caption?: string;
    className?: string;
};
export const Stub: React.FC<StubProps> = ({ reloadHandler, caption = 'Что-то пошло не так', className }) => {
    return (
        <div className={clsx(styles.root, className)}>
            <div className={styles.regular}>{caption}</div>
            <Button theme="octavius" middle className={styles.button} onClick={reloadHandler}>
                <ReloadIcon />
                Повторить загрузку
            </Button>
        </div>
    );
};
