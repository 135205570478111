import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { store as reduxStore } from 'reactApp/store';
import { sendDwh } from 'reactApp/utils/ga';

import { ECategoryGa } from './paymentGa';

interface Params {
    action: 'view' | 'close';
}

export const sendKasperskiSnackAnalitics = ({ action }: Params): void => {
    const platform = IS_PHONE_BROWSER ? 'touch' : 'desktop';
    const storage = getCurrentStorage(reduxStore.getState());

    const dwhData = {
        place: platform,
        source: storage,
    };

    sendDwh({
        eventCategory: ECategoryGa.kaspersky,
        action,
        dwhData,
    });
};
