import {
    faceSizeWidthPadding,
    maxRowInTooltip,
    numOfFacesPerRow,
} from 'reactApp/ui/FaceFilter/FaceList/FaceList.constants';

export const getGridSizes = ({ faces, hasMoreToLoad, isMobile, width }) => {
    const mobileColumnCount = Math.floor(width / faceSizeWidthPadding);
    const columnCount = isMobile ? mobileColumnCount : numOfFacesPerRow;
    const rowCount = Math.ceil(faces / columnCount) + (hasMoreToLoad ? 1 : 0);
    const itemSize = isMobile ? width / columnCount : faceSizeWidthPadding;

    const height = 8 + (isMobile ? rowCount : Math.min(maxRowInTooltip, rowCount)) * itemSize;
    // если в тултипе больше 4 строчек, то в тултипе появляется скорол, ширину которого закладываем в ширину тултипа
    const tooltipWidth = rowCount > maxRowInTooltip ? 334 : 328;

    return {
        columnWidth: itemSize,
        columnCount,
        rowCount,
        rowHeight: itemSize,
        height,
        width: isMobile ? width : tooltipWidth,
    };
};
