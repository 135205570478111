import { createAction, createReducer } from '@reduxjs/toolkit';

import { ContinueUploadState } from './continueUpload.model';

const initialUploaderState: ContinueUploadState = {
    showContinueUpload: false,
};

export const showContinueUploadAction = createAction('continueUpload/showContinueUpload');
export const hideContinueUploadAction = createAction('continueUpload/hideContinueUpload');
export const continueUploadAction = createAction('continueUpload/continueUpload');
export const clearContinueUploadFilesAction = createAction('continueUpload/clearContinueUploadFiles');

export const continueUploadReducer = createReducer(
    { ...initialUploaderState },
    {
        [showContinueUploadAction.type]: (state) => {
            return {
                ...state,
                showContinueUpload: true,
            };
        },
        [hideContinueUploadAction.type]: (state) => {
            return {
                ...state,
                showContinueUpload: false,
            };
        },
    }
);
