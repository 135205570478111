import { EUploadReasonSource } from 'reactApp/modules/uploading/serviceClasses/UploadingReason';

import { JavaScriptError } from '../../errors/JavaScriptError';
import { LocalFileNotFoundFail } from '../../fails/LocalFileNotFoundFail';
import { LocalFileNotReadableFail } from '../../fails/LocalFileNotReadableFail';
import { UnsupportedFolderTransferFail } from '../../fails/UnsupportedFolderTransferFail';

const readEntries = (reader: FileSystemDirectoryReader): Promise<FileSystemEntry[]> =>
    new Promise((resolve, reject) => {
        reader.readEntries((entries) => {
            const result: FileSystemEntry[] = [];
            // entries может быть объектом, похожим на Array,
            // поэтому конкатинируем коллекции в цикле.
            for (let i = 0; i < entries.length; i++) {
                result.push(entries[i]);
            }
            resolve(result);
        }, reject);
    });

class FileSystemEntryReader {
    getFile = async (entry: FileSystemFileEntry): Promise<File> => {
        let promise = new Promise<File>(function (resolve, reject) {
            entry.file((file) => resolve(file as File), reject);
        });

        promise = promise.catch(function (error) {
            const source = EUploadReasonSource.SOURCE_WEB_CLIENT;
            let stack;
            let reason;

            if (LocalFileNotFoundFail.isNotFoundError(error)) {
                stack = new Error('LocalFileNotFoundFail');
                reason = new LocalFileNotFoundFail(stack, source);
            } else if (entry.isDirectory && UnsupportedFolderTransferFail.isNotReadableError(error)) {
                stack = new Error('UnsupportedFolderTransferFail');
                reason = new UnsupportedFolderTransferFail(stack, source);
            } else if (LocalFileNotReadableFail.isNotReadableError(error)) {
                stack = new Error('LocalFileNotReadableFail');
                reason = new LocalFileNotReadableFail(stack, source);
            } else {
                stack = new Error('JavaScriptError');
                reason = new JavaScriptError(stack, source, error);
            }

            return Promise.reject(reason);
        });

        return promise;
    };

    readDirectory = async (entry: FileSystemDirectoryEntry) => {
        const reader = await entry.createReader();

        const result: FileSystemEntry[] = [];

        let count = 1;

        while (count > 0) {
            // eslint-disable-next-line no-useless-catch
            try {
                const entries = await readEntries(reader);

                count = entries.length;
                // entries может быть объектом, похожим на Array,
                // поэтому конкатинируем коллекции в цикле.
                for (let i = 0; i < count; i++) {
                    result.push(entries[i]);
                }

                // DirectoryReader может отдавать entries порциями,
                // поэтому повторяем действия, пока получаем
                // коллекцию ненулевой длины.
                // eslint-disable-next-line sonarjs/no-useless-catch
            } catch (error) {
                throw error;
            }
        }

        return result;
    };
}

export const fileSystemEntryReader = new FileSystemEntryReader();
