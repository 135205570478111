import { ReactComponent as IconClose } from 'img/icons/close_s__2.svg';
import React, { memo } from 'react';
import { Hint } from 'reactApp/ui/Hint/Hint';
import styles from 'reactApp/ui/UploadList/Problems/Problems.css';

interface ICloseProps {
    onClose: () => void;
}

export const iconSize = {
    width: 16,
    height: 16,
};
export const CloseIcon = memo(({ onClose }: ICloseProps) => {
    return (
        <div className={styles.close} onClick={onClose}>
            <Hint text="Закрыть сообщение">
                <IconClose {...iconSize} />
            </Hint>
        </div>
    );
});

CloseIcon.displayName = 'CloseIcon';
