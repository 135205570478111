export interface PageLocation {
    isStart?: boolean;
    isEnd?: boolean;
    percent?: number;
    // epubcfi for epub
    pageId?: string;
    chapterInfo?: {
        chapterId: string;
        current: number;
        total: number;
    };
}

export interface NavItem {
    id: string;
    href: string;
    label: string;
    subitems?: NavItem[];
    parent?: string;
}

export interface Toc {
    title?: NavItem;
    items: NavItem[];
}

export interface Bookmark {
    percent: number;
    pageId: string;
    chapterId: string;
    displayText?: string;
}

export interface LastReadingPage {
    hash?: string;
    path?: string;
    percent: number;
    pageId?: string;
    chapterId?: string;
}

export enum EbookTheme {
    LIGHT = 'LIGHT',
    DARK = 'DARK',
    CEPIA = 'CEPIA',
}

export enum EbookFont {
    Arial = 'Arial',
    Helvetica = 'Helvetica',
    TimesNewRoman = 'Times New Roman',
    Georgia = 'Georgia',
}

export interface Options {
    theme: EbookTheme;
    size: number;
    fontFamily: EbookFont;
}

export interface EbookState {
    isActiveBookmark: boolean;
    isOptionDlgShown: boolean;
    options: Options;
    downloadEbookClick?: boolean;
}

export interface EbookStatus {
    isReady: boolean;
    isError: boolean;
}

export interface EbookApi {
    nextPage(): void;

    prevPage(): void;

    goToItem(item: NavItem): Promise<boolean>;

    goToPercent(percent: number): void;

    // Нужно, например, для инфы при ховере в слайдере
    getInfoAtPercent(percent: number, items: NavItem[]): string;

    refresh(): void;

    getCurrentAsBookmark(): any;

    getVisibleBookmark(bookmarks: Bookmark[]): string;
}

export enum EbookType {
    epub = 'epub',
    fb2 = 'fb2',
    djvu = 'djvu',
}
