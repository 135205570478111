import React, { useRef } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

import styles from './WebPushBubble.css';

interface IProps {
    onClose(): void;
    onPostpone(): void;
    onAction(): void;
    isBlocked?: boolean;
}

export const WebPushBubble = ({ onAction, onClose, onPostpone, isBlocked }: IProps) => {
    const ref = useRef<HTMLDivElement>(null);

    const content = (
        <div className={styles.root}>
            <div className={styles.text}>
                {isBlocked
                    ? 'В настройках браузера заблокированы уведомления'
                    : 'Включите уведомления об акциях и новых функциях Облака в браузере'}
            </div>
            {isBlocked ? (
                <Button primary middle theme="octavius" onClick={onAction}>
                    Как включить уведомления
                </Button>
            ) : (
                <div className={styles.buttons}>
                    <Button primary middle theme="octavius" onClick={onAction}>
                        Включить
                    </Button>
                    <Button middle theme="octavius" onClick={onPostpone}>
                        Не сейчас
                    </Button>
                </div>
            )}
        </div>
    );

    return (
        <>
            <div className={styles.target} ref={ref} />
            <FloatingTooltip
                target={ref}
                placement={ETooltipPlacement.bottomStart}
                onClose={onClose}
                closeOnOutsideClick={false}
                hideArrow
                qaId="tooltip-webpush"
            >
                {content}
            </FloatingTooltip>
        </>
    );
};
