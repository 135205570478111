import React, { memo, ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadAlbumsListRequest } from 'reactApp/modules/albums/albums.actions';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';

import { SelectAlbumPopupContent } from './components/Content/SelectAlbumPopupContent';
import styles from './SelectAlbumPopup.css';
import { IProps } from './SelectAlbumPopup.types';

export const SelectAlbumPopup = memo(function SelectAlbumPopup(props: IProps): ReactElement {
    const { onClose } = props;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadAlbumsListRequest());
    }, []);

    return (
        <Dialog open id="select-album" onCancel={onClose} className={styles.modal}>
            <Content isModal qaId="select-album-content">
                <div className={styles.root}>
                    <div className={styles.title} data-qa-id="title">
                        Выберите альбом
                    </div>

                    <SelectAlbumPopupContent {...props} />
                </div>
            </Content>
        </Dialog>
    );
});
