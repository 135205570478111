import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { createGaSender } from 'reactApp/utils/ga';

import { IProps } from './PayInBrowser';

export const openPayInBrowserDialog = ({ onClose, ...data }: IProps) =>
    openPopupHelper({ popupName: popupNames.PAY_IN_BROWSER, data, onClose });

export const sendGa = createGaSender('browser-pay');
