/* eslint-disable no-var */
/* eslint-disable object-shorthand */
/* eslint-disable max-lines-per-function */
/**
 * Модуль предупреждения пользователей о потенциально опасных файлах.
 */
import { IS_MOBILE_BROWSER, IS_PHONE_BROWSER, SUSPICIOUS_EXTENSIONS } from 'reactApp/appHelpers/configHelpers';
import { isFolder } from 'reactApp/modules/file/utils';
import { closePopupHelper, openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { store } from 'reactApp/store';

const ERROR_NO_FILES = 'there are no files';
const platform = IS_MOBILE_BROWSER ? 'mobile' : 'desktop';

function isEnabled() {
    const state = store.getState();
    const { isPublic, isAttaches, isStock } = getStorage(getCurrentStorage(state));
    return (isPublic || isAttaches || isStock) && SUSPICIOUS_EXTENSIONS?.length;
}

function getItemExt(item) {
    return String(item?.ext || '').toLowerCase();
}

const isSuspicious = (item) => {
    if (!isEnabled()) {
        return false;
    }

    return item && !isFolder(item) && Array.isArray(SUSPICIOUS_EXTENSIONS) && SUSPICIOUS_EXTENSIONS.includes(getItemExt(item));
};

function SuspiciousRadarGroup(action) {
    var group;

    switch (action) {
        case 'download':
        case 'clone':
            group = action;
            break;

        default:
            group = 'suspicious';
    }

    return RADAR.group(`app_${group}-suspicious_${getCurrentStorage(store.getState())}`);
}

function confirmActionWithSuspiciousItem(item, action) {
    if (!isEnabled()) {
        return Promise.resolve(item);
    }

    const popupName = IS_PHONE_BROWSER ? popupNames.MOBILE_SUSPICIOS_DIALOG : popupNames.SUSPICIOS_DIALOG;
    var ext = getItemExt(item);
    var radar = new SuspiciousRadarGroup(action);

    radar.add('show');
    radar.add(`show_${ext}`);
    radar.add(`show-${platform}`);

    return new Promise((resolve, reject) => {
        openPopupHelper({
            popupName,
            onClose: () => {
                reject();
                closePopupHelper(popupName);
            },
            data: {
                action,
                virusScan: item.virus_scan,
                filename: item.name || item.filename,
                isPhone: IS_PHONE_BROWSER,
                isMobileIos: IS_MOBILE_BROWSER === 'IOS',
                onSuccess: () => {
                    resolve(item);
                    closePopupHelper(popupName);
                },
            },
        });
    });
}

export const confirmActionWithSuspiciousItems = (itemList, action) =>
    new Promise(function (resolve, reject) {
        if (!isEnabled()) {
            return resolve(itemList);
        }

        if (!Array.isArray(itemList)) {
            itemList = [itemList];
        }

        var itemCount = itemList.length;
        var suspiciousItemCount = 0;
        var confirmCount = 0;
        var cancelCount = 0;
        var radar = new SuspiciousRadarGroup(action);

        if (itemCount) {
            radar.add('list-length', itemCount);

            if (itemList.some(isSuspicious)) {
                var i = 0;
                var newItemList = [];
                var next = function () {
                    if (i < itemCount) {
                        var item = itemList[i++];

                        if (item) {
                            if (!isFolder(item) && isSuspicious(item)) {
                                suspiciousItemCount++;
                                confirmActionWithSuspiciousItem(item, action)
                                    .then(function (suspiciousLayer) {
                                        confirmCount++;
                                        suspiciousLayer && suspiciousLayer.hide && suspiciousLayer.hide();
                                        newItemList.push(item);
                                        // eslint-disable-next-line @typescript-eslint/no-use-before-define
                                        asyncNext();
                                    })
                                    .catch(function () {
                                        cancelCount++;
                                        // eslint-disable-next-line @typescript-eslint/no-use-before-define
                                        asyncNext();
                                    });
                            } else {
                                newItemList.push(item);
                                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                                asyncNext();
                            }
                        } else {
                            // eslint-disable-next-line @typescript-eslint/no-use-before-define
                            asyncNext();
                        }
                    } else {
                        var newItemCount = newItemList.length;

                        if (newItemCount) {
                            resolve(newItemList);
                        } else {
                            reject(new Error(ERROR_NO_FILES));
                        }

                        if (suspiciousItemCount) {
                            radar.add('list-suspicious', suspiciousItemCount);
                            radar.add('list-confirmed', confirmCount);
                            radar.add('list-cancelled', cancelCount);
                        }

                        radar.send();
                    }
                };

                var asyncNext = function () {
                    if (i < itemCount) {
                        setTimeout(next, 0);
                    } else {
                        // Последний вызов должен произойти синхронно
                        // с кликом пользователя.
                        next();
                    }
                };

                asyncNext();
            } else {
                // CLOUDWEB-6144:
                // если в списке выделенных файлов нет ниодного
                // небезопасного файла, то диалог не отобразится,
                // соответвенно пользователь не нажмет еще раз
                // кнопку "Скачать/Сохранить", а нам нужно попасть
                // в trusted event, поэтому заранее проверяем наличие
                // небезопасных файлов и синхронно резолвим deferred.
                // TODO: избавиться от завязки на сихронность deferred.
                radar.send();
                resolve(itemList);
            }
        } else {
            reject(new Error(ERROR_NO_FILES));

            radar.add('list-empty', itemCount);
            radar.send();
        }
    });
