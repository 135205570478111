export enum ActionName {
    download = 'download',
    zip = 'zip',
    clone = 'clone',
    publish = 'publish',
}

export enum ActionTarget {
    file = 'file',
    folder = 'folder',
    several = 'several',
}
