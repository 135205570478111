import { APICallV3Post } from 'reactApp/api/APICall';

/**
 * Класс, конвертирующий документ с определенными расширения в pdf
 *
 * **Пока работает только на хомяке и пабликах**
 *
 * Возвращает данные в формате 'arraybuffer'
 */
export class PDFConverterAPICall extends APICallV3Post<Buffer | null> {
    _method = 'ovidius/pdf/view';
    _responseType = 'arraybuffer' as const;
    _withCredentials = true;
}
