import { Method } from 'axios';
import { APICallV4 } from 'reactApp/api/APICall';

interface DeleteFamilyAPICallParams {
    error?: string;
    message?: string;
}

export interface DeleteFamilyQueryParams {
    family: string;
    remove_users: boolean;
}

export class DeleteFamilyAPICall extends APICallV4<DeleteFamilyAPICallParams> {
    _method = 'family/delete';
    _type: Method = 'post';
}
