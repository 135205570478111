import classNames from 'clsx';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { ICoordinates } from 'reactApp/modules/faces/faces.types';
import { Hint } from 'reactApp/ui/Hint/Hint';

import styles from './ViewerImage.css';

export interface IImageSize {
    top: number;
    left: number;
    width: number;
    height: number;
    naturalWidth?: number;
    naturalHeight?: number;
}

interface IOffset {
    x: number;
    y: number;
}

const getFaceSquare = (imageRect: IImageSize | null, coordinate: ICoordinates, offset: IOffset) => {
    const facesScale = (imageRect?.width ?? 1) / (imageRect?.naturalWidth || 1) || 1;

    let top = facesScale * coordinate.top + (imageRect?.top ?? 0) + offset.y;
    let left = facesScale * coordinate.left + (imageRect?.left ?? 0) + offset.x;
    let width = facesScale * (coordinate.right - coordinate.left);
    let height = facesScale * (coordinate.bottom - coordinate.top);

    if (width < height) {
        left = left - (height - width) / 2;
        width = height;
    } else {
        top = top - (width - height) / 2;
        height = width;
    }

    return { top, left, width, height };
};

export const FaceRectangle = memo(
    ({
        clickOnFace,
        imageRect,
        coordinate,
        offset,
        showOnHoverOnly,
    }: {
        clickOnFace?(faceId: string);
        imageRect: IImageSize | null;
        coordinate: ICoordinates;
        offset: IOffset;
        showOnHoverOnly: boolean;
    }) => {
        const { top, left, width, height } = useMemo(() => getFaceSquare(imageRect, coordinate, offset), [imageRect, coordinate, offset]);
        const faceId = coordinate.face;
        const [showRect, setShowRect] = useState(!showOnHoverOnly);

        useEffect(() => {
            if (!showOnHoverOnly) {
                // Не показываем квадраты лиц, пока список лиц слева не выедет, чтобы квадраты не скакали
                setTimeout(() => setShowRect(true), 100);
            }  else {
                setShowRect(false);
            }
        }, [showOnHoverOnly, setShowRect]);

        const handleCLick = useCallback(() => {
            clickOnFace?.(faceId);
        }, [faceId, clickOnFace]);

        return (
            <Hint text="Показать все фотографии с этим человеком" showDelay>
                <div
                    className={classNames({ [styles.faceFrame]: true, [styles.faceFrame_hoverOnly]: showOnHoverOnly || !showRect })}
                    style={{
                        left: `${left}px`,
                        top: `${top}px`,
                        width: `${width}px`,
                        height: `${height}px`,
                    }}
                    onClick={handleCLick}
                >
                    <div className={classNames(styles.corner, styles.topLeft)} />
                    <div className={classNames(styles.corner, styles.topRight)} />
                    <div className={classNames(styles.corner, styles.bottomRight)} />
                    <div className={classNames(styles.corner, styles.bottomLeft)} />
                </div>
            </Hint>
        );
    }
);

FaceRectangle.displayName = 'FaceRectangle';
