export enum EEpubPostMessageType {
    sender = 'ebook-content',
    init = 'ebook-content-init',
    globalError = 'ebook-content-global-error',
    ready = 'ebook-content-ready',
    error = 'ebook-content-error',
    xray = 'ebook-content-xray',
    onShowUI = 'ebook-content-show-ui',
    locationChanged = 'ebook-content-location-changed',
    config = 'ebook-content-config',
    bookmark = 'ebook-content-bookmark',
    activeBookmark = 'ebook-content-bookmark-active',
    deleteBookmark = 'ebook-content-bookmark-delete',
}

export const mapPostMessageTypeToRadar: Record<EEpubPostMessageType, string | null> = {
    [EEpubPostMessageType.sender]: 'sender',
    [EEpubPostMessageType.init]: 'init',
    [EEpubPostMessageType.globalError]: 'globalError',
    [EEpubPostMessageType.ready]: 'ready',
    [EEpubPostMessageType.error]: 'error',
    [EEpubPostMessageType.xray]: null,
    [EEpubPostMessageType.onShowUI]: null,
    [EEpubPostMessageType.locationChanged]: null,
    [EEpubPostMessageType.config]: null,
    [EEpubPostMessageType.bookmark]: null,
    [EEpubPostMessageType.activeBookmark]: null,
    [EEpubPostMessageType.deleteBookmark]: null,
};
