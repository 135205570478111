import { MYOFFICE_VARIANTS } from 'Cloud/Application/Editor/MyOffice/myOffice.types';
import { EditorID } from 'Cloud/Application/Editor/types';
import { IS_PUBLIC } from 'reactApp/appHelpers/configHelpers';
import { getEditorState } from 'reactApp/modules/editor/editor.selectors';
import { getFeatureCoEditAlertPopup } from 'reactApp/modules/features/features.selectors';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { UserStorageActions, UserStorageSelectors } from 'reactApp/modules/user/userStorage';
import { sendXray } from 'reactApp/utils/ga';
import { call, put, select } from 'redux-saga/effects';

const PROMO_ID = 'co-edit-public-alert';
const XRAY_NAME = PROMO_ID;

function* wasNotShown() {
    return !(yield select(UserStorageSelectors.get, PROMO_ID));
}

function* isAvailableForCoEdit() {
    const editorState: ReturnType<typeof getEditorState> = yield select(getEditorState);

    return (
        editorState.editorId === EditorID.MYOFFICE &&
        editorState.myOfficeType === MYOFFICE_VARIANTS.wopi &&
        editorState.item?.weblinkAccessRights === 'rw'
    );
}

export function* handleReadyCoEditPublicAlert() {
    const coEditAlertFeatureEnable: ReturnType<typeof getEditorState> = yield select(getFeatureCoEditAlertPopup);

    if (IS_PUBLIC && coEditAlertFeatureEnable && (yield wasNotShown()) && (yield isAvailableForCoEdit())) {
        yield call(openPopupHelper, {
            popupName: popupNames.CO_EDIT_ALERT,
            onClose: () =>
                sendXray(XRAY_NAME, {
                    close: 1,
                }),
            data: {
                onShow: () =>
                    sendXray(XRAY_NAME, {
                        show: 1,
                    }),
                onClick: () =>
                    sendXray(XRAY_NAME, {
                        click: 1,
                    }),
            },
        });
        yield put(UserStorageActions.set(PROMO_ID, '1'));
    }
}
