import { Icon16MoreVertical } from '@vkontakte/icons';
import React, { useCallback, useEffect, useMemo, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';
import { dispatchNewSearchRadar } from 'reactApp/modules/dwh/dwh.module';
import { CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import {
    downloadArchiveItemRequest,
    requestArchiveInfo,
    setArchiveCurrentFolder,
    setArchiveItemActive,
} from 'reactApp/modules/viewer/viewer.module';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { EArchiveType, IArchiveItem } from 'reactApp/modules/viewer/viewer.types';
import { RootState } from 'reactApp/store';
import { Breadcrumbs } from 'reactApp/ui/Mobile/Breadcrumbs/Breadcrumbs';
import { ItemViewer } from 'reactApp/ui/Mobile/MobileViewer/MobileViewerArchive/ItemViewer';
import { useArchiveViewerUrlState } from 'reactApp/ui/Mobile/MobileViewer/MobileViewerArchive/MobileViewerArchive.hooks';
import { MobileViewerArchiveList } from 'reactApp/ui/Mobile/MobileViewer/MobileViewerArchive/MobileViewerArchiveList';
import { sendDwh } from 'reactApp/utils/ga';
import { noop } from 'reactApp/utils/helpers';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import styles from './MobileViewerArchive.css';

const disableDownload = getQueryParams()?.hide_download_button === '1';

interface IMobileViewerArchiveProps {
    item: CloudFile;
    showBackButton?: boolean;
    noActionDotsIcon?: boolean;
    showCloseButton?: boolean;
    showToolbar?: boolean;
    storage: EStorageType;
    onClose: () => void;
    onShow: () => void;

    closeArchiveViewer();

    openToolbarOptions?: () => void;

    sendAnalytics(data: any): void;

    onError?(): void;
}

// eslint-disable-next-line max-lines-per-function, sonarjs/cognitive-complexity
export const MobileViewerArchive: VFC<IMobileViewerArchiveProps> = ({
    showCloseButton = true,
    showToolbar = true,
    openToolbarOptions,
    onClose,
    onShow,
    item,
    storage,
    noActionDotsIcon = false,
    closeArchiveViewer = noop,
    sendAnalytics,
    onError = noop,
}) => {
    const dispatch = useDispatch();
    const archiveInfo = useSelector(ViewerSelectors.getArchiveInfo);
    const activeItem = useSelector(ViewerSelectors.getArchiveActiveItem);
    const items = useSelector(ViewerSelectors.getCurrentFolderArchiveItems);
    const currentFolder = useSelector(ViewerSelectors.getArchiveCurrentFolder);
    const breadcrumbs = useSelector((state: RootState) => ViewerSelectors.getArchiveBreadcrumbs(state, item?.name));
    const navigation = useNavigate();
    const location = useLocation();
    const ext = item.ext;

    const { setActiveItem, updateUrlFromPath, urlActiveFolder, urlActiveItem } = useArchiveViewerUrlState();

    useEffect(() => {
        onShow();

        return () => {
            dispatch(setArchiveCurrentFolder(''));
            onClose();
        };
    }, []);

    useEffect(() => {
        if (archiveInfo?.error) {
            onError();
        }
    }, [archiveInfo?.error]);

    useEffect(() => {
        // fix for Public
        if (location.pathname !== window.location.pathname) {
            navigation(`${window.location.pathname}?force=`, { replace: true });
            setTimeout(() => {
                navigation(window.location.pathname, { replace: true });
            }, 0);
        }
    }, []);

    useEffect(() => {
        dispatch(requestArchiveInfo({ storage, id: item.id, ext }));
    }, [dispatch, item.id, storage, ext]);

    const handleGo = useCallback(
        (path?: string) => {
            if (typeof path === 'undefined') {
                closeArchiveViewer();
            } else {
                const activeFolder = updateUrlFromPath(path);
                dispatch(setArchiveCurrentFolder(activeFolder));
            }
        },
        [dispatch, closeArchiveViewer, updateUrlFromPath]
    );

    useEffect(() => {
        if (archiveInfo?.isLoaded) {
            dispatch(setArchiveCurrentFolder(urlActiveFolder || ''));
            dispatch(setArchiveItemActive(urlActiveItem || ''));
        }
    }, [archiveInfo?.isLoaded, urlActiveFolder, urlActiveItem]);

    useEffect(() => {
        if (archiveInfo?.isLoaded && urlActiveFolder) {
            sendDwh({ eventCategory: 'archive', action: 'folder-open', dwhData: { extension: ext } });
        }
    }, [archiveInfo?.isLoaded, urlActiveFolder]);

    const handleClick = useCallback(
        (item: IArchiveItem) => {
            if (item.isFolder) {
                if (storage === EStorageType.search) {
                    const currentFolderIndex = items.findIndex((file) => file.path === item.path);
                    dispatch(
                        dispatchNewSearchRadar({
                            dwhData: { eventCategory: ECategoryGa.archiveSearch, action: 'folder-open' },
                            items: [{ file_name: item.nameWithoutExt, pos: currentFolderIndex }],
                        })
                    );
                }
                handleGo(item.path);
            } else {
                dispatch(setArchiveItemActive(item.path));
                setActiveItem(item.path);
            }
        },
        [dispatch, handleGo, setActiveItem, items, storage]
    );

    const handleDownload = useCallback(
        (item: IArchiveItem) => {
            dispatch(
                downloadArchiveItemRequest({
                    storage,
                    archiveId: archiveInfo?.archiveId || '',
                    itemId: item.path,
                })
            );
            sendAnalytics({
                action: 'download-content',
                extension: item.ext,
                is_archive: true,
                size_files: item.size,
                type_content: item.kind,
            });
        },
        [sendAnalytics, storage, archiveInfo?.archiveId]
    );

    const handleArchiveViewerClose = useCallback(() => {
        if (!activeItem) {
            closeArchiveViewer();
            return;
        }
        setActiveItem(null);
    }, [setActiveItem, activeItem, closeArchiveViewer]);

    const showBackButton = useMemo(() => {
        return Boolean(showCloseButton || currentFolder);
    }, [showCloseButton, currentFolder, storage]);

    const handleClickBack = useCallback(() => {
        if (currentFolder) {
            const idx = currentFolder.lastIndexOf('/');
            const parent = idx >= 0 ? currentFolder.slice(0, Math.max(0, idx)) : '';
            handleGo(parent);
            return;
        }

        const params = getQueryParams();

        if (params && Boolean(params.is_app_viewer)) {
            window.CloudApp?.closeScreen();

            return;
        }

        handleArchiveViewerClose();
    }, [handleArchiveViewerClose, currentFolder, handleGo]);

    /**
     * Когда есть активный элемент (не папка) отображаем его в ItemViewer
     */
    if (activeItem) {
        return (
            <ItemViewer
                storage={storage}
                archiveId={archiveInfo?.archiveId || ''}
                item={activeItem}
                onClose={handleArchiveViewerClose}
                sendAnalytics={sendAnalytics}
                disableDownload={disableDownload && archiveInfo?.type !== EArchiveType.zip}
            />
        );
    }

    return (
        <div className={styles.archive}>
            <div className={styles.toolbar}>
                <div className={styles.toolbarBreadcrumbs}>
                    <Breadcrumbs
                        showBackButton={showBackButton}
                        name={breadcrumbs[breadcrumbs.length - 1]?.text || ''}
                        onClickBackButton={handleClickBack}
                    />
                </div>
                {showToolbar && breadcrumbs.length === 1 && !noActionDotsIcon && (
                    <div className={styles.toolbarAction} onClick={openToolbarOptions}>
                        <Icon16MoreVertical width={20} height={20} fill="currentColor" />
                    </div>
                )}
            </div>
            <MobileViewerArchiveList
                items={items}
                isLoaded={archiveInfo?.isLoaded}
                error={archiveInfo?.error}
                handleClick={handleClick}
                handleDownload={handleDownload}
                disableDownload={disableDownload && archiveInfo?.type !== EArchiveType.zip}
            />
        </div>
    );
};
