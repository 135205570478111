import { Icon20ChevronUpOutline } from '@vkontakte/icons';
import { Button, ButtonProps, SizeType } from '@vkontakte/vkui';
import classNames from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { getSearchContentStatus } from 'reactApp/modules/search/search.selectors';
/*
    TODO: заменить на vkui когда будет добавлена в библиотеку
    https://github.com/VKCOM/icons/issues/285
 */
import { DropdownOutlineIcon } from 'reactApp/ui/VKUIIcons';
import { useWebSearchFilters } from 'reactApp/ui/WebSearch/hooks/useWebSearchFilters';
import { ESearchOptionSource, ESearchOptionType, fileTypeOptions } from 'reactApp/ui/WebSearch/WebSearch.data';

import styles from './TypeSelectButton.css';

interface IProps extends ButtonProps {
    isOpen: boolean;
    type: ESearchOptionType;
    source: ESearchOptionSource;
}

export const TypeSelectButton = React.forwardRef<HTMLButtonElement, IProps>(({ isOpen, type: typeProp, source, onClick }, ref) => {
    const { type: typeHook } = useWebSearchFilters();
    const { webSearchContentEnabled } = useSelector(getSearchContentStatus);

    const type = webSearchContentEnabled ? typeProp : typeHook;

    const allTypes = type === ESearchOptionType.all;
    const allSources = webSearchContentEnabled ? source === ESearchOptionSource.all : true;

    const gotFilter = !allTypes || !allSources;
    const btnText = webSearchContentEnabled && type === ESearchOptionType.all ? 'Фильтр' : fileTypeOptions[type].text;

    return (
        <Button
            className={classNames(styles.button, { [styles.selected]: gotFilter })}
            hasHover={false}
            getRootRef={ref}
            size="l"
            mode={gotFilter ? 'secondary' : 'primary'}
            appearance={gotFilter ? 'accent' : 'neutral'}
            sizeY={SizeType.COMPACT}
            onClick={onClick}
            after={isOpen ? <Icon20ChevronUpOutline className={styles.icon} /> : <DropdownOutlineIcon className={styles.icon} />}
            data-qa-id="filters-button"
        >
            {btnText}
        </Button>
    );
});

TypeSelectButton.displayName = 'TypeSelectButton';
