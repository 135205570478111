import React, { RefObject, useCallback, useState, VFC } from 'react';
import { useDispatch } from 'react-redux';
import { UserStorageActions } from 'reactApp/modules/user/userStorage';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipAppearance, ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

interface IProps {
    targetRef: RefObject<HTMLDivElement>;
    onApply: () => void;
}

const TOOLTIP_ID = 'faceTipViewerMobile';

export const FacesTooltip: VFC<IProps> = ({ targetRef, onApply }) => {
    const dispatch = useDispatch();
    const showTip = !dispatch(UserStorageActions.get(TOOLTIP_ID));
    const [show, setShow] = useState(showTip);

    const handleClose = useCallback(() => {
        setShow(false);
        dispatch(UserStorageActions.set(TOOLTIP_ID, true));
    }, []);

    const handleApply = useCallback(() => {
        onApply();
        handleClose();
    }, [onApply, handleClose]);

    if (!show || !targetRef?.current) {
        return null;
    }

    return (
        <FloatingTooltip
            target={targetRef}
            title="Люди на фото"
            text={'Облако умеет определять\nлюдей на фото'}
            buttonText="Показать"
            placement={ETooltipPlacement.bottomEnd}
            appearance={ETooltipAppearance.dark}
            onClick={handleApply}
            onClose={handleClose}
            qaId="tooltip-faces-mobile"
        />
    );
};

FacesTooltip.displayName = 'FacesTooltip';
