import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useArchiveViewerUrlState = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const urlActiveFolder = searchParams.get('activeFolder');
    const urlActiveItem = searchParams.get('activeItem');

    const setActiveItem = useCallback(
        (item: string | null) => {
            if (!item) {
                searchParams.delete('activeItem');
            } else {
                searchParams.set('activeItem', item);
            }
            setSearchParams(searchParams);
        },
        [searchParams]
    );

    const updateUrlFromPath = useCallback(
        (path) => {
            const activeFolder = path?.replace(/^\//, '');
            searchParams.delete('activeItem');
            if (activeFolder) {
                searchParams.set('activeFolder', activeFolder);
            } else {
                searchParams.delete('activeFolder');
            }
            setSearchParams(searchParams);

            return activeFolder;
        },
        [searchParams]
    );

    return { setActiveItem, updateUrlFromPath, urlActiveFolder, urlActiveItem };
};
