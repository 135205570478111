import { useEffect } from 'react';
import { SUBSCRIPTION_ANALYTICS } from 'reactApp/sections/MobileSubscriptionsPage/MobileSubscription.analytics';
import { sendDwh } from 'reactApp/utils/ga';

export const useSendAnalyticsAfterOpen = <T>(subscriptionList: T[]) => {
    // TODO: заменить на `useFirstEffect`
    // Возможно, стоит переименовать `useFirstEffect` в `useEffectOnce`
    useEffect(() => {
        sendDwh({
            ...SUBSCRIPTION_ANALYTICS.MY_SUBS_OPEN,
            dwhData: {
                count_subs: subscriptionList.length,
                source: 'touch',
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
