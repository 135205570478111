import { EStatus } from 'reactApp/modules/subscriptions/subscriptions.types';

const PHOTO_SIZE_IN_BYTES = 3989751.320018579;

export const getNumberOfShot = (space: number) => `+ ${Math.floor(space / PHOTO_SIZE_IN_BYTES).toLocaleString('ru-RU')} снимков`;

const STATUS: Record<EStatus, string> = {
    [EStatus.ACTIVE]: 'Подключено',
    [EStatus.SUSPENDED]: 'Закончится',
    [EStatus.FINISHED]: 'Завершена',
};

export const getStatus = (status: EStatus = EStatus.ACTIVE) => STATUS[status];
