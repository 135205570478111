import coverImage from 'img/ask-phone-popup/success-phone.png';
import React from 'react';
import { useSelector } from 'react-redux';
import { WhatsNewDialog } from 'reactApp/components/WhatsNewDialog/WhatsNewDialog';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { MobileDialog } from 'reactApp/ui/AskPhoneDialog/components/MobileDialog';
import { noop } from 'reactApp/utils/helpers';

import { SUCCESS_ATTACH_PHONE_CONTENT } from './AskPhoneDialog.constants';

interface ISuccessAttachPhoneDialogProps {
    onClose?: () => void;
}

export const SuccessAttachPhoneDialog: React.VFC<ISuccessAttachPhoneDialogProps> = ({ onClose = noop }) => {
    const isMobile = useSelector(EnvironmentSelectors.isMobile);

    return isMobile ? (
        <MobileDialog
            closable
            title={SUCCESS_ATTACH_PHONE_CONTENT.title}
            buttonText={SUCCESS_ATTACH_PHONE_CONTENT.buttonText}
            onClickButton={onClose}
            onClose={onClose}
            imgProps={{
                height: 139,
                src: coverImage,
                alt: SUCCESS_ATTACH_PHONE_CONTENT.title,
            }}
        >
            {SUCCESS_ATTACH_PHONE_CONTENT.contentText}
        </MobileDialog>
    ) : (
        <WhatsNewDialog
            closable
            imageFluid={false}
            primaryButtonText={SUCCESS_ATTACH_PHONE_CONTENT.buttonText}
            onClick={onClose}
            onClose={onClose}
            title={SUCCESS_ATTACH_PHONE_CONTENT.title}
            text={SUCCESS_ATTACH_PHONE_CONTENT.contentText}
            imageUrl={coverImage}
            imageBgColor="#E7EDFB"
        />
    );
};
