import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routeStatusPage } from 'reactApp/modules/router/router.module';
import { EStatus } from 'reactApp/sections/ErrorPage/ErrorPage.types';

export const NotFoundPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = window.location;
    const { pathname, search, hash } = location;

    useEffect(() => {
        const isHome = pathname.startsWith('/home');

        if (isHome) {
            const url = `${pathname}${search}${hash}`;
            navigate(decodeURIComponent(url));
            return;
        }

        dispatch(routeStatusPage({ status: EStatus.NOT_FOUND }));
    }, [pathname]);

    return null;
};
