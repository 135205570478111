import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import { ButtonLink } from 'reactApp/ui/ButtonLink/ButtonLink';
import styles from 'reactApp/ui/SpacePromo/SpacePromo.css';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

export class SpacePromo extends PureComponent {
    static propTypes = {
        targetRect: PropTypes.object,
        target: PropTypes.node,
        closable: PropTypes.bool,
        onAction: PropTypes.func,
        onClose: PropTypes.func,
        icon: PropTypes.any,
        html: PropTypes.bool,
        title: PropTypes.string,
        description: PropTypes.any,
        button_link: PropTypes.bool,
        button: PropTypes.string,
    };

    static defaultProps = {
        closable: true,
    };

    onSubmit = (event) => {
        event.preventDefault();
        this.props.onAction();
    };

    render() {
        const { onAction, onClose, closable, html, icon, title, description, button, button_link, target, targetRect } = this.props;

        return (
            <FloatingTooltip
                target={target | targetRect}
                placement={ETooltipPlacement.right}
                closable={closable}
                onClose={onClose}
                qaId="tooltip-space-promo"
            >
                <form onSubmit={this.onSubmit}>
                    <div className={styles.wrapper}>
                        {title && <div className={styles.title}>{title}</div>}
                        <div className={styles.content}>
                            {icon && <div className={styles.icon}>{icon}</div>}
                            {html ? <span dangerouslySetInnerHTML={{ __html: description }} /> : <span>{description}</span>}
                        </div>
                        {button && (
                            <div className={styles.buttons}>
                                {button_link ? (
                                    <ButtonLink onClick={onAction} primary>
                                        {button}
                                    </ButtonLink>
                                ) : (
                                    <Button type="submit" theme="octavius">
                                        {button}
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                </form>
            </FloatingTooltip>
        );
    }
}
