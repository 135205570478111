import { FC, ReactElement, ReactNode, RefObject } from 'react';
import { EActions } from 'reactApp/modules/storage/storage.types';
import { DropdownTheme, ListItem } from 'reactApp/ui/DropdownList/DropdownList';
import { EHeaderColor } from 'reactApp/ui/Header/HeaderWrapper';

export type TToolbarDropdownItem = ListItem & { hotkeyLabel?: string; withStatus?: boolean; active?: boolean; asc?: boolean };

export enum EDropdownPosition {
    right = 'right',
    left = 'left',
}

export enum EToolbarItemMod {
    blue = 'blue',
    filename = 'filename',
}

export enum EToolbarItemTooltip {
    publicCreateCloud = 'public-create-cloud',
}

export interface IToolbarItemProps {
    className?: string;
    id?: EActions | string;
    promoId?: string;
    text?: string | ReactElement;
    onClick?: (id, onSuccess, event?) => void;
    hint?: string;
    icon?: ReactNode;
    collapse?: boolean;
    parentRef?: RefObject<HTMLElement>;
    list?: TToolbarDropdownItem[];
    withoutDropdownIcon?: boolean;
    dropdownTheme?: DropdownTheme;
    dropdownPosition?: EDropdownPosition;
    openDropdownByArrow?: boolean;
    mod?: EToolbarItemMod;
    tooltipId?: string;
    maxDropdownListHeight?: number;
    isDropdownListOpen?: boolean;
    color?: EHeaderColor;
    setIsDropdownListOpen?: (value: boolean) => void;
    renderDropdownListItem?: (item: ListItem, index?: number) => ReactElement;
    disabled?: boolean;
}

export enum ToolbarContextEnum {
    pageContentContext = 'page-content-context',
    headerContext = 'header-context',
}

export type ToolbarItemProps = Record<string, any> & { parentRef?: RefObject<HTMLElement> };
export type ToolbarItem = FC<ToolbarItemProps>;
export type TToolbarItem = IToolbarItemProps & {
    Item?: ToolbarItem | null;
    itemProps?: ToolbarItemProps;
    priority?: number;
    ref?: RefObject<any>;
    isEbookReader?: boolean;
};

export enum ToolbarGapMode {
    wide = 'wide',
    default = 'default',
}

export enum ToolbarActionsMode {
    right = 'right',
    left = 'left',
}

export interface ToolbarProps {
    gapMode?: ToolbarGapMode;
    toolbarType?: ToolbarContextEnum;
    isResponsiveEnable?: boolean;
    isEbookReader?: boolean;
    color?: EHeaderColor;

    onBookmark?(isActiveBookmark: boolean): void;

    onDownload?(): void;
}

export enum ToolbarDownloadFrom {
    reDownload = 'reDownload',
    toolbar = 'toolbar',
}
