import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { ActionbarItem, TActionbarItem } from 'reactApp/ui/Mobile/ActionsBar/customActions/useCustomActions';
import { QuotaDeleteButton } from 'reactApp/ui/Mobile/ActionsBar/QuotaDeleteButton/QuotaDeleteButton';
import { useDeleteItems } from 'reactApp/ui/QuotaCleanerPage/hooks/useDeleteItems';

export const getQuotaDeleteItem = (storage: EStorageType, items): TActionbarItem => {
    let hook;
    if (storage === EStorageType.quotaCleaner) {
        hook = useDeleteItems;
    }

    return {
        Item: hook ? (QuotaDeleteButton as ActionbarItem) : null,
        itemProps: { hook, items },
    };
};
