import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

export enum EPromoAction {
    SHOW = 'show',
    VIEW = 'view',
    CLICK = 'click',
    CLOSE = 'close',
}

interface IShowPromo {
    action: EPromoAction.SHOW;
    /* В каком разделе показали плашку */
    source: EStorageType;
    /* Название плашки */
    name: 'disable-adv' | 'disable-adv-button' | 'security';
}

interface IClickPromo {
    action: EPromoAction.CLICK;
    source: EStorageType;
    name: 'disable-adv' | 'disable-adv-button' | 'security';
    /* Название кнопки/ссылки на которую нажали */
    name_button: 'disable-adv' | 'security';
}

interface IClosePromo {
    action: EPromoAction.CLOSE;
    source: EStorageType;
    name: 'disable-adv' | 'disable-adv-button' | 'security';
}

type IPromoAnalytics = IShowPromo | IClickPromo | IClosePromo;

export const sendSplashscreenAnalytics = ({ action, ...rest }: IPromoAnalytics): void => {
    const platform = IS_PHONE_BROWSER ? 'touch' : 'desktop';

    sendDwh({
        eventCategory: ECategoryGa.splashscreen_alert,
        action,
        dwhData: {
            platform,
            ...rest,
        },
    });
};
