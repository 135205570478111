import classNames from 'clsx';
import { ReactComponent as PlayIcon } from 'img/icons/mobile/play_outline_20.svg';
import React, { memo, ReactElement, useCallback, useState } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { DataListControl } from 'reactApp/ui/DataListControl/DataListControl';
import { IProps } from 'reactApp/ui/Mobile/Datalist/DatalistGalleryItem/DatalistGalleryItem.types';
import { DatalistPic } from 'reactApp/ui/Mobile/Datalist/DatalistPic/DatalistPic';

import styles from './DatalistGalleryItem.css';

export const DatalistGalleryItem = memo(
    ({ isFolder, kind, thumbUrl, ext, isVirus, isSelected, isSelecting, name, onClick, id }: IProps): ReactElement => {
        const [isLoaded, setLoaded] = useState<boolean>(false);

        const onLoaded = useCallback(() => setLoaded(true), []);

        return (
            <div
                className={classNames({
                    [styles.root]: true,
                    [styles.root_loaded]: isLoaded || isFolder || !thumbUrl?.src,
                    [styles.root_selecting]: isSelecting,
                    [styles.root_selected]: isSelected,
                })}
                onClick={onClick}
                data-qa-id={id}
                data-qa-type={isFolder ? 'folder' : 'file'}
                data-qa-name={ext ? `${name}.${ext}` : name}
            >
                <div className={styles.content}>
                    <div className={styles.check}>
                        <DataListControl type="check" active={isSelected} size={28} viewType={EViewMode.galleryGrid3} />
                    </div>
                    <div className={styles.pic}>
                        <DatalistPic
                            isFolder={isFolder}
                            ext={ext || ''}
                            kind={kind}
                            thumbUrl={thumbUrl}
                            isVirus={isVirus}
                            viewMode={EViewMode.galleryGrid3}
                            onLoaded={onLoaded}
                        />
                    </div>
                    {kind === 'video' && (
                        <div className={styles.video}>
                            <PlayIcon />
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

DatalistGalleryItem.displayName = 'DatalistGalleryItem';
