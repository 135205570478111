import classNames from 'clsx';
import { ReactComponent as Icon } from 'img/icons/documents/informer_icon.svg';
import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enableDocumentsRecognition } from 'reactApp/modules/personalDocuments/personalDocuments.module';
import { getDocumentsRecognitionStatus } from 'reactApp/modules/personalDocuments/personalDocuments.selectors';
import { ERecognitionStatus } from 'reactApp/modules/personalDocuments/personalDocuments.types';
import { Informer } from 'reactApp/ui/Informer/Informer';
import { EInformerColor } from 'reactApp/ui/Informer/Informer.types';
import { noop } from 'reactApp/utils/helpers';

import styles from './DocumentsInformer.css';

interface IProps {
    sendGa: (event: string, label: string) => void;
    className?: string;
}

export const DocumentsInformer = memo(function DocumentsInformer({ sendGa, className }: IProps) {
    const dispatch = useDispatch();
    const documentsRecognitionStatus = useSelector(getDocumentsRecognitionStatus);
    const enableRecognition = useCallback(() => {
        dispatch(enableDocumentsRecognition());
        sendGa('recognition', 'enable');
    }, [dispatch, sendGa]);
    const icon = useMemo(() => <Icon className={styles.icon} />, []);

    if (![ERecognitionStatus.DISABLED, ERecognitionStatus.PROCESSING].includes(documentsRecognitionStatus)) {
        return null;
    }

    return (
        <div className={classNames(styles.informer, className)}>
            <Informer
                color={EInformerColor.grey}
                icon={icon}
                buttonText={documentsRecognitionStatus === ERecognitionStatus.DISABLED ? 'Включить распознавание' : ''}
                centered
                title={
                    documentsRecognitionStatus === ERecognitionStatus.DISABLED
                        ? 'Включите распознавание документов'
                        : 'Распознавание запущено'
                }
                onButtonClick={documentsRecognitionStatus === ERecognitionStatus.DISABLED ? enableRecognition : noop}
            >
                {documentsRecognitionStatus === ERecognitionStatus.DISABLED ? (
                    <>Мы автоматически найдём все ваши документы и сгруппируем их.</>
                ) : (
                    <>
                        Мы запустили распознавание в фоновом режиме. Облаку потребуется некоторое время, чтобы найти все ваши документы. Как
                        только всё будет готово, мы отправим вам письмо.
                    </>
                )}
            </Informer>
        </div>
    );
});
