import { Spacing } from '@vkontakte/vkui';
import classNames from 'clsx';
import React from 'react';
import { Placeholder } from 'reactApp/ui/Placeholder/Placeholder';

import styles from './MobileTariffCardLoader.css';

export const MobileTariffCardLoader = ({ isExpand, isDark }: { isExpand?: boolean; isDark?: boolean }) => (
    <div className={classNames(styles.root, { [styles.root_dark]: isDark })}>
        <Spacing size={78} />
        <div className={styles.quota}>
            <Placeholder />
        </div>

        {isExpand && (
            <>
                <Spacing size={25} />

                <div className={styles.informer}>
                    <Placeholder />
                </div>

                <Spacing size={31} />

                <div className={styles.features}>
                    {Array.from(new Array(4)).map(() => (
                        <>
                            <div className={styles.feature}>
                                <Placeholder />
                            </div>
                            <Spacing size={15} />
                        </>
                    ))}
                </div>
            </>
        )}

        <Spacing size={isExpand ? 31 : 47} />
        <div className={styles.buttons}>
            <div className={styles.button}>
                <Placeholder />
            </div>
            <div className={styles.button}>
                <Placeholder />
            </div>
        </div>
    </div>
);
