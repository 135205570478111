import React, { ComponentType } from 'react';
import { popupNames } from 'reactApp/modules/popup/popup.types';
import { Popup, Props as PopupProps } from 'reactApp/ui/Popup/Popup';

export function getPopupProps<P>({ name, component }: { name: keyof typeof popupNames; component: ComponentType<P> }) {
    return {
        name,
        component,
        modal: (props: PopupProps) => <Popup {...props} name={name} />,
    };
}
