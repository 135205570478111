import React, { memo, ReactElement, useCallback, useEffect } from 'react';
import { openVkBuyModalWithSidebar } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.helpers';
import { EPaymentModalType } from 'reactApp/components/VkBuyModalWithSidebar/VkBuyModalWithSidebar.types';
import { Button } from 'reactApp/ui/Button/Button';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './TrialBanner.css';

const BANNER_NAME = 'welcome-trial';

export const TrialBanner = memo(({ trialId }: { trialId: string }): ReactElement => {
    const onClick = useCallback(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.banner, action: 'click', name_banner: BANNER_NAME });
        openVkBuyModalWithSidebar({ productId: trialId, type: EPaymentModalType.trial });
    }, [trialId]);

    useEffect(() => {
        sendPaymentGa({ eventCategory: ECategoryGa.banner, action: 'view', name_banner: BANNER_NAME });
    }, []);

    return (
        <div className={styles.root}>
            <div className={styles.text}>
                Получите <span>128 ГБ </span>
                <br />
                за 1 ₽ на месяц
            </div>
            <div>
                <Button data-name="welcome-trial" theme="octavius" primary onClick={onClick}>
                    <div className={styles.buttonText}>Получить</div>
                </Button>
            </div>
        </div>
    );
});

TrialBanner.displayName = 'TrialBanner';
