import { BuyBusinessAPICall } from 'reactApp/api/BuyBusinessAPICall';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';

export const requestBusinessTariff = ({ name, login, phone }): Promise<any> => {
    const formData = new FormData();
    formData.append('need_json', '1');
    formData.append('fb.user_name', name);
    formData.append('fb.communication_email', login);
    formData.append('fb.question.56062', phone);

    return new BuyBusinessAPICall().makeRequest(formData);
};

export const renderBuyBusinessDialog = (data = {}) => openPopupHelper({ popupName: popupNames.BUY_BUSINESS_DIALOG, data });
