import React, { memo, ReactElement } from 'react';

import styles from './Informer.css';

export const Informer = memo(({ icon, text }: { icon?: ReactElement; text: string }): ReactElement => {
    return (
        <div className={styles.root}>
            <div className={styles.icon}>{icon}</div>
            {text}
        </div>
    );
});

Informer.displayName = 'Informer';
