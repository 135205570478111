export interface State {
    isLoading: boolean;
    isLoaded: boolean;
    isError: boolean;
}

export enum MalwareStatus {
    infected = 'infected',
    unknown = 'unknown',
    ok = 'ok',
}
