export const PurchasedGiftsFetch = {
    NAME: '@purchasedGifts/fetch',
    REQUEST: '@purchasedGifts/fetch/request',
    SUCCESS: '@purchasedGifts/fetch/success',
    FAILURE: '@purchasedGifts/fetch/failure',
};

export const PurchasedGiftsUpdate = {
    NAME: '@purchasedGifts/update',
    REQUEST: '@purchasedGifts/update/request',
    SUCCESS: '@purchasedGifts/update/success',
    FAILURE: '@purchasedGifts/update/failure',
};
