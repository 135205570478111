import React, { FC, SVGProps, VFC } from 'react';

import styles from './SubscriptionInfoItem.css';

type Props = {
    title: string;
    subtitle: string;
    Icon: FC<SVGProps<SVGSVGElement>>;
    buttonText: string;
    showButton?: boolean;
    onClick: () => void;
};

export const SubscriptionInfoItem: VFC<Props> = ({ title, subtitle, Icon, buttonText, onClick, showButton = true }) => (
    <div className={styles.root}>
        <Icon className={styles.icon} width={28} height={28} />
        <div className={styles.container}>
            <span className={styles.title}>{title}</span>
            <span className={styles.subtitle}>{subtitle}</span>
            {showButton && (
                <div className={styles.button} onClick={onClick}>
                    {buttonText}
                </div>
            )}
        </div>
    </div>
);
