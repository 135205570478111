import React, { useCallback, useMemo } from 'react';
import { getDomainAccessOptions } from 'reactApp/components/SharingNewBiz/SharingNew.constants';
import { DomainAccess, DomainAccessOptions } from 'reactApp/components/SharingNewBiz/SharingNew.types';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';

import { DomesticDropdownTemplate } from '../DomesticDropdownTemplate/DomesticDropdownTemplate';
import { UserDomesticProps } from './DomesticDropdown.types';
import { useDomestic } from './hooks/useDomestic';

export const UserDomesticDropdown: React.FC<UserDomesticProps> = ({ item, checked, onChecked, domain, disabled }) => {
    const isFolder = item?.isFolder;
    const isPhone = EnvironmentSelectors.isPhone();

    const domainAccessOptions = useMemo(() => {
        const domainAccessOptions: Partial<Record<DomainAccess, DomainAccessOptions>> = getDomainAccessOptions(domain);
        const accessOptions = { ...domainAccessOptions };
        delete accessOptions[DomainAccess.OFF];
        return accessOptions;
    }, [domain]);

    const { renderDropdownMenu } = useDomestic({
        checked,
        item,
        domainAccessOptions,
    });

    const updateDomestic = useCallback(
        (id: DomainAccess) => {
            onChecked(id);
        },
        [onChecked]
    );

    return (
        <DomesticDropdownTemplate
            checked={checked}
            accessText={isFolder ? 'Папка доступна' : 'Файл доступен'}
            isPhone={isPhone}
            renderDomesticDropdown={renderDropdownMenu}
            handleUpdateDomestic={updateDomestic}
            domain={domain}
            disabled={disabled}
        />
    );
};
