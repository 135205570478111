import config from 'Cloud/config';
import classNames from 'clsx';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { Button } from 'reactApp/ui/Button/Button';
import { Dropdown } from 'reactApp/ui/Dropdown/Dropdown';
import { DropdownMenu } from 'reactApp/ui/Dropdown/DropdownMenu';
import { SocialButtons } from 'reactApp/ui/SocialButtons/SocialButtons';
import { LinkIcon, MailIcon } from 'reactApp/ui/VKUIIcons';
import { copy } from 'reactApp/utils/copyToClipboard';

import styles from './ViewerShareButton.css';

const IS_ONPREMISE = config.get('ON_PREMISE');

const isMobile = EnvironmentSelectors.isMobile();

const isNativeShareSupported = isMobile && 'share' in navigator && typeof navigator.share === 'function';

const mapDispatchToProps = (dispatch): IDispatchToProps => ({
    showNotify: (options) => dispatch(showSnackbarAction(options)),
});

interface IDispatchToProps {
    showNotify: (data) => void;
}

interface IProps extends IDispatchToProps {
    onSocial?: (data) => void;
    socialList?: string[];
    placement: string;
    basic?: boolean;
    sendGaRadar?: (data) => void;
}

class ViewerShareButtonComponent extends PureComponent<IProps> {
    copyLink = () => {
        const { showNotify } = this.props;

        copy(window.location.href)
            .then(({ hasBeenCopied }) => {
                if (hasBeenCopied) {
                    showNotify({
                        text: 'Ссылка успешно скопирована',
                        type: SnackbarTypes.success,
                        id: 'copyLink',
                        closable: true,
                    });
                }
            })
            .catch(() => {
                showNotify({
                    text: 'Не удалось скопировать ссылку',
                    type: SnackbarTypes.failure,
                    id: 'copyLink',
                    closable: true,
                });
            });
    };

    handleOnSocial = (id) => {
        const { sendGaRadar, onSocial } = this.props;

        if (sendGaRadar) {
            sendGaRadar(`${id}-click`);
        }

        onSocial?.(id);
    };

    handleOnSharingClick = (id) => {
        switch (id) {
            case 'send':
                return this.handleOnSocial('email');
            case 'copy':
                return this.copyLink();
        }
    };

    handleOnButtonClick = () => {
        const { sendGaRadar } = this.props;

        if (sendGaRadar) {
            sendGaRadar('share-click');
        }
    };

    onNativeShare = () => {
        const { sendGaRadar } = this.props;

        if (isNativeShareSupported) {
            navigator
                .share({
                    url: window.location.href,
                })
                .catch(() => {
                    if (sendGaRadar) {
                        sendGaRadar('share-error');
                    }
                });
        }
    };

    renderSharingDropdown = () => {
        const list = [
            {
                id: 'copy',
                text: 'Скопировать ссылку',
                icon: <LinkIcon />,
            },
        ];

        if (!IS_ONPREMISE) {
            list.push({
                id: 'send',
                text: 'Отправить по почте',
                icon: <MailIcon />,
            });
        }

        return (
            <>
                <DropdownMenu list={list} onClick={this.handleOnSharingClick} />
                {this.props.socialList && (
                    <div className={styles.socials}>
                        <SocialButtons size="big" dark socials={this.props.socialList} onClick={this.handleOnSocial} />
                    </div>
                )}
            </>
        );
    };

    render() {
        const { basic, placement } = this.props;
        return (
            <div
                className={classNames({
                    [styles.root_basic]: basic,
                })}
            >
                {isNativeShareSupported ? (
                    <Button onClick={this.onNativeShare} size="big" basic={basic} color={basic ? undefined : 'black'} theme="octavius">
                        <LinkIcon className={styles.icon} fill="currentColor" />
                        <span className={styles.text}>Поделиться</span>
                    </Button>
                ) : (
                    <Dropdown
                        as={Button}
                        size="big"
                        theme="octavius"
                        basic={basic}
                        color={basic ? null : 'black'}
                        content={this.renderSharingDropdown()}
                        placement={placement}
                    >
                        <div onClick={this.handleOnButtonClick} className={styles.link}>
                            <LinkIcon className={styles.icon} fill="currentColor" />
                            <span className={styles.text}>Поделиться</span>
                        </div>
                    </Dropdown>
                )}
            </div>
        );
    }
}

export const ViewerShareButton = connect(null, mapDispatchToProps)(ViewerShareButtonComponent);
