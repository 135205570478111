import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { downloadAsJpeg } from 'Cloud/Application/DownloadAsJpeg';
import classNames from 'clsx';
import { isNil } from 'ramda';
import React, { memo, useCallback } from 'react';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { useViewContext } from 'reactApp/hocs/withViewContext';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { DataListControl } from 'reactApp/ui/DataListControl/DataListControl';
import { sendGa } from 'reactApp/utils/ga';

import styles from './DownloadVK.css';

interface IProps {
    onClick: (event) => void;
    size?: number;
    ext?: string;
    id: string;
    storage?: string;
    show?: boolean;
    isLarge?: boolean;
    isHidden?: boolean;
}

export const DownloadVK = memo(({ onClick, ext, size, id, storage, show = true, isLarge, isHidden = false }: IProps) => {
    const sizeTitle = isNil(size) ? undefined : bytesToNDigits(size, 3).value;
    const downloadHint = `Скачать${ext ? ` .${ext.toLowerCase()}` : ''}${sizeTitle ? `${ext ? `, ${sizeTitle}` : ` ${sizeTitle}`}` : ''}`;
    const viewContext = useViewContext();

    const handleClick = useCallback(
        (event) => {
            sendGa('datalist-control', 'click', 'download', EViewMode.list);

            onClick(event);
        },
        [onClick]
    );

    return (
        <div
            className={classNames(styles.download, { [styles.downloadResponsive]: ENABLE_FULL_RESPONSIVE, [styles.hidden]: isHidden })}
            onClick={handleClick}
        >
            {show ? (
                ext && downloadAsJpeg.isAvailable({ ext, storage: storage as EStorageType }) && storage !== EStorageType.archive ? (
                    <DataListControl
                        type="downloadAsJpeg"
                        onClick={onClick}
                        list={downloadAsJpeg.getDropdownItems({ itemOrId: id, gaSuffix: 'datalist', ext, textPrefix: 'в', storage })}
                        hint={downloadHint}
                        size={isLarge ? 28 : 24}
                        viewType={EViewMode.list}
                    />
                ) : (
                    <DataListControl
                        type={viewContext.isVKEmbedded ? 'downloadVK' : 'download'}
                        hint={downloadHint}
                        size={isLarge ? 28 : 24}
                        viewType={EViewMode.list}
                    />
                )
            ) : null}
        </div>
    );
});

DownloadVK.displayName = 'Download';
