import { captureException } from '@sentry/browser';
import { Rubles } from 'Cloud/Application/Rubles';
import config from 'Cloud/config';
import classNames from 'clsx';
import React, { memo, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import {
    HIDE_ADS,
    IS_B2B_BIZ_USER,
    IS_CHOSEN_PUBLIC_FILE,
    IS_MOBILE_OR_TABLET_BROWSER,
    IS_PHONE_BROWSER,
    PUBLIC_SHOVE,
} from 'reactApp/appHelpers/configHelpers';
import { ADS_TIMER_MS } from 'reactApp/constants';
import { useShowAdsBannerTimer } from 'reactApp/hooks/useShowAdsBannerTimer';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { isWelcomePromoShow } from 'reactApp/modules/welcome/welcome.selectors';
import { DisableButton } from 'reactApp/ui/AdvBanners/DisableButton/DisableButton';

import styles from './SidebarBanner.css';

const DOM_ID_HOME_SIDEBAR = config.get('DOM_ID_HOME_SIDEBAR');

export const SidebarBanner = memo((): ReactElement | null => {
    const isViewerActive = useSelector(ViewerSelectors.isViewerActive);

    const isWelcomePromo = useSelector(isWelcomePromoShow);

    const chosenPublicFile = IS_CHOSEN_PUBLIC_FILE && !IS_PHONE_BROWSER;
    const showAD =
        DOM_ID_HOME_SIDEBAR &&
        !HIDE_ADS &&
        !PUBLIC_SHOVE &&
        !IS_MOBILE_OR_TABLET_BROWSER &&
        !IS_B2B_BIZ_USER &&
        !chosenPublicFile &&
        !isViewerActive &&
        !isWelcomePromo;

    const updateAds = () => {
        if (!showAD) {
            return;
        }

        try {
            Rubles?.updateHomeSidebar();
        } catch (error) {
            captureException(error);
        }
    };

    const renderItem = () =>
        showAD ? (
            <div className={styles.root}>
                <DisableButton className={styles.button} id="sidebar-ad" />
                <div className={classNames(styles.content)} id={DOM_ID_HOME_SIDEBAR} />
            </div>
        ) : null;

    return useShowAdsBannerTimer({
        adsUpdateTimerMs: ADS_TIMER_MS,
        updateAds,
        suspendTimer: !showAD,
        renderItem,
    });
});

SidebarBanner.displayName = 'SidebarBanner';
