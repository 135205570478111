import classNames from 'clsx';
import React, { memo } from 'react';
import { datalistInlineRenameEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { useRenameInlineDoubleClick } from 'reactApp/hooks/useRenameInlineDoubleClick';
import { EInlineEditPlace } from 'reactApp/modules/modifying/modifying.types';
import { ContentEditable } from 'reactApp/ui/ContentEditable/ContentEditable';
import { NameComponent } from 'reactApp/ui/Name/Name';
import { noop } from 'reactApp/utils/helpers';

import styles from './ItemName.css';

interface ItemNameProps {
    className?: string;
    name: string;
    ext?: string;
    loading?: boolean;
    id: string;
    isRenameAvailable?: boolean;
}

export const ItemName = memo<ItemNameProps>(function ItemName({ className, loading, name, ext, id, isRenameAvailable = false }) {
    const { isEdit, handleDoubleClick, onRename } = useRenameInlineDoubleClick(name, id, EInlineEditPlace.datalistRow, ext);

    return (
        <div
            className={classNames(styles.root, className, {
                [styles.loading]: loading,
            })}
        >
            {isEdit ? (
                <div className={styles.edit}>
                    <ContentEditable value={name} onEdit={onRename} selectAllOnFocus />
                </div>
            ) : (
                <NameComponent
                    name={name}
                    extension={ext}
                    truncate
                    showTooltip
                    onDoubleClick={isRenameAvailable && datalistInlineRenameEnabled ? handleDoubleClick : noop}
                />
            )}
        </div>
    );
});
