import { Icon20Cancel } from '@vkontakte/icons';
import { IconButton, IconButtonProps } from '@vkontakte/vkui';
import React from 'react';

import styles from './ClearButton.css';

export const ClearButton: React.FC<IconButtonProps> = ({ onClick, ...rest }) => (
    <IconButton className={styles.button} hoverMode={styles.hover} hasActive={false} aria-label="Очистить поле" onClick={onClick} {...rest}>
        <Icon20Cancel />
    </IconButton>
);

ClearButton.displayName = 'ClearButton';
