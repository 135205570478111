import classNames from 'clsx';
import React, { memo } from 'react';
import { Tariff } from 'reactApp/types/Billing';
import { useYearSaving } from 'reactApp/ui/TariffSectionNew/hooks/useYearSaving';
import { getTariffProductsByPeriods } from 'reactApp/utils/Period';

import styles from './DiscountLabel.css';

export const DiscountLabel = memo(({ tariff, discount, isAction }: { tariff?: Tariff; discount?: string | number; isAction?: boolean }) => {
    const { yearProduct, monthProduct } = getTariffProductsByPeriods(tariff?.products || []);

    const [_, yearSavingDiscount] = useYearSaving({ yearProduct, monthProduct });

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_action]: isAction,
            })}
        >
            - {yearSavingDiscount || discount}%
        </div>
    );
});

DiscountLabel.displayName = 'DiscountLabel';
