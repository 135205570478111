import { Link } from '@vkontakte/vkui';
import React, { HTMLAttributes } from 'react';
import { useFallbackSearchUrl } from 'reactApp/hooks/useFallbackSearchUrl';

import { BaseMessage } from '../BaseMessage/BaseMessage';
import styles from './ErrorSearch.css';
import { ReloadButton } from './ReloadButton/ReloadButton';

interface IProps extends HTMLAttributes<HTMLDivElement> {
    isMobile?: boolean;
    isPopup?: boolean;
}

export const ErrorSearch: React.FC<IProps> = ({ isMobile = false, isPopup = false, ...props }) => {
    const { closeSearch } = useFallbackSearchUrl();

    const text = isPopup ? (
        'Попробуйте повторить поиск через несколько минут'
    ) : (
        <>
            Попробуйте обновить страницу через несколько минут или{' '}
            <Link onClick={closeSearch} data-qa-id="resetSearch">
                сбросьте поиск.
            </Link>
        </>
    );

    return (
        <>
            <BaseMessage img={<div className={styles.image} />} title="Не удалось выполнить поиск" text={text} {...props}>
                {!isMobile && !isPopup && <ReloadButton isMobile data-qa-id="reloadSearch" />}
            </BaseMessage>
            {isMobile && !isPopup && <ReloadButton isMobile data-qa-id="reloadSearch" />}
        </>
    );
};

ErrorSearch.displayName = 'ErrorSearch';
