import { Space } from '@mail/cross-sizes-utils';
import { ReactNode } from 'react';

export enum ESplashScreenMod {
    blue = 'blue',
    red = 'red',
    faces = 'faces',
}

export interface ISplashScreenContent {
    id: string;
    img: string;
    title: string;
    description: ReactNode;
    buttonText: string | ReactNode;
    footerText?: string;
    onClick: () => void;
    mod?: ESplashScreenMod;
}

export type HookParams = {
    productId?: string;
    source?: string;
    oldSubscriptionSpace?: Space;
    isTransit?: boolean;
};

export type UseContentHook = (props: { hookParams?: HookParams; onClose?: () => void }) => ISplashScreenContent;

export interface ISplashScreenProps {
    contentHook: UseContentHook;
    hookParams: HookParams;
    onClose: () => void;
}
