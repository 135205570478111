/**
 * Сортирует редакторы с учетом полей default и order
 *
 * Добавление полей order произощло в задаче CLOUDWEB-15451.
 * Для сохранения совместимости и обработки логики b2b в первую очередь учитываем поле default.
 * Поле order начинается с 1, поэтому значение 0 не учитывали
 * Для корректного запуска тестов используется force
 */
export const sortEditors = <
    Sortable extends {
        force?: boolean;
        default?: boolean | string;
        order?: number;
    }
>(
    a: Sortable,
    b: Sortable
): number => {
    if (a.force && !b.force) {
        return -1;
    } else if (!a.force && b.force) {
        return 1;
    } else if (a.force && b.force) {
        return 0;
    } else if (a.default && !b.default) {
        return -1;
    } else if (!a.default && b.default) {
        return 1;
    } else if (a.default && b.default) {
        return 0;
    } else if (a.order && b.order) {
        return a.order - b.order;
    } else if (a.order && !b.order) {
        return -1;
    } else if (!a.order && b.order) {
        return 1;
    }

    return 0;
};
