import React, { ReactElement, useCallback, useState } from 'react';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';
import { getNameById } from 'reactApp/modules/file/utils';

import { IProps } from './SharingNewMount.types';

export const SharingNewMount = React.memo(function SharingNewMount(props: IProps): ReactElement {
    const { info, onClose, onMount } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const folderName = info.name || getNameById(info.home || '');

    const onConfirm = useCallback(() => {
        setLoading(true);
        onMount();
    }, [onMount]);

    const renderHeader = useCallback(() => <div>Подключить папку</div>, []);

    const renderContent = useCallback(() => {
        return (
            <div>
                Вы действительно хотите подключить к своему Облаку папку «{folderName}»{' '}
                {info.access === 'read_only' ? '(только просмотр)' : '(редактирование)'} пользователя «{info.owner.email}»?
            </div>
        );
    }, [info.access, folderName, info.owner.email]);

    return (
        <BaseConfirmDialog
            renderHeader={renderHeader}
            successButtonText="Подключить"
            cancelButtonText="Отменить"
            buttons={[EButtonType.accept, EButtonType.cancel]}
            onClose={onClose}
            onSuccess={onConfirm}
            renderContent={renderContent}
            loading={loading}
        />
    );
});
