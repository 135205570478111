import classNames from 'clsx';
import React from 'react';
import { Placeholder } from 'reactApp/ui/Placeholder/Placeholder';

import styles from './MemberCard.css';

export const LoadingCard = () => {
    return (
        <div className={classNames(styles.item, styles.item_loading)}>
            <Placeholder />
        </div>
    );
};
