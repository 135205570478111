import { abRequiredSignUpWhenDownloadingTouchSelector } from 'reactApp/appHelpers/featuresHelpers';
import { chooseVariant } from 'reactApp/appHelpers/featuresHelpers/utils';
import { createGaSender, sendDwh, sendXray } from 'reactApp/utils/ga';

export const sendGaAb = createGaSender('pubfile');

export const handleOnMobileApp = () => {
    const deepLink = `?mt_deeplink=${window.location.href}`;
    const link = chooseVariant(() => abRequiredSignUpWhenDownloadingTouchSelector(false), {
        control: 'https://trk.mail.ru/c/kw1fh0',
        variant1: 'https://trk.mail.ru/c/tyhs60',
        variant2: 'https://trk.mail.ru/c/ydprp5',
    });

    sendXray(['public-file-view', 'dd-ab-click', 'blu']);
    sendXray(['public-file-view_dl-ab-click_blu'], null, { sendImmediately: true });
    sendGaAb('blu', 'click', null, true);
    sendDwh({
        eventCategory: 'ads-touch',
        action: 'click',
    });
    setTimeout(() => {
        window.location.href = link + deepLink;
    }, 100);
};
