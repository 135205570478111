import { MYOFFICE_VARIANTS } from 'Cloud/Application/Editor/MyOffice/myOffice.types';
import { xray } from 'lib/xray';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isWopiEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { getEditorItem } from 'reactApp/modules/editor/editor.selectors';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

export enum LLMAnalyticsType {
    BANNER_SHOW = 'banner-show',
    BANNER_CLICK = 'banner-click',
    BANNER_CLOSE = 'banner-close',
    BOTTOM_SHOW = 'bottom-show',
    BOTTOM_HOVER = 'bottom-hover',
    BOTTOM_CLICK = 'bottom-click',
    CHAT_OPEN = 'chat-open',
    CHAT_CLOSE = 'chat-close',
    QUESTION_SEND = 'question-send',
    SCENARIO_CHANGE = 'scenario-change',
    ERROR_SHOW = 'error-show',
    ANSWER_SHOW = 'answer-show',
    ANSWER_COPY = 'answer-copy',
    ANSWER_RATE_GOOD = 'answer-rate-good',
    ANSWER_RATE_BAD = 'answer-rate-bad',
    ANSWER_MENU_CLICK = 'answer-menu-click',
    CONTINUE_CLICK = 'continue-click',
    REWRITE_CLICK = 'rewrite-click',
    DELETE_CLICK = 'delete-click',
}

export const sendTechLLMAnalytics = ({ action, result, info }: { action: LLMAnalyticsType; result?: 'success' | 'error'; info?: string }) =>
    xray.send(`${ECategoryGa.gpt}-${action}${result ? `-${result}` : ''}${info ? `-${info}` : ''}`);

export const useGetLLMAnalytics = () => {
    const item = useSelector(getEditorItem);

    return useCallback(
        ({ action, dwh = {} }: { action: LLMAnalyticsType; dwh?: Record<string, string> }) => {
            const { size = 0, id = '', storage = '', ext = '' } = item || {};

            sendDwh({
                eventCategory: ECategoryGa.gpt,
                action,
                dwhData: {
                    size_file: size,
                    id_media: id,
                    source: storage,
                    version: isWopiEnabled ? MYOFFICE_VARIANTS.wopi : MYOFFICE_VARIANTS.amr,
                    extension: ext,
                    ...dwh,
                },
            });
        },
        [item]
    );
};
