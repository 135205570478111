import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { createGaSender } from 'reactApp/utils/ga';
import { scrollLock, scrollUnlock } from 'reactApp/utils/scrollLock';

import styles from './MobileDropdown.css';

export enum MobileDropdownTheme {
    public = 'public',
}

export interface MobileDropdownItemInterface {
    id: string;
    text: string;
    icon?: ReactElement;
    onClick?();
    items?: MobileDropdownItemInterface[];
}

interface Props {
    items: MobileDropdownItemInterface[];
    onClose?();
    gaId?: string;
    theme?: MobileDropdownTheme;
    header?: string;
}

export const MobileDropdown = memo(({ items, theme, header, onClose, gaId = 'mobile-dropdown' }: Props): ReactElement => {
    const [opened, setOpened] = useState<null | string>(null);
    const [visible, setVisible] = useState<boolean>(true);

    const rootRef = useRef<null | any>(null);

    const sendGa = createGaSender(gaId);

    useEffect(() => {
        sendGa('show-dropdown');

        const el = rootRef?.current;

        scrollLock(el, { reserveScrollBarGap: false });

        return () => {
            scrollUnlock(el);
        };
    }, []);

    const handleClose = useCallback((): void => {
        setVisible(false);
        if (onClose) {
            setTimeout(onClose, 250);
        }
    }, [onClose]);

    const handleOnItemClick = useCallback(
        (item: MobileDropdownItemInterface) => (): void => {
            sendGa('click-dropdown', item.id);

            if (item.items) {
                setOpened(item.id);
            } else if (item.onClick) {
                item.onClick();
                handleClose();
            }
        },
        [handleClose]
    );

    const renderItems = useMemo(() => {
        const list = (opened ? items.find((item) => item.id === opened)?.items : items) || [];

        return list.map((item) => {
            const { icon, text, id } = item;
            return (
                <div className={styles.item} key={id} onClick={handleOnItemClick(item)} data-name={id}>
                    {icon && <div className={styles.itemIcon}>{icon}</div>}
                    <div className={styles.itemText}>{text}</div>
                </div>
            );
        });
    }, [opened, handleOnItemClick, items]);

    return (
        <>
            <CSSTransition
                in={visible}
                timeout={250}
                key={opened ? opened : 'root'}
                appear
                classNames={{
                    exit: styles.animation_exit,
                    exitActive: styles.animation_exit_active,
                    enter: styles.animation_appear,
                    enterActive: styles.animation_appear_active,
                    appear: styles.animation_appear,
                    appearActive: styles.animation_appear_active,
                }}
            >
                <div
                    className={classNames({
                        [styles.root]: true,
                        [styles[`root_${theme}`]]: !!theme,
                    })}
                    ref={rootRef}
                >
                    {header && <div className={styles.header}>{header}</div>}
                    {renderItems}
                </div>
            </CSSTransition>
            <CSSTransition
                in={visible}
                timeout={250}
                appear
                classNames={{
                    exit: styles.overlay_exit,
                    exitActive: styles.overlay_exit_active,
                    appear: styles.overlay_appear,
                    appearActive: styles.overlay_appear_active,
                }}
            >
                <div className={styles.overlay} onClick={handleClose} />
            </CSSTransition>
        </>
    );
});

MobileDropdown.displayName = 'MobileDropdown';
