import classNames from 'clsx';
import PropTypes from 'prop-types';
import { path, pathOr,prop } from 'ramda';
import React, { Component, Fragment } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import Content from 'reactApp/ui/Content/Content';
import { Dialog } from 'reactApp/ui/Dialog/Dialog';
import { noop } from 'reactApp/utils/helpers';

import Pager from './Pager';
import styles from './Wizard.css';

export default class Wizard extends Component {
    static propTypes = {
        onClose: PropTypes.func,
        onComplete: PropTypes.func,
        onShown: PropTypes.func,
        buttonFinishCaption: PropTypes.string,
        buttonNextCaption: PropTypes.string,
        buttonBackCaption: PropTypes.string,
        isLoading: PropTypes.bool,
        isError: PropTypes.bool,
        minHeight: PropTypes.bool,
        closeOnEscape: PropTypes.bool,
        size: PropTypes.oneOf(['small', 'medium', 'large']),
    };

    static defaultProps = {
        onClose: noop,
        onComplete: noop,
        onShown: noop,
        buttonFinishCaption: 'Завершить',
        buttonNextCaption: 'Продолжить',
        buttonBackCaption: 'Вернуться',
        size: 'medium',
        minHeight: true,
        closeOnEscape: true,
    };

    state = {
        currentStepIdx: 0,
    };

    componentDidMount() {
        this.props.onShown(0);
    }

    getChildren = () => (Array.isArray(this.props.children) ? this.props.children.filter((item) => !!item) : [this.props.children]);

    getCurrentWizardStep = (currentStepIdx) => prop(currentStepIdx, this.getChildren());

    isStepValid = (wizardStep) => {
        if (!wizardStep) {
            return null;
        }

        if (typeof wizardStep.props.validateStep === 'function') {
            return wizardStep.props.validateStep();
        }

        return true;
    };

    handleClose = () => {
        const { currentStepIdx } = this.state;
        this.props.onClose(currentStepIdx);
    };

    handleClickPrev = () => {
        const { currentStepIdx } = this.state;
        if (currentStepIdx <= 0) {
            return;
        }
        const prevStep = this.getCurrentWizardStep(currentStepIdx - 1);
        const disableBack = path(['props', 'disableBack'], prevStep);

        if (disableBack) {
            return;
        }

        this.props.onShown(currentStepIdx - 1);

        this.setState({
            currentStepIdx: currentStepIdx - 1,
        });
    };

    handleClickNext = () => {
        const children = this.getChildren();
        const { currentStepIdx } = this.state;
        if (currentStepIdx > children.length - 1) {
            return;
        }
        const wizardStep = this.getCurrentWizardStep(currentStepIdx);
        if (!this.isStepValid(wizardStep)) {
            return;
        }
        const onNextClick = path(['props', 'onNextClick'], wizardStep);
        if (typeof onNextClick === 'function') {
            onNextClick();
        }

        if (currentStepIdx === children.length - 1) {
            this.props.onComplete();
            this.handleClose();
            return;
        }

        this.props.onShown(currentStepIdx + 1);

        this.setState({
            currentStepIdx: currentStepIdx + 1,
        });
    };

    handleStepClick = (currentStepIdx, nextStepIdx) => {
        const wizardStep = this.getCurrentWizardStep(currentStepIdx);
        if (nextStepIdx > currentStepIdx && !this.isStepValid(wizardStep)) {
            return;
        }

        const wizardStepNext = this.getCurrentWizardStep(nextStepIdx);
        const disableBack = path(['props', 'disableBack'], wizardStepNext);
        if (disableBack) {
            return;
        }

        if (currentStepIdx === nextStepIdx) {
            return;
        }

        this.props.onShown(nextStepIdx);

        this.setState({
            currentStepIdx: nextStepIdx,
        });
    };

    renderFooter = (currentStepIdx, step, footerComponent) => {
        const { buttonFinishCaption, buttonNextCaption, buttonBackCaption, isLoading } = this.props;
        const children = this.getChildren();

        const wizardStepPrev = this.getCurrentWizardStep(currentStepIdx - 1);
        const disableBack = path(['props', 'disableBack'], wizardStepPrev);
        const showPager = path(['props', 'showPager'], step);
        const primaryNext = path(['props', 'primaryNext'], step);

        return (
            <div className={styles.footer}>
                {currentStepIdx !== 0 && (
                    <Button className={styles.button} onClick={this.handleClickPrev} fluid disabled={disableBack} theme="octavius">
                        {buttonBackCaption}
                    </Button>
                )}
                {footerComponent}
                {showPager && <Pager currentStepIdx={currentStepIdx} numberOfSteps={children.length} onStepClick={this.handleStepClick} />}
                <Button
                    className={styles.button}
                    onClick={this.handleClickNext}
                    fluid
                    disabled={!this.isStepValid(step) || isLoading}
                    primary={primaryNext}
                    loading={isLoading}
                    theme="octavius"
                >
                    {currentStepIdx === children.length - 1 ? buttonFinishCaption : buttonNextCaption}
                </Button>
            </div>
        );
    };

    render() {
        const { currentStepIdx } = this.state;
        const { size, minHeight, isLoading, isError, closeOnEscape } = this.props;
        const children = this.getChildren();

        const step = this.getCurrentWizardStep(currentStepIdx);
        const footer = path(['props', 'footerComponent'], step);
        const header = path(['props', 'header'], step);
        const closable = pathOr(true, ['props', 'closable'], step);

        return (
            <Dialog
                open={true}
                dimmer={true}
                size="large"
                header={<>{header}</>}
                footer={this.renderFooter(currentStepIdx, step, footer)}
                closeOnDimmerClick={false}
                closeOnEscape={closeOnEscape}
                onCancel={this.handleClose}
                scrolling
                id="welcome-dialog"
                className={classNames({
                    [styles.root]: true,
                    [styles.root_medium]: size === 'medium',
                    [styles.root_mediumMinHeight]: minHeight && size === 'medium',
                })}
                closable={closable}
            >
                <Content isModal isLoading={isLoading} hasError={isError} scrolling>
                    {children.map((item, idx) => (
                        <div
                            key={idx}
                            className={classNames({
                                [styles.content]: true,
                                [styles.content_left]: idx < currentStepIdx,
                                [styles.content_right]: idx > currentStepIdx,
                                [styles.content_visible]: idx === currentStepIdx,
                            })}
                        >
                            {item}
                        </div>
                    ))}
                </Content>
            </Dialog>
        );
    }
}
