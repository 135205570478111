import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { noop, noopTrue } from 'reactApp/utils/helpers';

export class WizardStep extends PureComponent {
    static propTypes = {
        /** Если возвращает false, то переход на след. шаг запрещен */
        validateStep: PropTypes.func,
        onNextClick: PropTypes.func,
        header: PropTypes.string,
        footerComponent: PropTypes.object,
        /** Запрет возврата на этот шаг с последующих */
        disableBack: PropTypes.bool,
        showPager: PropTypes.bool,
        /** Запрет закрытия визарда на этом шаге */
        closable: PropTypes.bool,
        /** Стиль primary для кнопки  Next */
        primaryNext: PropTypes.bool,
    };

    static defaultProps = {
        validateStep: noopTrue,
        onNextClick: noop,
        showPager: true,
        closable: true,
        primaryNext: false,
    };

    render() {
        return this.props.children ? this.props.children : null;
    }
}
