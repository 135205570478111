import { Icon16MailOutline, Icon20MailOutline } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { memo } from 'react';
import { Avatar } from 'reactApp/ui/Avatar/Avatar';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { NameComponent } from 'reactApp/ui/Name/Name';

import styles from './Destination.css';

export enum SizeType {
    s = 's',
    m = 'm',
    l = 'l',
}

const avatarSizes = {
    [SizeType.s]: 16,
    [SizeType.m]: 28,
    [SizeType.l]: 32,
};

interface DestinationProps {
    className?: string;
    destination?: string;
    author?: { name?: string; email: string };
    openAttachLetter?: () => void;
    isAttaches?: boolean;
    noMargin?: boolean;
    size?: SizeType;
}

export const Destination = memo<DestinationProps>(function Destination({
    className,
    destination,
    author,
    openAttachLetter,
    isAttaches,
    size = SizeType.l,
    noMargin = false,
}) {
    const Icon = size === SizeType.l ? Icon20MailOutline : Icon16MailOutline;

    return (
        <div
            className={classNames(styles.root, className, {
                [styles[`size_${size}`]]: !!size,
            })}
        >
            {author && (
                <div
                    className={classNames(styles.avatar, {
                        [styles.avatarAttaches]: isAttaches,
                    })}
                >
                    <Hint text={author.name || author.email}>
                        <div data-qa-id="author">
                            <Avatar size={avatarSizes[size]} email={author.email} name={author.name} />
                        </div>
                    </Hint>
                    {isAttaches && (
                        <Hint text="Перейти к письму">
                            <div className={styles.mailIcon} onClick={openAttachLetter}>
                                <Icon />
                            </div>
                        </Hint>
                    )}
                </div>
            )}
            {destination && <NameComponent className={noMargin ? undefined : styles.nameMargin} name={destination} showTooltip truncate />}
        </div>
    );
});
