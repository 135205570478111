import { familyMemberQuotaUpdateInterval } from 'reactApp/appHelpers/featuresHelpers';
import { updateUser } from 'reactApp/modules/user/user.thunkActions';
import { store as reduxStore } from 'reactApp/store';

export const buildInviteLink = (link: string) => `https://${window.location.host}/family/${link}`;

const MIN_TIMEOUT = 60000;

export const initFamilyMemberQuotaUpdataIntervals = () => {
    const interval = familyMemberQuotaUpdateInterval < MIN_TIMEOUT ? MIN_TIMEOUT : familyMemberQuotaUpdateInterval;

    const intervalId = setInterval(() => {
        reduxStore.dispatch(updateUser());
    }, interval);

    window.addEventListener('unload', () => {
        clearInterval(intervalId);
    });
};
