import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';

export interface INewDocumentModal {
    createDocument: (editorArg: any, item: any, ext: string, force: boolean, fileName: string) => void;
    editorArg: any;
    item: { id: string };
    ext: string;
    from: string;
}

export const renderNewDocumentModal = (data: INewDocumentModal) => openPopupHelper({ popupName: popupNames.CREATE_DOCUMENT, data });
