import config from 'Cloud/config';
import { extInfo } from 'lib/extInfo';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import { getExtension, getItemNameWithoutExt, getNameById, getUflrStatus, normalizePublicApiInfo } from 'reactApp/modules/file/utils';
import { MalwareStatus } from 'reactApp/modules/start/start.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';
import { IPublicInfo } from 'reactApp/types/Tree';
import { normalizeMtime } from 'reactApp/utils/tree.helpers';

import { IAlbum, IAlbumItem } from './albums.types';

const urlBuilder = new UrlBuilder();

export const normalizeAlbumItem = (item, albumId: string): IAlbumItem => {
    const ext = getExtension(item);
    const name = item.name;
    const id = item.element_id;
    const size = item.size;
    const path = item.path || '';
    const isVirus = item?.malware?.status === MalwareStatus.infected;
    const { kind, subKind } = extInfo.get(ext);
    const uflr = getUflrStatus(item);
    const weblinkInfo = normalizePublicApiInfo(item) as IPublicInfo;

    const urlBuilderParams = path ? { ext, id: path, size, path, kind, subKind, name } : undefined;

    return {
        ...weblinkInfo,
        name,
        type: item.type,
        mtime: normalizeMtime(item.mtime || item.time),
        size,
        id,
        storage: EStorageType.albums,
        ext,
        nameWithoutExt: getItemNameWithoutExt(name, ext),
        parent: albumId,
        kind,
        subKind,
        isFolder: false,
        virusScan: isVirus ? 'fail' : 'pass',
        thumbnails: urlBuilderParams ? urlBuilder.getThumb(urlBuilderParams) : undefined,
        url: urlBuilderParams ? urlBuilder.getUrls(urlBuilderParams) : undefined,
        uflr,
        isInFavorites: Boolean(item?.flags?.favorite),
        home: path,
        path,
    };
};

const getBuilderParams = (url: string) => {
    if (!url) {
        return undefined;
    }

    // Path приходит заэнкоженным
    const path = decodeURIComponent(url);
    const ext = getExtension(url);
    const { kind, subKind } = extInfo.get(ext);

    return {
        ext,
        size: config.get('ITEM_WITHOUT_HASH_MAX_SIZE'),
        path,
        kind,
        subKind,
    };
};

export const normalizeAlbum = (item): IAlbum => {
    const weblinkInfo = normalizePublicApiInfo(item) as IPublicInfo;
    const thumbUrl = item.album_cover_thumb_url?.path;
    const urlBuilderParams = getBuilderParams(thumbUrl);

    return {
        ...weblinkInfo,
        cursor: item.cursor,
        id: item.id,
        kind: 'album',
        name: item.title,
        hasMoreToLoad: Boolean(item.cursor),
        parent: ROOT_FOLDER_ID,
        flags: { isMedia: true },
        isFolder: true,
        storage: EStorageType.albums,
        type: 'album',
        childs: [],
        thumbnails: thumbUrl ? urlBuilder.getThumb(urlBuilderParams) : undefined,
    };
};

/**
 * Возвращает временный элемент альбома, который добавляем после загрузки в облако
 */
export const getNewAlbumItem = ({ elementId, albumId, item }) => {
    return {
        album_id: albumId,
        element_id: elementId,
        path: item.cloudPath,
        name: getNameById(item.cloudPath),
        size: item.loaded,
        mtime: Math.ceil(Date.now() / 1000),
    };
};

export const isAlbumAllowedExtension = (ext: string): boolean => {
    const allowedExtensions = extInfo.getExtsForAlbums();

    return allowedExtensions.includes(ext?.toLowerCase());
};

export const getAlbumsPath = (id: string) => (id === '/albums' ? '/albums/' : `/albums/${id}`);
