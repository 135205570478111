import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { isAfter } from 'date-fns';
import { isEmpty } from 'ramda';
import {
    IApiCard,
    IApiLoadResponse,
    ICard,
    ICreditCardState,
    IRemoveCardAction,
    IShowCardAction,
    IUpdateCardAction,
} from 'reactApp/modules/creditCard/creditCard.types';

const initialState: ICreditCardState = {
    isLoaded: false,
    isLoading: true,
    hasError: false,
    card: null,
};

const loadCard = createAction('creditCard/load');
const loadCardSuccess = createAction<IApiLoadResponse>('creditCard/loadSuccess');
const updateCard = createAction<IUpdateCardAction | undefined>('creditCard/update');
const updateCardSuccess = createAction<IApiLoadResponse>('creditCard/updateSuccess');
const removeCard = createAction<IRemoveCardAction>('creditCard/remove');
const removeCardSuccess = createAction('creditCard/removeSuccess');
const hasError = createAction('creditCard/hasError');
const showModal = createAction<IShowCardAction | undefined>('creditCard/showModal');

export const normalizeCardData = (card: IApiCard): ICard | null => {
    if (isEmpty(card)) {
        return null;
    }

    const [month, year] = (card.exp_date || '').split('.');

    return {
        userId: card.merchant_user_id,
        payMethod: card.pay_method,
        isSbp: card.pay_method === 'sbp_edok2',
        payerId: card.payer_id,
        expDate: card.exp_date ? `${month}/${year.slice(-2)}` : '',
        added: card.added,
        bindId: card.bind_id,
        number: card.description,
        isExpired: Boolean(card.exp_date) && isAfter(new Date(), new Date(`${month}.01.${year}`)),
    };
};

const cardSuccess = (state: ICreditCardState, action: PayloadAction<IApiLoadResponse>) => {
    state.isLoading = false;
    state.isLoaded = true;
    state.card = normalizeCardData(action.payload.card);
};

export const creditCardReducer = createReducer(initialState, {
    [loadCard.type]: (state) => {
        state.isLoading = true;
        state.isLoaded = false;
    },
    [loadCardSuccess.type]: cardSuccess,
    [updateCard.type]: (state, action: PayloadAction<IUpdateCardAction>) => {
        state.isLoading = !action.payload?.isSilent;
        state.isLoaded = false;
    },
    [updateCardSuccess.type]: cardSuccess,
    [removeCard.type]: (state) => {
        state.isLoading = true;
        state.isLoaded = false;
    },
    [removeCardSuccess.type]: () => {
        return { ...initialState, isLoaded: true, isLoading: false };
    },
    [hasError.type]: (state) => {
        state.hasError = true;
        state.isLoading = false;
        state.isLoaded = true;
    },
});

export const CardActions = {
    loadCard,
    removeCard,
    updateCard,
    loadCardSuccess,
    removeCardSuccess,
    updateCardSuccess,
    hasError,
    showModal,
};
