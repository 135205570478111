import { UploadingReason } from "../serviceClasses/UploadingReason";

const ERROR_WRONG_INSTANCE = 'UploadFail called on non-instance of UploadFail';
const ERROR_ABSTRACT_CLASS = 'UploadFail is abstract class';

export class UploadFail extends UploadingReason {
    radarName = 'fail_{SOURCE}_abstract';

    constructor(stack: Error, source: string) {
        super(stack, source);

        if (!(this instanceof UploadFail)) {
            throw new TypeError(ERROR_WRONG_INSTANCE);
        }

        if (this.constructor === UploadFail) {
            throw new TypeError(ERROR_ABSTRACT_CLASS);
        }
    }
}
