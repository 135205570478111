import { Rubles } from 'Cloud/Application/Rubles';
import config from 'Cloud/config';
import React, { memo } from 'react';
import { DisableButton } from 'reactApp/ui/AdvBanners/DisableButton/DisableButton';
import { RollContent } from 'reactApp/ui/RollContent/RollContent';
import styles from 'reactApp/ui/RollContent/RollContent.css';
import { sendGa } from 'reactApp/utils/ga';

const DOM_ID_ROLL = config.get('DOM_ID_ROLL');

type Props = {
    darkMode?: boolean;
    showElementIsInViewport?: boolean;
};

export const RollContentAds = memo<Props>(({ darkMode = false, showElementIsInViewport }) => {
    return (
        <RollContent
            domRollId={DOM_ID_ROLL}
            darkMode={darkMode}
            showElementIsInViewport={showElementIsInViewport}
            updateContent={Rubles.updateRollContent}
            renderFooter={() => (
                <DisableButton
                    className={styles.button}
                    id="roll-content"
                    onClick={() => sendGa('public', 'rolladsbtn', 'click', null, true)}
                />
            )}
        />
    );
});

RollContentAds.displayName = 'RollContentAds';
