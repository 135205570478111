import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { ProductsSelectors } from 'reactApp/modules/products/products.selectors';
import { isFutureSubscription, ISubscription } from 'reactApp/modules/subscriptions/subscriptions.types';
import { RootState } from 'reactApp/store';
import { createGaSender } from 'reactApp/utils/ga';

import { SubscriptionItem } from '../SubscriptionListItem/SubscriptionListItem';
import { composeContent } from './ModernSubscriptionItem.helpers';

const sendGa = createGaSender('subs-sidebar');

type Props = {
    subscription: ISubscription;
    onClick: () => void;
};

export const ModernSubscriptionItemComponent = memo<Props>(({ subscription, onClick }) => {
    const { productId, type } = subscription;
    const product = useSelector((state: RootState) => ProductsSelectors.getProductById(state, productId));
    const { title, description, icon, partner, showWaiting } = composeContent({ subscription, product });

    const handleOnClick = () => {
        // нельзя слать полный id продукта, потому что тогда не укладываемся в лимит на длину метрики
        sendGa('click', 'subs-item', `${type}${productId ? `_${productId.slice(0, 16)}` : ''}`);

        onClick();
    };

    return (
        <SubscriptionItem
            type={partner}
            showWaiting={showWaiting}
            icon={icon}
            title={title}
            description={description}
            onClick={handleOnClick}
            isDisable={isFutureSubscription(subscription)}
        />
    );
});

ModernSubscriptionItemComponent.displayName = 'ModernSubscriptionItemComponent';
