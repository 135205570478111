import { Method } from 'axios';
import { APICallV3Post, APICallV4 } from 'reactApp/api/APICall';
import { apiMigrateV3ToV4 } from 'reactApp/appHelpers/featuresHelpers';

const method = 'favorites/add';

class FavoritesAddAPIV3Call extends APICallV3Post {
    _method = method;
}

class FavoritesAddAPIV4Call extends APICallV4 {
    _method = method;
    _type: Method = 'post';
}

export const getFavoritesAddAPICall = (): APICallV3Post | APICallV4 => {
    if (apiMigrateV3ToV4[method]) {
        return new FavoritesAddAPIV4Call();
    }

    return new FavoritesAddAPIV3Call();
};
