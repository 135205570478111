import { getExtension, getItemNameWithoutExt } from 'reactApp/modules/file/utils';

export const getNewFileName = (currentFileName, versionFileName, time) => {
    const currentExt = getExtension({ name: currentFileName });
    const versionExt = getExtension({ name: versionFileName });
    let newExt = versionExt;

    // Расширение файла текущей и восстанавливаемой версии могут
    // не совпадать. Всегда восстанавливаем файл, с оотвествующим
    // версии расширением, но с текущим именем.
    if (currentExt.toLowerCase() === versionExt.toLowerCase()) {
        // Если отличается только регистр символов,
        // используем текущее расширение файла.
        newExt = currentExt;
    }

    const newFileName = getItemNameWithoutExt(currentFileName, currentExt);

    return `${newFileName}${time ? ` ${time}` : ''}${newExt ? `.${newExt}` : ''}`;
};
