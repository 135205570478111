import cloudIcon from 'img/cloud_blue.svg?url';
import { QRCodeCanvas } from 'qrcode.react';
import React, { memo, ReactElement } from 'react';

interface Props {
    url: string;
    size?: number;
    isDark?: boolean;
}

const imageSettings = {
    src: cloudIcon,
    height: 28,
    width: 28,
    excavate: true,
};

export const QRCloud = memo(({ url, size = 156, isDark = false }: Props): ReactElement | null => {
    return (
        <QRCodeCanvas
            value={url}
            size={size}
            imageSettings={imageSettings}
            bgColor={'transparent'}
            fgColor={isDark ? '#ffffff' : '#2c2d2e'}
        />
    );
});

QRCloud.displayName = 'QRCloud';
