const PADDING = 10;

export interface Position {
    top: number;
    left: number;
}

export const getMinHorizontalPosition = (windowWidth: number, rectWidth: number, paddings: number): number => {
    const realPosX = windowWidth - rectWidth - paddings;
    return realPosX < 0 ? paddings : realPosX;
};

export const calculatePosition = (element: HTMLElement, currentPos: Position): void => {
    const hintRect = element.getBoundingClientRect();
    const windowWidth = Math.min(window.innerWidth, window.document.body.clientWidth);
    const windowHeight = Math.min(window.innerHeight, window.document.body.clientHeight || window.innerHeight);
    let { left, top } = currentPos;

    if (hintRect.width + left + PADDING >= windowWidth) {
        left = getMinHorizontalPosition(windowWidth, hintRect.width, PADDING);
    } else {
        left += PADDING;
    }

    if (hintRect.height + top + PADDING >= window.scrollY + windowHeight) {
        top = windowHeight - hintRect.height - PADDING;
        if (top < 0) {
            top = PADDING;
        }
    } else {
        top += PADDING;
    }

    element.style.left = `${left}px`;
    element.style.top = `${top}px`;
    element.style.opacity = '1';
};
