import { Icon20Cancel, Icon20Search } from '@vkontakte/icons';
import { IconButton, Input, InputProps, SizeType, Spinner } from '@vkontakte/vkui';
import React from 'react';

import styles from './SearchBar.css';

interface IProps extends InputProps {
    onInputClear?: () => void;
    isLoading?: boolean;
}

export const SearchBar: React.FC<IProps> = ({ value, isLoading = false, onInputClear, ...props }) => (
    <div className={styles.container}>
        <Input
            {...props}
            value={value}
            mode="plain"
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            enterKeyHint="search"
            type="search"
            name="search"
            autoComplete="off"
            autoFocus
            className={styles.input}
            sizeY={SizeType.REGULAR}
            before={<Icon20Search className={styles.searchIcon} />}
            after={
                (isLoading && <Spinner size="small" className={styles.loadingIcon} />) ||
                (!!value && (
                    <IconButton onClick={onInputClear} hasActive={false} data-qa-id="searchClear">
                        <Icon20Cancel />
                    </IconButton>
                )) ||
                null
            }
            data-qa-id="searchInput"
        />
    </div>
);
