import classnames from 'clsx';
import React, { PureComponent } from 'react';
import { noop } from 'reactApp/utils/helpers';

import styles from './Thumb.css';

interface IProps {
    url: string;
    load(file: any): void;
    file: any;
    removeLoader(file: any): void;
    borderRadius: boolean;
    onLoadThumb?: () => void;
    onErrorThumb(): void;
}

export class Thumb extends PureComponent<IProps> {
    componentDidMount() {
        const { load, file } = this.props;
        load(file);
    }

    componentWillUnmount() {
        this.props.removeLoader(this.props.file);
    }

    render() {
        const { url, onErrorThumb, onLoadThumb = noop, borderRadius } = this.props;

        if (!url) {
            return null;
        }

        return (
            <img
                className={classnames({ [styles.root]: true, [styles.borderRadius]: borderRadius })}
                src={url}
                onLoad={onLoadThumb}
                onError={onErrorThumb}
            />
        );
    }
}
