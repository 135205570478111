import { GiftBuyAPICall } from 'reactApp/api/billing/gift/GiftBuyAPICall';
import { GiftBuy } from 'reactApp/modules/gift/gift.constants';

const apiCall = id =>
    new GiftBuyAPICall().makeRequest({
        gift_id: id,
    });

const request = () => ({
    type: GiftBuy.REQUEST,
});

const loading = () => ({
    type: GiftBuy.LOADING,
});

const success = url => ({
    type: GiftBuy.SUCCESS,
    url,
});

const failure = error => ({
    type: GiftBuy.FAILURE,
    error,
});

export const buyGift = id => dispatch => {
    dispatch(request());

    return new Promise((resolve, reject) => {
        dispatch(loading());
        apiCall(id)
            .then(({ data }) => {
                resolve(dispatch(success(data.pw)));
            })
            .catch(error => {
                reject(dispatch(failure(error)));
            });
    });
};
