import { sendGa } from 'reactApp/utils/ga';

export const sendFilesHistoryGa = (action: string, label?: string) => {
    sendGa('file-history', action, label);
};

export const getDropdownItem = ({ onClick }: { onClick: (isRewrite) => void }) => {
    return [
        {
            text: 'Перезаписать',
            id: 'rewrite',
            onClick: (): void => onClick(true),
        },
        {
            text: 'Создать копию',
            id: 'cope',
            onClick: (): void => onClick(false),
        },
    ];
};
