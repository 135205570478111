import { REWIND_TIME } from 'reactApp/ui/ReactViewer/VideoPlayer/CustomNavigation/constants';
import videojs from 'video.js';

import styles from './styles.css';

const Component = videojs.getComponent('Component');

function animate(el: HTMLElement, count: number) {
    el.setAttribute('data-content', `${count * REWIND_TIME} сек.`);
    el.classList.add(styles.click);
    window.requestAnimationFrame(() => {
        el.classList.remove(styles.click);
    });
}

// Анимация промотки влево и вправо
export function addAnimatedBackground(player: videojs.Player) {
    const wrapperEl = document.createElement('div');
    wrapperEl.className = styles.animationWrapper;

    const elLeftAnimation = document.createElement('div');
    elLeftAnimation.className = `${styles.leftAnimationScreen} ${styles.click}`;
    wrapperEl.appendChild(elLeftAnimation);

    const elRightAnimation = document.createElement('div');
    elRightAnimation.className = `${styles.rightAnimationScreen} ${styles.click}`;
    wrapperEl.appendChild(elRightAnimation);

    const wrapper = new Component(player, { el: wrapperEl });

    player.addChild(wrapper);

    return { showLeft: (count: number) => animate(elLeftAnimation, count), showRight: (count: number) => animate(elRightAnimation, count) };
}
