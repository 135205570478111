import { APICallV3Post } from 'reactApp/api/APICall';

/**
 * Класс, возвращающий инфу об одной части файла pdf для аттачей
 */
export class PdfViewAttachPartAPICall extends APICallV3Post<ArrayBuffer | null> {
    _method = '/ovidius/pdf/hash/view_part';
    _responseType = 'arraybuffer' as const;
    _withCredentials = true;
}
