// tempexp_SRCH-70119-full-file
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IPromoData } from 'reactApp/modules/promo/promo.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { sendDwh } from 'reactApp/utils/ga';

import { searchTooltipData, SearchTooltipTypes } from './SearchTooltip.data';
import {
    getSessionTooltipVisibilityStatus,
    getTooltipAppearanceCount,
    getTooltipFlagName,
    increaseTooltipAppearanceCount,
    setSessionTooltipVisibilityStatus,
} from './SearchTooltip.helpers';

interface UseTooltipStateProps {
    visibilityValue: boolean;
    tooltipType: SearchTooltipTypes | null;
    promo?: IPromoData;
}

export const useTooltipState = ({ visibilityValue, tooltipType, promo }: UseTooltipStateProps): [boolean, () => void] => {
    const isVisible = !!(visibilityValue && tooltipType && promo);
    const email = useSelector(UserSelectors.getEmail);
    const tooltipFlagName = getTooltipFlagName(email, tooltipType);
    const { title = '', description = '' } = tooltipType ? searchTooltipData[tooltipType] : {};

    const sendRadar = useCallback(
        (eventCategory) => {
            sendDwh({
                eventCategory,
                dwhData: {
                    place: 'desktop',
                    text_id: tooltipType,
                    text: `${title} ${description}`,
                },
            });
        },
        [description, title, tooltipType]
    );

    const [isTooltipVisible, setTooltipVisibility] = useState(
        !!promo &&
            !!tooltipFlagName &&
            isVisible &&
            getSessionTooltipVisibilityStatus(tooltipFlagName, 'closed') !== 'true' &&
            (getTooltipAppearanceCount(tooltipFlagName) < 1 || getSessionTooltipVisibilityStatus(tooltipFlagName, 'visible') === 'true')
    );

    useEffect(() => {
        if (
            promo &&
            tooltipType &&
            getSessionTooltipVisibilityStatus(tooltipFlagName, 'closed') !== 'true' &&
            (getTooltipAppearanceCount(tooltipFlagName) < 1 || getSessionTooltipVisibilityStatus(tooltipFlagName, 'visible') === 'true')
        ) {
            setTooltipVisibility(isVisible);
        }
    }, [isVisible, promo, tooltipType, tooltipFlagName]);

    useEffect(() => {
        if (isTooltipVisible) {
            sendRadar('search-tooltip-show');
            promo?.onShow();
            if (getSessionTooltipVisibilityStatus(tooltipFlagName, 'visible') !== 'true') {
                setSessionTooltipVisibilityStatus(tooltipFlagName, 'visible');
                increaseTooltipAppearanceCount(tooltipFlagName);
            }
        }
    }, [isTooltipVisible, promo, sendRadar, tooltipFlagName]);

    const onTooltipClose = useCallback(() => {
        if (
            getSessionTooltipVisibilityStatus(tooltipFlagName, 'visible') === 'true' &&
            getSessionTooltipVisibilityStatus(tooltipFlagName, 'closed') !== 'true'
        ) {
            setTooltipVisibility(false);
            promo?.onClose();
            sendRadar('search-tooltip-close');
            setSessionTooltipVisibilityStatus(tooltipFlagName, 'closed');
        }
    }, [promo, sendRadar, tooltipFlagName]);

    return [isTooltipVisible, onTooltipClose];
};
