import classNames from 'clsx';
import CloudDocumentsIcon from 'img/portal-header/cloud-documents.svg?url';
import CloudIcon from 'img/portal-menu/cloud.svg?url';
import CloudWhiteIcon from 'img/portal-menu/cloud-white.svg?url';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { ENABLE_FULL_RESPONSIVE, IS_CLOUD_LOCKED } from 'reactApp/appHelpers/configHelpers';

import styles from './Logo.css';

export enum ELogoSize {
    m = 'm',
    s = 's',
}

interface IProps {
    isBiz: boolean;
    href?: string;
    isPhone: boolean;
    size?: ELogoSize;
    bizLogo?: string;
    bizBrandName?: string;
    white?: boolean;
    useRouterLink?: boolean;
    isDocuments?: boolean;
    errorPage?: boolean;
}

export const LogoComponent: React.FC<IProps> = ({
    href = '/',
    isDocuments,
    isPhone,
    isBiz,
    size = ELogoSize.m,
    bizLogo,
    bizBrandName,
    white,
    useRouterLink,
    errorPage = false,
}): ReactElement => {
    const whiteLogo = white || isPhone;
    const logoSize = isPhone ? ELogoSize.s : size;

    const icon = (
        /* @ts-ignore */
        <img className={styles.logo} src={whiteLogo ? CloudWhiteIcon : isDocuments ? CloudDocumentsIcon : CloudIcon} importance="high" />
    );

    const logoLinkProps = {
        title: isDocuments ? 'Документы Mail.ru' : 'Облако Mail.ru',
        className: styles.cloud,
        children: icon,
    };

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles[`size_${logoSize}`]]: !!logoSize,
                [styles.root_my_team]: ENABLE_FULL_RESPONSIVE,
                [styles.bizLocked]: ENABLE_FULL_RESPONSIVE && IS_CLOUD_LOCKED,
            })}
        >
            {isBiz ? (
                <a href={href} className={classNames({ [styles.biz]: true, [styles.hidden_logo]: errorPage })} title={bizBrandName}>
                    {/* @ts-ignore */}
                    <img className={styles.logo} src={bizLogo} height={32} width={164} importance="high" />
                </a>
            ) : (
                <>{useRouterLink ? <Link to={href} {...logoLinkProps} /> : <a href={href} {...logoLinkProps} />}</>
            )}
        </div>
    );
};
