export const processExtInfo = (ext, extInfo) => {
    const maxPreviewableSize = 78643200;
    const maxEditableSize = 52428800;

    const overrideExtInfo = ['xls', 'xlsb', 'xlsm', 'xlsx', 'doc', 'docx'];

    if (!overrideExtInfo.includes(ext)) {
        return extInfo;
    }

    return { ...(extInfo as Record<string, unknown>), maxPreviewableSize, maxEditableSize };
};
