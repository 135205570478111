import { createSelector } from 'reselect';

const getDialogState = (state) => state.dialog;

export const isDialogVisible = createSelector(getDialogState, (dialog) => dialog.dialogIdxs.length > 0);
export const getCurrentVisibleDialogId = createSelector(
    getDialogState,
    (state): number => getDialogState(state).dialogIdxs.length,
    (dialog, length) => (length > 0 ? dialog.dialogIdxs[length - 1] : '')
);
export const isDialogByIdVisible = createSelector(
    getDialogState,
    (id: string) => id,
    (dialog, id) => dialog.dialogIdxs.includes(id)
);

export const getIsDropdownOpen = (state) => getDialogState(state).isDropdownOpen;
