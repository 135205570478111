import escapeForHtml from 'lib/escapeForHtml';
import { ITEM_NAME_INVALID_CHARACTERS } from 'reactApp/appHelpers/configHelpers';

import { UploadFail } from './UploadFail';

export class InvalidCharactesFail extends UploadFail {
    static WEB_BACKEND_ERROR = 'invalid';

    radarName = 'fail_{SOURCE}_invalid-characters';

    message = [
        'Недопустимое имя файла или папки.',
        // eslint-disable-next-line no-irregular-whitespace
        `В названии нельзя использовать символы <kbd>${escapeForHtml(ITEM_NAME_INVALID_CHARACTERS)}</kbd>.`,
        'Также название не может состоять только из точки или из двух точек.',
        '<a href="https://help.mail.ru/cloud_web/confines" rel="noopener" target="_blank">Подробнее</a>',
    ].join(' ');
}
