import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import styles from './CardNumber.css';

export default class CardNumber extends PureComponent {
    static propTypes = {
        number: PropTypes.string.isRequired,
    };

    render() {
        return (
            <div className={styles.root}>
                <div className={styles.dots}>
                    <div className={styles.dot} />
                    <div className={styles.dot} />
                    <div className={styles.dot} />
                    <div className={styles.dot} />
                </div>
                <div className={styles.number}>{this.props.number.slice(-4)}</div>
            </div>
        );
    }
}
