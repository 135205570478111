import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { fixItemHomeLowcaseBug, getParent, normalizePublicApiInfo } from 'reactApp/modules/file/utils';
import {
    publishWeblink,
    removeFileSuccess,
    renameItemSuccess,
    resetWeblinkCountDownloads,
    toggleWeblinkAccessRights,
    toggleWeblinkDomestic,
    toggleWeblinkDownloadable,
    unPublishWeblink,
    updateItem,
    updateWeblinkAutoDelete,
    updateWeblinkCountDownloads,
    updateWeblinkExpires,
} from 'reactApp/modules/modifying/modifying.actions';
import {
    IRenameItemSuccess,
    RemoveFileSuccessAction,
    ResetWeblinkCountDownloads,
    ToggleWeblinkAccessRights,
    ToggleWeblinkDomestic,
    ToggleWeblinkDownloadable,
    UnpublishWeblinkAction,
    UpdateItemAction,
    UpdateWeblinkAutoDelete,
    UpdateWeblinkCountDownloads,
    UpdateWeblinkExpires,
} from 'reactApp/modules/modifying/modifying.types';
import { ApiSharedLinksResponse } from 'reactApp/modules/shared/shared.type';
import { SharedAutoDeleteFolder, SharedAutoDeleteState } from 'reactApp/modules/sharedAutoDelete/sharedAutoDelete.type';
import { ESortOder, ESortTypes } from 'reactApp/modules/sort/sort.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { AccessRights } from 'reactApp/types/Tree';
import { renameCameraUploadFolder } from 'reactApp/utils/tree.helpers';

const initialState: SharedAutoDeleteState = {
    '/': {
        id: '/',
        name: 'Автоудаляемые',
        isLoading: false,
        count: {
            files: 0,
            folders: 0,
            all: 0,
            loaded: 0,
        },
        childs: [],
        isLoaded: false,
        sort: {
            type: ESortTypes.name,
            order: ESortOder.asc,
        },
    },
    list: {},
};

export const loadSharedAutoDeleteRequest = createAction('sharedAutoDelete/load/request');
export const loadSharedAutoDeleteSuccess = createAction<ApiSharedLinksResponse>('sharedAutoDelete/load/success');

export const sharedAutoDeleteReducer = createReducer(initialState, {
    [updateItem.type]: (state, action: PayloadAction<UpdateItemAction>) => {
        const { home } = action.payload;
        const item = state.list[home?.toLowerCase()];

        if (item && !item.weblink) {
            state['/'].childs = state['/'].childs.filter((child) => child !== home);

            state['/'].count.folders -= 1;
            state['/'].count.all -= 1;
        }
    },
    [unPublishWeblink.type]: (state, action: PayloadAction<UnpublishWeblinkAction>) => {
        const { ids } = action.payload;

        ids.forEach((id) => {
            const item = state.list[id.toLowerCase()];

            if (!item) {
                return;
            }

            item.weblink = undefined;
            item.weblinkAccessRights = AccessRights.r;
            item.weblinkDomestic = false;
            item.weblinkExpires = 0;

            if (item.isFolder) {
                item.weblinkAutoDelete = false;
            }

            state['/'].childs = state['/'].childs.filter((child) => child !== id.toLowerCase());

            state['/'].count.folders -= 1;
            state['/'].count.all -= 1;
        });
    },
    [publishWeblink.type]: (state, action: PayloadAction<{ weblink: string; id: string }>) => {
        const { id, weblink } = action.payload;
        const item = state.list[id.toLowerCase()];

        if (item) {
            item.weblink = weblink;
        }
    },
    [toggleWeblinkAccessRights.type]: (state, action: PayloadAction<ToggleWeblinkAccessRights>): void => {
        const { id, type } = action.payload;
        const item = state.list[id.toLowerCase()];

        if (item) {
            item.weblinkAccessRights = type;
        }
    },
    [toggleWeblinkDownloadable.type]: (state, action: PayloadAction<ToggleWeblinkDownloadable>): void => {
        const { id, downloadable } = action.payload;
        const item = state.list[id.toLowerCase()];

        if (item) {
            item.weblinkDownloadable = downloadable;
        }
    },
    [updateWeblinkCountDownloads.type]: (state, action: PayloadAction<UpdateWeblinkCountDownloads>): void => {
        const { id, count_downloads } = action.payload;
        const item = state.list[id.toLowerCase()];

        if (item) {
            item.count_downloads_left = count_downloads;
            item.count_downloads_total = count_downloads;
        }
    },
    [resetWeblinkCountDownloads.type]: (state, action: PayloadAction<ResetWeblinkCountDownloads>): void => {
        const { id } = action.payload;
        const item = state.list[id.toLowerCase()];

        if (item) {
            item.count_downloads_total = undefined;
            item.count_downloads_left = undefined;
        }
    },
    [updateWeblinkExpires.type]: (state, action: PayloadAction<UpdateWeblinkExpires>): void => {
        const { id, expires } = action.payload;
        const item = state.list[id.toLowerCase()];

        if (item) {
            item.weblinkExpires = expires;
        }
    },
    [updateWeblinkAutoDelete.type]: (state, action: PayloadAction<UpdateWeblinkAutoDelete>): void => {
        const { id, autoDelete } = action.payload;
        const item = state.list[id.toLowerCase()];

        if (!item) {
            return;
        }

        item.weblinkAutoDelete = autoDelete;

        if (!state['/'].childs.includes(id.toLowerCase())) {
            state['/'].childs.push(id);

            state['/'].count.folders += 1;
            state['/'].count.all += 1;
        }

        if (!autoDelete) {
            state['/'].childs = state['/'].childs.filter((child) => child !== id.toLowerCase());

            state['/'].count.folders -= 1;
            state['/'].count.all -= 1;
        }
    },

    [toggleWeblinkDomestic.type]: (state, action: PayloadAction<ToggleWeblinkDomestic>): void => {
        const { id, domestic } = action.payload;
        const item = state.list[id.toLowerCase()];

        if (item) {
            item.weblinkDomestic = domestic;
        }
    },
    [loadSharedAutoDeleteRequest.type]: (state) => {
        state['/'].isLoaded = false;
        state['/'].isLoading = true;
    },
    [loadSharedAutoDeleteSuccess.type]: (state, action: PayloadAction<ApiSharedLinksResponse>) => {
        const { list } = action.payload;

        state['/'].childs = [];
        state['/'].count.all = list.length;
        state['/'].count.loaded = list.length;
        state['/'].count.folders = 0;
        state['/'].count.files = 0;

        list.forEach((item) => {
            const fixedHome = fixItemHomeLowcaseBug(item.home, item.name);
            const parentFolder = getParent(fixedHome);
            state['/'].childs.push(fixedHome.toLowerCase());

            state.list[fixedHome.toLowerCase()] = {
                __reduxTree: true,
                id: fixedHome,
                parent: parentFolder,
                name: renameCameraUploadFolder(item),
                size: item.size,
                type: 'folder',
                home: fixedHome,
                storage: EStorageType.sharedAutodelete,
                tree: item.tree || '',
                rev: item.rev || 0,
                kind: item.kind,
                isFolder: true,
                childs: [],
                ...normalizePublicApiInfo(item),
            };

            state['/'].count.folders += 1;
        });
        state['/'].isLoading = false;
        state['/'].isLoaded = true;
    },
    [renameItemSuccess.type]: (state, action: PayloadAction<IRenameItemSuccess>) => {
        const { newItem, oldId } = action.payload;
        const item = newItem as SharedAutoDeleteFolder;
        const disableAddToAutoDeleteFolder = !item || !oldId || !item.weblinkExpires || !item.weblinkAutoDelete;

        if (disableAddToAutoDeleteFolder) {
            return;
        }

        const fixedHome = fixItemHomeLowcaseBug(item.home, item.name);
        const oldItem = { ...state.list[oldId.toLowerCase()] };

        state['/'].childs = state['/'].childs.filter((child): boolean => child !== oldId.toLowerCase());
        delete state.list[oldId.toLowerCase()];

        state.list[fixedHome.toLowerCase()] = {
            ...oldItem,
            id: fixedHome,
            name: renameCameraUploadFolder(item),
            home: fixedHome,
        };

        state['/'].childs.push(fixedHome.toLowerCase());
    },
    [removeFileSuccess.type]: (state, action: PayloadAction<RemoveFileSuccessAction>) => {
        const { ids } = action.payload;

        ids.forEach((id): void => {
            state['/'].childs = state['/'].childs.filter((child): boolean => child !== id);
            state['/'].count.folders -= 1;
            state['/'].count.all -= 1;

            delete state.list[id.toLowerCase()];
        });
    },
});
