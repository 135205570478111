import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IApiCreateResponse, ICreditCardDmrLinkState } from 'reactApp/modules/creditCardDmrLink/creditCardDmrLink.types';
import { RootState } from 'reactApp/store';

const initialState: ICreditCardDmrLinkState = {
    isLoaded: false,
    isLoading: false,
    hasError: false,
    addCardLink: '',
};

const addCard = createAction('creditCardDmrLink/add');
const addCardSuccess = createAction<IApiCreateResponse>('creditCardDmrLink/addSuccess');
const resetLink = createAction('creditCardDmrLink/resetLink');
const hasError = createAction('creditCardDmrLink/hasError');

export const creditCardDmrLinkReducer = createReducer(initialState, {
    [addCard.type]: (state) => {
        state.isLoading = true;
        state.isLoaded = false;
    },
    [addCardSuccess.type]: (state, action: PayloadAction<IApiCreateResponse>) => {
        state.isLoading = false;
        state.isLoaded = true;
        state.addCardLink = action.payload.url;
    },
    [resetLink.type]: () => {
        return { ...initialState, isLoaded: true };
    },
    [hasError.type]: (state) => {
        state.hasError = true;
    },
});

const getDmrData = (state: RootState) => state.creditCardDmrLink as ICreditCardDmrLinkState;

export const CreditCardDmrLinkActions = {
    addCard,
    addCardSuccess,
    resetLink,
    hasError,
};

export const CreditCardDmrLinkSelectors = {
    getDmrData,
};
