import { IS_PHONE_BROWSER, IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { isSuggestionsLawEnabled } from 'reactApp/appHelpers/featuresHelpers';
import { getCurrentStorage, isPromoLandingPage, isReactLandingPage } from 'reactApp/modules/router/router.selectors';
import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { store as reduxStore } from 'reactApp/store';
import { sendDwh } from 'reactApp/utils/ga';
import opener from 'reactApp/utils/opener';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { put, select } from 'typed-redux-saga';

import { storeHelper } from '../promo.helpers';
import { addPromoToShowQueue, promoShown } from '../promo.module';
import { PromoSelectors } from '../promo.selectors';
import { EPromoType } from '../promo.types';

export const PROMO_ID = 'suggestionsLaw';

const sendGa = (action: string) => {
    const platform = IS_PHONE_BROWSER ? 'touch' : 'desktop';
    const storage = getCurrentStorage(reduxStore.getState());

    const dwhData = {
        place: platform,
        source: storage,
    };

    sendDwh({ eventCategory: ECategoryGa.snack, action, dwhData });
};

export function* initSuggestionsLawPromo() {
    const isLandingPage = isReactLandingPage() || isPromoLandingPage();
    const storage = yield* select(getCurrentStorage);
    const { isAttaches } = getStorage(storage);
    const alreadyShown = storeHelper.getValue(PROMO_ID);

    if (!isSuggestionsLawEnabled || isLandingPage || isAttaches || alreadyShown || IS_WEBVIEW) {
        return;
    }

    yield* put(
        addPromoToShowQueue({
            type: EPromoType.suggestionsLaw,
            promoId: PROMO_ID,
            onShow: () => {
                reduxStore.dispatch(promoShown(EPromoType.suggestionsLaw));
                storeHelper.markAsShown(PROMO_ID);
                sendGa('recommend_technologies');
            },
            onClose: () => {
                sendGa('recommend_technologies_close');
            },
        })
    );
}

export function* showSuggestionsLawPromo() {
    const suggestionsLawPromo = yield* select(PromoSelectors.getPromo(EPromoType.suggestionsLaw));

    yield* put(
        showSnackbarAction({
            id: 'quotaCleanerLoadGroupError',
            text: 'Облако использует рекомендательные технологии для подбора рекламы',
            buttonText: 'Подробнее',
            closable: true,
            onShow: suggestionsLawPromo?.onShow,
            onButtonClick: () => {
                opener('https://help.mail.ru/legal/terms/cloud/recommendation');
            },
        })
    );
}
