import React from 'react';

import styles from './EditorLLMDialogLoader.css';

export const EditorLLMDialogLoader = () => (
    <div className={styles.loader} data-qa-id="LLMDialog_loader">
        <div className={styles.loader_dot} />
        <div className={styles.loader_dot} />
        <div className={styles.loader_dot} />
    </div>
);
