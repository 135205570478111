import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { inlineEditFailure, inlineEditStart, inlineEditStop, inlineEditSuccess } from 'reactApp/modules/modifying/modifying.actions';
import { getInlineEditState } from 'reactApp/modules/modifying/modifying.selectors';
import { EInlineEditPlace } from 'reactApp/modules/modifying/modifying.types';
import { renameRequest } from 'reactApp/modules/modifying/sagas/rename.saga';
import { getItemById } from 'reactApp/modules/storage/storage.selectors';
import { RootState } from 'reactApp/store';

export const useRenameInlineDoubleClick = (
    oldValue: string,
    id: string,
    place: EInlineEditPlace,
    ext?: string
): {
    isEdit: boolean;
    handleDoubleClick: (e: MouseEvent) => void;
    onRename: (value: { value: string }) => Promise<any>;
} => {
    const dispatch = useDispatch();
    const [isEdit, setIsEdit] = useState(false);
    const cloudItem = useSelector((state: RootState) => getItemById(state, id));
    // tempexp_14737-start
    const { inlineEditItemId, isInlineEditInProgress } = useSelector(getInlineEditState);

    useEffect(() => {
        if (isInlineEditInProgress && inlineEditItemId === id) {
            setIsEdit(true);
        }
    }, [id, inlineEditItemId, isInlineEditInProgress]);
    // tempexp_14737-end

    const handleDoubleClick = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation();
            setIsEdit(true);
            dispatch(inlineEditStart());
        },
        [setIsEdit, dispatch]
    );

    const onRename = useCallback(
        ({ value }: { value: string }): Promise<any> => {
            const newName = value.trim().normalize();
            const oldName = oldValue.trim().normalize();

            if (newName !== oldName) {
                const finalName = cloudItem?.isFolder ? newName : `${newName}.${ext}`;

                return renameRequest(cloudItem, finalName)
                    .then((id) => {
                        dispatch(
                            inlineEditSuccess({
                                id,
                                place,
                            })
                        );
                    })
                    .catch((error) => {
                        dispatch(inlineEditFailure(error));
                    })
                    .finally(() => {
                        setIsEdit(false);
                        dispatch(inlineEditStop());
                    });
            }

            dispatch(inlineEditStop());
            setIsEdit(false);
            return Promise.resolve();
        },
        [setIsEdit, cloudItem, dispatch, oldValue, place, ext]
    );

    return {
        isEdit,
        handleDoubleClick,
        onRename,
    };
};
