import { ReactElement } from 'react';
import { EViewMode } from 'reactApp/modules/settings/settings.types';

export interface IProps {
    list: VirtualListItem[];
    itemWidth?: number;
    rowHeight: number;
    viewMode?: EViewMode;
    dividerHeight?: number;
    dividerSize?: EDividerSize;
    itemHeight?: number;
    hasMoreToLoad: boolean;
    loadOnScroll: () => void;
    onItemsRendered?: ({ start, stop }: { start: number; stop: number }) => void;
    isLoading: boolean | undefined;
    customScrollElement?: HTMLDivElement | null;
    renderItem: (
        id: string,
        options: { index: number },
        extraItemProps: { onItemSizeChange?: (size: number) => void }
    ) => ReactElement | null;
    className?: string;
    disableScroll?: boolean;
}

export type IVirtualListLoaderProps = Pick<IProps, 'isLoading' | 'loadOnScroll'>;

export interface IVirtualList {
    // Индекс элемента, к которому надо скроллить
    scrollToItem: (index: number, aligmentOption?: EAligment) => void;
    // Возвращает количество колонок
    getColumnsCount: () => number;
}

export enum EAligment {
    CENTER = 'center',
    SMART = 'smart',
}

export enum EDividerSize {
    SMALL = 'small',
}

export interface VirtualListDivider {
    divider: true;
    text?: string;
    showAdv?: boolean;
}

export interface VirtualIndex {
    start: number;
    end: number;
}

export type VirtualListItem = string | VirtualListDivider;
