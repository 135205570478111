import classNames from 'clsx';
import React, { FC, MouseEventHandler } from 'react';
import { LoaderIcon } from 'reactApp/ui/UploadList/Item/LoaderIcon';

interface ProgressProps {
    className?: string;
    onClick?: MouseEventHandler<HTMLDivElement>;
    progress: number;
}

export const Progress: FC<ProgressProps> = ({ className, progress, onClick }) => {
    return (
        <div className={classNames(className)} onClick={onClick} data-qa-id="progress">
            <LoaderIcon progress={progress} loading={true} loaderProps={{ radius: 16, stroke: 1 }} />
        </div>
    );
};
