import { getFavoritesListAPICall } from 'reactApp/api/FavoritesListAPICall';
import { getLoadMoreLimit } from 'reactApp/modules/storage/storage.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { Action, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import api from '../../../public/js/Cloud/Application/api';
import { EFavoritesFetch, EFavoritesFetchCount } from './favorites.constants';

const FAVORITES_LOAD_LIMIT = getLoadMoreLimit(EStorageType.favorites);

const callApiLoad = (data: any): any => getFavoritesListAPICall().makeRequest(data);

const request = (): Action => ({
    type: EFavoritesFetch.REQUEST,
});

export const favoritesFetchSuccess = (payload: any): AnyAction => ({
    type: EFavoritesFetch.SUCCESS,
    payload,
});

export const favoritesFetchCountSuccess = (payload: any): AnyAction => ({
    type: EFavoritesFetchCount.SUCCESS,
    payload,
});

const failure = (error: any): AnyAction => ({
    type: EFavoritesFetch.FAILURE,
    error,
});

export const getFavorites =
    ({ limit = FAVORITES_LOAD_LIMIT } = {}): ((
        dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
    ) => any) =>
    (dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>): Promise<any> =>
        new Promise<void>((resolve, reject): void => {
            dispatch(request());

            Promise.all([
                api.folder.shared.incoming(),
                callApiLoad({
                    limit,
                }),
                /* CLOUDWEB-13693: Бэкенд для favorites не помечаем доменные папки.
                 * поэтому фетчим их отдельно (как это сделано с shared несколькими строчками выше).
                 * TODO: попросить бэк это исправить если возможно.
                 */
                api.domain.folders(),
            ])
                .then(([sharedIncomingResponse = {}, favoritesResponse = {}, domainFolders = []]): void => {
                    const { data: favorites = {} } = favoritesResponse;
                    const { list: sharedIncoming = [] } = sharedIncomingResponse;
                    dispatch(favoritesFetchSuccess({ favorites, sharedIncoming, domainFolders }));
                    resolve();
                })
                .catch((error: any): void => reject(dispatch(failure(error))));
        });
