import { Spacing, Text } from '@vkontakte/vkui';
import { Promo } from 'Cloud/Application/Promo';
import { promoController } from 'Cloud/Application/PromoController';
import classNames from 'clsx';
import throttle from 'lodash.throttle';
import React, { RefObject, useCallback, useEffect, useState, VFC } from 'react';
import { useSelector } from 'react-redux';
import { MAIL_HOST } from 'reactApp/modules/attaches/attaches.constants';
import { getFeatureAbRegisterCloudTooltip } from 'reactApp/modules/features/features.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { CancelIcon } from 'reactApp/ui//VKUIIcons';
import { Button, ButtonSizeMode } from 'reactApp/ui/Button/Button';
import { Tooltip}  from 'reactApp/ui/Tooltip/Tooltip';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';

import styles from './RegisterCloudTooltip.css';
import { useTooltipData } from './RegisterCloudTooltip.hooks';

interface IProps {
    targetRef: RefObject<HTMLDivElement>;
    closeOnScroll?: boolean;
}

const TOOLTIP_ID = 'publicCreateCloud';
const TOOLTIP_OFFSET = {
    y: -20,
    x: 0,
};

promoController.register(11, new Promo(TOOLTIP_ID));

export const RegisterCloudTooltip: VFC<IProps> = ({ targetRef, closeOnScroll = false }) => {
    const abPublicCreateCloud = useSelector(getFeatureAbRegisterCloudTooltip);
    const showTip = promoController.canShow(TOOLTIP_ID);
    const isAnonymous = useSelector(UserSelectors.isAnonymous);
    const isViewer = useSelector(ViewerSelectors.isViewerActive);
    const showInitial = showTip && isAnonymous && abPublicCreateCloud === 'b' && !isViewer;

    const [show, setShow] = useState(showInitial);
    const [visible, setVisible] = useState(false);

    const { title, text, theme, image } = useTooltipData();

    const href = `https://${MAIL_HOST}/login?lang=ru_RU&page=${window.location.href}`;
    const throttledScroll = throttle(() => setShow(false), 100);

    useEffect(() => {
        if (targetRef?.current) {
            setVisible(true);
        }
    }, [targetRef?.current]);

    useEffect(() => {
        if (closeOnScroll) {
            document.addEventListener('scroll', throttledScroll);
        }

        return () => document.removeEventListener('scroll', throttledScroll);
    }, [throttledScroll]);

    const handleClose = useCallback(() => {
        setShow(false);
        promoController.markAsShown(TOOLTIP_ID, { daysCount: 14 });
    }, []);

    if (!show || !visible) {
        return null;
    }

    return (
        <Tooltip
            theme="octavius"
            target={targetRef}
            offset={TOOLTIP_OFFSET}
            minWidth={false}
            placement={tooltipPlacements.BOTTOM}
            smartPlacement={false}
            pinned
            noPageOffset
            arrowColor={theme === 'dark' ? '#2C2D2E' : '#f5f5f5'}
        >
            <div
                className={classNames(styles.root, {
                    [styles[`root_theme_${theme}`]]: !!theme,
                    [styles[`root_bg_${image}`]]: !!image,
                })}
            >
                <div className={styles.closeButton} onClick={handleClose}>
                    <CancelIcon />
                </div>
                <Text className={styles.title}>{title}</Text>
                <Spacing size={8} />
                <Text className={styles.text}>{text}</Text>
                <Spacing size={24} />
                <Button
                    sizeMode={ButtonSizeMode.small}
                    theme="octavius"
                    primary
                    onClick={handleClose}
                    href={href}
                    className={styles.button}
                >
                    <Text className={styles.buttonText}>Завести Облако</Text>
                </Button>
            </div>
        </Tooltip>
    );
};
