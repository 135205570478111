import { Button, Headline, List } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { useWebSearchFilters } from 'reactApp/ui/WebSearch/hooks/useWebSearchFilters';
import { WebFilterCell } from 'reactApp/ui/WebSearch/WebFilterCell';
import { ESearchOptionType, typesOptionsList } from 'reactApp/ui/WebSearch/WebSearch.data';
import { Popup } from 'reactApp/ui/WebSearchToolbar/Popup';
import { createGaSender } from 'reactApp/utils/ga';
import { sendNewUserGa } from 'reactApp/utils/newUserGa';

import styles from './CategoryFilters.css';

const sendGa = createGaSender('search');

interface IProps {
    targetRef: React.RefObject<HTMLElement>;
    query?: string;
    onClickAway: () => void;
}

export const CategoryFilters: React.FC<IProps> = ({ targetRef, query, onClickAway }) => {
    const isNewUser = useSelector(UserSelectors.isNewUser);
    const { type, onSelect, onReset } = useWebSearchFilters();

    const optionsList = typesOptionsList.map((item) => ({
        ...item,
        onClick: () => {
            sendGa('item-click', item.id);

            if (isNewUser) {
                sendNewUserGa(item.id, 'search');
            }
            onSelect({ query, type: item.id }, true);
            onClickAway();
        },
    }));

    useEffect(() => {
        typesOptionsList.forEach(({ id }) => sendGa('item-show', id));
    }, []);

    const handleReset = () => {
        onClickAway();
        onReset(true);
    };

    return (
        <Popup placement="bottom-end" targetRef={targetRef} onClickAway={onClickAway}>
            <div id="dropdownList" data-qa-id="search-options">
                <List className={styles.container}>
                    <WebFilterCell
                        hasHover={false}
                        hasActive={false}
                        after={
                            type !== ESearchOptionType.all && (
                                <Button className={styles.resetButton} hasHover={false} mode="tertiary" onClick={handleReset}>
                                    <Headline>Сбросить</Headline>
                                </Button>
                            )
                        }
                    >
                        <Headline level="1" weight="2">
                            Искать
                        </Headline>
                    </WebFilterCell>
                    {optionsList.map(({ id, icon, text, onClick }) => (
                        <WebFilterCell
                            key={id}
                            before={icon}
                            onClick={onClick}
                            checked={type === id}
                            data-qa-id={id}
                            className={styles.option}
                        >
                            {text}
                        </WebFilterCell>
                    ))}
                </List>
            </div>
        </Popup>
    );
};

CategoryFilters.displayName = 'CategoryFilters';
