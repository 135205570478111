import { bytesToNDigits } from '@mail/cross-sizes-utils';
import React, { ReactEventHandler, VFC } from 'react';
import { IArchiveItem } from 'reactApp/modules/viewer/viewer.types';
import { DatalistRowItem } from 'reactApp/ui/Mobile/Datalist/DatalistRowItem/DatalistRowItem';
import { DownloadIcon } from 'reactApp/ui/VKUIIcons';

import styles from './ItemRow.css';

interface IItemRowProps {
    item: IArchiveItem;
    onClick: (item: IArchiveItem) => void;
    onDownload: (item: IArchiveItem) => void;
    disableDownload?: boolean;
}

export const ItemRow: VFC<IItemRowProps> = ({ onClick, item, onDownload, disableDownload = false }) => {
    const canDownload = !disableDownload && !item.isEncrypted && !item.isFolder;
    const size = (item.size && bytesToNDigits(item.size, 3).value) || '';

    const handleDownload: ReactEventHandler = (e) => {
        e.stopPropagation();
        onDownload(item);
    };

    return (
        <div className={styles.root}>
            <DatalistRowItem
                ext={item.ext}
                id="test"
                isFolder={item.isFolder}
                size={size}
                isSelected={false}
                isSelecting={false}
                isVirus={false}
                kind={item.kind}
                name={item.nameWithoutExt || item.name}
                weblink={false}
                onClick={() => onClick(item)}
                showOptionIcon={canDownload}
                optionIconElement={<DownloadIcon />}
                onOptions={handleDownload}
            />
        </div>
    );
};
