import { UserQuotaGroupId } from 'reactApp/types/QuotaCleaner';

export const widgetTitles = {
    [UserQuotaGroupId.Backup]: 'Резервные копии',
    [UserQuotaGroupId.CameraUploads]: 'Автозагрузка с телефона',
    [UserQuotaGroupId.CloudLarge]: 'Большие файлы',
    [UserQuotaGroupId.CloudOld]: 'Старые файлы',
    [UserQuotaGroupId.LargeInbox]: 'Входящие письма',
    [UserQuotaGroupId.LargeSent]: 'Отправленные письма',
    [UserQuotaGroupId.Mounted]: 'Чужие файлы в Облаке',
    [UserQuotaGroupId.Newsletters]: 'Рассылки',
    [UserQuotaGroupId.OldInbox]: 'Старые входящие письма',
    [UserQuotaGroupId.OldSent]: 'Старые отправленные письма',
    [UserQuotaGroupId.Read]: 'Прочитанные письма',
    [UserQuotaGroupId.MailBin]: 'Корзина Почты',
    [UserQuotaGroupId.MailSpam]: 'Спам Почты',
    [UserQuotaGroupId.Video]: 'Видео',
    [UserQuotaGroupId.WithAttaches]: 'Письма с вложениями',
};

export const baseItemsLimit = 4;
export const imageItemsLimit = 18;

export const quotaCleanerRlog = 'cloud-quota-cleaner';

export const letterDefaultName = '<Без темы>';

export const letterGroups = [
    UserQuotaGroupId.LargeInbox,
    UserQuotaGroupId.LargeSent,
    UserQuotaGroupId.Newsletters,
    UserQuotaGroupId.OldInbox,
    UserQuotaGroupId.OldSent,
    UserQuotaGroupId.Read,
    UserQuotaGroupId.WithAttaches,
    UserQuotaGroupId.MailBin,
    UserQuotaGroupId.MailSpam,
];

export const cloudGroups = [
    UserQuotaGroupId.Mounted,
    UserQuotaGroupId.Backup,
    UserQuotaGroupId.CloudLarge,
    UserQuotaGroupId.CloudOld,
    UserQuotaGroupId.Video,
    UserQuotaGroupId.CameraUploads,
];

export const yearFilterGroups = [UserQuotaGroupId.OldInbox, UserQuotaGroupId.OldSent, UserQuotaGroupId.CloudOld];
