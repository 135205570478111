import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { extInfo } from 'lib/extInfo';
import { ROOT_FOLDER_ID } from 'reactApp/constants/magicIdentificators';
import {
    fixItemHomeLowcaseBug,
    getExtension,
    getItemNameWithoutExt,
    isFolder as checkIsFolder,
    isFolder,
    normalizePublicApiInfo,
} from 'reactApp/modules/file/utils';
import {
    publishWeblink,
    resetWeblinkCountDownloads,
    shareFolderSuccess,
    toggleWeblinkAccessRights,
    toggleWeblinkDomestic,
    toggleWeblinkDownloadable,
    unPublishWeblink,
    unshareFolderSuccess,
    updateItem,
    updateWeblinkAutoDelete,
    updateWeblinkCountDownloads,
    updateWeblinkExpires,
} from 'reactApp/modules/modifying/modifying.actions';
import {
    IShareFolderSuccess,
    IUnshareFolderSuccess,
    ResetWeblinkCountDownloads,
    ToggleWeblinkAccessRights,
    ToggleWeblinkDomestic,
    ToggleWeblinkDownloadable,
    UnpublishWeblinkAction,
    UpdateItemAction,
    UpdateWeblinkAction,
    UpdateWeblinkAutoDelete,
    UpdateWeblinkCountDownloads,
    UpdateWeblinkExpires,
} from 'reactApp/modules/modifying/modifying.types';
import {
    ApiSharedLinksResponse,
    PayloadActionUpdateIncomingCount,
    SharedFile,
    SharedFolder,
    SharedItem,
    SharedLinksState,
} from 'reactApp/modules/shared/shared.type';
import { ESortOder, ESortTypes } from 'reactApp/modules/sort/sort.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UrlBuilder } from 'reactApp/modules/urlBuilder/UrlBuilder';
import { AccessRights, Count } from 'reactApp/types/Tree';
import { normalizeMtime, renameCameraUploadFolder } from 'reactApp/utils/tree.helpers';

const urlBuilder = new UrlBuilder();

const initialState: SharedLinksState = {
    [ROOT_FOLDER_ID]: {
        id: ROOT_FOLDER_ID,
        name: 'Вы поделились',
        isLoading: false,
        count: {
            files: 0,
            folders: 0,
            all: 0,
            loaded: 0,
        },
        childs: [],
        isLoaded: false,
        sort: {
            type: ESortTypes.name,
            order: ESortOder.asc,
        },
    },
    list: {},
    sharedIncomingCounter: 0,
};

export const loadSharedLinksStartRequest = createAction('shared/load/requestStart');
export const loadSharedLinksRequest = createAction('shared/load/request');
export const loadSharedLinksSuccess = createAction<ApiSharedLinksResponse>('shared/load/success');
export const updateSharedIncomingCountSuccess = createAction<PayloadActionUpdateIncomingCount>('shared/updateIncomingCount/success');
export const updateSharedLinksCountSuccess = createAction<Count>('shared/updateSharedLinksCount/success');

export const sharedReducer = createReducer(initialState, {
    [updateItem.type]: (state, action: PayloadAction<UpdateItemAction>) => {
        const { home, kind } = action.payload;
        const item = state.list[home?.toLowerCase()];

        if (!item) {
            return;
        }

        item.kind = kind;

        if (!item.isFolder) {
            item.kind = extInfo.get(item.ext).kind;
        }

        if (!item.weblink && item.kind === 'folder') {
            state[ROOT_FOLDER_ID].childs = state[ROOT_FOLDER_ID].childs.filter((child) => child !== home);

            state[ROOT_FOLDER_ID].count.folders -= 1;
            state[ROOT_FOLDER_ID].count.all -= 1;
        }
    },
    [unPublishWeblink.type]: (state, action: PayloadAction<UnpublishWeblinkAction>) => {
        const { ids } = action.payload;

        ids.forEach((idTemp) => {
            const id = idTemp.toLowerCase();
            const item = state.list[id];

            if (!item) {
                return;
            }
            delete state.list[id];

            item.weblink = undefined;
            item.weblinkAccessRights = AccessRights.r;
            item.weblinkDomestic = false;
            item.weblinkExpires = 0;

            if (item.isFolder) {
                item.weblinkAutoDelete = false;
            }

            if (item.kind !== 'shared') {
                const rootFolder = state[ROOT_FOLDER_ID];
                rootFolder.childs = rootFolder.childs.filter((child) => child !== id);
                rootFolder.count[item?.isFolder ? 'folders' : 'files'] -= 1;
                rootFolder.count.all -= 1;
            }
        });
    },
    [publishWeblink.type]: (state, action: PayloadAction<UpdateWeblinkAction>) => {
        const { id: idTemp, weblink, item: itemNew } = action.payload;
        const id = idTemp.toLowerCase();
        const item = state.list[id];

        if (item) {
            item.weblink = weblink;
        } else if (itemNew) {
            const rootFolder = state[ROOT_FOLDER_ID];
            rootFolder.childs.push(id);
            rootFolder.count[isFolder(item) ? 'folders' : 'files'] += 1;
            rootFolder.count.all += 1;

            state.list[id] = { ...itemNew, weblink } as SharedItem;
        }
    },
    [toggleWeblinkAccessRights.type]: (state, action: PayloadAction<ToggleWeblinkAccessRights>): void => {
        const { id, type } = action.payload;
        const item = state.list[id.toLowerCase()];

        if (item) {
            item.weblinkAccessRights = type;
        }
    },
    [toggleWeblinkDownloadable.type]: (state, action: PayloadAction<ToggleWeblinkDownloadable>): void => {
        const { id, downloadable } = action.payload;
        const item = state.list[id.toLowerCase()];

        if (item) {
            item.weblinkDownloadable = downloadable;
        }
    },
    [updateWeblinkCountDownloads.type]: (state, action: PayloadAction<UpdateWeblinkCountDownloads>): void => {
        const { id, count_downloads } = action.payload;
        const item = state.list[id.toLowerCase()];

        if (item) {
            item.count_downloads_left = count_downloads;
            item.count_downloads_total = count_downloads;
        }
    },
    [resetWeblinkCountDownloads.type]: (state, action: PayloadAction<ResetWeblinkCountDownloads>): void => {
        const { id } = action.payload;
        const item = state.list[id.toLowerCase()];

        if (item) {
            item.count_downloads_total = undefined;
            item.count_downloads_left = undefined;
        }
    },
    [loadSharedLinksRequest.type]: (state) => {
        state[ROOT_FOLDER_ID].isLoaded = false;
        state[ROOT_FOLDER_ID].isLoading = true;
    },
    [updateWeblinkExpires.type]: (state, action: PayloadAction<UpdateWeblinkExpires>): void => {
        const { id, expires } = action.payload;
        const item = state.list[id.toLowerCase()];

        if (item) {
            item.weblinkExpires = expires;
        }
    },
    [updateWeblinkAutoDelete.type]: (state, action: PayloadAction<UpdateWeblinkAutoDelete>): void => {
        const { id, autoDelete } = action.payload;
        const item = state.list[id.toLowerCase()];

        if (item && item.isFolder) {
            item.weblinkAutoDelete = autoDelete;
        }
    },
    [toggleWeblinkDomestic.type]: (state, action: PayloadAction<ToggleWeblinkDomestic>): void => {
        const { id, domestic } = action.payload;
        const item = state.list[id.toLowerCase()];

        if (item) {
            item.weblinkDomestic = domestic;
        }
    },
    [loadSharedLinksSuccess.type]: (state, action: PayloadAction<ApiSharedLinksResponse>) => {
        const { list } = action.payload;

        state[ROOT_FOLDER_ID].childs = [];
        state[ROOT_FOLDER_ID].count.all = list.length;
        state[ROOT_FOLDER_ID].count.loaded = list.length;
        state[ROOT_FOLDER_ID].count.folders = 0;
        state[ROOT_FOLDER_ID].count.files = 0;

        list.forEach((item) => {
            const isFolder = checkIsFolder(item);

            item.home = fixItemHomeLowcaseBug(item.home, item.name);
            const name = item.name;
            const ext = getExtension(item);
            const nameWithoutExt = getItemNameWithoutExt(name, ext);

            state[ROOT_FOLDER_ID].childs.push(item.home);

            let normalizedItem: Partial<SharedItem> = {
                __reduxTree: true,
                id: item.home,
                name,
                size: item.size,
                type: item.type,
                home: item.home,
                weblink: '',
                storage: EStorageType.sharedLinks,
            };

            if (item.public) {
                normalizedItem = {
                    ...normalizedItem,
                    ...normalizePublicApiInfo(item),
                };
            }

            if (isFolder) {
                state.list[item.home.toLowerCase()] = {
                    ...normalizedItem,
                    name: renameCameraUploadFolder(item),
                    tree: item.tree || '',
                    rev: item.rev || 0,
                    kind: item.kind,
                    parent: ROOT_FOLDER_ID,
                    isFolder: true,
                } as SharedFolder;

                state[ROOT_FOLDER_ID].count.folders += 1;
            } else {
                const { kind, subKind } = extInfo.get(ext);
                state.list[item.home.toLowerCase()] = {
                    ...normalizedItem,
                    ext,
                    isFolder: false,
                    mtime: normalizeMtime(item.mtime),
                    hash: item.hash || '',
                    virusScan: item.virus_scan,
                    nameWithoutExt,
                    parent: ROOT_FOLDER_ID,
                    kind,
                    subKind,
                    thumbnails: urlBuilder.getThumb({
                        hash: item.hash,
                        ext,
                        id: item.home,
                        size: item.size,
                        kind,
                        name: item.name,
                        path: item.home,
                        isPublic: false,
                        isStock: false,
                        dwl_token: null,
                        weblink: '',
                    }),
                    url: urlBuilder.getUrls({
                        ext,
                        isPublic: false,
                        id: item.home,
                        weblink: '',
                        size: item.size,
                        kind,
                        subKind,
                        isStock: false,
                        name: item.name,
                        path: item.home,
                    }),
                } as SharedFile;

                state[ROOT_FOLDER_ID].count.files += 1;
            }
        });
        state[ROOT_FOLDER_ID].isLoading = false;
        state[ROOT_FOLDER_ID].isLoaded = true;
    },
    [updateSharedIncomingCountSuccess.type]: (state, action: PayloadAction<PayloadActionUpdateIncomingCount>) => {
        state.sharedIncomingCounter = action.payload.count;
    },
    [updateSharedLinksCountSuccess.type]: (state, action: PayloadAction<Count>) => {
        state[ROOT_FOLDER_ID].count = { ...action.payload };
    },
    [unshareFolderSuccess.type]: (state, action: PayloadAction<IUnshareFolderSuccess>) => {
        const { id } = action.payload;
        const item = state.list[id.toLowerCase()];

        if (!item || !checkIsFolder(item)) {
            return;
        }

        item.kind = 'folder';

        if (item.weblink) {
            return;
        }

        state[ROOT_FOLDER_ID].childs = state[ROOT_FOLDER_ID].childs.filter((child) => child !== id);

        state[ROOT_FOLDER_ID].count.folders -= 1;
        state[ROOT_FOLDER_ID].count.all -= 1;
    },
    [shareFolderSuccess.type]: (state, action: PayloadAction<IShareFolderSuccess>) => {
        const { id } = action.payload;
        const item = state.list[id.toLowerCase()];

        if (!item || !checkIsFolder(item)) {
            return;
        }

        item.kind = 'shared';
    },
});
