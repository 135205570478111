import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OvidiusCreateCopyOfNoneditableFileAPICall } from 'reactApp/api/OvidiusCreateCopyOfNoneditableFileAPICall';
import { openEditor } from 'reactApp/appHelpers/editorHelpers';
import {
    NotifyFrom,
    setNotificationAboutCreatingCopyOfNoneditableFile,
} from 'reactApp/appHelpers/notificationAboutCreatingCopyOfNoneditableFile';
import { toolbarActions } from 'reactApp/appHelpers/toolbarActions';
import { CreateCopyOfNonEditableFileModalProps } from 'reactApp/components/CreateCopyOfNoneditableFileModal/types';
import { focusOnCopiedFile } from 'reactApp/components/CreateCopyOfNoneditableFileModal/useCreateCopyOfFile.actions';
import { getFeatureEditingNoneditableFormats } from 'reactApp/modules/features/features.selectors';
import { loadHomeFolderRequest } from 'reactApp/modules/home/home.actions';
import { editPublicCopyNotification } from 'reactApp/modules/modifying/modifying.helpers';
import { snackbarController } from 'reactApp/modules/snackbar/snackbar.controller';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { RootState } from 'reactApp/store';

import { sendTechAnalytics, TechAnalyticsType } from './sendTechAnalytics';

const snackBarCommonProps = {
    closable: true,
    closeTimeout: 5000,
};

type Init = CreateCopyOfNonEditableFileModalProps & {
    isSharedFile: boolean;
    techAnalytics: ReturnType<typeof sendTechAnalytics>;
};

type CreateCopyOfFileReturnType = [boolean, () => Promise<void>];

export const useCreateCopyOfFile = ({
    file,
    editingNoneEditableFormats: { storage: convertedStorage },
    fromSharePopup,
    isSharedFile,
    onClose,
    techAnalytics,
}: Init): CreateCopyOfFileReturnType => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    // В данном случае фича точно будет присутствовать, без её наличия модальное окно не откроется
    const { ext } = useSelector((state: RootState) =>
        getFeatureEditingNoneditableFormats(state, { file, storage: convertedStorage })
    ) as NonNullable<ReturnType<typeof getFeatureEditingNoneditableFormats>>;

    const sendNotificationToEditor = (newId: string) => {
        const isPublicFile = file.storage === EStorageType.public;

        return isPublicFile
            ? editPublicCopyNotification.setText('Копия сохранена у вас в Документах')
            : dispatch(
                  setNotificationAboutCreatingCopyOfNoneditableFile({
                      newId,
                      oldId: file.id,
                      from: isSharedFile ? NotifyFrom.sharedHome : NotifyFrom.home,
                  })
              );
    };

    const handleCreateCopyOfFile = async () => {
        techAnalytics(TechAnalyticsType.clickPopupButton);
        techAnalytics(TechAnalyticsType.apiStart);

        setLoading(true);

        try {
            const { data } = await new OvidiusCreateCopyOfNoneditableFileAPICall().makeRequest({ [convertedStorage]: file.id });

            if (data) {
                dispatch(loadHomeFolderRequest({ id: file.parent, isFolder: true, force: true }));

                techAnalytics(TechAnalyticsType.apiSuccess);

                if (fromSharePopup) {
                    dispatch(focusOnCopiedFile({ file, home: data.home }));

                    onClose?.();

                    snackbarController.showSnackbar({
                        id: 'create-copy-of-non-editable-file-success',
                        text: 'Документ создан',
                        buttonText: 'Настроить доступ',
                        onButtonClick: () => {
                            toolbarActions.publish('editor', data.home);
                        },
                        type: SnackbarTypes.success,
                        oneline: true,
                        ...snackBarCommonProps,
                    });

                    return;
                }

                sendNotificationToEditor(data.home);

                openEditor({
                    ...file,
                    ext,
                    id: data.home,
                    name: data.home,
                    home: data.home,
                    size: data.size,
                });
            }
        } catch (error) {
            snackbarController.showSnackbar({
                id: 'create-copy-of-non-editable-file-error',
                text: 'Не удалось создать документ',
                type: SnackbarTypes.failure,
                ...snackBarCommonProps,
            });

            techAnalytics(TechAnalyticsType.apiError);
        } finally {
            setLoading(false);
        }

        onClose?.();
    };

    return [
        loading,
        useCallback(handleCreateCopyOfFile, [convertedStorage, dispatch, ext, file, fromSharePopup, isSharedFile, onClose, techAnalytics]),
    ];
};
