import React from 'react';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { Button } from 'reactApp/ui/Button/Button';

import styles from '../UploadBigFileDialog.css';
import { EShowTariffType, IBigFileModalContent } from '../UploadBigFileDialog.types';

export const useButtons = ({ tariffInfo, numberOfFiles, files, callbacks }: IBigFileModalContent): JSX.Element => {
    const { showTariffType } = tariffInfo;
    const isPhone = EnvironmentSelectors.isPhone();

    let buttonBuyText = 'Посмотреть тарифы';
    let buttonSkipText = 'Отменить загрузку';

    if (numberOfFiles > 1) {
        buttonSkipText = files ? 'Пропустить файлы' : 'Пропустить файл';
    }

    if (showTariffType === EShowTariffType.TRIAL) {
        buttonBuyText = 'Попробовать за 1 ₽';
    }

    return (
        <div className={styles.buttonWrapper}>
            <Button
                className={styles.button}
                onClick={callbacks.onRequestPayment}
                data-name="request-payment"
                theme="octavius"
                primary
                middle
                fluid={isPhone}
            >
                {buttonBuyText}
            </Button>
            <Button className={styles.button} onClick={callbacks.onSkip} theme="octavius" data-name="skip" middle fluid={isPhone}>
                {buttonSkipText}
            </Button>
        </div>
    );
};
