import type { TabValue } from './MobileSubscriptionsPage.types';

const USUAL = 'usual' as const;
const SPECIAL = 'special' as const;
const DISCOUNTS = 'discounts' as const;

export const TABS_MAP = {
    [USUAL]: `#${USUAL}`,
    [SPECIAL]: `#${SPECIAL}`,
    [DISCOUNTS]: `#${DISCOUNTS}`,
} as const;

export const TABS: Array<{ name: string; value: TabValue; match: TabValue[] }> = [
    {
        name: 'Тарифы',
        value: SPECIAL,
        match: [SPECIAL, USUAL],
    },
    {
        name: 'Акции',
        value: DISCOUNTS,
        match: [DISCOUNTS],
    },
];
