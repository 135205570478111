import { Method } from 'axios';
import { APICallV4 } from 'reactApp/api/APICall';

export type TSuggestHistory = {
    text: string;
};

export class SearchHistoryLoadApiCall extends APICallV4<TSuggestHistory> {
    _method = 'private/suggests/get';
    _type: Method = 'post';
}

export class SearchHistoryDeleteApiCall extends APICallV4 {
    _method = 'private/suggests/delete';
    _type: Method = 'post';
}

export class SearchHistoryClearApiCall extends APICallV4 {
    _method = 'private/suggests/clear';
    _type: Method = 'post';
}
