import diskoIcon from 'img/showCase/disko.png';
import screenshoterIcon from 'img/showCase/screenshoter.png';
import { ShowcaseItemData } from 'reactApp/ui/Showcase/ShowcaseItem/ShowcaseItem.types';

export const showcaseItemMockData: ShowcaseItemData[] = [
    {
        id: 'disko',
        title: 'Диск-О:',
        description: 'Удобная и быстрая программа\nдля работы с облачными хранилищами и резервного копирования',
        footerText: 'Бесплатно',
        buttonText: 'Установить',
        iconUrl: diskoIcon,
        clickUrlWin: 'https://disko.cloud.mail.ru/download/Disk-O_setup.exe?utm=5e2845e4319db679-0-0-',
        clickUrlOsx: 'https://trk.mail.ru/c/drrqn5?mt_idfa={mt_idfa}',
    },
    {
        id: 'screensh',
        title: 'Скриншотер',
        description: 'Создавайте, сохраняйте\nи делитесь скриншотами\nза пару кликов',
        footerText: 'Бесплатно',
        buttonText: 'Установить',
        iconUrl: screenshoterIcon,
        clickUrlWin: 'https://screenshoter.hb.bizmrg.com/Mail.Ru_Screenshoter.exe',
        clickUrlOsx: 'https://itunes.apple.com/ru/app/screenshoter-mail.ru/id1144027175?mt=12',
    },
];
