import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { useDeleteItems as useDeleteQuotaItems } from 'reactApp/ui/QuotaCleanerPage/hooks/useDeleteItems';
import { DeleteButton } from 'reactApp/ui/Toolbar/DeleteButton/DeleteButton';
import { ToolbarItem, TToolbarItem } from 'reactApp/ui/Toolbar/Toolbar.types';

export const getDeleteItem = (storage: EStorageType, items): TToolbarItem => {
    let hook;
    if (storage === EStorageType.quotaCleaner) {
        hook = useDeleteQuotaItems;
    }

    return {
        Item: hook ? (DeleteButton as ToolbarItem) : null,
        itemProps: { hook, items },
    };
};
