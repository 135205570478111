import classNames from 'clsx';
import { ReactComponent as IconClose } from 'img/icons/remove.svg';
import React, { useEffect } from 'react';
import { ITopBannerProps } from 'reactApp/ui/TopBanner/TopBanner.types';

import styles from './TopBanner.css';

export const TopBanner: React.VFC<ITopBannerProps> = ({
    onClose,
    onClick,
    onShow,
    htmlContent,
    htmlMobileContent,
    htmlReact,
    height = 60,
    closeDarkTheme = false,
    isMobile = false,
}) => {
    const isMobileVer = htmlMobileContent && isMobile;

    const content = isMobileVer ? htmlMobileContent : htmlContent;

    useEffect(() => {
        if (content || htmlReact) {
            onShow();
        }
    }, []);

    if (!content && !htmlReact) {
        return null;
    }

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_mobile]: isMobileVer,
            })}
            style={{
                height: isMobileVer ? 130 : height,
            }}
        >
            {content && <div className={styles.content} onClickCapture={onClick} dangerouslySetInnerHTML={{ __html: content }} />}
            {htmlReact && (
                <div className={styles.content} onClickCapture={onClick}>
                    {htmlReact}
                </div>
            )}
            <div
                className={classNames(styles.close, {
                    [styles.close_dark]: closeDarkTheme,
                })}
                onClickCapture={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    onClose(event);
                }}
            >
                <IconClose width={22} height={22} />
            </div>
        </div>
    );
};
