import { captureException } from '@sentry/browser';
import config from 'Cloud/config';
import { logger } from 'lib/logger';
import { GetPublicAlbumAPICall } from 'reactApp/api/albums/GetPublicAlbumAPICall';
import { IS_PUBLIC_ALBUM } from 'reactApp/appHelpers/configHelpers';
import { normalizePublicId } from 'reactApp/modules/public/public.helpers';
import { getCurrentFolderCursor, getPublicItemById, getPublicRootWeblink, isOwnPublic } from 'reactApp/modules/public/public.selectors';
import { loadPublicAlbumListSuccess, loadPublicAlbumPageSuccess } from 'reactApp/modules/public/publicAlbum.actions';
import { getCurrentRouteId } from 'reactApp/modules/router/router.selectors';
import { defaultSort, getSortById } from 'reactApp/modules/sort/sort.selectors';
import { cancel, put, select } from 'redux-saga/effects';

import { sendOpenPublicAnalytics } from './public.analytics';
import { openMobileViewer } from './public.saga';

const serverSideFolder = config.get('serverSideFolders');

const getPublicAlbumCall = ({ weblink, sort, cursor = '' }) =>
    new GetPublicAlbumAPICall().makeRequest({
        weblink_id: weblink,
        sort: sort.type,
        order: sort.order,
        cursor,
        limit: 500,
    });

function* loadAlbumMoreToFile({ fileId }) {
    try {
        const rootWeblink = yield select(getPublicRootWeblink);
        const { weblink } = rootWeblink;
        const sort = yield select((state) => getSortById(state, weblink));

        let cursor = yield select(getCurrentFolderCursor);

        while (true) {
            const { data } = yield getPublicAlbumCall({ weblink, sort: sort || defaultSort, cursor });
            // Выфильтровываем элемент, потому что он уже есть
            const list = data.list.filter((item) => item.element_id !== fileId);
            const isFileExist = list.length !== data.list.length;

            cursor = data.cursor;

            yield put(loadPublicAlbumListSuccess({ list, weblink, cursor }));

            if (!cursor || isFileExist) {
                break;
            }
        }
    } catch (error) {
        yield cancel();
    }
}

export function* loadPublicAlbumPage() {
    if (!IS_PUBLIC_ALBUM) {
        return;
    }

    try {
        yield put(loadPublicAlbumPageSuccess({ folder: serverSideFolder }));

        const id = yield select(getCurrentRouteId);
        const currentItem = yield select(getPublicItemById, normalizePublicId(id));
        const isOwn = yield select(isOwnPublic);

        sendOpenPublicAnalytics({
            id_public: serverSideFolder.public.id,
            type_public: 'folder',
            extension: 'None',
            owner: isOwn,
            source: 'album',
        });

        if (!currentItem.isFolder) {
            yield openMobileViewer();
            yield loadAlbumMoreToFile({ fileId: currentItem.id });
        }
    } catch (error) {
        logger.error(error);
        captureException(error);
    }
}

export function* loadPublicAlbumMore() {
    const cursor = yield select(getCurrentFolderCursor);

    if (!cursor) {
        return;
    }

    const rootWeblink = yield select(getPublicRootWeblink);
    const { weblink } = rootWeblink;
    const sort = yield select((state) => getSortById(state, weblink));
    const { data } = yield getPublicAlbumCall({ weblink, sort: sort || defaultSort, cursor });
    const { list, cursor: newCursor } = data;

    yield put(loadPublicAlbumListSuccess({ list, weblink, cursor: newCursor }));
}
