import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { createAvatarUrl } from 'reactApp/utils/avatar';

import styles from './Avatar.css';

export class Avatar extends PureComponent {
    static propTypes = {
        email: PropTypes.string,
        name: PropTypes.string,
        size: PropTypes.number,
        title: PropTypes.string,
        sourceSize: PropTypes.number,
    };

    render() {
        const { size, email, title, name, sourceSize } = this.props;

        if (!(email || name)) {
            return null;
        }

        const url = createAvatarUrl(email, name || email, sourceSize || size);

        return <img src={url} alt={email} width={size} height={size} className={styles.root} title={title} />;
    }
}
