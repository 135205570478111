import { xray } from 'lib/xray';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFeatureIframeDisableTimeout } from 'reactApp/modules/features/features.selectors';

export const useIframeState = (
    setDocState: (prevState) => void,
    setDocViewData: (prevState) => void
): {
    activeIframe: boolean;
    setVisibilityListener: () => void;
} => {
    // Переводим сразу в милисекунды
    const disableIframeTimeout = useSelector(getFeatureIframeDisableTimeout) * 1000;

    const [activeIframe, setActiveIframe] = useState(true);
    const [isTabEventListener, setIsTabEventListener] = useState(false);

    const iframeTimeout = useRef<number>();

    const revokeIframe = useCallback(() => {
        if (window.document.hidden) {
            return;
        }

        if (window.navigator.onLine === false) {
            return;
        }

        setDocState({
            isError: false,
            isLoaded: false,
        });

        setDocViewData((prevState) => ({
            ...prevState,
            isDocumentsViewWopiLoading: true,
        }));

        xray.send('revoke_iframe');
        setActiveIframe(true);

        window.document.removeEventListener('visibilitychange', revokeIframe);
        window.removeEventListener('online', revokeIframe);
    }, [setDocState, setDocViewData, setActiveIframe]);

    const iframeTimeoutOn = useCallback(() => {
        iframeTimeout.current = window.setTimeout(() => {
            window.document.addEventListener?.('visibilitychange', revokeIframe);

            if (window.navigator.onLine === false) {
                window.addEventListener?.('online', revokeIframe);
            }

            if (window.document.hidden) {
                xray.send('disable_iframe');
                setActiveIframe(false);
            }
        }, disableIframeTimeout);
    }, [iframeTimeout, disableIframeTimeout, setActiveIframe]);

    const switchIframeTimeout = useCallback(() => {
        if (window.document.hidden) {
            iframeTimeoutOn();
        } else {
            clearTimeout(iframeTimeout.current);
        }
    }, [iframeTimeout.current]);

    useEffect(() => {
        return () => {
            window.document.removeEventListener('visibilitychange', switchIframeTimeout);
        };
    }, []);

    const setVisibilityListener = useCallback(() => {
        if (!isTabEventListener) {
            setIsTabEventListener(true);
            window.document.addEventListener('visibilitychange', switchIframeTimeout);
        }

        if (window.document.hidden) {
            iframeTimeoutOn();
        }
    }, [switchIframeTimeout, iframeTimeoutOn]);

    return {
        activeIframe,
        setVisibilityListener,
    };
};
