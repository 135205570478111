/* eslint-disable max-lines-per-function */
import { toNDigits } from '@mail/cross-sizes-utils';
import classNames from 'clsx';
import { ReactComponent as IconClose } from 'img/close.svg';
import { ReactComponent as DotsIcon } from 'img/icons/3dots.svg';
import { ReactComponent as CancelIcon } from 'img/icons/cancel.svg';
import { ReactComponent as ArrowDownIcon } from 'img/icons/uploader/arrow_down.svg';
import { ReactComponent as UploadIcon } from 'img/icons/uploader/uploader.svg';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { accessControlListLoadStart } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.actions';
import { IAccessControlListLoadStart } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.types';
import { getCurrentFolderHome } from 'reactApp/modules/home/home.selectors';
import { HomeFolder } from 'reactApp/modules/home/home.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { setBottomMarginAction, showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { SnackbarTypes } from 'reactApp/modules/snackbar/snackbar.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { setShowInputAction } from 'reactApp/modules/upload/upload.module';
import {
    getDescriptorId,
    getErrorInfo,
    getFileName,
    isProgressComplete,
    isProgressing,
} from 'reactApp/modules/uploadList/uploadList.getters';
import { sendGaTouchUploader } from 'reactApp/modules/uploadList/uploadList.helpers';
import { EFileError, EProgressStatus, ICountStatistic, IInputFile } from 'reactApp/modules/uploadList/uploadList.model';
import { setProgressStatusAction, setShowUploaderAction, updateUploadFilesAction } from 'reactApp/modules/uploadList/uploadList.module';
import {
    getCountStatistic,
    getGroupedByFolderInputFiles,
    getPercentLoaded,
    getProblemFiles,
    getProgressStatus,
    getUserFileSizeLimit,
    hasWarningFiles,
    isUploaderVisible,
} from 'reactApp/modules/uploadList/uploadList.selectors';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { useSnackBarBottomMargin } from 'reactApp/ui/Snackbars/hooks/useSnackbarBottomMargin';
import { Item } from 'reactApp/ui/UploadList/Item/Item';
import { Progress } from 'reactApp/ui/UploadList/Progress/Progress';
import { ProgressBar } from 'reactApp/ui/UploadList/ProgressBar/ProgressBar';
import { noop } from 'reactApp/utils/helpers';

import { UploadInput } from '../Mobile/UploadInput/UploadInput';
import { PopupWarningsContainer } from './PopupWarnings/PopupWarnings';
import styles from './UploadListMobile.css';

interface IProps {
    percentLoaded: number;
    progressStatus: EProgressStatus;
    uploadFiles: IInputFile[];
    problemFiles?: IInputFile[];
    storage: EStorageType;
    hasWarningFiles: boolean;
    isInProgress: boolean;
    countStatistic: ICountStatistic;
    setShowInput(): void;
    cancelAll(): void;
    updateUploadFiles(data: any): void;
    showProblem(item: IInputFile, userFileSizeLimit: number, isCancelAll: boolean): void;
    setBottomMargin(value: number): void;
    setShowUploaderList(value: boolean): void;
    accessControlListLoadStart(payload: IAccessControlListLoadStart): void;
    userFileSizeLimit: number;
    currentFolder?: HomeFolder;
}

export const UploadListMobile = memo(
    ({
        percentLoaded,
        uploadFiles = [],
        storage,
        progressStatus,
        hasWarningFiles,
        setShowInput,
        cancelAll,
        updateUploadFiles,
        problemFiles,
        showProblem,
        isInProgress,
        countStatistic,
        setShowUploaderList,
        userFileSizeLimit,
        currentFolder,
    }: IProps) => {
        const [isCollapsed, setCollapsed] = useState(true);
        const [showMenu, setShowMenu] = useState(false);
        const [isCancelAll, setCancelAll] = useState(false);
        const showUploader = useSelector(isUploaderVisible);

        const handleCollapse = useCallback(() => {
            setCollapsed(!isCollapsed);
        }, [setCollapsed, isCollapsed]);

        const handleMenu = useCallback(() => {
            setShowMenu(true);
            sendGaTouchUploader('show', 'menu');
        }, [setShowMenu]);

        const handleCloseMenu = useCallback(() => {
            setShowMenu(false);
        }, [setShowMenu]);

        const handleUpload = useCallback(() => {
            setShowInput();
            setShowMenu(false);
            sendGaTouchUploader('click', 'upload');
        }, [setShowInput, setShowMenu]);

        const handleCancelAll = useCallback(() => {
            cancelAll();
            handleCloseMenu();
            setCancelAll(true);
            sendGaTouchUploader('cancelall');
        }, [cancelAll, handleCloseMenu]);

        const showError = useCallback(
            (file: IInputFile) => {
                showProblem(file, userFileSizeLimit, isCancelAll);
            },
            [showProblem, userFileSizeLimit, isCancelAll]
        );

        const handleCloseUploader = useCallback(() => {
            setShowUploaderList(false);
        }, [setShowUploaderList]);

        useEffect(() => {
            problemFiles?.forEach((item) => showProblem(item, userFileSizeLimit, isCancelAll));
            setCancelAll(false);
        }, [problemFiles?.length, userFileSizeLimit, isCancelAll]);

        useEffect(() => {
            sendGaTouchUploader('changecollapse');
        }, [isCollapsed]);

        let margin = 0;
        if (showMenu) {
            margin = isInProgress ? 192 : 88;
        } else {
            margin = isCollapsed ? 88 : 0;
        }

        useSnackBarBottomMargin(margin);

        useEffect(() => {
            sendGaTouchUploader('show');

            if (currentFolder) {
                // Запрашиваем, что бы получить информацию об авторе папки
                accessControlListLoadStart({ id: currentFolder?.id });
            }
        }, []);

        const renderHeader = () => {
            return (
                <div className={styles.header}>
                    <Progress
                        percentLoaded={percentLoaded}
                        countStatistic={countStatistic}
                        scrollToStart={noop}
                        status={progressStatus}
                        isMobile
                    />
                    <div className={styles.buttons}>
                        <div
                            className={classNames({ [styles.button]: true, [styles.buttonCollapsed]: isCollapsed })}
                            onClick={handleCollapse}
                        >
                            <ArrowDownIcon width={18} height={10} />
                        </div>
                        <div className={styles.button} onClick={handleMenu}>
                            <DotsIcon />
                        </div>
                        <div className={styles.button} onClick={handleCloseUploader}>
                            <IconClose width="18" height="18" fill="currentColor" />
                        </div>
                    </div>
                    <div className={styles.progressWrapper}>
                        <ProgressBar
                            percentLoaded={percentLoaded}
                            progressComplete={isProgressComplete(progressStatus)}
                            warning={hasWarningFiles}
                            hasSuccessFiles={countStatistic.successCount > 0}
                            isMobile
                        />
                    </div>
                </div>
            );
        };

        const renderContent = () => {
            if (isCollapsed) {
                return null;
            }

            return (
                <div className={styles.files}>
                    {uploadFiles.map((file, index) => (
                        <Item
                            key={index}
                            file={file}
                            show={true}
                            showError={showError}
                            isMobile
                            storage={storage}
                            updateUploadFiles={updateUploadFiles}
                        />
                    ))}
                </div>
            );
        };

        return (
            <>
                {showMenu && (
                    <MobileDialog
                        dimmer={true}
                        closable={false}
                        open={true}
                        onClose={handleCloseMenu}
                        mod="mobileV2"
                        disableScrolling={false}
                        contextPadding={24}
                        closeOnDimmerClick
                        id="upload-menu"
                        topmost
                    >
                        <div className={styles.menuWrapper}>
                            <div className={styles.menuItem} onClick={handleUpload}>
                                <div className={styles.uploadMore}>
                                    <UploadInput inputName="touch-upload-more" />
                                </div>
                                <UploadIcon width="20" height="20" />
                                <div>Загрузить еще</div>
                            </div>
                            {isInProgress && (
                                <div className={styles.menuItem} onClick={handleCancelAll}>
                                    <CancelIcon width="20" height="20" />
                                    <div>Отменить загрузку</div>
                                </div>
                            )}
                        </div>
                    </MobileDialog>
                )}
                {hasWarningFiles && <PopupWarningsContainer />}
                {showUploader && (
                    <MobileDialog
                        dimmer={false}
                        open={true}
                        closable={false}
                        onClose={handleCloseUploader}
                        mod="mobileV2"
                        disableScrolling={false}
                        contextPadding="zero"
                        fullHeight={!isCollapsed}
                        id="upload-mobile-dlg"
                        topmost
                    >
                        {renderHeader()}
                        <div className={classNames({ [styles.contentWrapper]: true, [styles.contentWrapper_collapsed]: isCollapsed })}>
                            {renderContent()}
                        </div>
                    </MobileDialog>
                )}
            </>
        );
    }
);

UploadListMobile.displayName = 'UploadListMobile';

const mapStateToProps = (state) => {
    return {
        progressValue: getPercentLoaded(state),
        uploadFiles: getGroupedByFolderInputFiles(state),
        storage: getCurrentStorage(state) as EStorageType,
        percentLoaded: getPercentLoaded(state),
        progressStatus: getProgressStatus(state),
        hasWarningFiles: hasWarningFiles(state),
        problemFiles: getProblemFiles(state),
        isInProgress: isProgressing(getProgressStatus(state)),
        countStatistic: getCountStatistic(state),
        userFileSizeLimit: getUserFileSizeLimit(state),
        currentFolder: getCurrentFolderHome(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    setShowInput: () => dispatch(setShowInputAction(true)),
    updateUploadFiles: (data) => dispatch(updateUploadFilesAction(data)),
    setBottomMargin: (value: number) => dispatch(setBottomMarginAction(value)),
    setShowUploaderList: (value: boolean) => dispatch(setShowUploaderAction(value)),
    showProblem: (item: IInputFile, userFileSizeLimit: number, isCancelAll: boolean) => {
        if ((item.error !== EFileError.CANCELLED_FILE && item.error !== EFileError.GATEWAY_ERROR) || !isCancelAll) {
            const error = item.error || EFileError.UNKNOWN;
            const errorInfo = getErrorInfo(error, {
                userFileSizeLimit: toNDigits({ bytes: userFileSizeLimit, maxDigitsCount: 3 }).value,
                fileName: getFileName(item),
            });

            dispatch(
                showSnackbarAction({
                    id: `upload-error-${item.descriptorId}`,
                    type: SnackbarTypes.failure,
                    text: errorInfo.pureText,
                    closable: true,
                })
            );
        }
        dispatch(updateUploadFilesAction({ descriptorId: getDescriptorId(item), cloudPath: item.cloudPath, hideError: true }));
    },
    cancelAll: () => dispatch(setProgressStatusAction({ status: EProgressStatus.CANCEL_ALL })),
    accessControlListLoadStart: (payload: IAccessControlListLoadStart) => dispatch(accessControlListLoadStart(payload)),
});

export const UploadListMobileContainer = connect(mapStateToProps, mapDispatchToProps)(UploadListMobile);
