import { Button, Spacing, Text, Title } from '@vkontakte/vkui';
import React, { memo } from 'react';
import { CancelIcon } from 'reactApp/ui//VKUIIcons';

import styles from './DeleteTooltip.css';
interface DeleteTooltipProps {
    onClose?: () => void;
    title: string;
    onSaveToCloud?: () => void;
}

export const DeleteTooltip = memo<DeleteTooltipProps>(function DeleteTooltip({ onClose, title, onSaveToCloud }) {
    return (
        <>
            <div className={styles.root}>
                <Title level="3">{title}</Title>
                <Spacing size={4} />
                <Text className={styles.description}>
                    Большие вложения в письмах автоматически удаляются через полгода после отправки. Сохраните его в Облако, чтобы не
                    потерять
                </Text>
                <Spacing size={16} />
                <Button className={styles.saveButton} onClick={onSaveToCloud}>
                    Сохранить в Облако
                </Button>
            </div>
            <div className={styles.closeButton} onClick={onClose}>
                <CancelIcon />
            </div>
        </>
    );
});

DeleteTooltip.displayName = 'DeleteTooltip';
