import classNames from 'clsx';
import React, { memo, useEffect } from 'react';
import { sendGaUploader } from 'reactApp/modules/uploadList/uploadList.helpers';
import { EFileError, IUploaderInformerItem } from 'reactApp/modules/uploadList/uploadList.model';
import { InfoComponent } from 'reactApp/ui/UploadList/Problems/InfoComponent';
import { InfoErrorComponent } from 'reactApp/ui/UploadList/Problems/InfoError';

import styles from './Problems.css';

interface IProps {
    item: IUploaderInformerItem;
    show?: boolean;
    isDark?: boolean;
}

export const Problem = memo(({ item, show = true, isDark = false }: IProps) => {
    const error = item.file?.error;

    let problem;

    useEffect(() => {
        if (!show || !error) {
            return;
        }

        sendGaUploader('show_error', error);
    }, [show]);

    if (item.file) {
        // eslint-disable-next-line sonarjs/no-all-duplicated-branches
        switch (error) {
            case EFileError.OVER_QUOTA_CLOUD_B2B:
            case EFileError.SKIPPED_FILE:
            case EFileError.FILE_EXIST:
            case EFileError.CANCELLED_FILE:
            case EFileError.IGNORED_FILE:
            case EFileError.NAME_TOO_LONG:
            case EFileError.INVALID_CHARACTERS:
            case EFileError.OVER_QUOTA_LIMIT_DOWNLOAD:
            case EFileError.CONNECTION_ERROR:
            case EFileError.READ_ONLY_DIRECTORY:
            case EFileError.LOCAL_FILE_NOT_FOUND:
            case EFileError.OVER_QUOTA_CLOUD_AT_OWNER:
            case EFileError.INVALID_CHARACTERS_AUTO_FIX:
            case EFileError.NAME_TOO_LONG_AUTO_FIX:
            case EFileError.USER_FILE_SIZE_LIMIT:
            case EFileError.VIOLATED_FILENAME:
            case EFileError.USER_FILE_SIZE_LIMIT_OVER_100_GB: {
                problem = <InfoErrorComponent file={item.file} />;
                break;
            }
            case EFileError.GATEWAY_ERROR:
                break;
            default: {
                problem = <InfoErrorComponent file={item.file} />;
                break;
            }
        }
    } else {
        problem = <InfoComponent type={item.type} />;
    }

    return (
        <div
            className={classNames({
                [styles.warning]: true,
                [styles.show]: show,
                [styles.dark]: isDark,
            })}
            data-qa-warning={error || item.type}
        >
            {problem}
        </div>
    );
});

Problem.displayName = 'Problem';
