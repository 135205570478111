import { Icon20Cancel } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { memo } from 'react';
import { Hint } from 'reactApp/ui/Hint/Hint';
import { Circle } from 'reactApp/ui/UploadList/Item/Circle';

import styles from './LoaderIcon.css';

interface ILoaderProps {
    progress?: number;
    className?: string;
    stroke?: number;
    radius?: number;
}

interface IProps {
    progress?: number;
    loading?: boolean;
    iconHover?: boolean;
    loaderProps?: Omit<ILoaderProps, 'progress'>;
}

const Loader = memo(function Loader({ progress = 0, className, stroke = 1, radius = 16 }: ILoaderProps) {
    return <Circle radius={radius} stroke={stroke} progress={progress} className={className} />;
});

export const LoaderIcon = memo(function LoaderIcon({ progress, loading, iconHover = false, loaderProps = {} }: IProps) {
    return (
        <div className={styles.loading}>
            {loading && <Loader progress={progress} className={classNames(styles.loader, loaderProps.className)} {...loaderProps} />}
            <Hint text="Отменить загрузку">
                <Icon20Cancel className={styles.loadingClose} />
            </Hint>
            <Loader
                progress={100}
                className={classNames(styles.loaderDefault, loaderProps.className, { [styles.loaderDefaultHover]: iconHover })}
                {...loaderProps}
            />
        </div>
    );
});
