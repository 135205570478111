import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';

export interface QuotaCleanerPageMobileProps {
    isIOSPromo?: boolean;
    isAndroidPromo?: boolean;
    isMobilePayAvailable: boolean;
}

const renderQuotaCleanerPage = () => openPopupHelper({ popupName: popupNames.QUOTA_CLEANER_PAGE });
const renderQuotaCleanerPageMobile = (data: QuotaCleanerPageMobileProps) =>
    openPopupHelper({ popupName: popupNames.QUOTA_CLEANER_PAGE_MOBILE, data });

class QuotaCleanerPageManager {
    private _dialog;

    render(isMobile: boolean, isMobilePayAvailable: boolean, isIOSPromo?: boolean, isAndroidPromo?: boolean) {
        if (!this._dialog) {
            this._dialog = isMobile
                ? renderQuotaCleanerPageMobile({ isIOSPromo, isAndroidPromo, isMobilePayAvailable })
                : renderQuotaCleanerPage();
        }
    }

    close() {
        this._dialog?.closeDialog();
        this._dialog = null;
    }
}

export const quotaCleanerPageManager = new QuotaCleanerPageManager();
