import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSearchLoadingState } from 'reactApp/modules/search/search.selectors';

const DELAY = 200;

export type TTimer = Nullable<ReturnType<typeof setTimeout>>;

export const useLongLoad = (): { longLoad: boolean; timerId: TTimer } => {
    const timerRef = useRef<TTimer>(null);
    const [longLoad, setLongLoad] = useState(false);

    const { isLoading, isLoaded } = useSelector(getSearchLoadingState);

    useEffect(() => {
        if (isLoading) {
            timerRef.current = setTimeout(() => setLongLoad(true), DELAY);
        } else if (isLoaded) {
            setLongLoad(false);
        }

        return () => {
            if (timerRef.current !== null) {
                clearTimeout(timerRef.current);
            }
            timerRef.current = null;
        };
    }, [isLoading, isLoaded]);

    return { longLoad, timerId: timerRef.current };
};
