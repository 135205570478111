import React, { ReactElement, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SharingNewDropdown } from 'reactApp/components/SharingNewBiz/Dropdown/SharingNewDropdown';
import { EDropdownColors } from 'reactApp/components/SharingNewBiz/Dropdown/SharingNewDropdown.types';
import { TOOLTIP_OFFSET } from 'reactApp/components/SharingNewBiz/SharingNew.constants';
import { EAccessRights, IAccessRightsOptions } from 'reactApp/components/SharingNewBiz/SharingNew.types';
import { Contact, contactsSlice } from 'reactApp/modules/contacts/contacts.module';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { Avatar } from 'reactApp/ui/Avatar/Avatar';
import { Dropdown } from 'reactApp/ui/Dropdown/Dropdown';
import { DropdownMenu } from 'reactApp/ui/Dropdown/DropdownMenu';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';

import { ACCESS_RIGHTS_OPTIONS } from '../User/User.constants';
import styles from './SharingNewAddUser.css';
import { SuggestionGetItem, SuggestionRenderItem } from './SharingNewAddUser.types';

export const useAccessRights = (isInputDisabled: boolean): [EAccessRights, () => ReactElement | null] => {
    const [accessRight, setAccessRight] = useState(EAccessRights.READ_WRITE);
    const isPhone = EnvironmentSelectors.isPhone();

    const renderAccessRightsDropdown = useCallback(
        (onClick: void): ReactElement => {
            const list = Object.values(ACCESS_RIGHTS_OPTIONS).map((item: IAccessRightsOptions) => ({
                id: item.key,
                text: item.text,
                check: item.key === accessRight,
                qaValue: item.key,
            }));

            return <DropdownMenu list={list} theme="octavius" onClick={onClick} className={styles.dropdownMenu} />;
        },
        [accessRight]
    );

    const handleAccessRightsUpdate = useCallback((id: EAccessRights): void => setAccessRight(id), []);

    const renderDropdown = useCallback((): ReactElement | null => {
        const item = ACCESS_RIGHTS_OPTIONS[accessRight];

        return (
            <div className={styles.rightsItem}>
                <Dropdown
                    withinDialog
                    theme="octavius"
                    qaValue={item.key}
                    as={SharingNewDropdown}
                    value={item.text}
                    color={EDropdownColors.BLUE}
                    placement={tooltipPlacements.BOTTOM_LEFT}
                    content={renderAccessRightsDropdown}
                    onClickItem={handleAccessRightsUpdate}
                    inInput
                    disabled={isInputDisabled}
                    tooltipOffset={TOOLTIP_OFFSET}
                    animatedTooltip
                    isPhone={isPhone}
                />
            </div>
        );
    }, [accessRight, handleAccessRightsUpdate, isInputDisabled, isPhone, renderAccessRightsDropdown]);

    return [accessRight, renderDropdown];
};

export const useSuggestionItem = (): [SuggestionRenderItem, SuggestionGetItem] => {
    const dispatch = useDispatch();

    const renderItem = useCallback((user?: Contact, width = 0): ReactElement | null => {
        if (!user) {
            return null;
        }

        const { name, email } = user;

        return (
            <div className={styles.user} data-qa-email={email} style={{ width }}>
                <div className={styles.avatar}>
                    <Avatar email={email} size={36} />
                </div>
                <div className={styles.info}>
                    {name && <div className={styles.name}>{name}</div>}
                    <div className={styles.email}>{email}</div>
                </div>
            </div>
        );
    }, []);

    const handleGetSuggestions = useCallback(
        (filter: string) => {
            dispatch(contactsSlice.actions.suggestRequest(filter));
        },
        [dispatch]
    );

    return [renderItem, handleGetSuggestions];
};
