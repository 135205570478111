import React, { VFC } from 'react';
import { isImage } from 'reactApp/modules/file/utils';
import { IArchiveItem } from 'reactApp/modules/viewer/viewer.types';
import { MobileViewerImg } from 'reactApp/ui/Mobile/MobileViewer/MobileViewerItem/MobileViewerImg';
import { Stub } from 'reactApp/ui/ReactViewer/Stub/Stub';

import styles from './ItemViewer.css';

interface IItemViewerContentProps {
    item: IArchiveItem;
}

export const ItemViewerContent: VFC<IItemViewerContentProps> = ({ item }) => {
    if (item.isEncrypted) {
        return (
            <div className={styles.content}>
                <Stub isVirus={false} ext={item.ext} kind={item?.kind || 'file'} mode="dark" size="big" />
                <div className={styles.contentMessage}>На этот файл установлен пароль</div>
            </div>
        );
    }
    if (isImage(item) && item.previewUrl !== null) {
        return (
            <MobileViewerImg
                src={item.previewUrl || ''}
                name={item.nameWithoutExt || ''}
                ext={item.ext}
                kind={item?.kind}
                isVirus={false}
            />
        );
    }
    return (
        <Stub
            ext={item.ext}
            name={item.nameWithoutExt || ''}
            kind={item?.kind || 'file'}
            mode="dark"
            size="big"
            showName={true}
            isVirus={false}
        />
    );
};
