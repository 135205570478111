import { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

const getTrashbin = (state: RootState) => state.trashbin;

export const getBins = createSelector(getTrashbin, (trashbin) => trashbin.bins);
export const getTrashbinFiles = createSelector(getTrashbin, (trashbin) => trashbin.files);
export const getOpenedBinId = (state: RootState) => getTrashbin(state).opened;
export const getBinById = createSelector(
    getBins,
    (state, id: string) => id,
    (bins, id) => bins.find((bin) => bin.id === id)
);
export const getOpenedBin = createSelector(
    getOpenedBinId,
    (state) => state,
    (id, state) => getBinById(state, id)
);
export const getBinItemById = createSelector(
    getTrashbinFiles,
    (state, id: string) => id,
    (files, id) => files[id]
);
export const getRemovingState = createSelector(getTrashbin, (trashbin) => trashbin.removing);
