export function appendQueryParams(originalUrl: string, newParams: Record<string, any>): string {
    try {
        const parsedUrl = new URL(originalUrl);
        Object.entries(newParams).forEach(([name, value]) => {
            if (name && value) {
                parsedUrl.searchParams.append(name, value);
            }
        });
        return parsedUrl.toString();
    } catch (_) {
        return originalUrl;
    }
}
