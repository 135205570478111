import { IInfo } from 'reactApp/components/SharingNewBiz/SharingNew.types';

export interface IProps {
    info: IInfo;
    onClose: () => void;
    onUnmount: (action: EAction) => void;
}

export enum EAction {
    REMOVE = 'remove',
    UNMOUNT = 'unmount',
}
