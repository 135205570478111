export enum FullScreenEvent {
    successStart = 'successStart',
    successStop = 'successStop',
    errorStart = 'errorStart',
    errorStop = 'errorStop',
}

type onChangeType = (type: FullScreenEvent) => void;

class FullScreenProcessor {
    private isFullScreenState = false;
    private isBrowserFullScreen = false;

    private onChange: onChangeType[] = [];

    public init() {
        window.addEventListener('webkitfullscreenchange mozfullscreenchange fullscreenchange', this.handleFullscreenChange);
        window.matchMedia('(display-mode: fullscreen)')?.addEventListener?.('change', this.handleChange);
    }

    public subscribe(onChange: onChangeType) {
        if (typeof onChange === 'function') {
            this.onChange.push(onChange);
        }
    }

    public unsubscribe(onChange: onChangeType) {
        if (typeof onChange === 'function') {
            this.onChange = this.onChange.filter((item) => item !== onChange);
        }
    }

    public switchFullscreen = () => {
        if (this.isFullScreenState || this.isBrowserFullScreen) {
            document
                .exitFullscreen()
                ?.then(() => {
                    this.isFullScreenState = false;
                })
                ?.catch(() => this.onChange.forEach((func) => func(FullScreenEvent.errorStop)));
        } else {
            document.documentElement
                .requestFullscreen?.()
                ?.then(() => {
                    this.isFullScreenState = true;
                })
                ?.catch(() => this.onChange.forEach((func) => func(FullScreenEvent.errorStart)));
        }
    };

    public isFullScreen = () => this.isFullScreenState || this.isBrowserFullScreen;

    public isFullScreenBrowserOnly = () => this.isBrowserFullScreen && !this.isFullScreenState;

    private handleChange = (event) => {
        this.isBrowserFullScreen = event.matches;
        if (!this.isBrowserFullScreen) {
            this.isFullScreenState = false;
        }

        this.onChange.forEach((func) => func(this.isFullScreenState ? FullScreenEvent.successStart : FullScreenEvent.successStop));
    };

    private handleFullscreenChange = (event) => {
        // eslint-disable-next-line compat/compat
        this.isFullScreenState = document.fullscreenElement === event.target;

        this.onChange.forEach((func) => func(this.isFullScreenState ? FullScreenEvent.successStart : FullScreenEvent.errorStop));
    };
}

export const fullScreenProcessor = new FullScreenProcessor();
