import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { FileIcon } from 'reactApp/ui/FileIcon/FileIcon';

import { Thumb } from '../Thumb/Thumb';
import styles from './FilePicture.css';

const FilePicture = ({
    loadThumb,
    thumbUrl,
    icon,
    file,
    removeLoader,
    view,
    showThumb,
    isVirus,
    borderRadius,
    onLoadThumb,
    isNew = false,
}) => {
    const [thumb, setThumb] = useState('');

    useEffect(() => {
        setThumb(thumbUrl);
    }, [thumbUrl]);

    const onErrorThumb = useCallback(() => {
        setThumb('');
    }, []);

    const props = {
        type: isVirus ? 'virus' : icon,
        showIcon: !thumb,
        view,
    };

    return (
        <div className={styles.root}>
            {!thumb && isNew ? <FileIcon.New {...props} /> : <FileIcon {...props} />}
            {showThumb && !isVirus && (
                <Thumb
                    file={file}
                    removeLoader={removeLoader}
                    url={thumb}
                    load={loadThumb}
                    view={view}
                    borderRadius={borderRadius}
                    onLoadThumb={onLoadThumb}
                    onErrorThumb={onErrorThumb}
                />
            )}
        </div>
    );
};

FilePicture.propTypes = {
    loadThumb: PropTypes.func,
    icon: PropTypes.string,
    thumbUrl: PropTypes.string,
    removeLoader: PropTypes.func,
    file: PropTypes.object,
    view: PropTypes.string,
    showThumb: PropTypes.bool,
    isVirus: PropTypes.bool,
    borderRadius: PropTypes.bool,
    onLoadThumb: PropTypes.func,
};

FilePicture.defaultProps = {
    showThumb: true,
    borderRadius: false,
};

const areEqual = (prevProps, nextProps) => {
    return prevProps.thumbUrl === nextProps.thumbUrl && prevProps.showThumb === nextProps.showThumb && prevProps.icon === nextProps.icon;
};

export default memo(FilePicture, areEqual);
