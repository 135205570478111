import { Icon20MoreVertical } from '@vkontakte/icons';
import { IconButton, List, SimpleCell } from '@vkontakte/vkui';
import { Popper } from '@vkontakte/vkui/unstable';
import React, { FC, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useClickOutsideTarget } from 'reactApp/hooks/useClickOutsideTarget';
import { continueMessage, deleteMessages, rewriteMessage } from 'reactApp/modules/editor/editor.module';
import { getLlmDialogState } from 'reactApp/modules/editor/editor.selectors';
import { LLMAnalyticsType, sendTechLLMAnalytics, useGetLLMAnalytics } from 'reactApp/ui/EditorLLM/analytics/EditorLlmAnalytics';
import iconStyles from 'reactApp/ui/EditorLLM/EditorLLMDialog/components/Messages/components/Message/components/ActionsBlock/ActionsBlock.css';
import { ACTIONS_SELECT_OPTIONS } from 'reactApp/ui/EditorLLM/EditorLLMDialog/constants/EditorLlmDialog.constants';
import { ActionCategory, Message } from 'reactApp/ui/EditorLLM/EditorLLMDialog/types/EditorLlmDialog.types';

import styles from './ActionsSelect.css';

interface Props {
    message: Message['message'];
    dataQaId: string;
}

export const ActionsSelect: FC<Props> = ({ message, dataQaId }) => {
    const dispatch = useDispatch();
    const { selectType, isLoading } = useSelector(getLlmDialogState);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const sendAnalytics = useGetLLMAnalytics();

    const handleOpen = () => {
        sendAnalytics({ action: LLMAnalyticsType.ANSWER_MENU_CLICK, dwh: { scenario: selectType.value } });
        sendTechLLMAnalytics({ action: LLMAnalyticsType.ANSWER_MENU_CLICK });
        setIsOpen(!isOpen);
    };

    const onClickAway = () => {
        setIsOpen(false);
    };

    const onDelete = () => {
        sendAnalytics({ action: LLMAnalyticsType.DELETE_CLICK, dwh: { scenario: selectType.value } });
        sendTechLLMAnalytics({ action: LLMAnalyticsType.DELETE_CLICK });
        dispatch(deleteMessages(message));
    };

    const onContinue = () => {
        sendAnalytics({ action: LLMAnalyticsType.CONTINUE_CLICK, dwh: { scenario: selectType.value } });
        sendTechLLMAnalytics({ action: LLMAnalyticsType.CONTINUE_CLICK });
        dispatch(continueMessage(message));
    };

    const onRewrite = () => {
        sendAnalytics({ action: LLMAnalyticsType.REWRITE_CLICK, dwh: { scenario: selectType.value } });
        sendTechLLMAnalytics({ action: LLMAnalyticsType.REWRITE_CLICK });
        dispatch(rewriteMessage(message));
    };

    const handlers = {
        [ActionCategory.REWRITE]: onRewrite,
        [ActionCategory.DELETE]: onDelete,
        [ActionCategory.CONTINUE]: onContinue,
    };

    const onSelectChange = (value: ActionCategory) => {
        handlers[value]();
        setIsOpen(false);
    };

    useClickOutsideTarget({ rootRef: buttonRef, ref: dropdownRef, onClickAway });

    return ACTIONS_SELECT_OPTIONS.length ? (
        <>
            <IconButton
                className={iconStyles.iconButton}
                onClick={handleOpen}
                getRootRef={buttonRef}
                disabled={isLoading}
                data-qa-id={`${dataQaId}_moreButton`}
            >
                <Icon20MoreVertical />
            </IconButton>
            {isOpen && (
                <Popper forcePortal={false} targetRef={buttonRef} placement="bottom-start">
                    <div ref={dropdownRef}>
                        <List className={styles.container} data-qa-id={`${dataQaId}_list`}>
                            {ACTIONS_SELECT_OPTIONS.map(({ value, label, icon }) => (
                                <SimpleCell
                                    key={value}
                                    before={icon}
                                    value={value}
                                    label={label}
                                    className={styles.cell}
                                    onClick={() => onSelectChange(value)}
                                    data-qa-id={`${dataQaId}_listItem_${value}`}
                                >
                                    {label}
                                </SimpleCell>
                            ))}
                        </List>
                    </div>
                </Popper>
            )}
        </>
    ) : null;
};
