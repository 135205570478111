import { bytesToNDigits, Space } from '@mail/cross-sizes-utils';
import { ReactComponent as AdIcon } from 'img/quota-landing/subscription-features/ad.svg';
import { ReactComponent as SendViaMailIcon } from 'img/quota-landing/subscription-features/sendViaMail.svg';
import { ReactComponent as SpaceIcon } from 'img/quota-landing/subscription-features/space.svg';
import { ReactComponent as SupportIcon } from 'img/quota-landing/subscription-features/support.svg';
import { ReactComponent as UploadIcon } from 'img/quota-landing/subscription-features/upload.svg';
import React, { ReactElement } from 'react';
import { ISubscription } from 'reactApp/modules/subscriptions/subscriptions.types';
import { EFeatures } from 'reactApp/sections/QuotaLanding/QuotaLanding.data';

interface IFeature {
    id: EFeatures;
    icon: ReactElement;
    text: string;
}

export const getFeatureList = (subscription: ISubscription, userSpace: Space): IFeature[] => {
    return [
        {
            id: EFeatures.space,
            text: `${subscription.space.value} в Почте и Облаке (остается всего ${
                bytesToNDigits(userSpace.original - subscription.space.original, 3).value
            })`,
            icon: <SpaceIcon height={28} width={28} />,
        },
        {
            id: EFeatures.ad,
            text: 'Отключение рекламы',
            icon: <AdIcon height={28} width={28} />,
        },
        {
            id: EFeatures.upload,
            text: 'Возможность сохранять в Облаке файлы более 2 ГБ',
            icon: <UploadIcon height={28} width={28} />,
        },
        {
            id: EFeatures.mail,
            text: 'Возможность отправлять файлы более 2 ГБ',
            icon: <SendViaMailIcon height={28} width={28} />,
        },
        {
            id: EFeatures.support,
            text: 'Приоритетная поддержка',
            icon: <SupportIcon height={28} width={28} />,
        },
    ];
};
