import { Spacing, Text } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { getFamilyTotalQuotaInfo, isUserFamilyMember } from 'reactApp/modules/family/family.selectors';
import { FamilyQuota } from 'reactApp/sections/FamilyPage/FamilySettings/FamilyQuota/FamilyQuota';

import { FamilyMembers } from './FamilyMembers/FamilyMembers';
import styles from './FamilySettings.css';

export const FamilySettings = memo(() => {
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    const isFamilyMember = useSelector(isUserFamilyMember);
    const familyQuota = useSelector(getFamilyTotalQuotaInfo);

    const description = useMemo(() => {
        if (isFamilyMember) {
            return (
                <>
                    Вам доступны преимущества платной подписки Mail Space. А также совместное место {familyQuota.value} в&nbsp;едином
                    хранилище Почты&nbsp;и&nbsp;Облака.
                </>
            );
        }

        return (
            <>
                Добавляйте близких и пользуйтесь подпиской Mail Space вместе.
                <br />
                Пригласить можно до 3 аккаунтов.
            </>
        );
    }, [familyQuota.value, isFamilyMember]);

    return (
        <div
            className={classNames(styles.root, {
                [styles.root_mobile]: isPhone,
            })}
        >
            <Text className={styles.descriptions}>{description}</Text>
            <Spacing size={40} />
            <FamilyQuota />
            {!isFamilyMember && (
                <>
                    <Spacing size={isPhone ? 12 : 16} />
                    <FamilyMembers />
                </>
            )}
        </div>
    );
});

FamilySettings.displayName = 'FamilySettings';
