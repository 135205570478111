import { BillingActions } from 'reactApp/modules/billing/billing.module';
import { takeEvery } from 'redux-saga/effects';

import { handleBuySubscription } from './sagas/buySubscription.saga';
import { handleRenewSubscription } from './sagas/renewSubscription.saga';

export function* watchBilling() {
    yield takeEvery(BillingActions.buySubscription.toString(), handleBuySubscription);
    yield takeEvery(BillingActions.renewSubscription.toString(), handleRenewSubscription);
}
