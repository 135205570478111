import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fileHistoryLoadRequest } from 'reactApp/modules/fileHistory/fileHistory.module';
import { FileHistorySelectors } from 'reactApp/modules/fileHistory/fileHistory.selectors';
import { RootState } from 'reactApp/store';
type Controller = {
    itemId: string;
};
export const useFileFoldersVersionLog = ({ itemId }: Controller) => {
    const dispatch = useDispatch();

    const { isLoading, hasError } = useSelector(FileHistorySelectors.getLoadingState);
    const items = useSelector((state: RootState) => FileHistorySelectors.getIds(state, itemId));

    useEffect(() => {
        dispatch(fileHistoryLoadRequest({ id: itemId }));
    }, [dispatch, itemId]);

    const reloadHandler = () => dispatch(fileHistoryLoadRequest({ id: itemId }));

    return {
        isLoading,
        hasError,
        items,
        reloadHandler,
    };
};
