import { StorageCurrentFolder } from 'reactApp/modules/storage/storage.selectors';

export interface IProps {
    folder: StorageCurrentFolder;
}

export interface IActionItem {
    title: string;
    annotation: string;
    className?: string;
    Icon?: JSX.Element;
    onClick: () => void;
    additionLinks?: JSX.Element;
}

export enum EItemType {
    CREATE = 'create',
    COSHARING = 'cosharing',
    UPLOAD = 'upload',
    SELECT_FROM_CLOUD = 'upload-from-cloud',
}

export const gaSuffix = 'empty-folder';
