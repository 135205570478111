import { ReactComponent as LockIcon } from '@vkontakte/icons/src/svg/16/lock_outline_16.svg';
import React, { ReactElement, useMemo } from 'react';
import { SharingNewDropdown } from 'reactApp/components/SharingNewBiz/Dropdown/SharingNewDropdown';
import { getDomainAccessOptions, QA_VALUE, TOOLTIP_OFFSET } from 'reactApp/components/SharingNewBiz/SharingNew.constants';
import { DomainAccess } from 'reactApp/components/SharingNewBiz/SharingNew.types';
import styles from 'reactApp/components/SharingNewBiz/Weblink/SharingNewWeblink.css';
import { Dropdown } from 'reactApp/ui/Dropdown/Dropdown';
import { tooltipPlacements } from 'reactApp/ui/Tooltip/Tooltip.constants';

interface Props {
    checked: DomainAccess;
    accessText: string;
    isPhone: boolean;
    renderDomesticDropdown: (onClick: void) => ReactElement;
    handleUpdateDomestic: (id: DomainAccess) => void;
    domain: string;
    disabled?: boolean;
}

export const DomesticDropdownTemplate: React.FC<Props> = React.memo(
    ({ checked, accessText, isPhone, renderDomesticDropdown, handleUpdateDomestic, domain, disabled }) => {
        const domainAccessOptions = useMemo(() => getDomainAccessOptions(domain), [domain]);
        const item = domainAccessOptions[checked];
        return (
            <div className={styles.rightsItem} data-qa-item={QA_VALUE.domesticItem}>
                <div className={styles.titleWrapper}>
                    <span className={styles.icon}>
                        <LockIcon />
                    </span>
                    <span className={styles.rightsTitle}>{accessText}</span>
                </div>
                <div className={styles.dropdown}>
                    <Dropdown
                        withinDialog
                        theme="octavius"
                        qaValue={item.id}
                        disabled={disabled}
                        as={SharingNewDropdown}
                        value={item.text}
                        content={renderDomesticDropdown}
                        onClickItem={handleUpdateDomestic}
                        placement={tooltipPlacements.BOTTOM_LEFT}
                        qaValueContent={QA_VALUE.domesticDropdownContent}
                        tooltipOffset={TOOLTIP_OFFSET}
                        animatedTooltip
                        tooltipClassName={isPhone ? styles.tooltip : undefined}
                        isPhone={isPhone}
                    />
                </div>
            </div>
        );
    }
);
DomesticDropdownTemplate.displayName = 'DomesticDropdownTemplate';
