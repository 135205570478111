import { AuthClient } from '@tutoria/auth-sdk';
import { AxiosRequestHeaders } from 'axios';
import { logger } from 'lib/logger';
import { parse } from 'qs';

export const isUseO2Token = () =>
    typeof window !== 'undefined' && // отключаем для SSR
    parse(window.location.search, { ignoreQueryPrefix: true })?.auth === 'o2';

export class O2AuthClient {
    private readonly authClient: AuthClient | null = null;
    private tokenPromise: Promise<string | null>;
    private loading = false;

    constructor() {
        if (!isUseO2Token()) {
            this.tokenPromise = Promise.resolve(null);
            return;
        }

        const authOrigin = parse(window.location.search, { ignoreQueryPrefix: true })?.auth_origin;

        this.authClient = new AuthClient({
            authProviderUrl: authOrigin,
            onError: (error) => {
                logger.error(error, '[OauthClient::onError]');
            },
        });

        this.tokenPromise = this.authClient.handshake().then(() => {
            return this.requestToken();
        });
    }

    private requestToken() {
        if (!this.authClient) {
            return Promise.resolve(null);
        }

        if (this.loading && this.tokenPromise) {
            return this.tokenPromise;
        }

        this.loading = true;

        return this.authClient
            .request('CloudOauthToken')
            .then((data) => {
                if (!data?.access_token) {
                    throw new Error(data);
                }
                return data.access_token;
            })
            .catch((error: unknown) => {
                logger.error(error, '[OauthClient::requestToken]');
                throw error;
            })
            .finally(() => {
                this.loading = false;
            });
    }

    public getToken() {
        return this.tokenPromise;
    }

    public static prepareAuthHeader(token: string | null): AxiosRequestHeaders {
        return token ? { Authorization: `Bearer ${token}` } : {};
    }

    public refreshToken(): Promise<string | null> {
        if (isUseO2Token()) {
            this.tokenPromise = this.requestToken();
        }
        return this.tokenPromise;
    }
}

export const o2AuthClient = new O2AuthClient();
