/**
 * Данные получаемые из API
 */
export type WopiData = {
    fileid: string;
    wopi_host: string;
    url: string;
    access_token: string;
    access_token_ttl: number;
};
/**
 * Провайдер необходимый для запуска просмотра/редактирования документа
 */
export enum WopiProvider {
    R7 = 'r7',
    R7_WOPI = 'r7-wopi',
    MYOFFICE = 'co',
    OFFICE = 'ms',
}
