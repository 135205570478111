import { moveItemsDialog } from 'reactApp/appHelpers/appHelpers';
import { selectOne, setDragging } from 'reactApp/modules/selections/selections.actions';
import { isDomainFolder } from 'reactApp/modules/storage/folder.helpers';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { closeContextMenu } from 'reactApp/ui/ContextMenu/createContextMenuToolkit';
import { sendDatalistGa } from 'reactApp/utils/datalistGa';
import { sendGa } from 'reactApp/utils/ga';

export const onStartDrag = ({ dragParams, selectedIdxs, dispatch, storage, prevSelectHandler }) => {
    dragParams.current.isDragging = true;
    dispatch(setDragging(true));
    const { dragItem } = dragParams.current;

    if (dragItem?.id && !selectedIdxs.includes(dragItem.id)) {
        dispatch(selectOne({ selectedIdx: dragItem.id, storage: storage as EStorageType }));
    }

    // Для сафари, иначе курсор не меняется на драггинг
    // при прекращении драга вернуть назад
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    prevSelectHandler.current = document.onselectstart;
    document.onselectstart = (): boolean => {
        return false;
    };

    closeContextMenu();

    sendGa('dnd-new', 'start');
};

export const onStopDrag = ({
    setDragOverItem,
    setDragPosX,
    setDragPosY,
    prevSelectHandler,
    dragParams,
    dragOverItem,
    selectedItems,
    isDragging,
    dispatch,
}) => {
    dispatch(setDragging(false));
    setDragOverItem(null);
    setDragPosX(0);
    setDragPosY(0);
    document.onselectstart = prevSelectHandler.current;
    dragParams.current = {
        startY: 0,
        startX: 0,
        dragItem: null,
        isDragging: false,
    };

    if (isDragging && dragOverItem && selectedItems.length) {
        const draggingItems = selectedItems;
        const isValidDestination = dragOverItem.isFolder && !draggingItems.some((el) => el?.id === dragOverItem.id);
        const isValidItems = !draggingItems.some(isDomainFolder);

        if (!isValidDestination || !isValidItems) {
            return;
        }

        sendGa('dnd-new', 'drop');
        sendDatalistGa('drop', selectedItems.length === 1 ? 'one' : 'many');

        moveItemsDialog(draggingItems, dragOverItem);
    }
};

export const onDraggingOver = ({ isDragging, item, dragOverItem, setDragOverItem }) => {
    if (!isDragging) {
        return;
    }

    if (item && item?.id !== dragOverItem?.id) {
        setDragOverItem(item);
    }
};
