import { IACListItem } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.types';
import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';

interface Props {
    user?: IACListItem;
    onActionClick(): void;
}

export const renderDeleteUserDialog = (props: Props) => {
    const { onActionClick, user } = props;

    openPopupHelper({
        popupName: popupNames.NO_WARNING,
        data: {
            id: 'delete-user-warning-dialog',
            onActionClick,
            title: `Вы действительно хотите отключить доступ для ${user?.email}?`,
            description: `После отключения ${user?.email} не сможет просматривать эту папку`,
            buttonAcceptText: 'Отключить',
            buttonRejectText: 'Не отключать',
        },
    });
};
