import { Icon20Dropdown } from '@vkontakte/icons';
import { Button, ButtonGroupProps, List } from '@vkontakte/vkui';
import classNames from 'clsx';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getSearchRequestParams } from 'reactApp/modules/search/search.selectors';
import { EActionSearch } from 'reactApp/modules/search/search.types';
import { RootState } from 'reactApp/store';
import { useWebSearchFilters } from 'reactApp/ui/WebSearch/hooks/useWebSearchFilters';
import { WebFilterCell } from 'reactApp/ui/WebSearch/WebFilterCell';
import {
    ESearchOptionSource,
    ESearchOptionType,
    fileTypeOptions,
    fileTypeOptionsList,
    IFilters,
    sourceTypeOptions,
    sourceTypeOptionsList,
} from 'reactApp/ui/WebSearch/WebSearch.data';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

import { Popup } from './Popup';
import styles from './WebSearchToolbar.css';

export const WebSearchToolbar: React.FC<ButtonGroupProps> = ({ className, ...props }) => {
    const fileTypeButtonRef = useRef<HTMLButtonElement>(null);
    const fileSourceButtonRef = useRef<HTMLButtonElement>(null);
    const [isOpen, setIsOpen] = useState<{ type: boolean; source: boolean }>({ type: false, source: false });
    const { type, source, onSelect } = useWebSearchFilters();
    const storage = useSelector((state) => getCurrentStorage(state));
    const { query } = useSelector((state: RootState) => getSearchRequestParams(state));

    const searchOptionType = (type && fileTypeOptions[type]) || fileTypeOptions.all;
    const searchOptionSource = (source && sourceTypeOptions[source]) || sourceTypeOptions[ESearchOptionSource.all];
    const typeIsAllOrDocs = type === ESearchOptionType.all || type === ESearchOptionType.document;

    const dwh = {
        search_phrase: query,
        place: 'web',
        section: storage,
        action_place: 'toolbar',
    };

    const onClick = (button: 'type' | 'source') => {
        setIsOpen((currentIsOpen) => ({
            ...currentIsOpen,
            [button]: !currentIsOpen[button],
        }));
    };

    const closePopups = () => {
        setIsOpen({ type: false, source: false });
    };

    const onTypeSelect = (type: ESearchOptionType) => {
        sendDwh({
            eventCategory: ECategoryGa.search,
            action: EActionSearch.filters,
            dwhData: {
                ...dwh,
                filters: type,
            },
        });
        const filters: IFilters = { type };
        if (type !== ESearchOptionType.all && type !== ESearchOptionType.document) {
            filters.source = ESearchOptionSource.name;
        } else {
            filters.source = ESearchOptionSource.all;
        }
        closePopups();
        onSelect(filters, true);
    };

    const onSourceSelect = (source: ESearchOptionSource) => {
        sendDwh({
            eventCategory: ECategoryGa.search,
            action: EActionSearch.filtersPlacement,
            dwhData: {
                ...dwh,
                filters_placement: source,
            },
        });
        closePopups();
        onSelect({ source }, true);
    };

    const optionsList = fileTypeOptionsList.map((item) => ({
        ...item,
        onClick: () => {
            onTypeSelect(item.id);
        },
    }));

    const sourceList = sourceTypeOptionsList.map((item) => ({
        ...item,
        onClick: () => {
            onSourceSelect(item.id);
        },
    }));

    return (
        <div className={classNames(className, styles.group)} {...props}>
            <Button
                appearance="neutral"
                mode="secondary"
                size="m"
                getRootRef={fileTypeButtonRef}
                after={<Icon20Dropdown />}
                onClick={() => onClick('type')}
            >
                {searchOptionType.text}
            </Button>
            {isOpen.type && (
                <Popup targetRef={fileTypeButtonRef} onClickAway={closePopups}>
                    <List className={styles.container}>
                        {optionsList.map(({ id, icon, text, onClick }) => (
                            <WebFilterCell key={id} before={icon} onClick={onClick} checked={searchOptionType?.id === id}>
                                {text}
                            </WebFilterCell>
                        ))}
                    </List>
                </Popup>
            )}
            {typeIsAllOrDocs && (
                <>
                    <Button
                        appearance="neutral"
                        mode="secondary"
                        size="m"
                        getRootRef={fileSourceButtonRef}
                        after={<Icon20Dropdown />}
                        onClick={() => onClick('source')}
                    >
                        {searchOptionSource.text}
                    </Button>
                    {isOpen.source && (
                        <Popup targetRef={fileSourceButtonRef} onClickAway={closePopups}>
                            <List className={styles.container}>
                                {sourceList.map(({ id, icon, text, onClick }) => (
                                    <WebFilterCell key={id} before={icon} onClick={onClick} checked={searchOptionSource?.id === id}>
                                        {text}
                                    </WebFilterCell>
                                ))}
                            </List>
                        </Popup>
                    )}
                </>
            )}
        </div>
    );
};

WebSearchToolbar.displayName = 'WebSearchToolbar';
