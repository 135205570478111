import debounce from 'lodash.debounce';
import { Kind } from 'reactApp/types/Tree';
import { sendKaktamLog, sendXray } from 'reactApp/utils/ga';
import videojs from 'video.js';
import { QualityLevelList } from 'videojs-contrib-quality-levels';

import { sendViewerDwh } from '../ReactViewer.helpers';

export type GaData = {
    source: string;
    is_stories: boolean;
    have_face: boolean;
    id_public: string;
    type_content: Kind;
    extension: string;
    is_archive: boolean;
    is_attache: boolean;
    name: string;
    is_touch: boolean;
    size_files?: number;
} & Record<string, any>;

type IAction = 'on' | 'off';

type ISubscriber = (action: IAction) => void;

export class VideoPlayerGa {
    wasStarted = false;
    wasSeeked = false;
    isUserChangeQuality = false;
    lastQualityHeight = 0;
    qualityLevels: QualityLevelList;
    lastVolume: number;
    progress: Element;
    playbackRateNative: { (rate: number): void; (): number };
    lastRate = 1;
    subscribers: ISubscriber[];

    constructor(private player: videojs.Player, private data: GaData) {
        this.wasStarted = this.player.hasStarted();
        this.qualityLevels = this.player.qualityLevels();
        this.lastVolume = this.player.volume();
        this.progress = this.player.controlBar.$('.vjs-progress-control');
        this.playbackRateNative = this.player.playbackRate.bind(this.player);

        this.subscribers = [
            this.qualitySubscriptions.bind(this),
            this.playSubscriptions.bind(this),
            this.volumeSubscriptions.bind(this),
            this.loopSubscriptions.bind(this),
            this.pipSubscriptions.bind(this),
            this.rewindSubscriptions.bind(this),
            this.playbackSubscriptions.bind(this),
            this.errorSubscriptions.bind(this),
            this.disposeSubscriptions.bind(this),
        ];

        sendXray(['video-player', 'render']);
    }

    subscribe() {
        this.wasStarted = this.player.hasStarted();
        this.lastVolume = this.player.volume();
        this.lastRate = this.player.playbackRate();

        this.subscribers.forEach((sub) => sub('on'));
    }

    unsubscribe() {
        this.subscribers.forEach((sub) => sub('off'));
    }

    private onUserChangeQuality = () => {
        this.isUserChangeQuality = true;
    };

    private onQualityChanged = () => {
        const previoseQuality = this.lastQualityHeight;
        const selectedQuality = this.qualityLevels[this.qualityLevels.selectedIndex]?.height;
        if (!selectedQuality) {
            return;
        }
        this.lastQualityHeight = selectedQuality;

        sendViewerDwh({
            ...this.data,
            action: 'quality-change',
            type_change: this.isUserChangeQuality ? 'manual' : 'auto',
            quality_last: previoseQuality,
            quality_now: selectedQuality,
        });
        this.isUserChangeQuality = false;
    };

    private onStart = () => {
        this.onQualityChanged();
        this.qualityLevels.on('change', this.onQualityChanged);
        this.player.off('play', this.onStart);
    };

    private qualitySubscriptions(action: IAction) {
        this.player[action]('changequality', this.onUserChangeQuality);

        if (action === 'off') {
            this.player.off('play', this.onStart);
            this.qualityLevels.off('change', this.onQualityChanged);
        } else if (this.wasStarted) {
            this.onQualityChanged();
            this.qualityLevels.on('change', this.onQualityChanged);
        } else {
            this.player.on('play', this.onStart);
        }
    }

    private onPlay = () => {
        if (this.wasSeeked) {
            this.wasSeeked = false;
            return;
        }

        if (this.wasStarted) {
            sendViewerDwh({ ...this.data, action: 'media-continue' });
        } else {
            this.wasStarted = true;
            sendViewerDwh({ ...this.data, action: 'media-start' });
        }
    };

    private onPause = () => {
        if (this.player.seeking()) {
            this.wasSeeked = true;
            return;
        }

        const ended = this.player.ended();

        sendViewerDwh({
            ...this.data,
            action: 'media-stop',
            type_stop: ended ? 'end' : 'user',
        });
    };

    private playSubscriptions(action: IAction) {
        this.player[action]('play', this.onPlay);
        this.player[action]('pause', this.onPause);
    }

    private volumeChangeLog = () => {
        sendViewerDwh({
            ...this.data,
            action: 'sound-level-change',
            sound_level_last: this.lastVolume,
            sound_level_now: this.player.volume(),
        });
        this.lastVolume = this.player.volume();
    };

    private onVolumeChange = debounce(this.volumeChangeLog, 300);

    private volumeSubscriptions(action: IAction) {
        this.player[action]('volumechange', this.onVolumeChange);
    }

    private onLoopChange = () => {
        sendViewerDwh({ ...this.data, action: 'reload-change' });
    };

    private loopSubscriptions(action: IAction) {
        this.player[action]('loopchange', this.onLoopChange);
    }

    private onEnterPIP = () => {
        sendViewerDwh({ ...this.data, action: 'pip-open' });
    };

    private onLeavePIP = () => {
        sendViewerDwh({ ...this.data, action: 'pip-close' });
    };

    private pipSubscriptions(action: IAction) {
        this.player[action]('enterpictureinpicture', this.onEnterPIP);
        this.player[action]('leavepictureinpicture', this.onLeavePIP);
    }

    private onRewindBackward = () => {
        sendViewerDwh({ ...this.data, action: 'quickly-move', type_move: 'minus' });
    };

    private onRewindForward = () => {
        sendViewerDwh({ ...this.data, action: 'quickly-move', type_move: 'plus' });
    };

    private onProgressChange = () => {
        sendViewerDwh({ ...this.data, action: 'rewind-change' });
        this.progress.removeEventListener('pointerup', this.onProgressChange);
    };

    private onProgressPointerDown = () => {
        this.progress.addEventListener('pointerup', this.onProgressChange);
    };

    private rewindSubscriptions = (action: IAction) => {
        this.player[action]('rewindbackward', this.onRewindBackward);
        this.player[action]('rewindforward', this.onRewindForward);
        if (action === 'off') {
            this.progress.removeEventListener('pointerdown', this.onProgressPointerDown);
            this.progress.removeEventListener('pointerup', this.onProgressChange);
        } else {
            this.progress.addEventListener('pointerdown', this.onProgressPointerDown);
        }
    };

    private onPlaybackRate = (rate?: number) => {
        if (rate && rate !== this.lastRate) {
            sendViewerDwh({
                ...this.data,
                action: 'speed-change',
                speed_last: this.lastRate,
                speed_now: rate,
            });
            this.lastRate = rate;
        }

        if (rate !== undefined) {
            return this.playbackRateNative(rate);
        }
        return this.playbackRateNative();
    };

    private playbackSubscriptions = (action: IAction) => {
        if (action === 'off') {
            this.player.playbackRate = this.playbackRateNative;
        } else {
            this.playbackRateNative = this.player.playbackRate.bind(this.player);

            // @ts-ignore
            this.player.playbackRate = this.onPlaybackRate;
        }
    };

    private onError = () => {
        const error = this.player.error();

        if (!error?.code) {
            // Кастомные ошибки (не из самого плеера)
            return;
        }

        sendViewerDwh({
            ...this.data,
            action: 'media-error',
            type_error: error?.code.toString(),
            error_message: error?.message,
        });
        sendXray([
            'video-player',
            'error',
            this.data.extension,
            error?.code.toString(),
            this.data.is_attache ? 'attache' : '',
            this.data.is_archive ? 'archive' : '',
        ]);
        sendKaktamLog(
            {
                ...this.data,
                action: 'media-error',
                label: error?.code.toString(),
                error_message: error?.message,
            },
            'cloud_video-player'
        );
    };

    private errorSubscriptions = (action: IAction) => {
        this.player[action]('error', this.onError);
    };

    private onDispose = () => {
        if (!this.player.hasStarted()) {
            return;
        }

        const ranges = this.player.played();
        let allTime = 0;

        for (let i = 0; i < ranges.length; i++) {
            allTime += ranges.end(i) - ranges.start(i);
        }

        const duration = this.player.duration();

        let watch_percent, percent_close;
        if (duration && duration !== Infinity) {
            watch_percent = Math.floor((allTime / duration) * 100);
            percent_close = Math.floor((this.player.currentTime() / duration) * 100);
        }

        sendViewerDwh({
            ...this.data,
            action: 'mediafile-close',
            percent_close,
            watch_percent,
        });
    };

    private disposeSubscriptions = (action: IAction) => {
        this.player[action]('dispose', this.onDispose);
    };
}
