import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { complement, filter, hasPath, path,reduce } from 'ramda';
import { PurchasedGiftsFetch, PurchasedGiftsUpdate } from 'reactApp/modules/purchasedGifts/purchasedGifts.constants';

export const purchasedGiftsReducer = (state = {}, action) => {
    switch (action.type) {
        case PurchasedGiftsFetch.SUCCESS:
        case PurchasedGiftsUpdate.SUCCESS:
            return {
                ...state,
                list: reduce(
                    (acc, gift) => ({
                        ...acc,
                        [gift.code]: {
                            isGifted: gift.gifted,
                            created: gift.created_at * 1000,
                            id: gift.gift_id,
                            expires: gift.expire_at * 1000,
                            start: gift.start_at,
                            code: gift.code,
                            giver: gift.giver,
                            receiver: gift.receiver,
                            space: bytesToNDigits(path(['services', 'quota'], gift), 3),
                        },
                    }),
                    {},
                    filter(complement(hasPath(['services', 'unlocked'])), action.list)
                ),
                rawList: action.list,
            };

        default:
            return state;
    }
};
