import { useSelector } from 'react-redux';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { isFileProgress, isFileWarning } from 'reactApp/modules/uploadList/uploadList.getters';
import { IInputFile } from 'reactApp/modules/uploadList/uploadList.model';
import { getUploadingItem } from 'reactApp/modules/uploadList/uploadList.selectors';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { RootState } from 'reactApp/store';

export const useCalculatedOptions = ({ isDragOver, item, descriptorId, view, isMobile, storage }) => {
    const { isHome, isAttaches } = getStorage(storage);
    const uploadingItem: IInputFile | null = useSelector((state: RootState) =>
        getUploadingItem(state, descriptorId, item?.name, item?.kind === 'folder')
    );

    const isDraggable = isHome && !isMobile;
    const isDroppable = isDragOver && item?.isFolder;

    // если есть descriptorId значит будет uploadingItem с полем status
    const denyDoAction =
        (descriptorId && uploadingItem && isFileProgress(uploadingItem)) ||
        (!!uploadingItem && (isFileProgress(uploadingItem) || isFileWarning(uploadingItem)));
    const skipDoubleClick = isMobile || view === EViewMode.gallery;
    // Если пришли из почты, то сразу показываем просмотрщик, даталист рендерится под ним и деалет кучу запросов в тамбы, нагружая почту.
    // Поэтому пока показывается просмотрщик, тамбы в даталисте не показываем.
    const isViewerOpening = useSelector(ViewerSelectors.isViewerOpening);
    const noThumbs = (isAttaches && isViewerOpening) || denyDoAction;

    return { denyDoAction, skipDoubleClick, noThumbs, isDraggable, isDroppable, uploadingItem };
};
