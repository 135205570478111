import { ReactComponent as ArrowNext } from 'img/icons/mobile/arrowNext.svg';
import React, { VFC } from 'react';

import styles from './FileDirection.css';

type Props = {
    direction: string[];
};

export const FileDirection: VFC<Props> = ({ direction }) => (
    <div className={styles.root}>
        <span>Облако</span>
        {direction.length > 0 && (
            <>
                {direction.length > 1 && (
                    <>
                        <ArrowNext width={20} height={20} />
                        <span>{'\u2026'}</span>
                    </>
                )}
                <ArrowNext width={20} height={20} />
                <span className={styles.folder}>{direction.slice(-1)[0]}</span>
            </>
        )}
    </div>
);
