import classNames from 'clsx';
import React, { memo, useCallback, useEffect } from 'react';
import { IS_ONPREMISE, IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { Kind } from 'reactApp/types/Tree';
import { Button } from 'reactApp/ui/Button/Button';
import { FileIcon } from 'reactApp/ui/FileIcon/FileIcon';
import { getIconType } from 'reactApp/ui/FileIcon/getIconType';
import { NameComponent } from 'reactApp/ui/Name/Name';
import { IViewerDwhData } from 'reactApp/ui/ReactViewer/ReactViewer.helpers';
import { noop } from 'reactApp/utils/helpers';

import { EbookStub } from './EbookStub';
import styles from './Stub.css';

interface Props {
    ext?: string;
    kind: Kind;
    isVirus: boolean;
    name?: string;
    showName?: boolean;
    mode?: 'light' | 'dark';
    size?: 'small' | 'medium' | 'big';
    isDownloadable?: boolean;
    itemSize?: number | string;
    isFolder?: boolean;
    isEbook?: boolean;
    fileHash?: string;

    sendRadar?: (data: IViewerDwhData) => void;

    onClick?(): void;

    onActionButtonClick?(): void;
}

export const Stub = memo(function Stub({
    ext,
    kind,
    isVirus,
    name,
    onClick = noop,
    showName = true,
    mode = 'light',
    size = 'medium',
    isDownloadable,
    itemSize,
    sendRadar,
    isFolder,
    isEbook = false,
    onActionButtonClick = noop,
    fileHash = '',
}: Props): JSX.Element | null {
    const iconType = getIconType({ isFolder, ext, kind, isVirus });

    const handleDownload = useCallback(
        (event: React.MouseEvent) => {
            event.stopPropagation();
            if (isEbook) {
                onActionButtonClick();
                return;
            }
            onClick();
        },
        [onClick, isEbook, onActionButtonClick]
    );

    useEffect(() => {
        sendRadar?.({ action: 'open-content-preview-error', forceSend: true });
    }, []);

    return (
        <div
            className={classNames({
                [styles.root]: true,
                [styles[`root_${size}`]]: true,
                [styles.cursor_default]: isDownloadable,
                [styles.root_mobile]: IS_PHONE_BROWSER,
            })}
            onClick={IS_ONPREMISE ? noop : handleDownload}
        >
            <div className={styles.content}>
                <FileIcon type={iconType} view={EViewMode.thumbs} mode={mode} />
            </div>
            {showName && name && (
                <div className={styles.footer}>
                    <div className={styles.meta}>
                        <div className={styles.name}>
                            <NameComponent name={name} extension={ext} showTooltip />
                        </div>
                        {isDownloadable && (
                            <Button className={styles.download_btn} onClick={handleDownload} size="big" theme="base" middle secondary>
                                Скачать {IS_ONPREMISE && itemSize}
                            </Button>
                        )}
                    </div>
                </div>
            )}
            {Boolean(isEbook) && <EbookStub fileHash={fileHash} onActionButtonClick={onActionButtonClick} ext={ext} />}
        </div>
    );
});
