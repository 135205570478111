import { ReactElement, ReactNode, RefObject } from 'react';
import { HomeFolder, HomeItem } from 'reactApp/modules/home/home.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { TreeNodeData } from 'reactApp/ui/TreeComponent/TreeNode.types';

export interface SelectedItemData {
    id: string;
    isFolder: boolean;
    home?: string;
    storage?: EStorageType;
    name?: string;
}

export enum ESelectFolderDialogMod {
    createShare = 'createShare',
    trashBin = 'trashBin',
}

export enum EActionId {
    COPY = 'copy',
    MOVE = 'move',
    CLONE = 'clone',
    CREATE_SHARE = 'createShare',
    RESTORE = 'restore',
    SELECT_FOLDER_PATH = 'selectFolderPath',
    SELECT_FOLDER_PATH_COMMON = 'selectFolderPathCommon',
    SELECT_FOLDER_PATH_TO_UPLOAD = 'selectFolderPathToUpload',
}

type AnyFunction = (data?: any) => any;

export interface Props {
    title: string;
    fullTitle?: ReactElement;
    selectButtonText: string;
    waiterText: string;
    selectedId: string;
    isPhone?: boolean;
    isMobile?: boolean;
    onActionClick: ({ items, destination }) => void;
    onClose: AnyFunction;
    onClosePopup?: ({ onClosePopup }: { onClosePopup: () => void }) => ReactElement;
    onShowDialog: AnyFunction;
    onHideDialog: AnyFunction;
    requestLoadFoldersTree: AnyFunction;
    onSelect: ({ id, storage }) => Promise<{ disableAction: boolean }>;
    onExpand: ({ id, storage, isOpen }) => void;
    onMakeNewFolder: ({
        id,
        storage,
        autoCreateFolderId,
    }: {
        id?: string;
        storage: EStorageType;
        autoCreateFolderId?: string;
    }) => Promise<string>;
    items: TreeNodeData[];
    selectedItems: SelectedItemData[];
    dialogMod?: ESelectFolderDialogMod;
    actionId: EActionId;
    closeOnAction: boolean;
    alertApproveTitle: string;
    rootFolderList?: HomeFolder;
    actionPart?: string;
    autoCreateSelectedId?: boolean;
    disableSelectSameId?: boolean;
    homeList: HomeItem[];
    domainFoldersFilterActive?: boolean;
}

export interface State {
    disableAction: boolean;
    isInAction: boolean;
    selectedId: string;
    selectedNodeId: string;
    isSharedOrMounted: boolean;
    isThisOrParentPublic: boolean;
    showOnClosePopup: boolean;
}

export type GetMainTitleProps = Pick<Props, 'isPhone' | 'title' | 'selectedItems' | 'waiterText' | 'fullTitle'> & Pick<State, 'isInAction'>;

type DialogCommonProps = {
    onClose: () => void;
    children: ReactNode;
    open: boolean;
    id: string;
    footer: ReactNode;
    title: ReactNode;
    closeOnDimmerClick: boolean;
    scrollRef?: RefObject<HTMLDivElement>;
    className?: string;
};

type DialogComponent = (props: DialogCommonProps) => ReactElement;

type Device = 'mobile' | 'desktop';

export type DialogComponentRecord = Record<Device, DialogComponent>;

export type Answer = { action: 'cancel' } | { action: 'upload'; destination: string };
