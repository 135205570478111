import { ActivitiesState } from 'reactApp/modules/activities/activities.types';
import { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

const getActivitiesState = (state: RootState): ActivitiesState => state.activities;

const isActivitiesLoading = createSelector(getActivitiesState, (state) => state.loading);
const getActivities = createSelector(getActivitiesState, (state) => state.activities);
const isError = createSelector(getActivitiesState, (state) => state.isError);

export const ActivitiesSelectors = {
    getIsLoading: isActivitiesLoading,
    activities: getActivities,
    getIsError: isError,
};
