import { GiftListAPICall } from 'reactApp/api/billing/gift/GiftListAPICall';
import { GiftFetch } from 'reactApp/modules/gift/gift.constants';

const callApiLoad = () => new GiftListAPICall().makeRequest();

const giftRequest = () => ({
    type: GiftFetch.REQUEST,
});

const giftLoading = () => ({
    type: GiftFetch.LOADING,
});

const giftLoadSuccess = list => ({
    type: GiftFetch.SUCCESS,
    list,
});

const giftLoadFailure = error => ({
    type: GiftFetch.FAILURE,
    error,
});

export const loadGifts = () => dispatch => {
    dispatch(giftRequest());

    return new Promise((resolve, reject) => {
        dispatch(giftLoading());

        callApiLoad()
            .then(({ data }) => {
                resolve(dispatch(giftLoadSuccess(data || [])));
            })
            .catch(error => {
                reject(dispatch(giftLoadFailure(error)));
            });
    });
};
