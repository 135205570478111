import api from 'Cloud/Application/api';
import config from 'Cloud/config';
import { contactsSlice } from 'reactApp/modules/contacts/contacts.module';
import { contactsSelectors } from 'reactApp/modules/contacts/contacts.selectors';
import { call, cancel, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

const IS_SOCIAL_USER = config.get('SOCIAL_USER');

const {
    actions: { listRequest, listSuccess, suggestRequest, suggestSuccess, suggestReset, addRequest },
} = contactsSlice;
const listApiCall = (): Promise<any> => Promise.resolve(api.mail.ab.contacts());
const suggestApiCall = ({ search }): Promise<any> => Promise.resolve(api.mail.ab.smart.suggest({ search }));
const addApiCall = ({ contacts }): Promise<any> => Promise.resolve(api.mail.ab.contacts.add({ contacts }));

function* listRequestSaga() {
    try {
        const data = yield call(listApiCall);
        yield put(listSuccess(data));
    } catch (_) {
        yield cancel();
    }
}

function* contactsRequestSaga() {
    const isLoaded = yield select(contactsSelectors.isLoaded);

    if (isLoaded || IS_SOCIAL_USER) {
        yield cancel();
    }

    yield listRequestSaga();
}

function* contactsSuggestSaga(action: ReturnType<typeof suggestRequest>) {
    const search = action.payload;

    if (!search) {
        yield put(suggestReset());
        yield cancel();
    }

    if (IS_SOCIAL_USER) {
        yield cancel();
    }

    try {
        const data = yield call(suggestApiCall, { search });
        yield put(suggestSuccess(data));
    } catch (_) {
        yield cancel();
    }
}

function* contactsAddSaga(action: ReturnType<typeof addRequest>) {
    if (!action.payload || IS_SOCIAL_USER) {
        yield cancel();
    }

    yield contactsRequestSaga();

    let contacts = action.payload;
    if (!Array.isArray(contacts)) {
        contacts = [contacts];
    }

    try {
        yield call(addApiCall, { contacts });
    } catch (_) {
        yield cancel();
    }

    yield listRequestSaga();
}

export function* watchContacts() {
    yield takeLatest(listRequest.toString(), contactsRequestSaga);
    yield takeLatest(suggestRequest.toString(), contactsSuggestSaga);
    yield takeEvery(addRequest.toString(), contactsAddSaga);
}
