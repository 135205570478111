import { ReactComponent as NoAdsIcon } from 'img/icons/no-ads.svg';
import React, { useEffect } from 'react';
import { Button } from 'reactApp/ui/Button/Button';

import styles from './DisableAdsPromo.css';

// tempexp_13536-next-line
export const DisableAdsPromo = ({ onClick, onShow }) => {
    useEffect(() => {
        onShow?.();
    }, []);

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <NoAdsIcon className={styles.icon} />
                    <div className={styles.text}>Отключите рекламу в Облаке</div>
                </div>
                <Button size="big" theme="white" onClick={onClick} className={styles.button}>Отключить</Button>
            </div>
        </div>
    );
}
