import { createAction, createReducer } from '@reduxjs/toolkit';
import { descend, prop, sort } from 'ramda';

import { PaymentItem, State } from './payments.types';

const initialState: State = {
    list: [],
};

export const paymentsRequest = createAction('@payments/request');
export const paymentsSuccess = createAction<{ data: any[] }>('@payments/success');
export const paymentsFailure = createAction<string>('@payments/failure');

export const paymentsReducer = createReducer(initialState, {
    [paymentsSuccess.type]: (state, action: ReturnType<typeof paymentsSuccess>): void => {
        state.list = sort(
            descend(prop('time')),
            action.payload.data.map(
                (item): PaymentItem => ({
                    amount: item.amount,
                    time: item.ctime * 1000,
                    currency: item.currency,
                    paymentId: item.payment_id,
                    product: item.product,
                    receiptType: item.receipt_type,
                    receiptUrl: item.receipt_url,
                    status: item.status,
                    subscription: item.sub_id,
                })
            )
        );
    },
});
