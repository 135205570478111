import React, { memo, useCallback } from 'react';
import { BaseConfirmDialog } from 'reactApp/components/BaseConfirmDialog/BaseConfirmDialog';
import { EButtonType } from 'reactApp/components/BaseConfirmDialog/BaseConformationDialog.types';

import styles from './SmallWindowWarningDialog.css';

export interface IProps {
    onClose: () => void;
}

const BUTTONS = [EButtonType.accept];

export const SmallWindowWarningDialog = memo<IProps>(({ onClose }) => {
    const renderHeader = useCallback(() => 'Слишком маленький экран', []);
    const renderContent = useCallback(
        () => (
            <>
                Приложение может работать некорректно на&nbsp;уменьшенном экране. Увеличьте окно с&nbsp;приложением или разверните его
                на&nbsp;весь экран
            </>
        ),
        []
    );

    return (
        <BaseConfirmDialog
            dataQAId="small-window-warning-dialog"
            renderHeader={renderHeader}
            onClose={onClose}
            renderContent={renderContent}
            buttons={BUTTONS}
            className={styles.root}
            successButtonText="Понятно"
            onSuccess={onClose}
            closeOnDimmerClick={false}
        />
    );
});

SmallWindowWarningDialog.displayName = 'SmallWindowWarningDialog';
