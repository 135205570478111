import classNames from 'clsx';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendGa } from 'reactApp/components/SharingWindow/Sharing.helpers';
import { EFrom } from 'reactApp/components/SharingWindow/Sharing.types';
import { SharingNewAddUser } from 'reactApp/components/SharingWindow/SharingNew/AddUser/SharingNewAddUser';
import { SharingNewSection } from 'reactApp/components/SharingWindow/SharingNew/Section/SharingNewSection';
import { User } from 'reactApp/components/SharingWindow/SharingNew/User/User';
import { contactsSlice } from 'reactApp/modules/contacts/contacts.module';
import {
    accessControlListLoadStart,
    accessControlListReset,
    deleteAllUsers,
} from 'reactApp/modules/folderAccessControlList/folderAccessControlList.actions';
import { getACL, getOwner, isAccessControlListLoading } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.selectors';
import { IACListItem } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.types';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getStorage } from 'reactApp/modules/storage/storage.helpers';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { loadUser } from 'reactApp/modules/user/user.thunkActions';
import { Loader } from 'reactApp/ui/Loader/Loader';

import styles from './SharingNewSharing.css';
import { renderDeleteUsersDialog } from './SharingNewSharing.helpers';
import { IProps } from './SharingNewSharing.types';

// eslint-disable-next-line max-lines-per-function
export const SharingNewSharing = React.memo(function SharingNewSharing(props: IProps): ReactElement | null {
    const dispatch = useDispatch();

    const { isLoaded, hasError: isUserError } = useSelector(UserSelectors.getLifeCycleState);
    const acList = useSelector(getACL);
    const owner = useSelector(getOwner);
    const currentUserEmail = useSelector(UserSelectors.getEmail);
    const isLoading = useSelector(isAccessControlListLoading);

    const storage = useSelector(getCurrentStorage);
    const { isSharedIncoming } = getStorage(storage);

    // Блок раскрыт если есть приглашенные пользователи и если пользователь пришел из блока "Настроить доступ"
    const isFromSharing = props.from === EFrom.SHARING;
    const isReadOnlyItem = props.item && 'isReadOnly' in props.item ? props.item.isReadOnly : false;

    const [isCloudUsersOpened, setCloudUsersOpened] = useState<boolean>(isFromSharing);

    const isUserLoaded = isLoaded && !isUserError;
    const { item, onClose, isMounted: mounted, isPhone } = props;

    // FIXME: CLOUDWEB-13574. Костыль на случай если у монтированной папки не флага "mounted"
    const isMounted = mounted || currentUserEmail !== owner?.email;

    const renderItem = useCallback(
        (user) => (
            <div className={styles.user} key={user.email}>
                <User
                    user={user}
                    item={item}
                    isMounted={isMounted}
                    currentUserEmail={currentUserEmail}
                    onClose={onClose}
                    publicId={item?.weblink}
                    isPhone={isPhone}
                />
            </div>
        ),
        [currentUserEmail, isMounted, item, onClose, isPhone]
    );

    const loadAllData = useCallback(() => {
        dispatch(contactsSlice.actions.listRequest);
        dispatch(accessControlListLoadStart({ id: item?.id || '', storage: item?.storage }));
    }, [dispatch, item?.id, storage]);

    useEffect(() => {
        if (!isUserLoaded) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dispatch(loadUser).then(() => loadAllData());
        } else {
            loadAllData();
        }

        return function cleanup() {
            dispatch(accessControlListReset());
        };
    }, [dispatch, isUserLoaded, loadAllData]);

    useEffect(() => {
        if (isFromSharing) {
            return;
        }

        const needOpen = Boolean(acList.length);

        // Если в списке есть юзеры, а он скрыт - надо открыть. Для первого открытия со списком.
        if (!isCloudUsersOpened && needOpen && !isLoading) {
            setCloudUsersOpened(true);
        }
    }, [acList.length, isCloudUsersOpened, isFromSharing, isLoading]);

    const onActionClick = useCallback(() => {
        if (!item) {
            return;
        }

        sendGa('delete-all');
        dispatch(deleteAllUsers({ item }));
        setCloudUsersOpened(false);
    }, [dispatch, item]);

    const onChangeCloudUsers = useCallback(() => {
        // Если нет приглашенных пользователей - просто закрываем, без предупреждения
        if (!acList.length) {
            setCloudUsersOpened(!isCloudUsersOpened);
            return;
        }

        if (isCloudUsersOpened) {
            renderDeleteUsersDialog({ onActionClick });
            return;
        }

        setCloudUsersOpened(true);
    }, [acList.length, isCloudUsersOpened]);

    const userList = useMemo(() => {
        const name = owner?.name || '';
        const email = name ? ` (${owner?.email})` : owner?.email;

        return (
            <div
                className={classNames(styles.root, {
                    [styles.root_phone]: isPhone,
                    [styles.mounted]: isSharedIncoming,
                })}
            >
                {isMounted && owner && (
                    <div className={styles.mountedRights}>
                        <span>
                            {name}
                            {email}
                        </span>{' '}
                        предоставил вам доступ на
                        {isReadOnlyItem ? ' чтение' : ' редактирование'}
                    </div>
                )}
                <div className={classNames(styles.usersList, { [styles.usersList_scroll]: !isMounted && acList.length > 2 })}>
                    {isLoading ? (
                        <div className={styles.loading}>
                            <Loader centered isModal />
                        </div>
                    ) : (
                        <>
                            {Boolean(owner) && (
                                <div className={styles.user}>
                                    <User user={owner as IACListItem} isPhone={isPhone} isOwner />
                                </div>
                            )}
                            {acList.map((user) => renderItem(user))}
                        </>
                    )}
                </div>
            </div>
        );
    }, [acList, isLoading, isMounted, isSharedIncoming, owner, renderItem, isReadOnlyItem, isPhone]);

    if (isMounted) {
        return userList;
    }

    return (
        <SharingNewSection
            onChange={onChangeCloudUsers}
            isOpened={isCloudUsersOpened}
            title="Пользователям Облака"
            name="cloud-users"
            className={styles.section}
            isPhone={isPhone}
        >
            <div className={classNames(styles.root, { [styles.root_phone]: isPhone })}>
                <SharingNewAddUser folderId={item?.id} isMounted={isMounted} publicId={item?.weblink} isPhone={isPhone} />
                {userList}
            </div>
        </SharingNewSection>
    );
});
