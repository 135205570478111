import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { promoMobileAppLM } from 'reactApp/appHelpers/featuresHelpers';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { sendDwh } from 'reactApp/utils/ga';

const TREE_POROMO_MOBILE_APP_CLOSED_KEY = 'TREE_POROMO_MOBILE_APP_CLOSED_KEY';

export const useMobileAppPromo = () => {
    const storage = useSelector(getCurrentStorage);
    const email = useSelector(UserSelectors.getEmail);

    const isClosed = sessionStorage.getItem(`${email}|${TREE_POROMO_MOBILE_APP_CLOSED_KEY}`);

    const showMobileAppTreePromo =
        storage !== EStorageType.integration &&
        storage !== EStorageType.inlineIntegration &&
        storage !== EStorageType.family &&
        promoMobileAppLM &&
        !isClosed;

    const handleTreePromoMobileAppClick = useCallback((type: 'mobile' | 'desktop') => {
        sendDwh({
            eventCategory: 'web_to_mobile',
            label: 'install_left_menu',
            dwhData: {
                install: type,
            },
        });
    }, []);

    const handleTreePromoMobileAppClose = useCallback(() => {
        sessionStorage.setItem(`${email}|${TREE_POROMO_MOBILE_APP_CLOSED_KEY}`, 'true');
    }, []);

    return {
        showMobileAppTreePromo,
        handleTreePromoMobileAppClick,
        handleTreePromoMobileAppClose,
    };
};
