import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CardActions } from 'reactApp/modules/creditCard/creditCard.module';
import { CreditCardDmrLinkActions } from 'reactApp/modules/creditCardDmrLink/creditCardDmrLink.module';
import { shouldShowCardModal } from 'reactApp/sections/SubscriptionsPage/ui/CreditCard/CreditCard.helpers';

export const useCardHandlers = () => {
    const dispatch = useDispatch();

    const isAddCardAutoShow = shouldShowCardModal();
    const [showAddCardModal, setShowAddCardModal] = useState(isAddCardAutoShow);

    useEffect(() => {
        if (isAddCardAutoShow) {
            RADAR.ga('send', 'event', {
                eventCategory: 'billing',
                eventAction: 'addCardAutoShow',
            });
        }
    }, [isAddCardAutoShow]);

    const onCloseAddCard = useCallback(() => {
        RADAR.ga('send', 'event', {
            eventCategory: 'billing',
            eventAction: 'addCardClose',
        });

        dispatch(CreditCardDmrLinkActions.resetLink());
        setShowAddCardModal(false);
    }, [dispatch]);

    const onSuccessAddCard = useCallback(() => {
        dispatch(CardActions.updateCard({ showNotifications: true }));
        setShowAddCardModal(false);
    }, [dispatch]);

    const onUnlinkCard = useCallback(() => {
        dispatch(CardActions.updateCard({ isSilent: true }));
    }, [dispatch]);

    return { showAddCardModal, onCloseAddCard, onSuccessAddCard, onUnlinkCard };
};
