import React, { CSSProperties, memo, PropsWithChildren } from 'react';

import styles from './TableColumn.css';

interface TableColumnProps extends React.HTMLAttributes<HTMLDivElement> {
    maxWidth?: number | string;
    minWidth?: number | string;
    width?: number | string;
    justifyContent?: CSSProperties['justifyContent'];
    gap?: number | string;
    overflow?: CSSProperties['overflow'];
    flexBasis?: number;
    flexShrink?: number;
    flexGrow?: number;
}

const convertToValue = (value?: string | number) => {
    if (!['number', 'string'].includes(typeof value)) {
        return undefined;
    }

    return typeof value === 'number' ? `${value}px` : value;
};

export const TableColumn = memo<PropsWithChildren<TableColumnProps>>(function TableColumn({
    children,
    maxWidth,
    minWidth,
    width,
    justifyContent,
    gap,
    overflow,
    flexBasis,
    flexShrink,
    flexGrow,
    ...props
}) {
    const cssVars = {
        '--tc-max-width': convertToValue(maxWidth),
        '--tc-min-width': convertToValue(minWidth),
        '--tc-justify-content': convertToValue(justifyContent),
        '--tc-gap': convertToValue(gap),
        '--tc-width': convertToValue(width),
        '--tc-overflow': convertToValue(overflow),
        '--tc-flex-basis': convertToValue(flexBasis),
        '--tc-flex-shrink': flexShrink,
        '--tc-flex-grow': flexGrow,
    };

    return (
        <div
            className={styles.root}
            {...props}
            style={{
                ...cssVars,
                ...(props.style || {}),
            }}
        >
            {children}
        </div>
    );
});
