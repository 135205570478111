import React, { ReactElement, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DomesticArguments, DomesticReturnData } from 'reactApp/components/SharingNewBiz/DomesticDropdown/DomesticDropdown.types';
import { sendGa } from 'reactApp/components/SharingNewBiz/SharingNew.helpers';
import { DomainAccess } from 'reactApp/components/SharingNewBiz/SharingNew.types';
import styles from 'reactApp/components/SharingNewBiz/Weblink/SharingNewWeblink.css';
import { renderUnpublishWeblinkDialog } from 'reactApp/components/SharingNewBiz/Weblink/SharingNewWeblink.helpers';
import { publishRequest, toggleWeblinkDomesticRequest } from 'reactApp/modules/modifying/modifying.actions';
import { DropdownMenu } from 'reactApp/ui/Dropdown/DropdownMenu';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

export const useDomestic = ({
    checked,
    item,
    isWeblinkOpened,
    onActionClick,
    domainAccessOptions,
}: DomesticArguments): DomesticReturnData => {
    const dispatch = useDispatch();

    const renderDomesticDropdown = useCallback(
        (onClick: void): ReactElement => {
            const list = Object.values(domainAccessOptions).map(({ id, text, disabled }) => ({
                id,
                text,
                check: id === checked,
                qaValue: id,
                disabled,
            }));
            return <DropdownMenu list={list} theme="octavius" onClick={onClick} className={styles.dropdownMenu} />;
        },
        [checked, domainAccessOptions]
    );

    const onToggleDomestic = useCallback(
        (id: DomainAccess): void => {
            if (id !== checked && item) {
                sendGa('domestic');
                sendPaymentGa({
                    eventCategory: ECategoryGa.public,
                    action: 'edit-access',
                    type_for: id,
                    public_id: item.weblink,
                });
                dispatch(toggleWeblinkDomesticRequest({ item }));
            }
        },
        [checked, item, dispatch]
    );

    const handleUpdateDomestic = useCallback(
        (id: DomainAccess): void => {
            if (id === DomainAccess.OFF && isWeblinkOpened && onActionClick) {
                renderUnpublishWeblinkDialog({ onActionClick });
                return;
            }

            if (isWeblinkOpened === false && item) {
                sendGa('publish');
                dispatch(publishRequest({ item, respectDomesticByDefault: false }));
                return;
            }

            onToggleDomestic(id);
        },
        [isWeblinkOpened, onActionClick, item, onToggleDomestic, dispatch]
    );

    return {
        renderDropdownMenu: renderDomesticDropdown,
        handleUpdateDomestic,
    };
};
