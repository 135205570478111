import { myOfficeSessionInterval } from 'reactApp/appHelpers/featuresHelpers';
import { MyOfficeBusinessMetric, sendMyOfficeMetric } from 'reactApp/ui/ReactViewer/helpers/sendMyOfficeMetric';
import { noop } from 'reactApp/utils/helpers';

type Callback = (metric: string, data: { t?: Array<string | undefined>; i?: Record<string, number> }) => void;

/**
 * Watcher сессий просмотрщика
 * используется для МойОфис
 */
export class SessionWatcher {
    private startTime = 0; // время начала сессии
    private currentKey = ''; // id документа
    private metric = ''; // радар
    private metricHit = ''; // радар на хит
    private callback: Callback = noop; // функция отправки радара
    private cache: Record<string, any> = {}; // кэш для документов с инфой для радаров
    private hitTimer: number | null = null; // id таймера
    private hitInterval: number | null = null; //

    init(
        metric: string = MyOfficeBusinessMetric.sessionTime,
        metricHit: string = MyOfficeBusinessMetric.sessionHit,
        hitInterval: number = myOfficeSessionInterval,
        callback: (metric: string, data: { t?: Array<string | undefined>; i?: Record<string, number> }) => void = sendMyOfficeMetric
    ) {
        this.metric = metric;
        this.metricHit = metricHit;
        this.hitInterval = hitInterval;
        this.callback = callback;

        window.addEventListener?.('visibilitychange', this.onVisibilityHidden.bind(this));
        window.addEventListener?.('beforeunload', this.endSession.bind(this, {}));
    }

    destroy() {
        window.removeEventListener?.('visibilitychange', this.onVisibilityHidden.bind(this));
        window.removeEventListener?.('beforeunload', this.endSession.bind(this, {}));
    }

    private onVisibilityHidden() {
        if (document.visibilityState === 'hidden') {
            this.endSession();
        } else {
            this.startSession({ skipSending: true });
        }
    }

    startHitMetric() {
        if (!this.hitInterval || !Number.isSafeInteger(this.hitInterval)) {
            return;
        }

        if (this.hitTimer) {
            this.endHitMetric();
        }

        this.hitTimer = window.setInterval(() => this.sendMetric(this.metricHit, this.hitInterval || 0), this.hitInterval * 1000);
    }

    endHitMetric() {
        if (!this.hitTimer) {
            return;
        }

        window.clearInterval(this.hitTimer);
        this.hitTimer = null;
    }

    startSession(params?: { key?: string; data?: { documentMode: string; ext: string }; skipSending?: boolean }) {
        if (this.currentKey === params?.key) {
            if (params?.data) {
                this.cache[this.currentKey] = params.data;
            }

            this.startTime = performance.now();
            this.startHitMetric();
            return;
        }

        this.endSession({ skipSending: params?.skipSending });
        this.startTime = performance.now();
        this.startHitMetric();

        if (params?.key) {
            this.currentKey = params.key;
        }

        if (params?.key && params?.data) {
            const { key, data } = params;
            this.cache[key] = data;
        }
    }

    endSession(params?: { skipSending?: boolean; clear?: boolean }) {
        const { skipSending = false, clear = false } = params || {};
        if (!!this.startTime && !skipSending) {
            const duration = Math.ceil((performance.now() - this.startTime) / 1000);
            this.sendMetric(this.metric, duration);
        }

        if (clear) {
            this.currentKey = '';
        }

        this.startTime = 0;
        this.endHitMetric();
    }

    sendMetric(metric: string, duration: number) {
        if (!this.currentKey || !this.cache[this.currentKey]) {
            return;
        }

        const item = this.cache[this.currentKey] ?? {};
        this.callback(metric, { t: [item.documentMode, item.ext], i: { time: duration } });
    }
}

export const sessionWatcher = new SessionWatcher();
