import { ReactComponent as ConnectErrorIcon } from 'img/icons/uploader/connectError.svg';
import React from 'react';
import { IS_BIZ_USER } from 'reactApp/appHelpers/configHelpers';
import { UPLOAD_PROMO_LINK } from 'reactApp/modules/features/features.helpers';
import { EFileError, EFileStatus, EProgressStatus, IInputFile } from 'reactApp/modules/uploadList/uploadList.model';
import { truncateTextMiddle } from 'reactApp/utils/textHelpers';

const selectStatusFile = (file: IInputFile) => file?.status;

export const isFileWarning = (file?: IInputFile) => file && selectStatusFile(file) === EFileStatus.WARNING;

export const isFileProgress = (file: IInputFile) => selectStatusFile(file) === EFileStatus.PROGRESS;

export const isFileError = (file: IInputFile) => selectStatusFile(file) === EFileStatus.ERROR;

export const isFileCancel = (file: IInputFile) => selectStatusFile(file) === EFileStatus.CANCEL;

export const isFileSkip = (file: IInputFile) => selectStatusFile(file) === EFileStatus.SKIP;

export const isFileInfo = (file: IInputFile) => selectStatusFile(file) === EFileStatus.INFO;

export const isFileDone = (file: IInputFile) => selectStatusFile(file) === EFileStatus.DONE || isFileInfo(file);

export const isFileDeleting = (file: IInputFile) => selectStatusFile(file) === EFileStatus.DELETING;

export const isFileComplete = (file: IInputFile) => isFileDone(file) || isFileError(file) || isFileSkip(file) || isFileCancel(file);

export const isFileCompleteWithError = (file: IInputFile) => isFileError(file) || isFileSkip(file) || isFileCancel(file);

export const isCancellable = (file: IInputFile) => !isFileDone(file) && !isFileError(file) && !isFileSkip(file) && !isFileCancel(file);

export const hasProblemFile = (file: IInputFile) => isFileWarning(file) || isFileCancel(file) || isFileSkip(file) || isFileError(file);

export const isFileExist = (file: IInputFile) => isFileErrorInfo(file) === EFileError.FILE_EXIST;

export const isFileIgnored = (file: IInputFile) => isFileErrorInfo(file) === EFileError.IGNORED_FILE;

export const isProgressComplete = (status: EProgressStatus) => status === EProgressStatus.COMPLETE;

export const isProgressing = (status: EProgressStatus) => status === EProgressStatus.PROGRESS;

export const isFolder = (file: IInputFile) => file.extension === 'folder';

export const getFolderCloudPath = (file: IInputFile) => file.folderCloudPath || '';

export const isFileErrorInfo = (file: IInputFile | undefined) => file?.error;

export const getDescriptorId = (file: IInputFile | null) => file?.descriptorId;

export const getCurrentUpload = (file: IInputFile) => file.currentUpload;

export const isFileAutoFixError = (file: IInputFile | undefined) =>
    isFileErrorInfo(file) === EFileError.INVALID_CHARACTERS_AUTO_FIX || isFileErrorInfo(file) === EFileError.NAME_TOO_LONG_AUTO_FIX;

export const hasAutoFix = (file: IInputFile) => file?.hasAutoFix?.hasInvalidCharAutoFix || file?.hasAutoFix?.hasNameTooLongAutoFix;

export const isShowInfoIcon = (item: IInputFile) =>
    isFileInfo(item) ||
    item.error === EFileError.IGNORED_FILE ||
    item.error === EFileError.SKIPPED_FILE ||
    item.error === EFileError.CANCELLED_FILE ||
    item.error === EFileError.OVER_QUOTA_CLOUD_AT_OWNER ||
    item.error === EFileError.OVER_QUOTA_CLOUD_B2B ||
    item.error === EFileError.USER_FILE_SIZE_LIMIT ||
    item.error === EFileError.USER_FILE_SIZE_LIMIT_OVER_100_GB ||
    item.error === EFileError.FILE_TOO_BIG ||
    isFileExist(item);

export const showInfoIcon = (item: IInputFile) => (isFolder(item) && item.error === EFileError.INFO_IN_FOLDER) || isShowInfoIcon(item);

export const isShowErrorIcon = (item: IInputFile) => {
    return (
        isFileWarning(item) ||
        item.error === EFileError.CONNECTION_ERROR ||
        item.error === EFileError.LOCAL_FILE_NOT_FOUND ||
        item.error === EFileError.LOCAL_FILE_READ_ERROR ||
        item.error === EFileError.VIOLATED_FILENAME
    );
};

export const showErrorIcon = (item: IInputFile) => {
    return (isFolder(item) && item.error === EFileError.ERROR_IN_FOLDER) || isShowErrorIcon(item);
};

export const getFileName = (file: IInputFile) => {
    const nameLength = 30;
    const partNameLength = nameLength / 2;
    const fileName = file?.name;

    if (!fileName) {
        return '';
    }

    const truncateName = truncateTextMiddle(fileName, partNameLength);
    let uploadedItemName = `${truncateName}.${file.extension}`;

    if (!file.extension || isFolder(file)) {
        uploadedItemName = truncateName;
    }

    return uploadedItemName;
};

export const getFileSize = (file: IInputFile) => (file.size && file.size > 0 ? file.size : 0);

export const getFileLoaded = (file: IInputFile) => (file.loaded && file.loaded > 0 ? file.loaded : 0);

export const getFilterFilesInProgress = (files: IInputFile[], ids: string[]): string[] =>
    ids?.filter((id) => !files?.find((file) => file.cloudPath === id && isFileProgress(file)));

export const getErrorInfo = (
    error: EFileError,
    { userFileSizeLimit, fileName, folderName }: { userFileSizeLimit?: string; fileName?: string; folderName?: string }
) => {
    const linkText = 'Подробнее о загрузке файлов';

    // eslint-disable-next-line sonarjs/max-switch-cases
    switch (error) {
        case EFileError.LOCAL_FILE_NOT_FOUND:
            return {
                text: 'Файл больше недоступен по указанному пути в локальной файловой системе. Возможно вы извлекли съемный диск, переименовали или удалили файл',
                pureText:
                    'Файл больше недоступен по указанному пути в локальной файловой системе. Возможно вы извлекли съемный диск, переименовали или удалили файл',
            };
        case EFileError.NAME_TOO_LONG:
            return {
                text: 'Превышено количество символов в названии файла',
                pureText: 'Превышено количество символов в названии файла',
                link: {
                    text: linkText,
                    url: 'https://help.mail.ru/cloud_web/confines',
                },
            };
        case EFileError.IGNORED_FILE:
            return {
                text: (
                    <>
                        Служебный файл <span>{fileName}</span> операционной системы игнорируется при загрузке в Облако
                    </>
                ),
                pureText: `Служебный файл ${fileName} операционной системы игнорируется при загрузке в Облако`,
                link: {
                    text: linkText,
                    url: 'https://help.mail.ru/cloud_web/files/upload',
                },
            };
        case EFileError.SKIPPED_FILE:
            return {
                text: 'Файл был пропущен',
                pureText: 'Файл был пропущен',
            };
        case EFileError.FILE_EXIST:
            return {
                text: (
                    <>
                        Файл <span>{fileName}</span> уже существует в вашем Облаке
                    </>
                ),
                pureText: `Файл ${fileName} уже существует в вашем Облаке`,
            };
        case EFileError.OVER_QUOTA_LIMIT_DOWNLOAD:
        case EFileError.CANCELLED_FILE:
            return {
                text: 'Загрузка файла была отменена',
                pureText: 'Загрузка файла была отменена',
            };
        case EFileError.CONNECTION_ERROR:
            return {
                text: 'Нет интернета',
                pureText: 'Нет интернета',
                description: 'Мы сохранили текущий результат. Загрузка продолжится, когда интернет снова появится',
                icon: <ConnectErrorIcon />,
                mode: 'connectError',
                link: {
                    text: 'Подробнее об умной загрузке',
                    url: UPLOAD_PROMO_LINK,
                },
            };
        case EFileError.READ_ONLY_DIRECTORY:
            return {
                text: 'Папка доступна только для чтения',
                pureText: 'Папка доступна только для чтения',
                link: {
                    text: linkText,
                    url: 'https://help.mail.ru/cloud_web/folders/public',
                },
            };
        case EFileError.INVALID_CHARACTERS:
            return {
                text: 'Недопустимое имя файла или папки',
                pureText: 'Недопустимое имя файла или папки',
                link: {
                    text: linkText,
                    url: 'https://help.mail.ru/cloud_web/confines',
                },
            };
        case EFileError.USER_FILE_SIZE_LIMIT:
            return {
                text: `Текущий тарифный план не позволяет загружать файлы больше ${userFileSizeLimit}`,
                pureText: `Текущий тарифный план не позволяет загружать файлы больше ${userFileSizeLimit}`,
                link: {
                    text: 'Увеличить лимит',
                    url: 'https://help.mail.ru/cloud_web/size/increase',
                },
            };
        case EFileError.OVER_QUOTA_CLOUD_AT_OWNER:
            return {
                text: `Загрузка невозможна. У владельца этой папки недостаточно места в Облаке для ваших файлов`,
                pureText: `Загрузка невозможна. У владельца этой папки недостаточно места в Облаке для ваших файлов`,
            };
        case EFileError.USER_FILE_SIZE_LIMIT_OVER_100_GB:
            return {
                text: 'В Облако нельзя загружать файлы более 100ГБ',
                pureText: 'В Облако нельзя загружать файлы более 100ГБ',
            };
        case EFileError.INVALID_CHARACTERS_AUTO_FIX:
            return {
                text: (
                    <>
                        Название файла <span>{fileName}</span> было автоматически исправлено. В Облаке действуют ограничения на спецсимволы
                    </>
                ),
                pureText: `Название файла ${fileName} было автоматически исправлено. В Облаке действуют ограничения на спецсимволы`,
                link: {
                    text: linkText,
                    url: 'https://help.mail.ru/cloud_web/about/confines#name',
                },
            };
        case EFileError.NAME_TOO_LONG_AUTO_FIX:
            return {
                text: (
                    <>
                        Название файла <span>{fileName}</span> было автоматически сокращено. В Облаке действует ограничение на длину
                    </>
                ),
                pureText: `Название файла ${fileName} было автоматически сокращено. В Облаке действует ограничение на длину`,
                ...(!IS_BIZ_USER && {
                    link: {
                        text: linkText,
                        url: 'https://help.mail.ru/cloud_web/about/confines',
                    },
                }),
            };
        case EFileError.OVER_QUOTA_CLOUD_B2B:
            return {
                text: (
                    <>
                        <div>Недостаточно свободного места в вашем Облаке.</div>
                        <div>Свяжитесь с&nbsp;администратором вашего Облака для изменения лимитов.</div>
                    </>
                ),
                pureText: `Недостаточно свободного места в вашем Облаке.\n Свяжитесь с администратором вашего Облака для изменения лимитов.`,
            };
        case EFileError.LOCAL_FILE_READ_ERROR:
            return {
                text: 'Ошибка при чтении файла',
                pureText: 'Ошибка при чтении файла',
            };
        case EFileError.VIOLATED_FILENAME:
            return {
                text: (
                    <>
                        <strong>Файл запрещенного формата</strong>
                        <br />
                        Файл <span>{folderName ? `${fileName} из ${folderName.split('/')[1]}` : fileName}</span> не может быть загружен.
                        <br />
                        Обратитесь к администратору
                    </>
                ),
                pureText: `Файл ${fileName} не может быть загружен.\n Обратитесь к администратору`,
            };
        case EFileError.FILE_TOO_BIG:
            return {
                text: 'Загрузка невозможна. У владельца этой папки недостаточно места в Облаке для ваших файлов',
                pureText: 'Загрузка невозможна. У владельца этой папки недостаточно места в Облаке для ваших файлов',
            };
        case EFileError.UNKNOWN:
        default:
            return {
                text: 'Неизвестная ошибка',
                pureText: 'Неизвестная ошибка',
            };
    }
};
