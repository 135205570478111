import api from 'Cloud/Application/api';
import config from 'Cloud/config';
import { parseURL, pathJoin } from 'reactApp/utils/helpers';

export class Dispatcher {
    last = {};
    currentCount = {};
    isLoading = false;
    dispatchers = this.normalizeDispatchers(config.get('DISPATCHERS'));

    apiV2ToV4Dispatchers(data) {
        return Object.keys(data).reduce((res, dispatcher) => {
            res[dispatcher] = data[dispatcher][0];
            return res;
        }, {});
    }

    load() {
        if (this.isLoading) {
            this.isLoading = true;
            return new Promise((resolve, reject) => {
                return Promise.resolve(api.dispatcher())
                    .then((data) => {
                        const apiv4Dispatchers = this.apiV2ToV4Dispatchers(data);
                        this.dispatchers = this.normalizeDispatchers(apiv4Dispatchers);
                        resolve();
                    })
                    .catch(reject)
                    .always(() => {
                        this.isLoading = false;
                    });
            });
        }
    }

    get(name) {
        const dispatcher = this.dispatchers[name];
        this.last[name] = { ...dispatcher };

        if (!this.currentCount[name]) {
            this.currentCount[name] = 1;
        }

        if (this.currentCount[name] > +this.last[name].count) {
            this.load();
        }

        this.currentCount[name] += 1;
        return this.last[name].url;
    }

    _createDispatcher(source, pathname, addToPathname = false) {
        if (!source) {
            return {};
        }

        const urlObj = parseURL(source.url);

        return {
                  count: Math.round(Math.max((source.count || 0) / 2, 2)),
                  url: pathJoin(urlObj.origin, addToPathname && urlObj.pathname ? pathJoin(urlObj.pathname, pathname) : pathname),
                  isProcessed: true,
              };
    }

    normalizeDispatchers(dispatchers) {
        if (dispatchers) {
            if (dispatchers.stock_get) {
                dispatchers.stock_get = this._createDispatcher(dispatchers.stock_get, 'stock/get');
            } else {
                dispatchers.stock_get = this._createDispatcher(dispatchers.stock, 'stock/get');
            }

            if (dispatchers.stock_view) {
                dispatchers.stock_view = this._createDispatcher(dispatchers.stock_view, 'stock/view');
            } else {
                dispatchers.stock_view = this._createDispatcher(dispatchers.stock, 'stock/view');
            }

            if (dispatchers.stock_video) {
                dispatchers.stock_video = this._createDispatcher(dispatchers.stock_video, 'stock/video/0p');
            } else {
                dispatchers.stock_video = this._createDispatcher(dispatchers.stock, 'stock/video/0p');
            }

            if (dispatchers.stock_thumb) {
                dispatchers.stock_thumbs = this._createDispatcher(dispatchers.stock_thumb, 'stock/thumb');
            } else {
                dispatchers.stock_thumbs = this._createDispatcher(dispatchers.stock, 'stock/thumb');
            }

            // private video
            if (dispatchers.video) {
                dispatchers.video = this._createDispatcher(dispatchers.video, 'video/0p');
            } else if (dispatchers.view) {
                dispatchers.video = this._createDispatcher(dispatchers.view, 'video/0p');
            }

            // public video
            if (dispatchers.weblink_video) {
                dispatchers.weblink_video = this._createDispatcher(dispatchers.weblink_video, 'videowl/0p');
            } else if (dispatchers.weblink_view) {
                dispatchers.weblink_video = this._createDispatcher(dispatchers.weblink_view, 'videowl/0p');
            }

            if (dispatchers.videowl_view && !dispatchers.videowl_view.isProcessed) {
                dispatchers.videowl_view = this._createDispatcher(dispatchers.videowl_view, '0p', true);
            }
        }

        return dispatchers;
    }
}
