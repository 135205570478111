import type { ReactNode } from 'react';

export enum GestureTypes {
    zoom = 'zoom',
}

interface GesturesComponentProps {
    children: ReactNode;
}

export interface GesturesZoomProps extends GesturesComponentProps {
    zoom?: number;
    zoomMax?: number;
    zoomMin?: number;
    onChangeZoom?: (zoomValue: number) => void;
}

export interface GesturesProps extends GesturesZoomProps {
    type: GestureTypes;
}
