import { openPopupHelper } from 'reactApp/modules/popup/popup.helpers';
import { popupNames } from 'reactApp/modules/popup/popup.types';

interface Props {
    onActionClick(): void;
}

export const renderUnpublishWeblinkDialog = (props: Props) => {
    const { onActionClick } = props;

    openPopupHelper({
        popupName: popupNames.NO_WARNING,
        data: {
            onActionClick,
            id: 'weblink-warning-dialog',
            title: 'Закрыть доступ?',
            description: 'Доступ будет закрыт для всех пользователей.',
            buttonAcceptText: 'Закрыть доступ',
            buttonRejectText: 'Отмена',
            hideCheckbox: true,
        },
    });
};
