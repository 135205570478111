import localState from 'lib/store';

const { UserSelectors } = require('./user.selectors');

export const noAuthUser = 'noAuthUser'; // на паблике без авторизации

const get = (id) => (dispatch, getState) => {
    const email = UserSelectors.getEmail(getState()) || noAuthUser;

    return localState.get(`${email}|${id}`);
};

const set = (id, value) => (dispatch, getState) => {
    const email = UserSelectors.getEmail(getState()) || noAuthUser;

    return localState.set(`${email}|${id}`, value);
};

const remove = (id) => (dispatch, getState) => {
    const email = UserSelectors.getEmail(getState()) || noAuthUser;

    return localState.remove(`${email}|${id}`);
};

export const UserStorageSelectors = {
    get: (state, id) => {
        const email = UserSelectors.getEmail(state) || noAuthUser;

        return localState.get(`${email}|${id}`);
    },
};

export const UserStorageActions = {
    get,
    set,
    remove,
};
