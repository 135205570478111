/* tempexp_15344-full-file */
export const SUCCESS_AUTH_WHEN_DOWNLOAD = 'success_auth_when_download';

export enum RequiredSignUpTouchSource {
    VIEWER_MORE_BTN = 'VIEWER_MORE_BTN',
    PUBLIC_TOOLBAR = 'PUBLIC_TOOLBAR',
    ITEM_MORE_BTN = 'ITEM_MORE_BTN',
}

export const REQUIRED_SIGNUP_POPUP_SHOWED_C = 'REQUIRED_SIGNUP_POPUP_SHOWED_C';
