// eslint-disable-next-line max-lines-per-function
define(function (require) {
    const $ = require('jquery');
    const MicroEvent = require('mrg-microevent');
    const { sendXray } = require('reactApp/utils/ga');
    const { coreKeeperAPI } = require('@mail-core/dashboard');
    const config = require('Cloud/config');
    const { HttpErrorCodes, isReadOnlyHttpStatus } = require('reactApp/api/HttpErrorCodes');
    const { O2AuthClient, o2AuthClient } = require('reactApp/api/O2AuthClient');

    // обертка над ответом сервера
    var ResponseFacade = (function (statuses) {
        var response = function (result, xhr, url) {
            this.getBody = this.getData = function () {
                return result.body;
            };

            this.getStatus = function () {
                const status = parseInt(result.status);
                return isReadOnlyHttpStatus(status) ? HttpErrorCodes.INSUFFICIENT_STORAGE : status;
            };

            this.getXHR = function () {
                return xhr;
            };

            this.getResult = function () {
                return result;
            };

            this.getUrl = function () {
                return url;
            };
        };

        response.statuses = statuses;

        $.each(statuses, function (status, code) {
            response.prototype[$.camelCase('is-' + status)] = function () {
                return this.getStatus() === code;
            };
        });

        // names sugar
        response.prototype.isOK = response.prototype.isOk;
        response.prototype.isAccessDenied = response.prototype.isDenied;

        return response;
    })({
        ok: 200,

        redirect: 302,
        notmodified: 304,

        invalid: 400,
        paymentRequired: 402,
        denied: 403,
        notfound: 404,
        unacceptable: 406,
        timeout: 408,
        conflict: 409,
        unprocessable: 422,
        failedDependency: 424,
        manyRequests: 429,
        retryWith: 449,
        blockedContent: 451,

        fail: 500,
        notImplemented: 501,
        unavailable: 503,
        insufficient: HttpErrorCodes.INSUFFICIENT_STORAGE,
    });

    // транспорт, для общения с сервером
    var TransportFactory = function (_options) {
        function send(params) {
            var url =
                (_options.domain ? '//' + _options.domain : '') +
                _options.baseUrl +
                'v' +
                _options.version +
                '/' +
                (params.method || params.url);

            // CLOUDWEB-5028
            // Пока в API не добавят conflict=ignore, приходится костылить
            var requestConflict = (params.data || {}).conflict || void 0;
            if (requestConflict === 'ignore') {
                params.data.conflict = 'strict';
            }

            var IS_APPLICATION_JSON = /application\/json/i.test(params.contentType);

            var inputData = {
                url: url,
                type: params.type,
                traditional: true,
                contentType: params.contentType || $.ajaxSettings.contentType,
                cache: false,
            };

            inputData.data = (function () {
                var data = Object.assign({}, params.data || params.formData, {
                    api: _options.version,
                    build: _options.build,
                    'x-page-id': _options['x-page-id'],
                });

                data = Object.assign(data, {
                    email: _options.email || 'anonym',
                    'x-email': _options.email || 'anonym',
                });

                if (!IS_APPLICATION_JSON) {
                    $.each(data, function (field, value) {
                        if (typeof value === 'object') {
                            data[field] = JSON.stringify(value);
                        }
                    });
                }

                return data;
            })();

            inputData.headers = {
                'X-CSRF-Token': _options.token,
            };

            if (IS_APPLICATION_JSON) {
                try {
                    inputData.data = JSON.stringify(inputData.data);
                } catch (error) {}
            }

            const startTime = Date.now();

            o2AuthClient.getToken().then(function (token) {
                Object.assign(
                    inputData.headers,
                    O2AuthClient.prepareAuthHeader(token)
                );

                $.ajax(inputData)
                    .done(function (data, textStatus, jqXHR) {
                        const response = new ResponseFacade(data, jqXHR, inputData.url);

                        if (checkAccess(params, response, url)) {
                            if (response.isOK()) {
                                params.deferred.resolve(response, jqXHR);
                            } else {
                                params.deferred.reject(response, jqXHR);
                            }
                        }
                    })
                    .fail(function (jqXHR, textStatus) {
                        let data;

                        try {
                            data = JSON.parse(jqXHR.responseText);
                        } catch (_) {}

                        if (!data || !data.status) {
                            data = {
                                status: jqXHR.status || ResponseFacade.statuses.timeout,
                                body: jqXHR.responseText || textStatus,
                            };
                        }

                        let needToIgnoreThisError = requestConflict === 'ignore'; // CLOUDWEB-5028
                        if (needToIgnoreThisError) {
                            requestConflict = undefined;
                            needToIgnoreThisError = false;

                            let _val = data.body;
                            if (_val && (_val = _val.home) && _val.error === 'exists') {
                                data.body = {
                                    id: _val.value,
                                    toString() {
                                        return this.id;
                                    },
                                    status: 200,
                                    apiStatus: 'faked',
                                };
                                needToIgnoreThisError = true;
                            }
                        }

                        const response = new ResponseFacade(data, jqXHR, inputData.url);

                        if (checkAccess(params, response, url)) {
                            if (needToIgnoreThisError) {
                                params.deferred.resolve(response, jqXHR);
                                return;
                            }

                            params.deferred.reject(response, jqXHR);
                        }
                    })
                    .always(function (data, textStatus, jqXHR) {
                        coreKeeperAPI(jqXHR, {
                            url: inputData.url,
                            duration: Date.now() - startTime,
                            apiStatus: data.status,
                        });
                    });
            })

        }

        function checkAccess(params, response, url) {
            var body = response.getBody();
            var xhr = response.getXHR();

            if (response.isAccessDenied()) {
                o2AuthClient.refreshToken();
                if (body == 'nosdc') {
                    sdc(params, url, xhr);
                    return false;
                } else if (body == 'token') {
                    csrfToken(params, xhr);
                    return false;
                } else if (body == 'user') {
                    accessDeniedRetry(params, xhr);
                    return false;
                }
            }

            return true;
        }

        function accessDeniedRetry(params, jqXHR) {
            params.accessDeniedRetry--;

            if (params.accessDeniedRetry > 0) {
                send(
                    { ...params,
                        deferred: new $.Deferred()
                            .done(function (result, xhr) {
                                params.deferred.resolve(result, xhr);
                            })
                            .fail(function (result, xhr) {
                                params.deferred.reject(result, xhr);
                            }),
                    }
                );
            } else {
                const response = new ResponseFacade(
                    {
                        status: ResponseFacade.statuses.denied,
                    },
                    jqXHR,
                    params.url
                );

                params.deferred.reject(response);
            }
        }

        function csrfToken(params, jqXHR) {
            params.tokenRetry--;

            if (params.tokenRetry > 0) {
                // (пере)запрашиваем access-токен
                send({
                    type: 'post',
                    method: 'tokens/csrf',
                    deferred: new $.Deferred()
                        .done(function (result, xhr) {
                            _options.token = result.getBody().token;

                            if (params.name == 'tokens' || params.name == 'tokens/csrf') {
                                // TODO: возможно params.name == 'tokens' тут лишняя(старая) проверка
                                params.deferred.resolve(result, xhr);
                            } else {
                                send(params);
                            }
                        })

                        .fail(params.deferred.reject),
                });
            } else {
                var response = new ResponseFacade(
                    {
                        status: ResponseFacade.statuses.denied,
                    },
                    jqXHR
                );

                params.deferred.reject(response);
            }
        }

        function sdc(params, url, jqXHR) {
            params.sdcRetry--;

            const sdcUrl = config.get('AUTH_DOMAIN') + 'sdc';

            const response = new ResponseFacade(
                {
                    status: ResponseFacade.statuses.denied,
                },
                jqXHR,
                sdcUrl
            );

            var methodKey = 'api_' + params.method.replace(/\//g, '-') + '_v2';
            let sendRadar = (name) => sendXray([methodKey, name]);

            sendRadar('nosdc_error');
            sendRadar('403_error');

            sendRadar('api-status_403-nosdc');

            sendRadar = (name) => sendXray(['app_sdc', name]);

            if (params.sdcRetry > 0) {
                // (пере)запрашиваем sdc куку
                $.ajax({
                    sdcUrl,
                    data: {
                        from: window.location.protocol + '//' + window.location.host + url,
                    },
                    jsonp: 'JSONP_call',
                    dataType: 'jsonp',
                })
                    .done(function (result) {
                        if (result === true) {
                            sendRadar('success');
                            send(params);
                        } else {
                            sendRadar('error');
                            params.deferred.reject(response);
                        }
                    })

                    .fail(function () {
                        sendRadar('error');
                        params.deferred.reject(response);
                    });
            } else {
                sendRadar('error');
                params.deferred.reject(response);
            }
        }

        return function (type, method, data, contentType) {
            var params = {
                type: type,
                method: method,
                data: data,
                tokenRetry: _options.tokenRetry,
                sdcRetry: _options.sdcRetry,
                accessDeniedRetry: _options.accessDeniedRetry,
                deferred: new $.Deferred(),
                contentType: contentType,
            };

            if (contentType) {
                params.contentType = contentType;
            }

            if (_options.token || params.method == 'tokens/scrf' || !_options.email) {
                send(params);
            } else {
                csrfToken(params);
            }

            return params.deferred.promise();
        };
    };

    var requestCounter = {
        post: 0,
        get: 0,
    };

    var API = function (options) {
        options = Object.assign({}, API.defaultOptions, options);

        MicroEvent.mixin(this);

        var transport = new TransportFactory(options);
        var _this = this;

        [
            { name: 'batch', type: 'post' },
            { name: 'clone', type: 'post' },
            { name: 'stock/save', type: 'post', contentType: 'application/json; charset=utf-8' },
            { name: 'dispatcher', type: 'get' },
            { name: 'docs/token', type: 'post' },
            { name: 'feed', type: 'post', contentType: 'application/json' },
            { name: 'file', type: 'get' },
            { name: 'file/add', type: 'post' },
            { name: 'file/history', type: 'get' },
            { name: 'folder', type: 'get' },
            { name: 'folder/add', type: 'post' },
            { name: 'folder/find', type: 'get' },
            { name: 'folder/invites', type: 'get' },
            { name: 'folder/shared/links', type: 'get' },
            { name: 'folder/shared/incoming', type: 'get' },
            { name: 'folder/tree', type: 'get' },
            { name: 'folder/unmount', type: 'post' },
            { name: 'folder/viruscan', type: 'post', contentType: 'application/json' },
            { name: 'mail/ab/contacts', type: 'get' },
            { name: 'mail/ab/contacts/add', type: 'post' },
            { name: 'mail/ab/smart', type: 'get' },
            { name: 'mail/ab/smart/suggest', type: 'get' },
            { name: 'mail/user/folders', type: 'post' },
            { name: 'status', type: 'get' },
            { name: 'tokens/csrf', type: 'post' },
            { name: 'tokens/download', type: 'post' },
            { name: 'weblinks', type: 'get' },
            { name: 'weblinks/subscribe', type: 'get' },
            { name: 'weblinks/message', type: 'post' },
            { name: 'weblinks/writable', type: 'post' },
            { name: 'weblinks/readonly', type: 'post' },
            { name: 'weblinks/expires', type: 'post' },
            { name: 'weblinks/file/add', type: 'post' },
            { name: 'weblinks/editable/on', type: 'post' },
            { name: 'weblinks/editable/off', type: 'post' },
            { name: 'weblinks/domestic/on', type: 'post' },
            { name: 'weblinks/domestic/off', type: 'post' },
            { name: 'weblinks/autodelete/on', type: 'post' },
            { name: 'weblinks/autodelete/off', type: 'post' },
            { name: 'weblinks/downloadable', type: 'post' },
            { name: 'weblinks/count_downloads', type: 'post' },
            { name: 'user', type: 'get' },
            { name: 'user/agree-la', type: 'post' },
            { name: 'user/edit', type: 'post' },
            { name: 'user/unfreeze', type: 'post' },
            { name: 'user/promo/active', type: 'post' },
            { name: 'user/promo/ignore', type: 'post' },
            { name: 'user/promo/invite', type: 'post' },
            { name: 'user/promo/join', type: 'post' },
            { name: 'user/space', type: 'get' },
            { name: 'zip', type: 'post' },
            { name: 'mail/ab/contacts', type: 'get' },
            { name: 'mail/ab/contacts/add', type: 'post' },
            { name: 'billing/rates', type: 'get' },
            { name: 'billing/change', type: 'post' },
            { name: 'billing/prolong', type: 'post' },
            { name: 'billing/cancel', type: 'post' },
            { name: 'billing/history', type: 'post' },
            { name: 'trashbin', type: 'get' },
            { name: 'trashbin/restore', type: 'post' },
            { name: 'trashbin/empty', type: 'post' },
            { name: 'domain/folders', type: 'get' },
            { name: 'promo/validate', type: 'post' },
        ].forEach(function (method) {
            var fn = _this;
            var name = method.name;
            var type = method.type;
            var contentType = method.contentType;

            var parts = name.split(/\//g);
            var partsLen = parts.length;

            for (var i = 0; i < partsLen; i++) {
                var part = parts[i];

                if (i + 1 < partsLen) {
                    if (fn[part] == void 0) {
                        fn[part] = {};
                    }

                    fn = fn[part];
                }
            }

            fn[parts[partsLen - 1]] = function (data) {
                var transportDeferred = transport(type, name, data, contentType);

                requestCounter[type.toLowerCase()]++;

                return options.promiseBuilder.call(_this, transportDeferred.promise(), name, data).always(function () {
                    requestCounter[type.toLowerCase()]--;
                });
            };
        });

        this.batch = function (params) {
            var batchParams = params.batch;
            var addParams = params;
            var results = new Array(batchParams.length);
            var d = new $.Deferred();
            var requestsStarted = 0;
            var requestsDone = 0;
            var maxParallelRequests = 7;

            delete params.batch;

            var makeRequest = function (reqParams, index) {
                var d = new $.Deferred();
                var method = this;
                var parts = reqParams.method.split('/');
                var partsLen = parts.length;
                var params = { ...reqParams.params, ...addParams };

                for (var i = 0; i < partsLen; i++) {
                    var part = parts[i];

                    if (i < partsLen) {
                        method = method[part];
                    }
                }

                method(params)
                    .done(function (data, status, response) {
                        d.resolve(data, status, response);
                    })
                    .fail(function (errors, status, response) {
                        d.resolve(errors, status, response);
                    });

                return d;
            }.bind(this);

            var selectRequest = function () {
                // запоминаем индекс
                var index = requestsStarted;

                // делаем щапрос
                makeRequest(batchParams[index], index).always(function (data, status, response) {
                    // запоминаем результат по индексу запроса
                    results[index] = {
                        data: data,
                        status: status,
                        response: response,
                    };

                    // если еще есть не начатые запросы, стартуем
                    if (requestsStarted < results.length) {
                        selectRequest();
                    }

                    requestsDone++;

                    // если это последний запрос, возвращаем результат
                    if (requestsDone == results.length) {
                        d.resolve(results);
                    }
                });

                requestsStarted++;
            }.bind(this);

            // стартуем 7 штук запросов
            for (var i = 0; i < maxParallelRequests && i < results.length; i++) {
                selectRequest();
            }

            return d;
        };

        this.getOptions = function () {
            return API.defaultOptions;
        }.bind(this);
    };

    API.prototype = {
        hasRequests: function () {
            return this.hasGetRequests() || this.hasPostRequests();
        },

        hasPostRequests: function () {
            return requestCounter.post !== 0;
        },

        hasGetRequests: function () {
            return requestCounter.get !== 0;
        },
    };

    API.ResponseFacade = ResponseFacade;

    API.defaultOptions = {
        tokenRetry: 2,
        sdcRetry: 2,
        version: 2,
        accessDeniedRetry: 2,
        baseUrl: '/api/',
        token: '',
        email: '',
        domain: '',
        build: 'dev',
        promiseBuilder: function (transportDeferred, name, params) {
            var requestDeferred = new $.Deferred();
            transportDeferred.always(function (response, xhr) {
                var method = response.isOk() ? 'resolve' : 'reject';
                requestDeferred[method](response.getData(), response.getStatus(), response);
            });

            return requestDeferred.promise();
        },
    };

    return API;
});
