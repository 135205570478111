export const sendConfigPixel = (config = {}, pixelType: 'show_pixel' | 'click_pixel' | 'close_pixel') => {
    if (config[pixelType]) {
        new Image().src = config[pixelType];
    }
};

export function sendPixel(url: string): void {
    const rnd = `${url.includes('?') ? '&' : '?'}rnd=${Math.random()}`;
    new Image().src = `${url}${rnd}`;
}
