import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useEffect } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';
import { ESplashScreenAction, useSplashScreenAnalytics } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen.analytics';
import { ESplashScreenMod, ISplashScreenProps } from 'reactApp/ui/Mobile/SplashScreen/SplashScreen.types';
import { noopVoid } from 'reactApp/utils/helpers';

import styles from './SplashScreen.css';

export const SplashScreen = memo(({ contentHook, hookParams, onClose }: ISplashScreenProps): ReactElement => {
    const {
        id,
        title,
        description,
        buttonText,
        footerText,
        img,
        mod = ESplashScreenMod.blue,
        onClick = noopVoid,
    } = contentHook({ hookParams, onClose });

    const sendSplashScreenAnalytics = useSplashScreenAnalytics({ name: id, button_name: buttonText });

    useEffect(() => {
        sendSplashScreenAnalytics(ESplashScreenAction.show);
    }, []);

    const onCloseCallback = useCallback(() => {
        sendSplashScreenAnalytics(ESplashScreenAction.close);
        onClose();
    }, [onClose, sendSplashScreenAnalytics]);

    const onClickCallback = useCallback(() => {
        sendSplashScreenAnalytics(ESplashScreenAction.click);
        onClick();
    }, [onClick, sendSplashScreenAnalytics]);

    return (
        <MobileDialog id={id} onClose={onCloseCallback} disableScrolling mod="base" topmost fullHeight className={styles[`root_${mod}`]}>
            <div className={classNames(styles.root, styles[`root_${mod}`])}>
                <img src={img} className={styles.image} />
                <div className={styles.content}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.description}>{description}</div>
                    <Button className={styles.button} theme="vk" primary onClick={onClickCallback}>
                        {buttonText}
                    </Button>
                    {footerText && <div className={styles.footer}>{footerText}</div>}
                </div>
            </div>
        </MobileDialog>
    );
});

SplashScreen.displayName = 'SplashScreen';
