import classNames from 'clsx';
import { ReactComponent as SpinnerIcon } from 'img/icons/spinner.svg';
import { ReactComponent as VKUISpinnerIcon } from 'img/icons/vkui/spinner_44.svg';
import React, { memo, ReactElement } from 'react';
import { useViewContext } from 'reactApp/hocs/withViewContext';

import styles from './Spinner.css';
import { IProps, SpinnerTypes } from './Spinner.types';

const spinnerComponents = {
    [SpinnerTypes.cloud]: SpinnerIcon,
    [SpinnerTypes.vkui]: VKUISpinnerIcon,
    [SpinnerTypes.tutoria]: VKUISpinnerIcon,
} as const;

export const Spinner = memo((props: IProps): ReactElement => {
    let { type } = props;
    const viewContext = useViewContext();

    if (!type) {
        type = viewContext.isVKEmbedded ? SpinnerTypes.vkui : SpinnerTypes.cloud;
    }

    const Component = spinnerComponents[type] || SpinnerIcon;

    return (
        <Component
            className={classNames(styles.root, {
                [styles.root_embedded]: viewContext.isVKEmbedded,
                [styles.root_tutoria]: type === SpinnerTypes.tutoria,
            })}
        />
    );
});

Spinner.displayName = 'Spinner';
