import classNames from 'clsx';
import React, { memo, ReactElement } from 'react';
import { Placeholder } from 'reactApp/ui/Placeholder/Placeholder';

import styles from './SpaceLoader.css';

interface IProps {
    withButton: boolean;
    isCompact?: boolean;
}

export const SpaceLoader = memo(
    ({ withButton, isCompact = false }: IProps): ReactElement => (
        <div
            className={classNames(styles.root, {
                [styles.root_compact]: isCompact,
            })}
        >
            <div className={styles.quota}>
                <Placeholder />
            </div>
            <div className={styles.progress}>
                <Placeholder />
            </div>
            <div className={styles.info}>
                <div className={styles.space}>
                    <Placeholder />
                </div>
                {withButton && (
                    <div className={styles.subs}>
                        <Placeholder />
                    </div>
                )}
            </div>
            {withButton && (
                <div className={styles.button}>
                    <Placeholder />
                </div>
            )}
        </div>
    )
);

SpaceLoader.displayName = 'SpaceLoading';
