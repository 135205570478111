import { requestPayment } from 'Cloud/Application/Payment';
import { useCallback } from 'react';
import { sendGa } from 'reactApp/utils/ga';
import { EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

const sendBannerGa = (id, action: string): void => {
    sendGa('popup_trial_ga', action, id);
    sendGa('popup_trial_ab', action, 'ab');
};

export const useBannerOfferCallback = ({ id, handleClose, handleClick, handleShow, product, groupAb = 'None' }) => {
    const sendPayment = useCallback(
        (label) => {
            sendPaymentGa({ action: EPaymentGa.offerBanner, label, size_offer: product?.space?.original });
        },
        [product?.space?.original]
    );
    const sendPaymentAb = useCallback(
        (action) => {
            sendPaymentGa({
                action,
                label: '',
                size_offer: product.space.original,
                name_popup: 'BannerOffer',
                group: groupAb,
                eventCategory: 'pop-up',
                tariffId: product.id,
            });
        },
        [product.space.original]
    );

    const onClose = useCallback(() => {
        sendBannerGa(id, 'close');
        sendPayment('close');
        sendPaymentAb('close');

        handleClose();
    }, [id, handleClose, product]);

    const onClick = useCallback(async () => {
        sendBannerGa(id, 'click');
        sendPayment('click');
        sendPaymentAb('click');

        handleClick();

        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            requestPayment({
                productId: product.id,
                forceFastCheckout: true,
                onSuccess: () => sendBannerGa(id, 'success'),
            });

            handleClose();
        } catch (error) {}
    }, [id, handleClick, handleClose, product]);

    const onShow = useCallback(() => {
        sendBannerGa(id, 'show');
        sendPayment('view');
        sendPaymentAb('open');

        handleShow();
    }, [id, handleShow, product]);

    return {
        onClick,
        onClose,
        onShow,
    };
};
