import config from 'Cloud/config';
import React, { memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';
import { abRequiredSignUpWhenDownloadingTouchSelector } from 'reactApp/appHelpers/featuresHelpers';
import { chooseVariant } from 'reactApp/appHelpers/featuresHelpers/utils';
import { forceShowDownloadAppButton } from 'reactApp/modules/public/public.selectors';
import { sendDwh } from 'reactApp/utils/ga';

import { DownloadAppButtonComponent } from './DownloadButtonComponent';

const DOWNLOAD_APP_CONFIG = config.get('DOWNLOAD_APP_BUTTON_PUBLIC');

const link = chooseVariant(() => abRequiredSignUpWhenDownloadingTouchSelector(false), {
    control: 'https://trk.mail.ru/c/kj3vg2',
    variant1: 'https://trk.mail.ru/c/tyhs60',
    variant2: 'https://trk.mail.ru/c/ydprp5',
});

export const DownloadAppButton = memo(() => {
    const forceDownloadAppButton = useSelector(forceShowDownloadAppButton);

    const showButton = (Boolean(DOWNLOAD_APP_CONFIG) && !IS_WEBVIEW) || forceDownloadAppButton;

    useEffect(() => {
        if (showButton) {
            sendDwh({
                eventCategory: 'ads-touch',
                action: 'view',
            });
        }
    }, []);

    const handleOnDownloadAppClick = useCallback(() => {
        new Image().src = DOWNLOAD_APP_CONFIG?.click_pixel;
        sendDwh({
            eventCategory: 'ads-touch',
            action: 'click',
        });
    }, []);

    if (!showButton) {
        return null;
    }

    return (
        <DownloadAppButtonComponent
            onClick={handleOnDownloadAppClick}
            linkToDownload={`${link}?mt_deeplink=${window.location.href}`}
            showIcon={DOWNLOAD_APP_CONFIG.showIcon}
            text={DOWNLOAD_APP_CONFIG.text}
        />
    );
});

DownloadAppButton.displayName = 'DownloadAppButton';
