import { BookmarkButton } from 'reactApp/ui/Toolbar/BookmarkButton/BookmarkButton';
import { ToolbarItem, TToolbarItem } from 'reactApp/ui/Toolbar/Toolbar.types';

export const getBookmarkItem = (onBookmark?: (isActiveBookmark: boolean) => void): TToolbarItem => {
    return {
        Item: BookmarkButton as ToolbarItem,
        itemProps: {
            hook: () => ({
                onClick: onBookmark,
            }),
        },
    };
};
