import { IS_PHONE_BROWSER, PUBLIC_SHOVE } from 'reactApp/appHelpers/configHelpers';
import { ECategoryGa, EPaymentGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

interface IOpenPublicAnalytics {
    eventCategory?: ECategoryGa;
    type_public: 'folder' | 'file';
    extension: string;
    id_public: string;
    place?: string;
    id_file?: string;
    id_folder?: string;
    count_objects?: number;
    owner?: boolean;
    isStock?: boolean;
    isEditor?: boolean;
    source: string;
    have_faces?: boolean;
    count_faces?: boolean;
    name_files?: string;
    type_content?: string;
    hash?: string;
    size?: number;
}

/**
 * https://metida.mail.ru/cloud/events/2029
 */
export const sendOpenPublicAnalytics = ({ source, eventCategory, ...data }: IOpenPublicAnalytics) => {
    const isPhone = Boolean(IS_PHONE_BROWSER);
    sendPaymentGa({
        eventCategory: eventCategory || ECategoryGa.public,
        action: EPaymentGa.openPublic,
        shove: PUBLIC_SHOVE,
        isPhone,
        type_access: 'url',
        source,
        ...data,
    });
};
