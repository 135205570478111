import api from 'Cloud/Application/api';
import { path } from 'ramda';

export const checkUserDataEquals = (oldData) => (newData) => {
    const diffUsed = path(['cloud', 'space', 'bytes_used']);
    const space = path(['cloud', 'space', 'bytes_total']);
    const sizeLimit = path(['cloud', 'file_size_limit']);
    const diffOverquota = path(['cloud', 'space', 'overquota']);

    return (
        space(oldData) !== space(newData) ||
        sizeLimit(newData) !== sizeLimit(oldData) ||
        diffUsed(oldData) !== diffUsed(newData) ||
        diffOverquota(oldData) !== diffOverquota(newData)
    );
};

export const callApi = () => Promise.resolve(api.user());
