import classNames from 'clsx';
import React, { memo } from 'react';
import { ENABLE_FULL_RESPONSIVE } from 'reactApp/appHelpers/configHelpers';
import { Hint } from 'reactApp/ui/Hint/Hint';
import styles from 'reactApp/ui/UploadList/Item/Item.css';
import { formatDuration } from 'reactApp/utils/duration';

interface IProps {
    timeRemain?: number;
    sizeToUpload?: number;
    uploadSpeed: number;
    showTooltip?: boolean;
    narrow?: boolean;
}

export const RemainTime = memo(({ timeRemain, sizeToUpload, uploadSpeed, showTooltip = false, narrow = false }: IProps) => {
    let timeRemainText;

    if (timeRemain) {
        timeRemainText = `Ещё ${formatDuration(timeRemain, true)}`;
    } else if (sizeToUpload && uploadSpeed > 0) {
        timeRemainText = `Ещё ${formatDuration(Math.round(sizeToUpload / 1024 / 1024 / uploadSpeed), true)}`;
    }

    const RemainTimeText = <span>{timeRemainText}</span>;

    if (timeRemainText) {
        return (
            <div className={classNames(styles.remainTime, { [styles.responsive]: ENABLE_FULL_RESPONSIVE, [styles.narrow]: narrow })}>
                {showTooltip ? <Hint text={timeRemainText}>{RemainTimeText}</Hint> : RemainTimeText}
            </div>
        );
    }

    return null;
});

RemainTime.displayName = 'RemainTime';
