import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { store as reduxStore } from 'reactApp/store';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

export const sendToolbarGa = (action: string) => {
    const state = reduxStore.getState();
    const storage = getCurrentStorage(state);

    sendPaymentGa({
        eventCategory: ECategoryGa.toolbar,
        action,
        source: storage,
    });
};
