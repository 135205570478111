import { EStatus } from 'reactApp/sections/ErrorPage/ErrorPage.types';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

// https://docs.google.com/spreadsheets/d/1oxESW-0Ne7WtP7gKejV6CJYSv-Q9ssFDC8MY1_chDT0

export enum EErrorPageAction {
    SHOW = 'error',
    CLICK = 'error-click',
}

interface IErrorPageAnalytics {
    action: EErrorPageAction;
    number_error: EStatus;
    /** Где было открытие */
    source: string;
    /** C какой страницы облака пользователь перешел на текущую. Пока что не отправляется */
    previous_source?: number;
}

export const sendErrorPageAnalytics = ({ action, ...rest }: IErrorPageAnalytics): void => {
    sendDwh({
        eventCategory: ECategoryGa.entered,
        action,
        dwhData: rest,
    });
};
