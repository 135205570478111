import { useEffect, useState } from 'react';
import { isUseO2Token } from 'reactApp/api/O2AuthClient';
import { o2AuthedSourceLoader } from 'reactApp/ui/ReactViewer/O2AuthedSourceLoader';

export const useO2AuthedSource = (src: string, handleError: () => void) => {
    const [authedSource, setAuthedSource] = useState<string | undefined>(undefined);
    useEffect(() => {
        if (!isUseO2Token()) {
            return;
        }

        o2AuthedSourceLoader
            .getAuthedSourceFromRedirect(src)
            .then(o2AuthedSourceLoader.getObjectUrlFromBlob)
            .then(setAuthedSource)
            .catch(handleError);

        return () => {
            if (authedSource) {
                o2AuthedSourceLoader.revokeObjectUrl(authedSource);
            }
        };
    }, [src]);
    return authedSource;
};
