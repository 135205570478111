import { useSelector } from 'react-redux';
import { getSearchContentStatus } from 'reactApp/modules/search/search.selectors';
import { SelectionsSelectors } from 'reactApp/modules/selections/selections.selectors';
import { getIsYearFilter, getStorageSelectedItems } from 'reactApp/modules/storage/storage.selectors';
import { CloudItem, EActions, EStorageType } from 'reactApp/modules/storage/storage.types';
import { RootState } from 'reactApp/store';
import { getBackItem } from 'reactApp/ui/Toolbar/customActions/getBackItem';
import { getBookmarkItem } from 'reactApp/ui/Toolbar/customActions/getBookmarkItem';
import { getDeleteItem } from 'reactApp/ui/Toolbar/customActions/getDeleteItem';
import { getFullScreenItem } from 'reactApp/ui/Toolbar/customActions/getFullScreenItem';
import { getOptionsItem } from 'reactApp/ui/Toolbar/customActions/getOptionsItem';
import { TOOLBAR_CONFIG } from 'reactApp/ui/Toolbar/Toolbar.config';
import { TToolbarItem } from 'reactApp/ui/Toolbar/Toolbar.types';

import { getNewFeaturesItem } from './getNewFeaturesItem';
import { getYearFilterItem } from './getYearFilterItem';

export const useCustomActions = ({
    storage,
    isEbookReader,
    onBookmark,
}: {
    storage: EStorageType;
    isEbookReader?: boolean;
    onBookmark?(isActiveBookmark: boolean): void;
}) => {
    const left: TToolbarItem[] = [];
    const right: TToolbarItem[] = [];

    const items: CloudItem[] = useSelector((state: RootState) => getStorageSelectedItems(state, storage)) as CloudItem[];
    const isYearFilter = useSelector(getIsYearFilter);

    const isSelectingMode = useSelector(SelectionsSelectors.isAnyIndexSelected);
    const { webSearchContentEnabled } = useSelector(getSearchContentStatus);

    const settings = TOOLBAR_CONFIG[storage];
    if (!settings) {
        return { left, right };
    }

    if (settings.back) {
        left.push(getBackItem(storage));
    }

    if (settings[EActions.yearFilter] && isYearFilter) {
        right.push(getYearFilterItem(storage));
    }

    if (settings.delete) {
        right.push(getDeleteItem(storage, items));
    }

    if (
        settings[EActions.newFeatures] &&
        !isSelectingMode &&
        !(storage === EStorageType.search && webSearchContentEnabled) &&
        !isEbookReader
    ) {
        right.push(getNewFeaturesItem());
    }

    if (isEbookReader) {
        right.push(getFullScreenItem());

        left.push(getBookmarkItem(onBookmark));
        left.push(getOptionsItem());
        left.push({ id: EActions.delimiter });
    }

    return { left, right };
};
