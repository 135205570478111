import { APICallV3Get } from 'reactApp/api/APICall';

export type PromocodeInfo = {
    active: boolean;
    code: string;
};

export type PromocodesApiInfo = {
    promocodes: PromocodeInfo[];
};

class PromocodeGetAPICall extends APICallV3Get<PromocodesApiInfo> {
    _method = 'promo/get';
}

export { PromocodeGetAPICall };
