import { createAction, createReducer } from '@reduxjs/toolkit';
import { BuyAction, HasErrorAction, SetLinkAction, StartLoadingAction, State } from 'reactApp/modules/billing/billing.types';

const INITIAL_STATE: State = {
    isLoading: false,
    isLoaded: false,
    hasError: false,
    link: '',
    renewLink: '',
    requestId: '',
};

const buySubscription = createAction<BuyAction>('billing/buySubscription');
const renewSubscription = createAction<BuyAction>('billing/renewSubscription');

const setLink = createAction<SetLinkAction>('billing/setLink');
const setRenewLink = createAction<SetLinkAction>('billing/setRenewLink');
const resetLink = createAction('billing/resetLink');
const hasError = createAction<HasErrorAction>('billing/hasError');
const startLoading = createAction<StartLoadingAction>('billing/startLoading');

export const billingReducer = createReducer(INITIAL_STATE, {
    [resetLink.type]: () => INITIAL_STATE,
    [setLink.type]: (state, action: ReturnType<typeof setLink>) => {
        const { link, requestId } = action.payload;

        if (requestId !== state.requestId) {
            return;
        }

        state.link = link;
        state.isLoaded = true;
        state.isLoading = false;
    },
    [setRenewLink.type]: (state, action: ReturnType<typeof setRenewLink>) => {
        const { link, requestId } = action.payload;

        if (requestId !== state.requestId) {
            return;
        }

        state.renewLink = link;
        state.isLoaded = true;
        state.isLoading = false;
    },
    [startLoading.type]: (state, action: ReturnType<typeof startLoading>) => {
        const { requestId } = action.payload;

        state.requestId = requestId;
        state.isLoading = true;
        state.isLoaded = false;
    },
    [hasError.type]: (state, action: ReturnType<typeof hasError>) => {
        const { requestId } = action.payload;

        if (requestId !== state.requestId) {
            return;
        }

        return {
            ...INITIAL_STATE,
            hasError: true,
        };
    },
});

export const BillingActions = {
    setLink,
    setRenewLink,
    resetLink,
    buySubscription,
    renewSubscription,
    hasError,
    startLoading,
};
