import classNames from 'clsx';
import format from 'date-fns/format';
import React, { memo } from 'react';

import styles from './Date.css';

interface DateProps {
    className?: string;
    date?: number;
}

export const Date = memo<DateProps>(function Date({ className, date }) {
    return (
        <div data-qa-id="date" className={classNames(styles.root, className)}>
            {!!date && format(date, 'dd.MM.yy HH:mm')}
        </div>
    );
});
