import { shallowEqual } from 'react-redux';
import { CloudFile } from 'reactApp/modules/storage/storage.types';

/**
 *  Объект с исключенными пропсами,
 *  которые влияют на обновление документа в шторке "Совместный доступ"
 */
const omitProps = {
    weblink: undefined,
    weblinkAccessRights: undefined,
    weblinkDomestic: undefined,
    weblinkExpires: undefined,
};

/**
 * Функция сравнивает 2 объекта не учитывая свойства влияющие на перерендер мобильного документа
 */
export const shallowEqualMobile = <T extends { file: CloudFile }>(
    { file: prevFile, ...prevRest }: Readonly<React.PropsWithChildren<T>>,
    { file: nextFile, ...nextRest }: Readonly<React.PropsWithChildren<T>>
) => {
    const filesAreEqual = shallowEqual(
        {
            ...prevFile,
            ...omitProps,
        },
        {
            ...nextFile,
            ...omitProps,
        }
    );

    return filesAreEqual && shallowEqual(prevRest, nextRest);
};
