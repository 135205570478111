import { path, pathOr } from 'ramda';
import { TreeAddFolder } from 'reactApp/modules/tree/tree.constants';
const request = () => ({
    type: TreeAddFolder.REQUEST,
});
const add = folder => ({
    type: TreeAddFolder.ADD,
    payload: folder,
});

export const addFolder = folder => (dispatch, getState) => {
    dispatch(request());

    return new Promise((resolve, reject) => {
        const id = pathOr(path(['home'], folder), ['weblink'], folder);
        dispatch(add(folder));
    });
};
