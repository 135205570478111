export enum EErrorCondition {
    READ_ONLY = 'CONDITION/ACCESSDENIED',
    OVERQUOTA = 'CONDITION/OVERQUOTA',
    INFECTED = 'CONDITION/INFECTED',
    BLOCKED = 'CONDITION/BLOCKED',
    ILLEGAL = 'CONDITION/ILLEGAL',
}

export const ErrorConditionMap = {
    [EErrorCondition.INFECTED]: 'virus_scan_fail',
    [EErrorCondition.OVERQUOTA]: 'overquota',
    [EErrorCondition.READ_ONLY]: 'read_only',
};
