import { sendDwh } from 'reactApp/utils/ga';

import { ECategoryGa } from './paymentGa';

export enum ESafeFakedoorAnalytics {
    SHOW = 'show',
    CLICK = 'click',
    FAKE_PURCHAISE = 'fake-purchase',
    CLOSE = 'close',
    PURCHAISE = 'purchase',
}

interface DWHOptions {
    entity?: 'folder' | 'chapter';
    hot_key?: 'true' | 'false';
    screen?: 'first' | 'second';
}

export const safeFakedoorAnalytics = (action: ESafeFakedoorAnalytics, options?: DWHOptions): void => {
    const dwhData = options;

    sendDwh({
        eventCategory: ECategoryGa.fakedoorSafe,
        action,
        dwhData,
    });
};
