interface IUrlParams {
    /** /home вместо /subscriptions */
    isHomeRoute?: boolean;
    /** дополнительные get-параметры */
    additionalParams?: Record<string, string>;
    productId?: string;
    tariffId?: string;
}

const getRequestPaymentParams = (params: Record<string, string | undefined>) => {
    return Object.entries(params).reduce((result, [key, value]) => {
        if (key && value) {
            result += `&${key}=${value}`;
        }

        return result;
    }, '');
};

export const getRequestPaymentUrl = ({ isHomeRoute, productId, tariffId, additionalParams }: IUrlParams): string => {
    const url = `/${isHomeRoute ? 'home' : 'subscriptions'}?action=request-payment`;
    const urlParams = tariffId ? { ...additionalParams, tariffId } : productId ? { ...additionalParams, productId } : undefined;

    return urlParams ? `${url}${getRequestPaymentParams(urlParams)}` : '/subscriptions';
};
