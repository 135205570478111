import { UserNewDeviceAPICall } from 'reactApp/api/UserNewDeviceAPICall';
import { ProfileApiResponses } from 'reactApp/modules/profile/profile.types';
import { checkEmailAction } from 'reactApp/modules/socialUser/socialUser.actions';
import {
    checkEmailRequest,
    loadSocialUserFailure,
    loadSocialUserRequest,
    loadSocialUserSuccess,
} from 'reactApp/modules/socialUser/socialUser.module';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

const callApiNewDevice = (): any => new UserNewDeviceAPICall().makeRequest({ did: 'Web', name: 'Web', plid: 'Web' });

type LoadSocialUserActions =
    | ReturnType<typeof checkEmailRequest>
    | ReturnType<typeof loadSocialUserRequest>
    | ReturnType<typeof loadSocialUserFailure>
    | ReturnType<typeof loadSocialUserSuccess>;

const loadSocialUserThunk = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<LoadSocialUserActions> =>
    new Promise<LoadSocialUserActions>((resolve, reject): void => {
        dispatch(loadSocialUserRequest());

        checkEmailAction()(dispatch)
            .then((): void => {
                resolve(dispatch(loadSocialUserSuccess()));
            })
            .catch((error: any): void => {
                const message = error?.payload?.response?.message;

                if (message === ProfileApiResponses.USER_NOT_FOUND || message === ProfileApiResponses.DEVICE_NOT_FOUND) {
                    callApiNewDevice()
                        .then(() => {
                            return dispatch(checkEmailRequest());
                        })
                        .then((): void => {
                            resolve(dispatch(loadSocialUserSuccess()));
                        })
                        .catch((): void => {
                            reject(dispatch(loadSocialUserFailure()));
                        });
                    return;
                }
                reject(dispatch(loadSocialUserFailure()));
            });
    });

export const loadSocialUser = (): ((dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => any) => loadSocialUserThunk;
