import classnames from 'clsx';
import { ReactComponent as IconEmailNew } from 'img/social/mail.svg';
import { ReactComponent as IconMmNew } from 'img/social/moimir.svg';
import { ReactComponent as IconOkNew } from 'img/social/ok.svg';
import { ReactComponent as IconTwNew } from 'img/social/twitter.svg';
import { ReactComponent as IconVkNew } from 'img/social/vk.svg';
import { ReactComponent as IconMm } from 'mrg-icons/src/mailru/social/moimir.svg';
import { ReactComponent as IconOk } from 'mrg-icons/src/mailru/social/ok.svg';
import { ReactComponent as IconTw } from 'mrg-icons/src/mailru/social/twitter.svg';
import { ReactComponent as IconVk } from 'mrg-icons/src/mailru/social/vk.svg';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { MailIcon } from 'reactApp/ui/VKUIIcons';
import { Form } from 'semantic-ui-react';

import styles from './SocialButtons.css';

const WHITE = 'var(--vkui--color_background)';
const BLACK = 'var(--vkui--color_icon_primary)';

const icons = { IconMm, IconTw, IconVk, MailIcon, IconOk, IconMmNew, IconTwNew, IconVkNew, IconEmailNew, IconOkNew };

function SocialIcon({ id, dark, isNewIcons }) {
    const fill = dark ? BLACK : id === 'email' ? null : WHITE;
    const suffix = isNewIcons ? 'New' : '';
    const iconName = 'Icon' + id.replace(/./, (w) => w.toUpperCase()) + suffix;
    const Icon = icons[iconName];

    return <Icon fill={fill} />;
}

SocialIcon.propTypes = {
    id: PropTypes.string,
    dark: PropTypes.bool,
};

export class SocialButtons extends PureComponent {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        size: PropTypes.oneOf([null, 'medium', 'big']),
        socials: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                id: PropTypes.string,
            })
        ),
        dark: PropTypes.bool,
        isNewIcons: PropTypes.bool,
        disabled: PropTypes.bool,
    };

    onClick = (id) => () => {
        this.props.onClick(id);
    };

    renderItem = ({ id, name }) => {
        const { isNewIcons, dark, size, disabled } = this.props;
        return (
            <Form.Button
                key={id}
                onClick={this.onClick(id)}
                title={(id === 'email' ? '' : 'Поделиться в ') + name}
                className={classnames(styles.button, styles[`button_${id}`], {
                    [styles.button_dark]: dark,
                    [styles[`button_size_${size}`]]: size,
                    [styles.button_isNewIcons]: isNewIcons,
                    [styles.button_disabled]: disabled,
                })}
            >
                <SocialIcon id={id} dark={dark} isNewIcons={isNewIcons} />
            </Form.Button>
        );
    };

    render() {
        const { socials } = this.props;

        if (!socials || !socials.length) {
            return null;
        }

        return (
            <Form className={styles.base}>
                <Form.Group inline className={styles.buttons}>
                    {socials.map(this.renderItem)}
                </Form.Group>
            </Form>
        );
    }
}
