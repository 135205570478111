import { Spacing, Text, Title } from '@vkontakte/vkui';
import qr from 'img/albums/downloadAppQR.jpg';
import folderImg from 'img/emptyFolder.png';
import React, { memo } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import { UploadInput } from 'reactApp/ui/Mobile/UploadInput/UploadInput';

import styles from './EmptyContent.css';

interface EmptyContentProps {
    onClose(): void;
}

export const EmptyContent = memo<EmptyContentProps>(({ onClose }) => {
    return (
        <div className={styles.root} data-qa-id="select-from-cloud-empty-content">
            <div className={styles.folder} data-qa-id="folder">
                <img src={folderImg} alt="У вас пока нет фотографий" />
            </div>
            <Spacing size={45} />
            <Title level="2" data-qa-id="title">
                У вас пока нет фотографий
            </Title>
            <Spacing size={10} />
            <Text className={styles.text} data-qa-id="text">
                Загрузите фото или видео, чтобы добавить их в альбом
            </Text>
            <Spacing size={28} />
            <Button theme="vk" primary className={styles.button}>
                <div className={styles.upload}>
                    <UploadInput inputName="upload-from-gallery" onClick={onClose} />
                </div>
                Загрузить с компьютера
            </Button>
            <Spacing size={56} />
            <div className={styles.qr} data-qa-id="qr">
                <div className={styles.qrImage}>
                    <img src={qr} alt="Установите мобильное приложение" data-qa-id="qr-image" />
                </div>
                <div className={styles.qrText}>
                    <Text data-qa-id="qr-title" weight="2">
                        Или установите мобильное приложение
                    </Text>
                    <Spacing size={8} />
                    <Text data-qa-id="qr-text">Чтобы автоматически загрузить фотографии</Text>
                </div>
            </div>
        </div>
    );
});

EmptyContent.displayName = 'EmptyContentProps';
