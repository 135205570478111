import { Icon20Search } from '@vkontakte/icons';
import { Input, InputProps, SizeType } from '@vkontakte/vkui';
import React from 'react';

import styles from './SearchInput.css';

export const SearchInput: React.FC<InputProps> = (props) => (
    <Input
        mode="plain"
        className={styles.input}
        sizeY={SizeType.COMPACT}
        before={<Icon20Search className={styles.searchIcon} />}
        {...props}
    />
);

SearchInput.displayName = 'SearchInput';
