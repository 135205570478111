import classNames from 'clsx';
import { ReactComponent as Arrow } from 'img/icons/mobile/arrow.svg';
import React, { FC, ReactNode, SVGProps, VFC } from 'react';

import styles from './FileInfoItem.css';

type Direction = 'up' | 'down' | 'left' | 'right';

type Props = {
    section: string;
    Icon: FC<SVGProps<SVGSVGElement>> | FC;
    rightIcon?: Direction;
    active?: boolean;
    value?: ReactNode | string;
    onClick?: () => void;
};

const mapDirectionToDeg: Record<Direction, string> = {
    up: 'deg180',
    down: 'deg0',
    left: 'deg90',
    right: 'deg270',
};

export const FileInfoItem: VFC<Props> = ({ value, active, section, Icon, rightIcon, onClick }) => (
    <div className={styles.root} onClick={onClick}>
        <div className={classNames(styles.item, styles.icon, { [styles.activeIcon]: active })}>
            <Icon />
        </div>
        <div className={classNames(styles.item, styles.infoContainer)}>
            <div className={styles.info}>{value}</div>
            <div className={styles.section}>{section}</div>
        </div>
        {rightIcon && (
            <Arrow className={classNames({ [styles.item]: true, [mapDirectionToDeg[rightIcon]]: true })} width={24} height={24} />
        )}
    </div>
);
