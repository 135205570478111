import classNames from 'clsx';
import { ReactComponent as MailIcon } from 'img/confirm-email/mail.svg';
import { ReactComponent as ClearIcon } from 'mrg-icons/src/mailru/actions/close.svg';
import React, { PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { addEmailAction } from 'reactApp/modules/socialUser/socialUser.actions';
import { clearError } from 'reactApp/modules/socialUser/socialUser.module';
import { getEmail, getError } from 'reactApp/modules/socialUser/socialUser.selectors';
import { Button } from 'reactApp/ui/Button/Button';
import { noop } from 'reactApp/utils/helpers';
import { AnyAction } from 'redux';

import styles from './Step.css';

interface MapState {
    error: string;
    email: string;
    storage: string;
}

interface Props extends MapState {
    // eslint-disable-next-line @typescript-eslint/ban-types
    onButtonClick: Function;
    // eslint-disable-next-line @typescript-eslint/ban-types
    addEmail: Function;
    // eslint-disable-next-line @typescript-eslint/ban-types
    clearError: Function;
}

interface State {
    email: string;
}

const mapStateToProps = (state): MapState => ({
    error: getError(state),
    email: getEmail(state),
    storage: getCurrentStorage(state),
});

const mapDispatchToProps = (dispatch) => ({
    addEmail: (email: string, storage: string): AnyAction => dispatch(addEmailAction(email, storage)),
    clearError: (): AnyAction => dispatch(clearError()),
});

export class FirstStepComponent extends PureComponent<Props, State> {
    public static defaultProps = {
        onButtonClick: noop,
        addEmail: noop,
        clearError: noop,
        error: '',
        email: '',
    };

    public readonly state = {
        email: this.props.email,
    };

    public componentWillUnmount(): void {
        this.props.clearError();
    }

    private handleEmailChange = (e): void => {
        this.setState({ email: e.target.value });
    };

    private handleEmailClear = (): void => {
        this.setState({ email: '' });
        this.props.clearError();
    };

    private handleSubmit = (): void => {
        const { email } = this.state;
        this.props.addEmail(email, this.props.storage).then((): void => {
            this.props.onButtonClick();
        });
    };

    public render(): ReactElement {
        const { email } = this.state;
        const { error } = this.props;

        const text = this.props.email
            ? 'Проверьте правильность введеного email. На него будет отправлено новое письмо со ссылкой на подтверждение почты.'
            : 'Чтобы начать пользоваться Облаком, укажите свой email, и мы пришлём на указанный вами адрес ссылку для подтверждения.';

        return (
            <div className={styles.step}>
                <div className={styles.icon}>
                    <MailIcon width={125} height={95} />
                </div>
                <div className={styles.title}>Подтверждение email</div>
                <div className={styles.text}>
                    {text}
                    <br />
                    На этот адрес мы будем отправлять важные письма
                </div>
                <div>
                    <div className={styles.control}>
                        <div className={styles.inputBlock}>
                            <input
                                className={classNames({
                                    [styles.input]: true,
                                    [styles.inputError]: !!error,
                                })}
                                type="email"
                                placeholder="Укажите ваш e-mail"
                                value={email}
                                onChange={this.handleEmailChange}
                                id="email"
                                name="email"
                                autoComplete="off"
                            />
                            {email && (
                                <span className={styles.iconClear} onClick={this.handleEmailClear}>
                                    <ClearIcon width={16} height={16} />
                                </span>
                            )}
                        </div>
                        <div>
                            <Button primary fluid size="big" theme="octavius" onClick={this.handleSubmit}>
                                <div className={styles.buttonText}>Отправить</div>
                            </Button>
                        </div>
                    </div>
                    <div className={styles.error}>{error}</div>
                </div>
            </div>
        );
    }
}

export const FirstStep = connect(mapStateToProps, mapDispatchToProps)(FirstStepComponent);
