export const isFramed = (): boolean => {
    let isFrame = false;

    try {
        isFrame = window !== window.top || document !== top?.document || self.location !== top?.location;
    } catch (error) {
        isFrame = true;
    }

    return isFrame;
};
