import { createAction, createReducer } from '@reduxjs/toolkit';
import { State } from 'reactApp/modules/captcha/captcha.types';
import { RootState } from 'reactApp/store';
import { createSelector } from 'reselect';

const INITIAL_STATE: State = {
    error: '',
    isLoading: false,
};

export const checkCaptchaRequest = createAction('captcha/checkCaptchaRequest');
export const checkCaptchaSuccess = createAction('captcha/checkCaptchaSuccess');
export const checkCaptchaFailure = createAction<{ error: string }>('captcha/checkCaptchaFailure');
export const resetCaptchaState = createAction('captcha/resetCaptchaState');

export const captchaReducer = createReducer(INITIAL_STATE, {
    [checkCaptchaRequest.type]: () => ({ ...INITIAL_STATE, isLoading: true }),
    [checkCaptchaSuccess.type]: () => INITIAL_STATE,
    [checkCaptchaFailure.type]: (state, action: ReturnType<typeof checkCaptchaFailure>) => ({
        ...INITIAL_STATE,
        error: action.payload.error,
    }),
    [resetCaptchaState.type]: () => INITIAL_STATE,
});

export const getCaptchaError = createSelector(
    (state: RootState) => state.captcha,
    (captchaState): string => captchaState.error
);

export const isCheckingCaptcha = createSelector(
    (state: RootState) => state.captcha,
    (captchaState): boolean => captchaState.isLoading
);
