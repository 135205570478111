import { xray } from 'lib/xray';
import { intersection } from 'ramda';
import { getQueryParams } from 'reactApp/appHelpers/settingsHelpers';

const formList = ['signin', 'login', 'singup'];

export const sendAuthAnalytics = () => {
    const { from } = getQueryParams();

    if (!from) {
        return;
    }

    const fromString = typeof from == 'string' ? from : Array.isArray(from) && intersection(from, formList).join('_');

    if (!fromString) {
        return;
    }

    xray.send(`auth_success_from_${fromString}`);
};
