import React, { FC } from 'react';

import styles from './DataListItemPlug.css';

const BLOCKS_COUNT = 40;
const items = new Array(BLOCKS_COUNT).fill('data_list_item_plug');

export const DataListItemPlug: FC = () => (
    <div>
        {items.map((key, i) => (
            <div key={`${key}:${i}`} className={styles.item}>
                <div className={styles.group}>
                    <div className={styles.checkbox} />
                    <div className={styles.icon} />
                    <div className={styles.name} />
                </div>
                <div className={styles.group}>
                    <div className={styles.date} />
                    <div className={styles.size} />
                    <div className={styles.download} />
                </div>
            </div>
        ))}
    </div>
);
