import { getEntries } from 'reactApp/modules/uploading/helpers/dataTransfer.helpers';
import { addToUploadQueue } from 'reactApp/modules/uploading/uploading.module';
import { IDescriptorOptions } from 'reactApp/modules/uploading/uploading.types';
import { put } from 'redux-saga/effects';

export function* checkedDrop(data: DataTransfer, descriptorOptions: IDescriptorOptions = {}) {
    const entries = getEntries(data);

    if (entries?.length) {
        yield put(addToUploadQueue({ files: entries, descriptorOptions }));
    } else {
        const files = Array.from(data.files) as File[];

        if (files) {
            yield put(addToUploadQueue({ files, descriptorOptions }));
        }
    }
}
