import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { UserQuotaGroupId, UserQuotaGroupType } from 'reactApp/types/QuotaCleaner';

export const getViewModes = (groupType?: UserQuotaGroupType, groupId?: UserQuotaGroupId): EViewMode[] => {
    if (!groupType || !groupId) {
        return [];
    }

    const modes = [EViewMode.list];
    if (groupType === UserQuotaGroupType.Cloud) {
        modes.push(EViewMode.thumbs);
    }

    if (groupId === UserQuotaGroupId.CameraUploads || groupId === UserQuotaGroupId.Video) {
        modes.push(EViewMode.gallery);
    }

    return modes;
};
