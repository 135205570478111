export function getExtension(item?: null | string | { name: string }): string {
    const name = typeof item === 'string' ? item : item?.name;
    if (!name) {
        return '';
    }

    const index = name.lastIndexOf('.');

    if (index === -1) {
        return '';
    }

    return name.slice(Math.max(0, index + 1));
}
