import { MouseEvent, useRef, useState } from 'react';

import { Props } from './MenuButton';

export const useMenuButton = (): Props => {
    const [isShowMenu, setShowMenu] = useState<boolean>(false);
    const menuRef = useRef<HTMLDivElement | null>(null);

    const closeMenu = (event?: MouseEvent) => {
        event?.stopPropagation();
        setShowMenu(false);
    };

    const showMenu = () => {
        setShowMenu(true);
    };

    return {
        isShowMenu,
        closeMenu,
        showMenu,
        menuRef,
    };
};
