import { EViewMode } from 'reactApp/modules/settings/settings.types';
import { VirtualListGalleryRowMobile } from 'reactApp/ui/Mobile/VirtualList/VirtualListGalleryRowMobile';

import { VirtualListGalleryRow } from './VirtualListGalleryRow';
import { VirtualListGridRow } from './VirtualListGridRow';
import { VirtualListRow } from './VirtualListRow';

export const listChildrenMap = {
    [EViewMode.gallery]: VirtualListGalleryRow,
    [EViewMode.list]: VirtualListRow,
    [EViewMode.thumbs]: VirtualListGridRow,
    [EViewMode.galleryGrid3]: VirtualListGalleryRowMobile,
    [EViewMode.squares]: VirtualListGridRow,
    [EViewMode.squares180]: VirtualListGridRow,
};
