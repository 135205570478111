import { showSnackbarAction } from 'reactApp/modules/snackbar/snackbar.actions';
import { uploadingLog } from 'reactApp/modules/uploading/serviceClasses/UploadingLog';
import { IDescriptorOptions } from 'reactApp/modules/uploading/uploading.types';
import { put } from 'redux-saga/effects';

import { addToUploadQueue } from '../uploading.module';

export function* processInputChange(files: File[], descriptorOptions: IDescriptorOptions) {
    try {
        yield put(addToUploadQueue({ files: Array.from(files), descriptorOptions }));

        uploadingLog.info({
            event: 'input files',
            details: files.length,
            radarName: 'input-files',
            radarValue: files.length,
        });

        uploadingLog.info({
            event: 'enqueue',
            details: files.length,
        });
    } catch (error: any) {
        yield put(showSnackbarAction({ id: 'uploaderror', text: 'Файлы не найдены' }));
        uploadingLog.error(error);
    }
}
