import { isAttachFile } from 'reactApp/modules/attaches/attaches.helpers';

import { UseOvidiusData, UseOvidiusProps } from './types';
import { useAttachOvidius } from './useAttachOvidius';
import { useHomeOvidius } from './useHomeOvidius';

export const useNormalizedOvidius = (params: UseOvidiusProps): UseOvidiusData => {
    const attachResult = useAttachOvidius(params);
    const homeResult = useHomeOvidius(params);

    return isAttachFile(params.file) ? attachResult : homeResult;
};
