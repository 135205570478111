import { createAction, createReducer } from '@reduxjs/toolkit';
import { State } from 'reactApp/modules/filesFilter/filesFilter.types';

const initialState: State = {
    filterAll: true,
    filterAuthor: [],
};

export const filesFilterAll = createAction('@filesFilter/filesFilterAll');
export const filesFilterRemoveAll = createAction('@filesFilter/filesFilterRemoveAll');
export const filesFilterOne = createAction<string>('@filesFilter/filesFilterOne');
export const filesFilterAddToFilter = createAction<{ author: string; folderAuthors: string[] }>('@filesFilter/filesFilterAddToFilter');
export const filesFilterRemoveFromFilter = createAction<{ author: string; folderAuthors: string[] }>(
    '@filesFilter/filesFilterRemoveFromFilter'
);

export const filesFilterReducer = createReducer(initialState, {
    [filesFilterAll.type]: (state): void => {
        state.filterAll = true;
        state.filterAuthor = [];
    },
    [filesFilterRemoveAll.type]: (state): void => {
        state.filterAll = false;
        state.filterAuthor = [];
    },
    [filesFilterOne.type]: (state, action: ReturnType<typeof filesFilterOne>): void => {
        state.filterAll = false;
        state.filterAuthor = [action.payload];
    },
    [filesFilterAddToFilter.type]: (state, action: ReturnType<typeof filesFilterAddToFilter>): void => {
        const { author, folderAuthors } = action.payload;

        const isAll = state.filterAuthor.length + 1 === folderAuthors.length;

        state.filterAuthor = isAll ? [] : [...state.filterAuthor, author];
        state.filterAll = isAll;
    },
    [filesFilterRemoveFromFilter.type]: (state, action: ReturnType<typeof filesFilterRemoveFromFilter>): void => {
        const { author, folderAuthors } = action.payload;

        state.filterAuthor = (state.filterAll ? folderAuthors : state.filterAuthor).filter(
            (authorInFilter): boolean => authorInFilter !== author
        );
        state.filterAll = false;
    },
});
