import { defaultMillisecondsToLive, fetchFunctionType, IMemoryCache, TCacheType } from './MemoryCache.models';
import { MemoryCacheItem } from './MemoryCacheItem';

const NO_KEY_PROVIDED = 'No key provided';

export class MemoryCache implements IMemoryCache {
    private cacheItems: Map<string, MemoryCacheItem> = new Map<string, MemoryCacheItem>();
    private millisecondsToLive: TCacheType = defaultMillisecondsToLive;

    public constructor(millisecondsToLive: number = defaultMillisecondsToLive) {
        this.millisecondsToLive = millisecondsToLive;
    }

    public add = (key: string, fetchFunction: fetchFunctionType, millisecondsToLive: TCacheType | null = null): void => {
        if (!key) {
            throw new RangeError(NO_KEY_PROVIDED);
        }
        if (!fetchFunction) {
            throw new RangeError('No get function provided');
        }
        this.cacheItems.set(key, new MemoryCacheItem(fetchFunction, millisecondsToLive || this.millisecondsToLive));
    };

    public delete = (key: string): void => {
        if (!key) {
            throw new RangeError(NO_KEY_PROVIDED);
        }
        this.cacheItems.delete(key);
    };

    public get = (key: string): Promise<any> => {
        if (!key) {
            throw new RangeError(NO_KEY_PROVIDED);
        }
        const item = this.cacheItems.get(key);
        if (item) {
            return item.getData();
        }

        return Promise.resolve();
    };

    public getOrAdd = (key: string, fetchFunction: fetchFunctionType, millisecondsToLive: TCacheType | null = null): any => {
        if (!key) {
            throw new RangeError(NO_KEY_PROVIDED);
        }
        let item = this.cacheItems.get(key);
        if (item) {
            return item.getData();
        }
        this.add(key, fetchFunction, millisecondsToLive);

        item = this.cacheItems.get(key);
        if (item) {
            return item.getData();
        }

        return Promise.resolve();
    };

    public resetCache(): void {
        this.cacheItems.forEach((item): void => item.resetCache());
        this.cacheItems.clear();
    }
}
