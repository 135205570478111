/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import classNames from 'clsx';
import { pathOr } from 'ramda';
import React, { memo, ReactElement, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IS_IOS_MOBILE, IS_PUBLIC_ALBUM } from 'reactApp/appHelpers/configHelpers';
import { ebookTouchConfig } from 'reactApp/appHelpers/featuresHelpers';
import { emptyImageTransparentUrl } from 'reactApp/constants';
import { ESources } from 'reactApp/hooks/useDownloadEbook';
import { getFeaturePdfRenderOnTouch } from 'reactApp/modules/features/features.selectors';
import {
    getItemNameWithoutExt,
    getWeblinkFromPublicId,
    isArchive,
    isAudio,
    isDocument,
    isEBook,
    isImage,
    isPdf,
    isPlainSource,
    isPreviewable,
    isVideo,
    isVirusItem,
} from 'reactApp/modules/file/utils';
import { isCloudFile } from 'reactApp/modules/storage/storage.helpers';
import { getStorageItemById } from 'reactApp/modules/storage/storage.selectors';
import { CloudFile, EStorageType } from 'reactApp/modules/storage/storage.types';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { RootState } from 'reactApp/store';
import { Button } from 'reactApp/ui/Button/Button';
import { MobileViewerArchive } from 'reactApp/ui/Mobile/MobileViewer/MobileViewerArchive/MobileViewerArchive';
import { MobileViewerBottomToolbar } from 'reactApp/ui/Mobile/MobileViewer/MobileViewerBottomToolbar/MobileViewerBottomToolbar';
import { MobileViewerImg } from 'reactApp/ui/Mobile/MobileViewer/MobileViewerItem/MobileViewerImg';
import { MobileViewerTxt } from 'reactApp/ui/Mobile/MobileViewer/MobileViewerTxt/MobileViewerTxt';
import { MobilePdfViewer } from 'reactApp/ui/ReactViewer/MobilePdfViewer/MobilePdfViewer';
import { getItemImageUrl, getPosterUrl, sendViewerDwh } from 'reactApp/ui/ReactViewer/ReactViewer.helpers';
import { Stub } from 'reactApp/ui/ReactViewer/Stub/Stub';
import { VideoPlayer } from 'reactApp/ui/ReactViewer/VideoPlayer/VideoPlayer';
import { ViewerEbook } from 'reactApp/ui/ViewerEbook/ViewerEbook';
import { noop } from 'reactApp/utils/helpers';
import { ECategoryGa } from 'reactApp/utils/paymentGa';
import { useSwiper } from 'swiper/react';

import { MobileViewerDoc } from '../MobileViewerDoc/MobileViewerDoc';
import styles from './MobileViewerItem.css';

const extNoPrecache = IS_IOS_MOBILE ? ['yml'] : [];

interface Props {
    itemId: string;
    forceArchiveViewer?: boolean;
    selected?: boolean;
    slideTransition?: boolean;
    setShowCustomItem?: (state: boolean) => void;
    onToggleCustomItem?: (state: boolean) => void;
    openToolbarOptions?: () => void;
    storage: EStorageType;
    isViewContentOnly?: boolean;
    isPublic: boolean;
    showToolbarInArchive?: boolean;
    isSingle?: boolean;

    sendAnalytics?(data: any): void;

    handleDownload?(): void;

    onClose?(label: string);
}

export const MobileViewerItem = memo<Props>(
    ({
        sendAnalytics = noop,
        openToolbarOptions,
        onToggleCustomItem,
        itemId,
        storage,
        isSingle,
        handleDownload = noop,
        selected = true,
        slideTransition = false,
        isViewContentOnly = false,
        isPublic,
        forceArchiveViewer = false,
        showToolbarInArchive = true,
        onClose = () => {},
    }): ReactElement | null => {
        const item = useSelector((state: RootState) => getStorageItemById(state, storage, itemId));
        const isPreviewableItem = item && isPreviewable(item, storage);
        const [showArchive, setShowArchive] = useState(false);
        const [cacheSelected, setCacheSelected] = useState(selected);
        const swiper = useSwiper();
        const renderPdfOnTouch = useSelector(getFeaturePdfRenderOnTouch);
        const showPdfDocument =
            item && !item.isFolder && isPreviewableItem && isPdf(item) && renderPdfOnTouch && item.size <= renderPdfOnTouch.maxViewSize;
        const showDocumentItem = isPreviewableItem && isCloudFile(item) && isDocument(item) && selected;
        const source = `touch-${IS_PUBLIC_ALBUM ? 'album' : storage}`;
        const itemStorage = useSelector(ViewerSelectors.getViewerItemStorage);
        const isItemArchive = item && isArchive(item);

        const name = item?.name || '';
        const ext = pathOr('', ['ext'], item) as string;
        const nameWithoutExt = getItemNameWithoutExt(name, ext);
        const mediaUrl = pathOr('', ['url', 'media'], item);
        const viewUrl = pathOr('', ['url', 'view'], item);

        const isVirus = isVirusItem(item);
        const shouldShowArchive = isPreviewableItem && isItemArchive && selected && showArchive;

        useEffect(() => {
            if (!slideTransition) {
                setCacheSelected(selected);
            }
        }, [selected, slideTransition]);

        useEffect(() => {
            if (selected && item && !item.isFolder) {
                sendViewerDwh({
                    id_public: storage === EStorageType.public ? getWeblinkFromPublicId(item.id) : 'None',
                    source: source || '',
                    type_content: item.kind,
                    extension: item.ext?.toLowerCase() || '',
                    action: 'open-content',
                    is_stories: storage === EStorageType.story,
                    have_face: false,
                    is_full_render: isPreviewableItem,
                    is_edit: false,
                    size_files: item.size,
                    is_archive: false,
                });
            }
        }, [selected, item]);

        if (selected) {
            /* Включаем zoom для всех. Выключаем там где требуется */
            swiper?.zoom?.enable();
        }

        const handleError = useCallback(() => {
            if (!item) {
                return;
            }

            sendViewerDwh({
                id_public: storage === EStorageType.public ? getWeblinkFromPublicId(item.id) : 'None',
                source: source || '',
                type_content: item.kind,
                extension: ext?.toLowerCase() || '',
                action: ECategoryGa.errorShow,
                is_stories: storage === EStorageType.story,
                have_face: false,
                is_full_render: isPreviewableItem,
                is_edit: false,
                size_files: 'size' in item ? item.size : undefined,
                is_archive: shouldShowArchive,
            });
        }, [item, showArchive]);

        const renderStub = useCallback(
            () => (
                <Stub
                    ext={ext}
                    isVirus={isVirus}
                    name={nameWithoutExt}
                    kind={item?.kind || 'file'}
                    mode={isSingle ? 'light' : 'dark'}
                    size={isSingle ? 'medium' : 'big'}
                    showName={isSingle}
                    onClick={handleDownload}
                />
            ),
            [ext, isVirus, nameWithoutExt, item?.kind, isSingle, handleDownload]
        );

        useEffect(() => {
            if (selected && forceArchiveViewer && isItemArchive && isPreviewableItem) {
                setShowArchive(true);
            }
        }, [selected, forceArchiveViewer, isItemArchive, isPreviewableItem]);

        const handleOnClose = useCallback(() => {
            if (!swiper?.destroyed) {
                swiper?.enable();
            }

            setShowArchive(false);

            if (!forceArchiveViewer || storage === EStorageType.viewerAttaches || storage === EStorageType.attaches) {
                return;
            }
            onClose('archive');
        }, [forceArchiveViewer, setShowArchive, storage]);

        const handleCloseArchiveViewer = useCallback(() => {
            onToggleCustomItem?.(false);
            setShowArchive(false);

            if (forceArchiveViewer) {
                handleOnClose();
            }
        }, [handleOnClose, onToggleCustomItem, setShowArchive]);

        if (shouldShowArchive) {
            onToggleCustomItem?.(true);
            swiper?.disable();

            return (
                <MobileViewerArchive
                    showToolbar={showToolbarInArchive}
                    showCloseButton={showToolbarInArchive}
                    openToolbarOptions={openToolbarOptions}
                    onShow={noop}
                    closeArchiveViewer={handleCloseArchiveViewer}
                    onClose={handleOnClose}
                    storage={storage}
                    item={item}
                    sendAnalytics={sendAnalytics}
                    onError={handleError}
                />
            );
        }

        if (showPdfDocument) {
            return (
                <MobilePdfViewer
                    item={item}
                    storage={storage}
                    wait={!cacheSelected}
                    onShow={() => {
                        swiper?.zoom?.disable();
                    }}
                    onClose={() => {
                        swiper?.zoom?.enable();
                    }}
                    onError={handleError}
                />
            );
        }

        if (showDocumentItem) {
            return <MobileViewerDoc file={item} />;
        }

        const doNotRenderInvisible = extNoPrecache.includes(ext) && !selected;

        if (!doNotRenderInvisible && isPreviewableItem && isPlainSource(item, storage)) {
            return <MobileViewerTxt item={item} />;
        }

        if (isPreviewableItem && isImage(item)) {
            const src = getItemImageUrl(item);

            return <MobileViewerImg src={src} name={name} ext={ext} kind={item?.kind} isVirus={isVirus} onError={handleError} />;
        }

        if (isPreviewableItem && (isVideo(item) || isAudio(item))) {
            const poster = getPosterUrl(item);

            return (
                <VideoPlayer
                    streamUrl={mediaUrl}
                    nativeUrl={viewUrl}
                    posterUrl={isAudio(item) ? emptyImageTransparentUrl : poster}
                    kind={item.kind}
                    ext={ext}
                    name={name}
                    isVisible={selected}
                    size={'size' in item ? item.size : undefined}
                    onError={handleError}
                />
            );
        }

        if (isPreviewableItem && isEBook(item) && ebookTouchConfig) {
            return (
                <ViewerEbook
                    file={item as CloudFile}
                    renderStub={renderStub}
                    source={ESources.touch}
                    itemStorage={itemStorage}
                    isPublic={isPublic}
                />
            );
        }

        if (selected) {
            /* Выключаем zoom для stub'ов */
            swiper?.zoom?.disable();
        }

        return (
            <div className={classNames(styles.iconBlock /* , 'swiper-zoom-target' */)}>
                {renderStub()}
                {isItemArchive && isPreviewableItem && (
                    <div className={styles.iconBlock_footer}>
                        <Button onClick={() => setShowArchive(true)} theme="octavius" primary className={styles.iconBlock_footerButton}>
                            Открыть архив
                        </Button>
                    </div>
                )}
                {!isItemArchive && !isSingle && !isVirus && !isViewContentOnly && (
                    <MobileViewerBottomToolbar id={itemId} storage={storage} sendAnalytics={sendAnalytics} />
                )}
            </div>
        );
    }
);

MobileViewerItem.displayName = 'MobileViewerItem';
