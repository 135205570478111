import sha1 from 'js-sha1';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { sendFacesDwh } from 'reactApp/modules/faces/faces.helpers';
import { getCurrentPublicId } from 'reactApp/modules/faces/faces.selectors';
import { getCurrentPublicFolder, isOwnPublic } from 'reactApp/modules/public/public.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';

export const usePublicFaceRadars = ({ countFaces }: { countFaces?: number }) => {
    const [hasRadarSent, setHasRadarSent] = useState<Record<string, boolean>>({});
    const isOwn = useSelector(isOwnPublic);
    const publicId = useSelector(getCurrentPublicId);
    const folder = useSelector(getCurrentPublicFolder);
    const countObjects = (folder?.count?.files ?? 0) + (folder?.count?.folders ?? 0);
    const radarPublicId = isOwn && folder?.home ? sha1(folder.home) : publicId;

    const sendFacesHelper = useCallback(
        (action, sendOnceOnly = false) => {
            if (sendOnceOnly && hasRadarSent[action]) {
                return;
            }
            if (sendOnceOnly) {
                setHasRadarSent((state) => ({ ...state, [action]: true }));
            }
            sendFacesDwh({
                action,
                count_faces: countFaces,
                id_public: radarPublicId,
                type_folder: folder?.parent ? 'folder_in_folder' : 'folder',
                type_access: 'url',
                source: EStorageType.public,
                count_objects: countObjects,
                id_folder: folder?.id,
            });
        },
        [publicId, countFaces, countObjects, hasRadarSent, setHasRadarSent]
    );

    return { sendFacesHelper };
};
