import { createSelector } from 'reselect';

import { State } from './filesFilter.types';

const getFilesFilter = (state: any): State => {
    return state.filesFilter;
};

export const getCurrentFilter = (state: any): 'all' | string[] => {
    const filter = getFilesFilter(state);
    return filter.filterAll ? 'all' : filter.filterAuthor;
};

export const getCurrentAuthorInFilter = createSelector(getFilesFilter, (filter): string[] => filter.filterAuthor);
export const isAllFiltered = createSelector(getFilesFilter, (filter): boolean => filter.filterAll);
export const isFilterAuthorEmpty = createSelector(getFilesFilter, (filter): boolean => filter?.filterAuthor?.length === 0);
