import { ReactComponent as DownloadAppIcon } from 'img/icons/downloadApp.svg';
import React, { useMemo } from 'react';
import { getStickyButtonExtentionList } from 'reactApp/modules/features/features.selectors';
import { Button } from 'reactApp/ui/Button/Button';
import { StickyComponent } from 'reactApp/ui/StickyComponent/StickyComponent';
import styles from 'reactApp/ui/ViewerToolbar/ViewerToolbar.css';
import { handleOnMobileApp } from 'reactApp/ui/ViewerToolbar/ViewerToolbar.helpers';
import type { IFile } from 'reactApp/ui/ViewerToolbar/ViewerToolbar.types';

interface IProps {
    file: IFile;
    isPreviewableItem?: boolean;
}

export const DownloadAppButton = ({ file, isPreviewableItem }: IProps) => {
    const button = (
        <Button onClick={handleOnMobileApp} color="blue" size="big">
            <DownloadAppIcon className={styles.downloadAppIcon2} />
            Скачать приложение
        </Button>
    );

    const isExtInList = useMemo(() => getStickyButtonExtentionList().includes(file?.extension?.toLowerCase()), [file?.extension]);

    /**
     * Нужно показывать не-стики кнопку
     * на просматриваемых файлах из списка
     */
    if (isExtInList && isPreviewableItem) {
        return button;
    }

    return <StickyComponent fixedContentClassName={styles.downloadApp_fixed} offset={22} renderContent={() => button} />;
};

DownloadAppButton.displayName = 'DownloadAppButton';
