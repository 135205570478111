import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { getBottomMargin } from 'reactApp/modules/snackbar/snackbar.selectors';

import { SnackbarComponent } from './components/SnackbarComponent';
import { useSnackbarsList } from './hooks/useSnackbarsList';
import styles from './Snackbars.css';

export const Snackbars = memo(function Snackbars() {
    const snackbarsList = useSnackbarsList();
    const bottomMargin = useSelector(getBottomMargin);

    if (!snackbarsList) {
        return null;
    }

    return (
        <div className={styles.root} style={bottomMargin ? { bottom: `${bottomMargin}px` } : undefined}>
            {snackbarsList.map((item) => (
                <div className={styles.snackbar} key={item.id} data-qa-id={item.type}>
                    <SnackbarComponent {...item} />
                </div>
            ))}
        </div>
    );
});
