import { APICallV3Post } from 'reactApp/api/APICall';
import { WopiData } from 'reactApp/api/wopi/WopiAPI.types';

export class WopiViewAPICall extends APICallV3Post<WopiData> {
    _method = 'wopi-go/view';
}

export class WopiEmbeddedViewAPICall extends APICallV3Post<WopiData> {
    _method = 'wopi-go/embedded/view_direct';
}
