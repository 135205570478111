export enum EActionName {
    download = 'download',
    clone = 'clone',
}

export enum EVirusScan {
    pass = 'pass',
    fail = 'fail',
    not_yet = 'not_yet',
    skip = 'skip',
}
