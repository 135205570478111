import { Icon12Chevron } from '@vkontakte/icons';
import React, { FC } from 'react';

import styles from './BackButton.css';

export interface BackButtonProps {
    id: string;
    hook: () => {
        onClose: () => void;
    };
}

export const BackButton: FC<BackButtonProps> = ({ id, hook }) => {
    const { onClose } = hook();
    return <Icon12Chevron id={id} data-qa-id={id} className={styles.icon} width={12} height={12} onClick={onClose} />;
};
