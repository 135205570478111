import classNames from 'clsx';
import { sanitize } from 'dompurify';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';
import { UserDomesticDropdown } from 'reactApp/components/SharingNewBiz/DomesticDropdown/UserDomesticDropdown';
import { sendGa } from 'reactApp/components/SharingNewBiz/SharingNew.helpers';
import { DomainAccess } from 'reactApp/components/SharingNewBiz/SharingNew.types';
import { Contact, contactsSlice } from 'reactApp/modules/contacts/contacts.module';
import { contactsSelectors } from 'reactApp/modules/contacts/contacts.selectors';
import { getFeaturePublicDomestic } from 'reactApp/modules/features/features.selectors';
import { setAccessRight } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.actions';
import { getError, isAccessControlListLoading } from 'reactApp/modules/folderAccessControlList/folderAccessControlList.selectors';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import { Button } from 'reactApp/ui/Button/Button';
import { InputSuggest } from 'reactApp/ui/InputSuggest/InputSuggest';
import { EInputSuggestTheme } from 'reactApp/ui/InputSuggest/InputSuggest.types';
import { ECategoryGa, sendPaymentGa } from 'reactApp/utils/paymentGa';

import styles from './SharingNewAddUser.css';
import { useAccessRights, useSuggestionItem } from './SharingNewAddUser.hooks';
import { IProps } from './SharingNewAddUser.types';

// нужно добавить проверку на доместик и подождать intra_domain_share
export const SharingNewAddUser = React.memo(function SharingNewAddUser(props: IProps): ReactElement {
    const { folderId = '', publicId, isPhone, isMounted, domain, isAlbum, onCleanupExternalUsers } = props;

    const dispatch = useDispatch();
    const errorMessage = useSelector(getError);
    const tempSuggest = useSelector(contactsSelectors.getContactSuggestions);
    const [checked, setChecked] = useState<DomainAccess>(props.item?.intraDomainShare ? DomainAccess.DOMESTIC : DomainAccess.EXTERNAL);
    const contactSuggestions =
        checked === DomainAccess.DOMESTIC && tempSuggest
            ? tempSuggest.filter((el: Contact) => {
                  return el.email.includes(domain || '');
              })
            : tempSuggest;
    const loading = useSelector(isAccessControlListLoading);
    const isInputDisabled = loading || isMounted;
    const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);
    const [currentEmail, setCurrentEmail] = useState<string>('');
    const isBizUser = useSelector(UserSelectors.isBizUser);
    const isCorpUser = useSelector(UserSelectors.isCorpUser);
    const isDomesticFeatureEnabled = useSelector(getFeaturePublicDomestic);
    const showUserDomesticSettings = !isAlbum && (isBizUser || isCorpUser) && Boolean(isDomesticFeatureEnabled);
    const whitelistDomains = checked === DomainAccess.DOMESTIC ? [domain] : undefined;

    const [accessRight, renderDropdown] = useAccessRights(isInputDisabled);
    const [renderItem, handleGetSuggestions] = useSuggestionItem();

    const handleLoginChange = useCallback((e, { value }) => {
        setCurrentEmail(value);
        setButtonDisabled(!value.length);
    }, []);

    const handleAccessSelection = useCallback(
        (id: DomainAccess) => {
            setChecked(id);

            if (id === DomainAccess.DOMESTIC) {
                onCleanupExternalUsers();
            }
        },
        [onCleanupExternalUsers]
    );

    const handleAddUser = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            dispatch(setAccessRight({ accessRight, folderId, email: currentEmail, additionalWhitelistDomains: whitelistDomains }));
            dispatch(contactsSlice.actions.listRequest());
            setCurrentEmail('');

            sendGa('add-user');
            sendPaymentGa({
                eventCategory: publicId ? ECategoryGa.public : ECategoryGa.sharedFolder,
                action: 'created-sharing',
                public_id: publicId,
            });

            event.preventDefault();
        },
        [dispatch, accessRight, folderId, currentEmail, whitelistDomains, publicId]
    );

    const action = useMemo(() => <div className={styles.dropdown}>{renderDropdown()}</div>, [renderDropdown]);

    return (
        <>
            <form
                className={classNames(styles.root, {
                    [styles.root_phone]: !IS_MY_TEAM && isPhone,
                    [styles.root_phone_vkt]: IS_MY_TEAM && isPhone,
                })}
            >
                <div className={styles.inputRow}>
                    <div className={styles.inputField}>
                        <InputSuggest
                            value={currentEmail}
                            placeholder="Введите ФИО или почту"
                            hasError={Boolean(errorMessage)}
                            suggestions={contactSuggestions}
                            getSuggestionItemId={(item) => item.email}
                            onChange={handleLoginChange}
                            onRequestSuggestions={handleGetSuggestions}
                            disabled={isInputDisabled}
                            focus={false}
                            action={isPhone ? undefined : action}
                            theme={isPhone ? EInputSuggestTheme.vk : undefined}
                            renderSuggestionItem={renderItem}
                            className={styles.inputSuggesst}
                            inputClassName={styles.input}
                        />
                    </div>
                    <div className={styles.buttonWrapper}>
                        <div className={styles.action}>{isPhone ? action : undefined}</div>
                        <Button
                            theme={isPhone ? 'vk' : 'octavius'}
                            primary
                            middle
                            disabled={isInputDisabled || isButtonDisabled}
                            onClick={handleAddUser}
                            className={styles.button}
                        >
                            Пригласить
                        </Button>
                    </div>
                </div>
                {Boolean(errorMessage) && (
                    <div className={styles.errorMessage} dangerouslySetInnerHTML={{ __html: sanitize(errorMessage) }} />
                )}
            </form>
            {showUserDomesticSettings && (
                <UserDomesticDropdown
                    item={props.item}
                    onChecked={handleAccessSelection}
                    checked={checked}
                    domain={domain}
                    disabled={props.item.intraDomainShare}
                />
            )}
        </>
    );
});
