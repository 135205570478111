import axios from 'axios';
import { xray } from 'lib/xray';
import { AF_API_HOST, downloadDocumentApi } from 'reactApp/api/axios.corsapi';
import { SDCAPICall } from 'reactApp/api/methods/SDCAPICall';

import { sendMyofficeGa } from './sendMyofficeGa';

export const downloadDocumentMail = async (url?: string): Promise<{ content?: Nullable<ArrayBuffer>; error?: unknown }> => {
    if (!url) {
        return {};
    }

    try {
        const { data: content } = await downloadDocumentApi(url);

        sendMyofficeGa('download-success');
        return { content };
    } catch (error) {
        sendMyofficeGa('download-error');
        return { error };
    }
};

/**
 * В случае отсутствия sdc-куки почты или истечении срока ее жизни перезапрашиваем ее
 *
 * Запрос возвращает 302 и кидает ошибку (так как не 200), поэтому результат нам не важен
 *
 */
const updateMailSDCCookies = async () => {
    try {
        await new SDCAPICall({ error: { requestUrl: 'https://e.mail.ru', skipCurrentLocation: true } }).makeRequest();
    } catch {}
};

const getAfUrl = async (attachUrl: string): Promise<string | undefined> => {
    try {
        const { data: loadAttachInfo } = await axios.get<string[]>(attachUrl, {
            params: {
                // Этот параметр запрещает редирект при походе на /getattach
                // Нам это нужно, чтобы самостоятельно сходить на af-ы (следующий запрос)
                // В противном случае не прокидывается заголовок Origin
                ajax_call: '1',
            },
            withCredentials: true,
        });

        return loadAttachInfo.find((param) => param.includes(AF_API_HOST));
    } catch {
        return undefined;
    }
};

export const downloadDocumentWithoutRedirect = async (
    url: string,
    isOvidius?: boolean
): Promise<{ content?: ArrayBuffer; error?: unknown }> => {
    if (!url) {
        return {};
    }

    try {
        let afUrl = await getAfUrl(url);
        if (!afUrl) {
            if (isOvidius) {
                xray.send(`ovidius_sdc_error`);
            }

            await updateMailSDCCookies();

            const newAfUrl = await getAfUrl(url);
            // если и после перезапроса не получилось достать url, возвращаем ошибку
            if (!newAfUrl) {
                return { error: 'no_sdc' };
            }
            afUrl = newAfUrl;
        }

        const { data: content } = await axios.get(afUrl, {
            withCredentials: true,
            responseType: 'arraybuffer',
        });

        return { content };
    } catch (error) {
        return { error };
    }
};
