import { RateLimitAPICall } from 'reactApp/api/RateLimitAPICall';
import { IS_ONPREMISE } from 'reactApp/appHelpers/configHelpers';

import { MY_OFFICE_MODE } from '../myOffice.constants';
import { sendMyofficeGa } from './sendMyofficeGa';
import { updateEditorHeaderLicenseExpired } from './updateEditorHeaderLicenseExpired';

export const rateLimitApiCall = () => new RateLimitAPICall().makeRequest();

export const getModeByRateLimit = async (mode) => {
    if (!IS_ONPREMISE) {
        return MY_OFFICE_MODE[mode];
    }

    if (mode === 'view') {
        return MY_OFFICE_MODE.view;
    }

    try {
        const data = await rateLimitApiCall();
        const access = data.data?.access;

        if (!access) {
            sendMyofficeGa('license-expired');
            updateEditorHeaderLicenseExpired();
        }

        return access ? MY_OFFICE_MODE.edit : MY_OFFICE_MODE.view;
    } catch (error) {
        return MY_OFFICE_MODE.view;
    }
};

export const rateLimitUpdate = async (mode) => {
    if (!IS_ONPREMISE || mode === MY_OFFICE_MODE.view) {
        return;
    }

    await rateLimitApiCall();
};
