import classNames from 'clsx';
import React, { FC } from 'react';
import { Hint } from 'reactApp/ui/Hint/Hint';

import styles from './WebSearchSource.css';

interface SearchSourceProps {
    query: string;
    className?: string;
}

export const WebSearchSource: FC<SearchSourceProps> = ({ className, query }) => (
    <Hint text={`Содержит ...${query}...`}>
        <div className={classNames(styles.source, className)}>
            <span className={styles.contains}>Содержит</span> ...<mark className={styles.match}>{query}</mark>...
        </div>
    </Hint>
);

WebSearchSource.displayName = 'WebSearchSource';
