import { Icon20ChevronUpOutline } from '@vkontakte/icons';
import { Button, ButtonProps, SizeType } from '@vkontakte/vkui';
import classNames from 'clsx';
import React from 'react';
import {
    SearchFileTypeOption,
    SearchOptionType,
} from 'reactApp/sections/AlbumsPage/SelectFromCloudPopup/components/SelectFromCloudSearch/SelectFromCloudSearch.types';
/*
    TODO: заменить на vkui когда будет добавлена в библиотеку
    https://github.com/VKCOM/icons/issues/285
 */
import { DropdownOutlineIcon } from 'reactApp/ui/VKUIIcons';

import styles from './TypeSelectButton.css';

interface IProps extends ButtonProps {
    searchOption?: SearchFileTypeOption;
    isOpen: boolean;
}

export const TypeSelectButton = React.forwardRef<HTMLButtonElement, IProps>(({ searchOption, isOpen, onClick }, ref) => (
    <Button
        className={classNames(styles.button, { [styles.selected]: Boolean(searchOption?.id !== SearchOptionType.media) })}
        hasHover={false}
        getRootRef={ref}
        size="l"
        mode={searchOption ? 'secondary' : 'primary'}
        appearance={searchOption ? 'accent' : 'neutral'}
        sizeY={SizeType.COMPACT}
        onClick={onClick}
        after={isOpen ? <Icon20ChevronUpOutline className={styles.icon} /> : <DropdownOutlineIcon className={styles.icon} />}
    >
        {searchOption ? searchOption.text : 'Фильтр'}
    </Button>
));

TypeSelectButton.displayName = 'TypeSelectButton';
