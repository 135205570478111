import { APIConfig } from 'reactApp/api/APIConfig';

import { jsonp } from './jsonp';

class SDCAPICall {
    _method = 'sdc';
    _type = 'jsonp';

    constructor({ error }) {
        this.error = error;
    }

    makeRequest() {
        return new Promise((resolve, reject) => {
            const { protocol, host } = window.location;
            const { requestUrl, skipCurrentLocation } = this.error;
            const authDomain = APIConfig.get('auth-domain');

            const from = skipCurrentLocation ? requestUrl : `${protocol}//${host}${requestUrl}`;

            jsonp(
                `${authDomain}sdc?from=${encodeURIComponent(`${from}`)}`,
                () => {
                    resolve();
                },
                () => {
                    reject(this.error);
                }
            );
        });
    }
}

export { SDCAPICall };
