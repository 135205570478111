import { addYears } from 'date-fns';
import { filter, path, propEq, sortBy, values } from 'ramda';
import { createSelector } from 'reselect';

const getPurchasedGifts = (state) => state.purchasedGifts.list;

export const sortPurchasedGiftsByCreatedDate = createSelector(getPurchasedGifts, (list) => sortBy(path(['created']), values(list)));
export const getGiftByCode = createSelector(
    getPurchasedGifts,
    (state, code) => code,
    (list, code) => list[code]
);
export const getNonActivatedGifts = createSelector(sortPurchasedGiftsByCreatedDate, (gifts) => filter(propEq('isGifted', false), gifts));
export const getNonActivatedNonExpiredGifts = createSelector(sortPurchasedGiftsByCreatedDate, (gifts) =>
    gifts.filter((gift) => {
        const expires = path(['expires'], gift) || addYears(path(['created'], gift), 2).valueOf();
        return !path(['isGifted'], gift) && expires > Date.now();
    })
);

export const getLastPurchasedGiftById = createSelector(
    (state, giftId) => giftId,
    sortPurchasedGiftsByCreatedDate,
    (giftId, list) => list.find((gift) => gift.id === giftId)
);
