import { isTempAttach, parseCloudViewerUrl } from '@mail/cross-url-sharing';
import { captureException } from '@sentry/browser';
import * as api2 from 'Cloud/Application/api';
import Config from 'Cloud/config';
import { logger } from 'lib/logger';
import { xray } from 'lib/xray';
import { parse } from 'qs';
import { getMessage, getTemporaryMessageAttaches } from 'reactApp/api/axios.corsapi';
import * as api from 'reactApp/api/axios.corsapi';
import { IS_BLOCKED, IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';
import {
    attachesChangePath,
    attachesFetchFailure,
    attachesFetchRequest,
    attachesFetchSuccess,
    attachesLoadMoreRequest,
    attachesOpenViewer,
    attachesPageStart,
    attachesViewerLoadMore,
    attachesViewerSuccess,
    attachFoldersRequest,
    failureFolders,
    requestFoldersStart,
    successFolders,
} from 'reactApp/modules/attaches/attaches.actions';
import { getSidesMessages, hasMoreToLoad } from 'reactApp/modules/attaches/attaches.helpers';
import { AttachesSelectors } from 'reactApp/modules/attaches/attaches.selectors';
import { AttachesItem } from 'reactApp/modules/attaches/attaches.types';
import { MemoryCache } from 'reactApp/modules/cache/MemoryCache';
import {
    getAttachesCacheTtlMs,
    getEmlIdRegExp,
    getFeatureAttachesIdRegExps,
    getFeatureMessagesIdRegExps,
} from 'reactApp/modules/features/features.selectors';
import { AttachFileBuilder } from 'reactApp/modules/file/AttachFileBuilder';
import { routeChangeSuccess } from 'reactApp/modules/router/router.module';
import { getCurrentRouteId } from 'reactApp/modules/router/router.selectors';
import { searchRequestParamsUpdate } from 'reactApp/modules/search/search.module';
import { setQueryParams } from 'reactApp/modules/settings/settings.module';
import { SettingsSelectors } from 'reactApp/modules/settings/settings.selectors';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { UserSelectors } from 'reactApp/modules/user/user.selectors';
import {
    closeViewer,
    openMobileViewer as openMobileViewerAction,
    openViewer,
    updateViewerData,
} from 'reactApp/modules/viewer/viewer.module';
import { handleUpdateViewerData } from 'reactApp/modules/viewer/viewer.saga';
import { ViewerSelectors } from 'reactApp/modules/viewer/viewer.selectors';
import { sendKaktamLog, sendXray } from 'reactApp/utils/ga';
import { promisifyDeferredCall } from 'reactApp/utils/helpers';
import { call, cancel, fork, put, select, takeEvery } from 'redux-saga/effects';

const cache = new MemoryCache();
const attachFileBuilder = new AttachFileBuilder();

let loadingMessages: string[] = [];

/*
     Подгружает для просмотрщика все аттачи данного письма с инфой о письме
 */
function* viewerAttachesLoad({ itemId, messageId }) {
    if (!itemId) {
        return;
    }

    try {
        if (messageId && !loadingMessages.includes(messageId)) {
            const { temporaryMessageIdReg } = yield select(getFeatureMessagesIdRegExps);
            const isTempAttachItem = isTempAttach(messageId, temporaryMessageIdReg);

            loadingMessages.push(messageId);
            const email = yield select(UserSelectors.getEmail);

            const folderId = parse(window.location.search, { ignoreQueryPrefix: true })['folder-id'];
            const response = isTempAttachItem
                ? yield call(getTemporaryMessageAttaches, { messageId, email })
                : yield call(getMessage, { messageId, email, folderId });

            if (!response) {
                sendXray(['attaches', 'saga', 'nomessagefound']);
                sendKaktamLog(
                    {
                        itemId,
                        location: window.location,
                        messageId,
                        isTempAttachItem,
                        text: 'no message from api for this message_id',
                    },
                    'cloud_attaches_saga'
                );
                yield put(closeViewer());
                return;
            }

            let attaches = [];
            const list = isTempAttachItem ? response : response?.attaches?.list;

            if (Array.isArray(list)) {
                attaches = list
                    .map((item) => ({
                        ...item,
                        id: `${messageId};${item.id}`,
                        message_id: messageId,
                        folder_id: folderId ?? response?.folder,
                        storage: EStorageType.viewerAttaches,
                    }))
                    .reduce((acc, item): Record<string, AttachesItem> => {
                        return {
                            ...acc,
                            [item.id]: {
                                ...attachFileBuilder.prepareFile(
                                    isTempAttachItem
                                        ? item
                                        : {
                                              ...item,
                                              subject: response.subject,
                                              authorEmail: response.email,
                                              from: response.correspondents?.from,
                                              time: response.date,
                                          },
                                    isTempAttachItem,
                                    true
                                ),
                            },
                        };
                    }, {});
            }

            yield put(attachesViewerSuccess({ attaches, messageId }));

            loadingMessages = loadingMessages.filter((item) => item !== messageId);

            return attaches;
        }
    } catch (error) {
        logger.error(error);
        captureException(error);
        yield cancel();
    }
}

function* handleViewerAttachesLoadMore(action) {
    const { itemId, ids } = action.payload;

    if (!itemId || !ids) {
        return;
    }

    try {
        const { before, after } = getSidesMessages(itemId, ids);

        const loadedMsgIds = yield select(AttachesSelectors.getLoadedMessagesIds);

        if (before && !loadedMsgIds.includes(before.messageId)) {
            yield fork(viewerAttachesLoad, { messageId: before.messageId, itemId: before.itemId });
        }
        if (after && !loadedMsgIds.includes(after.messageId)) {
            yield fork(viewerAttachesLoad, { messageId: after.messageId, itemId: after.itemId });
        }
    } catch (error) {
        logger.error(error);
        captureException(error);
        yield cancel();
    }
}

function* getIdsFromUrl(url: string) {
    const attachesRegExps = yield select(getFeatureAttachesIdRegExps);
    const messageIdsRegExps = yield select(getFeatureMessagesIdRegExps);
    const emlFormatId = yield select(getEmlIdRegExp);

    const regExps = {
        attachesRegExps,
        messageIdsRegExps,
        emlFormatId,
    };

    // Парсим урл
    const { result, error } = parseCloudViewerUrl(url, regExps);

    let messageId, encodedAttachId;
    if (error) {
        const parsedResult = yield select(getCurrentRouteId);
        messageId = parsedResult.messageId;
        encodedAttachId = parsedResult.partId;

        /** Посылаем радар о неуспешном парсинге урла только когда есть messageId, так как наличие
         error может означать также что это урл 'https://cloud.mail.ru/attaches/?{get params}'
         не на просмотр конкретного аттача.
         Чтобы определить, что был фейл был при парсинге именно урла на облачный просмотрщик с аттачом,
         можно проверять messageId (аналогичная проверка в handleAttachesPageStart в attaches.saga.ts) */
        if (messageId) {
            xray.send('parse_viewer_url_fail', {
                i: error,
                rlog: 'parse_viewer_url_fail',
                rlog_message: {
                    project: 'cloud.mail.ru',
                    url,
                    error,
                },
            });
        }
    } else {
        messageId = result?.messageId;
        encodedAttachId = result?.encodedAttachId;

        xray.send('parse_viewer_url_ok');
    }

    // Normalize messageId/attachId to form 'messageId;attachId'.
    const initialId = messageId ? `${messageId};${encodedAttachId}` : '';

    return { initialId, messageId };
}

function* handleLoadMessageAttachesAndOpenViewer(id: string, keepPreviousData = false) {
    const { initialId, messageId } = yield getIdsFromUrl(id);

    try {
        if (!initialId) {
            return;
        }

        const { fromCloud } = yield select(SettingsSelectors.getQueryParams);

        if (messageId) {
            const viewerData = {
                itemId: initialId,
                itemStorage: EStorageType.viewerAttaches,
                gaSuffix: '',
                itemIds: [],
                fromCloud,
            };
            if (!IS_PHONE_BROWSER) {
                yield put(openViewer(viewerData));
            }

            const attaches = yield viewerAttachesLoad({ itemId: initialId, messageId });

            if (!attaches) {
                yield put(closeViewer());
                return;
            }

            if (IS_PHONE_BROWSER) {
                yield put(openMobileViewerAction({ id: initialId, storage: EStorageType.viewerAttaches }));
            } else {
                const viewerItem = yield select(ViewerSelectors.getViewerItem);

                yield handleUpdateViewerData({
                    payload: {
                        ...viewerData,
                        keepPreviousData: viewerItem?.id === initialId ? true : keepPreviousData,
                    },
                });
            }
        }
    } catch (error) {
        sendXray(['attaches', 'saga', 'startexception']);
        sendKaktamLog({ initialId, text: 'exception for the id' }, 'cloud_attaches_saga');
        logger.error(error);
        captureException(error);
        yield cancel();
    }
}

function* handleAttachesPageStart() {
    yield call(handleLoadMessageAttachesAndOpenViewer, window.location.href);
}

/*
    Подгружает список аттачей для даталиста и догружает до текущего аттача в просм-ке, если он открыт.
    Также отвечает за дозагрузку аттачей при скролле.
 */
function* handleAttachesLoad(action) {
    const { ...params } = action.payload;

    try {
        const { fromCloud } = yield select(SettingsSelectors.getQueryParams);

        yield put(attachesFetchRequest(params));

        const ttl = yield select(getAttachesCacheTtlMs);
        const key = `getattaches${JSON.stringify(params)}`;
        const apiResponse = yield call(cache.getOrAdd, key, () => api.getAttaches(params), ttl);
        const {
            headers,
            data: { body },
        } = apiResponse;

        const attaches =
            body?.attaches && Array.isArray(body.attaches)
                ? body.attaches.reduce(
                      (acc, item, pos): Record<string, AttachesItem> => ({
                          ...acc,
                          [item.id]: {
                              ...attachFileBuilder.prepareFile(item),
                              pos,
                          },
                      }),
                      {}
                  )
                : {};

        yield put(
            searchRequestParamsUpdate({
                xPageId: Config.get('x-page-id'),
                xReqId: headers?.['x-mru-request-id'],
                query: params.query || '',
            })
        );
        yield put(
            attachesFetchSuccess({
                attaches,
                hasMoreToLoad: hasMoreToLoad(body?.attaches),
                after: body?.after,
            })
        );
        const initialItemId = yield select(ViewerSelectors.getViewerItemId);

        if (fromCloud && initialItemId) {
            const isLoadViewerFile = !!body?.attaches.find((item) => item.id === initialItemId);
            if (isLoadViewerFile || !body?.after) {
                if (!IS_PHONE_BROWSER) {
                    yield put(
                        updateViewerData({
                            itemId: initialItemId,
                            itemStorage: EStorageType.viewerAttaches,
                            gaSuffix: '',
                            itemIds: [],
                            fromCloud,
                        })
                    );
                }

                yield fork(handleViewerAttachesLoadMore, {
                    payload: {
                        itemId: initialItemId,
                        ids: Object.keys(attaches),
                    },
                });
            } else if (body?.after) {
                yield fork(handleAttachesLoad, { payload: { ...params, after: body.after } });
            }
        }
    } catch (error) {
        logger.error(error);
        captureException(error);
        yield put(attachesFetchFailure(error));
        yield cancel();
    }
}

// При открытии аттача из даталиста, нужно сходить в ручку для дополнения описания всех аттачей этого письма (например, урлы редактирования доков).
function* handleAttachesOpenViewer(action) {
    const id = `${window.location.origin}/attaches/${encodeURIComponent(action.payload.id)}`;
    const { query = '' } = yield select(SettingsSelectors.getQueryParams);

    yield put(
        setQueryParams({
            query,
            fromCloud: true,
            type: action.payload.attachType,
            folderId: action.payload.folderId,
        })
    );

    yield call(handleLoadMessageAttachesAndOpenViewer, id, true);

    // Догружаем аттачи из примыкающих слева+справа писем в тор аттачей просмотрщика
    const itemId = yield select(ViewerSelectors.getViewerItemId);
    const ids = yield select(ViewerSelectors.getViewerItemIds);

    yield call(handleViewerAttachesLoadMore, { payload: { itemId, ids } });
}

function* handleAttachChangePath(action) {
    const url = `${window.location.origin}${action.payload.path}`;

    const { initialId: id } = yield getIdsFromUrl(url);

    if (!id) {
        return;
    }

    if (IS_BLOCKED) {
        // на аттачах для блокированных Б2Б юзеров показываем просмотр аттачей, а саму страницу аттачей нет, потому дергаем этот экшен тут
        yield call(handleAttachesPageStart);
    }

    yield put(
        routeChangeSuccess({
            id,
            storage: EStorageType.attaches,
            params: {},
            __isFolder: true,
            url: '',
            __parent: '',
        })
    );
}

export function* handleAttachFoldersRequest() {
    const ttl = yield select(getAttachesCacheTtlMs);
    const key = `getfolders`;

    yield put(requestFoldersStart());

    try {
        const response = yield call(cache.getOrAdd, key, () => promisifyDeferredCall(api2.mail.user.folders), ttl);

        yield put(successFolders(response));
    } catch (error) {
        yield put(failureFolders(error as string));
    }
}

export function* watchAttchesRoot() {
    yield takeEvery(attachesViewerLoadMore.toString(), handleViewerAttachesLoadMore);
    yield takeEvery(attachesPageStart.toString(), handleAttachesPageStart);
    yield takeEvery(attachesLoadMoreRequest.toString(), handleAttachesLoad);
    yield takeEvery(attachesOpenViewer.toString(), handleAttachesOpenViewer);
    yield takeEvery(attachesChangePath.toString(), handleAttachChangePath);
    yield takeEvery(attachFoldersRequest.toString(), handleAttachFoldersRequest);
}
