export enum MediaTypes {
    PHONE = 'phone',
    TABLET = 'tablet',
    PHONE_SMALL = 'phoneSmall',
    DESKTOP_SMALL = 'desktopSmall',
    DESKTOP_MEDIUM = 'desktopMedium',
}

export interface MediaState {
    isMobile: boolean;
    isPhone: boolean;
    isPhoneSmall: boolean;
    isTablet: boolean;
    isDesktop: boolean;
    isDesktopSmall: boolean;
    isDesktopMedium: boolean;
    windowWidth: number;
}

export interface IActionType {
    type: MediaTypes;
    windowWidth: number;
}
