import { Icon20MoreVertical } from '@vkontakte/icons';
import classNames from 'clsx';
import React, { memo, MouseEvent, ReactElement, RefObject } from 'react';

import styles from './MenuButton.css';

export interface Props {
    menuRef: RefObject<HTMLDivElement>;
    isShowMenu: boolean;
    closeMenu: (event?: MouseEvent) => void;
    showMenu: () => void;
}

export const MenuButton = memo(
    ({ menuRef, closeMenu, showMenu, isShowMenu, marginRight }: Props & { marginRight?: number }): ReactElement => (
        <div
            className={classNames(styles.menuIcon, {
                [styles.menuIcon_active]: isShowMenu,
            })}
            ref={menuRef}
            onClick={isShowMenu ? closeMenu : showMenu}
            style={!marginRight ? {} : { marginRight: marginRight * -1 }}
        >
            <Icon20MoreVertical />
        </div>
    )
);

MenuButton.displayName = 'MenuButton';
