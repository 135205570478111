import React, { forwardRef, RefObject, useCallback, useEffect, useState } from 'react';
import { Portal } from 'react-portal';
import { useSelector } from 'react-redux';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { PromoSelectors } from 'reactApp/modules/promo/promo.selectors';
import { EPromoType } from 'reactApp/modules/promo/promo.types';
import { ECloudNewFeaturesActions, sendCloudNewFeaturesAnalytics } from 'reactApp/ui/CloudNewFeatures/CloudNewFeatures.analytics';
import { FloatingTooltip } from 'reactApp/ui/FloatingTooltip/FloatingTooltip';
import { ETooltipPlacement, ETooltipSize } from 'reactApp/ui/FloatingTooltip/FloatingTooltip.types';

export const FeaturesTooltip = forwardRef<HTMLDivElement>((_, ref) => {
    const [show, setShow] = useState(false);
    const isPhone = useSelector(EnvironmentSelectors.isPhone);
    const promoType = isPhone ? EPromoType.newFeaturesMobile : EPromoType.newFeaturesDesktop;
    const newFeaturesPromo = useSelector(PromoSelectors.getPromo(promoType));

    useEffect(() => {
        if (newFeaturesPromo) {
            setShow(true);
            newFeaturesPromo?.onShow();
            sendCloudNewFeaturesAnalytics({ action: ECloudNewFeaturesActions.showNews });
        }
    }, [newFeaturesPromo]);

    const handleClose = useCallback(() => {
        setShow(false);
        newFeaturesPromo?.onClose();
    }, [setShow, newFeaturesPromo]);

    if (!show) {
        return null;
    }

    return (
        <Portal>
            <FloatingTooltip
                target={ref as RefObject<HTMLDivElement>}
                placement={ETooltipPlacement.bottomEnd}
                size={ETooltipSize.medium}
                title="Что нового в Облаке"
                text="Полезные обновления Облака, которые вам могут пригодиться"
                onClose={handleClose}
                closeOnScroll
                qaId="tooltip-features"
            />
        </Portal>
    );
});

FeaturesTooltip.displayName = 'FeaturesTooltip';
