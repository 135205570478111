import { Space } from '@mail/cross-sizes-utils';
import React, { memo, ReactElement, useMemo } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import { BASE_ACTIVATE_URL } from 'reactApp/ui/GiftPromoCodeModal/GiftPromoCodeModal';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';

import styles from './GiftPromocodeModal.css';

interface IProps {
    code: string;
    onClose: () => void;
    space: Space;
    giftId: string;
}

export const GiftPromocodeModal = memo<IProps>(function GiftPromocodeModal(props): ReactElement {
    const { onClose, code, space, giftId } = props;

    const footer = useMemo(
        () => (
            <div>
                <Button primary theme="vk" fluid target="_blank" href={`${BASE_ACTIVATE_URL}${code}&quota=${space.original}`}>
                    Активировать подарок
                </Button>
            </div>
        ),
        [code, space.original]
    );

    return (
        <MobileDialog
            id="gift-promocode-modal"
            open
            dimmer
            onClose={onClose}
            disableScrolling
            closable
            closeOnDimmerClick
            mod="confirm"
            topmost
            footer={footer}
            className={giftId.includes('newyear2022') ? styles.root_newYear : undefined}
        >
            <div className={styles.root}>
                <div className={styles.title}>
                    Ваш подарок +{space.value}
                    <br /> Облаке
                </div>
                <div>Вы можете активировать подарок сами или поделиться с вашими друзьями и близкими</div>
            </div>
        </MobileDialog>
    );
});
