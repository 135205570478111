import { APICallV2Post } from 'reactApp/api/APICall';
import { FeedApiResponse } from 'reactApp/modules/feed/feed.types';

export interface IDocumentsApiArgs {
    limit?: number;
    cursor?: string;
    exts?: string;
}

export class DocumentsApiCall extends APICallV2Post<{ body: FeedApiResponse }> {
    _method = 'feed';
}
