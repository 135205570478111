import { AxiosInstance, Method } from 'axios';
import { APIConfig } from 'reactApp/api/APIConfig';
import { UserNoAuthError } from 'reactApp/errors/serverErrors/UserNoAuthError';

import { AbstractAPICall } from './AbstractAPICall';
import { getAxiosInstance } from './axiosFactory';
import { SDCAPICall } from './methods/SDCAPICall';
import { TokensCSRFAPICall } from './methods/TokensCSRFAPICall';

function error403(error) {
    if (error instanceof UserNoAuthError && !error.isNotAuthorizedError()) {
        const api = error.isCSRFTokenError() ? new TokensCSRFAPICall() : new SDCAPICall({ error });
        return api.makeRequest();
    }

    throw error;
}

class APICall<T = any> extends AbstractAPICall<T> {
    private _axios: AxiosInstance | null = null;

    protected _defaultPayload = {
        'x-email': APIConfig.get('user') || 'anonym',
    };

    _errorHandlers = [error403];

    _abstractGetAxiosInstance(): AxiosInstance | null {
        if (this._axios === null) {
            this._axios = getAxiosInstance(this.version);
        }

        return this._axios;
    }
}

class APICallV0<T = any> extends APICall<T> {}

class APICallV1<T = any> extends APICall<T> {
    version = 1;
}

class APICallV2<T = any> extends APICall<T> {
    version = 2;
}

class APICallV2Get<T = any> extends APICallV2<T> {
    _type: Method = 'get';
    defaultParams = this._defaultPayload;
}

class APICallV2Post<T = any> extends APICallV2<T> {
    _type: Method = 'post';
    defaultData = this._defaultPayload;
}

class APICallV3<T = any> extends APICall<T> {
    version = 3;
}

class APICallV3Get<T = any> extends APICallV3<T> {
    _type: Method = 'get';
    defaultParams = this._defaultPayload;
}

class APICallV3Post<T = any> extends APICallV3<T> {
    _type: Method = 'post';
    defaultData = this._defaultPayload;
}

class APICallV3Put<T = any> extends APICallV3<T> {
    _type: Method = 'put';
}

class APICallV4<T = any> extends APICall<T> {
    version = 4;
}

export { APICallV0, APICallV1, APICallV2Get, APICallV2Post, APICallV3Get, APICallV3Post, APICallV3Put, APICallV4 };
