import { getFeatureByName } from 'server/helpers/features/getFeatureByName';
import { IFeatures } from 'server/helpers/features/getFeatures';
import { IUser } from 'server/helpers/getUser';
import { EEditorUserType } from 'server/types/context/IOnlineConf';
import { IOwner } from 'server/types/context/IPublicFolder';
import { EAccountType } from 'server/types/context/IUser';

interface IGetUserGroupArgs {
    user: IUser;
    ownerUserGroup?: EEditorUserType;
    isEditPublic: boolean;
    features: IFeatures;
    isPublic: boolean;
}

export const getOwnerUserGroup = (owner: IOwner) => {
    switch (owner.account_type) {
        case EAccountType.PDD:
            return EEditorUserType.BIZ_USER;
        case EAccountType.REGULAR:
        case EAccountType.SOCIAL:
        default:
            return EEditorUserType.MAIL_USER;
    }
};

export const getUserGroup = ({ user, ownerUserGroup, isEditPublic, features, isPublic }: IGetUserGroupArgs): EEditorUserType => {
    const isr7EditorsFeatureEnabled = getFeatureByName(features, 'r7Editors') && !user.isBizUser;

    /**
     * В приоритетном порядке проверяем сначала редакторы с совместным редактированием в паблике
     * а уже потом все остальные
     *
     * для каждого из редакторов, проверяем группу владельца документа, и возвращаем такую-же
     */
    if (isPublic && ownerUserGroup === EEditorUserType.BIZ_USER) {
        return EEditorUserType.BIZ_USER;
    } else if (isPublic && ownerUserGroup === EEditorUserType.MAIL_USER) {
        return EEditorUserType.MAIL_USER;
    }

    if (user.testUser || getFeatureByName(features, 'test-editors')) {
        return EEditorUserType.TEST_USER;
    }

    if (user.isCorp) {
        return EEditorUserType.CORP_USER;
    }

    if (user.msUser) {
        return EEditorUserType.MS_USER;
    }

    if (user.isPaidB2bBizUser) {
        return EEditorUserType.PAID_BIZ_USER;
    }

    if (user.isBizUser) {
        return EEditorUserType.BIZ_USER;
    }

    if (isr7EditorsFeatureEnabled || isEditPublic) {
        return EEditorUserType.R7_USER;
    }

    if (user.isMailUser) {
        return EEditorUserType.MAIL_USER;
    }

    return EEditorUserType.EXTERNAL_USER;
};
