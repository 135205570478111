import { Spacing } from '@vkontakte/vkui';
import React, { ReactElement } from 'react';
import { Button } from 'reactApp/ui/Button/Button';
import { noopVoid } from 'reactApp/utils/helpers';

import styles from './SaveSubscriptionTopBanner.css';

export const SaveSubscriptionTopBanner = ({ isTransit }: { isTransit: boolean }): ReactElement => (
    <div className={styles.root}>
        <div className={styles.info}>
            <div className={styles.title}>Не {isTransit ? 'потеряйте свой' : 'упустите'} выгодный тариф</div>
            <Spacing size={4} />
            <div className={styles.text}> {isTransit ? 'Продлите' : 'Подключите'} тариф по выгодной цене и получите подарок</div>
        </div>
        <div className={styles.button}>
            <Button className={styles.button} theme="vk" primary onClick={noopVoid}>
                {isTransit ? 'Продлить' : 'Подключить'}
            </Button>
        </div>
    </div>
);
