import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentStorage } from 'reactApp/modules/router/router.selectors';
import { getSearchRequestParams } from 'reactApp/modules/search/search.selectors';
import { EActionSearch } from 'reactApp/modules/search/search.types';
import { RootState } from 'reactApp/store';
import { ESearchOptionSource, ESearchOptionType } from 'reactApp/ui/WebSearch/WebSearch.data';
import { WebSearchResetFiltersButton, WebSearchResetFiltersButtonProps } from 'reactApp/ui/WebSearch/WebSearchResetFiltersButton';
import { sendDwh } from 'reactApp/utils/ga';
import { ECategoryGa } from 'reactApp/utils/paymentGa';

interface ResetWebSearchFiltersButtonProps extends WebSearchResetFiltersButtonProps {
    hook: () => {
        type: ESearchOptionType;
        source: ESearchOptionSource;
        onReset: (force?: boolean) => void;
    };
}

export const ResetWebSearchFiltersButton: FC<ResetWebSearchFiltersButtonProps> = ({ hook }) => {
    const { type, source, onReset } = hook();

    const storage = useSelector((state) => getCurrentStorage(state));
    const { query } = useSelector((state: RootState) => getSearchRequestParams(state));

    if (type === ESearchOptionType.all && source === ESearchOptionSource.all) {
        return null;
    }

    const handleReset = () => {
        sendDwh({
            eventCategory: ECategoryGa.search,
            action: EActionSearch.filtersPlacement,
            dwhData: {
                place: 'web',
                search_phrase: query,
                section: storage,
                filters: type,
                filters_placement: source,
                action_place: 'toolbar',
            },
        });
        onReset(true);
    };

    return <WebSearchResetFiltersButton onClick={handleReset}>Сбросить фильтр</WebSearchResetFiltersButton>;
};

ResetWebSearchFiltersButton.displayName = 'ResetWebSearchFiltersButton';
