import { bytesToNDigits } from '@mail/cross-sizes-utils';
import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import cloudIcon from 'img/icons/cloud-colored.svg?url';
import { path, pathOr, reduce } from 'ramda';
import {
    EPromoSubscriptionType,
    IGetPromoAction,
    IPromo,
    IPromoApi,
    IState,
    IUnlockedProduct,
    TInitPromoTariffs,
} from 'reactApp/modules/promoTariffs/promoTariffs.types';
import { PARTNER_LIST } from 'reactApp/modules/subscriptions/subscriptions.data';
import { getStatus, getStatusByExpires } from 'reactApp/modules/subscriptions/subscriptions.helpers';
import { generateSubscriptionId } from 'reactApp/utils/generateSubscriptionId';

const INITIAL_STATE: IState = {
    list: {},
    rawList: [],
    isLoading: false,
    isLoaded: false,
};

const getType = (partner: string, isDiscount: boolean): EPromoSubscriptionType => {
    if ([EPromoSubscriptionType.android, EPromoSubscriptionType.ios].includes(partner as EPromoSubscriptionType)) {
        return partner as EPromoSubscriptionType;
    }

    if (isDiscount) {
        return EPromoSubscriptionType.discount;
    }

    return EPromoSubscriptionType.quota;
};

const getExpires = (promo?: IPromoApi) => {
    if (!promo || !promo.expire_at) {
        return undefined;
    }

    return promo.expire_at * 1000;
};

const normalize = (promos: IPromoApi[]) => {
    const today = Date.now();

    return reduce(
        (acc, cur) => {
            const isDiscount = !!cur.unlocked;

            if (isDiscount && !cur.unlocked.web) {
                return acc;
            }

            const expires = getExpires(cur);
            const start = cur.start_at * 1000;
            const quota = cur.space;
            const promoId = cur.id;
            const period = cur.period;
            const partner = cur.source;
            const type = getType(partner, isDiscount);
            const status = getStatus(getStatusByExpires(expires));
            const isProfessional = !!path(['flags', 'UFLAG_PRO'], cur);
            const isPartner = PARTNER_LIST.includes(partner);

            if (start && today < start) {
                return acc;
            }

            /** Необходимо для отправки аналитики партнеров */
            const id = isPartner
                ? promoId
                : generateSubscriptionId({
                      start,
                      quota,
                      expires,
                      type,
                      promoId,
                      period,
                      subId: '',
                  });

            const item: IPromo = {
                id,
                type,
                expires,
                period,
                promoId,
                start,
                status,
                icon: cur?.icon || cloudIcon,
                accountLink: cur?.account_link,
                partner: isPartner ? partner : undefined,
                isProfessional,
                flags: {
                    upload: !!path(['flags', 'UFLAG_PAID_UPLOAD'], cur),
                    paidFeatures: !!path(['flags', 'UFLAG_PAID_ACCOUNT'], cur),
                    pro: isProfessional,
                },
                products: [],
                productId: '',
            };

            if (isDiscount) {
                const unlocked = pathOr({}, ['unlocked', 'web'], cur) as IUnlockedProduct;

                item.productId = unlocked.product_id;
                item.products = [
                    {
                        id: unlocked.product_id,
                        period: unlocked.period,
                        space: bytesToNDigits(unlocked.space, 3),
                        price: unlocked.price,
                        discountPrice: unlocked.discount_price,
                        isTrial: unlocked.trial,
                        flags: {
                            upload: !!path(['flags', 'UFLAG_PAID_UPLOAD'], unlocked),
                            paidFeatures: !!path(['flags', 'UFLAG_PAID_ACCOUNT'], unlocked),
                        },
                    },
                ];
            } else {
                item.space = bytesToNDigits(quota, 3);
            }

            return {
                ...acc,
                [id]: { ...item },
            };
        },
        {},
        promos
    );
};

export const initPromoTariffs = createAction<TInitPromoTariffs>('promoTariffs/initPromoTariffs');
export const promoTariffsStart = createAction('promoTariffs/promoTariffsStart');
export const promoTariffsSuccess = createAction<IGetPromoAction>('promoTariffs/promoTariffsSuccess');
export const promoTariffsFailure = createAction<string>('promoTariffs/promoTariffsFailure');
export const updatePromoTariffs = createAction<{ onSuccess?(); onError?(); promoId?: string } | undefined>(
    'promoTariffs/updatePromoTariffs'
);
export const promoTariffsUpdateStart = createAction('promoTariffs/promoTariffsUpdateStart');
export const promoTariffsUpdateSuccess = createAction<IGetPromoAction>('promoTariffs/promoTariffsUpdateSuccess');
export const promoTariffsUpdateFailure = createAction<string>('promoTariffs/promoTariffsUpdateFailure');

const handlePromoTariffsSuccessAction = (state: IState, { payload: { list } }: PayloadAction<IGetPromoAction>): IState => ({
    ...state,
    rawList: list,
    list: normalize(list),
    isLoading: false,
    isLoaded: true,
});

const handlePromoTariffsStartAction = (state: IState) => ({
    ...state,
    isLoading: true,
    isLoaded: false,
});

const handlePromoTariffsFailureAction = (state: IState) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
});

export const promoTariffsReducer = createReducer(INITIAL_STATE, {
    [promoTariffsSuccess.type]: handlePromoTariffsSuccessAction,
    [promoTariffsUpdateSuccess.type]: handlePromoTariffsSuccessAction,
    [promoTariffsStart.type]: handlePromoTariffsStartAction,
    [promoTariffsUpdateStart.type]: handlePromoTariffsStartAction,
    [promoTariffsFailure.type]: handlePromoTariffsFailureAction,
    [promoTariffsUpdateFailure.type]: handlePromoTariffsFailureAction,
});
