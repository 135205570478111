import React, { memo, ReactElement, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EnvironmentSelectors } from 'reactApp/modules/environment/environment';
import { authPopup } from 'reactApp/modules/ph/ph.thunkActions';
import { Button } from 'reactApp/ui/Button/Button';
import { DownloadAppButton } from 'reactApp/ui/Mobile/DownloadAppButton/DownloadAppButton';
import { MobileDialog } from 'reactApp/ui/Mobile/MobileDialog/MobileDialog';

import styles from './AuthModal.css';

export const AuthModal = memo(({ onClose }: { onClose: () => void }): ReactElement => {
    const dispatch = useDispatch();
    const isWebview = useSelector(EnvironmentSelectors.isWebview);

    const onAuth = useCallback(() => {
        dispatch(
            authPopup({
                closable: false,
                loginRequest: true,
                successPage: window.location.href,
            })
        );
    }, []);

    const footer = useMemo(
        () => (
            <div>
                {!isWebview && (
                    <div className={styles.buttonWrapper}>
                        <DownloadAppButton />
                    </div>
                )}
                <div className={styles.buttonWrapper}>
                    <Button theme="vk" fluid onClick={onAuth}>
                        Войти
                    </Button>
                </div>
            </div>
        ),
        []
    );

    return (
        <MobileDialog
            id="mobile-auth-modal"
            open
            dimmer
            onClose={onClose}
            disableScrolling
            closable
            closeOnDimmerClick
            mod="base"
            topmost
            title="Войдите"
            footer={footer}
            contextPadding={24}
        >
            <div className={styles.root}>Для сохранения и редактирования в облаке, нужно авторизоваться или скачать приложение Облако</div>
        </MobileDialog>
    );
});

AuthModal.displayName = 'AuthModal';
