import { getFeature } from 'Cloud/Application/FeaturesEs6';
import { downloadItem } from 'reactApp/appHelpers/appDownload';
import { EAttachTypes } from 'reactApp/modules/attaches/attaches.types';
import { EStorageType } from 'reactApp/modules/storage/storage.types';
import { sendGa } from 'reactApp/utils/ga';

const availableExstension = getFeature('download-as-jpeg') || [];

const isDownloadedAttach = (itemType?: string, storage?: EStorageType): boolean => {
    const isAttaches = storage === EStorageType.attaches || storage === EStorageType.viewerAttaches;

    if (!itemType) {
        return !isAttaches;
    }

    if (!isAttaches) {
        return true;
    }

    const isCloudAttach = itemType === EAttachTypes.cloud;
    const isCloudStockAttach = itemType === EAttachTypes.cloudStock;

    return isCloudAttach || isCloudStockAttach;
};

class DownloadAsJpeg {
    isAvailable = ({ ext, itemType, storage }: { ext?: string; itemType?: string; storage?: EStorageType }): boolean =>
        !!ext && availableExstension.includes(ext.toLowerCase()) && isDownloadedAttach(itemType, storage);

    isAvailableUrl = (ext?: string): boolean => !!ext && availableExstension.includes(ext.toLowerCase());

    sendGa = ({ ext = '', asJpeg = false, label = '', event = '' }): void => {
        const extension = ext.toLowerCase();
        sendGa('downloadAsJpeg', event || (asJpeg ? `${extension}AsJpeg` : extension), label);
    };

    getDropdownItems = ({
        itemOrId,
        ext,
        gaSuffix = '',
        textPrefix = '',
        storage,
        isFormat = false,
    }: {
        // eslint-disable-next-line @typescript-eslint/ban-types
        itemOrId: string | object;
        ext: string;
        gaSuffix: string;
        textPrefix?: string;
        storage?: string;
        isFormat?: boolean;
    }) => {
        const itemExt = ext.toLowerCase();

        const buttonText = (ext) => (isFormat ? `${ext.toUpperCase()} формат` : `${textPrefix ? `${textPrefix} ` : ''}.${ext}`);

        return [
            {
                text: buttonText('jpeg'),
                id: 'downloadImgAsJpeg',
                onClick: (): void => {
                    this.sendGa({ ext: itemExt, asJpeg: true, label: gaSuffix });
                    downloadItem({ itemOrId, useDownloadUrl: true, storage });
                },
            },
            {
                text: buttonText(itemExt),
                id: 'downloadOriginal',
                onClick: (): void => {
                    this.sendGa({ ext: itemExt, label: gaSuffix });
                    downloadItem({ itemOrId, storage });
                },
            },
        ];
    };
}

const downloadAsJpeg = new DownloadAsJpeg();

export { downloadAsJpeg };
