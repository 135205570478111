import classNames from 'clsx';
import React, { memo, useRef } from 'react';
import { IS_MY_TEAM } from 'reactApp/appHelpers/configHelpers';
import { Input } from 'reactApp/components/BaseConfirmDialog/Input/Input';
import { DropdownFont, DropdownList, DropdownTheme } from 'reactApp/ui/DropdownList/DropdownList';
import { IProps } from 'reactApp/ui/InputSuggest/InputSuggest.types';
import { useCallbacks } from 'reactApp/ui/InputSuggest/useCallbacks';

import styles from './InputSuggest.css';

export const InputSuggest = memo<IProps>(
    ({
        value,
        focus,
        disabled,
        hasError,
        onChange,
        action,
        theme,
        placeholder,
        renderSuggestionItem,
        suggestions = [],
        onRequestSuggestions,
        getSuggestionItemId,
        className,
        inputClassName,
    }) => {
        const inputRef = useRef<HTMLInputElement | null>(null);
        const rootRef = useRef<HTMLDivElement | null>(null);

        const { handleInputKeyDown, closeDropdown, handleChange, calcDropdownPosition, calcDropdownSize, renderItem, showDropdown, list } =
            useCallbacks({
                suggestions,
                onChange,
                getSuggestionItemId,
                inputRef,
                rootRef,
                renderSuggestionItem,
                onRequestSuggestions,
                disabled,
            });

        const getReference = (ref) => {
            inputRef.current = ref;
        };

        return (
            <div ref={rootRef} className={classNames(styles.root, className)}>
                <Input
                    error={Boolean(hasError)}
                    onChange={handleChange}
                    onKeyDown={handleInputKeyDown}
                    value={value}
                    focus={focus}
                    placeholder={placeholder}
                    fluid
                    getReference={getReference}
                    disabled={disabled}
                    className={classNames(styles.input, inputClassName, {
                        [styles[`input_${theme}`]]: Boolean(theme),
                        [styles.input_right_action]: Boolean(action),
                        [styles.input_error]: hasError,
                    })}
                />
                {Boolean(action) && action}
                {showDropdown && (
                    <DropdownList
                        list={list}
                        renderItem={renderItem}
                        close={closeDropdown}
                        theme={DropdownTheme.suggest}
                        parentRef={inputRef}
                        closeOnItemClick
                        font={DropdownFont.mailSans}
                        gaId="suggest"
                        observePositionAndSize={IS_MY_TEAM}
                        calcPosition={calcDropdownPosition}
                        calcSize={calcDropdownSize}
                    />
                )}
            </div>
        );
    }
);

InputSuggest.displayName = 'InputSuggest';
