import classNames from 'clsx';
import React, { memo, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { CONTENT_LOAD_TIMEOUT } from 'reactApp/ui/ReactViewer/ReactViewer.constants';
import { Spinner } from 'reactApp/ui/Spinner/Spinner';

import styles from './ViewerTxt.css';

interface Props {
    url: string;
    renderError?: () => ReactElement;
    embedded?: boolean;
    onError?: () => void;
    onOpen?: () => void;
}

export const ViewerTxt = memo(({ url, renderError = undefined, embedded = false, onError, onOpen }: Props): ReactElement | null => {
    const [isError, setError] = useState(false);
    const [isLoaded, setLoaded] = useState(false);
    const ref = useRef<number | undefined>();

    const handleError = useCallback(() => {
        onError?.();
        setError(true);
        setLoaded(true);
    }, [setError, setLoaded, onError]);

    const handleLoad = useCallback(() => {
        if (ref.current) {
            clearTimeout(ref.current);
        }
        onOpen?.();
        setLoaded(true);
    }, [ref.current, setLoaded, onOpen]);

    useEffect(() => {
        if (ref.current) {
            clearTimeout(ref.current);
        }
        ref.current = window.setTimeout(handleError, CONTENT_LOAD_TIMEOUT);

        return () => {
            if (ref.current) {
                clearTimeout(ref.current);
            }
        };
    }, [url, handleError]);

    if (isError && renderError) {
        return renderError();
    }

    return (
        <div className={classNames({ [styles.root]: true, [styles.root_embedded]: embedded })}>
            <iframe
                src={url}
                className={styles.txt}
                allowFullScreen
                allow="autoplay; fullscreen"
                frameBorder={0}
                onError={handleError}
                onLoad={handleLoad}
            />
            {!isLoaded && <Spinner />}
        </div>
    );
});

ViewerTxt.displayName = 'ViewerTxt';

ViewerTxt.whyDidYouRender = true;
