import { captureException } from '@sentry/browser';
import { Rubles } from 'Cloud/Application/Rubles';
import React from 'react';
import { DOM_ID_INSIDE_PHONE_DATALIST } from 'reactApp/appHelpers/advertisingConfigHelpers';
import { ADS_TIMER_MS } from 'reactApp/constants';
import { useShowAdsBannerTimer } from 'reactApp/hooks/useShowAdsBannerTimer';

export const AdvBanner = () => {
    return useShowAdsBannerTimer({
        adsUpdateTimerMs: ADS_TIMER_MS,
        updateAds: () => {
            try {
                Rubles?.updateAdvPhonePublicInsideDatalist();
            } catch (error) {
                captureException(error);
            }
        },
        renderItem: () => <div id={DOM_ID_INSIDE_PHONE_DATALIST} />,
    });
};

AdvBanner.displayName = 'AdvBanner';
